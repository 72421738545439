import rocky1 from "./pictures/rocky/rocky-1.jpeg";
import rocky2 from "./pictures/rocky/rocky-2.jpeg";
import rocky3 from "./pictures/rocky/rocky-3.jpeg";
import predator1 from "./pictures/predator/predator-1.jpeg";
import predator2 from "./pictures/predator/predator-2.jpeg";
import predator3 from "./pictures/predator/predator-3.jpeg";
import showdown1 from "./pictures/showdown/showdown-1.jpeg";
import showdown2 from "./pictures/showdown/showdown-2.jpeg";
import showdown3 from "./pictures/showdown/showdown-3.jpeg";
import mnemonic1 from "./pictures/johnny-mnemonic/mnemonic-1.jpg";
import mnemonic2 from "./pictures/johnny-mnemonic/mnemonic-2.jpg";
import mnemonic3 from "./pictures/johnny-mnemonic/mnemonic-3.jpg";
import silverBullet1 from "./pictures/silver-bullet/silver-bullet-1.jpeg";
import silverBullet2 from "./pictures/silver-bullet/silver-bullet-2.jpeg";
import silverBullet3 from "./pictures/silver-bullet/silver-bullet-3.jpeg";
import roadHouse1 from "./pictures/road-house/road-house-1.jpeg";
import roadHouse2 from "./pictures/road-house/road-house-2.jpeg";
import roadHouse3 from "./pictures/road-house/road-house-3.jpeg";
import toxicAvenger1 from "./pictures/toxic-avenger/toxic-avenger-1.jpeg";
import toxicAvenger2 from "./pictures/toxic-avenger/toxic-avenger-2.jpeg";
import toxicAvenger3 from "./pictures/toxic-avenger/toxic-avenger-3.jpeg";
import beastmaster1 from "./pictures/beastmaster/beastmaster-1.jpg";
import beastmaster2 from "./pictures/beastmaster/beastmaster-2.jpg";
import beastmaster3 from "./pictures/beastmaster/beastmaster-3.jpg";
import escapeFromNy1 from "./pictures/escape-from-ny/escape-from-ny-1.jpg";
import escapeFromNy2 from "./pictures/escape-from-ny/escape-from-ny-2.jpg";
import escapeFromNy3 from "./pictures/escape-from-ny/escape-from-ny-3.jpg";
import goonies1 from "./pictures/goonies/goonies-1.jpg";
import goonies2 from "./pictures/goonies/goonies-2.jpg";
import goonies3 from "./pictures/goonies/goonies-3.jpg";
import karateKid1 from "./pictures/karate-kid/karate-kid-1.jpeg";
import karateKid2 from "./pictures/karate-kid/karate-kid-2.jpeg";
import karateKid3 from "./pictures/karate-kid/karate-kid-3.jpeg";
import commando1 from "./pictures/commando/commando-1.jpg";
import commando2 from "./pictures/commando/commando-2.jpg";
import commando3 from "./pictures/commando/commando-3.jpg";
import bloodDiner1 from "./pictures/blood-diner/blood-diner-1.jpg";
import bloodDiner2 from "./pictures/blood-diner/blood-diner-2.jpg";
import bloodDiner3 from "./pictures/blood-diner/blood-diner-3.jpg";
import hardTimes1 from "./pictures/hard-times/hard-times-1.jpg";
import hardTimes2 from "./pictures/hard-times/hard-times-2.jpg";
import hardTimes3 from "./pictures/hard-times/hard-times-3.jpg";
import pulpFiction1 from "./pictures/pulp-fiction/pulp-fiction-1.jpeg";
import pulpFiction2 from "./pictures/pulp-fiction/pulp-fiction-2.jpeg";
import pulpFiction3 from "./pictures/pulp-fiction/pulp-fiction-3.jpeg";
import commandPerformance1 from "./pictures/command-performance/command-performance-1.jpg";
import commandPerformance2 from "./pictures/command-performance/command-performance-2.jpg";
import commandPerformance3 from "./pictures/command-performance/command-performance-3.jpg";
import creepshow1 from "./pictures/creepshow/creepshow-1.jpg";
import creepshow2 from "./pictures/creepshow/creepshow-2.jpg";
import creepshow3 from "./pictures/creepshow/creepshow-3.jpg";
import bloodsport1 from "./pictures/bloodsport/bloodsport-1.jpg";
import bloodsport2 from "./pictures/bloodsport/bloodsport-2.jpg";
import bloodsport3 from "./pictures/bloodsport/bloodsport-3.jpg";
import sleepyHollow1 from "./pictures/sleepy-hollow/sleepy-hollow-1.jpeg";
import sleepyHollow2 from "./pictures/sleepy-hollow/sleepy-hollow-2.jpeg";
import sleepyHollow3 from "./pictures/sleepy-hollow/sleepy-hollow-3.jpeg";
import zodiac1 from "./pictures/zodiac/zodiac-1.jpeg";
import zodiac2 from "./pictures/zodiac/zodiac-2.jpeg";
import zodiac3 from "./pictures/zodiac/zodiac-3.jpeg";
import gingerdead1 from "./pictures/gingerdead/gingerdead-1.jpg";
import gingerdead2 from "./pictures/gingerdead/gingerdead-2.jpg";
import gingerdead3 from "./pictures/gingerdead/gingerdead-3.jpg";
import cabinInWoods1 from "./pictures/cabin-in-woods/cabin-in-woods-1.jpg";
import cabinInWoods2 from "./pictures/cabin-in-woods/cabin-in-woods-2.jpg";
import cabinInWoods3 from "./pictures/cabin-in-woods/cabin-in-woods-3.jpg";
import lastSamurai1 from "./pictures/last-samurai/last-samurai-1.jpeg";
import lastSamurai2 from "./pictures/last-samurai/last-samurai-2.jpeg";
import lastSamurai3 from "./pictures/last-samurai/last-samurai-3.jpeg";
import theFan1 from "./pictures/the-fan/the-fan-1.jpeg";
import theFan2 from "./pictures/the-fan/the-fan-2.jpeg";
import theFan3 from "./pictures/the-fan/the-fan-3.jpeg";
import colorOfMoney1 from "./pictures/color-of-money/color-of-money-1.jpg";
import colorOfMoney2 from "./pictures/color-of-money/color-of-money-2.jpg";
import colorOfMoney3 from "./pictures/color-of-money/color-of-money-3.jpg";
import jackieBrown1 from "./pictures/jackie-brown/jackie-brown-1.jpg";
import jackieBrown2 from "./pictures/jackie-brown/jackie-brown-2.jpg";
import jackieBrown3 from "./pictures/jackie-brown/jackie-brown-3.jpg";
import patriot1 from "./pictures/patriot/patriot-1.jpeg";
import patriot2 from "./pictures/patriot/patriot-2.jpeg";
import patriot3 from "./pictures/patriot/patriot-3.jpeg";
import stonedAge1 from "./pictures/stoned-age/stoned-age-1.jpeg";
import stonedAge2 from "./pictures/stoned-age/stoned-age-2.jpeg";
import stonedAge3 from "./pictures/stoned-age/stoned-age-3.jpeg";
import bttf1 from "./pictures/back-to-the-future/bttf-1.jpg";
import bttf2 from "./pictures/back-to-the-future/bttf-2.jpg";
import bttf3 from "./pictures/back-to-the-future/bttf-3.jpg";
import cocktail1 from "./pictures/cocktail/cocktail-1.jpg";
import cocktail2 from "./pictures/cocktail/cocktail-2.jpg";
import cocktail3 from "./pictures/cocktail/cocktail-3.jpg";
import bttfThree1 from "./pictures/back-to-the-future-3/bttf3-1.jpg";
import bttfThree2 from "./pictures/back-to-the-future-3/bttf3-2.jpg";
import bttfThree3 from "./pictures/back-to-the-future-3/bttf3-3.jpg";
import bttfTwo1 from "./pictures/back-to-the-future-2/bttf2-1.jpg";
import bttfTwo2 from "./pictures/back-to-the-future-2/bttf2-2.jpg";
import bttfTwo3 from "./pictures/back-to-the-future-2/bttf2-3.jpg";
import actionJackson1 from "./pictures/action-jackson/action-jackson-1.jpg";
import actionJackson2 from "./pictures/action-jackson/action-jackson-2.jpg";
import actionJackson3 from "./pictures/action-jackson/action-jackson-3.jpg";
import godfather1 from "./pictures/the-godfather/the-godfather-1.jpeg";
import godfather2 from "./pictures/the-godfather/the-godfather-2.jpeg";
import godfather3 from "./pictures/the-godfather/the-godfather-3.jpeg";
import revenant1 from "./pictures/the-revenant/the-revenant-1.jpeg";
import revenant2 from "./pictures/the-revenant/the-revenant-2.jpeg";
import revenant3 from "./pictures/the-revenant/the-revenant-3.jpeg";
import warcraft1 from "./pictures/warcraft/warcraft-1.jpeg";
import warcraft2 from "./pictures/warcraft/warcraft-2.jpeg";
import warcraft3 from "./pictures/warcraft/warcraft-3.jpeg";
import amadeus1 from "./pictures/amadeus/amadeus-1.jpg";
import amadeus2 from "./pictures/amadeus/amadeus-2.jpg";
import amadeus3 from "./pictures/amadeus/amadeus-3.jpg";
import godForgives1 from "./pictures/only-god-forgives/only-god-forgives-1.jpeg";
import godForgives2 from "./pictures/only-god-forgives/only-god-forgives-2.jpeg";
import godForgives3 from "./pictures/only-god-forgives/only-god-forgives-3.jpeg";
import edge1 from "./pictures/the-edge/edge-1.jpeg";
import edge2 from "./pictures/the-edge/edge-2.jpeg";
import edge3 from "./pictures/the-edge/edge-3.jpeg";
import mask1 from "./pictures/the-mask/mask-1.jpeg";
import mask2 from "./pictures/the-mask/mask-2.jpeg";
import mask3 from "./pictures/the-mask/mask-3.jpeg";
import corpses1 from "./pictures/house-of-corpses/corpses-1.jpg";
import corpses2 from "./pictures/house-of-corpses/corpses-2.jpg";
import corpses3 from "./pictures/house-of-corpses/corpses-3.jpg";
import rejects1 from "./pictures/devils-rejects/rejects-1.jpg";
import rejects2 from "./pictures/devils-rejects/rejects-2.jpg";
import rejects3 from "./pictures/devils-rejects/rejects-3.jpg";
import threeFromHell1 from "./pictures/3-from-hell/3-from-hell-1.jpeg";
import threeFromHell2 from "./pictures/3-from-hell/3-from-hell-2.jpeg";
import threeFromHell3 from "./pictures/3-from-hell/3-from-hell-3.jpeg";
import rockyTwo1 from "./pictures/rocky-2/rocky-two-1.jpeg";
import rockyTwo2 from "./pictures/rocky-2/rocky-two-2.jpeg";
import rockyTwo3 from "./pictures/rocky-2/rocky-two-3.jpeg";
import rockyThree1 from "./pictures/rocky-3/rocky-three-1.jpeg";
import rockyThree2 from "./pictures/rocky-3/rocky-three-2.jpeg";
import rockyThree3 from "./pictures/rocky-3/rocky-three-3.jpeg";
import rockyFour1 from "./pictures/rocky-4/rocky-four-1.jpeg";
import rockyFour2 from "./pictures/rocky-4/rocky-four-2.jpeg";
import rockyFour3 from "./pictures/rocky-4/rocky-four-3.jpeg";
import rockyFive1 from "./pictures/rocky-5/rocky-five-1.jpeg";
import rockyFive2 from "./pictures/rocky-5/rocky-five-2.jpeg";
import rockyFive3 from "./pictures/rocky-5/rocky-five-3.jpeg";
import lostBoys1 from "./pictures/the-lost-boys/the-lost-boys-1.jpeg";
import lostBoys2 from "./pictures/the-lost-boys/the-lost-boys-2.jpeg";
import lostBoys3 from "./pictures/the-lost-boys/the-lost-boys-3.jpeg";
import fightClub1 from "./pictures/fight-club/fight-club-1.jpg";
import fightClub2 from "./pictures/fight-club/fight-club-2.jpg";
import fightClub3 from "./pictures/fight-club/fight-club-3.jpg";
import hotFuzz1 from "./pictures/hot-fuzz/hot-fuzz-1.jpg";
import hotFuzz2 from "./pictures/hot-fuzz/hot-fuzz-2.jpg";
import hotFuzz3 from "./pictures/hot-fuzz/hot-fuzz-3.jpg";
import shaun1 from "./pictures/shaun-of-the-dead/shaun-of-the-dead-1.jpeg";
import shaun2 from "./pictures/shaun-of-the-dead/shaun-of-the-dead-2.jpeg";
import shaun3 from "./pictures/shaun-of-the-dead/shaun-of-the-dead-3.jpeg";
import worldsEnd1 from "./pictures/the-worlds-end/the-worlds-end-1.jpeg";
import worldsEnd2 from "./pictures/the-worlds-end/the-worlds-end-2.jpeg";
import worldsEnd3 from "./pictures/the-worlds-end/the-worlds-end-3.jpeg";
import fatboy1 from "./pictures/run-fatboy-run/run-fatboy-run-1.jpeg";
import fatboy2 from "./pictures/run-fatboy-run/run-fatboy-run-2.jpeg";
import fatboy3 from "./pictures/run-fatboy-run/run-fatboy-run-3.jpeg";
import knockedUp1 from "./pictures/knocked-up/knocked-up-1.jpeg";
import knockedUp2 from "./pictures/knocked-up/knocked-up-2.jpeg";
import knockedUp3 from "./pictures/knocked-up/knocked-up-3.jpeg";
import fortyYearOld1 from "./pictures/40-year-old-virgin/40-year-old-virgin-1.jpeg";
import fortyYearOld2 from "./pictures/40-year-old-virgin/40-year-old-virgin-2.jpeg";
import fortyYearOld3 from "./pictures/40-year-old-virgin/40-year-old-virgin-3.jpeg";
import dod1 from "./pictures/dawn-of-the-dead04/dawn-of-the-dead04-1.jpg";
import dod2 from "./pictures/dawn-of-the-dead04/dawn-of-the-dead04-2.jpg";
import dod3 from "./pictures/dawn-of-the-dead04/dawn-of-the-dead04-3.jpg";
import roleModels1 from "./pictures/role-models/role-models-1.jpeg";
import roleModels2 from "./pictures/role-models/role-models-2.jpeg";
import roleModels3 from "./pictures/role-models/role-models-3.jpeg";
import sarahMarshall1 from "./pictures/forgetting-sarah-marshall/forgetting-sarah-marshall-1.jpg";
import sarahMarshall2 from "./pictures/forgetting-sarah-marshall/forgetting-sarah-marshall-2.jpg";
import sarahMarshall3 from "./pictures/forgetting-sarah-marshall/forgetting-sarah-marshall-3.jpg";
import comingSoon1 from "./pictures/coming-soon/coming-soon-1.jpg";
import comingSoon2 from "./pictures/coming-soon/coming-soon-2.jpg";
import comingSoon3 from "./pictures/coming-soon/coming-soon-3.jpg";
import fastTimes1 from "./pictures/fast-times/fast-times-1.jpg";
import fastTimes2 from "./pictures/fast-times/fast-times-2.jpg";
import fastTimes3 from "./pictures/fast-times/fast-times-3.jpg";
import threeOClock1 from "./pictures/three-o-clock-high/three-o-clock-high-1.jpeg";
import threeOClock2 from "./pictures/three-o-clock-high/three-o-clock-high-2.jpeg";
import threeOClock3 from "./pictures/three-o-clock-high/three-o-clock-high-3.jpeg";
import menAtWork1 from "./pictures/men-at-work/men-at-work-1.jpeg";
import menAtWork2 from "./pictures/men-at-work/men-at-work-2.jpeg";
import menAtWork3 from "./pictures/men-at-work/men-at-work-3.jpeg";
import cuttingClass1 from "./pictures/cutting-class/cutting-class-1.jpg";
import cuttingClass2 from "./pictures/cutting-class/cutting-class-2.jpg";
import cuttingClass3 from "./pictures/cutting-class/cutting-class-3.jpg";
import httm1 from "./pictures/hot-tub-time-machine/hot-tub-time-machine-1.jpg";
import httm2 from "./pictures/hot-tub-time-machine/hot-tub-time-machine-2.jpg";
import httm3 from "./pictures/hot-tub-time-machine/hot-tub-time-machine-3.jpg";
import landOfDead1 from "./pictures/land-of-the-dead/land-of-the-dead-1.jpeg";
import landOfDead2 from "./pictures/land-of-the-dead/land-of-the-dead-2.jpeg";
import landOfDead3 from "./pictures/land-of-the-dead/land-of-the-dead-3.jpeg";
import lotrFellowship1 from "./pictures/lotr-fellowship/lotr-fellowship-1.jpeg";
import lotrFellowship2 from "./pictures/lotr-fellowship/lotr-fellowship-2.jpeg";
import lotrFellowship3 from "./pictures/lotr-fellowship/lotr-fellowship-3.jpeg";
import lotrTower1 from "./pictures/lotr-tower/lotr-tower-1.jpeg";
import lotrTower2 from "./pictures/lotr-tower/lotr-tower-2.jpeg";
import lotrTower3 from "./pictures/lotr-tower/lotr-tower-3.jpeg";
import lotrReturn1 from "./pictures/lotr-return/lotr-return-1.jpeg";
import lotrReturn2 from "./pictures/lotr-return/lotr-return-2.jpeg";
import lotrReturn3 from "./pictures/lotr-return/lotr-return-3.jpeg";
import rockyBalboa1 from "./pictures/rocky-balboa/rocky-balboa-1.jpeg";
import rockyBalboa2 from "./pictures/rocky-balboa/rocky-balboa-2.jpeg";
import rockyBalboa3 from "./pictures/rocky-balboa/rocky-balboa-3.jpeg";
import superbad1 from "./pictures/superbad/superbad-1.jpeg";
import superbad2 from "./pictures/superbad/superbad-2.jpeg";
import superbad3 from "./pictures/superbad/superbad-3.jpeg";
import superTroopers1 from "./pictures/super-troopers/super-troopers-1.jpeg";
import superTroopers2 from "./pictures/super-troopers/super-troopers-2.jpeg";
import superTroopers3 from "./pictures/super-troopers/super-troopers-3.jpeg";
import superTroopersTwo1 from "./pictures/super-troopers-2/super-troopers2-1.jpeg";
import superTroopersTwo2 from "./pictures/super-troopers-2/super-troopers2-2.jpeg";
import superTroopersTwo3 from "./pictures/super-troopers-2/super-troopers2-3.jpeg";
import waiting1 from "./pictures/waiting/waiting-1.jpeg";
import waiting2 from "./pictures/waiting/waiting-2.jpeg";
import waiting3 from "./pictures/waiting/waiting-3.jpeg";
import palmSprings1 from "./pictures/palm-springs/palm-springs-1.jpeg";
import palmSprings2 from "./pictures/palm-springs/palm-springs-2.jpeg";
import palmSprings3 from "./pictures/palm-springs/palm-springs-3.jpeg";
import justFriends1 from "./pictures/just-friends/just-friends-1.jpg";
import justFriends2 from "./pictures/just-friends/just-friends-2.jpg";
import justFriends3 from "./pictures/just-friends/just-friends-3.jpg";
import hotRod1 from "./pictures/hot-rod/hot-rod-1.jpg";
import hotRod2 from "./pictures/hot-rod/hot-rod-2.jpg";
import hotRod3 from "./pictures/hot-rod/hot-rod-3.jpg";
import rushHour1 from "./pictures/rush-hour/rush-hour-1.jpeg";
import rushHour2 from "./pictures/rush-hour/rush-hour-2.jpeg";
import rushHour3 from "./pictures/rush-hour/rush-hour-3.jpeg";
import rushHour21 from "./pictures/rush-hourtwo/rush-hourtwo-1.jpeg";
import rushHour22 from "./pictures/rush-hourtwo/rush-hourtwo-2.jpeg";
import rushHour23 from "./pictures/rush-hourtwo/rush-hourtwo-3.jpeg";
import rushHour31 from "./pictures/rush-hourthree/rush-hourthree-1.jpeg";
import rushHour32 from "./pictures/rush-hourthree/rush-hourthree-2.jpeg";
import rushHour33 from "./pictures/rush-hourthree/rush-hourthree-3.jpeg";
import jedi1 from "./pictures/star-wars-rotj/star-wars-rotj-1.jpeg";
import jedi2 from "./pictures/star-wars-rotj/star-wars-rotj-2.jpeg";
import jedi3 from "./pictures/star-wars-rotj/star-wars-rotj-3.jpeg";
import darkKnight1 from "./pictures/dark-knight/dark-knight-1.jpg";
import darkKnight2 from "./pictures/dark-knight/dark-knight-2.jpg";
import darkKnight3 from "./pictures/dark-knight/dark-knight-3.jpg";
import hotShots1 from "./pictures/hot-shots/hot-shots-1.jpg";
import hotShots2 from "./pictures/hot-shots/hot-shots-2.jpg";
import hotShots3 from "./pictures/hot-shots/hot-shots-3.jpg";
import hotShotsDeux1 from "./pictures/hot-shots-deux/hot-shots-deux-1.jpg";
import hotShotsDeux2 from "./pictures/hot-shots-deux/hot-shots-deux-2.jpg";
import hotShotsDeux3 from "./pictures/hot-shots-deux/hot-shots-deux-3.jpg";
import et1 from "./pictures/et/et-1.jpg";
import et2 from "./pictures/et/et-2.jpg";
import et3 from "./pictures/et/et-3.jpg";
import departed1 from "./pictures/the-departed/the-departed-1.jpeg";
import departed2 from "./pictures/the-departed/the-departed-2.jpeg";
import departed3 from "./pictures/the-departed/the-departed-3.jpeg";
import werewolf1 from "./pictures/am-were-london/am-were-london-1.jpg";
import werewolf2 from "./pictures/am-were-london/am-were-london-2.jpg";
import werewolf3 from "./pictures/am-were-london/am-were-london-3.jpg";
import mac1 from "./pictures/mac-and-me/mac-and-me-1.jpeg";
import mac2 from "./pictures/mac-and-me/mac-and-me-2.jpeg";
import mac3 from "./pictures/mac-and-me/mac-and-me-3.jpeg";
import taxi1 from "./pictures/taxi-driver/taxi-driver-1.jpeg";
import taxi2 from "./pictures/taxi-driver/taxi-driver-2.jpeg";
import taxi3 from "./pictures/taxi-driver/taxi-driver-3.jpeg";
import greenMile1 from "./pictures/green-mile/green-mile-1.jpg";
import greenMile2 from "./pictures/green-mile/green-mile-2.jpg";
import greenMile3 from "./pictures/green-mile/green-mile-3.jpg";
import reservoir1 from "./pictures/reservoir-dogs/reservoir-dogs-1.jpeg";
import reservoir2 from "./pictures/reservoir-dogs/reservoir-dogs-2.jpeg";
import reservoir3 from "./pictures/reservoir-dogs/reservoir-dogs-3.jpeg";
import privateRyan1 from "./pictures/saving-ryan/saving-ryan-1.jpeg";
import privateRyan2 from "./pictures/saving-ryan/saving-ryan-2.jpeg";
import privateRyan3 from "./pictures/saving-ryan/saving-ryan-3.jpeg";
import seven1 from "./pictures/seven/seven-1.jpeg";
import seven2 from "./pictures/seven/seven-2.jpeg";
import seven3 from "./pictures/seven/seven-3.jpeg";
import silenceLambs1 from "./pictures/silence-lambs/silence-lambs-1.jpeg";
import silenceLambs2 from "./pictures/silence-lambs/silence-lambs-2.jpeg";
import silenceLambs3 from "./pictures/silence-lambs/silence-lambs-3.jpeg";
import thankskilling1 from "./pictures/thankskilling/thankskilling-1.jpeg";
import thankskilling2 from "./pictures/thankskilling/thankskilling-2.jpeg";
import thankskilling3 from "./pictures/thankskilling/thankskilling-3.jpeg";
import professional1 from "./pictures/the-professional/the-professional-1.jpeg";
import professional2 from "./pictures/the-professional/the-professional-2.jpeg";
import professional3 from "./pictures/the-professional/the-professional-3.jpeg";
import beverlyHillsNinja1 from "./pictures/beverly-hills-ninja/beverly-hills-ninja-1.jpg";
import beverlyHillsNinja2 from "./pictures/beverly-hills-ninja/beverly-hills-ninja-2.jpg";
import beverlyHillsNinja3 from "./pictures/beverly-hills-ninja/beverly-hills-ninja-3.jpg";
import rumbleInBronx1 from "./pictures/rumble-bronx/rumble-bronx-1.jpeg";
import rumbleInBronx2 from "./pictures/rumble-bronx/rumble-bronx-2.jpeg";
import rumbleInBronx3 from "./pictures/rumble-bronx/rumble-bronx-3.jpeg";
import wildwildWest1 from "./pictures/wild-wild-west/wild-wild-west-1.jpeg";
import wildwildWest2 from "./pictures/wild-wild-west/wild-wild-west-2.jpeg";
import wildwildWest3 from "./pictures/wild-wild-west/wild-wild-west-3.jpeg";
import joker1 from "./pictures/joker/joker-1.jpg";
import joker2 from "./pictures/joker/joker-2.jpg";
import joker3 from "./pictures/joker/joker-3.jpg";
import lionKing1 from "./pictures/lion-king94/lion-king94-1.jpeg";
import lionKing2 from "./pictures/lion-king94/lion-king94-2.jpeg";
import lionKing3 from "./pictures/lion-king94/lion-king94-3.jpeg";
import greatEscape1 from "./pictures/the-great-escape/great-escape-1.jpeg";
import greatEscape2 from "./pictures/the-great-escape/great-escape-2.jpeg";
import greatEscape3 from "./pictures/the-great-escape/great-escape-3.jpeg";
import conAir1 from "./pictures/con-air/con-air-1.jpg";
import conAir2 from "./pictures/con-air/con-air-2.jpg";
import conAir3 from "./pictures/con-air/con-air-3.jpg";
import deathWarrant1 from "./pictures/death-warrant/death-warrant-1.jpg";
import deathWarrant2 from "./pictures/death-warrant/death-warrant-2.jpg";
import deathWarrant3 from "./pictures/death-warrant/death-warrant-3.jpg";
import airplane1 from "./pictures/airplane/airplane-1.jpg";
import airplane2 from "./pictures/airplane/airplane-2.jpg";
import airplane3 from "./pictures/airplane/airplane-3.jpg";
import breakfastClub1 from "./pictures/breakfast-club/breakfast-club-1.jpg";
import breakfastClub2 from "./pictures/breakfast-club/breakfast-club-2.jpg";
import breakfastClub3 from "./pictures/breakfast-club/breakfast-club-3.jpg";
import ferris1 from "./pictures/ferris-buehler/ferris-buehler-1.jpg";
import ferris2 from "./pictures/ferris-buehler/ferris-buehler-2.jpg";
import ferris3 from "./pictures/ferris-buehler/ferris-buehler-3.jpg";
import summerRental1 from "./pictures/summer-rental/summer-rental-1.jpeg";
import summerRental2 from "./pictures/summer-rental/summer-rental-2.jpeg";
import summerRental3 from "./pictures/summer-rental/summer-rental-3.jpeg";
import karateKid21 from "./pictures/karate-kid-2/karate-kid-2-1.jpeg";
import karateKid22 from "./pictures/karate-kid-2/karate-kid-2-2.jpeg";
import karateKid23 from "./pictures/karate-kid-2/karate-kid-2-3.jpeg";
import karateKid31 from "./pictures/karate-kid-3/karate-kid-3-1.jpeg";
import karateKid32 from "./pictures/karate-kid-3/karate-kid-3-2.jpeg";
import karateKid33 from "./pictures/karate-kid-3/karate-kid-3-3.jpeg";
import nextKarate1 from "./pictures/next-karate/next-karate-1.jpeg";
import nextKarate2 from "./pictures/next-karate/next-karate-2.jpeg";
import nextKarate3 from "./pictures/next-karate/next-karate-3.jpeg";
import aboveTheLaw1 from "./pictures/above-the-law/above-the-law-1.jpg";
import aboveTheLaw2 from "./pictures/above-the-law/above-the-law-2.jpg";
import aboveTheLaw3 from "./pictures/above-the-law/above-the-law-3.jpg";
import fearlessHyena1 from "./pictures/fearless-hyena/fearless-hyena-1.jpg";
import fearlessHyena2 from "./pictures/fearless-hyena/fearless-hyena-2.jpg";
import fearlessHyena3 from "./pictures/fearless-hyena/fearless-hyena-3.jpg";
import kungPow1 from "./pictures/kung-pow/kung-pow-1.jpeg";
import kungPow2 from "./pictures/kung-pow/kung-pow-2.jpeg";
import kungPow3 from "./pictures/kung-pow/kung-pow-3.jpeg";
import clockworkOrange1 from "./pictures/clockwork-orange/clockwork-orange-1.jpg";
import clockworkOrange2 from "./pictures/clockwork-orange/clockwork-orange-2.jpg";
import clockworkOrange3 from "./pictures/clockwork-orange/clockwork-orange-3.jpg";
import nukeEmHigh1 from "./pictures/nuke-em-high/nuke-em-high-1.jpeg";
import nukeEmHigh2 from "./pictures/nuke-em-high/nuke-em-high-2.jpeg";
import nukeEmHigh3 from "./pictures/nuke-em-high/nuke-em-high-3.jpeg";
import mortalKombat1 from "./pictures/mortal-kombat/mortal-kombat-1.jpeg";
import mortalKombat2 from "./pictures/mortal-kombat/mortal-kombat-2.jpeg";
import mortalKombat3 from "./pictures/mortal-kombat/mortal-kombat-3.jpeg";
import scaryMovie1 from "./pictures/scary-movie-1/scary-movie-1-1.jpeg";
import scaryMovie12 from "./pictures/scary-movie-1/scary-movie-1-2.jpeg";
import scaryMovie13 from "./pictures/scary-movie-1/scary-movie-1-3.jpeg";
import scaryMovie21 from "./pictures/scary-movie-2/scary-movie-2-1.jpeg";
import scaryMovie22 from "./pictures/scary-movie-2/scary-movie-2-2.jpeg";
import scaryMovie23 from "./pictures/scary-movie-2/scary-movie-2-3.jpeg";
import scaryMovie31 from "./pictures/scary-movie-3/scary-movie-3-1.jpeg";
import scaryMovie32 from "./pictures/scary-movie-3/scary-movie-3-2.jpeg";
import scaryMovie33 from "./pictures/scary-movie-3/scary-movie-3-3.jpeg";
import hardToKill1 from "./pictures/hard-to-kill/hard-to-kill-1.jpg";
import hardToKill2 from "./pictures/hard-to-kill/hard-to-kill-2.jpg";
import hardToKill3 from "./pictures/hard-to-kill/hard-to-kill-3.jpg";
import shawshank1 from "./pictures/shawshank-redemption/shawshank-redemption-1.jpeg";
import shawshank2 from "./pictures/shawshank-redemption/shawshank-redemption-2.jpeg";
import shawshank3 from "./pictures/shawshank-redemption/shawshank-redemption-3.jpeg";
import joseyWales1 from "./pictures/josey-wales/josey-wales-1.jpg";
import joseyWales2 from "./pictures/josey-wales/josey-wales-2.jpg";
import joseyWales3 from "./pictures/josey-wales/josey-wales-3.jpg";
import unforgiven1 from "./pictures/unforgiven/unforgiven-1.jpeg";
import unforgiven2 from "./pictures/unforgiven/unforgiven-2.jpeg";
import unforgiven3 from "./pictures/unforgiven/unforgiven-3.jpeg";
import forrestGump1 from "./pictures/forrest-gump/forrest-gump-1.jpg";
import forrestGump2 from "./pictures/forrest-gump/forrest-gump-2.jpg";
import forrestGump3 from "./pictures/forrest-gump/forrest-gump-3.jpg";
import scaryMovie41 from "./pictures/scary-movie-4/scary-movie-4-1.jpeg";
import scaryMovie42 from "./pictures/scary-movie-4/scary-movie-4-2.jpeg";
import scaryMovie43 from "./pictures/scary-movie-4/scary-movie-4-3.jpeg";
import matrix1 from "./pictures/the-matrix/the-matrix-1.jpeg";
import matrix2 from "./pictures/the-matrix/the-matrix-2.jpeg";
import matrix3 from "./pictures/the-matrix/the-matrix-3.jpeg";
import cuckoo1 from "./pictures/cuckoos-nest/cuckoos-nest-1.jpg";
import cuckoo2 from "./pictures/cuckoos-nest/cuckoos-nest-2.jpg";
import cuckoo3 from "./pictures/cuckoos-nest/cuckoos-nest-3.jpg";
import marathonMan1 from "./pictures/marathon-man/marathon-man-1.jpeg";
import marathonMan2 from "./pictures/marathon-man/marathon-man-2.jpeg";
import marathonMan3 from "./pictures/marathon-man/marathon-man-3.jpeg";
import goodfellas1 from "./pictures/goodfellas/goodfellas-1.jpg";
import goodfellas2 from "./pictures/goodfellas/goodfellas-2.jpg";
import goodfellas3 from "./pictures/goodfellas/goodfellas-3.jpg";
import cityOfGod1 from "./pictures/city-of-god/city-of-god-1.jpg";
import cityOfGod2 from "./pictures/city-of-god/city-of-god-2.jpg";
import cityOfGod3 from "./pictures/city-of-god/city-of-god-3.jpg";
import hannibal1 from "./pictures/hannibal/hannibal-1.jpg";
import hannibal2 from "./pictures/hannibal/hannibal-2.jpg";
import hannibal3 from "./pictures/hannibal/hannibal-3.jpg";
import redDragon1 from "./pictures/red-dragon/red-dragon-1.jpeg";
import redDragon2 from "./pictures/red-dragon/red-dragon-2.jpeg";
import redDragon3 from "./pictures/red-dragon/red-dragon-3.jpeg";
import spiritedAway1 from "./pictures/spirited-away/spirited-away-1.jpeg";
import spiritedAway2 from "./pictures/spirited-away/spirited-away-2.jpeg";
import spiritedAway3 from "./pictures/spirited-away/spirited-away-3.jpeg";
import interstellar1 from "./pictures/interstellar/interstellar-1.jpg";
import interstellar2 from "./pictures/interstellar/interstellar-2.jpg";
import interstellar3 from "./pictures/interstellar/interstellar-3.jpg";
import usualSuspects1 from "./pictures/usual-suspects/usual-suspects-1.jpeg";
import usualSuspects2 from "./pictures/usual-suspects/usual-suspects-2.jpeg";
import usualSuspects3 from "./pictures/usual-suspects/usual-suspects-3.jpeg";
import hunted1 from "./pictures/hunted/hunted-1.jpg";
import hunted2 from "./pictures/hunted/hunted-2.jpg";
import hunted3 from "./pictures/hunted/hunted-3.jpg";
import ahx1 from "./pictures/american-history-x/ahx-1.jpg";
import ahx2 from "./pictures/american-history-x/ahx-2.jpg";
import ahx3 from "./pictures/american-history-x/ahx-3.jpg";
import pianist1 from "./pictures/the-pianist/pianist-1.jpeg";
import pianist2 from "./pictures/the-pianist/pianist-2.jpeg";
import pianist3 from "./pictures/the-pianist/pianist-3.jpeg";
import schindler1 from "./pictures/schindlers-list/schindler-1.jpeg";
import schindler2 from "./pictures/schindlers-list/schindler-2.jpeg";
import schindler3 from "./pictures/schindlers-list/schindler-3.jpeg";
import casino1 from "./pictures/casino/casino-1.jpg";
import casino2 from "./pictures/casino/casino-2.jpg";
import casino3 from "./pictures/casino/casino-3.jpg";
import theTerminator1 from "./pictures/the-terminator/terminator-1.jpeg";
import theTerminator2 from "./pictures/the-terminator/terminator-2.jpeg";
import theTerminator3 from "./pictures/the-terminator/terminator-3.jpeg";
import theTerminator21 from "./pictures/the-terminator-2/t2-1.jpeg";
import theTerminator22 from "./pictures/the-terminator-2/t2-2.jpeg";
import theTerminator23 from "./pictures/the-terminator-2/t2-3.jpeg";
import theTerminator31 from "./pictures/the-terminator-3/t3-1.jpeg";
import theTerminator32 from "./pictures/the-terminator-3/t3-2.jpeg";
import theTerminator33 from "./pictures/the-terminator-3/t3-3.jpeg";
import gladiator1 from "./pictures/gladiator/gladiator-1.jpg";
import gladiator2 from "./pictures/gladiator/gladiator-2.jpg";
import gladiator3 from "./pictures/gladiator/gladiator-3.jpg";
import whiplash1 from "./pictures/whiplash/whiplash-1.jpeg";
import whiplash2 from "./pictures/whiplash/whiplash-2.jpeg";
import whiplash3 from "./pictures/whiplash/whiplash-3.jpeg";
import greatestShowman1 from "./pictures/greatest-showman/greatest-showman-1.jpg";
import greatestShowman2 from "./pictures/greatest-showman/greatest-showman-2.jpg";
import greatestShowman3 from "./pictures/greatest-showman/greatest-showman-3.jpg";
import gangsOfNY1 from "./pictures/gangs-of-new-york/gangs-of-ny-1.jpg";
import gangsOfNY2 from "./pictures/gangs-of-new-york/gangs-of-ny-2.jpg";
import gangsOfNY3 from "./pictures/gangs-of-new-york/gangs-of-ny-3.jpg";
import footballFactory1 from "./pictures/football-factory/football-factory-1.jpg";
import footballFactory2 from "./pictures/football-factory/football-factory-2.jpg";
import footballFactory3 from "./pictures/football-factory/football-factory-3.jpg";
import greenStreetHooligans1 from "./pictures/green-street-hooligans/green-street-1.jpg";
import greenStreetHooligans2 from "./pictures/green-street-hooligans/green-street-2.jpg";
import greenStreetHooligans3 from "./pictures/green-street-hooligans/green-street-3.jpg";
import prestige1 from "./pictures/the-prestige/the-prestige-1.jpeg";
import prestige2 from "./pictures/the-prestige/the-prestige-2.jpeg";
import prestige3 from "./pictures/the-prestige/the-prestige-3.jpeg";
import alien1 from "./pictures/alien/alien-1.jpg";
import alien2 from "./pictures/alien/alien-2.jpg";
import alien3 from "./pictures/alien/alien-3.jpg";
import memento1 from "./pictures/memento/memento-1.jpeg";
import memento2 from "./pictures/memento/memento-2.jpeg";
import memento3 from "./pictures/memento/memento-3.jpeg";
import caddyshack1 from "./pictures/caddyshack/caddyshack-1.jpg";
import caddyshack2 from "./pictures/caddyshack/caddyshack-2.jpg";
import caddyshack3 from "./pictures/caddyshack/caddyshack-3.jpg";
import backToSchool1 from "./pictures/back-to-school/back-to-school-1.jpg";
import backToSchool2 from "./pictures/back-to-school/back-to-school-2.jpg";
import backToSchool3 from "./pictures/back-to-school/back-to-school-3.jpg";
import encinoMan1 from "./pictures/encino-man/encino-man-1.jpg";
import encinoMan2 from "./pictures/encino-man/encino-man-2.jpg";
import encinoMan3 from "./pictures/encino-man/encino-man-3.jpg";
import blastFromThePast1 from "./pictures/blast-from-the-past/blast-from-the-past-1.jpg";
import blastFromThePast2 from "./pictures/blast-from-the-past/blast-from-the-past-2.jpg";
import blastFromThePast3 from "./pictures/blast-from-the-past/blast-from-the-past-3.jpg";
import apocalypseNow1 from "./pictures/apocalypse-now/apocalypse-now-1.jpg";
import apocalypseNow2 from "./pictures/apocalypse-now/apocalypse-now-2.jpg";
import apocalypseNow3 from "./pictures/apocalypse-now/apocalypse-now-3.jpg";
import bedazzled1 from "./pictures/bedazzled/bedazzled-1.jpg";
import bedazzled2 from "./pictures/bedazzled/bedazzled-2.jpg";
import bedazzled3 from "./pictures/bedazzled/bedazzled-3.jpg";
import lebenAnderen1 from "./pictures/lives-of-others/lives-of-others-1.jpeg";
import lebenAnderen2 from "./pictures/lives-of-others/lives-of-others-2.jpeg";
import lebenAnderen3 from "./pictures/lives-of-others/lives-of-others-3.jpeg";
import django1 from "./pictures/django-unchained/django-unchained-1.jpg";
import django2 from "./pictures/django-unchained/django-unchained-2.jpg";
import django3 from "./pictures/django-unchained/django-unchained-3.jpg";
import inglouriousBasterds1 from "./pictures/inglourious-basterds/inglourious-1.jpg";
import inglouriousBasterds2 from "./pictures/inglourious-basterds/inglourious-2.jpg";
import inglouriousBasterds3 from "./pictures/inglourious-basterds/inglourious-3.jpg";
import shining1 from "./pictures/the-shining/shining-1.jpeg";
import shining2 from "./pictures/the-shining/shining-2.jpeg";
import shining3 from "./pictures/the-shining/shining-3.jpeg";
import walle1 from "./pictures/walle/walle-1.jpeg";
import walle2 from "./pictures/walle/walle-2.jpeg";
import walle3 from "./pictures/walle/walle-3.jpeg";
import bigEyes1 from "./pictures/big-eyes/big-eyes-1.jpg";
import bigEyes2 from "./pictures/big-eyes/big-eyes-2.jpg";
import bigEyes3 from "./pictures/big-eyes/big-eyes-3.jpg";
import madMax1 from "./pictures/mad-max/mad-max-1.jpeg";
import madMax2 from "./pictures/mad-max/mad-max-2.jpeg";
import madMax3 from "./pictures/mad-max/mad-max-3.jpeg";
import mono1 from "./pictures/princess-mononoke/mono-1.jpeg";
import mono2 from "./pictures/princess-mononoke/mono-2.jpeg";
import mono3 from "./pictures/princess-mononoke/mono-3.jpeg";
import maximumOverdrive1 from "./pictures/maximum-overdrive/maximum-overdrive-1.jpeg";
import maximumOverdrive2 from "./pictures/maximum-overdrive/maximum-overdrive-2.jpeg";
import maximumOverdrive3 from "./pictures/maximum-overdrive/maximum-overdrive-3.jpeg";
import overTheTop1 from "./pictures/over-the-top/over-the-top-1.jpeg";
import overTheTop2 from "./pictures/over-the-top/over-the-top-2.jpeg";
import overTheTop3 from "./pictures/over-the-top/over-the-top-3.jpeg";
import whatWomenWant1 from "./pictures/what-women-want/what-women-want-1.jpeg";
import whatWomenWant2 from "./pictures/what-women-want/what-women-want-2.jpeg";
import whatWomenWant3 from "./pictures/what-women-want/what-women-want-3.jpeg";
import homeAlone1 from "./pictures/home-alone/home-alone-1.jpg";
import homeAlone2 from "./pictures/home-alone/home-alone-2.jpg";
import homeAlone3 from "./pictures/home-alone/home-alone-3.jpg";
import beerfest1 from "./pictures/beerfest/beerfest-1.jpg";
import beerfest2 from "./pictures/beerfest/beerfest-2.jpg";
import beerfest3 from "./pictures/beerfest/beerfest-3.jpg";
import weddingCrashers1 from "./pictures/wedding-crashers/wedding-crashers-1.jpeg";
import weddingCrashers2 from "./pictures/wedding-crashers/wedding-crashers-2.jpeg";
import weddingCrashers3 from "./pictures/wedding-crashers/wedding-crashers-3.jpeg";
import oldSchool1 from "./pictures/old-school/old-school-1.jpeg";
import oldSchool2 from "./pictures/old-school/old-school-2.jpeg";
import oldSchool3 from "./pictures/old-school/old-school-3.jpeg";
import dodgeball1 from "./pictures/dodgeball/dodgeball-1.jpg";
import dodgeball2 from "./pictures/dodgeball/dodgeball-2.jpg";
import dodgeball3 from "./pictures/dodgeball/dodgeball-3.jpg";
import graduate1 from "./pictures/the-graduate/graduate-1.jpeg";
import graduate2 from "./pictures/the-graduate/graduate-2.jpeg";
import graduate3 from "./pictures/the-graduate/graduate-3.jpeg";
import mtp1 from "./pictures/meet-the-parents/mtp-1.jpeg";
import mtp2 from "./pictures/meet-the-parents/mtp-2.jpeg";
import mtp3 from "./pictures/meet-the-parents/mtp-3.jpeg";
import mtf1 from "./pictures/meet-the-fockers/mtf-1.jpeg";
import mtf2 from "./pictures/meet-the-fockers/mtf-2.jpeg";
import mtf3 from "./pictures/meet-the-fockers/mtf-3.jpeg";
import littleFockers1 from "./pictures/little-fockers/little-fockers-1.jpeg";
import littleFockers2 from "./pictures/little-fockers/little-fockers-2.jpeg";
import littleFockers3 from "./pictures/little-fockers/little-fockers-3.jpeg";
import gwh1 from "./pictures/good-will-hunting/good-will-hunting-1.jpg";
import gwh2 from "./pictures/good-will-hunting/good-will-hunting-2.jpg";
import gwh3 from "./pictures/good-will-hunting/good-will-hunting-3.jpg";
import requiem1 from "./pictures/requiem-for-a-dream/requiem-1.jpeg";
import requiem2 from "./pictures/requiem-for-a-dream/requiem-2.jpeg";
import requiem3 from "./pictures/requiem-for-a-dream/requiem-3.jpeg";
import spaceOdyssey1 from "./pictures/2001/space-odyssey-1.jpeg";
import spaceOdyssey2 from "./pictures/2001/space-odyssey-2.jpeg";
import spaceOdyssey3 from "./pictures/2001/space-odyssey-3.jpeg";
import jaws1 from "./pictures/jaws/jaws-1.jpg";
import jaws2 from "./pictures/jaws/jaws-2.jpg";
import jaws3 from "./pictures/jaws/jaws-3.jpg";
import riversEdge1 from "./pictures/rivers-edge/rivers-edge-1.jpeg";
import riversEdge2 from "./pictures/rivers-edge/rivers-edge-2.jpeg";
import riversEdge3 from "./pictures/rivers-edge/rivers-edge-3.jpeg";
import citizenKane1 from "./pictures/citizen-kane/citizen-kane-1.jpg";
import citizenKane2 from "./pictures/citizen-kane/citizen-kane-2.jpg";
import citizenKane3 from "./pictures/citizen-kane/citizen-kane-3.jpg";
import amelie1 from "./pictures/amelie/amelie-1.jpg";
import amelie2 from "./pictures/amelie/amelie-2.jpg";
import amelie3 from "./pictures/amelie/amelie-3.jpg";
import bigLebowski1 from "./pictures/big-lebowski/big-lebowski-1.jpg";
import bigLebowski2 from "./pictures/big-lebowski/big-lebowski-2.jpg";
import bigLebowski3 from "./pictures/big-lebowski/big-lebowski-3.jpg";
import whatAboutBob1 from "./pictures/what-about-bob/what-about-bob-1.jpeg";
import whatAboutBob2 from "./pictures/what-about-bob/what-about-bob-2.jpeg";
import whatAboutBob3 from "./pictures/what-about-bob/what-about-bob-3.jpeg";
import pines1 from "./pictures/place-beyond-pines/pines-1.jpeg";
import pines2 from "./pictures/place-beyond-pines/pines-2.jpeg";
import pines3 from "./pictures/place-beyond-pines/pines-3.jpeg";
import slamminSalmon1 from "./pictures/slammin-salmon/slammin-salmon-1.jpeg";
import slamminSalmon2 from "./pictures/slammin-salmon/slammin-salmon-2.jpeg";
import slamminSalmon3 from "./pictures/slammin-salmon/slammin-salmon-3.jpeg";
import clubDread1 from "./pictures/club-dread/club-dread-1.jpg";
import clubDread2 from "./pictures/club-dread/club-dread-2.jpg";
import clubDread3 from "./pictures/club-dread/club-dread-3.jpg";
import looper1 from "./pictures/looper/looper-1.jpeg";
import looper2 from "./pictures/looper/looper-2.jpeg";
import looper3 from "./pictures/looper/looper-3.jpeg";
import inTime1 from "./pictures/in-time/in-time-1.jpg";
import inTime2 from "./pictures/in-time/in-time-2.jpg";
import inTime3 from "./pictures/in-time/in-time-3.jpg";
import diaryOfTheDead1 from "./pictures/diary-of-the-dead/diary-of-the-dead-1.jpg";
import diaryOfTheDead2 from "./pictures/diary-of-the-dead/diary-of-the-dead-2.jpg";
import diaryOfTheDead3 from "./pictures/diary-of-the-dead/diary-of-the-dead-3.jpg";
import returnOfDead1 from "./pictures/return-of-dead/return-of-dead-1.jpeg";
import returnOfDead2 from "./pictures/return-of-dead/return-of-dead-2.jpeg";
import returnOfDead3 from "./pictures/return-of-dead/return-of-dead-3.jpeg";
import twelveMonkeys1 from "./pictures/twelve-monkeys/twelve-monkeys-1.jpeg";
import twelveMonkeys2 from "./pictures/twelve-monkeys/twelve-monkeys-2.jpeg";
import twelveMonkeys3 from "./pictures/twelve-monkeys/twelve-monkeys-3.jpeg";
import armageddon1 from "./pictures/armageddon/armageddon-1.jpg";
import armageddon2 from "./pictures/armageddon/armageddon-2.jpg";
import armageddon3 from "./pictures/armageddon/armageddon-3.jpg";
import deepImpact1 from "./pictures/deep-impact/deep-impact-1.jpg";
import deepImpact2 from "./pictures/deep-impact/deep-impact-2.jpg";
import deepImpact3 from "./pictures/deep-impact/deep-impact-3.jpg";
import speed1 from "./pictures/speed/speed-1.jpeg";
import speed2 from "./pictures/speed/speed-2.jpeg";
import speed3 from "./pictures/speed/speed-3.jpeg";
import ted1 from "./pictures/ted/ted-1.jpeg";
import ted2 from "./pictures/ted/ted-2.jpeg";
import ted3 from "./pictures/ted/ted-3.jpeg";
import ted21 from "./pictures/ted-2/ted2-1.jpeg";
import ted22 from "./pictures/ted-2/ted2-2.jpeg";
import ted23 from "./pictures/ted-2/ted2-3.jpeg";
import million1 from "./pictures/million-ways-to-die/million-1.jpeg";
import million2 from "./pictures/million-ways-to-die/million-2.jpeg";
import million3 from "./pictures/million-ways-to-die/million-3.jpeg";
import sixthSense1 from "./pictures/sixth-sense/sixth-sense-1.jpeg";
import sixthSense2 from "./pictures/sixth-sense/sixth-sense-2.jpeg";
import sixthSense3 from "./pictures/sixth-sense/sixth-sense-3.jpeg";
import signs1 from "./pictures/signs/signs-1.jpeg";
import signs2 from "./pictures/signs/signs-2.jpeg";
import signs3 from "./pictures/signs/signs-3.jpeg";
import blueStreak1 from "./pictures/blue-streak/blue-streak-1.jpg";
import blueStreak2 from "./pictures/blue-streak/blue-streak-2.jpg";
import blueStreak3 from "./pictures/blue-streak/blue-streak-3.jpg";
import loose1 from "./pictures/every-which-way-but-loose/loose-1.jpg";
import loose2 from "./pictures/every-which-way-but-loose/loose-2.jpg";
import loose3 from "./pictures/every-which-way-but-loose/loose-3.jpg";
import can1 from "./pictures/any-which-way-you-can/can-1.jpg";
import can2 from "./pictures/any-which-way-you-can/can-2.jpg";
import can3 from "./pictures/any-which-way-you-can/can-3.jpg";
import doubleImpact1 from "./pictures/double-impact/double-impact-1.jpg";
import doubleImpact2 from "./pictures/double-impact/double-impact-2.jpg";
import doubleImpact3 from "./pictures/double-impact/double-impact-3.jpg";
import streetFighter1 from "./pictures/street-fighter/street-fighter-1.jpeg";
import streetFighter2 from "./pictures/street-fighter/street-fighter-2.jpeg";
import streetFighter3 from "./pictures/street-fighter/street-fighter-3.jpeg";
import realSteel1 from "./pictures/real-steel/real-steel-1.jpeg";
import realSteel2 from "./pictures/real-steel/real-steel-2.jpeg";
import realSteel3 from "./pictures/real-steel/real-steel-3.jpeg";
import originsWolverine1 from "./pictures/xmen-origins-wolverine/origins-wolverine-1.jpeg";
import originsWolverine2 from "./pictures/xmen-origins-wolverine/origins-wolverine-2.jpeg";
import originsWolverine3 from "./pictures/xmen-origins-wolverine/origins-wolverine-3.jpeg";
import xmenOrigins1 from "./pictures/xmen-origins/xmen-origins-1.jpeg";
import xmenOrigins2 from "./pictures/xmen-origins/xmen-origins-2.jpeg";
import xmenOrigins3 from "./pictures/xmen-origins/xmen-origins-3.jpeg";
import ava1 from "./pictures/ava/ava-1.jpeg";
import ava2 from "./pictures/ava/ava-2.jpeg";
import ava3 from "./pictures/ava/ava-3.jpeg";
import billionaire1 from "./pictures/billionaire-boys/billionaire-boys-1.jpg";
import billionaire2 from "./pictures/billionaire-boys/billionaire-boys-2.jpg";
import billionaire3 from "./pictures/billionaire-boys/billionaire-boys-3.jpg";
import bombshell1 from "./pictures/bombshell/bombshell-1.jpg";
import bombshell2 from "./pictures/bombshell/bombshell-2.jpg";
import bombshell3 from "./pictures/bombshell/bombshell-3.jpg";
import holidayWild1 from "./pictures/holiday-wild/holiday-wild-1.jpg";
import holidayWild2 from "./pictures/holiday-wild/holiday-wild-2.jpg";
import holidayWild3 from "./pictures/holiday-wild/holiday-wild-3.jpg";
import holidate1 from "./pictures/holidate/holidate-1.jpg";
import holidate2 from "./pictures/holidate/holidate-2.jpg";
import holidate3 from "./pictures/holidate/holidate-3.jpg";
import professorMadman1 from "./pictures/professor-madman/prof-madman-1.jpeg";
import professorMadman2 from "./pictures/professor-madman/prof-madman-2.jpeg";
import professorMadman3 from "./pictures/professor-madman/prof-madman-3.jpeg";
import blockers1 from "./pictures/blockers/blockers-1.jpg";
import blockers2 from "./pictures/blockers/blockers-2.jpg";
import blockers3 from "./pictures/blockers/blockers-3.jpg";
import disasterArtist1 from "./pictures/the-disaster-artist/the-disaster-artist-1.jpeg";
import disasterArtist2 from "./pictures/the-disaster-artist/the-disaster-artist-2.jpeg";
import disasterArtist3 from "./pictures/the-disaster-artist/the-disaster-artist-3.jpeg";
import funnyPeople1 from "./pictures/funny-people/funny-people-1.jpg";
import funnyPeople2 from "./pictures/funny-people/funny-people-2.jpg";
import funnyPeople3 from "./pictures/funny-people/funny-people-3.jpg";
import drillbit1 from "./pictures/drillbit-taylor/drillbit-1.jpg";
import drillbit2 from "./pictures/drillbit-taylor/drillbit-2.jpg";
import drillbit3 from "./pictures/drillbit-taylor/drillbit-3.jpg";
import princessBride1 from "./pictures/princess-bride/princess-bride-1.jpeg";
import princessBride2 from "./pictures/princess-bride/princess-bride-2.jpeg";
import princessBride3 from "./pictures/princess-bride/princess-bride-3.jpeg";
import clerks1 from "./pictures/clerks/clerks-1.jpg";
import clerks2 from "./pictures/clerks/clerks-2.jpg";
import clerks3 from "./pictures/clerks/clerks-3.jpg";
import clerks21 from "./pictures/clerks-2/clerks-21.jpg";
import clerks22 from "./pictures/clerks-2/clerks-22.jpg";
import clerks23 from "./pictures/clerks-2/clerks-23.jpg";
import montyPython1 from "./pictures/monty-python/monty-python-1.jpeg";
import montyPython2 from "./pictures/monty-python/monty-python-2.jpeg";
import montyPython3 from "./pictures/monty-python/monty-python-3.jpeg";
import blazingSaddles1 from "./pictures/blazing-saddles/blazing-saddles-1.jpg";
import blazingSaddles2 from "./pictures/blazing-saddles/blazing-saddles-2.jpg";
import blazingSaddles3 from "./pictures/blazing-saddles/blazing-saddles-3.jpg";
import willyWonka1 from "./pictures/willy-wonka/willy-wonka-1.jpeg";
import willyWonka2 from "./pictures/willy-wonka/willy-wonka-2.jpeg";
import willyWonka3 from "./pictures/willy-wonka/willy-wonka-3.jpeg";
import alcatraz1 from "./pictures/escape-from-alcatraz/alcatraz-1.jpg";
import alcatraz2 from "./pictures/escape-from-alcatraz/alcatraz-2.jpg";
import alcatraz3 from "./pictures/escape-from-alcatraz/alcatraz-3.jpg";
import warriors1 from "./pictures/the-warriors/the-warriors-1.jpeg";
import warriors2 from "./pictures/the-warriors/the-warriors-2.jpeg";
import warriors3 from "./pictures/the-warriors/the-warriors-3.jpeg";
import waynesWorld1 from "./pictures/waynes-world/waynes-world-1.jpeg";
import waynesWorld2 from "./pictures/waynes-world/waynes-world-2.jpeg";
import waynesWorld3 from "./pictures/waynes-world/waynes-world-3.jpeg";
import waynesWorld21 from "./pictures/waynes-world-2/waynes-world-21.jpeg";
import waynesWorld22 from "./pictures/waynes-world-2/waynes-world-22.jpeg";
import waynesWorld23 from "./pictures/waynes-world-2/waynes-world-23.jpeg";
import dazedAndConfused1 from "./pictures/dazed-and-confused/dazed-1.jpg";
import dazedAndConfused2 from "./pictures/dazed-and-confused/dazed-2.jpg";
import dazedAndConfused3 from "./pictures/dazed-and-confused/dazed-3.jpg";
import donnieDarko1 from "./pictures/donnie-darko/donnie-darko-1.jpg";
import donnieDarko2 from "./pictures/donnie-darko/donnie-darko-2.jpg";
import donnieDarko3 from "./pictures/donnie-darko/donnie-darko-3.jpg";
import misery1 from "./pictures/misery/misery-1.jpeg";
import misery2 from "./pictures/misery/misery-2.jpeg";
import misery3 from "./pictures/misery/misery-3.jpeg";
import deadZone1 from "./pictures/the-dead-zone/dead-zone-1.jpeg";
import deadZone2 from "./pictures/the-dead-zone/dead-zone-2.jpeg";
import deadZone3 from "./pictures/the-dead-zone/dead-zone-3.jpeg";
import up1 from "./pictures/up/up-1.jpeg";
import up2 from "./pictures/up/up-2.jpeg";
import up3 from "./pictures/up/up-3.jpeg";
import arabia1 from "./pictures/lawrence-of-arabia/arabia-1.jpeg";
import arabia2 from "./pictures/lawrence-of-arabia/arabia-2.jpeg";
import arabia3 from "./pictures/lawrence-of-arabia/arabia-3.jpeg";
import jungleBook1 from "./pictures/jungle-book/jungle-book-1.jpg";
import jungleBook2 from "./pictures/jungle-book/jungle-book-2.jpg";
import jungleBook3 from "./pictures/jungle-book/jungle-book-3.jpg";
import dundee1 from "./pictures/crocodile-dundee/dundee-1.jpg";
import dundee2 from "./pictures/crocodile-dundee/dundee-2.jpg";
import dundee3 from "./pictures/crocodile-dundee/dundee-3.jpg";
import dundee21 from "./pictures/crocodile-dundee-2/dundee-21.jpg";
import dundee22 from "./pictures/crocodile-dundee-2/dundee-22.jpg";
import dundee23 from "./pictures/crocodile-dundee-2/dundee-23.jpg";
import groundhogDay1 from "./pictures/groundhog-day/groundhog-day-1.jpg";
import groundhogDay2 from "./pictures/groundhog-day/groundhog-day-2.jpg";
import groundhogDay3 from "./pictures/groundhog-day/groundhog-day-3.jpg";
import starTrek1 from "./pictures/star-trek/star-trek-1.jpeg";
import starTrek2 from "./pictures/star-trek/star-trek-2.jpeg";
import starTrek3 from "./pictures/star-trek/star-trek-3.jpeg";
import grandmasBoy1 from "./pictures/grandmas-boy/grandmas-boy-1.jpg";
import grandmasBoy2 from "./pictures/grandmas-boy/grandmas-boy-2.jpg";
import grandmasBoy3 from "./pictures/grandmas-boy/grandmas-boy-3.jpg";
import bloodDiamond1 from "./pictures/blood-diamond/blood-diamond-1.jpg";
import bloodDiamond2 from "./pictures/blood-diamond/blood-diamond-2.jpg";
import bloodDiamond3 from "./pictures/blood-diamond/blood-diamond-3.jpg";
import musicAndLyrics1 from "./pictures/music-and-lyrics/music-and-lyrics-1.jpeg";
import musicAndLyrics2 from "./pictures/music-and-lyrics/music-and-lyrics-2.jpeg";
import musicAndLyrics3 from "./pictures/music-and-lyrics/music-and-lyrics-3.jpeg";
import goingTheDistance1 from "./pictures/going-the-distance/going-the-distance-1.jpg";
import goingTheDistance2 from "./pictures/going-the-distance/going-the-distance-2.jpg";
import goingTheDistance3 from "./pictures/going-the-distance/going-the-distance-3.jpg";
import httm21 from "./pictures/hot-tub-time-machine-2/httm21.jpg";
import httm22 from "./pictures/hot-tub-time-machine-2/httm22.jpg";
import httm23 from "./pictures/hot-tub-time-machine-2/httm23.jpg";
import hangover1 from "./pictures/the-hangover/the-hangover-1.jpeg";
import hangover2 from "./pictures/the-hangover/the-hangover-2.jpeg";
import hangover3 from "./pictures/the-hangover/the-hangover-3.jpeg";
import doctorStrange1 from "./pictures/doctor-strange/strange-1.jpg";
import doctorStrange2 from "./pictures/doctor-strange/strange-2.jpg";
import doctorStrange3 from "./pictures/doctor-strange/strange-3.jpg";
import blackPanther1 from "./pictures/black-panther/black-panther-1.jpg";
import blackPanther2 from "./pictures/black-panther/black-panther-2.jpg";
import blackPanther3 from "./pictures/black-panther/black-panther-3.jpg";
import wilderPeople1 from "./pictures/hunt-wilderpeople/hunt-wilderpeople-1.jpg";
import wilderPeople2 from "./pictures/hunt-wilderpeople/hunt-wilderpeople-2.jpg";
import wilderPeople3 from "./pictures/hunt-wilderpeople/hunt-wilderpeople-3.jpg";
import markedForDeath1 from "./pictures/marked-4-death/marked-4-death-1.jpeg";
import markedForDeath2 from "./pictures/marked-4-death/marked-4-death-2.jpeg";
import markedForDeath3 from "./pictures/marked-4-death/marked-4-death-3.jpeg";
import neverWoman1 from "./pictures/never-woman/never-woman-1.jpeg";
import neverWoman2 from "./pictures/never-woman/never-woman-2.jpeg";
import neverWoman3 from "./pictures/never-woman/never-woman-3.jpeg";
import piratesCarb1 from "./pictures/pirates-carb-4/pirates-carb-4-1.jpeg";
import piratesCarb2 from "./pictures/pirates-carb-4/pirates-carb-4-2.jpeg";
import piratesCarb3 from "./pictures/pirates-carb-4/pirates-carb-4-3.jpeg";
import predator21 from "./pictures/predator-2/predator-2-1.jpeg";
import predator22 from "./pictures/predator-2/predator-2-2.jpeg";
import predator23 from "./pictures/predator-2/predator-2-3.jpeg";
import riki1 from "./pictures/riki-oh/riki-oh-1.jpeg";
import riki2 from "./pictures/riki-oh/riki-oh-2.jpeg";
import riki3 from "./pictures/riki-oh/riki-oh-3.jpeg";
import totalRecall1 from "./pictures/total-recall-90/total-recall-90-1.jpeg";
import totalRecall2 from "./pictures/total-recall-90/total-recall-90-2.jpeg";
import totalRecall3 from "./pictures/total-recall-90/total-recall-90-3.jpeg";
import underSiege1 from "./pictures/under-siege/under-siege-1.jpeg";
import underSiege2 from "./pictures/under-siege/under-siege-2.jpeg";
import underSiege3 from "./pictures/under-siege/under-siege-3.jpeg";
import underSiege21 from "./pictures/under-siege-2/under-siege-2-1.jpeg";
import underSiege22 from "./pictures/under-siege-2/under-siege-2-2.jpeg";
import underSiege23 from "./pictures/under-siege-2/under-siege-2-3.jpeg";
import casinoRoyale1 from "./pictures/casino-royale/casino-royale-1.jpg";
import casinoRoyale2 from "./pictures/casino-royale/casino-royale-2.jpg";
import casinoRoyale3 from "./pictures/casino-royale/casino-royale-3.jpg";
import trainingDay1 from "./pictures/training-day/training-day-1.jpeg";
import trainingDay2 from "./pictures/training-day/training-day-2.jpeg";
import trainingDay3 from "./pictures/training-day/training-day-3.jpeg";
import menOfHonor1 from "./pictures/men-of-honor/men-of-honor-1.jpeg";
import menOfHonor2 from "./pictures/men-of-honor/men-of-honor-2.jpeg";
import menOfHonor3 from "./pictures/men-of-honor/men-of-honor-3.jpeg";
import indDay1 from "./pictures/independence-day/ind-day-1.jpg";
import indDay2 from "./pictures/independence-day/ind-day-2.jpg";
import indDay3 from "./pictures/independence-day/ind-day-3.jpg";
import eurotrip1 from "./pictures/eurotrip/eurotrip-1.jpg";
import eurotrip2 from "./pictures/eurotrip/eurotrip-2.jpg";
import eurotrip3 from "./pictures/eurotrip/eurotrip-3.jpg";
import transporter21 from "./pictures/transporter-2/transporter-21.jpeg";
import transporter22 from "./pictures/transporter-2/transporter-22.jpeg";
import transporter23 from "./pictures/transporter-2/transporter-23.jpeg";
import jerry1 from "./pictures/jerry-maguire/jerry-1.jpg";
import jerry2 from "./pictures/jerry-maguire/jerry-2.jpg";
import jerry3 from "./pictures/jerry-maguire/jerry-3.jpg";
import meanStreets1 from "./pictures/mean-streets/mean-streets-1.jpeg";
import meanStreets2 from "./pictures/mean-streets/mean-streets-2.jpeg";
import meanStreets3 from "./pictures/mean-streets/mean-streets-3.jpeg";
import warrior1 from "./pictures/warrior/warrior-1.jpeg";
import warrior2 from "./pictures/warrior/warrior-2.jpeg";
import warrior3 from "./pictures/warrior/warrior-3.jpeg";
import projectKill1 from "./pictures/project-kill/project-kill-1.jpeg";
import projectKill2 from "./pictures/project-kill/project-kill-2.jpeg";
import projectKill3 from "./pictures/project-kill/project-kill-3.jpeg";
import batmanBegins1 from "./pictures/batman-begins/batman-begins-1.jpg";
import batmanBegins2 from "./pictures/batman-begins/batman-begins-2.jpg";
import batmanBegins3 from "./pictures/batman-begins/batman-begins-3.jpg";
import darkKnightRises1 from "./pictures/dark-knight-rises/dark-knight-rises-1.jpg";
import darkKnightRises2 from "./pictures/dark-knight-rises/dark-knight-rises-2.jpg";
import darkKnightRises3 from "./pictures/dark-knight-rises/dark-knight-rises-3.jpg";
import monstersInc1 from "./pictures/monsters-inc/monsters-inc-1.jpeg";
import monstersInc2 from "./pictures/monsters-inc/monsters-inc-2.jpeg";
import monstersInc3 from "./pictures/monsters-inc/monsters-inc-3.jpeg";
import stepBrothers1 from "./pictures/step-brothers/step-brothers-1.jpeg";
import stepBrothers2 from "./pictures/step-brothers/step-brothers-2.jpeg";
import stepBrothers3 from "./pictures/step-brothers/step-brothers-3.jpeg";
import bb1 from "./pictures/benjamin-button/bb-1.jpg";
import bb2 from "./pictures/benjamin-button/bb-2.jpg";
import bb3 from "./pictures/benjamin-button/bb-3.jpg";
import zombieland21 from "./pictures/zombieland-double-tap/double-tap-1.jpeg";
import zombieland22 from "./pictures/zombieland-double-tap/double-tap-2.jpeg";
import zombieland23 from "./pictures/zombieland-double-tap/double-tap-3.jpeg";
import threeHundred1 from "./pictures/300/300-1.jpeg";
import threeHundred2 from "./pictures/300/300-2.jpeg";
import threeHundred3 from "./pictures/300/300-3.jpeg";
import legend1 from "./pictures/I-am-legend/legend-1.jpg";
import legend2 from "./pictures/I-am-legend/legend-2.jpg";
import legend3 from "./pictures/I-am-legend/legend-3.jpg";
import easternPromises1 from "./pictures/eastern-promises/eastern-promises-1.jpg";
import easternPromises2 from "./pictures/eastern-promises/eastern-promises-2.jpg";
import easternPromises3 from "./pictures/eastern-promises/eastern-promises-3.jpg";
import pineapple1 from "./pictures/pineapple-express/pineapple-1.jpeg";
import pineapple2 from "./pictures/pineapple-express/pineapple-2.jpeg";
import pineapple3 from "./pictures/pineapple-express/pineapple-3.jpeg";
import catch1 from "./pictures/catch-me/catch-1.jpg";
import catch2 from "./pictures/catch-me/catch-2.jpg";
import catch3 from "./pictures/catch-me/catch-3.jpg";
import inBruges1 from "./pictures/in-bruges/bruges-1.jpg";
import inBruges2 from "./pictures/in-bruges/bruges-2.jpg";
import inBruges3 from "./pictures/in-bruges/bruges-3.jpg";
import pacificRim1 from "./pictures/pacific-rim/pacific-1.jpeg";
import pacificRim2 from "./pictures/pacific-rim/pacific-2.jpeg";
import pacificRim3 from "./pictures/pacific-rim/pacific-3.jpeg";
import nightcrawler1 from "./pictures/nightcrawler/nightcrawler-1.jpeg";
import nightcrawler2 from "./pictures/nightcrawler/nightcrawler-2.jpeg";
import nightcrawler3 from "./pictures/nightcrawler/nightcrawler-3.jpeg";
import castaway1 from "./pictures/castaway/castaway-1.jpg";
import castaway2 from "./pictures/castaway/castaway-2.jpg";
import castaway3 from "./pictures/castaway/castaway-3.jpg";
import revolver1 from "./pictures/revolver/revolver-1.jpeg";
import revolver2 from "./pictures/revolver/revolver-2.jpeg";
import revolver3 from "./pictures/revolver/revolver-3.jpeg";
import perfectStranger1 from "./pictures/perfect-stranger/perfect-stranger-1.jpeg";
import perfectStranger2 from "./pictures/perfect-stranger/perfect-stranger-2.jpeg";
import perfectStranger3 from "./pictures/perfect-stranger/perfect-stranger-3.jpeg";
import walk1 from "./pictures/walk-to-remember/walk-1.jpeg";
import walk2 from "./pictures/walk-to-remember/walk-2.jpeg";
import walk3 from "./pictures/walk-to-remember/walk-3.jpeg";
import notebook1 from "./pictures/the-notebook/notebook-1.jpeg";
import notebook2 from "./pictures/the-notebook/notebook-2.jpeg";
import notebook3 from "./pictures/the-notebook/notebook-3.jpeg";
import hateYou1 from "./pictures/10-things/hate-you-1.jpeg";
import hateYou2 from "./pictures/10-things/hate-you-2.jpeg";
import hateYou3 from "./pictures/10-things/hate-you-3.jpeg";
import titans1 from "./pictures/remember-the-titans/titans-1.jpeg";
import titans2 from "./pictures/remember-the-titans/titans-2.jpeg";
import titans3 from "./pictures/remember-the-titans/titans-3.jpeg";
import rudy1 from "./pictures/rudy/rudy-1.jpeg";
import rudy2 from "./pictures/rudy/rudy-2.jpeg";
import rudy3 from "./pictures/rudy/rudy-3.jpeg";
import coachCarter1 from "./pictures/coach-carter/coach-carter-1.jpg";
import coachCarter2 from "./pictures/coach-carter/coach-carter-2.jpg";
import coachCarter3 from "./pictures/coach-carter/coach-carter-3.jpg";
import titanic1 from "./pictures/titanic/titanic-1.jpeg";
import titanic2 from "./pictures/titanic/titanic-2.jpeg";
import titanic3 from "./pictures/titanic/titanic-3.jpeg";
import devilsAdvocate1 from "./pictures/devils-advocate/advocate-1.jpg";
import devilsAdvocate2 from "./pictures/devils-advocate/advocate-2.jpg";
import devilsAdvocate3 from "./pictures/devils-advocate/advocate-3.jpg";
import iRobot1 from "./pictures/I-robot/robot-1.jpg";
import iRobot2 from "./pictures/I-robot/robot-2.jpg";
import iRobot3 from "./pictures/I-robot/robot-3.jpg";
import happyness1 from "./pictures/pursuit-of-happyness/happyness-1.jpeg";
import happyness2 from "./pictures/pursuit-of-happyness/happyness-2.jpeg";
import happyness3 from "./pictures/pursuit-of-happyness/happyness-3.jpeg";
import butterfly1 from "./pictures/butterfly-effect/butterfly-1.jpg";
import butterfly2 from "./pictures/butterfly-effect/butterfly-2.jpg";
import butterfly3 from "./pictures/butterfly-effect/butterfly-3.jpg";
import philadelphia1 from "./pictures/philadelphia/philadelphia-1.jpeg";
import philadelphia2 from "./pictures/philadelphia/philadelphia-2.jpeg";
import philadelphia3 from "./pictures/philadelphia/philadelphia-3.jpeg";
import seconds1 from "./pictures/gone-in-sixty-seconds/seconds-1.jpg";
import seconds2 from "./pictures/gone-in-sixty-seconds/seconds-2.jpg";
import seconds3 from "./pictures/gone-in-sixty-seconds/seconds-3.jpg";
import troy1 from "./pictures/troy/troy-1.jpeg";
import troy2 from "./pictures/troy/troy-2.jpeg";
import troy3 from "./pictures/troy/troy-3.jpeg";
import deadMansChest1 from "./pictures/pirates-dead-mans/dead-man-1.jpeg";
import deadMansChest2 from "./pictures/pirates-dead-mans/dead-man-2.jpeg";
import deadMansChest3 from "./pictures/pirates-dead-mans/dead-man-3.jpeg";
import seventeenAgain1 from "./pictures/seventeen-again/seventeen-1.jpeg";
import seventeenAgain2 from "./pictures/seventeen-again/seventeen-2.jpeg";
import seventeenAgain3 from "./pictures/seventeen-again/seventeen-3.jpeg";
import mickeyBlue1 from "./pictures/mickey-blue-eyes/mickey-1.jpeg";
import mickeyBlue2 from "./pictures/mickey-blue-eyes/mickey-2.jpeg";
import mickeyBlue3 from "./pictures/mickey-blue-eyes/mickey-3.jpeg";
import taken31 from "./pictures/taken-3/taken-31.jpeg";
import taken32 from "./pictures/taken-3/taken-32.jpeg";
import taken33 from "./pictures/taken-3/taken-33.jpeg";
import inbetweeners1 from "./pictures/inbetweeners/inbetweeners-1.jpg";
import inbetweeners2 from "./pictures/inbetweeners/inbetweeners-2.jpg";
import inbetweeners3 from "./pictures/inbetweeners/inbetweeners-3.jpg";
import munich1 from "./pictures/munich/munich-1.jpeg";
import munich2 from "./pictures/munich/munich-2.jpeg";
import munich3 from "./pictures/munich/munich-3.jpeg";
import majestyk1 from "./pictures/mr-majestyk/majestyk-1.jpeg";
import majestyk2 from "./pictures/mr-majestyk/majestyk-2.jpeg";
import majestyk3 from "./pictures/mr-majestyk/majestyk-3.jpeg";
import disturbia1 from "./pictures/disturbia/disturbia-1.jpg";
import disturbia2 from "./pictures/disturbia/disturbia-2.jpg";
import disturbia3 from "./pictures/disturbia/disturbia-3.jpg";
import weirdScience1 from "./pictures/weird-science/weird-science-1.jpeg";
import weirdScience2 from "./pictures/weird-science/weird-science-2.jpeg";
import weirdScience3 from "./pictures/weird-science/weird-science-3.jpeg";
import gottaGive1 from "./pictures/somethings-gotta-give/give-1.jpeg";
import gottaGive2 from "./pictures/somethings-gotta-give/give-2.jpeg";
import gottaGive3 from "./pictures/somethings-gotta-give/give-3.jpeg";
import complications1 from "./pictures/its-complicated/complications-1.jpg";
import complications2 from "./pictures/its-complicated/complications-2.jpg";
import complications3 from "./pictures/its-complicated/complications-3.jpg";
import billyMadison1 from "./pictures/billy-madison/billy-madison-1.jpg";
import billyMadison2 from "./pictures/billy-madison/billy-madison-2.jpg";
import billyMadison3 from "./pictures/billy-madison/billy-madison-3.jpg";
import happyGilmore1 from "./pictures/happy-gilmore/happy-gilmore-1.jpg";
import happyGilmore2 from "./pictures/happy-gilmore/happy-gilmore-2.jpg";
import happyGilmore3 from "./pictures/happy-gilmore/happy-gilmore-3.jpg";
import almostHeroes1 from "./pictures/almost-heroes/almost-heroes-1.jpg";
import almostHeroes2 from "./pictures/almost-heroes/almost-heroes-2.jpg";
import almostHeroes3 from "./pictures/almost-heroes/almost-heroes-3.jpg";
import war1 from "./pictures/war/war-1.jpeg";
import war2 from "./pictures/war/war-2.jpeg";
import war3 from "./pictures/war/war-3.jpeg";
import fearless1 from "./pictures/fearless/fearless-1.jpg";
import fearless2 from "./pictures/fearless/fearless-2.jpg";
import fearless3 from "./pictures/fearless/fearless-3.jpg";
import gpb1 from "./pictures/grosse-pointe-blank/gpb-1.jpg";
import gpb2 from "./pictures/grosse-pointe-blank/gpb-2.jpg";
import gpb3 from "./pictures/grosse-pointe-blank/gpb-3.jpg";
import wrongfullyAccused1 from "./pictures/wrongfully-accused/accused-1.jpeg";
import wrongfullyAccused2 from "./pictures/wrongfully-accused/accused-2.jpeg";
import wrongfullyAccused3 from "./pictures/wrongfully-accused/accused-3.jpeg";
import rounders1 from "./pictures/rounders/rounders-1.jpeg";
import rounders2 from "./pictures/rounders/rounders-2.jpeg";
import rounders3 from "./pictures/rounders/rounders-3.jpeg";
import horribleBosses1 from "./pictures/horrible-bosses/bosses-1.jpg";
import horribleBosses2 from "./pictures/horrible-bosses/bosses-2.jpg";
import horribleBosses3 from "./pictures/horrible-bosses/bosses-3.jpg";
import definitelyMaybe1 from "./pictures/definitely-maybe/definitely-1.jpg";
import definitelyMaybe2 from "./pictures/definitely-maybe/definitely-2.jpg";
import definitelyMaybe3 from "./pictures/definitely-maybe/definitely-3.jpg";
import adventure1 from "./pictures/adventureland/adventure-1.jpg";
import adventure2 from "./pictures/adventureland/adventure-2.jpg";
import adventure3 from "./pictures/adventureland/adventure-3.jpg";
import passion1 from "./pictures/passion-of-christ/passion-1.jpeg";
import passion2 from "./pictures/passion-of-christ/passion-2.jpeg";
import passion3 from "./pictures/passion-of-christ/passion-3.jpeg";
import bigSick1 from "./pictures/big-sick/sick-1.jpg";
import bigSick2 from "./pictures/big-sick/sick-2.jpg";
import bigSick3 from "./pictures/big-sick/sick-3.jpg";
import elRoyale1 from "./pictures/bad-times-el-royale/el-royale-1.jpg";
import elRoyale2 from "./pictures/bad-times-el-royale/el-royale-2.jpg";
import elRoyale3 from "./pictures/bad-times-el-royale/el-royale-3.jpg";
import klan1 from "./pictures/blackkklansman/klan-1.jpg";
import klan2 from "./pictures/blackkklansman/klan-2.jpg";
import klan3 from "./pictures/blackkklansman/klan-3.jpg";
import heartbreak1 from "./pictures/heartbreak-kid/heartbreak-1.jpg";
import heartbreak2 from "./pictures/heartbreak-kid/heartbreak-2.jpg";
import heartbreak3 from "./pictures/heartbreak-kid/heartbreak-3.jpg";
import stuck1 from "./pictures/stuck-on-you/stuck-1.jpeg";
import stuck2 from "./pictures/stuck-on-you/stuck-2.jpeg";
import stuck3 from "./pictures/stuck-on-you/stuck-3.jpeg";
import shallow1 from "./pictures/shallow-hal/shallow-1.jpeg";
import shallow2 from "./pictures/shallow-hal/shallow-2.jpeg";
import shallow3 from "./pictures/shallow-hal/shallow-3.jpeg";
import gentlemen1 from "./pictures/gentlemen/gentlemen-1.jpg";
import gentlemen2 from "./pictures/gentlemen/gentlemen-2.jpg";
import gentlemen3 from "./pictures/gentlemen/gentlemen-3.jpg";
import rogue1 from "./pictures/rogue-one/rogue-1.jpeg";
import rogue2 from "./pictures/rogue-one/rogue-2.jpeg";
import rogue3 from "./pictures/rogue-one/rogue-3.jpeg";
import summer1 from "./pictures/kings-of-summer/summer-1.jpeg";
import summer2 from "./pictures/kings-of-summer/summer-2.jpeg";
import summer3 from "./pictures/kings-of-summer/summer-3.jpeg";
import seabiscuit1 from "./pictures/seabiscuit/seabiscuit-1.jpeg";
import seabiscuit2 from "./pictures/seabiscuit/seabiscuit-2.jpeg";
import seabiscuit3 from "./pictures/seabiscuit/seabiscuit-3.jpeg";
import goon1 from "./pictures/goon/goon-1.jpg";
import goon2 from "./pictures/goon/goon-2.jpg";
import goon3 from "./pictures/goon/goon-3.jpg";
import goon21 from "./pictures/goon-2/goon-21.jpg";
import goon22 from "./pictures/goon-2/goon-22.jpg";
import goon23 from "./pictures/goon-2/goon-23.jpg";
import tonya1 from "./pictures/I-tonya/tonya-1.jpg";
import tonya2 from "./pictures/I-tonya/tonya-2.jpg";
import tonya3 from "./pictures/I-tonya/tonya-3.jpg";
import longestYard1 from "./pictures/longest-yard/longest-yard-1.jpeg";
import longestYard2 from "./pictures/longest-yard/longest-yard-2.jpeg";
import longestYard3 from "./pictures/longest-yard/longest-yard-3.jpeg";
import whiteMen1 from "./pictures/white-men-cant-jump/white-1.jpeg";
import whiteMen2 from "./pictures/white-men-cant-jump/white-2.jpeg";
import whiteMen3 from "./pictures/white-men-cant-jump/white-3.jpeg";
import kicking1 from "./pictures/kicking-and-screaming/kicking-1.jpg";
import kicking2 from "./pictures/kicking-and-screaming/kicking-2.jpg";
import kicking3 from "./pictures/kicking-and-screaming/kicking-3.jpg";
import hoosiers1 from "./pictures/hoosiers/hoosiers-1.jpg";
import hoosiers2 from "./pictures/hoosiers/hoosiers-2.jpg";
import hoosiers3 from "./pictures/hoosiers/hoosiers-3.jpg";
import foxcatcher1 from "./pictures/foxcatcher/foxcatcher-1.jpg";
import foxcatcher2 from "./pictures/foxcatcher/foxcatcher-2.jpg";
import foxcatcher3 from "./pictures/foxcatcher/foxcatcher-3.jpg";
import beautyAndBeast1 from "./pictures/beauty-and-the-beast/beauty-1.jpg";
import beautyAndBeast2 from "./pictures/beauty-and-the-beast/beauty-2.jpg";
import beautyAndBeast3 from "./pictures/beauty-and-the-beast/beauty-3.jpg";
import paddington1 from "./pictures/paddington/paddington-1.jpeg";
import paddington2 from "./pictures/paddington/paddington-2.jpeg";
import paddington3 from "./pictures/paddington/paddington-3.jpeg";
import paddington21 from "./pictures/paddington-2/paddington-21.jpeg";
import paddington22 from "./pictures/paddington-2/paddington-22.jpeg";
import paddington23 from "./pictures/paddington-2/paddington-23.jpeg";
import giantPeach1 from "./pictures/james-giant-peach/giant-peach-1.jpg";
import giantPeach2 from "./pictures/james-giant-peach/giant-peach-2.jpg";
import giantPeach3 from "./pictures/james-giant-peach/giant-peach-3.jpg";
import trainDragon1 from "./pictures/how-to-train-dragon/dragon-1.jpg";
import trainDragon2 from "./pictures/how-to-train-dragon/dragon-2.jpg";
import trainDragon3 from "./pictures/how-to-train-dragon/dragon-3.jpg";
import dumbo1 from "./pictures/dumbo/dumbo-1.jpg";
import dumbo2 from "./pictures/dumbo/dumbo-2.jpg";
import dumbo3 from "./pictures/dumbo/dumbo-3.jpg";
import monstersUniversity1 from "./pictures/monsters-university/monsters-university-1.jpeg";
import monstersUniversity2 from "./pictures/monsters-university/monsters-university-2.jpeg";
import monstersUniversity3 from "./pictures/monsters-university/monsters-university-3.jpeg";
import aladdin1 from "./pictures/aladdin/aladdin-1.jpg";
import aladdin2 from "./pictures/aladdin/aladdin-2.jpg";
import aladdin3 from "./pictures/aladdin/aladdin-3.jpg";
import miloOtis1 from "./pictures/milo-and-otis/milo-1.jpeg";
import miloOtis2 from "./pictures/milo-and-otis/milo-2.jpeg";
import miloOtis3 from "./pictures/milo-and-otis/milo-3.jpeg";
import bigHero1 from "./pictures/big-hero-6/big-hero-1.jpg";
import bigHero2 from "./pictures/big-hero-6/big-hero-2.jpg";
import bigHero3 from "./pictures/big-hero-6/big-hero-3.jpg";
import kfp1 from "./pictures/kung-fu-panda/kfp-1.jpeg";
import kfp2 from "./pictures/kung-fu-panda/kfp-2.jpeg";
import kfp3 from "./pictures/kung-fu-panda/kfp-3.jpeg";
import cirqueFreak1 from "./pictures/cirque-du-freak/freak-1.jpg";
import cirqueFreak2 from "./pictures/cirque-du-freak/freak-2.jpg";
import cirqueFreak3 from "./pictures/cirque-du-freak/freak-3.jpg";
import goodSon1 from "./pictures/good-son/good-son-1.jpg";
import goodSon2 from "./pictures/good-son/good-son-2.jpg";
import goodSon3 from "./pictures/good-son/good-son-3.jpg";
import darkFate1 from "./pictures/terminator-dark-fate/dark-fate-1.jpeg";
import darkFate2 from "./pictures/terminator-dark-fate/dark-fate-2.jpeg";
import darkFate3 from "./pictures/terminator-dark-fate/dark-fate-3.jpeg";
import babyMama1 from "./pictures/baby-mama/baby-mama-1.jpg";
import babyMama2 from "./pictures/baby-mama/baby-mama-2.jpg";
import babyMama3 from "./pictures/baby-mama/baby-mama-3.jpg";
import hannibalRising1 from "./pictures/hannibal-rising/hannibal-rising-1.jpg";
import hannibalRising2 from "./pictures/hannibal-rising/hannibal-rising-2.jpg";
import hannibalRising3 from "./pictures/hannibal-rising/hannibal-rising-3.jpg";
import pumpingIron1 from "./pictures/pumping-iron/pumping-iron-1.jpeg";
import pumpingIron2 from "./pictures/pumping-iron/pumping-iron-2.jpeg";
import pumpingIron3 from "./pictures/pumping-iron/pumping-iron-3.jpeg";
import baggerVance1 from "./pictures/bagger-vance/bagger-vance-1.jpg";
import baggerVance2 from "./pictures/bagger-vance/bagger-vance-2.jpg";
import baggerVance3 from "./pictures/bagger-vance/bagger-vance-3.jpg";
import tinCup1 from "./pictures/tin-cup/tin-cup-1.jpeg";
import tinCup2 from "./pictures/tin-cup/tin-cup-2.jpeg";
import tinCup3 from "./pictures/tin-cup/tin-cup-3.jpeg";
import draftDay1 from "./pictures/draft-day/draft-day-1.jpg";
import draftDay2 from "./pictures/draft-day/draft-day-2.jpg";
import draftDay3 from "./pictures/draft-day/draft-day-3.jpg";
import pinocchio1 from "./pictures/pinocchio/pinocchio-1.jpeg";
import pinocchio2 from "./pictures/pinocchio/pinocchio-2.jpeg";
import pinocchio3 from "./pictures/pinocchio/pinocchio-3.jpeg";
import silverman1 from "./pictures/saving-silverman/silverman-1.jpeg";
import silverman2 from "./pictures/saving-silverman/silverman-2.jpeg";
import silverman3 from "./pictures/saving-silverman/silverman-3.jpeg";
import hustler1 from "./pictures/the-hustler/hustler-1.jpeg";
import hustler2 from "./pictures/the-hustler/hustler-2.jpeg";
import hustler3 from "./pictures/the-hustler/hustler-3.jpeg";
import wimpyKid1 from "./pictures/diary-of-wimpy/wimpy-1.jpg";
import wimpyKid2 from "./pictures/diary-of-wimpy/wimpy-2.jpg";
import wimpyKid3 from "./pictures/diary-of-wimpy/wimpy-3.jpg";
import tooLittle1 from "./pictures/the-man-who-knew-too-little/too-little-1.jpeg";
import tooLittle2 from "./pictures/the-man-who-knew-too-little/too-little-2.jpeg";
import tooLittle3 from "./pictures/the-man-who-knew-too-little/too-little-3.jpeg";
import americanPie1 from "./pictures/american-pie/pie-1.jpg";
import americanPie2 from "./pictures/american-pie/pie-2.jpg";
import americanPie3 from "./pictures/american-pie/pie-3.jpg";
import hdyk1 from "./pictures/how-do-you-know/hdyk-1.jpg";
import hdyk2 from "./pictures/how-do-you-know/hdyk-2.jpg";
import hdyk3 from "./pictures/how-do-you-know/hdyk-3.jpg";
import busterScruggs1 from "./pictures/ballad-of-buster-scruggs/buster-1.jpg";
import busterScruggs2 from "./pictures/ballad-of-buster-scruggs/buster-2.jpg";
import busterScruggs3 from "./pictures/ballad-of-buster-scruggs/buster-3.jpg";
import hacksawRidge1 from "./pictures/hacksaw-ridge/hacksaw-1.jpg";
import hacksawRidge2 from "./pictures/hacksaw-ridge/hacksaw-2.jpg";
import hacksawRidge3 from "./pictures/hacksaw-ridge/hacksaw-3.jpg";
import abyss1 from "./pictures/the-abyss/abyss-1.jpeg";
import abyss2 from "./pictures/the-abyss/abyss-2.jpeg";
import abyss3 from "./pictures/the-abyss/abyss-3.jpeg";
import rift1 from "./pictures/the-rift/rift-1.jpeg";
import rift2 from "./pictures/the-rift/rift-2.jpeg";
import rift3 from "./pictures/the-rift/rift-3.jpeg";
import deadBeforeDawn1 from "./pictures/dead-before-dawn/dbd-1.jpg";
import deadBeforeDawn2 from "./pictures/dead-before-dawn/dbd-2.jpg";
import deadBeforeDawn3 from "./pictures/dead-before-dawn/dbd-3.jpg";
import sorceror1 from "./pictures/harry-potter-sorceror/sorceror-1.jpg";
import sorceror2 from "./pictures/harry-potter-sorceror/sorceror-2.jpg";
import sorceror3 from "./pictures/harry-potter-sorceror/sorceror-3.jpg";
import hollows1 from "./pictures/harry-potter-hollows/hollows-1.jpg";
import hollows2 from "./pictures/harry-potter-hollows/hollows-2.jpg";
import hollows3 from "./pictures/harry-potter-hollows/hollows-3.jpg";
import ap21 from "./pictures/american-pie-2/ap-21.jpg";
import ap22 from "./pictures/american-pie-2/ap-22.jpg";
import ap23 from "./pictures/american-pie-2/ap-23.jpg";
import chuckLarry1 from "./pictures/chuck-and-larry/chuck-1.jpg";
import chuckLarry2 from "./pictures/chuck-and-larry/chuck-2.jpg";
import chuckLarry3 from "./pictures/chuck-and-larry/chuck-3.jpg";
import zookeeper1 from "./pictures/zookeeper/zookeeper-1.jpeg";
import zookeeper2 from "./pictures/zookeeper/zookeeper-2.jpeg";
import zookeeper3 from "./pictures/zookeeper/zookeeper-3.jpeg";
import norbit1 from "./pictures/norbit/norbit-1.jpeg";
import norbit2 from "./pictures/norbit/norbit-2.jpeg";
import norbit3 from "./pictures/norbit/norbit-3.jpeg";
import benchwarmers1 from "./pictures/benchwarmers/benchwarmers-1.jpg";
import benchwarmers2 from "./pictures/benchwarmers/benchwarmers-2.jpg";
import benchwarmers3 from "./pictures/benchwarmers/benchwarmers-3.jpg";
import bof1 from "./pictures/balls-of-fury/bof-1.jpg";
import bof2 from "./pictures/balls-of-fury/bof-2.jpg";
import bof3 from "./pictures/balls-of-fury/bof-3.jpg";
import glory1 from "./pictures/glory/glory-1.jpg";
import glory2 from "./pictures/glory/glory-2.jpg";
import glory3 from "./pictures/glory/glory-3.jpg";
import napoleon1 from "./pictures/napoleon-dynamite/napoleon-1.jpeg";
import napoleon2 from "./pictures/napoleon-dynamite/napoleon-2.jpeg";
import napoleon3 from "./pictures/napoleon-dynamite/napoleon-3.jpeg";
import counselor1 from "./pictures/the-counselor/counselor-1.jpeg";
import counselor2 from "./pictures/the-counselor/counselor-2.jpeg";
import counselor3 from "./pictures/the-counselor/counselor-3.jpeg";
import tomahawk1 from "./pictures/bone-tomahawk/tomahawk-1.jpg";
import tomahawk2 from "./pictures/bone-tomahawk/tomahawk-2.jpg";
import tomahawk3 from "./pictures/bone-tomahawk/tomahawk-3.jpg";
import com1 from "./pictures/children-of-men/com-1.jpg";
import com2 from "./pictures/children-of-men/com-2.jpg";
import com3 from "./pictures/children-of-men/com-3.jpg";
import daybreakers1 from "./pictures/daybreakers/daybreakers-1.jpg";
import daybreakers2 from "./pictures/daybreakers/daybreakers-2.jpg";
import daybreakers3 from "./pictures/daybreakers/daybreakers-3.jpg";
import predestination1 from "./pictures/predestination/predestination-1.jpeg";
import predestination2 from "./pictures/predestination/predestination-2.jpeg";
import predestination3 from "./pictures/predestination/predestination-3.jpeg";
import ghostTeam1 from "./pictures/ghost-team/ghost-team-1.jpg";
import ghostTeam2 from "./pictures/ghost-team/ghost-team-2.jpg";
import ghostTeam3 from "./pictures/ghost-team/ghost-team-3.jpg";
import bigHit1 from "./pictures/the-big-hit/big-hit-1.jpeg";
import bigHit2 from "./pictures/the-big-hit/big-hit-2.jpeg";
import bigHit3 from "./pictures/the-big-hit/big-hit-3.jpeg";
import americanHero1 from "./pictures/american-hero/ah-1.jpg";
import americanHero2 from "./pictures/american-hero/ah-2.jpg";
import americanHero3 from "./pictures/american-hero/ah-3.jpg";
import fromHell1 from "./pictures/from-hell/from-hell-1.jpg";
import fromHell2 from "./pictures/from-hell/from-hell-2.jpg";
import fromHell3 from "./pictures/from-hell/from-hell-3.jpg";
import blankCheck1 from "./pictures/blank-check/blank-check-1.jpg";
import blankCheck2 from "./pictures/blank-check/blank-check-2.jpg";
import blankCheck3 from "./pictures/blank-check/blank-check-3.jpg";
import natm1 from "./pictures/night-at-the-museum/natm-1.jpeg";
import natm2 from "./pictures/night-at-the-museum/natm-2.jpeg";
import natm3 from "./pictures/night-at-the-museum/natm-3.jpeg";
import hjntiy1 from "./pictures/hes-not-into/hjntiy-1.jpg";
import hjntiy2 from "./pictures/hes-not-into/hjntiy-2.jpg";
import hjntiy3 from "./pictures/hes-not-into/hjntiy-3.jpg";
import watchmen1 from "./pictures/watchmen/watchmen-1.jpeg";
import watchmen2 from "./pictures/watchmen/watchmen-2.jpeg";
import watchmen3 from "./pictures/watchmen/watchmen-3.jpeg";
import messageFromKing1 from "./pictures/message-from-the-king/mftk-1.jpeg";
import messageFromKing2 from "./pictures/message-from-the-king/mftk-2.jpeg";
import messageFromKing3 from "./pictures/message-from-the-king/mftk-3.jpeg";
import comingToAmerica1 from "./pictures/coming-to-america/coming-to-america-1.jpg";
import comingToAmerica2 from "./pictures/coming-to-america/coming-to-america-2.jpg";
import comingToAmerica3 from "./pictures/coming-to-america/coming-to-america-3.jpg";
import darkman21 from "./pictures/darkman-2/darkman-2-1.jpg";
import darkman22 from "./pictures/darkman-2/darkman-2-2.jpg";
import darkman23 from "./pictures/darkman-2/darkman-2-3.jpg";
import deltaForce1 from "./pictures/delta-force/delta-force-1.jpg";
import deltaForce2 from "./pictures/delta-force/delta-force-2.jpg";
import deltaForce3 from "./pictures/delta-force/delta-force-3.jpg";
import ironEagle1 from "./pictures/iron-eagle/iron-eagle-1.jpg";
import ironEagle2 from "./pictures/iron-eagle/iron-eagle-2.jpg";
import ironEagle3 from "./pictures/iron-eagle/iron-eagle-3.jpg";
import lastVirgin1 from "./pictures/last-virgin/last-virgin-1.jpeg";
import lastVirgin2 from "./pictures/last-virgin/last-virgin-2.jpeg";
import lastVirgin3 from "./pictures/last-virgin/last-virgin-3.jpeg";
import lionheart1 from "./pictures/lionheart/lionheart-1.jpeg";
import lionheart2 from "./pictures/lionheart/lionheart-2.jpeg";
import lionheart3 from "./pictures/lionheart/lionheart-3.jpeg";
import soldier1 from "./pictures/soldier/soldier-1.jpeg";
import soldier2 from "./pictures/soldier/soldier-2.jpeg";
import soldier3 from "./pictures/soldier/soldier-3.jpeg";
import year11 from "./pictures/year-one/year-one-1.jpeg";
import year12 from "./pictures/year-one/year-one-2.jpeg";
import year13 from "./pictures/year-one/year-one-3.jpeg";
import endgame1 from "./pictures/avengers-endgame/endgame-1.jpg";
import endgame2 from "./pictures/avengers-endgame/endgame-2.jpg";
import endgame3 from "./pictures/avengers-endgame/endgame-3.jpg";
import matchPoint1 from "./pictures/match-point/match-point-1.jpeg";
import matchPoint2 from "./pictures/match-point/match-point-2.jpeg";
import matchPoint3 from "./pictures/match-point/match-point-3.jpeg";
import unhinged1 from "./pictures/unhinged/unhinged-1.jpeg";
import unhinged2 from "./pictures/unhinged/unhinged-2.jpeg";
import unhinged3 from "./pictures/unhinged/unhinged-3.jpeg";
import myGirl1 from "./pictures/my-girl/my-girl-1.jpeg";
import myGirl2 from "./pictures/my-girl/my-girl-2.jpeg";
import myGirl3 from "./pictures/my-girl/my-girl-3.jpeg";
import simonBirch1 from "./pictures/simon-birch/simon-birch-1.jpeg";
import simonBirch2 from "./pictures/simon-birch/simon-birch-2.jpeg";
import simonBirch3 from "./pictures/simon-birch/simon-birch-3.jpeg";
import mousehunt1 from "./pictures/mouse-hunt/mouse-hunt-1.jpeg";
import mousehunt2 from "./pictures/mouse-hunt/mouse-hunt-2.jpeg";
import mousehunt3 from "./pictures/mouse-hunt/mouse-hunt-3.jpeg";
import fortyEightHours1 from "./pictures/48-hrs/48-hrs-1.jpeg";
import fortyEightHours2 from "./pictures/48-hrs/48-hrs-2.jpeg";
import fortyEightHours3 from "./pictures/48-hrs/48-hrs-3.jpeg";
import bigTrouble1 from "./pictures/big-little-china/big-little-china-1.jpg";
import bigTrouble2 from "./pictures/big-little-china/big-little-china-2.jpg";
import bigTrouble3 from "./pictures/big-little-china/big-little-china-3.jpg";
import conanDestroyer1 from "./pictures/conan-destroyer/conan-destroyer-1.jpg";
import conanDestroyer2 from "./pictures/conan-destroyer/conan-destroyer-2.jpg";
import conanDestroyer3 from "./pictures/conan-destroyer/conan-destroyer-3.jpg";
import ijskull1 from "./pictures/ij-skull/ij-skull-1.jpg";
import ijskull2 from "./pictures/ij-skull/ij-skull-2.jpg";
import ijskull3 from "./pictures/ij-skull/ij-skull-3.jpg";
import loneWolf1 from "./pictures/lone-mcquade/lone-mcquade-1.jpeg";
import loneWolf2 from "./pictures/lone-mcquade/lone-mcquade-2.jpeg";
import loneWolf3 from "./pictures/lone-mcquade/lone-mcquade-3.jpeg";
import octopussy1 from "./pictures/octopussy/octopussy-1.jpeg";
import octopussy2 from "./pictures/octopussy/octopussy-2.jpeg";
import octopussy3 from "./pictures/octopussy/octopussy-3.jpeg";
import spiesLikeUs1 from "./pictures/spies-like-us/spies-like-us-1.jpeg";
import spiesLikeUs2 from "./pictures/spies-like-us/spies-like-us-2.jpeg";
import spiesLikeUs3 from "./pictures/spies-like-us/spies-like-us-3.jpeg";
import split1 from "./pictures/split/split-1.jpeg";
import split2 from "./pictures/split/split-2.jpeg";
import split3 from "./pictures/split/split-3.jpeg";
import topDog1 from "./pictures/top-dog/top-dog-1.jpeg";
import topDog2 from "./pictures/top-dog/top-dog-2.jpeg";
import topDog3 from "./pictures/top-dog/top-dog-3.jpeg";
import turnerAndHooch1 from "./pictures/turner-hooch/turner-hooch-1.jpeg";
import turnerAndHooch2 from "./pictures/turner-hooch/turner-hooch-2.jpeg";
import turnerAndHooch3 from "./pictures/turner-hooch/turner-hooch-3.jpeg";
import analyzeThat1 from "./pictures/analyze-that/analyze-that-1.jpg";
import analyzeThat2 from "./pictures/analyze-that/analyze-that-2.jpg";
import analyzeThat3 from "./pictures/analyze-that/analyze-that-3.jpg";
import bigDaddy1 from "./pictures/big-daddy/big-daddy-1.jpg";
import bigDaddy2 from "./pictures/big-daddy/big-daddy-2.jpg";
import bigDaddy3 from "./pictures/big-daddy/big-daddy-3.jpg";
import dogma1 from "./pictures/dogma/dogma-1.jpg";
import dogma2 from "./pictures/dogma/dogma-2.jpg";
import dogma3 from "./pictures/dogma/dogma-3.jpg";
import galaxyQuest1 from "./pictures/galaxy-quest/galaxy-quest-1.jpg";
import galaxyQuest2 from "./pictures/galaxy-quest/galaxy-quest-2.jpg";
import galaxyQuest3 from "./pictures/galaxy-quest/galaxy-quest-3.jpg";
import manMoon1 from "./pictures/man-moon/man-moon-1.jpeg";
import manMoon2 from "./pictures/man-moon/man-moon-2.jpeg";
import manMoon3 from "./pictures/man-moon/man-moon-3.jpeg";
import mysteryMen1 from "./pictures/mystery-men/mystery-men-1.jpeg";
import mysteryMen2 from "./pictures/mystery-men/mystery-men-2.jpeg";
import mysteryMen3 from "./pictures/mystery-men/mystery-men-3.jpeg";
import neverKissed1 from "./pictures/never-kissed/never-kissed-1.jpeg";
import neverKissed2 from "./pictures/never-kissed/never-kissed-2.jpeg";
import neverKissed3 from "./pictures/never-kissed/never-kissed-3.jpeg";
import stuartLittle1 from "./pictures/stuart-little/stuart-little-1.jpeg";
import stuartLittle2 from "./pictures/stuart-little/stuart-little-2.jpeg";
import stuartLittle3 from "./pictures/stuart-little/stuart-little-3.jpeg";
import vanillaSky1 from "./pictures/vanilla-sky/vanilla-sky-1.jpeg";
import vanillaSky2 from "./pictures/vanilla-sky/vanilla-sky-2.jpeg";
import vanillaSky3 from "./pictures/vanilla-sky/vanilla-sky-3.jpeg";
import sixdays1 from "./pictures/6days-7nights/6days-7nights-1.jpeg";
import sixdays2 from "./pictures/6days-7nights/6days-7nights-2.jpeg";
import sixdays3 from "./pictures/6days-7nights/6days-7nights-3.jpeg";
import analyzeThis1 from "./pictures/analyze-this/analyze-this-1.jpg";
import analyzeThis2 from "./pictures/analyze-this/analyze-this-2.jpg";
import analyzeThis3 from "./pictures/analyze-this/analyze-this-3.jpg";
import edtv1 from "./pictures/ed-tv/ed-tv-1.jpg";
import edtv2 from "./pictures/ed-tv/ed-tv-2.jpg";
import edtv3 from "./pictures/ed-tv/ed-tv-3.jpg";
import mafia1 from "./pictures/mafia/mafia-1.jpeg";
import mafia2 from "./pictures/mafia/mafia-2.jpeg";
import mafia3 from "./pictures/mafia/mafia-3.jpeg";
import parentTrap1 from "./pictures/parent-trap-98/parent-trap-98-1.jpeg";
import parentTrap2 from "./pictures/parent-trap-98/parent-trap-98-2.jpeg";
import parentTrap3 from "./pictures/parent-trap-98/parent-trap-98-3.jpeg";
import paulie1 from "./pictures/paulie/paulie-1.jpeg";
import paulie2 from "./pictures/paulie/paulie-2.jpeg";
import paulie3 from "./pictures/paulie/paulie-3.jpeg";
import shakespeareInLove1 from "./pictures/shakes-love/shakes-love-1.jpeg";
import shakespeareInLove2 from "./pictures/shakes-love/shakes-love-2.jpeg";
import shakespeareInLove3 from "./pictures/shakes-love/shakes-love-3.jpeg";
import smallSoldiers1 from "./pictures/small-soldiers/small-soldiers-1.jpeg";
import smallSoldiers2 from "./pictures/small-soldiers/small-soldiers-2.jpeg";
import smallSoldiers3 from "./pictures/small-soldiers/small-soldiers-3.jpeg";
import strangersCandy1 from "./pictures/strangers-candy/strangers-candy-1.jpeg";
import strangersCandy2 from "./pictures/strangers-candy/strangers-candy-2.jpeg";
import strangersCandy3 from "./pictures/strangers-candy/strangers-candy-3.jpeg";
import waterboy1 from "./pictures/waterboy/waterboy-1.jpeg";
import waterboy2 from "./pictures/waterboy/waterboy-2.jpeg";
import waterboy3 from "./pictures/waterboy/waterboy-3.jpeg";
import youveGotMail1 from "./pictures/youve-mail/youve-mail-1.jpeg";
import youveGotMail2 from "./pictures/youve-mail/youve-mail-2.jpeg";
import youveGotMail3 from "./pictures/youve-mail/youve-mail-3.jpeg";
import heartbreakRidge1 from "./pictures/heartbreak-ridge/heartbreak-ridge-1.jpg";
import heartbreakRidge2 from "./pictures/heartbreak-ridge/heartbreak-ridge-2.jpg";
import heartbreakRidge3 from "./pictures/heartbreak-ridge/heartbreak-ridge-3.jpg";
import jackfrostHorror1 from "./pictures/jack-frost-horror/jack-frost-horror-1.jpg";
import jackfrostHorror2 from "./pictures/jack-frost-horror/jack-frost-horror-2.jpg";
import jackfrostHorror3 from "./pictures/jack-frost-horror/jack-frost-horror-3.jpg";
import jackfrostKeaton1 from "./pictures/jack-frost-keaton/jack-frost-keaton-1.jpg";
import jackfrostKeaton2 from "./pictures/jack-frost-keaton/jack-frost-keaton-2.jpg";
import jackfrostKeaton3 from "./pictures/jack-frost-keaton/jack-frost-keaton-3.jpg";
import lethalWeapon1 from "./pictures/lethal-weapon1/lethal-weapon1-1.jpeg";
import lethalWeapon2 from "./pictures/lethal-weapon1/lethal-weapon1-2.jpeg";
import lethalWeapon3 from "./pictures/lethal-weapon1/lethal-weapon1-3.jpeg";
import wheelsMeals1 from "./pictures/wheels-meals/wheels-meals-1.jpeg";
import wheelsMeals2 from "./pictures/wheels-meals/wheels-meals-2.jpeg";
import wheelsMeals3 from "./pictures/wheels-meals/wheels-meals-3.jpeg";
import antz1 from "./pictures/antz/antz-1.jpg";
import antz2 from "./pictures/antz/antz-2.jpg";
import antz3 from "./pictures/antz/antz-3.jpg";
import inout1 from "./pictures/in-n-out/in-n-out-1.jpg";
import inout2 from "./pictures/in-n-out/in-n-out-2.jpg";
import inout3 from "./pictures/in-n-out/in-n-out-3.jpg";
import liarliar1 from "./pictures/liar-liar/liar-liar-1.jpeg";
import liarliar2 from "./pictures/liar-liar/liar-liar-2.jpeg";
import liarliar3 from "./pictures/liar-liar/liar-liar-3.jpeg";
import lucySky1 from "./pictures/lucy-sky/lucy-sky-1.jpeg";
import lucySky2 from "./pictures/lucy-sky/lucy-sky-2.jpeg";
import lucySky3 from "./pictures/lucy-sky/lucy-sky-3.jpeg";
import mrMagoo1 from "./pictures/mr-magoo/mr-magoo-1.jpeg";
import mrMagoo2 from "./pictures/mr-magoo/mr-magoo-2.jpeg";
import mrMagoo3 from "./pictures/mr-magoo/mr-magoo-3.jpeg";
import bean1 from "./pictures/bean/bean-1.jpg";
import bean2 from "./pictures/bean/bean-2.jpg";
import bean3 from "./pictures/bean/bean-3.jpg";
import goodBurger1 from "./pictures/good-burger/good-burger-1.jpg";
import goodBurger2 from "./pictures/good-burger/good-burger-2.jpg";
import goodBurger3 from "./pictures/good-burger/good-burger-3.jpg";
import hercules971 from "./pictures/hercules-97/hercules-97-1.jpg";
import hercules972 from "./pictures/hercules-97/hercules-97-2.jpg";
import hercules973 from "./pictures/hercules-97/hercules-97-3.jpg";
import herculesNY1 from "./pictures/hercules-ny/hercules-ny-1.jpg";
import herculesNY2 from "./pictures/hercules-ny/hercules-ny-2.jpg";
import herculesNY3 from "./pictures/hercules-ny/hercules-ny-3.jpg";
import mimisiku1 from "./pictures/jungle-2-jungle/jungle-2-jungle-1.jpg";
import mimisiku2 from "./pictures/jungle-2-jungle/jungle-2-jungle-2.jpg";
import mimisiku3 from "./pictures/jungle-2-jungle/jungle-2-jungle-3.jpg";
import airbud1 from "./pictures/air-bud/air-bud-1.jpg";
import airbud2 from "./pictures/air-bud/air-bud-2.jpg";
import airbud3 from "./pictures/air-bud/air-bud-3.jpg";
import georgeJungle1 from "./pictures/george-jungle/george-jungle-1.jpg";
import georgeJungle2 from "./pictures/george-jungle/george-jungle-2.jpg";
import georgeJungle3 from "./pictures/george-jungle/george-jungle-3.jpg";
import goneFishin1 from "./pictures/gone-fishin/gone-fishin-1.jpg";
import goneFishin2 from "./pictures/gone-fishin/gone-fishin-2.jpg";
import goneFishin3 from "./pictures/gone-fishin/gone-fishin-3.jpg";
import richerPoorer1 from "./pictures/richer-poorer/richer-poorer-1.jpeg";
import richerPoorer2 from "./pictures/richer-poorer/richer-poorer-2.jpeg";
import richerPoorer3 from "./pictures/richer-poorer/richer-poorer-3.jpeg";
import spyhard1 from "./pictures/spy-hard/spy-hard-1.jpeg";
import spyhard2 from "./pictures/spy-hard/spy-hard-2.jpeg";
import spyhard3 from "./pictures/spy-hard/spy-hard-3.jpeg";
import uhf1 from "./pictures/uhf/uhf-1.jpeg";
import uhf2 from "./pictures/uhf/uhf-2.jpeg";
import uhf3 from "./pictures/uhf/uhf-3.jpeg";
import hshigh1 from "./pictures/hs-high/hs-high-1.jpg";
import hshigh2 from "./pictures/hs-high/hs-high-2.jpg";
import hshigh3 from "./pictures/hs-high/hs-high-3.jpg";
import joesApt1 from "./pictures/joes-apt/joes-apt-1.jpg";
import joesApt2 from "./pictures/joes-apt/joes-apt-2.jpg";
import joesApt3 from "./pictures/joes-apt/joes-apt-3.jpg";
import marsAttacks1 from "./pictures/mars-attacks/mars-attacks-1.jpeg";
import marsAttacks2 from "./pictures/mars-attacks/mars-attacks-2.jpeg";
import marsAttacks3 from "./pictures/mars-attacks/mars-attacks-3.jpeg";
import butthead1 from "./pictures/beavis-butthead-am/beavis-butthead-am-1.jpg";
import butthead2 from "./pictures/beavis-butthead-am/beavis-butthead-am-2.jpg";
import butthead3 from "./pictures/beavis-butthead-am/beavis-butthead-am-3.jpg";
import blackSheep1 from "./pictures/black-sheep/black-sheep-1.jpg";
import blackSheep2 from "./pictures/black-sheep/black-sheep-2.jpg";
import blackSheep3 from "./pictures/black-sheep/black-sheep-3.jpg";
import cableGuy1 from "./pictures/cable-guy/cable-guy-1.jpg";
import cableGuy2 from "./pictures/cable-guy/cable-guy-2.jpg";
import cableGuy3 from "./pictures/cable-guy/cable-guy-3.jpg";
import dusktildawn1 from "./pictures/dusk-til-dawn/dusk-til-dawn-1.jpg";
import dusktildawn2 from "./pictures/dusk-til-dawn/dusk-til-dawn-2.jpg";
import dusktildawn3 from "./pictures/dusk-til-dawn/dusk-til-dawn-3.jpg";
import killIrishman1 from "./pictures/kill-irishman/kill-irishman-1.jpg";
import killIrishman2 from "./pictures/kill-irishman/kill-irishman-2.jpg";
import killIrishman3 from "./pictures/kill-irishman/kill-irishman-3.jpg";
import empireStrikes1 from "./pictures/empire-strikes/empire-strikes-1.jpg";
import empireStrikes2 from "./pictures/empire-strikes/empire-strikes-2.jpg";
import empireStrikes3 from "./pictures/empire-strikes/empire-strikes-3.jpg";
import newHope1 from "./pictures/a-new-hope/a-new-hope-1.jpeg";
import newHope2 from "./pictures/a-new-hope/a-new-hope-2.jpeg";
import newHope3 from "./pictures/a-new-hope/a-new-hope-3.jpeg";
import ghost1 from "./pictures/ghost/ghost-1.jpg";
import ghost2 from "./pictures/ghost/ghost-2.jpg";
import ghost3 from "./pictures/ghost/ghost-3.jpg";
import heavyweights1 from "./pictures/heavyweights/heavyweights-1.jpg";
import heavyweights2 from "./pictures/heavyweights/heavyweights-2.jpg";
import heavyweights3 from "./pictures/heavyweights/heavyweights-3.jpg";
import spiderverse1 from "./pictures/into-spider-verse/into-spider-verse-1.jpg";
import spiderverse2 from "./pictures/into-spider-verse/into-spider-verse-2.jpg";
import spiderverse3 from "./pictures/into-spider-verse/into-spider-verse-3.jpg";
import kingArthur1 from "./pictures/kid-king-arthurs/kid-king-arthurs-1.jpeg";
import kingArthur2 from "./pictures/kid-king-arthurs/kid-king-arthurs-2.jpeg";
import kingArthur3 from "./pictures/kid-king-arthurs/kid-king-arthurs-3.jpeg";
import mallrats1 from "./pictures/mallrats/mallrats-1.jpeg";
import mallrats2 from "./pictures/mallrats/mallrats-2.jpeg";
import mallrats3 from "./pictures/mallrats/mallrats-3.jpeg";
import midnightSpecial1 from "./pictures/midnight-special/midnight-special-1.jpeg";
import midnightSpecial2 from "./pictures/midnight-special/midnight-special-2.jpeg";
import midnightSpecial3 from "./pictures/midnight-special/midnight-special-3.jpeg";
import clueless1 from "./pictures/clueless/clueless-1.jpg";
import clueless2 from "./pictures/clueless/clueless-2.jpg";
import clueless3 from "./pictures/clueless/clueless-3.jpg";
import maverick1 from "./pictures/maverick/maverick-1.jpeg";
import maverick2 from "./pictures/maverick/maverick-2.jpeg";
import maverick3 from "./pictures/maverick/maverick-3.jpeg";
import santaClause1 from "./pictures/santa-clause/santa-clause-1.jpeg";
import santaClause2 from "./pictures/santa-clause/santa-clause-2.jpeg";
import santaClause3 from "./pictures/santa-clause/santa-clause-3.jpeg";
import twins1 from "./pictures/twins/twins-1.jpeg";
import twins2 from "./pictures/twins/twins-2.jpeg";
import twins3 from "./pictures/twins/twins-3.jpeg";
import airheads1 from "./pictures/airheads/airheads-1.jpg";
import airheads2 from "./pictures/airheads/airheads-2.jpg";
import airheads3 from "./pictures/airheads/airheads-3.jpg";
import fisherking1 from "./pictures/fisher-king/fisher-king-1.jpg";
import fisherking2 from "./pictures/fisher-king/fisher-king-2.jpg";
import fisherking3 from "./pictures/fisher-king/fisher-king-3.jpg";
import hocusPocus1 from "./pictures/hocus-pocus/hocus-pocus-1.jpg";
import hocusPocus2 from "./pictures/hocus-pocus/hocus-pocus-2.jpg";
import hocusPocus3 from "./pictures/hocus-pocus/hocus-pocus-3.jpg";
import sandlot1 from "./pictures/sandlot/sandlot-1.jpeg";
import sandlot2 from "./pictures/sandlot/sandlot-2.jpeg";
import sandlot3 from "./pictures/sandlot/sandlot-3.jpeg";
import standByMe1 from "./pictures/stand-by-me/stand-by-me-1.jpeg";
import standByMe2 from "./pictures/stand-by-me/stand-by-me-2.jpeg";
import standByMe3 from "./pictures/stand-by-me/stand-by-me-3.jpeg";
import silverLake1 from "./pictures/under-silver-lake/under-silver-lake-1.jpeg";
import silverLake2 from "./pictures/under-silver-lake/under-silver-lake-2.jpeg";
import silverLake3 from "./pictures/under-silver-lake/under-silver-lake-3.jpeg";
import bernies1 from "./pictures/weekend-bernies/weekend-bernies-1.jpeg";
import bernies2 from "./pictures/weekend-bernies/weekend-bernies-2.jpeg";
import bernies3 from "./pictures/weekend-bernies/weekend-bernies-3.jpeg";
import conjuringThree1 from "./pictures/conjuring-3/conjuring-3-1.jpg";
import conjuringThree2 from "./pictures/conjuring-3/conjuring-3-2.jpg";
import conjuringThree3 from "./pictures/conjuring-3/conjuring-3-3.jpg";
import cousinVinny1 from "./pictures/cousin-vinny/cousin-vinny-1.jpg";
import cousinVinny2 from "./pictures/cousin-vinny/cousin-vinny-2.jpg";
import cousinVinny3 from "./pictures/cousin-vinny/cousin-vinny-3.jpg";
import grumpierOldMen1 from "./pictures/grumpier-old-men/grumpier-old-men-1.jpg";
import grumpierOldMen2 from "./pictures/grumpier-old-men/grumpier-old-men-2.jpg";
import grumpierOldMen3 from "./pictures/grumpier-old-men/grumpier-old-men-3.jpg";
import addwater1 from "./pictures/just-add-water/just-add-water-1.jpg";
import addwater2 from "./pictures/just-add-water/just-add-water-2.jpg";
import addwater3 from "./pictures/just-add-water/just-add-water-3.jpg";
import ladybugs1 from "./pictures/ladybugs/ladybugs-1.jpeg";
import ladybugs2 from "./pictures/ladybugs/ladybugs-2.jpeg";
import ladybugs3 from "./pictures/ladybugs/ladybugs-3.jpeg";
import perfectScore1 from "./pictures/perfect-score/perfect-score-1.jpeg";
import perfectScore2 from "./pictures/perfect-score/perfect-score-2.jpeg";
import perfectScore3 from "./pictures/perfect-score/perfect-score-3.jpeg";
import quietplaceTwo1 from "./pictures/quiet-place-2/quiet-place-2-1.jpeg";
import quietplaceTwo2 from "./pictures/quiet-place-2/quiet-place-2-2.jpeg";
import quietplaceTwo3 from "./pictures/quiet-place-2/quiet-place-2-3.jpeg";
import revengeNerds1 from "./pictures/revenge-nerds/revenge-nerds-1.jpeg";
import revengeNerds2 from "./pictures/revenge-nerds/revenge-nerds-2.jpeg";
import revengeNerds3 from "./pictures/revenge-nerds/revenge-nerds-3.jpeg";
import stopMomShoot1 from "./pictures/stop-mom-shoot/stop-mom-shoot-1.jpeg";
import stopMomShoot2 from "./pictures/stop-mom-shoot/stop-mom-shoot-2.jpeg";
import stopMomShoot3 from "./pictures/stop-mom-shoot/stop-mom-shoot-3.jpeg";
import braindead1 from "./pictures/braindead/braindead-1.jpg";
import braindead2 from "./pictures/braindead/braindead-2.jpg";
import braindead3 from "./pictures/braindead/braindead-3.jpg";
import homeAlone21 from "./pictures/home-alone-2/home-alone-2-1.jpg";
import homeAlone22 from "./pictures/home-alone-2/home-alone-2-2.jpg";
import homeAlone23 from "./pictures/home-alone-2/home-alone-2-3.jpg";
import blewUp1 from "./pictures/honey-up-kid/honey-up-kid-1.jpg";
import blewUp2 from "./pictures/honey-up-kid/honey-up-kid-2.jpg";
import blewUp3 from "./pictures/honey-up-kid/honey-up-kid-3.jpg";
import gilbertGrape1 from "./pictures/gilbert-grape/gilbert-grape-1.jpg";
import gilbertGrape2 from "./pictures/gilbert-grape/gilbert-grape-2.jpg";
import gilbertGrape3 from "./pictures/gilbert-grape/gilbert-grape-3.jpg";
import gremlins21 from "./pictures/gremlins-2/gremlins-2-1.jpg";
import gremlins22 from "./pictures/gremlins-2/gremlins-2-2.jpg";
import gremlins23 from "./pictures/gremlins-2/gremlins-2-3.jpg";
import gremlins1 from "./pictures/gremlins/gremlins-1.jpg";
import gremlins2 from "./pictures/gremlins/gremlins-2.jpg";
import gremlins3 from "./pictures/gremlins/gremlins-3.jpg";
import jerk1 from "./pictures/jerk/jerk-1.jpg";
import jerk2 from "./pictures/jerk/jerk-2.jpg";
import jerk3 from "./pictures/jerk/jerk-3.jpg";
import jingle1 from "./pictures/jingle-all-way/jingle-all-way-1.jpg";
import jingle2 from "./pictures/jingle-all-way/jingle-all-way-2.jpg";
import jingle3 from "./pictures/jingle-all-way/jingle-all-way-3.jpg";
import kindergarten1 from "./pictures/kindergarten-cop/kindergarten-cop-1.jpeg";
import kindergarten2 from "./pictures/kindergarten-cop/kindergarten-cop-2.jpeg";
import kindergarten3 from "./pictures/kindergarten-cop/kindergarten-cop-3.jpeg";
import matilda1 from "./pictures/matilda/matilda-1.jpeg";
import matilda2 from "./pictures/matilda/matilda-2.jpeg";
import matilda3 from "./pictures/matilda/matilda-3.jpeg";
import nakedGun1 from "./pictures/naked-gun/naked-gun-1.jpeg";
import nakedGun2 from "./pictures/naked-gun/naked-gun-2.jpeg";
import nakedGun3 from "./pictures/naked-gun/naked-gun-3.jpeg";
import patchAdams1 from "./pictures/patch-adams/patch-adams-1.jpeg";
import patchAdams2 from "./pictures/patch-adams/patch-adams-2.jpeg";
import patchAdams3 from "./pictures/patch-adams/patch-adams-3.jpeg";
import romeoJuliet1 from "./pictures/romeo-juliet/romeo-juliet-1.jpeg";
import romeoJuliet2 from "./pictures/romeo-juliet/romeo-juliet-2.jpeg";
import romeoJuliet3 from "./pictures/romeo-juliet/romeo-juliet-3.jpeg";
import kabukiman1 from "./pictures/sgt-kabukiman/sgt-kabukiman-1.jpeg";
import kabukiman2 from "./pictures/sgt-kabukiman/sgt-kabukiman-2.jpeg";
import kabukiman3 from "./pictures/sgt-kabukiman/sgt-kabukiman-3.jpeg";
import uncleBuck1 from "./pictures/uncle-buck/uncle-buck-1.jpeg";
import uncleBuck2 from "./pictures/uncle-buck/uncle-buck-2.jpeg";
import uncleBuck3 from "./pictures/uncle-buck/uncle-buck-3.jpeg";
import farFromHome1 from "./pictures/far-from-home/far-from-home-1.jpg";
import farFromHome2 from "./pictures/far-from-home/far-from-home-2.jpg";
import farFromHome3 from "./pictures/far-from-home/far-from-home-3.jpg";
import guilty1 from "./pictures/guilty/guilty-1.jpg";
import guilty2 from "./pictures/guilty/guilty-2.jpg";
import guilty3 from "./pictures/guilty/guilty-3.jpg";
import inYourHouse1 from "./pictures/in-your-house/in-your-house-1.jpg";
import inYourHouse2 from "./pictures/in-your-house/in-your-house-2.jpg";
import inYourHouse3 from "./pictures/in-your-house/in-your-house-3.jpg";
import miFallout1 from "./pictures/mi-fallout/mi-fallout-1.jpeg";
import miFallout2 from "./pictures/mi-fallout/mi-fallout-2.jpeg";
import miFallout3 from "./pictures/mi-fallout/mi-fallout-3.jpeg";
import wander1 from "./pictures/wander/wander-1.jpeg";
import wander2 from "./pictures/wander/wander-2.jpeg";
import wander3 from "./pictures/wander/wander-3.jpeg";
import livingDaylights1 from "./pictures/living-daylights/living-daylights-1.jpeg";
import livingDaylights2 from "./pictures/living-daylights/living-daylights-2.jpeg";
import livingDaylights3 from "./pictures/living-daylights/living-daylights-3.jpeg";
import sayAnything1 from "./pictures/say-anything/say-anything-1.jpeg";
import sayAnything2 from "./pictures/say-anything/say-anything-2.jpeg";
import sayAnything3 from "./pictures/say-anything/say-anything-3.jpeg";
import babysitter1 from "./pictures/babysitter/babysitter-1.jpg";
import babysitter2 from "./pictures/babysitter/babysitter-2.jpg";
import babysitter3 from "./pictures/babysitter/babysitter-3.jpg";
import babysitter21 from "./pictures/babysitter-2/babysitter-2-1.jpg";
import babysitter22 from "./pictures/babysitter-2/babysitter-2-2.jpg";
import babysitter23 from "./pictures/babysitter-2/babysitter-2-3.jpg";
import knightsBadass1 from "./pictures/knights-badass/knights-badass-1.jpeg";
import knightsBadass2 from "./pictures/knights-badass/knights-badass-2.jpeg";
import knightsBadass3 from "./pictures/knights-badass/knights-badass-3.jpeg";
import palmer1 from "./pictures/palmer/palmer-1.jpeg";
import palmer2 from "./pictures/palmer/palmer-2.jpeg";
import palmer3 from "./pictures/palmer/palmer-3.jpeg";
import mysteryTeam1 from "./pictures/mystery-team/mystery-team-1.jpeg";
import mysteryTeam2 from "./pictures/mystery-team/mystery-team-2.jpeg";
import mysteryTeam3 from "./pictures/mystery-team/mystery-team-3.jpeg";
import almostFamous1 from "./pictures/almost-famous/almost-famous-1.jpg";
import almostFamous2 from "./pictures/almost-famous/almost-famous-2.jpg";
import almostFamous3 from "./pictures/almost-famous/almost-famous-3.jpg";
import parasite1 from "./pictures/parasite/parasite-1.jpeg";
import parasite2 from "./pictures/parasite/parasite-2.jpeg";
import parasite3 from "./pictures/parasite/parasite-3.jpeg";
import jack1 from "./pictures/jack/jack-1.jpg";
import jack2 from "./pictures/jack/jack-2.jpg";
import jack3 from "./pictures/jack/jack-3.jpg";
import scream1 from "./pictures/scream/scream-1.jpeg";
import scream2 from "./pictures/scream/scream-2.jpeg";
import scream3 from "./pictures/scream/scream-3.jpeg";
import beingRicardos1 from "./pictures/being-ricardos/being-ricardos-1.jpg";
import beingRicardos2 from "./pictures/being-ricardos/being-ricardos-2.jpg";
import beingRicardos3 from "./pictures/being-ricardos/being-ricardos-3.jpg";
import carrie1 from "./pictures/carrie/carrie-1.jpg";
import carrie2 from "./pictures/carrie/carrie-2.jpg";
import carrie3 from "./pictures/carrie/carrie-3.jpg";
import dune1 from "./pictures/dune/dune-1.jpg";
import dune2 from "./pictures/dune/dune-2.jpg";
import dune3 from "./pictures/dune/dune-3.jpg";
import babyDriver1 from "./pictures/baby-driver/baby-driver-1.jpg";
import babyDriver2 from "./pictures/baby-driver/baby-driver-2.jpg";
import babyDriver3 from "./pictures/baby-driver/baby-driver-3.jpg";
import englishPatient1 from "./pictures/english-patient/english-patient-1.jpg";
import englishPatient2 from "./pictures/english-patient/english-patient-2.jpg";
import englishPatient3 from "./pictures/english-patient/english-patient-3.jpg";
import godCommittee1 from "./pictures/god-committee/god-committee-1.jpg";
import godCommittee2 from "./pictures/god-committee/god-committee-2.jpg";
import godCommittee3 from "./pictures/god-committee/god-committee-3.jpg";
import midnightInParis1 from "./pictures/midnight-paris/midnight-paris-1.jpeg";
import midnightInParis2 from "./pictures/midnight-paris/midnight-paris-2.jpeg";
import midnightInParis3 from "./pictures/midnight-paris/midnight-paris-3.jpeg";
import number231 from "./pictures/number-23/number-23-1.jpeg";
import number232 from "./pictures/number-23/number-23-2.jpeg";
import number233 from "./pictures/number-23/number-23-3.jpeg";
import dieHard1 from "./pictures/die-hard/die-hard-1.jpg";
import dieHard2 from "./pictures/die-hard/die-hard-2.jpg";
import dieHard3 from "./pictures/die-hard/die-hard-3.jpg";
import hateful81 from "./pictures/hateful-eight/hateful-eight-1.jpg";
import hateful82 from "./pictures/hateful-eight/hateful-eight-2.jpg";
import hateful83 from "./pictures/hateful-eight/hateful-eight-3.jpg";
import vegasVaca1 from "./pictures/vegas-vaca/vegas-vaca-1.jpeg";
import vegasVaca2 from "./pictures/vegas-vaca/vegas-vaca-2.jpeg";
import vegasVaca3 from "./pictures/vegas-vaca/vegas-vaca-3.jpeg";
import onceHollywood1 from "./pictures/once-hollywood/once-hollywood-1.jpeg";
import onceHollywood2 from "./pictures/once-hollywood/once-hollywood-2.jpeg";
import onceHollywood3 from "./pictures/once-hollywood/once-hollywood-3.jpeg";
import captainRon1 from "./pictures/captain-ron/captain-ron-1.jpg";
import captainRon2 from "./pictures/captain-ron/captain-ron-2.jpg";
import captainRon3 from "./pictures/captain-ron/captain-ron-3.jpg";
import macgruber1 from "./pictures/macgruber/macgruber-1.jpeg";
import macgruber2 from "./pictures/macgruber/macgruber-2.jpeg";
import macgruber3 from "./pictures/macgruber/macgruber-3.jpeg";
import cannibalHolocaust1 from "./pictures/can-holocaust/can-holocaust-1.jpg";
import cannibalHolocaust2 from "./pictures/can-holocaust/can-holocaust-2.jpg";
import cannibalHolocaust3 from "./pictures/can-holocaust/can-holocaust-3.jpg";
import zoolander1 from "./pictures/zoolander/zoolander-1.jpeg";
import zoolander2 from "./pictures/zoolander/zoolander-2.jpeg";
import zoolander3 from "./pictures/zoolander/zoolander-3.jpeg";
import raiders1 from "./pictures/raiders-ark/raiders-1.jpeg";
import raiders2 from "./pictures/raiders-ark/raiders-2.jpeg";
import raiders3 from "./pictures/raiders-ark/raiders-3.jpeg";
import nighthawks1 from "./pictures/nighthawks/nighthawks-1.jpeg";
import nighthawks2 from "./pictures/nighthawks/nighthawks-2.jpeg";
import nighthawks3 from "./pictures/nighthawks/nighthawks-3.jpeg";
import templeDoom1 from "./pictures/temple-doom/temple-doom-1.jpeg";
import templeDoom2 from "./pictures/temple-doom/temple-doom-2.jpeg";
import templeDoom3 from "./pictures/temple-doom/temple-doom-3.jpeg";
import lastCrusade1 from "./pictures/last-crusade/last-crusade-1.jpeg";
import lastCrusade2 from "./pictures/last-crusade/last-crusade-2.jpeg";
import lastCrusade3 from "./pictures/last-crusade/last-crusade-3.jpeg";
import fly1 from "./pictures/the-fly/fly-1.jpeg";
import fly2 from "./pictures/the-fly/fly-2.jpeg";
import fly3 from "./pictures/the-fly/fly-3.jpeg";
import runningMan1 from "./pictures/running-man/running-man-1.jpeg";
import runningMan2 from "./pictures/running-man/running-man-2.jpeg";
import runningMan3 from "./pictures/running-man/running-man-3.jpeg";
import lawCitizen1 from "./pictures/abiding-citizen/abiding-citizen-1.jpg";
import lawCitizen2 from "./pictures/abiding-citizen/abiding-citizen-2.jpg";
import lawCitizen3 from "./pictures/abiding-citizen/abiding-citizen-3.jpg";
import paulBlart1 from "./pictures/paul-blart/paul-blart-1.jpeg";
import paulBlart2 from "./pictures/paul-blart/paul-blart-2.jpeg";
import paulBlart3 from "./pictures/paul-blart/paul-blart-3.jpeg";
import wrongMissy1 from "./pictures/wrong-missy/wrong-missy-1.jpeg";
import wrongMissy2 from "./pictures/wrong-missy/wrong-missy-2.jpeg";
import wrongMissy3 from "./pictures/wrong-missy/wrong-missy-3.jpeg";
import mincemeat1 from "./pictures/mincemeat/mincemeat-1.jpeg";
import mincemeat2 from "./pictures/mincemeat/mincemeat-2.jpeg";
import mincemeat3 from "./pictures/mincemeat/mincemeat-3.jpeg";
import twoWeeks1 from "./pictures/two-weeks/two-weeks-1.jpeg";
import twoWeeks2 from "./pictures/two-weeks/two-weeks-2.jpeg";
import twoWeeks3 from "./pictures/two-weeks/two-weeks-3.jpeg";
import rascals1 from "./pictures/little-rascals/little-rascals-1.jpeg";
import rascals2 from "./pictures/little-rascals/little-rascals-2.jpeg";
import rascals3 from "./pictures/little-rascals/little-rascals-3.jpeg";
import primalFear1 from "./pictures/primal-fear/primal-fear-1.jpeg";
import primalFear2 from "./pictures/primal-fear/primal-fear-2.jpeg";
import primalFear3 from "./pictures/primal-fear/primal-fear-3.jpeg";
import drive1 from "./pictures/drive/drive-1.jpg";
import drive2 from "./pictures/drive/drive-2.jpg";
import drive3 from "./pictures/drive/drive-3.jpg";
import sherlockHolmes1 from "./pictures/sherlock-holmes/sherlock-holmes-1.jpeg";
import sherlockHolmes2 from "./pictures/sherlock-holmes/sherlock-holmes-2.jpeg";
import sherlockHolmes3 from "./pictures/sherlock-holmes/sherlock-holmes-3.jpeg";
import skyfall1 from "./pictures/skyfall/skyfall-1.jpeg";
import skyfall2 from "./pictures/skyfall/skyfall-2.jpeg";
import skyfall3 from "./pictures/skyfall/skyfall-3.jpeg";
import doubleTeam1 from "./pictures/double-team/double-team-1.jpg";
import doubleTeam2 from "./pictures/double-team/double-team-2.jpg";
import doubleTeam3 from "./pictures/double-team/double-team-3.jpg";
import cliffhanger1 from "./pictures/cliffhanger/cliffhanger-1.jpg";
import cliffhanger2 from "./pictures/cliffhanger/cliffhanger-2.jpg";
import cliffhanger3 from "./pictures/cliffhanger/cliffhanger-3.jpg";
import logan1 from "./pictures/logan/logan-1.jpeg";
import logan2 from "./pictures/logan/logan-2.jpeg";
import logan3 from "./pictures/logan/logan-3.jpeg";
import onceamerica1 from "./pictures/once-america/once-america-1.jpeg";
import onceamerica2 from "./pictures/once-america/once-america-2.jpeg";
import onceamerica3 from "./pictures/once-america/once-america-3.jpeg";
import copland1 from "./pictures/copland/copland-1.jpg";
import copland2 from "./pictures/copland/copland-2.jpg";
import copland3 from "./pictures/copland/copland-3.jpg";
import escapeLA1 from "./pictures/escape-from-la/escape-la-1.jpg";
import escapeLA2 from "./pictures/escape-from-la/escape-la-2.jpg";
import escapeLA3 from "./pictures/escape-from-la/escape-la-3.jpg";
import tommyboy1 from "./pictures/tommy-boy/tommy-boy-1.jpeg";
import tommyboy2 from "./pictures/tommy-boy/tommy-boy-2.jpeg";
import tommyboy3 from "./pictures/tommy-boy/tommy-boy-3.jpeg";
import policeStory1 from "./pictures/police-story/police-story-1.jpeg";
import policeStory2 from "./pictures/police-story/police-story-2.jpeg";
import policeStory3 from "./pictures/police-story/police-story-3.jpeg";
import policeStory21 from "./pictures/police-story-2/police-story-21.jpeg";
import policeStory22 from "./pictures/police-story-2/police-story-22.jpeg";
import policeStory23 from "./pictures/police-story-2/police-story-23.jpeg";
import supercop1 from "./pictures/supercop/supercop-1.jpeg";
import supercop2 from "./pictures/supercop/supercop-2.jpeg";
import supercop3 from "./pictures/supercop/supercop-3.jpeg";
import meangirls1 from "./pictures/mean-girls/mean-girls-1.jpeg";
import meangirls2 from "./pictures/mean-girls/mean-girls-2.jpeg";
import meangirls3 from "./pictures/mean-girls/mean-girls-3.jpeg";
import inception1 from "./pictures/inception/inception-1.jpg";
import inception2 from "./pictures/inception/inception-2.jpg";
import inception3 from "./pictures/inception/inception-3.jpg";
import ironman21 from "./pictures/ironman2/ironman2-1.jpg";
import ironman22 from "./pictures/ironman2/ironman2-2.jpg";
import ironman23 from "./pictures/ironman2/ironman2-3.jpg";
import machinist1 from "./pictures/machinist/machinist-1.jpeg";
import machinist2 from "./pictures/machinist/machinist-2.jpeg";
import machinist3 from "./pictures/machinist/machinist-3.jpeg";
import mulholland1 from "./pictures/mulholland-drive/mulholland-1.jpeg";
import mulholland2 from "./pictures/mulholland-drive/mulholland-2.jpeg";
import mulholland3 from "./pictures/mulholland-drive/mulholland-3.jpeg";
import battleRoyale1 from "./pictures/battle-royale/battle-royale-1.jpg";
import battleRoyale2 from "./pictures/battle-royale/battle-royale-2.jpg";
import battleRoyale3 from "./pictures/battle-royale/battle-royale-3.jpg";
import collisionCourse1 from "./pictures/collision-course/collision-course-1.jpg";
import collisionCourse2 from "./pictures/collision-course/collision-course-2.jpg";
import collisionCourse3 from "./pictures/collision-course/collision-course-3.jpg";
import innerspace1 from "./pictures/innerspace/innerspace-1.jpg";
import innerspace2 from "./pictures/innerspace/innerspace-2.jpg";
import innerspace3 from "./pictures/innerspace/innerspace-3.jpg";
import grayman1 from "./pictures/gray-man/gray-man-1.jpg";
import grayman2 from "./pictures/gray-man/gray-man-2.jpg";
import grayman3 from "./pictures/gray-man/gray-man-3.jpg";
import nope1 from "./pictures/nope/nope-1.jpeg";
import nope2 from "./pictures/nope/nope-2.jpeg";
import nope3 from "./pictures/nope/nope-3.jpeg";
import catseye1 from "./pictures/cats-eye/cats-eye-1.jpg";
import catseye2 from "./pictures/cats-eye/cats-eye-2.jpg";
import catseye3 from "./pictures/cats-eye/cats-eye-3.jpg";
import honorsociety1 from "./pictures/honor-society/honor-society-1.jpg";
import honorsociety2 from "./pictures/honor-society/honor-society-2.jpg";
import honorsociety3 from "./pictures/honor-society/honor-society-3.jpg";
import empireSun1 from "./pictures/empire-sun/empire-sun-1.jpg";
import empireSun2 from "./pictures/empire-sun/empire-sun-2.jpg";
import empireSun3 from "./pictures/empire-sun/empire-sun-3.jpg";
import perdition1 from "./pictures/perdition/perdition-1.jpeg";
import perdition2 from "./pictures/perdition/perdition-2.jpeg";
import perdition3 from "./pictures/perdition/perdition-3.jpeg";
import pointbreak1 from "./pictures/point-break/point-break-1.jpeg";
import pointbreak2 from "./pictures/point-break/point-break-2.jpeg";
import pointbreak3 from "./pictures/point-break/point-break-3.jpeg";
import out4just1 from "./pictures/out-4-just/out-4-just-1.jpeg";
import out4just2 from "./pictures/out-4-just/out-4-just-2.jpeg";
import out4just3 from "./pictures/out-4-just/out-4-just-3.jpeg";
import babysitting1 from "./pictures/adventures-babysitting/babysitting-1.jpg";
import babysitting2 from "./pictures/adventures-babysitting/babysitting-2.jpg";
import babysitting3 from "./pictures/adventures-babysitting/babysitting-3.jpg";
import grind1 from "./pictures/mississippi-grind/grind-1.jpeg";
import grind2 from "./pictures/mississippi-grind/grind-2.jpeg";
import grind3 from "./pictures/mississippi-grind/grind-3.jpeg";
import fourthJuly1 from "./pictures/fourth-july/fourthJuly-1.jpg";
import fourthJuly2 from "./pictures/fourth-july/fourthJuly-2.jpg";
import fourthJuly3 from "./pictures/fourth-july/fourthJuly-3.jpg";
import thelma1 from "./pictures/thelma/thelma-1.jpeg";
import thelma2 from "./pictures/thelma/thelma-2.jpeg";
import thelma3 from "./pictures/thelma/thelma-3.jpeg";
import seriousMen1 from "./pictures/serious-men/serious-men-1.jpeg";
import seriousMen2 from "./pictures/serious-men/serious-men-2.jpeg";
import seriousMen3 from "./pictures/serious-men/serious-men-3.jpeg";
import uncommonValor1 from "./pictures/uncommon-valor/valor-1.jpeg";
import uncommonValor2 from "./pictures/uncommon-valor/valor-2.jpeg";
import uncommonValor3 from "./pictures/uncommon-valor/valor-3.jpeg";
import topgunMaverick1 from "./pictures/top-gun-maverick/topgun-maverick-1.webp";
import topgunMaverick2 from "./pictures/top-gun-maverick/topgun-maverick-2.webp";
import topgunMaverick3 from "./pictures/top-gun-maverick/topgun-maverick-3.webp";
import elvis1 from "./pictures/elvis/elvis-1.webp";
import elvis2 from "./pictures/elvis/elvis-2.webp";
import elvis3 from "./pictures/elvis/elvis-3.webp";
import dirtyHarry1 from "./pictures/dirty-harry/dirty-harry-1.jpg";
import dirtyHarry2 from "./pictures/dirty-harry/dirty-harry-2.jpg";
import dirtyHarry3 from "./pictures/dirty-harry/dirty-harry-3.jpg";
import splash1 from "./pictures/splash/splash-1.jpeg";
import splash2 from "./pictures/splash/splash-2.jpeg";
import splash3 from "./pictures/splash/splash-3.jpeg";
import ducks1 from "./pictures/mighty-ducks/ducks-1.jpeg";
import ducks2 from "./pictures/mighty-ducks/ducks-2.jpeg";
import ducks3 from "./pictures/mighty-ducks/ducks-3.jpeg";
import ducks21 from "./pictures/mighty-ducks-2/ducks-21.jpeg";
import ducks22 from "./pictures/mighty-ducks-2/ducks-22.jpeg";
import ducks23 from "./pictures/mighty-ducks-2/ducks-23.jpeg";
import eventHorizon1 from "./pictures/event-horizon/horizon-1.jpg";
import eventHorizon2 from "./pictures/event-horizon/horizon-2.webp";
import eventHorizon3 from "./pictures/event-horizon/horizon-3.webp";
import lastLooks1 from "./pictures/last-looks/last-looks-1.jpeg";
import lastLooks2 from "./pictures/last-looks/last-looks-2.webp";
import lastLooks3 from "./pictures/last-looks/last-looks-3.webp";
import nineteen1 from "./pictures/1922/1922-1.jpeg";
import nineteen2 from "./pictures/1922/1922-2.jpeg";
import nineteen3 from "./pictures/1922/1922-3.jpeg";
import grimsby1 from "./pictures/brothers-grimsby/grimsby-1.jpg";
import grimsby2 from "./pictures/brothers-grimsby/grimsby-2.jpg";
import grimsby3 from "./pictures/brothers-grimsby/grimsby-3.jpg";
import dictator1 from "./pictures/the-dictator/dictator-1.webp";
import dictator2 from "./pictures/the-dictator/dictator-2.webp";
import dictator3 from "./pictures/the-dictator/dictator-3.webp";
import careerOpportunities1 from "./pictures/career-opportunities/career-ops-1.jpg";
import careerOpportunities2 from "./pictures/career-opportunities/career-ops-2.jpg";
import careerOpportunities3 from "./pictures/career-opportunities/career-ops-3.jpg";
import scarface1 from "./pictures/scarface/scarface-1.jpeg";
import scarface2 from "./pictures/scarface/scarface-2.jpeg";
import scarface3 from "./pictures/scarface/scarface-3.jpeg";
import seerun1 from "./pictures/see-run/seerun-1.jpeg";
import seerun2 from "./pictures/see-run/seerun-2.jpeg";
import seerun3 from "./pictures/see-run/seerun-3.jpeg";
import fatherstu1 from "./pictures/father-stu/father-stu-1.jpg";
import fatherstu2 from "./pictures/father-stu/father-stu-2.jpg";
import fatherstu3 from "./pictures/father-stu/father-stu-3.jpg";
import angelheart1 from "./pictures/angel-heart/angel-heart-1.jpg";
import angelheart2 from "./pictures/angel-heart/angel-heart-2.jpg";
import angelheart3 from "./pictures/angel-heart/angel-heart-3.jpg";
import existenz1 from "./pictures/existenz/existenz-1.jpg";
import existenz2 from "./pictures/existenz/existenz-2.jpg";
import existenz3 from "./pictures/existenz/existenz-3.jpg";
import werewolvesin1 from "./pictures/werewolves-in/werewolves-in-1.jpeg";
import werewolvesin2 from "./pictures/werewolves-in/werewolves-in-2.jpeg";
import werewolvesin3 from "./pictures/werewolves-in/werewolves-in-3.jpeg";
import wolfhollow1 from "./pictures/wolf-hollow/wolf-hollow-1.jpeg";
import wolfhollow2 from "./pictures/wolf-hollow/wolf-hollow-2.jpeg";
import wolfhollow3 from "./pictures/wolf-hollow/wolf-hollow-3.jpeg";
import ghostworld1 from "./pictures/ghost-world/ghost-world-1.jpg";
import ghostworld2 from "./pictures/ghost-world/ghost-world-2.jpg";
import ghostworld3 from "./pictures/ghost-world/ghost-world-3.jpg";
import raisingcain1 from "./pictures/raising-cain/raising-cain-1.jpeg";
import raisingcain2 from "./pictures/raising-cain/raising-cain-2.jpeg";
import raisingcain3 from "./pictures/raising-cain/raising-cain-3.jpeg";
import neverhere1 from "./pictures/never-here/never-here-1.jpeg";
import neverhere2 from "./pictures/never-here/never-here-2.jpeg";
import neverhere3 from "./pictures/never-here/never-here-3.jpeg";
import urbanlegend1 from "./pictures/urban-legend/urban-legend-1.jpeg";
import urbanlegend2 from "./pictures/urban-legend/urban-legend-2.jpeg";
import urbanlegend3 from "./pictures/urban-legend/urban-legend-3.jpeg";
import vibes1 from "./pictures/vibes/vibes-1.jpeg";
import vibes2 from "./pictures/vibes/vibes-2.jpeg";
import vibes3 from "./pictures/vibes/vibes-3.jpeg";
import lastsoho1 from "./pictures/last-soho/last-soho-1.jpeg";
import lastsoho2 from "./pictures/last-soho/last-soho-2.jpeg";
import lastsoho3 from "./pictures/last-soho/last-soho-3.jpeg";
import dayshift1 from "./pictures/day-shift/day-shift-1.jpg";
import dayshift2 from "./pictures/day-shift/day-shift-2.jpg";
import dayshift3 from "./pictures/day-shift/day-shift-3.jpg";
import cityslickers1 from "./pictures/city-slickers/city-slickers-1.jpg";
import cityslickers2 from "./pictures/city-slickers/city-slickers-2.jpg";
import cityslickers3 from "./pictures/city-slickers/city-slickers-3.jpg";
import quietplace1 from "./pictures/quiet-place/quiet-place-1.jpeg";
import quietplace2 from "./pictures/quiet-place/quiet-place-2.jpeg";
import quietplace3 from "./pictures/quiet-place/quiet-place-3.jpeg";
import witcheastwick1 from "./pictures/witch-eastwick/witch-eastwick-1.jpeg";
import witcheastwick2 from "./pictures/witch-eastwick/witch-eastwick-2.jpeg";
import witcheastwick3 from "./pictures/witch-eastwick/witch-eastwick-3.jpeg";
import house851 from "./pictures/house-85/house-85-1.jpg";
import house852 from "./pictures/house-85/house-85-2.jpg";
import house853 from "./pictures/house-85/house-85-3.jpg";
import hellraiser1 from "./pictures/hellraiser/hellraiser-1.jpg";
import hellraiser2 from "./pictures/hellraiser/hellraiser-2.jpg";
import hellraiser3 from "./pictures/hellraiser/hellraiser-3.jpg";
import stepfordwives1 from "./pictures/stepford-wives/stepford-wives-1.jpeg";
import stepfordwives2 from "./pictures/stepford-wives/stepford-wives-2.jpeg";
import stepfordwives3 from "./pictures/stepford-wives/stepford-wives-3.jpeg";
import frightnight1 from "./pictures/fright-night/fright-night-1.jpg";
import frightnight2 from "./pictures/fright-night/fright-night-2.jpg";
import frightnight3 from "./pictures/fright-night/fright-night-3.jpg";
import searching1 from "./pictures/searching/searching-1.jpeg";
import searching2 from "./pictures/searching/searching-2.jpeg";
import searching3 from "./pictures/searching/searching-3.jpeg";
import lifeforce1 from "./pictures/lifeforce/lifeforce-1.jpeg";
import lifeforce2 from "./pictures/lifeforce/lifeforce-2.jpeg";
import lifeforce3 from "./pictures/lifeforce/lifeforce-3.jpeg";
import friendsexorcism1 from "./pictures/friends-exorcism/friends-exorcism-1.jpg";
import friendsexorcism2 from "./pictures/friends-exorcism/friends-exorcism-2.jpg";
import friendsexorcism3 from "./pictures/friends-exorcism/friends-exorcism-3.jpg";
import apocalypto1 from "./pictures/apocalypto/apocalypto-1.webp";
import apocalypto2 from "./pictures/apocalypto/apocalypto-2.jpg";
import apocalypto3 from "./pictures/apocalypto/apocalypto-3.jpg";
import beemovie1 from "./pictures/bee-movie/bee-movie-1.jpg";
import beemovie2 from "./pictures/bee-movie/bee-movie-2.jpg";
import beemovie3 from "./pictures/bee-movie/bee-movie-3.jpg";
import nowsgood1 from "./pictures/nows-good/nows-good-1.jpeg";
import nowsgood2 from "./pictures/nows-good/nows-good-2.jpeg";
import nowsgood3 from "./pictures/nows-good/nows-good-3.jpeg";
import paradiseAlley1 from "./pictures/paradise-alley/paradise-1.jpeg";
import paradiseAlley2 from "./pictures/paradise-alley/paradise-2.jpeg";
import paradiseAlley3 from "./pictures/paradise-alley/paradise-3.jpeg";
import rollingThunder1 from "./pictures/rolling-thunder/rolling-thunder-1.jpeg";
import rollingThunder2 from "./pictures/rolling-thunder/rolling-thunder-2.jpeg";
import rollingThunder3 from "./pictures/rolling-thunder/rolling-thunder-3.jpeg";
import hardcore1 from "./pictures/hardcore/hardcore-1.jpg";
import hardcore2 from "./pictures/hardcore/hardcore-2.jpg";
import hardcore3 from "./pictures/hardcore/hardcore-3.jpg";
import capphillips1 from "./pictures/cap-phillips/cap-phillips-1.jpg";
import capphillips2 from "./pictures/cap-phillips/cap-phillips-2.jpg";
import capphillips3 from "./pictures/cap-phillips/cap-phillips-3.jpg";
import facingnolan1 from "./pictures/facing-nolan/facing-nolan-1.jpg";
import facingnolan2 from "./pictures/facing-nolan/facing-nolan-2.jpg";
import facingnolan3 from "./pictures/facing-nolan/facing-nolan-3.jpg";
import aqotwf1 from "./pictures/aqotwf/aqotwf-1.jpg";
import aqotwf2 from "./pictures/aqotwf/aqotwf-2.jpg";
import aqotwf3 from "./pictures/aqotwf/aqotwf-3.jpg";
import carny1 from "./pictures/carny/carny-1.jpg";
import carny2 from "./pictures/carny/carny-2.jpg";
import carny3 from "./pictures/carny/carny-3.jpg";
import sittingTarget1 from "./pictures/sitting-target/sitting-target-1.jpeg";
import sittingTarget2 from "./pictures/sitting-target/sitting-target-2.jpeg";
import sittingTarget3 from "./pictures/sitting-target/sitting-target-3.jpeg";
import weirdal1 from "./pictures/weird-al/weird-al-1.jpeg";
import weirdal2 from "./pictures/weird-al/weird-al-2.jpeg";
import weirdal3 from "./pictures/weird-al/weird-al-3.jpeg";
import alligator1 from "./pictures/alligator/alligator-1.jpg";
import alligator2 from "./pictures/alligator/alligator-2.jpg";
import alligator3 from "./pictures/alligator/alligator-3.jpg";
import croupier1 from "./pictures/croupier/croupier-1.jpg";
import croupier2 from "./pictures/croupier/croupier-2.jpg";
import croupier3 from "./pictures/croupier/croupier-3.jpg";
import anySunday1 from "./pictures/any-given-sunday/sunday-1.webp";
import anySunday2 from "./pictures/any-given-sunday/sunday-2.jpg";
import anySunday3 from "./pictures/any-given-sunday/sunday-3.jpg";
import outrage1 from "./pictures/outrage/outrage-1.webp";
import outrage2 from "./pictures/outrage/outrage-2.jpeg";
import outrage3 from "./pictures/outrage/outrage-3.jpeg";
import badnewsbears1 from "./pictures/bad-news-bears/badnewsbears-1.jpg";
import badnewsbears2 from "./pictures/bad-news-bears/badnewsbears-2.jpg";
import badnewsbears3 from "./pictures/bad-news-bears/badnewsbears-3.jpg";
import spidermannwh1 from "./pictures/spiderman-nwh/spiderman-nwh-1.jpeg";
import spidermannwh2 from "./pictures/spiderman-nwh/spiderman-nwh-2.jpeg";
import spidermannwh3 from "./pictures/spiderman-nwh/spiderman-nwh-3.jpeg";
import crawdadssing1 from "./pictures/crawdads-sing/crawdads-sing-1.jpg";
import crawdadssing2 from "./pictures/crawdads-sing/crawdads-sing-2.jpg";
import crawdadssing3 from "./pictures/crawdads-sing/crawdads-sing-3.jpg";
import legotwo1 from "./pictures/lego-two/lego-two-1.jpeg";
import legotwo2 from "./pictures/lego-two/lego-two-2.jpeg";
import legotwo3 from "./pictures/lego-two/lego-two-3.jpeg";
import beachbum1 from "./pictures/beach-bum/beach-bum-1.jpg";
import beachbum2 from "./pictures/beach-bum/beach-bum-2.jpg";
import beachbum3 from "./pictures/beach-bum/beach-bum-3.jpg";
import amsterdam1 from "./pictures/amsterdam/amsterdam-1.jpg";
import amsterdam2 from "./pictures/amsterdam/amsterdam-2.jpg";
import amsterdam3 from "./pictures/amsterdam/amsterdam-3.jpg";
import heartsdarkness1 from "./pictures/hearts-darkness/hearts-darkness-1.jpg";
import heartsdarkness2 from "./pictures/hearts-darkness/hearts-darkness-2.jpg";
import heartsdarkness3 from "./pictures/hearts-darkness/hearts-darkness-3.jpg";
import pinocchio221 from "./pictures/pinocchio-22/pinocchio-22-1.jpeg";
import pinocchio222 from "./pictures/pinocchio-22/pinocchio-22-2.jpeg";
import pinocchio223 from "./pictures/pinocchio-22/pinocchio-22-3.jpeg";
import bigmomma1 from "./pictures/big-mommas-house/bigmomma-1.jpg";
import bigmomma2 from "./pictures/big-mommas-house/bigmomma-2.jpg";
import bigmomma3 from "./pictures/big-mommas-house/bigmomma-3.jpg";
import summernights1 from "./pictures/summer-nights/summer-nights-1.jpeg";
import summernights2 from "./pictures/summer-nights/summer-nights-2.jpeg";
import summernights3 from "./pictures/summer-nights/summer-nights-3.jpeg";
import soulplane1 from "./pictures/soul-plane/soul-plane-1.jpeg";
import soulplane2 from "./pictures/soul-plane/soul-plane-2.jpeg";
import soulplane3 from "./pictures/soul-plane/soul-plane-3.jpeg";
import honk41 from "./pictures/honk4/honk4-1.jpg";
import honk42 from "./pictures/honk4/honk4-2.jpg";
import honk43 from "./pictures/honk4/honk4-3.jpg";
import stelmos1 from "./pictures/st-elmos/st-elmos-1.jpeg";
import stelmos2 from "./pictures/st-elmos/st-elmos-2.jpeg";
import stelmos3 from "./pictures/st-elmos/st-elmos-3.jpeg";
import fletch1 from "./pictures/fletch/fletch-1.jpg";
import fletch2 from "./pictures/fletch/fletch-2.jpg";
import fletch3 from "./pictures/fletch/fletch-3.jpg";
import xstoryxmas1 from "./pictures/xstory-xmas/xstory-xmas-1.jpeg";
import xstoryxmas2 from "./pictures/xstory-xmas/xstory-xmas-2.jpeg";
import xstoryxmas3 from "./pictures/xstory-xmas/xstory-xmas-3.jpeg";
import dccab1 from "./pictures/dc-cab/dccab-1.jpg";
import dccab2 from "./pictures/dc-cab/dccab-2.jpg";
import dccab3 from "./pictures/dc-cab/dccab-3.jpg";
import fewgood1 from "./pictures/few-good/few-good-1.jpeg";
import fewgood2 from "./pictures/few-good/few-good-2.jpeg";
import fewgood3 from "./pictures/few-good/few-good-3.jpeg";
import thefirm1 from "./pictures/the-firm/the-firm-1.jpeg";
import thefirm2 from "./pictures/the-firm/the-firm-2.jpeg";
import thefirm3 from "./pictures/the-firm/the-firm-3.jpeg";
import whistanfox1 from "./pictures/whis-tan-fox/whis-tan-fox-1.jpeg";
import whistanfox2 from "./pictures/whis-tan-fox/whis-tan-fox-2.jpeg";
import whistanfox3 from "./pictures/whis-tan-fox/whis-tan-fox-3.jpeg";
import birdman1 from "./pictures/bird-man/bird-man-1.jpeg";
import birdman2 from "./pictures/bird-man/bird-man-2.jpeg";
import birdman3 from "./pictures/bird-man/bird-man-3.jpeg";
import struggle1 from "./pictures/struggle/struggle-1.jpeg";
import struggle2 from "./pictures/struggle/struggle-2.jpeg";
import struggle3 from "./pictures/struggle/struggle-3.jpeg";
import bethcooper1 from "./pictures/beth-cooper/beth-cooper-1.jpeg";
import bethcooper2 from "./pictures/beth-cooper/beth-cooper-2.jpeg";
import bethcooper3 from "./pictures/beth-cooper/beth-cooper-3.jpeg";
import thewonder1 from "./pictures/the-wonder/the-wonder-1.jpeg";
import thewonder2 from "./pictures/the-wonder/the-wonder-2.jpeg";
import thewonder3 from "./pictures/the-wonder/the-wonder-3.jpeg";
import losinit1 from "./pictures/losin-it/losin-it-1.jpeg";
import losinit2 from "./pictures/losin-it/losin-it-2.jpeg";
import losinit3 from "./pictures/losin-it/losin-it-3.jpeg";
import auntdiane1 from "./pictures/aunt-diane/aunt-diane-1.jpeg";
import auntdiane2 from "./pictures/aunt-diane/aunt-diane-2.jpeg";
import auntdiane3 from "./pictures/aunt-diane/aunt-diane-3.jpeg";
import daysofthunder1 from "./pictures/days-of-thunder/days-thunder-1.jpeg";
import daysofthunder2 from "./pictures/days-of-thunder/days-thunder-2.jpeg";
import daysofthunder3 from "./pictures/days-of-thunder/days-thunder-3.jpeg";
import alltheright1 from "./pictures/all-the-right/all-the-right-1.jpeg";
import alltheright2 from "./pictures/all-the-right/all-the-right-2.jpeg";
import alltheright3 from "./pictures/all-the-right/all-the-right-3.jpeg";
import lionslambs2 from "./pictures/lions-lambs/lions-lambs-2.jpeg";
import lionslambs1 from "./pictures/lions-lambs/lions-lambs-1.jpeg";
import lionslambs3 from "./pictures/lions-lambs/lions-lambs-3.jpeg";
import minorityreport1 from "./pictures/minority-report/minority-report-1.jpeg";
import minorityreport2 from "./pictures/minority-report/minority-report-2.jpeg";
import minorityreport3 from "./pictures/minority-report/minority-report-3.jpeg";
import americanmade1 from "./pictures/american-made/american-made-1.jpeg";
import americanmade2 from "./pictures/american-made/american-made-2.jpeg";
import americanmade3 from "./pictures/american-made/american-made-3.jpeg";
import licoricepizza1 from "./pictures/licorice-pizza/licorice-pizza-1.jpeg";
import licoricepizza2 from "./pictures/licorice-pizza/licorice-pizza-2.jpeg";
import licoricepizza3 from "./pictures/licorice-pizza/licorice-pizza-3.jpeg";
import jackreacher21 from "./pictures/jack-reacher2/jack-reacher2-1.jpeg";
import jackreacher22 from "./pictures/jack-reacher2/jack-reacher2-2.jpeg";
import jackreacher23 from "./pictures/jack-reacher2/jack-reacher2-3.jpeg";
import jackreacher1 from "./pictures/jack-reacher/jack-reacher-1.jpeg";
import jackreacher2 from "./pictures/jack-reacher/jack-reacher-2.jpeg";
import jackreacher3 from "./pictures/jack-reacher/jack-reacher-3.jpeg";
import oblivion1 from "./pictures/oblivion/oblivion-1.jpeg";
import oblivion2 from "./pictures/oblivion/oblivion-2.jpeg";
import oblivion3 from "./pictures/oblivion/oblivion-3.jpeg";
import knightday1 from "./pictures/knight-day/knight-day-1.jpeg";
import knightday2 from "./pictures/knight-day/knight-day-2.jpeg";
import knightday3 from "./pictures/knight-day/knight-day-3.jpeg";
import rockages1 from "./pictures/rock-ages/rock-ages-1.jpeg";
import rockages2 from "./pictures/rock-ages/rock-ages-2.jpeg";
import rockages3 from "./pictures/rock-ages/rock-ages-3.jpeg";
import topgun1 from "./pictures/top-gun/top-gun-1.jpeg";
import topgun2 from "./pictures/top-gun/top-gun-2.jpeg";
import topgun3 from "./pictures/top-gun/top-gun-3.jpeg";
import edgetomorrow1 from "./pictures/edge-tomorrow/edge-tomorrow-1.jpeg";
import edgetomorrow2 from "./pictures/edge-tomorrow/edge-tomorrow-2.jpeg";
import edgetomorrow3 from "./pictures/edge-tomorrow/edge-tomorrow-3.jpeg";
import oppenheimer1 from "./pictures/oppenheimer/oppenheimer-1.jpg";
import oppenheimer2 from "./pictures/oppenheimer/oppenheimer-2.jpg";
import oppenheimer3 from "./pictures/oppenheimer/oppenheimer-3.jpg";
import outlaws1 from "./pictures/out-laws/out-laws-1.jpeg";
import outlaws2 from "./pictures/out-laws/out-laws-2.jpeg";
import outlaws3 from "./pictures/out-laws/out-laws-3.jpeg";
import yesman1 from "./pictures/yes-man/yes-man-1.jpg";
import yesman2 from "./pictures/yes-man/yes-man-2.jpg";
import yesman3 from "./pictures/yes-man/yes-man-3.jpg";
import happinessbeginners1 from "./pictures/happiness-beginners/happiness-beginners-1.jpeg";
import happinessbeginners2 from "./pictures/happiness-beginners/happiness-beginners-2.jpeg";
import happinessbeginners3 from "./pictures/happiness-beginners/happiness-beginners-3.jpeg";
import collateral1 from "./pictures/collateral/collateral-1.jpeg";
import collateral2 from "./pictures/collateral/collateral-2.jpeg";
import collateral3 from "./pictures/collateral/collateral-3.jpeg";
import piratespearl1 from "./pictures/pirates-pearl/pirates-pearl-1.jpeg";
import piratespearl2 from "./pictures/pirates-pearl/pirates-pearl-2.jpeg";
import piratespearl3 from "./pictures/pirates-pearl/pirates-pearl-3.jpeg";
import popeexorcist1 from "./pictures/pope-exorcist/pope-exorcist-1.jpg";
import popeexorcist2 from "./pictures/pope-exorcist/pope-exorcist-2.jpg";
import popeexorcist3 from "./pictures/pope-exorcist/pope-exorcist-3.jpg";
import hobbitone1 from "./pictures/hobbit-one/hobbit-one-1.jpg";
import hobbitone2 from "./pictures/hobbit-one/hobbit-one-2.jpg";
import hobbitone3 from "./pictures/hobbit-one/hobbit-one-3.jpg";
import violentnight1 from "./pictures/violent-night/violent-night-1.jpg";
import violentnight2 from "./pictures/violent-night/violent-night-2.jpg";
import violentnight3 from "./pictures/violent-night/violent-night-3.jpg";
import tenmidnight1 from "./pictures/ten-midnight/ten-midnight-1.jpg";
import tenmidnight2 from "./pictures/ten-midnight/ten-midnight-2.jpg";
import tenmidnight3 from "./pictures/ten-midnight/ten-midnight-3.jpg";
import forbiddensubjects1 from "./pictures/forbidden-subjects/forbidden-subjects-1.jpg";
import forbiddensubjects2 from "./pictures/forbidden-subjects/forbidden-subjects-2.jpg";
import forbiddensubjects3 from "./pictures/forbidden-subjects/forbidden-subjects-3.jpg";
import soundmusic1 from "./pictures/sound-music/sound-music-1.jpg";
import soundmusic2 from "./pictures/sound-music/sound-music-2.jpg";
import soundmusic3 from "./pictures/sound-music/sound-music-3.jpg";
import equalizer1 from "./pictures/equalizer/equalizer-11.jpg";
import equalizer2 from "./pictures/equalizer/equalizer-3.jpg";
import equalizer3 from "./pictures/equalizer/equalizer-2.jpg";
import equalizer21 from "./pictures/equalizer-2/equalizer-2-1.jpg";
import equalizer22 from "./pictures/equalizer-2/equalizer-2-2.jpg";
import equalizer23 from "./pictures/equalizer-2/equalizer-2-3.jpg";
import equalizer31 from "./pictures/equalizer-3/equalizer-3-1.jpg";
import equalizer32 from "./pictures/equalizer-3/equalizer-3-2.jpg";
import equalizer33 from "./pictures/equalizer-3/equalizer-3-3.jpg";
import equalizer3Poster from "./pictures/equalizer-3/Equalizer3-Poster.jpg";
import badwords1 from "./pictures/bad-words/bad-words-1.jpg";
import badwords2 from "./pictures/bad-words/bad-words-2.jpg";
import badwords3 from "./pictures/bad-words/bad-words-3.jpg";
import dumbMoney1 from "./pictures/dumb-money/dumbMoney1.webp";
import dumbMoney2 from "./pictures/dumb-money/dumbMoney2.jpg";
import dumbMoney3 from "./pictures/dumb-money/dumbMoney3.webp";
import monumentsmen1 from "./pictures/monuments-men/monuments-men-1.jpg";
import monumentsmen2 from "./pictures/monuments-men/monuments-men-2.jpg";
import monumentsmen3 from "./pictures/monuments-men/monuments-men-3.jpg";
import fielddreams1 from "./pictures/field-dreams/field-dreams-1.jpg";
import fielddreams2 from "./pictures/field-dreams/field-dreams-2.jpg";
import fielddreams3 from "./pictures/field-dreams/field-dreams-3.jpg";
import stoker1 from "./pictures/stoker/stoker-1.jpg";
import stoker2 from "./pictures/stoker/stoker-2.jpg";
import stoker3 from "./pictures/stoker/stoker-3.jpg";
import spygame1 from "./pictures/spy-game/spy-game-1.jpg";
import spygame2 from "./pictures/spy-game/spy-game-2.jpg";
import spygame3 from "./pictures/spy-game/spy-game-3.jpg";
import wererabbit1 from "./pictures/wallace-gromit/were-rabbit-1.jpg";
import wererabbit2 from "./pictures/wallace-gromit/were-rabbit-2.jpg";
import wererabbit3 from "./pictures/wallace-gromit/were-rabbit-3.jpg";
import flowerMoon1 from "./pictures/killers-flower-moon/flowerMoon1.png";
import flowerMoon2 from "./pictures/killers-flower-moon/flowerMoon2.png";
import flowerMoon3 from "./pictures/killers-flower-moon/flowerMoon3.png";
import oldDads1 from "./pictures/old-dads/oldDads1.webp";
import oldDads2 from "./pictures/old-dads/oldDads2.png";
import oldDads3 from "./pictures/old-dads/oldDads3.png";
import thekiller1 from "./pictures/the-killer/the-killer-1.jpg";
import thekiller2 from "./pictures/the-killer/the-killer-2.jpg";
import thekiller3 from "./pictures/the-killer/the-killer-3.jpg";
import tintin1 from "./pictures/tintin/Tintin-1.jpg";
import tintin2 from "./pictures/tintin/Tintin-2.jpg";
import tintin3 from "./pictures/tintin/Tintin-3.jpg";
import eldorado1 from "./pictures/el-dorado/el-dorado-1.jpg";
import eldorado2 from "./pictures/el-dorado/el-dorado-2.jpg";
import eldorado3 from "./pictures/el-dorado/el-dorado-3.jpg";
import totallykiller1 from "./pictures/totally-killer/totally-killer-1.jpg";
import totallykiller2 from "./pictures/totally-killer/totally-killer-2.jpg";
import totallykiller3 from "./pictures/totally-killer/totally-killer-3.jpg";
import hellcamp1 from "./pictures/hell-camp/hell-camp-1.jpg";
import hellcamp2 from "./pictures/hell-camp/hell-camp-2.jpg";
import hellcamp3 from "./pictures/hell-camp/hell-camp-3.jpg";
import fiveyearengage1 from "./pictures/5-year-engage/5-year-engage-1.jpg";
import fiveyearengage2 from "./pictures/5-year-engage/5-year-engage-2.jpg";
import fiveyearengage3 from "./pictures/5-year-engage/5-year-engage-3.jpg";
import xmovie1 from "./pictures/x-movie/x-movie-1.jpg";
import xmovie2 from "./pictures/x-movie/x-movie-2.jpg";
import xmovie3 from "./pictures/x-movie/x-movie-3.jpg";
import velvetbuzz1 from "./pictures/velvet-buzz/velvet-buzz-1.jpg";
import velvetbuzz2 from "./pictures/velvet-buzz/velvet-buzz-2.jpg";
import velvetbuzz3 from "./pictures/velvet-buzz/velvet-buzz-3.jpg";
import tmnt1 from "./pictures/tmnt/tmnt-1.jpg";
import tmnt2 from "./pictures/tmnt/tmnt-2.jpg";
import tmnt3 from "./pictures/tmnt/tmnt-3.webp";
import belfast1 from "./pictures/belfast/belfast1.jpeg";
import belfast2 from "./pictures/belfast/belfast2.webp";
import belfast3 from "./pictures/belfast/belfast3.jpeg";
import bloodfart1 from "./pictures/bloodfart-lake/bloodfart1.jpg";
import bloodfart2 from "./pictures/bloodfart-lake/bloodfart2.jpg";
import bloodfart3 from "./pictures/bloodfart-lake/bloodfart3.png";
import fubar1 from "./pictures/fubar/fubar-1.jpg";
import fubar2 from "./pictures/fubar/fubar-2.png";
import fubar3 from "./pictures/fubar/fubar-3.png";
import howardduck1 from "./pictures/howard-the-duck/howard-duck-1.jpeg";
import howardduck2 from "./pictures/howard-the-duck/howard-duck-2.png";
import howardduck3 from "./pictures/howard-the-duck/howard-duck-3.webp";
import fallingChristmas1 from "./pictures/falling-for-christmas/falling-christmas-1.jpg";
import fallingChristmas2 from "./pictures/falling-for-christmas/falling-christmas-2.webp";
import fallingChristmas3 from "./pictures/falling-for-christmas/falling-christmas-3.jpg";
import castleChristmas1 from "./pictures/castle-for-christmas/castle-christmas-1.jpg";
import castleChristmas2 from "./pictures/castle-for-christmas/castle-christmas-2.jpg";
import castleChristmas3 from "./pictures/castle-for-christmas/castle-christmas-3.jpeg";
import planesTrains1 from "./pictures/planes-trains/planes-trains-1.jpg";
import planesTrains2 from "./pictures/planes-trains/planes-trains-2.jpg";
import planesTrains3 from "./pictures/planes-trains/planes-trains-3.jpg";
import joker21 from "./pictures/joker-2/joker2-1.webp";
import joker22 from "./pictures/joker-2/joker2-2.jpeg";
import joker23 from "./pictures/joker-2/joker2-3.webp";
import loveyouman1 from "./pictures/I-love-you-man/loveyouman-1.jpg";
import loveyouman2 from "./pictures/I-love-you-man/loveyouman-2.jpg";
import loveyouman3 from "./pictures/I-love-you-man/loveyouman-3.png";
import christmasRome1 from "./pictures/christmas-in-rome/christmas-rome-1.webp";
import christmasRome2 from "./pictures/christmas-in-rome/christmas-rome-2.jpg";
import christmasRome3 from "./pictures/christmas-in-rome/christmas-rome-3.jpg";
import anchorman1 from "./pictures/anchorman/anchorman-1.webp";
import anchorman2 from "./pictures/anchorman/anchorman-2.jpg";
import anchorman3 from "./pictures/anchorman/anchorman-3.png";
import hotFrosty1 from "./pictures/hot-frosty/hot-frosty-1.jpg";
import hotFrosty2 from "./pictures/hot-frosty/hot-frosty-2.png";
import hotFrosty3 from "./pictures/hot-frosty/hot-frosty-3.png";
import soulsNight1 from "./pictures/souls-night/souls-night-1.jpg";
import soulsNight2 from "./pictures/souls-night/souls-night-2.png";
import soulsNight3 from "./pictures/souls-night/souls-night-3.png";
import shine1 from "./pictures/shine/shine-1.png";
import shine2 from "./pictures/shine/shine-2.png";
import shine3 from "./pictures/shine/shine-3.png";
import argylle1 from "./pictures/argylle/argylle-1.jpeg";
import argylle2 from "./pictures/argylle/argylle-2.png";
import argylle3 from "./pictures/argylle/argylle-3.png";
import scarecrow1 from "./pictures/scarecrow/scarecrow-1.png";
import scarecrow2 from "./pictures/scarecrow/scarecrow-2.png";
import scarecrow3 from "./pictures/scarecrow/scarecrow-3.png";
import inventLying1 from "./pictures/invent-lying/invent-lying-1.jpg";
import inventLying2 from "./pictures/invent-lying/invent-lying-2.png";
import inventLying3 from "./pictures/invent-lying/invent-lying-3.png";
import loveDeath1 from "./pictures/love-and-death/love-death-1.jpg";
import loveDeath2 from "./pictures/love-and-death/love-death-3.png";
import loveDeath3 from "./pictures/love-and-death/love-death-3.jpg";
import elvira1 from "./pictures/elvira/elvira-1.webp";
import elvira2 from "./pictures/elvira/elvira-2.png";
import elvira3 from "./pictures/elvira/elvira-3.png";
import bridgetJones1 from "./pictures/bridget-jones-diary/bridget-jones-1.webp";
import bridgetJones2 from "./pictures/bridget-jones-diary/bridget-jones-2.png";
import bridgetJones3 from "./pictures/bridget-jones-diary/bridget-jones-3.webp";

let MovieList = [
  {
    id: 1,
    name: "Action Jackson",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZWFhNmI3OWQtOTU5Zi00ODA3LWExNjctMTllZWE2ZGE3ZTA1XkEyXkFqcGdeQXVyNzc5MjA3OA@@._V1_SX300.jpg",
    rating: 6,
    year: 1988,
    synopsis:
      "To mitigate an avalanche of personal injury lawsuits, Detective Lt. Action Jackson is demoted to a pencil-pushing desk job. But when a local industrialist frames him for murder, nothing can stop his vengeance-fueled tsunami of violence.",
    genre: "Action",
    subgenre: "",
    runtime: "96 min",
    pairs: "an Action Blaxion.",
    drinkRecipe: "Coffee, Kahlúa, Rum.",
    rated: "R",
    picture1: actionJackson1,
    picture2: actionJackson2,
    picture3: actionJackson3,
    tagline: "EXPECT A STANDING OVATION",
    taglineWriter: "Detroit Sentinel",
    bulletOne:
      "Craig T. Nelson delivers the single greatest boner joke of all time.",
    bulletTwo: "Has all the charm of a 70's Blaxploitation.",
    cast: "Directed by CRAIG R. BAXLEY starring CARL WEATHERS CRAIG T. NELSON SHARON STONE and BILL DUKE",
  },
  {
    id: 2,
    name: "Back to the Future",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZmU0M2Y1OGUtZjIxNi00ZjBkLTg1MjgtOWIyNThiZWIwYjRiXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    year: 1985,
    synopsis: "A teen must get his parents to hook up or he's dead. ",
    genre: "Scifi",
    subgenre: "Adventure",
    runtime: "116 min",
    pairs: "a Flux Capacitor.",
    drinkRecipe: "Tab & Whiskey",
    rated: "PG",
    picture1: bttf1,
    picture2: bttf2,
    picture3: bttf3,
    tagline: "E(ntertainment) = M.C. fly ",
    taglineWriter: "Einstein",
    bulletOne:
      "Probably the best/most entertaining movie ever made. It's perfect.",
    bulletTwo:
      "Figuring out if you'd actually be friends with your dad if you were the same age.",
    cast: "Directed by ROBERT ZEMECKIS starring MICHAEL J FOX CHRISTOPER LLOYD THOMAS F. WILSON and LEA THOMPSON",
  },
  {
    id: 3,
    name: "The Lost Boys",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWEzNmUxZTMtZjY0My00OGNmLWIyNDctODM2YzZjM2YwZWEwXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1987,
    synopsis:
      "Two Van Helsing wannabe’s help a nerdy new kid and his lovestruck older brother fight off vampires in the missing person capital of California.",
    genre: "Horror",
    subgenre: "Cult Classic",
    runtime: "97 min",
    pairs: "a Found Girl.",
    drinkRecipe: "Vodka, Red Bull, cranberry.",
    rated: "R",
    picture1: lostBoys1,
    picture2: lostBoys2,
    picture3: lostBoys3,
    tagline: "When choosing a movie, this is easy pickin's",
    taglineWriter: "Blockbuster Billy",
    bulletOne:
      "Pulling off the 'turn food into bugs' gag about a hundred times.",
    bulletTwo:
      "Bored after taking down werewolves, Corey 'Silver Bullet' Haim moved on to vampires.",
    cast: "Directed by JOEL SCHUMAKER starring COREY HAIM COREY FELDMAN KIEFER SUTHERLAND and EDWARD HERRMAN",
  },
  {
    id: 4,
    name: "Fight Club",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmEzNTkxYjQtZTc0MC00YTVjLTg5ZTEtZWMwOWVlYzY0NWIwXkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 5,
    year: 1999,
    synopsis:
      "After young professional types decide that money and comfort are gay, they form secret clubs in dark basements to dress down, lube up, and wrestle. Don't tell ANYONE about this.",
    genre: "Thriller",
    subgenre: "",
    runtime: "139 min",
    pairs: "a Sliding Penguin.",
    drinkRecipe: "Milk, Sheridan's Coffee Liquer.",
    rated: "R",
    picture1: fightClub1,
    picture2: fightClub2,
    picture3: fightClub3,
    tagline: "Meatloaf had some juithy tittieth",
    taglineWriter: "Mike Tyson",
    bulletOne: "Jared Leto getting beaten to a pulp.",
    bulletTwo: "Learning that motor oil makes great lawn fertilizer.",
    cast: "Directed by DAVID FINCHER starring BRAD PITT  EDWARD NORTON  HELENA BONHAM CARTER and MEAT LOAF",
  },
  {
    id: 5,
    name: "Star Wars: The Empire Strikes Back",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYmU1NDRjNDgtMzhiMi00NjZmLTg5NGItZDNiZjU5NTU4OTE0XkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 6,
    year: 1980,
    synopsis:
      "Hell Hoth no fury like a wampa scorned in this movie where the bad guys (good guys) teach the rebels that resistance is futile.",
    genre: "Scifi",
    subgenre: "Adventure",
    runtime: "124 min",
    pairs: "a Pale Blue Orb.",
    drinkRecipe: "sprite, coconut rum, Blue curaçao, ice sphere.",
    rated: "PG",
    picture1: empireStrikes1,
    picture2: empireStrikes2,
    picture3: empireStrikes3,
    tagline: "REVENGE IS A DISH BEST SERVED COLD",
    taglineWriter: "Tauntaunya Harding",
    bulletOne: "Nice knowing someone loves you.",
    bulletTwo: "Being so cool you can triple-cross a double-cross.",
    cast: "Directed by IRVIN KERSHNER starring MARK HAMILL HARRISON FORD CARRIE FISHER and BILLY DEE WILLIAMS",
  },
  {
    id: 6,
    name: "The Good Son",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDBiOWM2ZGEtNTNjNi00NmY1LWJhOTEtNjQ5MmNiMTgzNWVjL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Proving that all kids are evil, Culkin continues escalating his HOME-icidal behavior. Watch before deciding to have children.",
    genre: "Thriller",
    subgenre: "Drama",
    runtime: "87 min",
    pairs: "a Capri-Son.",
    drinkRecipe: "Capri-Sun Pacific Cooler, vodka, & gin",
    rated: "R",
    picture1: goodSon1,
    picture2: goodSon2,
    picture3: goodSon3,
    tagline: "THAT'S MY KIND OF KID!",
    taglineWriter: "John Wayne Gacy",
    bulletOne: "Working with your IRL BFF.",
    bulletTwo:
      "Forget hittin' pipes in the Shire, Frodo was rippin cigs at age 5. ",
    cast: "Directed by JOSEPH RUBEN starring MACAULEY CULKIN ELIJAH WOOD WENDY CREWSON and DAVID MORSE",
  },
  {
    id: 7,
    name: "The Hangover",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNGQwZjg5YmYtY2VkNC00NzliLTljYTctNzI5NmU3MjE2ODQzXkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 5,
    year: 2009,
    synopsis:
      "The wolfpack try to piece their night together after getting roofied in Vegas. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "100 min",
    pairs: "",
    noPairs: "shots of Jaeger.",
    rated: "R",
    picture1: hangover1,
    picture2: hangover3,
    picture3: hangover2,
    tagline: "It'll leave you wanting some hair of the dog",
    taglineWriter: "Johnny Walker",
    bulletOne: "Mike Tyson's drumming/punch combo.",
    bulletTwo:
      "Titled 'The Very Very Bad Trip' in France. You'd expect something grotesque like 'Le Angooueveur', but 𝙣𝙤𝙣.",
    cast: "Directed by TODD PHILLIPS starring ZACH GALIFIANKIS BRADLEY COOPER ED HELMS and JUSTIN BARTHA",
  },
  {
    id: 8,
    name: "Showdown in Little Tokyo",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDI5NzM2YzEtNjBkNC00Njk0LTgxZDItY2RhMWM2ODE1ODNjXkEyXkFqcGdeQXVyMDI4NjM1MQ@@._V1_SX300.jpg",
    rating: 6,
    year: 1990,
    synopsis:
      "To infiltrate the Yakuza, the LAPD must choose an undercover agent to seamlessly blend in with the Japanese: B̶r̶a̶n̶d̶o̶n̶ ̶L̶e̶e̶ Dolph Lundgren.",
    genre: "Action",
    subgenre: "",
    runtime: "79 min",
    pairs: "a Scandinavian Samurai.",
    drinkRecipe: "sake, Sapporo, svedka, and seppuku.",
    noPairs: "",
    rated: "R",
    picture1: showdown1,
    picture2: showdown2,
    picture3: showdown3,
    tagline: "YOU'LL HEAR THEM COMING",
    taglineWriter: "Dick Long",
    bulletOne:
      "Initiating sex in a hot tub, stopping cold, then resuming much later.",
    bulletTwo: "Complimenting your friend on the size of his johnson.",
    cast: "Directed by MARK L. LESTER starring DOLPH LUNDGREN BRANDON LEE TIA CARRERE and CARY-HIROYUKI TAGAWA",
  },
  {
    id: 9,
    name: "The Great Escape",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzA2NmYxMWUtNzBlMC00MWM2LTkwNmQtYTFlZjQwODNhOWE0XkEyXkFqcGdeQXVyNTIzOTk5ODM@._V1_SX300.jpg",
    rating: 6,
    year: 1963,
    synopsis:
      "If you're wary about watching 'classic' movies because they're often boring and bloated pieces of crap, worry no more. The Great Escape is the absolute best of the Old Hollywood movies.",
    genre: "War",
    subgenre: "Adventure",
    runtime: "172 min",
    pairs: "",
    noPairs: "home-made potato moonshine.",
    rated: "Approved",
    picture1: greatEscape1,
    picture2: greatEscape3,
    picture3: greatEscape2,
    tagline: "A pretty friendly camp compared to others.",
    taglineWriter: "A.W. Schwitz",
    bulletOne: "Choosing Steve McQueen over Rick Dalton.",
    bulletTwo: "Bronson's impeccable Polish accent.",
    cast: "Directed by JOHN STURGES starring STEVE MCQUEEN JAMES GARNER RICHARD ATTENBOROUGH and CHARLES BRONSON",
  },
  {
    id: 10,
    name: "Cocktail",
    poster:
      "https://m.media-amazon.com/images/M/MV5BY2Q0MjFlMTgtOWM1Ny00Y2FiLWI1NDItZDVmMmY5YTdlOGJmXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1988,
    synopsis:
      "Tom Cruise is a business student turned bartender turned manwhore. Paying homage Cruise's 1983 teenage hit, the writers could have (should have) titled this 'Whisky Business'.",
    genre: "Romcom",
    subgenre: "",
    runtime: "104 min",
    pairs: "a Red-Eye. ",
    noPairs: "Enjoy this as the port of embarkation on the Booze Cruise.",
    drinkRecipe: "Tomato juice, A cracked egg, vodka, and cold beer.",
    rated: "R",
    picture1: cocktail1,
    picture2: cocktail2,
    picture3: cocktail3,
    tagline: "Call us Old Fashioned, but this was Neat",
    taglineWriter: "Alcoholics Eponymous",
    bulletOne:
      "Prime Elisabeth Shue (Elisabeth Goo, if you watched this as a teen).",
    bulletTwo:
      "Full of great quotes. 'Beer is for breakfast around here. Drink or be gone'.",
    cast: "Directed by ROGER DONALDSON starring TOM CRUISE BRYAN BROWN ELISABETH SHUE and KELLY LYNCH",
  },
  {
    id: 11,
    name: "Silver Bullet",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzYzOTllMGMtZjQ1Ni00ZDJhLWFhNjctYTlmYjY3NWRkZjk5XkEyXkFqcGc@._V1_SX300.jpg",
    rating: 6,
    year: 1985,
    synopsis:
      "Gary Busey, the world's greatest drunk uncle (druncle), finds new and inventive ways to further incapacitate his paraplegic nephew. Oh also a werewolf is on the loose.",
    genre: "Horror",
    subgenre: "",
    runtime: "95 min",
    pairs: "",
    noPairs: "Coors Light, of course.",
    rated: "R",
    picture1: silverBullet1,
    picture2: silverBullet2,
    picture3: silverBullet3,
    tagline: "I'M REALLY LYCAN IT!",
    taglineWriter: "Teen Wolf",
    bulletOne:
      "Giving your nephew a supercharged wheelchair and a bag of fireworks.",
    bulletTwo:
      "Melting all your silver to make a bullet and then doubting werewolves exist.",
    cast: "Directed by DANIEL ATTIAS starring GARY BUSEY  COREY HAIM  EVERETT MCGILL and MEGAN FOLLOWS",
  },
  {
    id: 12,
    name: "Hot Rod",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjYwNjA3OTY0Nl5BMl5BanBnXkFtZTcwNDMyNDUzMw@@._V1_SX300.jpg",
    rating: 5,
    year: 2007,
    synopsis:
      "In order to save his stepfather (and ultimately kick his ass upon recovery), a bumbling stuntman must raise $50,000 to pay for the conveniently priced surgery.",
    genre: "Comedy",
    subgenre: "",
    runtime: "88 min",
    pairs: "Flaming Dr. Pepper.",
    drinkRecipe: "8 ounces beer, 3/4 ounce Amaretto, high proof rum.",
    rated: "PG-13",
    picture1: hotRod1,
    picture2: hotRod2,
    picture3: hotRod3,
    tagline: "THE VOICE OF A GENERATION!",
    taglineWriter: "John Farnham",
    bulletOne: "Convincing your babe to stay. ",
    bulletTwo: "Drinking green tea all goddamn day and getting PUMPED.",
    cast: "Directed by AKIVA SCHAFFER starring ANDY SAMBERG ISLA FISHER IAN MCSHANE and BILL HADER",
  },
  {
    id: 13,
    name: "Coming to America",
    year: 1988,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNGZlNjdlZmMtYTg0MC00MmZkLWIyNDktYmNlOWYzMTkzYWQ1XkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "That African Prince who's been e-mailing you has come to collect.",
    genre: "Comedy",
    subgenre: "",
    runtime: "116 min",
    pairs: "a Kudu.",
    drinkRecipe: "Crown Royal, Coke.",
    rated: "R",
    picture1: comingToAmerica1,
    picture2: comingToAmerica2,
    picture3: comingToAmerica3,
    tagline: "Wakanda find Zamunda on the map?",
    taglineWriter: "Black Panther",
    bulletOne:
      "Super elaborate and strange dance sequence that puts Mac and Me to shame.",
    bulletTwo:
      "Louie Anderson teaching about hard work and earning the big bucks.",
    cast: "Directed by JOHN LANDIS starring EDDIE MURPHY ARSENIO HALL JAMES EARL JONES and SHEILA JOHNSON",
  },
  {
    id: 14,
    name: "Casino",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDRlZWZjZjYtYzY2NS00ZWVjLTkwYzAtZTA2ZDAzMGRiYmYwXkEyXkFqcGc@._V1_SX300.jpg",
    rating: 5,
    year: 1995,
    synopsis:
      "Betting ace Sam Rothstein runs the Tangiers Casino and all is good ... until Pesci's psycho levels shoot off the charts.",
    genre: "Crime",
    subgenre: "",
    runtime: "178 min",
    pairs: "a Highball.",
    drinkRecipe: "Whiskey and ginger ale in a tall glass.",
    rated: "R",
    picture1: casino1,
    picture2: casino2,
    picture3: casino3,
    tagline: "The pen is mightier than the sword",
    taglineWriter: "Nicky Santoro",
    bulletOne: "See cheaters justice firsthand in a pre-Disneyfied Vegas.",
    bulletTwo:
      "Gamblers fantasy - winging shitty cards back at a dealer until you get good ones.",
    cast: "Directed by MARTIN SCORSESE starring ROBERT DE NIRO JOE PESCI SHARON STONE and JAMES WOODS",
  },
  {
    id: 15,
    name: "Pulp Fiction",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTViYTE3ZGQtNDBlMC00ZTAyLTkyODMtZGRiZDg0MjA2YThkXkEyXkFqcGc@._V1_SX300.jpg",
    rating: 6,
    year: 1994,
    synopsis:
      "A jheri-curl foot masseuse and a heroin-addicted hairpiece cross paths with a big-brain Brett, a butt-watch boxer, and a couple of dine-n-dashers.",
    genre: "Action",
    subgenre: "",
    runtime: "154 min",
    pairs: "",
    noPairs:
      "a $5 shake (with bourbon in it) and a glass of beer (not no little paper cup).",
    rated: "R",
    picture1: pulpFiction1,
    picture2: pulpFiction2,
    picture3: pulpFiction3,
    tagline: "A SHOT TO THE FACE!",
    taglineWriter: "Marvin",
    bulletOne: "Sodomy.",
    bulletTwo:
      "Learning the difference between a motorcycle and chopper, and freeze-dried taster's choice vs. gourmet coffee.",
    cast: "Directed by QUENTIN TARANTINO starring JOHN TRAVOLTA  SAMUEL L. JACKSON  BRUCE WILLIS and UMA THURMAN",
  },
  {
    id: 16,
    name: "The Mighty Ducks",
    year: 1992,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTc4YzQ4NTMtZjc2Yy00OWY1LWIxNGMtZTJkMmFiN2M4ZGViL2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "A former hotshot has to coach a kids hockey team as punishment for a DUI.",
    genre: "Sports",
    subgenre: "",
    runtime: "104 min",
    pairs: "a Beaky Blinder.",
    drinkRecipe: "Hot cocoa, fireball",
    rated: "PG",
    picture1: ducks1,
    picture2: ducks2,
    picture3: ducks3,
    tagline: "IT QUACKED ME UP!",
    taglineWriter: "Drake",
    bulletOne: "An extremely dedicated chauffeur.",
    bulletTwo:
      "Pro-tip for coach Bombay: probably not a good idea to say 'I decide who sucks around here' to a group of children.",
    cast: "Directed by STEPHEN HEREK starring EMILIO ESTEVEZ JOSS ACKLAND LANE SMITH and JOSHUA JACKSON",
  },
  {
    id: 17,
    name: "D2: The Mighty Ducks",
    year: 1994,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjA1YjVjZWQtZTNkYS00NTA2LThhODUtZTI1MzIwMWRjNWMzXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "To beat some fowl tempered foreigners in an international hockey tournament, the ducks cannot wing it - they must reach beak performance.",
    genre: "Sports",
    subgenre: "",
    runtime: "106 min",
    pairs: "a Knucklepuck.",
    drinkRecipe: "kahlua, kraken, cold brew coffee",
    rated: "PG",
    picture1: ducks21,
    picture2: ducks22,
    picture3: ducks23,
    tagline: "THAT GAME WAS A MIRACLE!",
    taglineWriter: "Kurt Russell",
    bulletOne: "Benny the Jet gave up baseball for hockey.",
    bulletTwo:
      "After hooking up with Iceland's trainer, Estevez is forever known as 'Emilio Stealio' girl.",
    cast: "Directed by SAM WEISMAN starring EMILIO ESTEVEZ KATHRYN ERBE JAN RUBES and CARSTEN NORGAARD",
  },
  {
    id: 18,
    name: "Bloodsport",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTJmMGEzMTQtMzQzMi00YjE1LWI4MTctNjY0NWZiYzE2MDVhXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1988,
    synopsis:
      "Jean Claude splits, poses, and kicks his way into the finals of the fabled Kumite while Bolo Yeung viciously murders opponents for no reason.",
    genre: "Action",
    subgenre: "",
    runtime: "92 min",
    pairs: "Kung-Fu Punch.",
    drinkRecipe: "Baijiu and Kool-Aid.",
    rated: "R",
    picture1: bloodsport1,
    picture2: bloodsport2,
    picture3: bloodsport3,
    tagline: "PREPARE FOR VAN DAMMAGE!",
    taglineWriter: "Goodgroin.com",
    bulletOne: "Don Gibb being a total maniac.",
    bulletTwo: "A story based on a guy who was completely full of shit.",
    cast: "Directed by NEWT ARNOLD starring JEAN CLAUDE VAN DAMME  BOLO YEUNG  DONALD GIBB and LEAH AYRES",
  },
  {
    id: 19,
    name: "Sleepy Hollow",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQ4Yjk5ODEtNGFkOS00OTY1LTgwYTYtOTFkNmU2NzBmNGM2XkEyXkFqcGdeQXVyNDQ2MTMzODA@._V1_SX300.jpg",
    rating: 4,
    year: 1999,
    synopsis:
      "Tim Burton and Johnny Depp 3 of 9. The kooky spooky duo once again ask you to change the brightness of your screen to barely visible in this retelling of the tale of Ichabod Crane and the Headless Horseman.",
    genre: "Horror",
    subgenre: "",
    runtime: "105 min",
    pairs: "",
    noPairs: "a sixer of Dogfish Punkin’",
    rated: "R",
    picture1: sleepyHollow1,
    picture2: sleepyHollow2,
    picture3: sleepyHollow3,
    tagline: "PUTS THE CLEAVE IN CLEAVAGE",
    taglineWriter: "Christina Ricciround",
    bulletOne: "Casper (the ghost) Van Dien.",
    bulletTwo: "Wishing someone would just give him head already.",
    cast: "Directed by TIM BURTON starring JOHNNY DEPP  CHRISTINA RICCI  CASPER VAN DIEN and CHRISTOPHER WALKEN",
  },
  {
    id: 20,
    name: "Sherlock Holmes",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTg0NjEwNjUxM15BMl5BanBnXkFtZTcwMzk0MjQ5Mg@@._V1_SX300.jpg",
    rating: 5,
    year: 2009,
    synopsis:
      "The world's most famous dick has lost his hat and his accent in this revamp.",
    genre: "Mystery",
    subgenre: "Action",
    runtime: "128 min",
    pairs: "an Ophthalmic Medication.",
    drinkRecipe: "Redbull and Pimm's",
    rated: "PG-13",
    picture1: sherlockHolmes1,
    picture2: sherlockHolmes2,
    picture3: sherlockHolmes3,
    tagline: "A real mystery!",
    taglineWriter: "Sir Eggatha Christy",
    bulletOne: "Bath time nightmares.",
    bulletTwo: "Humongous French Elmer Fudd hunts rabbit.",
    cast: "Directed by GUY RICHIE starring ROBERT DOWNEY JR  JUDE LAW  RACHAEL McADAMS and MARK STRONG",
  },

  {
    id: 21,
    name: "The Gingerdead Man",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTgyNTUwOTUxN15BMl5BanBnXkFtZTgwNjE0NzgwMzE@._V1_SX300.jpg",
    rating: 4,
    year: 2005,
    synopsis:
      "In this quick flick, a convicted murderer is reincarnated into the body of a gingerbread man and then terrorizes a bakery. Perfect Christmas movie for the whole family!",
    genre: "Cult Classic",
    subgenre: "Horror",
    runtime: "70 min",
    pairs: "a Gingerbread Martini.",
    drinkRecipe: "Baileys, vodka, Kahlúa, gingerbread syrup, whipped cream.",
    rated: "Not rated",
    picture1: gingerdead1,
    picture2: gingerdead2,
    picture3: gingerdead3,
    tagline: "Another one bites the crust",
    taglineWriter: "Breaddie Mercury",
    bulletOne: "Inexplicably spawned three sequels.",
    bulletTwo:
      "There's something magical about hearing Gary Busey's voice shout from a cookies mouth, 'Eat me, you punk bitch!'. ",
    cast: "Directed by CHALES BAND starring GARY BUSEY  ROBIN SYDNEY  RYAN LOCKE  and LARRY CEDAR",
  },
  {
    id: 22,
    name: "The Cabin in the Woods",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTUxNzYyMjg2N15BMl5BanBnXkFtZTcwMTExNzExNw@@._V1_SX300.jpg",
    rating: 6,
    year: 2011,
    synopsis:
      "Five archetypal pals enjoy a pleasant retreat at their one cousin’s cabin. What could go wrong? Only everything ever.",
    genre: "Horror",
    subgenre: "Cult Classic",
    runtime: "95 min",
    pairs: "",
    noPairs: "a keg of beer and four friends you can probably outrun.",
    rated: "R",
    picture1: cabinInWoods1,
    picture2: cabinInWoods2,
    picture3: cabinInWoods3,
    tagline: "STURDY! THIS MOVIE HOLDS UP!",
    taglineWriter: "Lincoln Vlogs",
    bulletOne: "Finally seeing your (anti)hero in action.",
    bulletTwo: "Pro tip: Drink for every horror trope you spot.",
    cast: "Directed by DREW GODDARD starring CHRIS HEMSWORTH  KRISTEN CONNOLLY  RICHARD JENKINS and BRADLEY WHITFORD",
  },
  {
    id: 23,
    name: "The Last Samurai",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzkyNzQ1Mzc0NV5BMl5BanBnXkFtZTcwODg3MzUzMw@@._V1_SX300.jpg",
    rating: 5,
    year: 2003,
    synopsis:
      "John Smith --wait no, Nathan Algren is captivated by Samurai culture in this period piece set in 1870’s Japan",
    genre: "War",
    subgenre: "Drama, Action",
    runtime: "154 min",
    pairs: "",
    noPairs: "SAAAKKKEEEEEE!!!",
    rated: "R",
    picture1: lastSamurai1,
    picture2: lastSamurai2,
    picture3: lastSamurai3,
    tagline: "Not enough running",
    taglineWriter: "Tom Cruise-Control",
    bulletOne: "The Ninja vs. Samurai debate is put to rest.",
    bulletTwo:
      "Teaching polite, quiet, savages to be civilized through brutal, bloody, and unnecessary force.",
    cast: "Directed by EDWARD ZWICK starring TOM CRUISE  KEN WATANABE  BILLY CONNOLLY and MASATO HARADA",
  },
  {
    id: 24,
    name: "The Fan",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjFlN2VjNjYtZDdjOS00ZGI3LTg1MWQtZTBhNmRjNDFiZDE3XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    year: 1996,
    synopsis:
      "Bob De Niro shows his range, yet again, by perfectly portraying a murderous psychopath. You’ll cringe at his increasingly awkward and violent outbursts as Gil torments Blade and his son.",
    genre: "Sports",
    subgenre: "Drama",
    runtime: "116 min",
    pairs: "",
    noPairs: "your favorite pilsner and maybe some Cracker Jacks.",
    rated: "R",
    picture1: theFan1,
    picture2: theFan3,
    picture3: theFan2,
    tagline: "IN BASEBALL I LOVE 'THE PAN'!",
    taglineWriter: "Ken Burnz",
    bulletOne: "John Kruk’s major motion picture premiere!",
    bulletTwo: "Every little league hero who thinks they shoulda went pro.",
    cast: "Directed by TONY SCOTT starring ROBERT DE NIRO  WESLEY SNIPES  JOHN LEGUIZAMO and BENICIO DEL TORO",
  },
  {
    id: 25,
    name: "The Color of Money",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNGY4NGEzY2QtZmIwYS00NzkwLWI0MmItZmVjYjU0YzM3ZjBmXkEyXkFqcGdeQXVyNTE1NjY5Mg@@._V1_SX300.jpg",
    rating: 5,
    year: 1986,
    synopsis:
      "After decades of silence, slick grifter Eddie Felson is back! Only this time he’s the money-man. Young buck Tom Cruise takes to the waters as ill-tempered pool shark Vincent Lauria.",
    genre: "Sports",
    subgenre: "Drama",
    runtime: "119 min",
    pairs: "",
    noPairs: "two shots of whiskey",
    rated: "R",
    picture1: colorOfMoney1,
    picture2: colorOfMoney2,
    picture3: colorOfMoney3,
    tagline: "WAY better than 'The Hustler'",
    taglineWriter: "Anyone Who's Seen Both",
    bulletOne: "Who's hustling who?",
    bulletTwo: "Do NOT leave Paul Newman alone with your girlfriend.",
    cast: "Directed by MARTIN SCORSESE starring TOM CRUISE  PAUL NEWMAN  MARY ELIZABETH MASTRANTONIO and JOHN TURTURRO",
  },
  {
    id: 26,
    name: "Jackie Brown",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNmY5ODRmYTItNWU0Ni00MWE3LTgyYzUtYjZlN2Q5YTcyM2NmXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 5,
    year: 1997,
    synopsis:
      "In Tarantino’s fourth epic Foxy changes her name, Travis Bickle shows his temper (again), and Ice Cube's pal Smokey forgets to call shotgun. From Frozone to Birdman and a Single White Female, everyone's after some cold hard cash.",
    genre: "Crime",
    subgenre: "",
    runtime: "154 min",
    pairs: "",
    noPairs:
      "Colt Forty Five for when you absolutely, positively, got to be more wasted than every motha in the room, accept no substitutions",
    rated: "R",
    picture1: jackieBrown1,
    picture2: jackieBrown2,
    picture3: jackieBrown3,
    tagline: "Wanna fuck?",
    taglineWriter: "De Niro's stache",
    bulletOne: "A smokin' hot pothead.",
    bulletTwo: "Remembering where you parked the car.",
    cast: "Directed by QUENTIN TARANTINO starring SAMUEL L JACKSON  ROBERT DE NIRO PAM GRIER  and ROBERT FORSTER",
  },
  {
    id: 27,
    name: "Death Warrant",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjM5OTVkMDEtZWU1ZS00ODExLWE0MjktNDE4MTQ5NDk2NmMwXkEyXkFqcGdeQXVyNDc2NjEyMw@@._V1_SX300.jpg",
    rating: 4,
    year: 1990,
    synopsis:
      "Jean Claude goes undercover to figure out why so many inmates are dying. It's pretty tough to go unrecognized in a prison full of people you personally arrested but Van Damme is the Muscles from Brussels, not the Brain from Belgium.",
    genre: "Action",
    subgenre: "",
    runtime: "89 min",
    pairs: "a banana split cocktail.",
    drinkRecipe: "Banana liquer, Kahlúa, pineapple juice, cherry.",
    rated: "R",
    picture1: deathWarrant1,
    picture2: deathWarrant3,
    picture3: deathWarrant2,
    tagline: "I guess you could say he's 𝙈𝙖𝙧𝙠𝙚𝙙 𝙛𝙤𝙧 𝘿𝙚𝙖𝙩𝙝.",
    taglineWriter: "Steven Seagal",
    bulletOne: "Any time Al Leong shows up, you know the movie's gonna be lit.",
    bulletTwo: "Roundhouses so telegraphed they had to pay AT&T.",
    cast: "Directed by DERAN SARAFIAN starring JEAN CLAUDE VAN DAMME ROBERT GUILLAUME CYNTHIA GIBB and PATRICK KILPATRICK",
  },
  {
    id: 28,
    name: "Predator",
    poster:
      "https://m.media-amazon.com/images/M/MV5BY2QwYmFmZTEtNzY2Mi00ZWMyLWEwY2YtMGIyNGZjMWExOWEyXkEyXkFqcGdeQXVyNjUwNzk3NDc@._V1_SX300.jpg",
    rating: 6,
    year: 1987,
    synopsis:
      "The jungle comes alive ... and is swiftly mowed down by a squad of beefcake commandos. ",
    genre: "Action",
    subgenre: "",
    runtime: "107 min",
    pairs: "",
    noPairs:
      "jungle juice and/or beer. Neck it from a beer bong to simulate drinking from a vine.",
    rated: "R",
    picture1: predator1,
    picture2: predator2,
    picture3: predator3,
    tagline: "GET READY TO HAVE YOUR PENCIL PUSHED!",
    taglineWriter: "Buster Cherry",
    bulletOne: "Two governors on one screen.",
    bulletTwo:
      "Although this movie gets a top rating due to body mass ALONE, the one-liners are also a great reason to stick around.",
    cast: "Directed by JOHN MCTIERNAN starring ARNOLD SCHWARZENGGER  CARL WEATHERS  JESSE VENTURA and BILL DUKE",
  },
  {
    id: 29,
    name: "Back to the Future Part II",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTMxMGM5MjItNDJhNy00MWI2LWJlZWMtOWFhMjI5ZTQwMWM3XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1989,
    synopsis:
      "Doc transports Marty and Jennifer to 2015 just in time to save their kids from becoming themselves.",
    genre: "Scifi",
    subgenre: "Adventure",
    runtime: "108 min",
    noPairs: "light beer.",
    rated: "PG",
    picture1: bttfTwo1,
    picture2: bttfTwo2,
    picture3: bttfTwo3,
    tagline: "THE FUTURE IS FAR OUT!",
    taglineWriter: "Nostradamus",
    bulletOne: "Cubbies ruining the prediction in the NLCS.",
    bulletTwo:
      "Figuring out if you'd actually be friends with your son if you were the same age.",
    cast: "Directed by ROBERT ZEMECKIS starring MICHAEL J FOX CHRISTOPER LLOYD THOMAS F. WILSON and LEA THOMPSON",
  },
  {
    id: 30,
    name: "Back to the Future Part III",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjhlMGYxNmMtOWFmMi00Y2M2LWE5NWYtZTdlMDRlMGEzMDA3XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1990,
    synopsis:
      "A hatless Marty goes back to Hill Valley's humble beginnings in 1885 to save Doc from his untimely death.",
    genre: "Scifi",
    subgenre: "Adventure",
    runtime: "118 min",
    pairs: "",
    noPairs: "Mad Dog 20/20.",
    rated: "PG",
    picture1: bttfThree1,
    picture2: bttfThree2,
    picture3: bttfThree3,
    tagline: "These slackers keep horsing around!",
    taglineWriter: "Chief Marshall of Hill Valley",
    bulletOne: "Sharp dressed men.",
    bulletTwo: "Wishing a $5 dollar whiskey was still top shelf. ",
    cast: "Directed by ROBERT ZEMECKIS starring MICHAEL J FOX CHRISTOPER LLOYD THOMAS F. WILSON and LEA THOMPSON",
  },
  {
    id: 31,
    name: "The Stoned Age",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjA3MzAyMTY1M15BMl5BanBnXkFtZTcwOTc4NzMyMQ@@._V1_SX300.jpg",
    rating: 6,
    year: 1994,
    synopsis:
      "The classic tale: two best friends try to score booze and get laid. It's like Superbad but made in the 90's and set in the 70's.",
    genre: "Cult Classic",
    subgenre: "Comedy",
    runtime: "90 min",
    pairs: "",
    noPairs: "peppermint shnapps and a case of talls.",
    rated: "R",
    picture1: stonedAge1,
    picture2: stonedAge2,
    picture3: stonedAge3,
    tagline: "DON'T FEAR THE CREEPER!",
    taglineWriter: "Officer Dean",
    bulletOne: "Learning it's not cool to worm someone's chicks.",
    bulletTwo: "Identifying the one pussy song that every band puts out.",
    cast: "Directed by JAMES MELKONIAN starring MICHAEL KOPELOW BRADFORD TATUM KEVIN KILNER and CHINA KANTNER",
  },
  {
    id: 32,
    name: "The Lord of the Rings: The Fellowship of the Ring",
    poster:
      "https://m.media-amazon.com/images/M/MV5BN2EyZjM3NzUtNWUzMi00MTgxLWI0NTctMzY4M2VlOTdjZWRiXkEyXkFqcGdeQXVyNDUzOTQ5MjY@._V1_SX300.jpg",
    rating: 5,
    year: 2001,
    synopsis:
      "For some reason, four hairy footed pals join a team of warriors on a quest to overthrow a quiet, watchful king just minding his own business.",
    genre: "Adventure",
    subgenre: "Fantasy",
    runtime: "178 min",
    pairs: "",
    noPairs: "a tankard of ale.",
    rated: "PG-13",
    picture1: lotrFellowship1,
    picture2: lotrFellowship2,
    picture3: lotrFellowship3,
    tagline: "An epic quest for jewelry has a nice ring to it!",
    taglineWriter: "Xerxes",
    bulletOne: "Where the fuck did that huge bird go?",
    bulletTwo: "Doctors HATE him! Watch Gollum's secrets to a longer life",
    cast: "Directed by PETER JACKSON starring ELIJAH WOODS SEAN ASTIN VIGGO MORGENSTEIN and IAN MCKELLAN",
  },
  {
    id: 33,
    name: "The Lord of the Rings: The Two Towers",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGMxZTdjZmYtMmE2Ni00ZTdkLWI5NTgtNjlmMjBiNzU2MmI5XkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 5,
    year: 2002,
    synopsis: "The forest comes alive! Very, very ..... very, slowly.",
    genre: "Adventure",
    subgenre: "Fantasy",
    runtime: "179 min",
    pairs: "",
    noPairs: "two towers of ale.",
    rated: "PG-13",
    picture1: lotrTower1,
    picture2: lotrTower2,
    picture3: lotrTower3,
    tagline: "Releafed about Gandalf, but let's not get sappy.",
    taglineWriter: "Treebeard",
    bulletOne: "Without his advisor, the King in Rohan is a total douche.",
    bulletTwo:
      "Samwise forgets inspirational quotes do nothing against orc attacks",
    cast: "Directed by PETER JACKSON starring ELIJAH WOODS SEAN ASTIN VIGGO MORGENSTEIN and IAN MCKELLAN",
  },
  {
    id: 34,
    name: "The Lord of the Rings: The Return of the King",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzA5ZDNlZWMtM2NhNS00NDJjLTk4NDItYTRmY2EwMWZlMTY3XkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 4,
    year: 2003,
    synopsis:
      "Samwise and Frodo finish their attempt to return lost property. Meanwhile Aragorn uses cheatcodes against the rightful rulers of Middle Earth.",
    genre: "Adventure",
    subgenre: "Fantasy",
    runtime: "201 min",
    pairs: "a Kings Cup.",
    drinkRecipe: "Ginger Ale, Crown Royal.",
    rated: "PG-13",
    picture1: lotrReturn1,
    picture2: lotrReturn2,
    picture3: lotrReturn3,
    tagline: "Deus ex Machina? What are you Tolkien about?",
    taglineWriter: "Marrtin R.R. Georrge",
    bulletOne: "Being the second son SUCKS.",
    bulletTwo: "Conveniently the birds are back.",
    cast: "Directed by PETER JACKSON starring ELIJAH WOODS SEAN ASTIN VIGGO MORGENSTEIN and IAN MCKELLAN",
  },
  {
    id: 35,
    name: "The Edge",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTU3MGE1OTgtM2YwNC00ODAyLTg3Y2YtYTM0NTc2ZTc3ZDFkL2ltYWdlXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 6,
    year: 1997,
    synopsis:
      "A photographer, an assistant, and a know-it-all billionaire try to escape the Alaskan wilderness before getting devoured by a massive bear.",
    genre: "Thriller",
    subgenre: "Adventure",
    runtime: "117 min",
    pairs: "",
    noPairs: "whiskey or moonshine.",
    rated: "R",
    picture1: edge1,
    picture2: edge2,
    picture3: edge3,
    tagline: "They bearly made it. It was grizzly.",
    taglineWriter: "Farva",
    bulletOne: "Gruesome bird strike.",
    bulletTwo:
      "Hopkins spouting useless facts about survival, such as 'you can season meat with gunpowder'. Cool. We don't have any. ",
    cast: "Directed by LEE TAMAHORI starring ANTHONY HOPKINS ALEC BALDWIN HAROLD PERRINEAU and BART THE BEAR",
  },
  {
    id: 36,
    name: "Hot Tub Time Machine",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQwMjExODA4Ml5BMl5BanBnXkFtZTcwNTYwMDYxMw@@._V1_SX300.jpg",
    rating: 6,
    year: 2010,
    synopsis:
      "A group of friends do some male bonding a la Wild Hogs before getting sent back to the 80's. This is the Great White Buffalo of time travel comedy.",
    genre: "Comedy",
    subgenre: "",
    runtime: "101 min",
    pairs: "",
    noPairs: "Chernobly 'n' Vodka.",
    rated: "R",
    picture1: httm1,
    picture2: httm2,
    picture3: httm3,
    tagline: "VIOLATE 'ER? I BARELY KNOW HER!",
    taglineWriter: "Mötley Löu",
    bulletOne:
      "Traumatizing your spouse when they're young ensures future fidelity.",
    bulletTwo:
      "Learning that even guys with goatee's wouldn't accept a hyphenated name.",
    cast: "Directed by STEVE PINK starring JOHN CUSACK ROB CORDDRY CRAIG ROBINSON and CLARK DUKE",
  },
  {
    id: 37,
    name: "House of 1000 Corpses",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjUyNjU0NDE0OV5BMl5BanBnXkFtZTYwNzcwMzg3._V1_SX300.jpg",
    rating: 5,
    year: 2003,
    synopsis:
      "Clowns, hillbillies, and straight up monsters torment a group of city slickers in this southern-fried horror. If you're into campy horror and/or B-movies, this film is sure to please.",
    genre: "Horror",
    subgenre: "Cult Classic",
    runtime: "89 min",
    pairs: "",
    noPairs: "homemade moonshine in a greasy jug.",
    rated: "R",
    picture1: corpses1,
    picture2: corpses2,
    picture3: corpses3,
    tagline: "THERE WERE ONLY 946 CORPSES",
    taglineWriter: "Rainn Man Wilson",
    bulletOne: "Enjoy with a big bowl of Agatha Crispies.",
    bulletTwo: "It's ok to dislike chicken, but unnacceptable to hate clowns.",
    cast: "Directed by ROB ZOMBIE starring SID HAIG BILL MOSELY SHERI MOON ZOMBIE and KAREN BLACK",
  },
  {
    id: 38,
    name: "The Devil's Rejects",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGU4NWMxZTQtNjYwYy00Mjg1LTg4YjQtYTk5M2ZhNTFiMzAxXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 2005,
    synopsis:
      "If you thought House of 1000 Corpses was brutal, prepare yourself for this darker and grittier sequel. After the Firefly house is raided by police, the surviving members hit the road in search of freedom.",
    genre: "Horror",
    subgenre: "Cult Classic",
    runtime: "107 min",
    pairs: "",
    noPairs: "bourbon.",
    rated: "R",
    picture1: rejects1,
    picture2: rejects2,
    picture3: rejects3,
    tagline: "You won't even want a bathroom break!",
    taglineWriter: "Baby Firefly",
    bulletOne: "Awesome end sequence with Freebird.",
    bulletTwo: "Enjoy with a tutti-fuckin'-frutti ice cream cone.",
    cast: "Directed by ROB ZOMBIE starring SID HAIG BILL MOSELY SHERI MOON ZOMBIE and WILLIAM FORSYTHE",
  },
  {
    id: 39,
    name: "3 From Hell",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOWY5NzU5YTEtNTQ5My00Yzk0LTg2M2UtYWU4MjQwY2Y5ZTBmXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 3,
    year: 2019,
    synopsis:
      "Watching 3 From Hell is like watching your favorite fighter come out of retirement -- you hope for a return to glory but deep down you know their best days are done. It's worth watching but don't expect it to be anywhere near as good as Corpses or Rejects.",
    genre: "Horror",
    subgenre: "",
    runtime: "115 min",
    pairs: "",
    noPairs: "one bourbon, one scotch, one beer.",
    rated: "R",
    picture1: threeFromHell1,
    picture2: threeFromHell2,
    picture3: threeFromHell3,
    tagline: "I WOULD'VE SENT 20 MORE TO HELL",
    taglineWriter: "Johnny 23",
    bulletOne: "Last movie for the legendary Sid Haig.",
    bulletTwo:
      "Mr. Baggy Britches not reaching the level of excellence set by Captain Spaulding.",
    cast: "Directed by ROB ZOMBIE starring SID HAIG BILL MOSELY SHERI MOON ZOMBIE and RICHARD BRAKE",
  },
  {
    id: 40,
    name: "Rocky",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTY5MDMzODUyOF5BMl5BanBnXkFtZTcwMTQ3NTMyNA@@._V1_SX300.jpg",
    rating: 6,
    year: 1976,
    synopsis:
      "A walking case of CTE gets pulverized for 97% of every fight. When opponents literally can't hit him anymore, he goes in for the kill. ",
    genre: "Sports",
    subgenre: "",
    runtime: "120 min",
    pairs: "",
    noPairs: "a glass of raw eggs ... or a flask of whiskey if you're a pussy.",
    rated: "PG",
    picture1: rocky1,
    picture2: rocky2,
    picture3: rocky3,
    tagline: "This guy can really beat his meat!",
    taglineWriter: "Eaton Beaver",
    bulletOne: "Lovable, violent alcoholic Paulie.",
    bulletTwo:
      "Expert dating advice from local leg-breakers, such as taking retards to the zoo.",
    cast: "Directed by JOHN G. AVILDSEN starring SYLVESTER STALLONE TALIA SHIRE  BURT YOUNG  and BURGESS MEREDITH",
  },
  {
    id: 41,
    name: "Rocky II",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQzZjQyMDgtMmQ3YS00ZDE4LWIyYTAtMWRkMWQ4ZTI5ZjA5XkEyXkFqcGdeQXVyMjMwNDgzNjc@._V1_SX300.jpg",
    rating: 5,
    year: 1979,
    synopsis:
      "Rocky can't sing or dance ... he also can't read. With no other options for work, he finally gets back in the ring to face Apollo Creed again. ",
    genre: "Sports",
    subgenre: "",
    runtime: "119 min",
    pairs: "a Sloe Gin Fizz.",
    noPairs: "as this movie is SLOW until the training montage and fight.",
    drinkRecipe: "Sloe gin, lemon juice, simple syrup, club soda.",
    rated: "PG",
    picture1: rockyTwo1,
    picture2: rockyTwo2,
    picture3: rockyTwo3,
    tagline: "Derogatory? Yeah, it's great",
    taglineWriter: "Philadelphia Pugilist",
    bulletOne: "Running more than a marathon across Philly.",
    bulletTwo:
      "Launching hurtful personal attacks to goad your enemy into combat.",
    cast: "Directed by SYLVESTER STALLONE starring SYLVESTER STALLONE CARL WEATHERS TALIA SHIRE and BURGESS MEREDITH",
  },
  {
    id: 42,
    name: "Rocky III",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTMyOTYzMDMzMF5BMl5BanBnXkFtZTcwMTkzODM1NA@@._V1_SX300.jpg",
    rating: 5,
    year: 1982,
    synopsis:
      "Rocky gets rich and wild shit happens. He fights Hulk Hogan, Mr. T, and even a buys a robot (that Paulie probably bangs).",
    genre: "Sports",
    subgenre: "",
    runtime: "99 min",
    pairs: "",
    noPairs:
      "good ol' American beer for Thunderlips and/or Irish beer to honor Mick.",
    rated: "PG",
    picture1: rockyThree1,
    picture2: rockyThree2,
    picture3: rockyThree3,
    tagline: "PITY THE FOOL WHO DON'T SEE THIS",
    taglineWriter: "Clubber Lang",
    bulletOne: "Thunderlips in the flesh, babe-uh.",
    bulletTwo: "The most homoerotic training montage committed to celluloid.",
    cast: "Directed by SYLVESTER STALLONE starring SYLVESTER STALLONE CARL WEATHERS MR. T and BURGESS MEREDITH",
  },
  {
    id: 43,
    name: "Rocky IV",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTg3ODk5ODE0NV5BMl5BanBnXkFtZTcwNDkzODM1NA@@._V1_SX300.jpg",
    rating: 5,
    year: 1985,
    synopsis:
      "Rocky seeks to avenge Apollo by laying a beatdown on Dr. Dolph Lundgren. ",
    genre: "Sports",
    subgenre: "",
    runtime: "91 min",
    pairs: "",
    noPairs:
      "Four Roses and Schlitz for Paulie. And at least one shot of Svedka for Dolph.",
    rated: "PG",
    picture1: rockyFour1,
    picture2: rockyFour2,
    picture3: rockyFour3,
    tagline: "IT'LL SET YOUR HEART ON FIRE!",
    taglineWriter: "John Cafferty",
    bulletOne:
      "Epic US vs Russia sentiments provide a full-on raging patriot boner. USA USA USA!!!",
    bulletTwo: "Lundgren hitting Stallone so hard he nearly stopped his heart.",
    cast: "Directed by SYLVESTER STALLONE starring SYLVESTER STALLONE CARL WEATHERS DOLPH LUNDGREN and BURT YOUNG",
  },
  {
    id: 44,
    name: "Rocky V",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTM2Mzc4MTYxM15BMl5BanBnXkFtZTcwODY5NTk1NA@@._V1_SX300.jpg",
    rating: 4,
    year: 1990,
    synopsis:
      "This movie gets a lot of shit but it's a fun flick. Rocky trains a young Gunn who wants to be the best.",
    genre: "Sports",
    subgenre: "",
    runtime: "104 min",
    pairs: "",
    noPairs: "Yards Brawler - the pugilist style ale.",
    rated: "PG-13",
    picture1: rockyFive1,
    picture2: rockyFive2,
    picture3: rockyFive3,
    tagline: "IF HE DIES, HE DIES",
    taglineWriter: "AIDS",
    bulletOne: "No KO's in a bare-knuckle street brawl.",
    bulletTwo:
      "Rocky fails to grasp the concept of assault as he punches a Don King wannabe's face clean off and asks 'Sue me for what?!'",
    cast: "Directed by JOHN G. AVILDSEN starring SYLVESTER STALLONE TOMMY MORRISON TALIA SHIRE and BURT YOUNG",
  },
  {
    id: 45,
    name: "Rocky Balboa",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTM2OTUzNDE3NV5BMl5BanBnXkFtZTcwODczMzkzMQ@@._V1_SX300.jpg",
    rating: 4,
    year: 2006,
    synopsis:
      "After a nurses aid accidentally spills his bedpan, Rocky uses his knotted and arthritic fists of fury to escape the nursing home. ",
    genre: "Sports",
    subgenre: "Drama",
    runtime: "102 min",
    pairs: "a Smooth Move.",
    drinkRecipe: "Ensure, Kahlúa, and prune juice.",
    rated: "PG",
    picture1: rockyBalboa1,
    picture2: rockyBalboa2,
    picture3: rockyBalboa3,
    tagline: "Bad movies don't last a long time",
    taglineWriter: "Sly",
    bulletOne: "Pauly somoehow hasn't died of cirrhosis.",
    bulletTwo: "Computer simulation woulda made a sweet video game.",
    cast: "Directed by SYLVESTER STALLONE starring SYLVESTER STALLONE ANTONIO TARVER MILO VENTIMIGLIA and BURT YOUNG ",
  },
  {
    id: 46,
    name: "Johnny Mnemonic",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTdhYjEzYTEtYTkwZC00NzgxLWI0ZWEtYmEyMGZhOWYwMjE2XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1995,
    synopsis:
      "In this pulse-pounding-cyber-slam, Keanu Reeves REALLY wants room service.",
    genre: "Scifi",
    subgenre: "",
    runtime: "96 min",
    pairs: "a Mind Eraser",
    noPairs: "... to wipe all those gigs from your memory.",
    drinkRecipe: "Coffee liquer, vodka, club soda.",
    rated: "R",
    picture1: mnemonic1,
    picture2: mnemonic2,
    picture3: mnemonic3,
    tagline: "The internet is a passing fad, mark my words",
    taglineWriter: "Lou Sirr",
    bulletOne: "Dolphin Lundgren.",
    bulletTwo: "Scenic tour of The Free City of Newark.",
    cast: "Directed by ROBERT LONGO starring KEANU REEVES  DINA MEYER  DOLPH LUNDGREN  and ICE-T",
  },
  {
    id: 47,
    name: "Fast Times at Ridgemont High",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzBlZjE1MDctYjZmZC00ZTJmLWFkOWEtYjdmZDZkODBkZmI2XkEyXkFqcGdeQXVyNjQ2MjQ5NzM@._V1_SX300.jpg",
    rating: 5,
    year: 1982,
    synopsis:
      "80's nostalgia-fest complete with misfits, nerds, jocks, malls, crappy jobs, babes, school, and trying to get laid. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "90 min",
    pairs: "",
    noPairs: "tasty waves and a cool buzz.",
    rated: "R",
    picture1: fastTimes1,
    picture2: fastTimes2,
    picture3: fastTimes3,
    tagline: "Hey, Bud, let's party!",
    taglineWriter: "Jeff Spicoli",
    bulletOne: "Young Nic Cage cameo.",
    bulletTwo:
      "The Phoebe Cates pool scene defines an entire generations cum-ing of age.",
    cast: "Directed by AMY HECKERLING starring SEAN PENN JENNIFER JASON LEIGH PHOEBE CATES and BRIAN BACKER",
  },
  {
    id: 48,
    name: "Three O'Clock High",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWNiMTU4MTEtZDAzMy00ODU2LWE0OGMtOGQ5NDQ1MjUzMTM3XkEyXkFqcGdeQXVyNDIwODAwNzg@._V1_SX300.jpg",
    rating: 6,
    year: 1987,
    synopsis:
      "After a timid little wimp named Jerry Mitchell breaks every rule of mens bathroom ettiquette (talking while peeing, not taking the furthest urinal, touching another person), transfer student Buddy Revell has no choice but to kick his ass at 3 o' clock. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "101 min",
    pairs: "an Uppercut.",
    drinkRecipe: "Whiskey, 7-Up.",
    rated: "PG-13",
    picture1: threeOClock1,
    picture2: threeOClock2,
    picture3: threeOClock3,
    tagline: "In this fight there's no 'Saved by the Bell'",
    taglineWriter: "Screech",
    bulletOne: "Hilarious 80's insults.",
    bulletTwo:
      "Bullies having genuine disgust for cowardly victims. 'You didn't even try. How does that feel??'",
    cast: "Directed by PHIL JOANOU starring CASEY SIEMASZKO RICHARD TYSON STACEY GLICK and JEFFREY TAMBOR",
  },
  {
    id: 49,
    name: "Men at Work",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODc3ZmI0NzAtYWFiZC00ZGIwLTk3NDUtZWUxYTUxODc2ZDM1XkEyXkFqcGdeQXVyMjY3MjUzNDk@._V1_SX300.jpg",
    rating: 4,
    year: 1990,
    synopsis:
      "While peeping on their hot neighbor, Sheen and Estevez witness a murder and have to avoid being framed for it (and explaining why they saw it). ",
    genre: "Comedy",
    subgenre: "",
    runtime: "98 min",
    pairs: "an Irish Trash Can.",
    drinkRecipe: "Red Bull, Peach Schnapps, Blue Curaçao, Triple Sec, Gin.",
    rated: "PG-13",
    picture1: menAtWork1,
    picture2: menAtWork2,
    picture3: menAtWork3,
    tagline: "They're NOT from a land down under.",
    taglineWriter: "Men at Work",
    bulletOne: "Giving a golf clap to jerks.",
    bulletTwo:
      "Dirtbag garbage men who clang cans and make as much noise as possible when collecting trash.",
    cast: "Directed by EMILIO ESTEVEZ starring CHARLIE SHEEN EMILIO ESTEVEZ KEITH DAVID and LESLIE HOPE",
  },
  {
    id: 50,
    name: "Cutting Class",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjk5ODY1ODgtNzE2OS00NDdlLWI3YTctOTdjN2Q0OGI2MmFiXkEyXkFqcGdeQXVyMTMxMTY0OTQ@._V1_SX300.jpg",
    rating: 4,
    year: 1989,
    synopsis:
      "In this 80's high school slasher, we see the classic dilemma for our starlet -- should she choose the hot guy or the sensitive weirdo?",
    genre: "Horror",
    subgenre: "",
    runtime: "91 min",
    pairs: "an Axe to the Head.",
    drinkRecipe: "Cooking Sherry and Tuaca.",
    rated: "R",
    picture1: cuttingClass1,
    picture2: cuttingClass2,
    picture3: cuttingClass3,
    tagline: "Misleading. No instructions for self-mutilation.",
    taglineWriter: "Pinhead",
    bulletOne: "Witholding sex until your partner gets better grades in class.",
    bulletTwo:
      "As long as the person is hot, who cares if they're murderers (or have bad grades).",
    cast: "Directed by ROSPO PALLENBERG starring BRAD PITT DONOVAN LEITCH JR JILL SCHOELEN and RODDY MCDOWALL",
  },
  {
    id: 51,
    name: "The Beastmaster",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWRiMDExOWUtY2ZjMi00NmFhLWI4Y2MtMTgxMjM2MjMxMjUwXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    year: 1982,
    synopsis:
      "Mix 2 ounces Conan the Barbarian with 1 ounce of Dr. Doolittle and a splash of loin cloth.",
    genre: "Scifi",
    subgenre: "",
    runtime: "118 min",
    pairs: "",
    noPairs: "a skin of wine.",
    rated: "PG",
    picture1: beastmaster1,
    picture2: beastmaster2,
    picture3: beastmaster3,
    tagline: "Could've been more erotic",
    taglineWriter: "Animal Lovers United",
    bulletOne: "All animals were harmed in the making of this film",
    bulletTwo: "Seriously... I think they killed that dog",
    cast: "Directed by DON COSCARELLI starring MARC SINGER  RIP TORN  TANYA ROBERTS and JOHN AMOS",
  },
  {
    id: 52,
    name: "Shaun of the Dead",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTg5Mjk2NDMtZTk0Ny00YTQ0LWIzYWEtMWI5MGQ0Mjg1OTNkXkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 6,
    year: 2004,
    synopsis:
      "A pair of slackers blunder their way through a zombie apocalypse. Try watching this movie in your own personal Winchester.",
    genre: "Comedy",
    subgenre: "Horror",
    runtime: "99 min",
    pairs: "",
    noPairs: "a nice cold pint.",
    rated: "R",
    picture1: shaun1,
    picture2: shaun2,
    picture3: shaun3,
    tagline: "ANOTHER ONE BITES THE CRUSHED",
    taglineWriter: "Deaddie Mercury",
    bulletOne: "The inability to distinguish zombies from drunks.",
    bulletTwo:
      "Choosing a safe, familiar, smoking-friendly location to ride out the end of the world.",
    cast: "Directed by EDGAR WRIGHT starring SIMON PEGG NICK FROST KATE ASHFIELD and DYLAN MORAN",
  },
  {
    id: 53,
    name: "The World's End",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzA1MTk1MzY0OV5BMl5BanBnXkFtZTgwNjkzNTUwMDE@._V1_SX300.jpg",
    rating: 4,
    year: 2013,
    synopsis:
      "In the last film of the Cornetto Trilogy, longtime friends uncover an alien invasion during a drunken pub crawl.",
    genre: "Comedy",
    subgenre: "Scifi",
    runtime: "109 min",
    pairs: "",
    noPairs: "12 pub ales.",
    rated: "R",
    picture1: worldsEnd1,
    picture2: worldsEnd2,
    picture3: worldsEnd3,
    tagline: "The pubs lacked atmosphere",
    taglineWriter: "E.T.",
    bulletOne: "Drinking an obscene amount of alcohol.",
    bulletTwo:
      "If you're into themed movie nights, make this a double feature with 'This Is the End'.",
    cast: "Directed by EDGAR WRIGHT starring SIMON PEGG NICK FROST MARTIN FREEMAN and PADDY CONSIDINE",
  },
  {
    id: 54,
    name: "Hot Fuzz",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzg4MDJhMDMtYmJiMS00ZDZmLThmZWUtYTMwZDM1YTc5MWE2XkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 5,
    year: 2007,
    synopsis:
      "Simon Pegg and Nick Frost are police officers in a sleepy country town. You know it's a true fantasy - British cops have guns. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "121 min",
    pairs: "",
    noPairs: "Boddington's Pub Ale.",
    rated: "R",
    picture1: hotFuzz1,
    picture2: hotFuzz2,
    picture3: hotFuzz3,
    tagline: "BETTER THAN A GOLDEN GOOSE!",
    taglineWriter: "P.I. Staker",
    bulletOne: "Part two of the Cornetto Trilogy.",
    bulletTwo:
      "Don't be a fascist or a hag at the store; no one likes a prickly customer. ",
    cast: "Directed by EDGAR WRIGHT starring SIMON PEGG NICK FROST BILL NIGHY and JIM BROADBENT",
  },
  {
    id: 55,
    name: "Run Fatboy Run",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTgzOTg0NTk5NF5BMl5BanBnXkFtZTcwMjMxNDI2MQ@@._V1_SX300.jpg",
    rating: 5,
    year: 2007,
    synopsis:
      "To prove to his wife he's not a loser, a man comes in very last place in a marathon. This is another fun, funny movie with Simon Pegg - definitely worth a watch. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "100 min",
    pairs: "a Rum Runner.",
    drinkRecipe:
      "Rum, banana liquer, blackberry liquer, pineapple juice, lime juice, grenadine.",
    rated: "PG-13",
    picture1: fatboy1,
    picture2: fatboy2,
    picture3: fatboy3,
    tagline: "Runaway Groom",
    taglineWriter: "Julia Roberts",
    bulletOne: "The keys to health? Booze and cigarettes.",
    bulletTwo: "Faking a disability to get money for charity.",
    cast: "Directed by DAVID SCHWIMMER starring SIMON PEGG THANDIE NEWTON HANK AZARIA and DYLAN MORAN",
  },
  {
    id: 56,
    name: "The 40 Year-Old Virgin",
    poster:
      "https://m.media-amazon.com/images/I/81O1+TqqQwL._AC_UY436_FMwebp_QL65_.jpg",
    rating: 5,
    year: 2005,
    synopsis:
      "After finding out their co-worker is a virgin a group of ne’er do wells help Andy meet the woman of his (wet) dreams; or anyone willing at this point.",
    genre: "Comedy",
    subgenre: "",
    runtime: "116 min",
    pairs: "a Bag of Sand.",
    drinkRecipe: "Tequila, mango juice, lime, salted rim.",
    rated: "R",
    picture1: fortyYearOld1,
    picture2: fortyYearOld2,
    picture3: fortyYearOld3,
    tagline: "DEFINITELY ON A PEDESTAL!",
    taglineWriter: "Health Clinic Dad",
    bulletOne: "Having a fruity drink montage with your buddies.",
    bulletTwo: "Confirming that friends who like Coldplay are gay.",
    cast: "Directed by JUDD APATOW starring STEVE CARRELL CATHERINE KEENER PAUL RUDD and SETH ROGEN",
  },
  {
    id: 57,
    name: "Knocked Up",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzgzYjMzMWItNDFiYi00ZjhkLWIyMzYtZGRlY2FmNzkwNjRhL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 2007,
    synopsis:
      "Seth Rogen and Katherine Heigl star in this modern rom-com, with an emphasis on “com”. Although Flesh of the Stars would give this movie zero tits, it’s a comedy hit from the Golden Age of Team Apatow.",
    genre: "Comedy",
    subgenre: "",
    runtime: "129 min",
    pairs: "",
    noPairs: "a Corona snatched from behind the bar.",
    rated: "R",
    picture1: knockedUp1,
    picture2: knockedUp2,
    picture3: knockedUp3,
    tagline: "SO GOOD YOU WON'T WANT TO PULL OUT!",
    taglineWriter: "Dirk Diggler",
    bulletOne: "Using marijuana to treat any and all ailments.",
    bulletTwo: "Not letting the door hit you in the vagina on your way out.",
    cast: "Directed by JUDD APATOW starring SETH ROGEN KATHERINE HEIGL PAUL PAUL RUDD and LESLIE MANN",
  },
  {
    id: 58,
    name: "Forgetting Sarah Marshall",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTYzODgzMjAyM15BMl5BanBnXkFtZTcwMTI3NzI2MQ@@._V1_SX300.jpg",
    rating: 6,
    year: 2008,
    synopsis:
      "To get some space after a breakup, a composer sets off to Hawaii ... only to get stuck at the same resort with his ex and her douchebag new fling.   ",
    genre: "Comedy",
    subgenre: "",
    runtime: "111 min",
    pairs: "a Blue Hawaiian.",
    drinkRecipe: "Rum, pineapple juice, Blue Curaçao, sweet and sour mix.",
    rated: "R",
    picture1: sarahMarshall1,
    picture2: sarahMarshall2,
    picture3: sarahMarshall3,
    tagline: "Trust your Animal Instincts - watch this movie!",
    taglineWriter: "Jason Bateman",
    bulletOne:
      "Having two incredibly attractive women fight over your schlubby ass.",
    bulletTwo:
      "Learning the national fish of Hawaii (humuhumunukunukuapuaa ... yeah, bitch!)",
    cast: "Directed by NICHOLAS STOLLER starring JASON SEGEL MILA KUNIS KRISTEN BELL and RUSSELL BRAND",
  },

  {
    id: 59,
    name: "Dawn of the Dead",
    poster:
      "https://m.media-amazon.com/images/M/MV5BN2M2ZmU2OGQtNmU5Yi00MTIyLTgwNWMtYjljMzZlYTdiNjBhXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 5,
    year: 2004,
    synopsis:
      "Remake of the 1978 classic with one key difference: instead of your run of the mill slow zombies, this movie gives us speed zombies.  ",
    genre: "Horror",
    subgenre: "",
    runtime: "101 min",
    pairs: "",
    noPairs: "champagne toasts on a boat.",
    rated: "R",
    picture1: dod1,
    picture2: dod2,
    picture3: dod3,
    tagline: "GET DOWN WITH THE ILLNESS",
    taglineWriter: "Perturbed",
    bulletOne:
      "Zombie baby getting plugged almost immediately after birth (death?).",
    bulletTwo:
      "Finding ways to amuse yourself, such as sniping celebrity lookalike zombies.",
    cast: "Directed by ZACK SNYDER starring SARAH POLLEY VING RHAMES JAKE WEBER and MEKHI PFIFER",
  },
  {
    id: 60,
    name: "Land of the Dead",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzU1MDI1NDM1NF5BMl5BanBnXkFtZTcwMzU5OTkyMQ@@._V1_SX300.jpg",
    rating: 5,
    year: 2005,
    synopsis:
      "Instead of the typical idiot zombie, this movie gives us smart zombies that learn and communicate with each other.",
    genre: "Horror",
    subgenre: "",
    runtime: "93 min",
    pairs: "a Skyflower.",
    drinkRecipe: "Sprite, OJ, Cran splash.",
    rated: "R",
    picture1: landOfDead1,
    picture2: landOfDead2,
    picture3: landOfDead3,
    tagline: "These zombies aint no deadheads",
    taglineWriter: "The Ungrateful Alive",
    bulletOne: "Sweet Jurassic Park 2 style RV.",
    bulletTwo:
      "If we could retrain zombies, could we get our friends back like in Shaun of the Dead?",
    cast: "Directed by GEORGE A. ROMERO starring JOHN LEGUIZAMO ASIA ARGENTO SIMON BAKER and DENNIS HOPPER",
  },
  {
    id: 61,
    name: "Role Models",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTg3MTk4NzQ0NV5BMl5BanBnXkFtZTcwNjM0OTc5MQ@@._V1_SX300.jpg",
    rating: 5,
    year: 2008,
    synopsis:
      "Two cool dudes have to mentor a dork and a guttermouth to avoid going to jail. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "99 min",
    pairs: "Nuclear Horse Piss.",
    drinkRecipe: "Vodka, Monster, and Ambien.",
    rated: "R",
    picture1: roleModels1,
    picture2: roleModels2,
    picture3: roleModels3,
    tagline: "You'll want to run train on this movie",
    taglineWriter: "McLovin",
    bulletOne: "Louis CK cameo.",
    bulletTwo: "Learning how to LARP with Gleep Glop and the Floopdie Doos.",
    cast: "Directed by DAVID WAIN starring PAUL RUDD SEAN WILLIAM SCOTT ELIZABETH BANKS and CHRISTOPHER MINTZ-PLASSE",
  },
  {
    id: 62,
    name: "Superbad",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTc0NjIyMjA2OF5BMl5BanBnXkFtZTcwMzIxNDE1MQ@@._V1_SX300.jpg",
    rating: 6,
    year: 2007,
    synopsis:
      "A tale as old as time. Best friends attempt to hook up with their crushes at their first and last high school party while facing cockblocking cops, horrible fake ID’s, and god damn veterinary exams.",
    genre: "Comedy",
    subgenre: "",
    runtime: "113 min",
    pairs: "",
    noPairs:
      "a shit-ton of different shit, like Goldschlager and Kyle's Killer Lemonade",
    rated: "R",
    picture1: superbad1,
    picture2: superbad2,
    picture3: superbad3,
    tagline: "See it with friends if possible.",
    taglineWriter: "Steven Glansberg",
    bulletOne: "Expert storage tips from Danny Ocean.",
    bulletTwo:
      "Learn how to stop being such a vagine (and how to clean up if one bleeds on you).",
    cast: "Directed by GREG MOTTOLA starring JONAH HILL MICHAEL CERA CHRISTOPHER MINTZ-PLASSE and EMMA STONE",
  },
  {
    id: 63,
    name: "The Mask",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOWExYjI5MzktNTRhNi00Nzg2LThkZmQtYWVkYjRlYWI2MDQ4XkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 5,
    year: 1994,
    synopsis: "A magical mask gives a wimp the balls to be assertive.",
    genre: "Comedy",
    subgenre: "Scifi",
    runtime: "101 min",
    pairs: "",
    noPairs: "Absinthe.",
    rated: "PG-13",
    picture1: mask1,
    picture2: mask2,
    picture3: mask3,
    tagline: "Full of toxik maskulinity",
    taglineWriter: "The Matriarkhy",
    bulletOne: "Cameron Diaz is SMOKIN'.",
    bulletTwo: "Based on and is better than a comic book that no one read.",
    cast: "Directed by CHUCK RUSSELL starring JIM CARREY CAMERON DIAZ PETER RIEGERT and PETER GREENE",
  },
  {
    id: 64,
    name: "Super Troopers",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzAyOTZjZDItZjNiYy00YTA3LWEyYWMtZTA0NmUzYjZhNjg0XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 2001,
    synopsis:
      "Vermont State Troopers prank unsuspecting motorists while trying to bust a drug smuggling operation. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "100 min",
    pairs: "",
    noPairs:
      "a bottle of maple syrup or coffee with a bar of soap if you're an asshole.",
    rated: "R",
    picture1: superTroopers1,
    picture2: superTroopers2,
    picture3: superTroopers3,
    tagline: "Go watch this right meow.",
    taglineWriter: "Mac",
    bulletOne: "Mustache rides.",
    bulletTwo:
      "If your drunkest, craziest friends had a badge and gun, they'd be perfect for this police force.",
    cast: "Directed by JAY CHANDRASEKHAR starring STEVE LEMME KEVIN HEFFERNAN ERIK STOLHANSKE and PAUL SOTER",
  },
  {
    id: 65,
    name: "Super Troopers 2",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZmFhMjM2NmQtYWM3Yi00YmJhLWIxOTgtYWY3ZjNlYzMwOWJmXkEyXkFqcGdeQXVyMjM4NTM5NDY@._V1_SX300.jpg",
    rating: 6,
    year: 2018,
    synopsis:
      "Broken Lizard’s eighth flick is a rare win for sequel’s. This time the Vermont HIGHway patrolmen take on The Great White North with a healthy hatred of their accents and general way of life.",
    genre: "Comedy",
    subgenre: "Crime",
    runtime: "99 min",
    pairs: "",
    noPairs: "a case of Molson.",
    rated: "R",
    picture1: superTroopersTwo1,
    picture2: superTroopersTwo2,
    picture3: superTroopersTwo3,
    tagline: "Like sex with an eskimo - you'll be Inuit!",
    taglineWriter: "Farva",
    bulletOne: "Arguably better than the first.",
    bulletTwo: "The key to life: happiness in your asshole.",
    cast: "Directed by JAY CHANDRASEKHAR starring STEVE LEMME KEVIN HEFFERNAN ERIK STOLHANSKE and PAUL SOTER",
  },
  {
    id: 66,
    name: "Waiting",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTIxNzY1MTQ5N15BMl5BanBnXkFtZTYwMTkxMzE3._V1_SX300.jpg",
    rating: 5,
    year: 2005,
    synopsis:
      "Get a sneak peek at what it’s like behind the apron at this chain restaurant. Remember to be kind to your servers, and watch out for the occasional sack or two, just don’t get caught looking.",
    genre: "Comedy",
    subgenre: "",
    runtime: "94 min",
    pairs: "a Batwing.",
    drinkRecipe: "Baileys, Blue Curaçao.",
    rated: "R",
    picture1: waiting1,
    picture2: waiting2,
    picture3: waiting3,
    tagline: "YOU WON'T SEND IT BACK!",
    taglineWriter: "Weirddough72, Yelp Elite",
    bulletOne: "Finding new and creative ways to show your balls.",
    bulletTwo:
      "Learning tips from the master of seduction: R̶y̶a̶n̶ ̶R̶e̶y̶n̶o̶l̶d̶s̶ Luis Guzman.",
    cast: "Directed by ROB MCKITTRICK starring RYAN REYNOLDS ANNA FARIS JUSTIN LONG and JOHN FRANCIS DALEY",
  },
  {
    id: 67,
    name: "Just Friends",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjA0Mzg2NjUzMl5BMl5BanBnXkFtZTcwNDg2ODUzMQ@@._V1_SX300.jpg",
    rating: 5,
    year: 2005,
    synopsis:
      "RyRey pulls out of a town full of losers only to be stuck back home for Christmas 10 years later.",
    genre: "Comedy",
    subgenre: "Romcom",
    runtime: "96 min",
    pairs: "a Yearbook Switch.",
    drinkRecipe: "Hot chocolate, Kahlúa, and rum.",
    rated: "PG-13",
    picture1: justFriends1,
    picture2: justFriends2,
    picture3: justFriends3,
    tagline: "LIKE A HIGH SCHOOL REUNION",
    taglineWriter: "Romy & Michele",
    bulletOne: "Never hearing back from Joyce",
    bulletTwo: "Best tip for escaping the friend-zone: be hot.",
    cast: "Directed by ROGER KUMBLE starring RYAN REYNOLDS ANNA FARIS AMY SMART and CHRIS KLEIN",
  },
  {
    id: 68,
    name: "Palm Springs",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjk0MTgzMmQtZmY2My00NmE5LWExNGUtYjZkNTA3ZDkyMTJiXkEyXkFqcGdeQXVyMTkxNjUyNQ@@._V1_SX300.jpg",
    rating: 6,
    year: 2020,
    synopsis:
      "Two wedding-goers get stuck in your classic Groundhog Day timeloop and must decide whether or not to stay. ",
    genre: "Comedy",
    subgenre: "Scifi",
    runtime: "90 min",
    pairs: "",
    noPairs: "a never-ending can of Tecate.",
    rated: "R",
    picture1: palmSprings1,
    picture2: palmSprings2,
    picture3: palmSprings3,
    tagline: "Weddings are great for studying dark holes",
    taglineWriter: "Steven Hawking",
    bulletOne: "JK Simmons has a killer cameo.",
    bulletTwo:
      "Using your infinite time to memorize peoples lives and fuck with them.",
    cast: "Directed by MAX BARBAKOW starring ANDY SAMBURG CRISTIN MILIOTI JK SIMMONS and PETER GALLAGHER",
  },
  {
    id: 69,
    name: "Blood Diner",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODBlYzAwN2QtNzViZC00Zjk2LTg0ZjgtMDIyZWJjZjVkOTFhXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1987,
    synopsis:
      "Two brothers prepare a cannibalistic feast of sacrifice to resurrect an ancient Godess",
    genre: "Cult Classic",
    subgenre: "Horror",
    runtime: "88 min",
    pairs: "",
    noPairs: "Sangria.",
    rated: "Unrated",
    picture1: bloodDiner1,
    picture2: bloodDiner2,
    picture3: bloodDiner3,
    tagline: "Stay away from the stake sandwich!",
    taglineWriter: "Chicken-Marsala Ali",
    bulletOne: "Bonding with your one weird uncle.",
    bulletTwo:
      "Seeing your buddy get violently killed and then asking if he's ok.",
    cast: "Directed by JACKIE KONG starring RICK BURKS  CARL CREW  ROGER DAUER and LISA ELAINA",
  },
  {
    id: 70,
    name: "The Breakfast Club",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTM5N2ZmZTMtNjlmOS00YzlkLTk3YjEtNTU1ZmQ5OTdhODZhXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    year: 1985,
    synopsis:
      "Archetypes in detention bond over the challenges of being a teenager.",
    genre: "Drama",
    subgenre: "",
    runtime: "97 min",
    pairs: "",
    noPairs: "bottomless Mimosa's.",
    rated: "R",
    picture1: breakfastClub1,
    picture2: breakfastClub2,
    picture3: breakfastClub3,
    tagline: "YOU WON'T FORGET ABOUT IT.",
    taglineWriter: "Simple Minds",
    bulletOne: "Weed somehow affecting the kids like meth.",
    bulletTwo: "Learning to judge a book by its cover.",
    cast: "Directed by JOHN HUGHES starring JUDD NELSON MOLLY RINGWALD ALLY SHEEDY and EMILIO ESTEVEZ",
  },
  {
    id: 71,
    name: "Ferris Bueller's Day Off",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDA0NjZhZWUtNmI2NC00MmFjLTgwZDYtYzVjZmNhMDVmOTBkXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    year: 1986,
    synopsis:
      "A devious little truant plays hooky and attempts to murder the school principal.",
    genre: "Comedy",
    subgenre: "",
    runtime: "103 min",
    pairs: "",
    noPairs: "Pina Coladas by the pool.",
    rated: "PG-13",
    picture1: ferris1,
    picture2: ferris3,
    picture3: ferris2,
    tagline: "You could say he's Cutting Class",
    taglineWriter: "Brad Pitt",
    bulletOne: "Drugged out Charlie Sheen.",
    bulletTwo:
      "If you have the technical skills to motion-activate an audio system and hack a computer, school is a waste of your time.",
    cast: "Directed by JOHN HUGHES starring MATTHEW BRODERICK MIA SARA ALAN RUCK and JEFFREY JONES",
  },
  {
    id: 72,
    name: "The Karate Kid",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTkzY2YzNmYtY2ViMS00MThiLWFlYTEtOWQ1OTBiOGEwMTdhXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    year: 1984,
    synopsis:
      "A little dirtbag from Newark moves to California and ruins the life of a well-to-do karate student.",
    genre: "Sports",
    subgenre: "Drama",
    runtime: "126 min",
    pairs: "a Crane Kick.",
    noPairs: "Banzai!!!",
    drinkRecipe: "Sake and sapporo beer.",
    rated: "PG",
    picture1: karateKid1,
    picture2: karateKid2,
    picture3: karateKid3,
    tagline: "IT'LL SWEEP YOUR LEGS!",
    taglineWriter: "Bodybag Monthly",
    bulletOne: "Sensei Kreese being a total badass.",
    bulletTwo: "Mega 80's vibes.",
    cast: "Directed by JOHN G. AVILDSEN starring RALPH MACCHIO  PAT MORITA  WILLIAM ZABKA and MARTIN KOVE",
  },
  {
    id: 73,
    name: "The Karate Kid Part II",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDdjNDJjOWYtZDNkYy00MTM3LTlhY2EtNzMxMjhhMjU3OTFmXkEyXkFqcGdeQXVyMTUzMDUzNTI3._V1_SX300.jpg",
    rating: 5,
    year: 1986,
    synopsis:
      "Chozen Toguchi had it all: his own business, his pick of the ladies, and his blackbelt from the most respected karate master in Okinawa. Then Scumbag LaRusso arrives and fucks his life up worse than Johnny's. ",
    genre: "Sports",
    subgenre: "",
    runtime: "113 min",
    pairs: "",
    noPairs: "Asahi Super Dry.",
    rated: "PG",
    picture1: karateKid21,
    picture2: karateKid22,
    picture3: karateKid23,
    tagline: "Miyagi-San is a sensei-tive coward!",
    taglineWriter: "Sato",
    bulletOne: "Tamlyn Tomita is a babe.",
    bulletTwo:
      "Ranger Trivette sparing Daniel's ego by not shattering every sheet of ice with his pinky.",
    cast: "Directed by JOHN G. AVILDSEN starring RALPH MACCHIO PAT MORITA TAMLYN TOMITA and YUJI OKUMOTO",
  },
  {
    id: 74,
    name: "The Karate Kid Part III",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODk0NzA4MTMtNGQ3Zi00OTdlLThiZmQtOTI0YWEyNDFiMTg2XkEyXkFqcGdeQXVyNTUyMzE4Mzg@._V1_SX300.jpg",
    rating: 4,
    year: 1989,
    synopsis:
      "Daniel FINALLY realizes that Cobra Kai is the superior dojo - all it took was a store-brand Steven Seagal to prove it.",
    genre: "Sports",
    subgenre: "",
    runtime: "112 min",
    pairs: "",
    noPairs: "champagne in a hot tub.",
    rated: "PG",
    picture1: karateKid31,
    picture2: karateKid32,
    picture3: karateKid33,
    tagline: "Forget boards, Daniel's been hitting the buffet",
    taglineWriter: "Fat Morita",
    bulletOne: "Terry Silver is the man. ",
    bulletTwo:
      "Opening Mr. Miyagi's Little Trees without an ounce of market research.",
    cast: "Directed by JOHN G. AVILDSEN starring RALPH MACCHIO PAT MORITA SEAN KANAN and THOMAS IAN GRIFFITH",
  },
  {
    id: 75,
    name: "The Next Karate Kid",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODFjOTk3NTMtNTZkYi00ZWVjLTg2ZDItMTM1ODVlMDA0MmViXkEyXkFqcGdeQXVyMTcwOTQzOTYy._V1_SX300.jpg",
    rating: 4,
    year: 1994,
    synopsis:
      "This movie gets a lot of guff, but it's not that bad. It's the worst of the four movies but if you love Pat Morita and 90's cheesiness, you'll find this movie perfectly enjoyable. ",
    genre: "Sports",
    subgenre: "",
    runtime: "107 min",
    pairs: "a Drunk Monk.",
    drinkRecipe: "Chimay, Cognac.",
    rated: "PG",
    picture1: nextKarate1,
    picture2: nextKarate2,
    picture3: nextKarate3,
    tagline: "GIRL WAX OFF BETTER!",
    taglineWriter: "Mr. Miyagi",
    bulletOne: "'Accidentally' walking in on Hilary Swank getting dressed.",
    bulletTwo:
      "The WEIRDEST high school ever, complete with mini gestapo and hawks.",
    cast: "Directed by CHRISTOPHER CAIN starring PAT MORITA HILARY SWANK CHRIS CONRAD and MICHAEL IRONSIDE",
  },
  {
    id: 76,
    name: "Summer Rental",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODkxMjUzYzEtNjA0My00M2Q4LWE0NzEtMDIxYTdhM2Y3ZDgzL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1985,
    synopsis:
      "An overworked air traffic controller rents a beach house and accidentally makes enemies with the town bully - who happens to own the rental property. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "87 min",
    pairs: "",
    noPairs: "beer and oyster crackers.",
    rated: "PG",
    picture1: summerRental1,
    picture2: summerRental2,
    picture3: summerRental3,
    tagline: "For sailors and landlubbers alike.",
    taglineWriter: "Scully",
    bulletOne: "80's fixing-stuff-up montage. ",
    bulletTwo:
      "Richard Crenna acting as the ultimate jerk, Rip Torn actually being a pirate.",
    cast: "Directed by CARL REINER starring JOHN CANDY KAREN AUSTIN RIP TORN and RICHARD CRENNA",
  },
  {
    id: 77,
    name: "Star Wars: A New Hope",
    poster:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimg.reelgood.com%2Fcontent%2Fmovie%2F7fef1434-6499-4223-b367-9be512dd7445%2Fposter-780.jpg&f=1&nofb=1",
    rating: 5,
    year: 1977,
    synopsis:
      "This historical drama set far, far, away follows a whiny loser who, after causing the death of his family, joins a terrorist organization and attempts to overthrow a noble Emperor protected by his powerful bodyguard.",
    genre: "Scifi",
    subgenre: "Adventure",
    runtime: "121 min",
    pairs: "PeptoBleu.",
    drinkRecipe: "Blue curaçao, Bols Yogurt Liqueur, and vanilla rum.",
    rated: "PG",
    picture1: newHope1,
    picture2: newHope2,
    picture3: newHope3,
    tagline: "Didn't see any space jugs. Super pissed.",
    taglineWriter: "Steven Hyde",
    bulletOne: "A Zero's Journey.",
    bulletTwo:
      "Farting around on speedsters at the farm somehow makes you Maverick in an X-Wing.",
    cast: "Directed by GEORGE LUCAS starring MARK HAMILL HARRISON FORD CARRIE FISHER and ALEC GUINNESS",
  },
  {
    id: 78,
    name: "Star Wars: The Return of the Jedi",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOWZlMjFiYzgtMTUzNC00Y2IzLTk1NTMtZmNhMTczNTk0ODk1XkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 5,
    year: 1983,
    synopsis:
      "In the third and final Star Wars movie, a little green goblin REALLY doesn't want to train whiney ass Luke to face his daddy issues.",
    genre: "Scifi",
    subgenre: "Adventure",
    runtime: "131 min",
    pairs: "swamp water.",
    drinkRecipe: "fresca, rum, Blue Curaçao",
    rated: "PG",
    picture1: jedi1,
    picture2: jedi2,
    picture3: jedi3,
    tagline: "BOBA FETT LIVES!",
    taglineWriter: "Patton Oswalt",
    bulletOne: "Realizing Ewoks aren't cute but savage.",
    bulletTwo:
      "About 23 minutes in, millions of boys cried out in joy and suddenly came of age.",
    cast: "Directed by RICHARD MARQUAND starring MARK HAMILL HARRISON FORD CARRIE FISHER and JAMES EARL JONES",
  },
  {
    id: 79,
    name: "A Clockwork Orange",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTY3MjM1Mzc4N15BMl5BanBnXkFtZTgwODM0NzAxMDE@._V1_SX300.jpg",
    rating: 5,
    year: 1971,
    synopsis:
      "A gang of miscreants terrorize society at large until the lead droog undergoes a behavior modification treatment.",
    genre: "Scifi",
    subgenre: "Crime",
    runtime: "136 min",
    pairs: "an Orange Clockwork.",
    drinkRecipe: "6 shots Absolut Orange, 6 shots OJ.",
    rated: "R",
    picture1: clockworkOrange1,
    picture2: clockworkOrange2,
    picture3: clockworkOrange3,
    tagline: "I'm never singing in the rain again",
    taglineWriter: "Gene Kelly",
    bulletOne: "Drinking enough milk to put the McPoyles to shame.",
    bulletTwo:
      "Undergoing a radical procedure that puts the 'rape' in therapy.",
    cast: "Directed by STANLEY KUBRICK starring MALCOLM MCDOWELL PATRICK MAGEE WARREN CLARKE and ADRIENNE CORRI",
  },
  {
    id: 80,
    name: "Class of Nuke 'Em High",
    poster:
      "https://m.media-amazon.com/images/M/MV5BY2QyYTQ1NzktMzc3NC00ZTcwLTgwZjAtYmU0NjQ1MDFmNDQ2XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1986,
    synopsis:
      "High school students exhibit erratic behavior following an accident at a nuclear power plant. Is it that different from Toxie? No. Does anyone care? No.",
    genre: "Cult Classic",
    subgenre: "Horror",
    runtime: "85 min",
    pairs: "a Toiletmonster.",
    drinkRecipe: "Kahlúa, Kraken.",
    rated: "R",
    picture1: nukeEmHigh1,
    picture2: nukeEmHigh2,
    picture3: nukeEmHigh3,
    tagline: "IT'LL RIP YOUR THROAT OUT!",
    taglineWriter: "Cretin",
    bulletOne: "Spawned a bunch of sequels.",
    bulletTwo:
      "This movie has everything you love and expect from Troma - nudity, violence, absurdity, and nuclear waste.",
    cast: "Directed by LLOYD KAUFMAN starring PAT RYAN GIL BRENTON JANELLE BRADY and BRAD DUNKER",
  },
  {
    id: 81,
    name: "ThanksKilling",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTYwNDMzODI0Ml5BMl5BanBnXkFtZTgwNzMzODQyOTE@._V1_SX300.jpg",
    rating: 5,
    year: 2009,
    synopsis:
      "A turkey kills college kids during Thanksgiving break. With an opening line of 'Nice tits, bitch!', how could this movie not be a winner?",
    genre: "Cult Classic",
    subgenre: "Horror",
    runtime: "70 min",
    pairs: "Wild Turkie.",
    drinkRecipe: "Wild Turkey",
    rated: "Not Rated",
    picture1: thankskilling1,
    picture2: thankskilling2,
    picture3: thankskilling3,
    tagline: "Crazier than the JonBenet Ramsey case!",
    taglineWriter: "Wanda Lust",
    bulletOne: "Based on a true story.",
    bulletTwo:
      "Best Friend Billy song, and Darren's mom having the juciest poon in town.",
    cast: "Directed by JORDAN DOWNEY starring RYAN E. FRANCIS AARON RINGHISER-CARLSON CHUCK LAMB and GENERAL BASTARD",
  },
  {
    id: 82,
    name: "Con Air",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGZmNGIxMTYtMmVjMy00YzhkLWIyOTktNTExZGFiYjNiNzdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    year: 1997,
    synopsis:
      "This is a badass movie with a cast that oozes machismo. Incredible action and guitar riffs throughout. If you love cheesy one-liners and over-the-top deaths, this is the flick for you.",
    genre: "Action",
    subgenre: "",
    runtime: "115 min",
    pairs: "",
    noPairs: "several B-52 shots.",
    rated: "R",
    picture1: conAir1,
    picture2: conAir2,
    picture3: conAir3,
    tagline: "IT WAS LOVE AT FIRST FLIGHT!",
    taglineWriter: "Johnny 23",
    bulletOne: "Nic Cage's mullet.",
    bulletTwo:
      "Guard Falzon's blistering comeback to Cyrus' stewardess comment.",
    cast: "Directed by SIMON WEST starring NICOLAS CAGE JOHN MALKOVICH JOHN CUSACK and VING RHAMES",
  },
  {
    id: 83,
    name: "Rush Hour",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYWM2NDZmYmYtNzlmZC00M2MyLWJmOGUtMjhiYmQ2OGU1YTE1L2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1998,
    synopsis:
      "Jackie Chan and Chris Tucker teach us that culture shock is real, being a cop is tough, and you don't EVER touch a black man's radio.",
    genre: "Comedy",
    subgenre: "Action",
    runtime: "98 min",
    pairs: "",
    noPairs: "Tsingtao Stout.",
    rated: "PG-13",
    picture1: rushHour1,
    picture2: rushHour2,
    picture3: rushHour3,
    tagline: "These cops are a spicy one-two combo!",
    taglineWriter: "General Tso",
    bulletOne: "Jackie Chan's first Hollywood success.",
    bulletTwo:
      "Taking the time to crack jokes even as your friends life literally hangs in the balance.",
    cast: "Directed by BRETT RATNER starring JACKIE CHAN CHRIS TUCKER KEN LEUNG and TOM WILKINSON",
  },
  {
    id: 84,
    name: "Rush Hour 2",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODhlNGJjMWQtZGMyYS00MzJhLWJhZGMtY2NlNDI5Nzg5NTU2XkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 5,
    year: 2001,
    synopsis:
      "This time around the roles are reversed - Carter is the fish out of water in Hong Kong.",
    genre: "Comedy",
    subgenre: "Action",
    runtime: "90 min",
    pairs: "Buttercream Martini.",
    drinkRecipe: "Vodka, butterscotch schnapps, whipped cream.",
    rated: "PG-13",
    picture1: rushHour21,
    picture2: rushHour22,
    picture3: rushHour23,
    tagline: "So nice they made it twice!",
    taglineWriter: "Michael Jackson",
    bulletOne: "Checking again to ensure a badge is real.",
    bulletTwo:
      "Chris Tucker teaches another thing never to do to a black man: jump in front of him in a buffet line.",
    cast: "Directed by BRETT RATNER starring JACKIE CHAN CHRIS TUCKER JOHN LONE and ZIYI ZHANG",
  },
  {
    id: 85,
    name: "Rush Hour 3",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTA0Nzg5NjQ0MDBeQTJeQWpwZ15BbWU3MDE4Mzg5NDE@._V1_SX300.jpg",
    rating: 5,
    year: 2007,
    synopsis:
      "After an assassination attempt, agents Carter and Lee have to track down the identity of a powerful Triad.",
    genre: "Comedy",
    subgenre: "Action",
    runtime: "91 min",
    pairs: "",
    noPairs: "champagne and baijiu.",
    rated: "PG-13",
    picture1: rushHour31,
    picture2: rushHour32,
    picture3: rushHour33,
    tagline: "A FILM FOR YOU AND ME!",
    taglineWriter: "Yu and Mi",
    bulletOne: "Consul Han just can't stay out of trouble.",
    bulletTwo: "Soo Yung is no longer so young.",
    cast: "Directed by BRETT RATNER starring JACKIE CHAN CHRIS TUCKER HIROYUKI SANADA and NOEMIE LENOIR ",
  },
  {
    id: 86,
    name: "Days of Thunder",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmU0MjU5ODEtODRkOC00MzdlLTk5YmEtMmY0MWM4MzM2MDMxXkEyXkFqcGdeQXVyMjUzOTY1NTc@._V1_SX300.jpg",
    rating: 5,
    year: 1990,
    synopsis:
      "Rookie driver Cole Trickle, who is a total son of a bitch, is determined to take on Daytona-- but first he has to win a foot-race against Robert Duvall.",
    genre: "Action",
    subgenre: "Sports",
    runtime: "107 min",
    pairs: "a son-of-a-bitch.",
    noPairs: "",
    drinkRecipe: "",
    rated: "PG-13",
    picture1: daysofthunder1,
    picture2: daysofthunder2,
    picture3: daysofthunder3,
    tagline: "SHAKE n BAKE THAT PIT CREW!",
    taglineWriter: "Ricky Bobby",
    bulletOne: "Great non-NASCAR race sequences as well.",
    bulletTwo: "Not being anything from California.",
    cast: "Directed by TONY SCOTT starring TOM CRUISE ROBERT DUVALL NICOLE KIDMAN and MICHAEL ROOKER",
  },
  {
    id: 87,
    name: "Beverly Hills Ninja",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNGU1NzQ1ZmItZGVmNC00NWQ4LWEwYTYtOTc4NWE1MmY0YTk2XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    year: 1997,
    synopsis:
      "Chris Farley tumbles, flips, and crashes his way through Beverly Hills in this masterpiece of physical comedy.",
    genre: "Comedy",
    subgenre: "",
    runtime: "88 min",
    pairs: "a Sake Bomb.",
    drinkRecipe: "Drop a shot of sake in a Japanese beer.",
    rated: "PG-13",
    picture1: beverlyHillsNinja1,
    picture2: beverlyHillsNinja2,
    picture3: beverlyHillsNinja3,
    tagline: "REAL LIFE KUNG FU PANDA.",
    taglineWriter: "Oogway",
    bulletOne: "Sniffing shrimp after retrieving it from cleavage.",
    bulletTwo: "Action scenes are both well-choreographed and hilarious.",
    cast: "Directed by DENNIS DUGAN starring CHRIS FARLEY ROBIN SHOU NICOLLETTE SHERIDAN and CHRIS ROCK",
  },
  {
    id: 88,
    name: "Rumble in the Bronx",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTUxMzY1N2YtZjM5NC00MmUzLThkNzEtYjNiYzg0MzI4MDc5L2ltYWdlXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 4,
    year: 1995,
    synopsis:
      "Jackie Chan at his best, no need for a stunt man because he’ll fight with a broken foot. Visiting America for the first time, Chan takes on street tuffs, out for DINERO, with his masterful kung fu skills.",
    genre: "Action",
    subgenre: "",
    runtime: "87 min",
    pairs: "",
    noPairs: "Streetwise IPA",
    rated: "R",
    picture1: rumbleInBronx1,
    picture2: rumbleInBronx2,
    picture3: rumbleInBronx3,
    tagline: "Gotta love the NYC Mountain Range",
    taglineWriter: "Cliffhanger",
    bulletOne: "Getting caught checking yourself out.",
    bulletTwo: "Wheelchair kid is an awesome wingman.",
    cast: "Directed by STANLEY TONG starring JACKIE CHAN ANITA MUI FRANCOISE YIP and GARVIN CROSS",
  },
  {
    id: 89,
    name: "The Fearless Hyena",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYWNmOTk3NjktYWE1ZS00ZjcyLWJjYjctMzJjN2Y0NjVhN2FlXkEyXkFqcGdeQXVyNzc5MjA3OA@@._V1_SX300.jpg",
    rating: 6,
    year: 1979,
    synopsis:
      "Jackie Chan gives inspiration to “Kung Fu Panda’s” chopsticks skills in this 70’s action flick.",
    genre: "Action",
    subgenre: "",
    runtime: "98 min",
    pairs: "Canton Crippler.",
    drinkRecipe: "Baijiu, iced tea.",
    rated: "R",
    picture1: fearlessHyena1,
    picture2: fearlessHyena2,
    picture3: fearlessHyena3,
    tagline: "Damn! This guy sure can fight!",
    taglineWriter: "Chris Tucker",
    bulletOne: "Hilariously bad English dubbing.",
    bulletTwo: "Chan in drag using his fake tits to punch a guy.",
    cast: "Directed by JACKIE CHAN starring JACKIE CHAN JAMES TIEN KUN LI and SHI-KWAN YAN",
  },
  {
    id: 90,
    name: "Hot Shots!",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzgxNmI1ODItZWZlYy00ZjE0LWIwYjgtMTk2MzU0MjhkOWIzXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    year: 1991,
    synopsis:
      "Lieutenant Topper Harley has to overcome paternal conflict syndrome to regain his wings in this hilarious Top Gun parody.",
    genre: "Comedy",
    subgenre: "",
    runtime: "84 min",
    pairs: "Hot Shoddy's.",
    drinkRecipe: "Hot Toddy's in shot form.",
    rated: "PG-13",
    picture1: hotShots1,
    picture2: hotShots2,
    picture3: hotShots3,
    tagline: "GIVES YOU BREATH TO STAY",
    taglineWriter: "Munich",
    bulletOne: "Getting shot down on every mission.",
    bulletTwo:
      "Teaching viewers to never talk about things you'll do in the future. If you do, you're dead. ",
    cast: "Directed by JIM ABRAHAMS starring CHARLIE SHEEN CARY ELWES VALERIA GOLINO and LLOYD BRIDGES",
  },
  {
    id: 91,
    name: "Hot Shots! Part Deux",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjU2ODlmZWMtODEwZS00NTQ0LTk1ZDYtYzk0ZjE3ZGZhMGM0XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    year: 1993,
    synopsis:
      "Topper Harley is back at it again, but this time he aims to win the war like Rambo II - the very movie this spoof is based on.",
    genre: "Comedy",
    subgenre: "",
    runtime: "86 min",
    pairs: "",
    noPairs: "dos equis (or deux equis).",
    rated: "PG-13",
    picture1: hotShotsDeux1,
    picture2: hotShotsDeux2,
    picture3: hotShotsDeux3,
    tagline: "Un grand parodie of Rambeux!",
    taglineWriter: "Sîlvéstér Stâllônë",
    bulletOne: "Suspenseful prison escape scene.",
    bulletTwo:
      "Inflicting more damage to a referee than an opponent during a fight. ",
    cast: "Directed by JIM ABRAHAMS starring CHARLIE SHEEN VALERIA GOLINO RICHARD CRENNA and LLOYD BRIDGES",
  },
  {
    id: 92,
    name: "Kung Pow! Enter the Fist",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGQxZDEwZDctMjNkMi00YmIxLTgyN2MtYmJhYjEzZGY0NjljXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    year: 2002,
    synopsis:
      "The Chosen One travels from town to town in search of a man named Betty. The movie splices new action with an old Hong Kong flick, and it's awesome. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "81 min",
    pairs: "Kung Pao Chicken.",
    drinkRecipe: "Shot of vodka mixed with sriracha.",
    rated: "PG-13",
    picture1: kungPow1,
    picture2: kungPow2,
    picture3: kungPow3,
    tagline: "You'll laugh 'til your shirt rips!",
    taglineWriter: "Shirt ripper",
    bulletOne:
      "Masterfully parodies bad dubbings and wild changes in audio volume.",
    bulletTwo:
      "Making a split-second choice, choosing the wrong option, instantly regretting it.",
    cast: "Directed by STEVE OEDEKERK starring STEVE OEDEKERK FEI LUNG LING-LING HSIEH and FEI LUNG",
  },
  {
    id: 93,
    name: "Airplane!",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjA3YjdhMWEtYjc2Ni00YzVlLWI0MTUtMGZmNTJjNmU0Yzk2XkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 6,
    year: 1980,
    synopsis:
      "THE classic spoof movie. If you haven't seen it yet, then Shirley there's something wrong with you. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "88 min",
    pairs: "",
    noPairs: "beer ... black. As long as you don't have a drinking problem.",
    rated: "PG",
    picture1: airplane1,
    picture2: airplane2,
    picture3: airplane3,
    tagline: "I prefer movies with gladiators.",
    taglineWriter: "Captain Clarence",
    bulletOne:
      "A plane is like a car in the sky, but that's not important right now.",
    bulletTwo: "Learning how to speak jive and re-inflate the autopilot.",
    cast: "Directed by JIM ABRAHAMS starring ROBERT HAYS JULIE HAGERTY LESLIE NIELSEN and LLOYD BRIDGES",
  },
  {
    id: 94,
    name: "The Princess Bride",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGM4M2Q5N2MtNThkZS00NTc1LTk1NTItNWEyZjJjNDRmNDk5XkEyXkFqcGdeQXVyMjA0MDQ0Mjc@._V1_SX300.jpg",
    rating: 5,
    year: 1987,
    synopsis: "A man must save his one true love.",
    genre: "Adventure",
    subgenre: "Comedy",
    runtime: "98 min",
    pairs: "",
    noPairs: "red wine without iocane powder.",
    rated: "PG",
    picture1: princessBride1,
    picture2: princessBride2,
    picture3: princessBride3,
    tagline: "Princess Bride? So ... a Queen?",
    taglineWriter: "Freddie Mercury",
    bulletOne: "Engaging in a battle of wits.",
    bulletTwo:
      "Peanuts are the best snack for this movie if anybody wants one.",
    cast: "Directed by ROB REINER starring CARY ELWES MANDY PATINKIN ROBIN WRIGHT and WALLACE SHAWN",
  },
  {
    id: 95,
    name: "Scary Movie",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGEzZjdjMGQtZmYzZC00N2I4LThiY2QtNWY5ZmQ3M2ExZmM4XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 2000,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "88 min",
    pairs: "",
    rated: "R",
    picture1: scaryMovie1,
    picture2: scaryMovie12,
    picture3: scaryMovie13,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by KEENEN IVORY WAYANS starring ANNA FARIS JON ABRAHAMS MARLON WAYANS and DAVE SHERIDAN",
  },
  {
    id: 96,
    name: "Scary Movie 2",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzQxYjU1OTUtYjRiOC00NDg2LWI4MWUtZGU5YzdkYTcwNTBlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    year: 2001,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "83 min",
    pairs: "",
    rated: "R",
    picture1: scaryMovie21,
    picture2: scaryMovie22,
    picture3: scaryMovie23,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by KEENEN IVORY WAYANS starring ANNA FARIS MARLON WAYANS DAVID CROSS and CHRIS ELLIOTT",
  },
  {
    id: 97,
    name: "Scary Movie 3",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDE2NTIyMjg2OF5BMl5BanBnXkFtZTYwNDEyMTg3._V1_SX300.jpg",
    rating: 5,
    year: 2003,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "84 min",
    pairs: "",
    rated: "PG-13",
    picture1: scaryMovie31,
    picture2: scaryMovie32,
    picture3: scaryMovie33,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by DAVID ZUCKER starring ANNA FARIS CHARLIE SHEEN REGINA HALL and SIMON REX",
  },
  {
    id: 98,
    name: "Scary Movie 4",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZmFkMzc2NTctN2U1Ni00MzE5LWJmMzMtYWQ4NjQyY2MzYmM1XkEyXkFqcGdeQXVyNTIzOTk5ODM@._V1_SX300.jpg",
    rating: 5,
    year: 2006,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "83 min",
    pairs: "",
    rated: "PG-13",
    picture1: scaryMovie41,
    picture2: scaryMovie42,
    picture3: scaryMovie43,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by DAVID ZUCKER starring ANNA FARIS CRAIG BIERKO LESLIE NIELSEN and SIMON REX",
  },
  {
    id: 99,
    name: "Above The Law",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjkyY2EzZmMtOGFhYS00OWQyLWI0YmMtZGFmNzhkN2EzZDgyXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 5,
    year: 1988,
    synopsis:
      "Ex-CIA agent Nico Toscani's moral compass may be Above the Law, but the action is Below the Ponytail.",
    genre: "Action",
    subgenre: "",
    runtime: "99 min",
    pairs: "",
    noPairs: "Miller High Life.",
    rated: "R",
    picture1: aboveTheLaw1,
    picture2: aboveTheLaw2,
    picture3: aboveTheLaw3,
    tagline: "SEAGAL WOULD 𝙍𝙀𝙏𝙍𝙀𝘼𝙏 𝘼𝙉𝘿 𝙎𝙐𝙍𝙍𝙀𝙉𝘿𝙀𝙍!",
    taglineWriter: "Van Damme",
    bulletOne:
      "Having your Oldsmobile take about a thousand bullets and survive.",
    bulletTwo: "Bringing fights inside to maximize property damage.",
    cast: "Directed by ANDREW DAVIS starring STEVEN SEAGAL PAM GRIER HENRY SILVA and RON DEAN",
  },
  {
    id: 100,
    name: "The Goonies",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODRlMjRkZGEtZWM2Zi00ZjYxLWE0MWUtMmM1YWM2NzZlOTE1XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1985,
    synopsis:
      "A group of latchkey kids find a treasure map and battle criminals with the help of a hideously deformed monster.",
    genre: "Adventure",
    subgenre: "Cult Classic, Comedy",
    runtime: "114 min",
    pairs: "a One-Eyed Willy.",
    drinkRecipe: "Captain Morgan Loconut & Malibu.",
    rated: "PG",
    picture1: goonies1,
    picture2: goonies2,
    picture3: goonies3,
    tagline: "You'd be loonie to miss it!",
    taglineWriter: "Andy Feltersnatch",
    bulletOne: "Shooting a gun to start a fire.",
    bulletTwo: "Forcing fat children to dance for your entertainment",
    cast: "Directed by RICHARD DONNER starring SEAN ASTIN  JOSH BROLIN  COREY FELDMAN  and KE HUY QUAN",
  },
  {
    id: 101,
    name: "Hard to Kill",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTc4NzE1NTU5N15BMl5BanBnXkFtZTgwNTgwNTg4NjE@._V1_SX300.jpg",
    rating: 5,
    year: 1990,
    synopsis:
      "Segal is left in a coma after an assassination attempt. No, really -- the medical staff just left him to rot. He woke up with a huge beard and innumerable bedsores. ",
    genre: "Action",
    subgenre: "",
    runtime: "96 min",
    pairs: "a Ponytail Whip.",
    drinkRecipe: "Dark Rum, Coke.",
    rated: "R",
    picture1: hardToKill1,
    picture2: hardToKill2,
    picture3: hardToKill3,
    tagline: "Hard to kill? Give him a 𝘽𝙪𝙡𝙡𝙚𝙩 𝙩𝙤 𝙩𝙝𝙚 𝙃𝙚𝙖𝙙.",
    taglineWriter: "Sly Stallone",
    bulletOne:
      "Only taking about a weekend to fully recover and resume snapping spines.",
    bulletTwo: "Stabbing a dude in the jugular with a broken pool stick",
    cast: "Directed by BRUCE MALMUTH starring STEVEN SEAGAL KELLY LeBROCK WILLIAM SADLER and CHARLES BOSWELL",
  },
  {
    id: 102,
    name: "Wild Wild West",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYmYyMTc4YjItMGNhNC00OWQwLWJhMWUtNTdjZDgxMDI5MjE2L2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1999,
    synopsis:
      "Set in post Civil War America, the Fresh Prince and Pirate King team up to save the President and preserve the Union.",
    genre: "Action",
    subgenre: "Comedy",
    runtime: "106 min",
    pairs: "a Dusty Boot.",
    drinkRecipe: "sasparilla & whiskey.",
    rated: "PG-13",
    picture1: wildwildWest1,
    picture2: wildwildWest2,
    picture3: wildwildWest3,
    tagline: "Legless jokes were savage as Fort Wilderness",
    taglineWriter: "Benjamin Martin",
    bulletOne:
      "Glad Will Smith turned down The Matrix 'cause this movie rules.",
    bulletTwo:
      "Giant mechanical spiders, wacky gadgets, and Salma Hayek to boot.",
    cast: "Directed by BARRY SONNENFELD starring WILL SMITH KEVIN KLINE SALMA HAYEK and KENNETH BRANAGH",
  },
  {
    id: 103,
    name: "The Shawshank Redemption",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDFkYTc0MGEtZmNhMC00ZDIzLWFmNTEtODM1ZmRlYWMwMWFmXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    rating: 5,
    year: 1999,
    synopsis:
      "A financial whiz faces consecutive life sentences for his wifes murder - but doesn't intend to serve them.",
    genre: "Drama",
    subgenre: "",
    runtime: "142 min",
    pairs: "",
    noPairs: "icy cold Bohemia style beer.",
    rated: "R",
    picture1: shawshank1,
    picture2: shawshank2,
    picture3: shawshank3,
    tagline: "I WAS WRONGFULLY CONVICTED!",
    taglineWriter: "Andy Duframed",
    bulletOne: "One of Kings best non-horror stories.",
    bulletTwo: "You know it's fiction when an accountant is the cool guy.",
    cast: "Directed by FRANK DARABONT starring TIM ROBBINS MORGAN FREEMAN JAMES WHITMORE and CLANCY BROWN",
  },
  {
    id: 104,
    name: "The Godfather",
    poster:
      "https://m.media-amazon.com/images/M/MV5BM2MyNjYxNmUtYTAwNi00MTYxLWJmNWYtYzZlODY3ZTk3OTFlXkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 2,
    year: 1972,
    synopsis:
      "A mumbly loser groans his way through a wedding. An overrated, three hour snoozefest. Spoiler alert: Orange foreshadows death. Wow, cool.",
    genre: "Crime",
    subgenre: "",
    runtime: "175 min",
    pairs: "",
    noPairs: "Nyquil - if you can stay awake long enough to drink it.",
    rated: "R",
    picture1: godfather1,
    picture2: godfather2,
    picture3: godfather3,
    tagline: "Hmmm mehm mhmmmmm",
    taglineWriter: "Brando",
    bulletOne: "Inaudible dialogue followed by ear shattering gunshots.",
    bulletTwo:
      "Only liking it because your parents insist it's the greatest movie of all time.",
    cast: "Directed by FRANCIS FORD COPPOLA starring MARLON BRANDO AL PACINO JAMES CAAN and ROBERT DUVALL",
  },
  {
    id: 105,
    name: "The Good, The Bad, and the Ugly",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTQ5NDI3MTI4MF5BMl5BanBnXkFtZTgwNDQ4ODE5MDE@._V1_SX300.jpg",
    rating: 4,
    year: 1966,
    synopsis: "",
    genre: "Western",
    subgenre: "",
    runtime: "178 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "Fuckin long as shit. Push 2.",
    bulletTwo: "",
    cast: "Directed by SERGIO LEONE starring CLINT EASTWOOD LEE VAN CLEEF RADA RASSIMOV and ELI WALLACH",
  },
  {
    id: 106,
    name: "The Outlaw Josey Wales",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjIxNDYxMTk2MF5BMl5BanBnXkFtZTgwMjQxNjU3MTE@._V1_SX300.jpg",
    rating: 6,
    year: 1976,
    synopsis:
      "Kickass western with Clint Eastwood as a tobacco chewin', pistol pullin' gunslinger who teaches men why they should've whistled Dixie. ",
    genre: "Western",
    subgenre: "Action",
    runtime: "135 min",
    pairs: "Miracle Elixir.",
    drinkRecipe: "whiskey, sasparilla.",
    rated: "PG",
    picture1: joseyWales1,
    picture2: joseyWales2,
    picture3: joseyWales3,
    tagline: "SPARKS RIGHT UP!",
    taglineWriter: "Flint Eastwood",
    bulletOne: "Spitting chew to indicate dissatisfaction.",
    bulletTwo: "Being so cool that even the Commanche want to chill with you.",
    cast: "Directed by CLINT EASTWOOD starring CLINT EASTWOOD SONDRA LOCKE CHIEF DAN GEORGE and BILL MCKINNEY",
  },
  {
    id: 107,
    name: "Unforgiven",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODM3YWY4NmQtN2Y3Ni00OTg0LWFhZGQtZWE3ZWY4MTJlOWU4XkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 6,
    year: 1992,
    synopsis:
      "A couple of old timers dust off their pistols to hunt down a man who sliced up a hooker.",
    genre: "Western",
    subgenre: "Action",
    runtime: "130 min",
    pairs: "",
    noPairs: "shots of Bulleit bourbon.",
    rated: "R",
    picture1: unforgiven1,
    picture2: unforgiven2,
    picture3: unforgiven3,
    tagline: "Forgive one another - unless you don't want to",
    taglineWriter: "Proverbs 17:9",
    bulletOne: "Showing your true colors.",
    bulletTwo:
      "Cool lines. When told he shot an unarmed man, Eastwood replies 'well he should have armed himself'.",
    cast: "Directed by CLINT EASTWOOD starring CLINT EASTWOOD MORGAN FREEMAN GENE HACKMAN and JAIMZ WOOLVETT",
  },
  {
    id: 108,
    name: "Joker",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNGVjNWI4ZGUtNzE0MS00YTJmLWE0ZDctN2ZiYTk2YmI3NTYyXkEyXkFqcGdeQXVyMTkxNjUyNQ@@._V1_SX300.jpg",
    rating: 3,
    year: 2019,
    synopsis:
      "A little loser gets bullied, turns psycho, then dances his way through Gotham. ",
    genre: "Crime",
    subgenre: "",
    runtime: "122 min",
    pairs: "",
    noPairs: "Joker IPA",
    rated: "R",
    picture1: joker1,
    picture2: joker2,
    picture3: joker3,
    tagline: "NOT ENOUGH LIP SMACKING",
    taglineWriter: "Leaf Edger",
    bulletOne: "Hilarious Direction!",
    bulletTwo: "Inspiring nerdy Halloween costumes for at least 10 years.",
    cast: "Directed by TODD PHILLIPS starring JOAQUIN PHOENIX ROBERT DE NIRO ZAZIE BEETZ and FRANCES CONROY",
  },
  {
    id: 109,
    name: "Forrest Gump",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNWIwODRlZTUtY2U3ZS00Yzg1LWJhNzYtMmZiYmEyNmU1NjMzXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    year: 1994,
    synopsis:
      "A simple man unwittingly influences most major events from 1950 onward.",
    genre: "Drama",
    subgenre: "",
    runtime: "142 min",
    pairs: "a Leg Brace.",
    drinkRecipe: "Dr. Pepper and Rum.",
    rated: "PG-13",
    picture1: forrestGump1,
    picture2: forrestGump2,
    picture3: forrestGump3,
    tagline: "IT'LL BLOW YOUR LEGS OFF!",
    taglineWriter: "Lieutenant Dan",
    bulletOne: "Never going full retard.",
    bulletTwo: "Learning every conceivable variation on shrimp preparation.",
    cast: "Directed by ROBERT ZEMECKIS starring TOM HANKS ROBIN WRIGHT GARY SINISE and SALLY FIELD",
  },
  {
    id: 110,
    name: "Inception",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjAxMzY3NjcxNF5BMl5BanBnXkFtZTcwNTI5OTM0Mw@@._V1_SX300.jpg",
    rating: 4,
    year: 2010,
    synopsis:
      "Mind bending movie that lends credibility to the, 'if you die in a dream you die in real life' rumor.",
    genre: "Action",
    subgenre: "Scifi",
    runtime: "148 min",
    pairs: "Melatonin.",
    rated: "PG-13",
    picture1: inception1,
    picture2: inception2,
    picture3: inception3,
    tagline: "If you can sleep through it, you can do it!",
    taglineWriter: "Fault Dizny",
    bulletOne: "Becoming a term for tricking someone mentally.",
    bulletTwo: "Missing your wife's train.",
    cast: "Directed by CHRISTOPHER NOLAN starring LEONARDO DiCAPRIO JOSEPH GORDON-LEVITT ELLEN PAGE and KEN WATANABE",
  },
  {
    id: 111,
    name: "The Departed",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTI1MTY2OTIxNV5BMl5BanBnXkFtZTYwNjQ4NjY3._V1_SX300.jpg",
    rating: 5,
    year: 2006,
    synopsis: "A guy who's nawt a cawp infiltrates the Irish mawb.",
    genre: "Crime",
    subgenre: "Thriller",
    runtime: "151 min",
    pairs: "",
    noPairs:
      "Boston Lager - or a cranberry juice and vodka if you're on your period.",
    rated: "R",
    picture1: departed1,
    picture2: departed2,
    picture3: departed3,
    tagline: "I DON'T GET IT",
    taglineWriter: "The Retahded",
    bulletOne: "Marky Marks atrocious haircut.",
    bulletTwo: "Alec Baldwin's Boston accent. All the R's definitely depahted.",
    cast: "Directed by MARTIN SCORSESE starring LEONARDO DiCAPRIO MATT DAMON JACK NICHOLSON and MARK WAHLBERG",
  },
  {
    id: 112,
    name: "The Matrix",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzQzOTk3OTAtNDQ0Zi00ZTVkLWI0MTEtMDllZjNkYzNjNTc4L2ltYWdlXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 5,
    year: 1999,
    synopsis:
      "A pill-pushing scam artist drags a successful computer programmer into an unspeakably horrible world.",
    genre: "Action",
    subgenre: "Scifi",
    runtime: "136 min",
    pairs: "a Blue Pill.",
    drinkRecipe: "Malibu, Blue Curaçao.",
    rated: "R",
    picture1: matrix1,
    picture2: matrix2,
    picture3: matrix3,
    tagline: "THE FUTURE LOOKS HEAVY!",
    taglineWriter: "Marty McFly",
    bulletOne:
      "Talking shit on living a 'false reality' then entering simulations at every chance.",
    bulletTwo: "The oracle never making an accurate prediction, ever.",
    cast: "Directed by THE WACHOWSKIS starring KEANU REEVES LAURENCE FISHBURNE CARRIE-ANNE MOSS and HUGO WEAVING",
  },
  {
    id: 113,
    name: "One Flew Over The Cuckoo's Nest",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjA0OWVhOTAtYWQxNi00YzNhLWI4ZjYtNjFjZTEyYjJlNDVlL2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1975,
    synopsis:
      "A guy lands in a psych ward instead of jail and assumes it'll be smooth sailing - until a sadistic nurse throws a wrench in his plans. ",
    genre: "Drama",
    subgenre: "Comedy",
    runtime: "133 min",
    pairs: "a Mind Eraser.",
    drinkRecipe: "Coffee liquer, vodka, club soda.",
    rated: "R",
    picture1: cuckoo1,
    picture2: cuckoo3,
    picture3: cuckoo2,
    tagline: "Nicholson as McMurphy? He's too small!",
    taglineWriter: "The Leprachaun",
    bulletOne: "Hanging with a bunch of donkey-brained lunatics.",
    bulletTwo: "Seeing a young Frank Reynolds school some fools in basketball.",
    cast: "Directed by MILOS FORMAN starring JACK NICHOLSON LOUISE FLETCHER WILL SAMPSON and DANNY DeVITO",
  },
  {
    id: 114,
    name: "Zodiac",
    poster:
      "https://m.media-amazon.com/images/M/MV5BN2UwNDc5NmEtNjVjZS00OTI5LWE5YjctMWM3ZjBiZGYwMGI2XkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 4,
    year: 2007,
    synopsis:
      "Jayke Gyeyllynhyll, cartoonist and cipher extraordinaire, solves nothing in under three hours.",
    genre: "Crime",
    subgenre: "Mystery",
    runtime: "157 min",
    pairs: "Aqua Velva.",
    drinkRecipe: "Vodka, gin, lemon-lime, and Blue Curaçao.",
    rated: "R",
    picture1: zodiac1,
    picture2: zodiac2,
    picture3: zodiac3,
    tagline: "NOT ENOUGH BLOOD!",
    taglineWriter: "Siri Elchilar",
    bulletOne: "The most brutal stabbing on film",
    bulletTwo: "Mid-comeback RDJ spirals out of control",
    cast: "Directed by DAVID FINCHER starring JAKE GYLLENHAAL  ROBERT DOWNEY JR  MARK RUFFALO and BRIAN COX",
  },

  {
    id: 115,
    name: "E.T.",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQ2ODFlMDAtNzdhOC00ZDYzLWE3YTMtNDU4ZGFmZmJmYTczXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1982,
    synopsis:
      "An illegal alien freeloads, gets children drunk, and evades authorities before escaping during the cover of night.",
    genre: "Scifi",
    subgenre: "",
    runtime: "115 min",
    pairs: "",
    noPairs: "Coors, and some reese's pieces to munch on.",
    rated: "PG",
    picture1: et1,
    picture2: et2,
    picture3: et3,
    tagline: "OUT OF THIS WORLD!",
    taglineWriter: "ALF",
    bulletOne: "Providing inspiration for Mac & Me.",
    bulletTwo:
      "ET's complete inability to get a signal/make a call is relatable for anyone who's ever had Sprint.",
    cast: "Directed by STEVEN SPIELBERG starring HENRY THOMAS DREW BARRYMORE DEE WALLACE and ROBERT MACNAUGHTON",
  },
  {
    id: 116,
    name: "Mac and Me",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTllYjI2ZTktMWRlMS00ZWI4LWIzNmYtMWI2YmE3ZWU2ZDQ2XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1988,
    synopsis:
      "After getting LAUNCHED off a cliff and into a ravine, a wheelchair-bound boy is rescued by a freakish alien. ",
    genre: "Scifi",
    subgenre: "",
    runtime: "95 min",
    pairs: "",
    noPairs:
      "Coke on the rocks. With a drink this tasty, what else do you need? Ahhh.",
    rated: "PG",
    picture1: mac1,
    picture2: mac2,
    picture3: mac3,
    tagline: "The tale of an ant-sized superhero.",
    taglineWriter: "Paul Rudd",
    bulletOne:
      "An extraterrestrial that puts the 'ET' in Fetal Alcohol Syndrome.",
    bulletTwo:
      "Bizarre dance sequences. Basically a feature length advertisement for Coca-Cola.",
    cast: "Directed by STEWART RAFFILL starring JONATHAN WARD CHRISTINE EBERSOLE JADE CALEGORY and TINA CASPARY",
  },
  {
    id: 117,
    name: "Goodfellas",
    poster:
      "https://m.media-amazon.com/images/M/MV5BY2NkZjEzMDgtN2RjYy00YzM1LWI4ZmQtMjIwYjFjNmI3ZGEwXkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 5,
    year: 1990,
    synopsis: "A couple'a badfellas murder people for the mafia.",
    genre: "Crime",
    subgenre: "",
    runtime: "146 min",
    pairs: "Paulie's pruno.",
    drinkRecipe: "Toilet wine.",
    rated: "R",
    picture1: goodfellas1,
    picture2: goodfellas2,
    picture3: goodfellas3,
    tagline: "SAVE YOUR STUB IN A BOX!",
    taglineWriter: "Spitshine Tommy",
    bulletOne: "Finding out what's so funny about Pesci.",
    bulletTwo: "Shooting someone for taking too long to grab drinks.",
    cast: "Directed by MARTIN SCORSESE starring RAY LIOTTA ROBERT DE NIRO JOE PESCI and LORRAINE BRACCO ",
  },
  {
    id: 118,
    name: "Hard Times",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDQ5MTk5OTQtMWRmYy00ZGVjLThlYjItYmMwOTU3ZjI4MjEzXkEyXkFqcGdeQXVyMTMxMTY0OTQ@._V1_SX300.jpg",
    rating: 4,
    year: 1975,
    synopsis:
      "Bronson's fists do the talking in this flick. Seriously -- the guy says maybe 12 words in the whole movie.",
    genre: "Action",
    subgenre: "",
    runtime: "93 min",
    pairs: "",
    noPairs:
      "straight vodka. Need to keep the cals low to achieve Bronson's lean physique.",
    rated: "PG",
    picture1: hardTimes1,
    picture2: hardTimes2,
    picture3: hardTimes3,
    tagline: "HAYMAKERS WILL RING YOUR BELL!",
    taglineWriter: "Anita Ward",
    bulletOne: "Appearance from badass Robert Tessier.",
    bulletTwo: "Bronson probably manhandling the actors in real life.",
    cast: "Directed by WALTER HILL starring CHARLES BRONSON  JAMES COBURN  JILL IRELAND and ROBERT TESSIER",
  },
  {
    id: 119,
    name: "Taxi Driver",
    poster:
      "https://m.media-amazon.com/images/M/MV5BM2M1MmVhNDgtNmI0YS00ZDNmLTkyNjctNTJiYTQ2N2NmYzc2XkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 6,
    year: 1976,
    synopsis:
      "A skinny psycho drives cabs and considers life's most pressing existential questions: 'Does anything matter?' , 'What is my greater purpose?', and 'Are you talkin' to me?'.",
    genre: "Crime",
    subgenre: "",
    runtime: "114 min",
    pairs: "a Bizarre Breakfast.",
    drinkRecipe: "peach schnapps with white bread and sugar.",
    rated: "R",
    picture1: taxi1,
    picture2: taxi2,
    picture3: taxi3,
    tagline: "A wild ride! I'm glad he's not MY driver!",
    taglineWriter: "Princess Die",
    bulletOne:
      "Sax soundtrack that'll make everyone think you're trying to get laid.",
    bulletTwo:
      "Asking your friend for advice and then telling him it's the dumbest thing you ever heard.",
    cast: "Directed by MARTIN SCORSESE starring ROBERT DE NIRO JODIE FOSTER CYBILL SHEPHERD and PETER BOYLE",
  },
  {
    id: 120,
    name: "An America Werewolf in London",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGNmYWQzMGEtNDlhMS00NzEwLTkzMDItMDQ4MjkyMzRkNjFiXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    year: 1981,
    synopsis:
      "The title says it all: Americans, werewolves, London. An instant classic horror flick with fantastic special effects.",
    genre: "Horror",
    subgenre: "Cult Classic",
    runtime: "97 min",
    pairs: "",
    noPairs: "Beefeater London Dry Gin. ",
    rated: "R",
    picture1: werewolf1,
    picture2: werewolf2,
    picture3: werewolf3,
    tagline: "A naked man goes wild at night? I'm in!",
    taglineWriter: "Elton John",
    bulletOne: "Pulling off the fabled 'fake cry and kiss'.",
    bulletTwo:
      "Refusing to do the right thing at the detriment to everyone else.",
    cast: "Directed by JOHN LANDIS starring DAVID NAUGHTON JENNY AGUTTER GRIFFIN DUNNE and JOHN WOODVINE",
  },
  {
    id: 121,
    name: "Seven",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTUwODM5MTctZjczMi00OTk4LTg3NWUtNmVhMTAzNTNjYjcyXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 5,
    year: 1995,
    synopsis:
      "A man gets seven guesses about the contents of a box. If he can't get it, it's his wife's head.",
    genre: "Crime",
    subgenre: "",
    runtime: "127 min",
    pairs: "seven and seven.",
    drinkRecipe: "Seagram's whiskey and seven up.",
    rated: "R",
    picture1: seven1,
    picture2: seven2,
    picture3: seven3,
    tagline: "STOP ASKING AND JUST OPEN IT",
    taglineWriter: "Pandora",
    bulletOne:
      "Using thousands of little tree air fresheners to mask the mess you're too lazy to clean.",
    bulletTwo:
      "If 'every god damn scene being too dark' isn't a mortal sin, it should be.",
    cast: "Directed by DAVID FINCHER starring MORGAN FREEMAN BRAD PITT KEVIN SPACEY and GWYNETH PALTROW",
  },
  {
    id: 122,
    name: "City of God",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTMwYjc5ZmItYTFjZC00ZGQ3LTlkNTMtMjZiNTZlMWQzNzI5XkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 3,
    year: 2002,
    synopsis: "",
    genre: "Crime",
    subgenre: "",
    runtime: "130 min",
    pairs: "",
    noPairs: "Caipirinha.",
    rated: "R",
    picture1: cityOfGod1,
    picture2: cityOfGod2,
    picture3: cityOfGod3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "This movie stink",
    bulletTwo: "",
    cast: "Directed by FERNANDO MEIRELLES starring ALEXANDRE RODRIGUES LEANDRO FIRMINO DOUGLAS SILVA and SEU JORGE",
  },
  {
    id: 123,
    name: "The Silence of the Lambs",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjNhZTk0ZmEtNjJhMi00YzFlLWE1MmEtYzM1M2ZmMGMwMTU4XkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 6,
    year: 1991,
    synopsis:
      "A tale of two cunts: A murderer who wants one and a forensic psychiatrist who can't smell one. ",
    genre: "Crime",
    subgenre: "Thriller",
    runtime: "118 min",
    pairs: "",
    noPairs: "Fava beans and a nice Chianti.",
    rated: "R",
    picture1: silenceLambs1,
    picture2: silenceLambs2,
    picture3: silenceLambs3,
    tagline: "YOU'LL CRAWL OUT OF YOUR SKIN!",
    taglineWriter: "A great big fat person",
    bulletOne: "Forget the Dougie; learn how to Tuckie.",
    bulletTwo:
      "Being urbane and sophisticated yet failing to learn the proper pronunciation of a common wine.",
    cast: "Directed by JONATHAN DEMME starring ANTHONY HOPKINS JODIE FOSTER ANTHONY HEALDE and SCOTT GLENN",
  },
  {
    id: 124,
    name: "Hannibal",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDMxMjhiZmItNWMxMC00NzYyLWJiOTYtNGYwOTAyYjU5OWY4XkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 4,
    year: 2001,
    synopsis:
      "A hideously disfigured freak aims to get revenge on everyone's favorite cannibal. ",
    genre: "Crime",
    subgenre: "Thriller",
    runtime: "131 min",
    pairs: "",
    noPairs: "Riesling.",
    rated: "R",
    picture1: hannibal1,
    picture2: hannibal2,
    picture3: hannibal3,
    tagline: "Exciting! You won't be boared.",
    taglineWriter: "Mason Verger",
    bulletOne: "Generously letting a child try human brains. ",
    bulletTwo:
      "Making a man disfigure his own face, and then mocking him relentlessly for it. ",
    cast: "Directed by RIDLEY SCOTT starring ANTHONY HOPKINS JULIANNE MOORE GARY OLDMAN and RAY LIOTTA",
  },
  {
    id: 125,
    name: "Red Dragon",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQ4MDgzNjM5MF5BMl5BanBnXkFtZTYwMjUwMzY2._V1_SX300.jpg",
    rating: 5,
    year: 2002,
    synopsis:
      "Hannibal helps pursue a serial killer with grandmommy issues and a jacked up lip.",
    genre: "Crime",
    subgenre: "Thriller",
    runtime: "124 min",
    pairs: "",
    noPairs: "Apothic Red.",
    rated: "R",
    picture1: redDragon1,
    picture2: redDragon2,
    picture3: redDragon3,
    tagline: "THANKS FOR RUINING MY REPUTATION",
    taglineWriter: "Tooth Fairy",
    bulletOne: "Insulting your friends on their atrocious after shave.",
    bulletTwo:
      "Getting strange looks when you bench at the gym with a sack covering your face.",
    cast: "Directed by BRETT RATNER starring ANTHONY HOPKINS EDWARD NORTON RALPH FIENNES and HARVEY KEITEL",
  },
  {
    id: 126,
    name: "Saving Private Ryan",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjhkMDM4MWItZTVjOC00ZDRhLThmYTAtM2I5NzBmNmNlMzI1XkEyXkFqcGdeQXVyNDYyMDk5MTU@._V1_SX300.jpg",
    rating: 6,
    year: 1998,
    synopsis:
      "A team of handpicked soldiers head deep into Nazi territory to save the last surviving son in the Ryan family.",
    genre: "War",
    subgenre: "Action",
    runtime: "169 min",
    pairs: "a Front Line.",
    drinkRecipe: "whiskey from a canteen.",
    rated: "R",
    picture1: privateRyan1,
    picture2: privateRyan2,
    picture3: privateRyan3,
    tagline: "IT'LL STORM YOUR BEACH!",
    taglineWriter: "Norman Cota",
    bulletOne: "Will Matt Damon ever not need saving?",
    bulletTwo:
      "Most action-packed and realistic opening scene in any war movie.",
    cast: "Directed by STEVEN SPIELBERG starring TOM HANKS TOM SIZEMORE BARRY PEPPER and EDWARD BURNS",
  },
  {
    id: 127,
    name: "Spirited Away",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjlmZmI5MDctNDE2YS00YWE0LWE5ZWItZDBhYWQ0NTcxNWRhXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    rating: 4,
    year: 2001,
    synopsis:
      "A young girl has a drug-fueled hallucination of witches, demons, and pigs.",
    genre: "Animation",
    subgenre: "",
    runtime: "125 min",
    pairs: "",
    noPairs: "any spirit - just don't get carried away.",
    rated: "PG",
    picture1: spiritedAway1,
    picture2: spiritedAway2,
    picture3: spiritedAway3,
    tagline: "I don't like noisy ghosts",
    taglineWriter: "Poltergeist",
    bulletOne: "Seeing a PIG MAN!",
    bulletTwo:
      "No-face ghost sounding like its about to cum for the entire movie.",
    cast: "Directed by HIYAO MIYAZAKI starring DAVEIGH CHASE MIYU IRUNO RUMI HIIRAGI and SUZANNE PLESHETTE",
  },
  {
    id: 128,
    name: "The Green Mile",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTUxMzQyNjA5MF5BMl5BanBnXkFtZTYwOTU2NTY3._V1_SX300.jpg",
    rating: 6,
    year: 1999,
    synopsis: "A monster tard heals people by sucking flies out of their face.",
    genre: "Drama",
    subgenre: "Crime",
    runtime: "189 min",
    pairs: "a large Black Russian.",
    drinkRecipe: "Vodka, Kahlúa.",
    rated: "R",
    picture1: greenMile1,
    picture2: greenMile2,
    picture3: greenMile3,
    tagline: "ELECTRIFYING!",
    taglineWriter: "John Coffey",
    bulletOne: "Yet another movie where Tom Hanks pees.",
    bulletTwo: "One of the few movies where dudes get a pass for crying.",
    cast: "Directed by FRANK DARABONT starring TOM HANKS MICHAEL CLARKE DUNCAN DAVID MORSE and SAM ROCKWELL",
  },
  {
    id: 129,
    name: "The Professional",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODllNWE0MmEtYjUwZi00ZjY3LThmNmQtZjZlMjI2YTZjYmQ0XkEyXkFqcGdeQXVyNTc1NTQxODI@._V1_SX300.jpg",
    rating: 6,
    year: 1994,
    synopsis:
      "A milk-guzzling assassin becomes an unlikely guardian to princess padmé.",
    genre: "Action",
    subgenre: "Crime",
    runtime: "110 min",
    pairs: "a White Russian.",
    drinkRecipe: "Vodka, Kahlúa, cream.",
    rated: "R",
    picture1: professional1,
    picture2: professional2,
    picture3: professional3,
    tagline: "UDDERLY BRILLIANT",
    taglineWriter: "Louis Pasteur",
    bulletOne: "Highly recommended by Ryan and Liam McPoyle.",
    bulletTwo: "Taking debt collection to extreme levels. ",
    cast: "Directed by LUC BESSON starring JEAN RENO NATALIE PORTMAN GARY OLDMAN and DANNY AIELLO",
  },
  {
    id: 130,
    name: "Reservoir Dogs",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZmExNmEwYWItYmQzOS00YjA5LTk2MjktZjEyZDE1Y2QxNjA1XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    year: 1992,
    synopsis:
      "A bank job goes wrong, chaos ensues. Part whodunnit, part whoisit, this movie has all the killer dialogue and action you'd expect from Tarantino. Spoiler alert: there's not a single dog OR reservoir in the whole movie.",
    genre: "Crime",
    subgenre: "Action",
    runtime: "99 min",
    pairs: "",
    noPairs: "a drink at the bar with NO TIP.",
    rated: "R",
    picture1: reservoir1,
    picture2: reservoir2,
    picture3: reservoir3,
    tagline: "Feels like being touched for the very first time",
    taglineWriter: "Marsellus Wallace",
    bulletOne: "Lawrence Tierney's reason for naming Buscemi 'Mr. Pink'.",
    bulletTwo:
      "Envisioning a gruesome mutilation every time 'Stuck in the Middle with You' is played.",
    cast: "Directed by QUENTIN TARANTINO starring HARVEY KEITEL MICHAEL MADSEN TIM ROTH and CHRIS PENN",
  },
  {
    id: 131,
    name: "Interstellar",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjdkOTU3MDktN2IxOS00OGEyLWFmMjktY2FiMmZkNWIyODZiXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    rating: 4,
    year: 2014,
    synopsis: "A young father helps his daughter with her math homework.",
    genre: "Scifi",
    subgenre: "Adventure",
    runtime: "169 min",
    pairs: "a Stormdust.",
    drinkRecipe: "Corn Liquor, Baileys, Cinnamon.",
    rated: "PG-13",
    picture1: interstellar1,
    picture2: interstellar2,
    picture3: interstellar3,
    tagline: "I woulda surfed that wave all the way home",
    taglineWriter: "Snake Plissken",
    bulletOne: "Keeping an organized library proves difficult.",
    bulletTwo: "An all corn diet makes people understandably violent.",
    cast: "Directed by CHRISTOPHER NOLAN starring MATTHEW MCCONAUGHEY ANNE HATHAWAY JESSICA CHASTAIN and MACKENZIE FOY",
  },
  {
    id: 132,
    name: "The Usual Suspects",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTViNjMyNmUtNDFkNC00ZDRlLThmMDUtZDU2YWE4NGI2ZjVmXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 6,
    year: 1995,
    synopsis:
      "A bunch of criminals try to figure out who the hell Keyser Soze is. ",
    genre: "Crime",
    subgenre: "Mystery",
    runtime: "106 min",
    pairs: "",
    noPairs: "an Irish Coffee (don't drop it).",
    rated: "R",
    picture1: usualSuspects1,
    picture2: usualSuspects2,
    picture3: usualSuspects3,
    tagline: "WHAT A TWIST!",
    taglineWriter: "M. Night Shyamalan",
    bulletOne: "Addressing people by their physical handicap.",
    bulletTwo: "Being a wolf in sheeps clothes.",
    cast: "Directed by BRYAN SINGER starring KEVIN SPACEY GABRIEL BYRNE CHAZZ PALMINTERI and STEPHEN BALDWIN",
  },
  {
    id: 133,
    name: "The Hunted",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTI0MjI4ODg2NV5BMl5BanBnXkFtZTcwMzI0NjgyMQ@@._V1_SX300.jpg",
    rating: 5,
    year: 2003,
    synopsis:
      "A spec-ops assassin with PTSD starts slaughtering fools left and right (we mean slaughter in the literal sense - dude is a knife fighting wizard).",
    genre: "Action",
    subgenre: "War",
    runtime: "94 min",
    pairs: "a Black Ops.",
    drinkRecipe: "Jager, Coke.",
    rated: "R",
    picture1: hunted1,
    picture2: hunted2,
    picture3: hunted3,
    tagline: "CUTS YOU DEEP!",
    taglineWriter: "Caesar",
    bulletOne: "Great fight and chase sequences.",
    bulletTwo: "Underrated. One of the coolest war/action movies out there.",
    cast: "Directed by WILLIAM FRIEDKIN starring TOMMY LEE JONES BENICIO DEL TORO CONNIE NIELSEN and LESLIE STEFANSON",
  },
  {
    id: 134,
    name: "The Lion King",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTYxNGMyZTYtMjE3MS00MzNjLWFjNmYtMDk3N2FmM2JiM2M1XkEyXkFqcGdeQXVyNjY5NDU4NzI@._V1_SX300.jpg",
    rating: 5,
    year: 1994,
    synopsis:
      "The son of a loser gets lost in the jungle and tries to usurp the crown from his uncle.",
    genre: "Animation",
    subgenre: "",
    runtime: "88 min",
    pairs: "",
    noPairs: "Lion's Head while playing Kings.",
    rated: "G",
    picture1: lionKing1,
    picture2: lionKing2,
    picture3: lionKing3,
    tagline: "The Dyin' King",
    taglineWriter: "Scar",
    bulletOne: "Hilarious stampede scene.",
    bulletTwo: "Tripping balls and seeing your dad in the stars.",
    cast: "Directed by ROGER ALLERS starring MATTHEW BRODERICK JEREMY IRONS JAMES EARL JONES and NATHAN LANE",
  },
  {
    id: 135,
    name: "American History X",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjA0MTM4MTQtNzY5MC00NzY3LWI1ZTgtYzcxMjkyMzU4MDZiXkEyXkFqcGdeQXVyNDYyMDk5MTU@._V1_SX300.jpg",
    rating: 6,
    year: 1998,
    synopsis: "A skinhead learns that racism is stupid.",
    genre: "Drama",
    subgenre: "Crime",
    runtime: "119 min",
    pairs: "",
    noPairs: "an American IPA",
    rated: "R",
    picture1: ahx1,
    picture2: ahx2,
    picture3: ahx3,
    tagline: "AN EMOTIONAL CURB STOMP",
    taglineWriter: "BlackKklansman",
    bulletOne:
      "Blowing smoke in someones face is just plain rude. If you do it, you deserve whatever you get.",
    bulletTwo: "Making friends in the least likely place.",
    cast: "Directed by TONY KAYE starring EDWARD NORTON EDWARD FURLONG BEVERLY D'ANGELO and GUY TORRY",
  },
  {
    id: 136,
    name: "The Pianist",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOWRiZDIxZjktMTA1NC00MDQ2LWEzMjUtMTliZmY3NjQ3ODJiXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 4,
    year: 2002,
    synopsis: "A Jewish musician struggles to survive as Nazi's invade Poland.",
    genre: "Drama",
    subgenre: "",
    runtime: "150 min",
    pairs: "",
    noPairs: "Vodka & tears. L'Chaim",
    rated: "R",
    picture1: pianist1,
    picture2: pianist2,
    picture3: pianist3,
    tagline: "THIS MUSIC IS SHEET!",
    taglineWriter: "Chopin",
    bulletOne: "Adrien Brody gets his ass kicked for nearly 3 hours.",
    bulletTwo: "The movie is depressing but the piano playing is excellent.",
    cast: "Directed by ROMAN POLANSKI starring ADRIEN BRODY THOMAS KRETSCHMANN EMILIA FOX and MICHAL ZEBROWSKI",
  },
  {
    id: 137,
    name: "Schindler's List",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDE4OTMxMTctNmRhYy00NWE2LTg3YzItYTk3M2UwOTU5Njg4XkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 4,
    year: 1993,
    synopsis: "A German businessman saves thousands of jews from the Nazi's.",
    genre: "Drama",
    subgenre: "",
    runtime: "195 min",
    pairs: "",
    noPairs: "schnapps.",
    rated: "R",
    picture1: schindler1,
    picture2: schindler2,
    picture3: schindler3,
    tagline: "THIS GUY REALLY KNOWS HOW TO HIDE!",
    taglineWriter: "Anne Frank",
    bulletOne:
      "Please don't make out during this movie, it's just disrespectful.",
    bulletTwo: "Helping others even at great personal risk.",
    cast: "Directed by STEVEN SPIELBERG starring LIAM NEESON RALPH FIENNES BEN KINGSLEY and CAROLINE GOODALL",
  },
  {
    id: 138,
    name: "The Terminator",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTViNzMxZjEtZGEwNy00MDNiLWIzNGQtZDY2MjQ1OWViZjFmXkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 6,
    year: 1984,
    synopsis: "A cyborg is sent back in time to cockblock a soldier.",
    genre: "Action",
    subgenre: "Scifi",
    runtime: "107 min",
    pairs: "a Laser Sight.",
    drinkRecipe: "Fruit punch, Red Bull, Vodka.",
    rated: "R",
    picture1: theTerminator1,
    picture2: theTerminator2,
    picture3: theTerminator3,
    tagline: "IT HAD ME BURNING IN THE THIRD DEGREE!",
    taglineWriter: "T-1000",
    bulletOne: "What kinda slob lets an iguana freely roam?",
    bulletTwo:
      "Although cool in a movie, 'Come with me if you want to live' rarely works as a real-life pickup line.",
    cast: "Directed by JAMES CAMERON starring ARNOLD SCHWARZENEGGER LINDA HAMILTON MICHAEL BIEHN and PAUL WINFIELD",
  },
  {
    id: 139,
    name: "Terminator 2: Judgment Day",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGU2NzRmZjUtOGUxYS00ZjdjLWEwZWItY2NlM2JhNjkxNTFmXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 6,
    year: 1991,
    synopsis:
      "Arnie battles an advanced cyborg in this sequel that's arguably better than the first. ",
    genre: "Action",
    subgenre: "Scifi",
    runtime: "137 min",
    pairs: "Peppermint Patty shot.",
    drinkRecipe: "Ice 101 and Hershey's Syrup.",
    rated: "R",
    picture1: theTerminator21,
    picture2: theTerminator22,
    picture3: theTerminator23,
    tagline: "A man of his word. He came back.",
    taglineWriter: "Officer Wright",
    bulletOne: "All-time great special effects/CGI.",
    bulletTwo: "Sensibly trying to destroy Skynet. ",
    cast: "Directed by JAMES CAMERON starring ARNOLD SCHWARZENEGGER LINDA HAMILTON EDWARD FURLONG and ROBERT PATRICK",
  },
  {
    id: 140,
    name: "Terminator 3: Rise of the Machines",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTk5NzM1ODgyN15BMl5BanBnXkFtZTcwMzA5MjAzMw@@._V1_SX300.jpg",
    rating: 4,
    year: 2003,
    synopsis:
      "This third installment of the terminator series is decent but nowhere near 1 and 2. It really didn't help that John Connor was an emaciated wimp.",
    genre: "Action",
    subgenre: "Scifi",
    runtime: "109 min",
    pairs: "a Three Horsemen shot.",
    drinkRecipe: "Jack Daniels, Jim Beam, Johnny Walker.",
    rated: "R",
    picture1: theTerminator31,
    picture2: theTerminator32,
    picture3: theTerminator33,
    tagline: "Machines aren't the only thing rising",
    taglineWriter: "Robo-boners.org",
    bulletOne: "Hot robot.",
    bulletTwo:
      "If someone tells you to watch this one, respond with 'talk to the hand'.",
    cast: "Directed by JONATHAN MOSTOW starring ARNOLD SCHWARZENEGGER NICK STAHL KRISTANNA LOKEN and CLAIRE DANES",
  },
  {
    id: 141,
    name: "Gladiator",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDliMmNhNDEtODUyOS00MjNlLTgxODEtN2U3NzIxMGVkZTA1L2ltYWdlXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 6,
    year: 2000,
    synopsis:
      "A badass general battles his way to freedom after being forced into slavery by a patricidal weasel. ",
    genre: "Action",
    subgenre: "",
    runtime: "155 min",
    pairs: "",
    noPairs: "a chalice of wine.",
    rated: "R",
    picture1: gladiator1,
    picture2: gladiator2,
    picture3: gladiator3,
    tagline: "Hairlip Phoenix is a gladi-hater!",
    taglineWriter: "Marcus Aurelius",
    bulletOne: "Last movie of legendary Oliver Reed.",
    bulletTwo: "Killing man and beast alike in pursuit of revenge.",
    cast: "Directed by RIDLEY SCOTT starring RUSSELL CROWE OLIVER REED DJIMON HOUNSOU and JOAQUIN PHOENIX",
  },
  {
    id: 142,
    name: "Whiplash",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTA5NDZlZGUtMjAxOS00YTRkLTkwYmMtYWQ0NWEwZDZiNjEzXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    rating: 4,
    year: 2014,
    synopsis:
      "A passionate teacher takes a mediocre drummer under his wing and makes him great. How does the little scamp show thanks? By whining the whole time.",
    genre: "Drama",
    subgenre: "",
    runtime: "106 min",
    pairs: "a Crybaby.",
    drinkRecipe: "Vodka, Blue Gatorade.",
    rated: "R",
    picture1: whiplash1,
    picture2: whiplash2,
    picture3: whiplash3,
    tagline: "I changed my mind - I'd rather work",
    taglineWriter: "Todd Rundgren",
    bulletOne: "JK is NOT kidding. ",
    bulletTwo:
      "Epic lines like 'you give a calculator to a fuckin' retard he's gonna try to turn on a TV with it'. ",
    cast: "Directed by DAMIEN CHAZELLE starring MILES TELLER JK SIMMONS MELISSA BENOIST and PAUL REISER",
  },
  {
    id: 143,
    name: "Road House",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTU1MTE2Mjk2OF5BMl5BanBnXkFtZTcwMTUzNjYzNA@@._V1_SX300.jpg",
    rating: 6,
    year: 1989,
    synopsis:
      "World-Famous bouncer (is that Dalton?!) kicks the everloving shit out of ruffians at the Double Deuce -- when he isn't performing lubed up Tai Chi.  ",
    genre: "Action",
    subgenre: "",
    runtime: "114 min",
    pairs: "a Spare Tire.",
    drinkRecipe: "Kahlúa and Kraken.",
    rated: "R",
    picture1: roadHouse1,
    picture2: roadHouse2,
    picture3: roadHouse3,
    tagline: "Pain don't hurt but knife wounds squirt",
    taglineWriter: "Willie Layer",
    bulletOne: "Throat ripping.",
    bulletTwo: "Strip-mall karate dojo philosophy.",
    cast: "Directed by ROWDY HERRINGTON starring PATRICK SWAYZE  KELLY LYNCH  SAM ELLIOTT and BEN GAZZARA",
  },
  {
    id: 144,
    name: "The Greatest Showman",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjI1NDYzNzY2Ml5BMl5BanBnXkFtZTgwODQwODczNTM@._V1_SX300.jpg",
    rating: 4,
    year: 2017,
    synopsis:
      "The completely untrue telling of P.T. Barnum’s questionable beginnings in showbiz.",
    genre: "Drama",
    subgenre: "Musical",
    runtime: "105 min",
    pairs: "The Gayest Blowman.",
    drinkRecipe: "seven shots of bourbon.",
    rated: "PG",
    picture1: greatestShowman1,
    picture2: greatestShowman2,
    picture3: greatestShowman3,
    tagline: "The ONLY movie I don't hate!",
    taglineWriter: "Johnny Burke",
    bulletOne: "Perfect choreography while drunk.",
    bulletTwo: "Fire soundtrack.",
    cast: "Directed by MICHAEL GRACY starring HUGH JACKMAN MICHELLE WILLIAMS ZAC EFRON and ZENDAYA",
  },
  {
    id: 145,
    name: "Gangs of New York",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDg3MmI1ZDYtMDZjYi00ZWRlLTk4NzEtZjY4Y2U0NjE5YmRiXkEyXkFqcGdeQXVyNzAxMjE1NDg@._V1_SX300.jpg",
    rating: 6,
    year: 2002,
    synopsis:
      "A duplicitous snake tries to kill the man who took him in and gave him a job. Sure, the guy killed his dad, but you don't shoot the devil in the back. ",
    genre: "Crime",
    subgenre: "Action",
    runtime: "167 min",
    pairs: "",
    noPairs: "a beer bong of 'all sorts'. ",
    rated: "R",
    picture1: gangsOfNY1,
    picture2: gangsOfNY3,
    picture3: gangsOfNY2,
    tagline: "GRABS YOU LIKE A FISH HOOK",
    taglineWriter: "McGloin",
    bulletOne:
      "Full of old-timey insults you can use on your friends (Fidlam Ben, chiseler, crusty bitch). ",
    bulletTwo:
      "Everyone fights everyone - including rival firefighting brigades.",
    cast: "Directed by MARTIN SCORSESE starring LEONARDO DiCAPRIO CAMERON DIAZ DANIEL DAY LEWIS and BRENDAN GLEESON",
  },
  {
    id: 146,
    name: "The Football Factory",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDUzYjVlNDktN2RmNS00NmE5LTkxMmUtNTc3NjIzNDdmZjY5XkEyXkFqcGdeQXVyNTMwNDQzODA@._V1_SX300.jpg",
    rating: 6,
    year: 2004,
    synopsis:
      "Awesome soccer hooligan movie, but be warned: the action scenes are as incredible as the accents are indecipherable.",
    genre: "Crime",
    subgenre: "Sports",
    runtime: "91 min",
    pairs: "",
    noPairs: "plenty of pints at your local boozer.",
    rated: "R",
    picture1: footballFactory1,
    picture2: footballFactory2,
    picture3: footballFactory3,
    tagline: "Eez blokes is proppa 'ard geezers!",
    taglineWriter: "Michael Caine",
    bulletOne: "The 'hold out your hand' scene.",
    bulletTwo: "Calling people 'litt'l streaks o' piss'.",
    cast: "Directed by NICK LOVE starring DANNY DYER FRANK HARPER TAMER HASSAN and NEIL MASKELL",
  },
  {
    id: 147,
    name: "Green Street Hooligans",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjExNTg3NzYwMV5BMl5BanBnXkFtZTcwMzcxMTEzMQ@@._V1_SX300.jpg",
    rating: 5,
    year: 2005,
    synopsis:
      "Frodo moves to England and joins a soccer gang. This is basically the Hollywood version of The Football Factory.",
    genre: "Crime",
    subgenre: "Sports",
    runtime: "109 min",
    pairs: "",
    noPairs: "any and all pub ales.",
    rated: "R",
    picture1: greenStreetHooligans1,
    picture2: greenStreetHooligans2,
    picture3: greenStreetHooligans3,
    tagline: "Soccer still sucks, but this was cool",
    taglineWriter: "America",
    bulletOne: "Denim + boots = instant tuffy.",
    bulletTwo:
      "Hunnams accent. It's like melting every British accent into a pot and then pouring it over Australia via South Africa.",
    cast: "Directed by LEXI ALEXANDER starring ELIJAH WOOD CHARLIE HUNNAM LEO GREGORY and GEOFF BELL",
  },
  {
    id: 148,
    name: "The Prestige",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjA4NDI0MTIxNF5BMl5BanBnXkFtZTYwNTM0MzY2._V1_SX300.jpg",
    rating: 5,
    year: 2006,
    synopsis:
      "Two magicians in a protracted pissing contest constantly try to outdo one another.",
    genre: "Thriller",
    subgenre: "",
    runtime: "130 min",
    pairs: "",
    noPairs: "Magic Hat no.9",
    rated: "PG-13",
    picture1: prestige1,
    picture2: prestige2,
    picture3: prestige3,
    tagline: "THE MAGIC TRICKS WERE WAND-ERFUL!",
    taglineWriter: "Harry Potter",
    bulletOne: "Basically Batman vs Wolverine. ",
    bulletTwo:
      "First of many times in his career that Hugh Jackman drowns in a vat of water.",
    cast: "Directed by CHRISTOPHER NOLAN starring CHRISTIAN BALE HUGH JACKMAN SCARLETT JOHANSSON and MICHAEL CAINE",
  },
  {
    id: 149,
    name: "Alien",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOGQzZTBjMjQtOTVmMS00NGE5LWEyYmMtOGQ1ZGZjNmRkYjFhXkEyXkFqcGdeQXVyMjUzOTY1NTc@._V1_SX300.jpg",
    rating: 5,
    year: 1979,
    synopsis: "",
    genre: "Scifi",
    subgenre: "",
    runtime: "117 min",
    pairs: "",
    rated: "R",
    picture1: alien1,
    picture2: alien2,
    picture3: alien3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by RIDLEY SCOTT starring SIGOURNEY WEAVER TOM SKERRITT JOHN HURT and VERONICA CARTWRIGHT ",
  },
  {
    id: 151,
    name: "Memento",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTcyNjk1MjgtOWI3Mi00YzQwLWI5MTktMzY4ZmI2NDAyNzYzXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 3,
    year: 2000,
    synopsis:
      "A Guy struggles to find his wife’s murderer while battling a serious bout of amnesia.",
    genre: "Thriller",
    subgenre: "Mystery",
    runtime: "113 min",
    pairs: "a Mind Eraser.",
    drinkRecipe: "Coffee liquer, vodka, club soda.",
    rated: "R",
    picture1: memento1,
    picture2: memento2,
    picture3: memento3,
    tagline: "Is this a good movie? I can't seem to recall.",
    taglineWriter: "Dementia Digest",
    bulletOne: "It's ok if you forget this movie, it's not great.",
    bulletTwo:
      "There is a cult following who claim it is the greatest thing Pearce has ever done (uh, hello “Factory Girl” anyone?).",
    cast: "Directed by CHRISTOPHER NOLAN starring GUY PEARCE CARRIE-ANNE MOSS JOE PANTOLIANO MARK BOONE JUNIOR",
  },
  {
    id: 152,
    name: "Caddyshack",
    poster:
      "https://m.media-amazon.com/images/M/MV5BY2I1NWE2NzctNzNkYS00Nzg5LWEwZTQtN2I3Nzk3MTQwMDY2XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    year: 1980,
    synopsis:
      "The uptight country club world is challenged by nouveau riche real estate mogul Al Czervik (Dangerfield). Determined to break all club rules, a clash of the classes boils over into a match worth $40,000.",
    genre: "Comedy",
    subgenre: "",
    runtime: "98 min",
    pairs: "a CaddyShake",
    drinkRecipe: "Milkshake + bourbon.",
    rated: "R",
    picture1: caddyshack1,
    picture2: caddyshack2,
    picture3: caddyshack3,
    tagline: "Good as a hole in one ... or one in a hole",
    taglineWriter: "John Daly",
    bulletOne: "Getting head from Amelia Earheart.",
    bulletTwo:
      "Caddyshack: The Making Of A Hollywood Cinderella Story by Chris Nashawaty.",
    cast: "Directed by HAROLD RAMIS starring CHEVY CHASE BILL MURRAY RODNEY DANGERFIELD and TED KNIGHT",
  },
  {
    id: 153,
    name: "Back to School",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTYxMTIyOTM1MF5BMl5BanBnXkFtZTcwNzQ0MTcyNA@@._V1_SX300.jpg",
    rating: 5,
    year: 1986,
    synopsis:
      "Worlds coolest dad enrolls in college to help his sniveling little whiner of a son. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "98 min",
    pairs: "a Triple Lindy.",
    drinkRecipe: "Malibu, Captain, Pina Colada mix.",
    rated: "PG-13",
    picture1: backToSchool1,
    picture2: backToSchool2,
    picture3: backToSchool3,
    tagline: "Leeching off your dad rules!",
    taglineWriter: "Jaden Smith",
    bulletOne: "Getting the actual author to do your book report.",
    bulletTwo:
      "RDJ, Sam Kinison, Burt Young, and Billy Zabka turn in awesome cameos.",
    cast: "Directed by ALAN METTER starring RODNEY DANGERFIELD SALLY KELLERMAN KEITH GORDON and BURT YOUNG",
  },
  {
    id: 154,
    name: "Encino Man",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjc5OGIzYTUtYjFkYy00NDcxLTgwMzUtNzRlMTJmZjZkZDQ4L2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyNjE5MjUyOTM@._V1_SX300.jpg",
    rating: 5,
    year: 1992,
    synopsis:
      "Brandon Fascer plays a frozen caveman who wakes up in the early 90’s, what a time to be alive.",
    genre: "Comedy",
    subgenre: "Scifi",
    runtime: "88 min",
    pairs: "Bro Magnon Man.",
    drinkRecipe: "Vodka and Slurpee.",
    rated: "PG",
    picture1: encinoMan1,
    picture2: encinoMan2,
    picture3: encinoMan3,
    tagline: "I'd rather see a FUCKIN robot movie!",
    taglineWriter: "Cliff Steel",
    bulletOne: "Pancreatic health awareness",
    bulletTwo: "Tips for weezing the juice",
    cast: "Directed by LES MAYFIELD starring BRENDAR FACIR SEAN ASTIN PAULY SHORE and MEGAN WARD ",
  },
  {
    id: 155,
    name: "Son in Law",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZWY1YWUwNjQtMDk2Zi00MDJiLWIzMDAtNzAyNjJiYTFlMGI0XkEyXkFqcGdeQXVyNjE5MjUyOTM@._V1_SX300.jpg",
    rating: 4,
    year: 1993,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "95 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by STEVE RASH starring PAULY SHORE CARLA GUGINO LANE SMITH and PATRICK RENNA",
  },
  {
    id: 156,
    name: "Blast from the Past",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGFiZjRhMTYtZmMzYS00MGEyLWEzN2EtNWMzMjA3OGI0NGZlXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 6,
    year: 1999,
    synopsis:
      "After spending 35 years in a 60's time capsule, Adam Webber is thrust into a strange, modern world to buy new supplies for his family.",
    genre: "Comedy",
    subgenre: "",
    runtime: "112 min",
    pairs: "Flaming Dr. Pepper.",
    drinkRecipe: "8 ounces beer, 3/4 ounce Amaretto, high proof rum.",
    rated: "PG-13",
    picture1: blastFromThePast1,
    picture2: blastFromThePast2,
    picture3: blastFromThePast3,
    tagline: "Bunker? I barely know her!",
    taglineWriter: "Brundon Frasner",
    bulletOne:
      "You'd think Encino Man would've taught Brendan Frasier not to get frozen in the past, but some people never learn.",
    bulletTwo: "Eve's way out of sync 'get away from me!'",
    cast: "Directed by HUGH WILSON starring BRANDON FASTENER ALICIA SILVERSTONE CHRISTOPHER WALKEN and SISSY SPACEK",
  },
  {
    id: 157,
    name: "Apocalypse Now",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDdhODg0MjYtYzBiOS00ZmI5LWEwZGYtZDEyNDU4MmQyNzFkXkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 2,
    year: 1979,
    synopsis:
      "Incredibly boring and overrated, this movie may have been longer and more painful than the actual Vietnam war.",
    genre: "War",
    subgenre: "",
    runtime: "147 min",
    pairs: "",
    noPairs: "Budweiser.",
    rated: "R",
    picture1: apocalypseNow1,
    picture2: apocalypseNow3,
    picture3: apocalypseNow2,
    tagline: "WATCH PLATOON INSTEAD!",
    taglineWriter: "Rambo",
    bulletOne:
      "Most of the movie is a disjointed fantasy that makes you feel schizophrenic.",
    bulletTwo:
      "Giving it second and third chances and realizing it still sucks.",
    cast: "Directed by FRANCIS FORD COPPOLA starring MARTIN SHEEN MARLON BRANDO ROBERT DUVALL and LAURENCE FISHBURNE",
  },
  {
    id: 158,
    name: "Bedazzled",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODgxOTg5OGUtOWViMC00NjM5LWEyMTQtN2M4NTBjYWUzOWUzXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 2000,
    synopsis:
      "Brendan Fazer hopes to finally land his dream girl - for the low low price of his soul.",
    genre: "Comedy",
    subgenre: "",
    runtime: "93 min",
    pairs: "a Red Devil",
    drinkRecipe:
      "Vodka, SoCo, Peach Schnapps, Sloe Gin, Triple Sec, OJ, Grenadine.",
    rated: "PG-13",
    picture1: bedazzled1,
    picture2: bedazzled2,
    picture3: bedazzled3,
    tagline: "This movie went out there and gave 110%",
    taglineWriter: "Dennis Rodman",
    bulletOne: "Smoking hot Liz Hurley with devilish good looks.",
    bulletTwo:
      "Proof that women CLAIM to want sensitive guys but actually hate vegan soyboys.",
    cast: "Directed by HAROLD RAMIS starring BENDAN FRAZIER LIZ HURLEY ORLANDO JONES and PAUL ADELSTEIN",
  },
  {
    id: 159,
    name: "The Lives of Others",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOThkM2EzYmMtNDE3NS00NjlhLTg4YzktYTdhNzgyOWY3ZDYzXkEyXkFqcGdeQXVyNzQzNzQxNzI@._V1_SX300.jpg",
    rating: 5,
    year: 2006,
    synopsis:
      "A creep in the East German Stasi eavesdrops and spies on his compatriots.",
    genre: "Drama",
    subgenre: "",
    runtime: "137 min",
    pairs: "",
    noPairs: "Radeberger Pilsner",
    rated: "R",
    picture1: lebenAnderen1,
    picture2: lebenAnderen2,
    picture3: lebenAnderen3,
    tagline: "I HEARD THAT",
    taglineWriter: "Erich Mielke",
    bulletOne: "Surprisingly cool work uniforms.",
    bulletTwo:
      "Between Alexa, Siri, Smart TV's, and browsing history, we're basically under the same amount of surveillance.",
    cast: "Directed by FLORIAN HENKEL VON DONNERSMARCK starring ULRICH MUEHE MARTINA GEDECK SEBASTIAN KOCH und ULRICH TUKUR",
  },
  {
    id: 160,
    name: "Django Unchained",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjIyNTQ5NjQ1OV5BMl5BanBnXkFtZTcwODg1MDU4OA@@._V1_SX300.jpg",
    rating: 5,
    year: 2012,
    synopsis:
      "A slave got the fuck out of chains while the gettin was good. Became a free man, teamed up with King Schultz, come back to give y'all what for.",
    genre: "Action",
    subgenre: "Western",
    runtime: "165 min",
    pairs: "",
    noPairs: "a Polynesian Pearl - and don't spare the rum.",
    rated: "R",
    picture1: django1,
    picture2: django2,
    picture3: django3,
    tagline: "TEARS YOU TO SHREDS!",
    taglineWriter: "Man Dingo",
    bulletOne: "Classic Tarantino shootouts.",
    bulletTwo: "Being unable to contain your contempt for someone.",
    cast: "Directed by QUENTIN TARANTINO starring JAMIE FOXX CHRISTOPH WALTZ KERRY WASHINGTON and LEONARDO DiCAPRIO",
  },
  {
    id: 161,
    name: "Inglourious Basterds",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTJiNDEzOWYtMTVjOC00ZjlmLWE0NGMtZmE1OWVmZDQ2OWJhXkEyXkFqcGdeQXVyNTIzOTk5ODM@._V1_SX300.jpg",
    rating: 6,
    year: 2009,
    synopsis:
      "A team of soldier-spies hunt Nazis and hatch a plan to kill Hitler.",
    genre: "War",
    subgenre: "Action",
    runtime: "153 min",
    pairs: "",
    noPairs: "Three glasses of scotch.",
    rated: "R",
    picture1: inglouriousBasterds1,
    picture2: inglouriousBasterds2,
    picture3: inglouriousBasterds3,
    tagline: "BETTER THAN THE VIEW FROM PIZ PALÜ",
    taglineWriter: "Leni Riefenstahl",
    bulletOne:
      "The story, dialogue, characters, action, and acting are all superb.",
    bulletTwo:
      "If you don't like this movie, you deserve to have your brains bashed in by a Bear Jew.",
    cast: "Directed by QUENTIN TARANTINO starring BRAD PITT DIANE KRUGER CHRISTOPH WALTZ and TIL SCHWEIGER",
  },
  {
    id: 162,
    name: "Spider-Man: Into the Spider-Verse",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjMwNDkxMTgzOF5BMl5BanBnXkFtZTgwNTkwNTQ3NjM@._V1_SX300.jpg",
    rating: 6,
    year: 2018,
    synopsis:
      "If they want to continue to remake Spiderman over and over again like this then fine cause this movie rules.",
    genre: "Superhero",
    subgenre: "Animation",
    runtime: "117 min",
    pairs: "a massive cue-ball.",
    drinkRecipe: "Vodka, Rum, coconut water, and pineapple juice.",
    rated: "PG",
    picture1: spiderverse1,
    picture2: spiderverse2,
    picture3: spiderverse3,
    tagline: "WHERE'S MY MOVIE?!",
    taglineWriter: "Spider Ma'am",
    bulletOne: "Sweet theme song.",
    bulletTwo: "Six different Spider-people-things.",
    cast: "Directed by BOB PERSICHETTI, PETER RAMSEY, and RODNEY ROTHMAN starring SHAMEIK MOORE JAKE JOHNSON HALEY STEINFELD and MAHERSHALA   ALI",
  },
  {
    id: 163,
    name: "The Shining",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZWFlYmY2MGEtZjVkYS00YzU4LTg0YjQtYzY1ZGE3NTA5NGQxXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    year: 1980,
    synopsis:
      "After months of snowbound isolation, a doting father decides his annoying wife and son GOTTA GO.",
    genre: "Horror",
    subgenre: "Cult Classic",
    runtime: "146 min",
    pairs: "Martini's and Advil.",
    rated: "R",
    picture1: shining1,
    picture2: shining2,
    picture3: shining3,
    tagline: "POORLY WRITTEN!",
    taglineWriter: "Steven Queen",
    bulletOne: "Making new friends is easy as a kid.",
    bulletTwo: "The book is better.",
    cast: "Directed by STANLEY KUBRICK starring JACK NICHOLSON SHELLEY DUVALL DANNY LLOYD and SCATMAN CROTHERS",
  },
  {
    id: 164,
    name: "WALL-E",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjExMTg5OTU0NF5BMl5BanBnXkFtZTcwMjMxMzMzMw@@._V1_SX300.jpg",
    rating: 5,
    year: 2008,
    synopsis:
      "Set in the actual future of humanity, WALL-E follows a trash compacting robot whose sole job is to clean up all the junk we left behind. ",
    genre: "Animation",
    subgenre: "",
    runtime: "98 min",
    pairs: "a Junkyard.",
    drinkRecipe: "Whisky, Rum, Jager, Coke.",
    rated: "G",
    picture1: walle1,
    picture2: walle2,
    picture3: walle3,
    tagline: "Earth is doomed. But we already know that.",
    taglineWriter: "Greenzo",
    bulletOne: "Robot romance.",
    bulletTwo:
      "Let's hurry up and ruin the Earth so we can have cool floating computer chairs already.",
    cast: "Directed by ANDREW STANTON starring BEN BURTT ELISSA KNIGHT JEFF GARLIN and FRED WILLARD",
  },
  {
    id: 165,
    name: "Big Eyes",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjA2ODM5MDE3N15BMl5BanBnXkFtZTgwOTIxNjc1MzE@._V1_SX300.jpg",
    rating: 4,
    year: 2014,
    synopsis:
      "During a time when no one was buying lady art, Walter Keane generously made his wifes work popular by lending his name. Everything was great until she just HAD to have credit for it.",
    genre: "Drama",
    subgenre: "Biography",
    runtime: "106 min",
    pairs: "a Red Eye.",
    drinkRecipe: "Tomato juice, A cracked egg, vodka, and cold beer.",
    rated: "PG-13",
    picture1: bigEyes1,
    picture2: bigEyes2,
    picture3: bigEyes3,
    tagline: "Darn! I thought this was 'Big Guys'",
    taglineWriter: "Anderson Cooper",
    bulletOne: "Basically the blueprint for anime.",
    bulletTwo:
      "Watching pretentious art types trying to decide if the art is good or bad.",
    cast: "Directed by TIM BURTON starring CHRISTOPH WALTZ AMY ADAMS DANNY HUSTON and KRYSTEN RITTER",
  },
  {
    id: 166,
    name: "Mad Max: Fury Road",
    poster:
      "https://m.media-amazon.com/images/M/MV5BN2EwM2I5OWMtMGQyMi00Zjg1LWJkNTctZTdjYTA4OGUwZjMyXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    rating: 6,
    year: 2015,
    synopsis:
      "A bunch of thirsty people want some water, but the plot doesn't matter - watch this for the awesome effects and stunts.",
    genre: "Action",
    subgenre: "Scifi",
    runtime: "120 min",
    pairs: "",
    noPairs: "scotch and water.",
    rated: "R",
    picture1: madMax1,
    picture2: madMax2,
    picture3: madMax3,
    tagline: "THIS GUY NEEDS TO CHILL",
    taglineWriter: "Mr. Freeze",
    bulletOne: "Badass flaming guitar. ",
    bulletTwo:
      "The chase through the desert is 𝒐𝒖𝒕𝒔𝒂𝒏𝒅𝒊𝒏𝒈. Forgive the pun - it's just our 𝒅𝒓𝒚 𝒉𝒖𝒎𝒐𝒓. Ok, that was the last one - we're 𝒅𝒖𝒏𝒆.",
    cast: "Directed by GEORGE MILLER starring TOM HARDY CHARIZE THERON NICHOLAS HOULT and HUGH KEAYS-BYRNE",
  },
  {
    id: 167,
    name: "Warcraft",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjIwNTM0Mzc5MV5BMl5BanBnXkFtZTgwMDk5NDU1ODE@._V1_SX300.jpg",
    rating: 5,
    year: 2016,
    synopsis:
      "Action-packed movie where humans and orcs battle over land. You don't need to have played the game to enjoy it. In fact, it's probably better if you haven't played the game; gamers and comic book nerds are never happy with movie adaptations.",
    genre: "Scifi",
    subgenre: "Action, War",
    runtime: "123 min",
    pairs: "",
    noPairs: "a flagon of mead or a cherry grog.",
    rated: "PG-13",
    picture1: warcraft1,
    picture2: warcraft2,
    picture3: warcraft3,
    tagline: "WoW! It pulls you right in",
    taglineWriter: "Leeroy Jenkins",
    bulletOne: "The green babe is hot - fangs or not. ",
    bulletTwo:
      "Awesome fight scenes. Humans are like 1/4th the size of orcs but somehow cut through them easily.",
    cast: "Directed by DUNCAN JONES starring TRAVIS FIMMEL PAULA PATTON BEN FOSTER and DOMINIC COOPER",
  },
  {
    id: 168,
    name: "The Revenant",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDE5OWMzM2QtOTU2ZS00NzAyLWI2MDEtOTRlYjIxZGM0OWRjXkEyXkFqcGdeQXVyODE5NzE3OTE@._V1_SX300.jpg",
    rating: 5,
    year: 2015,
    synopsis:
      "Leo fights for survival as bears, indians, and sub-zero temperatures do their damndest to kill him.",
    genre: "Action",
    subgenre: "",
    runtime: "156 min",
    pairs: "a Brown Bear.",
    drinkRecipe: "Baileys, Cinnamon Schnapps.",
    rated: "R",
    picture1: revenant1,
    picture2: revenant2,
    picture3: revenant3,
    tagline: "Fuckin' guy just won't die!",
    taglineWriter: "Rasputin",
    bulletOne: "Leo finally got the oscar.",
    bulletTwo: "Hardy's accent is thicker than the pelts he sells.",
    cast: "Directed by ALEJANDRO G. INARITTU starring LEONARDO DiCAPRIO TOM HARDY WILL POULTER and DOMHNALL GLEESON",
  },
  {
    id: 169,
    name: "Princess Mononoke",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNGIzY2IzODQtNThmMi00ZDE4LWI5YzAtNzNlZTM1ZjYyYjUyXkEyXkFqcGdeQXVyODEzNjM5OTQ@._V1_SX300.jpg",
    rating: 4,
    year: 1997,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "134 min",
    pairs: "",
    rated: "PG-13",
    picture1: mono1,
    picture2: mono2,
    picture3: mono3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 170,
    name: "Jack Reacher: Never Go Back",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTA2MDIwNzAyNzReQTJeQWpwZ15BbWU4MDAxNjI5Njkx._V1_SX300.jpg",
    rating: 4,
    year: 2016,
    synopsis:
      "With the help of a hot Canadian Army Major, Jack must fight literally everyone in sight to clear his name and prove he isn't a father.",
    genre: "Action",
    subgenre: "Drama",
    runtime: "118 min",
    pairs: "No Stunt Doubles.",
    drinkRecipe: "Double Shot of Jack and Canadian Club with Dr. Pepper.",
    noPairs: "",
    rated: "PG-13",
    picture1: jackreacher21,
    picture2: jackreacher22,
    picture3: jackreacher23,
    tagline: "HE SHOULD OFFER THOSE COPS CADDY'S!",
    taglineWriter: "Barry Seal",
    bulletOne: "Technically providing intel without committing treason.",
    bulletTwo: "Solid follow up, although not a  rare sequel win.",
    cast: "Directed by EDWARD ZWINK starring TOM CRUISE COBIE SMULDERS ALDIS HODGE and DANIKA YAROSH",
  },
  {
    id: 172,
    name: "Maximum Overdrive",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzUwOWQxNjAtMzU4MC00M2IwLWEyNzgtNGRlMjE4Y2VlYThiXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    year: 1986,
    synopsis:
      "Machines become sentient and start killing people. Not sophisticated or intelligent cyborgs like The Terminator, mind you; we're talking basic run of the mill electronics like hair dryers, lawnmowers, and radios. ",
    genre: "Horror",
    subgenre: "Cult Classic",
    runtime: "98 min",
    pairs: "",
    noPairs: "Captain and coke, nuts on the side.",
    rated: "R",
    picture1: maximumOverdrive1,
    picture2: maximumOverdrive2,
    picture3: maximumOverdrive3,
    tagline: "Radio killed the video star.",
    taglineWriter: "Tommy Vercetti",
    bulletOne: "VERY abrupt ending.",
    bulletTwo:
      "If you want to see a soda machine blast a dude in the nuts with a can of coke, this is the movie for you.",
    cast: "Directed by STEPHEN KING starring EMILIO ESTEVEZ PAT HINGLE LAURA HARRINGTON and YEARDLEY SMITH",
  },
  {
    id: 173,
    name: "Over the Top",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjFiZjYyOGUtNWQ4My00ZTcyLWJkYTMtOGMzZjZjOTE0MDE0XkEyXkFqcGdeQXVyMTUzMDUzNTI3._V1_SX300.jpg",
    rating: 4,
    year: 1987,
    synopsis:
      "A fun armwrestling flick wherein Sly answers the age old question 'is it possible to stretch your lower lip past your collarbone?'.",
    genre: "Sports",
    subgenre: "",
    runtime: "93 min",
    pairs: "Castrol Motor Oil.",
    drinkRecipe: "Jager, Kraken, Coke, Skrewball.",
    rated: "PG",
    picture1: overTheTop1,
    picture2: overTheTop2,
    picture3: overTheTop3,
    tagline: "A muscles and mullets masterpiece",
    taglineWriter: "Joe Dirt",
    bulletOne:
      "So many roids that simply watching will make you fail a drug test.",
    bulletTwo: "Cameo from real life armwrestling GOAT John Brzenk.",
    cast: "Directed by MENAHEM GOLAN starring SYLVESTER STALLONE RICK ZUMWALT TERRY FUNK and BRUCE WAY",
  },
  {
    id: 174,
    name: "Cannibal Holocaust",
    poster:
      "https://m.media-amazon.com/images/M/MV5BN2YyNWI1ZjAtZjZmOC00MDU4LWE0ODktYmMyZmMzZGExZWE4XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    year: 1980,
    synopsis:
      "Professor Monroe enlists some help in search of an ill fated nature documentary crew.",
    genre: "Horror",
    subgenre: "Cult Classic",
    runtime: "95 min",
    pairs: "Film Crew Blood.",
    drinkRecipe: "tomato juice, gin, lemon juice, and red wine.",
    rated: "Unrated",
    picture1: cannibalHolocaust1,
    picture2: cannibalHolocaust2,
    picture3: cannibalHolocaust3,
    tagline: "LOOKS WELL DONE!",
    taglineWriter: "Hannibal Lecter",
    bulletOne: "The original found footage flick.",
    bulletTwo: "Being charged with murder, IRL.",
    cast: "Directed by RUGGERO DEODATO starring FRANCESA CIARDI  ROBERT KERMAN  PERRY PIRKANEN  and  LUCA BARBARESCHI",
  },
  {
    id: 175,
    name: "Braveheart",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzkzMmU0YTYtOWM3My00YzBmLWI0YzctOGYyNTkwMWE5MTJkXkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 6,
    year: 1995,
    synopsis: "",
    genre: "War",
    subgenre: "Action",
    runtime: "178 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 176,
    name: "What Women Want",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjUyZWE5YmMtNDA2ZC00NzFlLTg0MzktOTgzYjA2ZWE3NmIwXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 5,
    year: 2000,
    synopsis:
      "After a bit of whimsical electrocution, a manly marketing executive can hear womens thoughts. ",
    genre: "Romcom",
    subgenre: "",
    runtime: "127 min",
    pairs: "",
    noPairs: "scotch.",
    rated: "PG-13",
    picture1: whatWomenWant1,
    picture2: whatWomenWant2,
    picture3: whatWomenWant3,
    tagline: "ITS GOT BROAD APPEAL",
    taglineWriter: "Sean Connery",
    bulletOne: "A chick flick starring Mel Gibson? Count us in.",
    bulletTwo:
      "If you don't exploit your superpower to get laid, then you don't even deserve it.",
    cast: "Directed by NANCY MEYER starring MEL GIBSON HELEN HUNT MARISA TOMEI and ALAN ALDA",
  },
  {
    id: 177,
    name: "Home Alone",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzFkM2YwOTQtYzk2Mi00N2VlLWE3NTItN2YwNDg1YmY0ZDNmXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    rating: 5,
    year: 1990,
    synopsis:
      "A pint-sized psycho tortures intruders while his family visits Paris. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "103 min",
    pairs: "a Burned Scalp.",
    drinkRecipe: "Eggnog, Fireball.",
    rated: "PG",
    picture1: homeAlone1,
    picture2: homeAlone2,
    picture3: homeAlone3,
    tagline: "I would've babysat the precious child!",
    taglineWriter: "Michael Jackson",
    bulletOne: "Kevin 100% grew up to be the Jigsaw Killer in Saw.",
    bulletTwo: "Excellent physical comedy from Pesci and Stern.",
    cast: "Directed by CHRIS COLUMBUS starring MACAULEY CULKIN JOE PESCI DANIEL STERN and DEVIN RATRAY",
  },
  {
    id: 178,
    name: "Beerfest",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTI4NzYyMDE0OF5BMl5BanBnXkFtZTcwOTIzODczMw@@._V1_SX300.jpg",
    rating: 6,
    year: 2006,
    synopsis:
      "With the help of a scientist, a brewery worker, and a hooker, two brothers hope to win back their family's beer recipe and prove that their Great Gam Gam isn't a whore.",
    genre: "Comedy",
    subgenre: "",
    runtime: "110 min",
    pairs: "",
    noPairs: "Schnitzengiggle or She-Wolf.",
    rated: "R",
    picture1: beerfest1,
    picture2: beerfest2,
    picture3: beerfest3,
    tagline: "BETTER THAN A Z-J!",
    taglineWriter: "Herr Schniedelwichsen",
    bulletOne:
      "Extremely accurate portrayal of your drunken memories vs reality.",
    bulletTwo:
      "One of the best moments in cinema: killing a main character and bringing him right back.",
    cast: "Directed by JAY CHANDRASEKHAR starring STEVE LEMME KEVIN HEFFERNAN ERIK STOLHANSKE and PAUL SOTER",
  },
  {
    id: 179,
    name: "Wedding Crashers",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZmJkNzViYjYtZWZlNy00OGE4LWI2MzUtYTcwNjY3Y2MyODIwXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 5,
    year: 2005,
    synopsis:
      "Two motorboatin' sons a bitches crash weddings to sleep with babes. It's all fun and games until one catches feelings and the other catches a stage-five clinger. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "119 min",
    pairs: "",
    noPairs: "champagne and MEATLOAF! FUCK!",
    rated: "R",
    picture1: weddingCrashers1,
    picture2: weddingCrashers2,
    picture3: weddingCrashers3,
    tagline: "ENGAGING!",
    taglineWriter: "Kneel Diamond",
    bulletOne: "Keeping a gift even when the giver wants it back.",
    bulletTwo: "Getting jerked off in front of an entire dinner party.",
    cast: "Directed by DAVID DOBKIN starring OWEN WILSON VINCE VAUGHN RACHEL MCADAMS and ISLA FISHER",
  },
  {
    id: 180,
    name: "Old School",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzI4NDIzMDgtNGNkZi00MTI2LWJhYzgtYzM5NThhMTQ0OGIzXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 5,
    year: 2003,
    synopsis: "Middle aged dudes start a frat to relive the glory days. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "88 min",
    pairs: "",
    noPairs: "maybe just one beer bong.",
    rated: "R",
    picture1: oldSchool1,
    picture2: oldSchool2,
    picture3: oldSchool3,
    tagline: "TOP OF ITS CLASS!",
    taglineWriter: "Dean Wormer",
    bulletOne:
      "There's a fair amount of profanity, so you'll have to wear your earmuffs. ",
    bulletTwo:
      "Tips for health: rip cigs during gymnastics routines and streak through the quad.",
    cast: "Directed by TODD PHILLIPS starring LUKE WILSON VINCE VAUGHN WILL FERRELL and JEREMY PIVEN",
  },
  {
    id: 181,
    name: "Dodgeball",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTIwMzE2MjM4MV5BMl5BanBnXkFtZTYwNjA1OTY3._V1_SX300.jpg",
    rating: 5,
    year: 2004,
    synopsis:
      "A group of local losers take on ripped up gym rats in a dodgeball tournament where first place wins $50,000. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "92 min",
    pairs: "Sterile Urine.",
    drinkRecipe: "Gin, Lemonade.",
    rated: "PG-13",
    picture1: dodgeball1,
    picture2: dodgeball2,
    picture3: dodgeball3,
    tagline: "THUMBS UP!",
    taglineWriter: "Fuckin' Chuck Norris",
    bulletOne: "A true underdog story.",
    bulletTwo: "Having your dodgeball coach get crushed by two tons of irony.",
    cast: "Directed by RAWSON MARSHALL THURBER starring VINCE VAUGHN BEN STILLER CHRISTINE TAYLOR and RIP TORN",
  },
  {
    id: 182,
    name: "The Graduate",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQ0ODc4MDk4Nl5BMl5BanBnXkFtZTcwMTEzNzgzNA@@._V1_SX300.jpg",
    rating: 4,
    year: 1967,
    synopsis:
      "Dustin Hoffman has to choose between banging an aged seductress or her daughter in this conceptual blueprint for what American Pie would later coin as 'MILF'. ",
    genre: "Comedy",
    subgenre: "Romcom",
    runtime: "106 min",
    pairs: "",
    noPairs: "aged wine",
    rated: "PG",
    picture1: graduate1,
    picture2: graduate2,
    picture3: graduate3,
    tagline: "HERE'S TO YOU!",
    taglineWriter: "Mrs. Robinson",
    bulletOne: "Great investment tips (plastics).",
    bulletTwo: "Interrupting a wedding in the loudest way possible.",
    cast: "Directed by MIKE NICHOLS starring DUSTIN HOFFMAN ANNE BANCROFT KATHARINE ROSS and WILLIAM DANIELS",
  },
  {
    id: 183,
    name: "Meet the Parents",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGNlMGZiMmUtZjU0NC00MWU4LWI0YTgtYzdlNGVhZGU4NWZlXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 6,
    year: 2000,
    synopsis:
      "Ben Stiller lives out every fiancee’s worst nightmare in this cringe comedy you’ll have to watch through your fingers. Trying to win the approval of a joyless florist (Robert De Niro) Stiller battles an ex-boyfriend, strict family rules, and a flammable gazebo.",
    genre: "Comedy",
    subgenre: "",
    runtime: "108 min",
    pairs: "",
    noPairs: "a whole bunch of Mumm's.",
    rated: "PG-13",
    picture1: mtp1,
    picture2: mtp2,
    picture3: mtp3,
    tagline: "FOCKER? I BARELY KNOW HER!",
    taglineWriter: "Andrew Dice Gay",
    bulletOne: "Cat milking in Motown.",
    bulletTwo:
      "Getting ridiculed for playing sports timidly, and then scolded when showing an ounce of aggression.",
    cast: "Directed by JAY ROACH starring BEN STILLER TERI POLO ROBERT DE NIRO and BLYTHE DANNER",
  },
  {
    id: 184,
    name: "Meet the Fockers",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWZjODQxMWMtZmIzYS00YjMwLWFiZTctYmFiZmJiNjljYWVmL2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    year: 2004,
    synopsis:
      "In the first movie they asked 'What kind of parents name their kid Gay Focker?'. This sequel answers that question.",
    genre: "Comedy",
    subgenre: "",
    runtime: "115 min",
    pairs: "Truth Serum.",
    drinkRecipe: "Straight vodka.",
    rated: "PG-13",
    picture1: mtf1,
    picture2: mtf2,
    picture3: mtf3,
    tagline: "GETS AN 'A' FOR EFFORT!",
    taglineWriter: "Bernie Focker",
    bulletOne: "Making your parents proud - even if you come in 9th place.",
    bulletTwo:
      "The manary gland proves Jack wasn't kidding when he asked Greg to milk him.",
    cast: "Directed by JAY ROACH starring BEN STILLER TERI POLO ROBERT DE NIRO and BLYTHE DANNER",
  },
  {
    id: 185,
    name: "Little Fockers",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTkwNjE3NjQwOV5BMl5BanBnXkFtZTcwNzAyNTMwNA@@._V1_SX300.jpg",
    rating: 4,
    year: 2010,
    synopsis:
      "The jokes were stretched pretty focking thin by this point, but it's still a worth a watch - especially if you puff the magic dragon. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "98 min",
    pairs: "a Tom Collins.",
    drinkRecipe: "Gin, lemon juice, simple syrup, club soda.",
    rated: "PG-13",
    picture1: littleFockers1,
    picture2: littleFockers2,
    picture3: littleFockers3,
    tagline: "We're all hamsters on Jack's wheel",
    taglineWriter: "The Bobfather",
    bulletOne: "Finally earning your position as the turkey carver.",
    bulletTwo: "Giving your father in law a full-on rager.",
    cast: "Directed by PAUL WEITZ starring BEN STILLER TERI POLO ROBERT DE NIRO and BLYTHE DANNER",
  },
  {
    id: 186,
    name: "Toy Story",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDU2ZWJlMjktMTRhMy00ZTA5LWEzNDgtYmNmZTEwZTViZWJkXkEyXkFqcGdeQXVyNDQ2OTk4MzI@._V1_SX300.jpg",
    rating: 6,
    year: 1995,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "81 min",
    pairs: "",
    rated: "G",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 187,
    name: "Toy Story 2",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWM5ZDcxMTYtNTEyNS00MDRkLWI3YTItNThmMGExMWY4NDIwXkEyXkFqcGdeQXVyNjUwNzk3NDc@._V1_SX300.jpg",
    rating: 4,
    year: 1999,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "92 min",
    pairs: "",
    rated: "G",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 188,
    name: "Toy Story 3",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTgxOTY4Mjc0MF5BMl5BanBnXkFtZTcwNTA4MDQyMw@@._V1_SX300.jpg",
    rating: 6,
    year: 2010,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "103 min",
    pairs: "",
    rated: "G",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 189,
    name: "Amadeus",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNWJlNzUzNGMtYTAwMS00ZjI2LWFmNWQtODcxNWUxODA5YmU1XkEyXkFqcGdeQXVyNTIzOTk5ODM@._V1_SX300.jpg",
    rating: 5,
    year: 1984,
    synopsis:
      "A prodigy effortlessly outclasses and humiliates an older musician at every turn.  ",
    genre: "Drama",
    subgenre: "",
    runtime: "160 min",
    pairs: "",
    noPairs: "Gluhwein.",
    rated: "R",
    picture1: amadeus1,
    picture2: amadeus2,
    picture3: amadeus3,
    tagline: "Europeans love powdered wigs down below",
    taglineWriter: "A Merkin",
    bulletOne: "In life, Mozart composed. In death, he decomposed.",
    bulletTwo:
      "Even the world's most sophisticated figures find fart jokes hilarious.",
    cast: "Directed by MILOS FORMAN starring F. MURRAY ABRAHAM TOM HULCE ELIZABETH BERRIDGE and SIMON CALLOW",
  },
  {
    id: 190,
    name: "Good Will Hunting",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTI0MzcxMTYtZDVkMy00NjY1LTgyMTYtZmUxN2M3NmQ2NWJhXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    year: 1997,
    synopsis:
      "A genius janitor sees a therapist to overcome an apple addiction, meanwhile the therapist won't stop talking about his wifes farts. ",
    genre: "Drama",
    subgenre: "",
    runtime: "126 min",
    pairs: "Hairy Knuckles.",
    drinkRecipe: "Dip cotton candy into a glass of Crown Royal.",
    rated: "R",
    picture1: gwh1,
    picture2: gwh2,
    picture3: gwh3,
    tagline: "WHATSYA MAJOR DUDE?",
    taglineWriter: "Charlie Kelly",
    bulletOne: "Telling the pilot/coffee joke as if it's yours.",
    bulletTwo:
      "Be honest - we all wish we were cool/smart enough to dress someone down like in the iconic bar scene.",
    cast: "Directed by GUS VAN SANT starring MATT DAMON BEN AFFLECK ROBIN WILLIAMS and STELLAN SKARSGARD",
  },
  {
    id: 191,
    name: "Requiem for a Dream",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTdiNzJlOWUtNWMwNS00NmFlLWI0YTEtZmI3YjIzZWUyY2Y3XkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 4,
    year: 2000,
    synopsis: "A bunch of druggies do drugs. ",
    genre: "Drama",
    subgenre: "",
    runtime: "102 min",
    pairs: "Dream Potion.",
    drinkRecipe: "THC soda + vodka.",
    rated: "R",
    picture1: requiem1,
    picture2: requiem2,
    picture3: requiem3,
    tagline: "DRUGS ARE BAD, MKAY?",
    taglineWriter: "Mr. Mackey",
    bulletOne: "Entrepreneurial TV sales.",
    bulletTwo:
      "The drug-addled lunacy of this movie is so powerful that you'll feel fucked up even if you're stone-cold sober.",
    cast: "Directed by DARREN ARONOFSKY starring ELLEN BURSTYN JARED LETO JENNIFER CONNELLY and MARLON WAYANS",
  },
  {
    id: 192,
    name: "2001: A Space Odyssey",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmNlYzRiNDctZWNhMi00MzI4LThkZTctMTUzMmZkMmFmNThmXkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 1,
    year: 1968,
    synopsis:
      "This movie chugs dick. Being locked in a padded white room for 2001 years would be more entertaining (and shorter) than this turd. Avoid at all costs. ",
    genre: "Scifi",
    subgenre: "",
    runtime: "149 min",
    pairs: "",
    noPairs: "watching a different movie.",
    rated: "G",
    picture1: spaceOdyssey1,
    picture2: spaceOdyssey2,
    picture3: spaceOdyssey3,
    tagline: "Began before I was born; continued after I died",
    taglineWriter: "Methuselah",
    bulletOne: "Mind-numbingly slow.",
    bulletTwo:
      "Hearing pretentious shitheads tell you 'you don't get it' or 'you're not smart enough'. Nope, we get it - it just sucks. ",
    cast: "Directed by STANLEY KUBRICK starring KEIR DULLEA GARY LOCKWOOD WILLIAM SYLVESTER and DANIEL RICHTER",
  },
  {
    id: 193,
    name: "Jaws",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmVmODY1MzEtYTMwZC00MzNhLWFkNDMtZjAwM2EwODUxZTA5XkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 5,
    year: 1975,
    synopsis:
      "You’re gonna need a bigger TV for this Spielberg classic.  A cop, a marine biologist, and a salty old sea-dog hunt down a killer shark.",
    genre: "Thriller",
    subgenre: "Horror",
    runtime: "124 min",
    pairs: "",
    noPairs: "a couple of drinks an hour before you watch.",
    rated: "PG",
    picture1: jaws1,
    picture2: jaws2,
    picture3: jaws3,
    tagline: "IT'S A MAN-EATER!",
    taglineWriter: "Holland Oats",
    bulletOne: "Iconic dolly zoom and suspense music.",
    bulletTwo:
      "The film that caused an exponential rise in shark hunting to the point of near extinction.",
    cast: "Directed by STEVEN SPIELBERG starring ROY SCHNEIDER ROBERT SHAW RICHARD DREYFUSS and LORRAINE GARY",
  },
  {
    id: 194,
    name: "River's Edge",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzMyYzVkYjctNDliZi00Yjc0LThiODctOTc5Mzk2Njc5YzM2XkEyXkFqcGdeQXVyNTc1NTQxODI@._V1_SX300.jpg",
    rating: 4,
    year: 1986,
    synopsis: "A dude is remarkably open about murdering a fellow student. ",
    genre: "Thriller",
    subgenre: "Crime",
    runtime: "99 min",
    pairs: "",
    noPairs: "Coors Banquet.",
    rated: "R",
    picture1: riversEdge1,
    picture2: riversEdge2,
    picture3: riversEdge3,
    tagline: "Murder was her density ... I mean destiny.",
    taglineWriter: "George McFly",
    bulletOne: "Britt and Erica love this movie.",
    bulletTwo: "Full-frontal nudity in the trailer.",
    cast: "Directed by TIM HUNTER starring CRISPIN GLOVER KEANU REEVES IONE SKYE and DANIEL ROEBUCK",
  },
  {
    id: 195,
    name: "Citizen Kane",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjBiOTYxZWItMzdiZi00NjlkLWIzZTYtYmFhZjhiMTljOTdkXkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 2,
    year: 1941,
    synopsis:
      "To quote Peter Griffin, 'It's his sled. It was his sled from when he was a kid. There, I just saved two long boobless hours'.",
    genre: "Drama",
    subgenre: "Mystery",
    runtime: "119 min",
    pairs: "a Rosebud.",
    drinkRecipe: "Vodka, Grenadine, Cherry.",
    rated: "PG",
    picture1: citizenKane1,
    picture2: citizenKane2,
    picture3: citizenKane3,
    tagline: "THIS MOVIE STINKS",
    taglineWriter: "ORSON SMELLES",
    bulletOne: "Xanadu puts you to sleep faster than Xanax.",
    bulletTwo:
      "Should be Citizen Cane because you'll be an old fuck by the time it ends.",
    cast: "Directed by ORSON WELLES starring ORSON WELLES JOSEPH COTTEN DOROTHY COMINGORE and AGNES MOOREHEAD",
  },
  {
    id: 196,
    name: "Full Metal Jacket",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzkxODk0NjEtYjc4Mi00ZDI0LTgyYjEtYzc1NDkxY2YzYTgyXkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 4,
    year: 1987,
    synopsis: "",
    genre: "War",
    subgenre: "",
    runtime: "116 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 197,
    name: "Snatch",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTA2NDYxOGYtYjU1Mi00Y2QzLTgxMTQtMWI1MGI0ZGQ5MmU4XkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 6,
    year: 2000,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Crime",
    runtime: "102 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 198,
    name: "The Replacements",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGEwOWQzNmItM2FlNC00YTk3LTljNjYtNGQ1OTE2M2M4YzI0L2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 2000,
    synopsis: "",
    genre: "Sports",
    subgenre: "Comedy",
    runtime: "118 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 199,
    name: "Amelie",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDg4NjM1YjMtYmNhZC00MjM0LWFiZmYtNGY1YjA3MzZmODc5XkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 2,
    year: 2001,
    synopsis:
      "A gawky little weirdo wanders around and grins at the camera. Pass.",
    genre: "Romcom",
    subgenre: "",
    runtime: "122 min",
    pairs: "",
    noPairs: "wine.",
    rated: "R",
    picture1: amelie1,
    picture2: amelie2,
    picture3: amelie3,
    tagline: "Gag me with a spoon",
    taglineWriter: "Valley girls",
    bulletOne: "Hard pass.",
    bulletTwo: "Just another fatuous French movie that sucks. ",
    cast: "Directed by JEAN-PIERRE JEUNET starring AUDREY TAUTOU MATHIEU KASSOVITZ RUFUS and JAMEL DEBBOUZE",
  },
  {
    id: 200,
    name: "The Big Lebowski",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzliZDk0NjctNjhlOC00MWEyLWI3OWYtNjA5ZDYxMTMzNTc5XkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 5,
    year: 1998,
    synopsis: "A dude just wants his rug.",
    genre: "Comedy",
    subgenre: "Cult Classic",
    runtime: "117 min",
    pairs: "a White Russian.",
    drinkRecipe: "Vodka, Kahlúa, Cream.",
    rated: "R",
    picture1: bigLebowski1,
    picture2: bigLebowski3,
    picture3: bigLebowski2,
    tagline: "Degenerates bowl their way to oblivion",
    taglineWriter: "Ignatius J. Reilly",
    bulletOne: "Never pushing a man with a drink.",
    bulletTwo:
      "Learning what happens when you fuck a stranger in the ass (or find a stranger in the Alps, if you watched on TV).",
    cast: "Directed by THE COEN BROTHERS starring JEFF BRIDGES JOHN GOODMAN STEVE BUSCEMI and JULIANNE MOORE",
  },
  {
    id: 201,
    name: "Kingpin",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODAzODIyMDYwMV5BMl5BanBnXkFtZTcwMTUyNTEzNA@@._V1_SX300.jpg",
    rating: 6,
    year: 1996,
    synopsis: "",
    genre: "Sports",
    subgenre: "Comedy",
    runtime: "114 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 202,
    name: "What About Bob?",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQxMjU2ODk4N15BMl5BanBnXkFtZTgwODQzNTcxMTE@._V1_SX300.jpg",
    rating: 6,
    year: 1991,
    synopsis:
      "A perfectly reasonable psychiatrist is vilified by a psychotic patient that everyone loves. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "99 min",
    pairs: "",
    noPairs: "a bottle of vodka, drunk in baby sips.",
    rated: "PG",
    picture1: whatAboutBob1,
    picture2: whatAboutBob2,
    picture3: whatAboutBob3,
    tagline: "What a great Lake House!",
    taglineWriter: "Keanu Reeves",
    bulletOne: "Showing appreciation for delicious food. ",
    bulletTwo:
      "Dreyfuss on the brink of losing it for two straight hours. He really turns in a great and hilarious performance.",
    cast: "Directed by FRANK OZ starring BILL MURRAY RICHARD DREYFUSS JULIE HAGERTY and CHARLIE KORSMO",
  },
  {
    id: 203,
    name: "The Nice Guys",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODNlNmU4MGItMzQwZi00NGQyLWEyZWItYjFkNmI0NWI4NjBhXkEyXkFqcGdeQXVyODE5NzE3OTE@._V1_SX300.jpg",
    rating: 5,
    year: 2016,
    synopsis: "",
    genre: "Action",
    subgenre: "Comedy",
    runtime: "116 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by SHANE BLACK starring RUSSELL CROWE RYAN GOSLING ANGOURIE RICE and MATT BOMER",
  },
  {
    id: 204,
    name: "The Place Beyond the Pines",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjc1OTEwNjU4N15BMl5BanBnXkFtZTcwNzUzNDIwOQ@@._V1_SX300.jpg",
    rating: 1,
    year: 2012,
    synopsis:
      "Don’t watch the trailer, it’s a total lie. In fact, don’t even watch this movie.",
    genre: "Drama",
    subgenre: "",
    runtime: "140 min",
    pairs: "",
    noPairs: "Pine Sol.",
    rated: "R",
    picture1: pines1,
    picture2: pines2,
    picture3: pines3,
    tagline: "Disgrace Beyond the Pines",
    taglineWriter: "Everyone",
    bulletOne: "Gosling screams like a girl.",
    bulletTwo:
      "Expecting a badass cat-and-mouse crime thriller, then diverging into a story about kids that no one gives a shit about.",
    cast: "Directed by DEREK CIANFRANCE starring RYAN GOSLING BRADLEY COOPER EVA MENDES and MAHERSHALA ALI",
  },
  {
    id: 205,
    name: "Die Hard",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjRlNDUxZjAtOGQ4OC00OTNlLTgxNmQtYTBmMDgwZmNmNjkxXkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 6,
    year: 1988,
    synopsis: "A flat-footed cop battles a group of terrorist Deutschbags.",
    genre: "Action",
    subgenre: "",
    runtime: "132 min",
    pairs: "a Live Easy.",
    drinkRecipe: "Malibu, Pineapple Juice.",
    rated: "R",
    picture1: dieHard1,
    picture2: dieHard2,
    picture3: dieHard3,
    tagline: "THE HOSTAGE SITUATION GOT PRETTY HAIRY",
    taglineWriter: "Hans Puber",
    bulletOne: "The definitive Christmas movie.",
    bulletTwo: "Cameos from Al Leong AND Ranger Trivette? Hell yeah. ",
    cast: "Directed by JOHN McTIERNAN starring BRUCE WILLIS ALAN RICKMAN BONNIE BEDELIA and REGINALD VELJOHNSON",
  },
  {
    id: 206,
    name: "Die Hard 2",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzMzYzk3ZTEtZDg0My00MTY5LWE3ZmQtYzNhYjhjN2RhZGRjL2ltYWdlXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 4,
    year: 1990,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "124 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by RENNY HARLIN starring BRUCE WILLIS WILLIAM ATHERTON BONNIE BEDELIA and REGINALD VELJOHNSON",
  },
  {
    id: 207,
    name: "Die Hard with a Vengeance",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjI0ZWFiMmQtMjRlZi00ZmFhLWI4NmYtMjQ5YmY0MzIyMzRiXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1995,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "128 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by JOHN McTIERNAN starring BRUCE WILLIS JEREMY IRONS SAMUEL L. JACKSON and GRAHAM GREENE",
  },
  {
    id: 208,
    name: "Live Free or Die Hard",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDQxMDE1OTg4NV5BMl5BanBnXkFtZTcwMTMzOTQzMw@@._V1_SX300.jpg",
    rating: 5,
    year: 2007,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "128 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by LEN WISEMAN starring BRUCE WILLIS JUSTIN LONG TIMOTHY OLYPHANT and MAGGIE Q",
  },
  {
    id: 209,
    name: "A Good Day to Die Hard",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTcwNzgyNzUzOV5BMl5BanBnXkFtZTcwMzAwOTA5OA@@._V1_SX300.jpg",
    rating: 3,
    year: 2013,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "98 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "A good day to retire, McClane!",
    taglineWriter: "Vincent Vega",
    bulletOne: "",
    bulletTwo: ".",
    cast: "",
  },
  {
    id: 210,
    name: "The Slammin' Salmon",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTkyODk2NTU3OV5BMl5BanBnXkFtZTcwNjM5OTIwMw@@._V1_SX300.jpg",
    rating: 4,
    year: 2009,
    synopsis:
      "To save their restaurant and win $10,000 dollars, waiters compete to see who can make the most money in one night.  ",
    genre: "Comedy",
    subgenre: "",
    runtime: "90 min",
    pairs: "",
    noPairs: "a glass of hot water with lemon.",
    rated: "R",
    picture1: slamminSalmon1,
    picture2: slamminSalmon2,
    picture3: slamminSalmon3,
    tagline: "You have to DOMINATE the Swordfish!",
    taglineWriter: "John Travolta",
    bulletOne: "Eating an engagement ring.",
    bulletTwo: "Dealing with a psycho boss and crazy co-workers. ",
    cast: "Directed by KEVIN HEFFERNAN starring JAY CHANDRASEKHAR PAUL SOTER STEVE LEMME and MICHAEL CLARKE DUNCAN",
  },
  {
    id: 211,
    name: "Club Dread",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjExNDgxMjEwNl5BMl5BanBnXkFtZTcwMTYzNzQyMQ@@._V1_SX300.jpg",
    rating: 4,
    year: 2004,
    synopsis: "A serial killer wreaks havoc at a beachside resort. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "104 min",
    pairs: "",
    noPairs:
      "Coconut milk with a pineapple splash, dont forget that the rum comes third.",
    rated: "R",
    picture1: clubDread1,
    picture2: clubDread2,
    picture3: clubDread3,
    tagline: "GOOD AS A HAMBURGER IN HEAVEN!",
    taglineWriter: "Coconut Pete",
    bulletOne:
      "First things first, this was out 7 and a half fucking years before Margaritaville was even on the map.",
    bulletTwo: "Enjoy with a cool pina colada(burg).",
    cast: "Directed by JAY CHANDRASEKHAR starring BILL PAXTON KEVIN HEFFERNAN BRITTANY DANIEL and NAT FAXON",
  },
  {
    id: 212,
    name: "Look Who's Talking",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYWQzNGM0NjQtODI5MC00ODZjLTgyZDktYWI0YmJkYjcwNzA4XkEyXkFqcGdeQXVyNjk1Njg5NTA@._V1_SX300.jpg",
    rating: 4,
    year: 1989,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "93 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 213,
    name: "Look Who's Talking Too",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTdkYjYzYTMtMWM5MS00ODBiLWEwODAtMmVjOWY4YjU2Y2MyXkEyXkFqcGdeQXVyNDE5MTU2MDE@._V1_SX300.jpg",
    rating: 5,
    year: 1990,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "81 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 214,
    name: "Looper",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTg5NTA3NTg4NF5BMl5BanBnXkFtZTcwNTA0NDYzOA@@._V1_SX300.jpg",
    rating: 5,
    year: 2012,
    synopsis:
      "Although this movie flies in the face of 'the same matter can't occupy the same space' rule, it's still a fun and entertaining action/scifi flick.",
    genre: "Scifi",
    subgenre: "Action",
    runtime: "113 min",
    pairs: "a Fountain of Youth.",
    drinkRecipe: "vodka, cucumber, Blue Curaçao.",
    rated: "R",
    picture1: looper1,
    picture2: looper2,
    picture3: looper3,
    tagline: "1000 times worse than Time Cop!",
    taglineWriter: "Jean-Claude Van Johnson",
    bulletOne: "A prosthetic nose.",
    bulletTwo: "Seeing if you could beat up your younger self.",
    cast: "Directed by RIAN JOHNSON starring JOSEPH GORDON-LEVITT BRUCE WILLIS EMILY BLUNT and PAUL DANO",
  },
  {
    id: 215,
    name: "In Time",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjA3NzI1ODc1MV5BMl5BanBnXkFtZTcwMzI5NjQwNg@@._V1_SX300.jpg",
    rating: 5,
    year: 2011,
    synopsis:
      "A guy from the ghetto struggles not to bang his superhot mom who's the same age as him.",
    genre: "Scifi",
    subgenre: "Action",
    runtime: "109 min",
    pairs: "Good Times.",
    drinkRecipe:
      "Vodka, peach schnapps, cranberry juice, orange juice, splash of lime",
    rated: "PG-13",
    picture1: inTime1,
    picture2: inTime2,
    picture3: inTime3,
    tagline: "IT'LL CLEAN YOUR CLOCK!",
    taglineWriter: "Big Ben",
    bulletOne: "Drinking yourself to death as soon as you get some money.",
    bulletTwo: "Time as money would finally put an end to the habitually late.",
    cast: "Directed by ANDREW NICCOL starring JUSTIN TIMBERLAKE AMANDA SEYFRIED CILLIAN MURPHY and OLIVIA WILDE",
  },
  {
    id: 216,
    name: "Diary of the Dead",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDg5MjAxMjA2Nl5BMl5BanBnXkFtZTcwMjE1OTc1MQ@@._V1_SX300.jpg",
    rating: 4,
    year: 2007,
    synopsis:
      "In the most 2007 crossover ever, this movie meets at the intersection of zombies and found footage. ",
    genre: "Horror",
    subgenre: "",
    runtime: "95 min",
    pairs: "",
    noPairs: "a flask of whiskey. Too easy. ",
    rated: "R",
    picture1: diaryOfTheDead1,
    picture2: diaryOfTheDead3,
    picture3: diaryOfTheDead2,
    tagline: "DEAR DIARY, MORE BRAINS!",
    taglineWriter: "Zom Bee",
    bulletOne: "Hawkeye caught on candid camera.",
    bulletTwo: "Being bothered while you're very clearly committing larceny.",
    cast: "Directed by GEORGE A. ROMERO starring MICHELLE MORGAN JOSHUA CLOSE SHAWN ROBERTS and TODD SCHROEDER",
  },
  {
    id: 217,
    name: "Return of the Living Dead",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzY0ZjJlNmMtMGU3NC00Yjk3LTk0N2ItMDNlMDVhZjA4OTFmXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    year: 1985,
    synopsis:
      "This is a terrific zombie comedy. You'll find yourself craving more brains and if you laugh hard enough, be sure to send for more paramedics. ",
    genre: "Cult Classic",
    subgenre: "Horror",
    runtime: "95 min",
    pairs: "a Zombie-Brain.",
    drinkRecipe: "Peach schnapps, Creme de Menthe, Bailey's, Grenadine",
    rated: "R",
    picture1: returnOfDead1,
    picture2: returnOfDead2,
    picture3: returnOfDead3,
    tagline: "RETURN TO THE VIDEO STORE!",
    taglineWriter: "Gorge Romeo",
    bulletOne: "Visiting a cemetary to respect the still dead.",
    bulletTwo:
      "Awful/awesome lines like 'Do you like sex with death?' 'Yeah, so fuck off and die'.",
    cast: "Directed by DAN O'BANNON starring CLU GULAGER JAMES KAREN DON CALFA and THOM MATTHEWS",
  },
  {
    id: 218,
    name: "12 Monkeys",
    poster:
      "https://m.media-amazon.com/images/M/MV5BN2Y2OWU4MWMtNmIyMy00YzMyLWI0Y2ItMTcyZDc3MTdmZDU4XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 2,
    year: 1995,
    synopsis:
      "Brad Pitt and Bruce Willis go insane trying to stop the next plague in this impossible to follow time travel drama.",
    genre: "Scifi",
    subgenre: "Thriller",
    runtime: "129 min",
    pairs: "",
    noPairs: "Golden Monkey.",
    rated: "R",
    picture1: twelveMonkeys1,
    picture2: twelveMonkeys2,
    picture3: twelveMonkeys3,
    tagline: "CONFUSING.",
    taglineWriter: "David Lynch",
    bulletOne: "To be honest, we just don't like this movie.",
    bulletTwo: "Mind-bendy stuff is not our cup of tea.",
    cast: "Directed by TERRY GILLIAM starring BRUCE WILLIS BRAD PITT MADELEINE STOWE and JON SEDA",
  },
  {
    id: 219,
    name: "Armageddon",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGM0NzE2YjgtZGQ4YS00MmY3LTg4ZDMtYjUwNTNiNTJhNTQ5XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    year: 1998,
    synopsis:
      "Well drillers fly into space to nuke an asteroid. With a plot this crazy, you don't wanna close your eyes or fall asleep. Sorry, had to. ",
    genre: "Scifi",
    subgenre: "Action",
    runtime: "151 min",
    pairs: "an Oil Drum.",
    drinkRecipe: "Kahlúa, Cannon Blast, can of Coke.",
    rated: "PG-13",
    picture1: armageddon1,
    picture2: armageddon2,
    picture3: armageddon3,
    tagline: "IT MAKES A DEEP IMPACT!",
    taglineWriter: "Morgan Freeman",
    bulletOne:
      "It's gotta be strange being buddies with a guy whose daughter you're banging.",
    bulletTwo:
      "It's ok if you haven't seen this yet - it's not the end of the world.",
    cast: "Directed by MICHAEL BAY starring BRUCE WILLIS BILLY BOB THORNTON BEN AFFLECK and LIV TYLER",
  },
  {
    id: 220,
    name: "Deep Impact",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTUwMTY1YmMtN2U5NC00YjkzLTg0YWQtZmEwNTEzZjdkNzQ2XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    year: 1998,
    synopsis:
      "Occasionally, two nearly identical movies are released at the same time and there's always a clear-cut better one. Deep Impact is the worse Armageddon.",
    genre: "Scifi",
    subgenre: "",
    runtime: "120 min",
    pairs: "a Depth Charge.",
    drinkRecipe: "drop a shot of Baileys/Jameson in a Guinness.",
    rated: "PG-13",
    picture1: deepImpact1,
    picture2: deepImpact2,
    picture3: deepImpact3,
    tagline: "I wouldn't have let the meteor hit",
    taglineWriter: "Bruce Drillis",
    bulletOne:
      "Other duplicate movies: Babe vs Gordy. Happy Feet vs. Surf's Up.",
    bulletTwo:
      "A Dennis-Reynolds-approved plan for getting women to your bunker.",
    cast: "Directed by MIMI LEDER starring ROBERT DUVALL TEA LEONI ELIJAH WOOD and MORGAN FREEMAN",
  },
  {
    id: 221,
    name: "Speed",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjc0MjYyN2EtZGRhMy00NzJiLWI2Y2QtYzhiYTU3NzAxNzg4XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1994,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "116 min",
    pairs: "",
    rated: "R",
    picture1: speed1,
    picture2: speed2,
    picture3: speed3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "The most fuel-efficient city bus ever.",
    bulletTwo: "",
    cast: "Directed by JAN De BONT starring KEANU REEVES DENNIS HOPPER SANDRA BULLOCK and JEFF DANIELS",
  },
  {
    id: 222,
    name: "Ted",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQ1OTU0ODcxMV5BMl5BanBnXkFtZTcwOTMxNTUwOA@@._V1_SX300.jpg",
    rating: 5,
    year: 2012,
    synopsis:
      "Marky Mark is torn between keeping a relationship with his girlfriend or his best friend.  ",
    genre: "Comedy",
    subgenre: "",
    runtime: "106 min",
    pairs: "",
    noPairs: "Boston Lager.",
    rated: "R",
    picture1: ted1,
    picture2: ted2,
    picture3: ted3,
    tagline: "SEE! BEARS NEVER HARM HUMANS!",
    taglineWriter: "Grizzly Man",
    bulletOne: "Give it up for Wahlburg memorizing all those trashy names.",
    bulletTwo: "Ripping shots and doing drugs with your childhood hero.",
    cast: "Directed by SETH MACFARLANE starring MARK WAHLBERG SETH MACFARLANE MILA KUNIS and GIOVANNI RIBISI",
  },
  {
    id: 223,
    name: "Ted 2",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjEwMDg3MDk1NF5BMl5BanBnXkFtZTgwNjYyODA1NTE@._V1_SX300.jpg",
    rating: 5,
    year: 2015,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "115 min",
    pairs: "",
    rated: "R",
    picture1: ted21,
    picture2: ted22,
    picture3: ted23,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by SETH MACFARLANE starring MARK WAHLBERG SETH MACFARLANE AMANDA SEYFRIED and GIOVANNI RIBISI",
  },
  {
    id: 224,
    name: "Knight and Day",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTM0Mzg0MzI3Ml5BMl5BanBnXkFtZTcwNjIyNzk1Mw@@._V1_SX300.jpg",
    rating: 4,
    year: 2010,
    synopsis:
      "While not having anything to do with 'Knight' or 'Day', a secret agent meets an amatuer vintage car mechanic and in an effort to win her affection he 𝙙𝙧𝙪𝙜𝙨 her along from adventure to adventure.",
    genre: "Action",
    subgenre: "Comedy",
    runtime: "109 min",
    pairs: "me / without me.",
    drinkRecipe: "Jom Beam and Brandy.",
    noPairs: "",
    rated: "PG-13",
    picture1: knightday1,
    picture2: knightday2,
    picture3: knightday3,
    tagline: "THANKS FOR LETTING ME BE IN THIS!",
    taglineWriter: "Paul Dano",
    bulletOne: "Running with the bulls in the wrong city.",
    bulletTwo: "Tom Cruise's legit stunt driving and park-job.",
    cast: "Directed by JAMES MANGOLD starring TOM CRUISE CAMERON DIAZ PETER SARSGAARD and PAUL DANO",
  },
  {
    id: 225,
    name: "Sin City",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODZmYjMwNzEtNzVhNC00ZTRmLTk2M2UtNzE1MTQ2ZDAxNjc2XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 2005,
    synopsis: "",
    genre: "Crime",
    subgenre: "Thriller",
    runtime: "124 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 226,
    name: "Unbreakable",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDIwMjAxNzktNmEzYS00ZDY5LWEyZjktM2Y0MmUzZDkyYmZkXkEyXkFqcGdeQXVyNTA4NzY1MzY@._V1_SX300.jpg",
    rating: 4,
    year: 2000,
    synopsis: "",
    genre: "Superhero",
    subgenre: "Thriller",
    runtime: "106 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 227,
    name: "The Sixth Sense",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWM4NTFhYjctNzUyNi00NGMwLTk3NTYtMDIyNTZmMzRlYmQyXkEyXkFqcGdeQXVyMTAwMzUyOTc@._V1_SX300.jpg",
    rating: 5,
    year: 1999,
    synopsis:
      "A grown man is remarkably chill about being completely ignored by everyone except a child. ",
    genre: "Horror",
    subgenre: "Mystery, Thriller",
    runtime: "107 min",
    pairs: "",
    noPairs: "six shots of Quiet Man whiskey.",
    rated: "PG-13",
    picture1: sixthSense1,
    picture2: sixthSense2,
    picture3: sixthSense3,
    tagline: "Should have noticed the Signs",
    taglineWriter: "Mel Gibson",
    bulletOne: "Forrest Gump Jr. reunites with Jenny.",
    bulletTwo:
      "Aside from The Usual Suspects, this has one of the most famous twists in movie history.",
    cast: "Directed by M. NIGHT SHYAMALAN starring BRUCE WILLIS HALEY JOEL OSMENT TONI COLLETTE and OLIVIA WILLIAMS",
  },
  {
    id: 228,
    name: "Signs",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDUwMDUyMDAyNF5BMl5BanBnXkFtZTYwMDQ3NzM3._V1_SX300.jpg",
    rating: 4,
    year: 2002,
    synopsis:
      "Aliens smart enough to travel through billions of galaxies are dumb enough to inhabit the one planet made entirely of the element that kills them.",
    genre: "Horror",
    subgenre: "",
    runtime: "106 min",
    pairs: "Stale Water.",
    drinkRecipe: "Room temp vodka and Fresca.",
    rated: "PG-13",
    picture1: signs1,
    picture2: signs2,
    picture3: signs3,
    tagline: "Should've sensed something was off",
    taglineWriter: "Bruce Willis",
    bulletOne: "One of Shyamalama-dingdongs good ones.",
    bulletTwo:
      "Thinking 'how could a vastly superior creature allow itself to be bludgeoned by a caveman?'",
    cast: "Directed by M. NIGHT SHYAMALAN starring MEL GIBSON JOAQUIN PHOENIX RORY CULKIN and ABIGAIL BRESLIN",
  },
  {
    id: 229,
    name: "Blue Streak",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTJkYTBlZjktYmYyYi00ZWQ0LTgyMWYtMjQ5Yzg1Y2M3YzE5XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1999,
    synopsis:
      "Martin Lawrence plays a Federale who struggles to get a cat out of his pants. ",
    genre: "Comedy",
    subgenre: "Action",
    runtime: "93 min",
    pairs: "a Diamond Blue.",
    drinkRecipe: "De violette, gin, champagne, lemon juice.",
    rated: "PG-13",
    picture1: blueStreak1,
    picture2: blueStreak2,
    picture3: blueStreak3,
    tagline: "A REAL GEM!",
    taglineWriter: "Adam Sandler",
    bulletOne: "Dave Chapelle in his prime.",
    bulletTwo: "Beating up criminals is the only way to crack a case.",
    cast: "Directed by LES MAYFIELD starring MARTIN LAWRENCE DAVE CHAPPELLE LUKE WILSON and PETER GREENE",
  },
  {
    id: 230,
    name: "Every Which Way but Loose",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWZkMmE4OTItMzE5MS00YTNhLWIxZTYtODE0ZTYxNDU2ZTVlXkEyXkFqcGdeQXVyMjUzOTY1NTc@._V1_SX300.jpg",
    rating: 5,
    year: 1978,
    synopsis:
      "A tale as old as time: a trucker/bare-knuckle brawler drives from town to town with his pet orangutan to fight people at honky-tonks.",
    genre: "Comedy",
    subgenre: "Action",
    runtime: "114 min",
    pairs: "",
    noPairs: "Miller High Life.",
    rated: "PG",
    picture1: loose1,
    picture2: loose2,
    picture3: loose3,
    tagline: "Buy your tickets and be first to check in!",
    taglineWriter: "Dunston",
    bulletOne: "Most incompetent, bumbling biker gang ever.",
    bulletTwo:
      "Clint feeds Clyde so much beer, it's probably the origin of the phrase 'drunk as a monkey'.",
    cast: "Directed by JAMES FARGO starring CLINT EASTWOOD SONDRA LOCKE GEOFFREY LEWIS and MANIS",
  },
  {
    id: 231,
    name: "Any Which Way You Can",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTUzYmIxM2YtYjBkZC00M2FiLTllMGQtOTQ5N2UzMzc2YTAzL2ltYWdlXkEyXkFqcGdeQXVyNjc1NTYyMjg@._V1_SX300.jpg",
    rating: 5,
    year: 1980,
    synopsis:
      "Philo Beddoe is back at it - only this time his opponent is an equally jacked and tough old man.",
    genre: "Comedy",
    subgenre: "Action",
    runtime: "116 min",
    pairs: "an Orangu-Tang.",
    drinkRecipe: "Tang and everclear",
    rated: "PG",
    picture1: can1,
    picture2: can2,
    picture3: can3,
    tagline: "More satisfying than a knuckle sandwich",
    taglineWriter: "Hannibal Lecter",
    bulletOne: "They're all lucky Clyde doesn't jump in.",
    bulletTwo:
      "Helping the wife's singing career by featuring several full-length music videos.",
    cast: "Directed by BUDDY VAN HORN starring CLINT EASTWOOD SONDRA LOCKE GEOFFREY LEWIS and WILLIAM SMITH",
  },
  {
    id: 232,
    name: "Double Impact",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjcyYjg2OGMtOTJiYS00ZjE4LThkZjEtMDEyNmRkMDNhYzliXkEyXkFqcGdeQXVyMjUzOTY1NTc@._V1_SX300.jpg",
    rating: 5,
    year: 1991,
    synopsis:
      "It's like The Parent Trap except Lindsay Lohan is Jean Claude Van Damme and instead of reuniting the parents, the parents get murdered.",
    genre: "Action",
    subgenre: "",
    runtime: "110 min",
    pairs: "",
    noPairs: "the liquor of your choice, but make it a double.",
    rated: "R",
    picture1: doubleImpact1,
    picture2: doubleImpact2,
    picture3: doubleImpact3,
    tagline: "I guess you could say it's a Double Team",
    taglineWriter: "Dennis Rodman",
    bulletOne: "Bolo Yeung's gnarly scar and nasty eye.",
    bulletTwo:
      "JCVD teaching ballet for the sole purpose of hitting on babes (in the worlds only aerobics studio/dojo). ",
    cast: "Directed by SHELDON LETTICH starring JEAN CLAUDE VAN DAMME GEOFFREY LEWIS ALONNA SHAW and BOLO YEUNG",
  },
  {
    id: 233,
    name: "Street Fighter",
    poster:
      "https://m.media-amazon.com/images/M/MV5BY2JhYjUzYzAtMWFhMi00YzIyLTllZGQtMTAzNThkNTJhNTBmXkEyXkFqcGdeQXVyNDc2NjEyMw@@._V1_SX300.jpg",
    rating: 5,
    year: 1994,
    synopsis:
      "Van Damme flaunts his impeccable Yankee accent to play Col. Guile of the US Air Force, a man out to kill villainous dictator M. Bison.",
    genre: "Action",
    subgenre: "Cult Classic",
    runtime: "102 min",
    pairs: "a Karate Kick Martini.",
    drinkRecipe:
      "Light rum, dark rum, lime juice, orgeat syrup, orange liqueur",
    rated: "PG-13",
    picture1: streetFighter1,
    picture2: streetFighter2,
    picture3: streetFighter3,
    tagline: "HE'S SO AMERICAN!",
    taglineWriter: "Tommy Wiseau",
    bulletOne:
      "Zangief's gotta be the most accurate portrayal of any video game character.",
    bulletTwo: "Blanka looking like a ginger retard Hulk.",
    cast: "Directed by STEVEN E. DE SOUZA starring JEAN CLAUDE VAN DAMME RAUL JULIA MING NA-WEN and DAMIAN CHAPA",
  },
  {
    id: 234,
    name: "Real Steel",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjEzMzEzNjg0N15BMl5BanBnXkFtZTcwMzg4NDk0Ng@@._V1_SX300.jpg",
    rating: 4,
    year: 2011,
    synopsis:
      "This movie is essentially Rock 'em Sock 'em Rocky starring Wolverine.",
    genre: "Sports",
    subgenre: "",
    runtime: "127 min",
    pairs: "a Knockout Punch.",
    drinkRecipe: "Fruit punch, Everclear.",
    rated: "PG-13",
    picture1: realSteel1,
    picture2: realSteel3,
    picture3: realSteel2,
    tagline: "The bot's a bum! A ninth-rate pug!",
    taglineWriter: "Mick",
    bulletOne: "Not to be confused with 'Steel' starring Shaquille O'Neal.",
    bulletTwo:
      "Actually a good idea for boxers to fight with bots - less chance of getting punch drunk.",
    cast: "Directed by SHAWN LEVY starring HUGH JACKMAN EVANGELINE LILLY DAKOTA GOYO and ANTHONY MACKIE",
  },
  {
    id: 235,
    name: "The Wolverine",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzg1MDQxMTQ2OF5BMl5BanBnXkFtZTcwMTk3MjAzOQ@@._V1_SX300.jpg",
    rating: 5,
    year: 2013,
    synopsis: "",
    genre: "Superhero",
    subgenre: "Action",
    runtime: "126 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 236,
    name: "Logan",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzc5MTU4N2EtYTkyMi00NjdhLTg3NWEtMTY4OTEyMzJhZTAzXkEyXkFqcGdeQXVyNjc1NTYyMjg@._V1_SX300.jpg",
    rating: 6,
    year: 2017,
    synopsis:
      "Wolverine and the nonagenarian Prof plan to circumnavigate the globe in their beloved Sunseeker. First they must transport a Mexican speciation to the only mute safe place left on Earth: Canada.",
    genre: "Superhero",
    subgenre: "Action",
    runtime: "137 min",
    pairs: "a Budweiser on a boat.",
    drinkRecipe: "skip the adamantium bullet.",
    rated: "R",
    picture1: logan1,
    picture2: logan3,
    picture3: logan2,
    tagline: "WE NEED A NORTHERN BOARDER WALL!",
    taglineWriter: "Huge Jacked-Man",
    bulletOne: "True mutants eat milkless cereal.",
    bulletTwo: "Revealing Bill Gates master plan for food.",
    cast: "Directed by JAMES MANGOLD starring HUGH JACKMAN DAFNE KEEN PATROCK STEWART and BOYD HOLBROOK",
  },
  {
    id: 237,
    name: "X-Men Origins: Wolverine",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZWRhMzdhMzEtZTViNy00YWYyLTgxZmUtMTMwMWM0NTEyMjk3XkEyXkFqcGdeQXVyNTIzOTk5ODM@._V1_SX300.jpg",
    rating: 4,
    year: 2009,
    synopsis:
      "The movie we didn't know we didn't want. How Wolverine became Wolverine.  ",
    genre: "Superhero",
    subgenre: "Action",
    runtime: "107 min",
    pairs: "Wolf's Piss.",
    drinkRecipe: "Vodka, creatine, yellow Gatorade.",
    rated: "PG-13",
    picture1: originsWolverine1,
    picture2: originsWolverine2,
    picture3: originsWolverine3,
    tagline: "HE DOESN'T YELL ENOUGH!",
    taglineWriter: "Sam Kinison",
    bulletOne: "Not the Deadpool you were looking for",
    bulletTwo:
      "Warning: if you are a drug tested athlete, merely watching this movie will make you piss hot for steroids.",
    cast: "Directed by GAVIN HOOD starring HUGH JACKMAN LIEV SCHREIBER RYAN REYNOLDS and DANNY HUSTON",
  },
  {
    id: 238,
    name: "X-Men: First Class",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTg5OTMxNzk4Nl5BMl5BanBnXkFtZTcwOTk1MjAwNQ@@._V1_SX300.jpg",
    rating: 5,
    year: 2011,
    synopsis:
      "Out of the three origin stories, this movie - like Deutsches Bier - is das beste.",
    genre: "Superhero",
    subgenre: "Action",
    runtime: "131 min",
    pairs: "",
    noPairs: "Bitburger.",
    rated: "PG-13",
    picture1: xmenOrigins1,
    picture2: xmenOrigins2,
    picture3: xmenOrigins3,
    tagline: "I'm also part of the Ex-Men!",
    taglineWriter: "Kaitlyn Jenner",
    bulletOne: "Awesome reveal in first scene.",
    bulletTwo:
      "Wishing you had Magneto power, and bringing it up every single time you're stuck in traffic.",
    cast: "Directed by MATTHEW VAUGHN starring JAMES MCAVOY MICHAEL FASSBENDER JENNIFER LAWRENCE and KEVIN BACON",
  },
  {
    id: 239,
    name: "The Disaster Artist",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOGNkMzliMGMtMDI5Ni00OTZkLTgyMTYtNzk5ZTY1NjVhYjVmXkEyXkFqcGdeQXVyNTAzMTY4MDA@._V1_SX300.jpg",
    rating: 4,
    year: 2017,
    synopsis:
      "'The Room' is so goddamn bizarre that this biographical comedy had to be made to explain how it came to be.",
    genre: "Cult Classic",
    subgenre: "Comedy",
    runtime: "104 min",
    pairs: "a Tommy Weiss-O.",
    drinkRecipe: "Southern Comfort and Weissbeer.",
    rated: "R",
    picture1: disasterArtist1,
    picture2: disasterArtist2,
    picture3: disasterArtist3,
    tagline: "IT WILL TEAR YOU APART!",
    taglineWriter: "Lisa",
    bulletOne: "Financed by and starring a 100% American non-villain.",
    bulletTwo:
      "Kudos to Franco for the picture perfect reproductions of scenes. ",
    cast: "Directed by JAMES FRANCO starring JAMES FRANCO DAVE FRANCO ARI GRAYNOR and SETH ROGEN",
  },
  {
    id: 240,
    name: "Funny People",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNWU0ZDllZWEtNWI4ZC00YjIzLTk3YjMtZmE0MmFiNzg4MmRlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    year: 2010,
    synopsis:
      "Broke standup comedians get paid to write for megastar Adam Sandler (fiction), who largely makes unwatchable garbage (non-fiction). ",
    genre: "Comedy",
    subgenre: "",
    runtime: "146 min",
    pairs: "a Merman.",
    drinkRecipe: "Coconut rum, Blue Curaçao, pineapple juice.",
    rated: "R",
    picture1: funnyPeople1,
    picture2: funnyPeople2,
    picture3: funnyPeople3,
    tagline: "FUNNY HOW!?",
    taglineWriter: "Joe Pesci",
    bulletOne:
      "At least Sandler can make fun of himself. The fake trailers are great.",
    bulletTwo: "Pretty accurate portayal of starting out as a comedian.",
    cast: "Directed by JUDD APATOW starring ADAM SANDLER SETH ROGEN JONAH HILL and ERIC BANA",
  },
  {
    id: 241,
    name: "Minority Report",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTI3YzZjZjEtMDdjOC00OWVjLTk0YmYtYzI2MGMwZjFiMzBlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    year: 2002,
    synopsis:
      "A new government agency can track and prevent murders before they happen using a straight-foward, user-friendly, computer interface to decode the jumbled up dreams of three twins with fetal neuroin syndrome.",
    genre: "Action",
    subgenre: "Thriller",
    runtime: "92 min",
    pairs: "PreCog Water.",
    drinkRecipe: "Coconut smoothie, bacardi silver, monster ultra.",
    noPairs: "",
    rated: "R",
    picture1: minorityreport1,
    picture2: minorityreport2,
    picture3: minorityreport3,
    tagline: "Yeah, I'm gonna need those minority reports ASAP",
    taglineWriter: "Bill Lumberg",
    bulletOne: "The plant lady is just a worse version of the Oracle.",
    bulletTwo: "Being creepily attracted to your slimey co-worker.",
    cast: "Directed by STEVEN SPIELBERG starring TOM CRUISE COLIN FARRELL SAMANTHA MORTON and MAX VON SYDOW",
  },
  {
    id: 242,
    name: "Army of Darkness",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODcyYzM4YTAtNGM5MS00NjU4LTk2Y2ItZjI5NjkzZTk0MmQ1XkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 5,
    year: 1992,
    synopsis: "",
    genre: "Cult Classic",
    subgenre: "Horror",
    runtime: "81 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 243,
    name: "The Patriot",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTkzMjE4ODU0MV5BMl5BanBnXkFtZTYwNTI2OTk2._V1_SX300.jpg",
    rating: 5,
    year: 2000,
    synopsis:
      "Historical fact be damned, this movie is perfect for getting that red, white, and blue blood pumping! Get lit and pledge allegiance to the flag.",
    genre: "War",
    subgenre: "Action",
    runtime: "165 min",
    pairs: "",
    noPairs: "Jack Daniels chased by Sam Adams Boston Lager",
    rated: "R",
    picture1: patriot1,
    picture2: patriot2,
    picture3: patriot3,
    tagline: "I've never felt more American",
    taglineWriter: "Aunt Samantha",
    bulletOne: "Not yelling, 'heads up' under cannon fire",
    bulletTwo: "Best if watched repeatedly on the Fourth of July",
    cast: "Directed by ROLAND EMMERICH starring MEL GIBSON HEATH LEDGER JASON ISAACS and JOELY RICHARDSON",
  },
  {
    id: 244,
    name: "Clerks",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzE1Njk0NmItNDhlMC00ZmFlLWI4ZTUtYTY4ZjgzNjkyMTU1XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1994,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "92 min",
    pairs: "a Jersey Turnpike.",
    drinkRecipe: "Wring out the bar towel into a shot glass. Drink Up!",
    rated: "R",
    picture1: clerks1,
    picture2: clerks2,
    picture3: clerks3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by KEVIN SMITH starring BRIAN O'HALLORAN JEFF ANDERSON JASON MEWES and KEVIN SMITH",
  },
  {
    id: 245,
    name: "Clerks 2",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODg1MzY2NDY0OF5BMl5BanBnXkFtZTcwNDgyMzQzMQ@@._V1_SX300.jpg",
    rating: 5,
    year: 2006,
    synopsis:
      "Dante and Randall trade life at the convenience and video store for a fast food joint. The location may have changed, but the asshole customers remain the same. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "97 min",
    pairs: "a Listerfiend.",
    drinkRecipe: "Blue Curaçao, pineapple juice, silver tequila.",
    rated: "R",
    picture1: clerks21,
    picture2: clerks22,
    picture3: clerks23,
    tagline: "YOU SHALL NOT PASS!",
    taglineWriter: "Pillow Pants",
    bulletOne: "Making fun of Lord of the Rings.",
    bulletTwo: "Bizarre dance sequence straight out of Mac and Me.",
    cast: "Directed by KEVIN SMITH starring BRIAN O'HALLORAN JEFF ANDERSON JASON MEWES and RASARIO DAWSON",
  },
  {
    id: 246,
    name: "Monty Python and the Holy Grail",
    poster:
      "https://m.media-amazon.com/images/M/MV5BN2IyNTE4YzUtZWU0Mi00MGIwLTgyMmQtMzQ4YzQxYWNlYWE2XkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 5,
    year: 1975,
    synopsis:
      "The gospel and source of every obscure quote uttered by the band geeks in your high school.",
    genre: "Comedy",
    subgenre: "",
    runtime: "91 min",
    pairs: "a chalice of mead.",
    rated: "PG",
    picture1: montyPython1,
    picture2: montyPython2,
    picture3: montyPython3,
    tagline: "HEAVY LAUGHS CARRY THE FILM",
    taglineWriter: "African Swallow",
    bulletOne: "Dancing to music even when chained up.",
    bulletTwo:
      "Be thankful we don't live in such sad times where passing ruffians say 'ni' at will to old ladies.",
    cast: "Directed by TERRY GILLIAM starring GRAHAM CHAPMAN JOHN CLEESE ERIC IDLE and TERRY GILLIAM",
  },
  {
    id: 247,
    name: "Blazing Saddles",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGZmMWE1MDYtNzAyNC00MDMzLTgzZjQtNTQ5NjYzN2E4MzkzXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 5,
    year: 1974,
    synopsis:
      "A black man (gasp!) is appointed sheriff of an old west town. Hilarity ensues as he struggles to win over the townsfolk. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "93 min",
    pairs: "",
    noPairs: "Kentucky bourbon - neat.",
    rated: "R",
    picture1: blazingSaddles1,
    picture2: blazingSaddles2,
    picture3: blazingSaddles3,
    tagline: "PULLS YOU IN LIKE QUICKSAND!",
    taglineWriter: "Bart",
    bulletOne: "This movie has it all, from swears to farts to horse punching.",
    bulletTwo:
      "Proving racism is ridiculous in the most literal sense - by ridiculing it.",
    cast: "Directed by MEL BROOKS starring CLEAVON LITTLE GENE WILDER HARVEY KORMAN and MADELINE KAHN",
  },
  {
    id: 248,
    name: "Willy Wonka & The Chocolate Factory",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTllNDU0ZTItYTYxMC00OTI4LThlNDAtZjNiNzdhMWZiYjNmXkEyXkFqcGdeQXVyNzY1NDgwNjQ@._V1_SX300.jpg",
    rating: 4,
    year: 1971,
    synopsis:
      "An eccentric factory owner murders children with the help of horrible orange monsters. ",
    genre: "Adventure",
    subgenre: "Musical",
    runtime: "100 min",
    pairs: "Childs Blood.",
    drinkRecipe: "Hot Tamales soaked in vodka.",
    rated: "G",
    picture1: willyWonka1,
    picture2: willyWonka2,
    picture3: willyWonka3,
    tagline: "OH YES, JAWS WILL BE BROKEN",
    taglineWriter: "Wonka",
    bulletOne: "Pretending you can't walk - for years.",
    bulletTwo:
      "Killing the kids is somehow less disturbing than Charlie's grandparents sleeping situation.",
    cast: "Directed by MEL STUART starring GENE WILDER JACK ALBERTSON PETER OSTRUM and JULIE DAWN COLE",
  },
  {
    id: 249,
    name: "Escape from Alcatraz",
    poster:
      "https://m.media-amazon.com/images/I/71tr4kG03vL._AC_UY436_FMwebp_QL65_.jpg",
    rating: 4,
    year: 1979,
    synopsis:
      "Clint Eastwood plays his usual iron-tough self, and even has a prophetic line that perfectly summarizes daily life in the 2020's: 'There's always the possibility that some asshole will be offended. Isn't there?'",
    genre: "Crime",
    subgenre: "Thriller",
    runtime: "112 min",
    pairs: "",
    noPairs: "pruno or any bagged wine.",
    rated: "PG",
    picture1: alcatraz1,
    picture2: alcatraz2,
    picture3: alcatraz3,
    tagline: "That was a Great Escape!",
    taglineWriter: "Steve McQueen",
    bulletOne: "Not knowing if the guys lived or died. ",
    bulletTwo:
      "Pulling the ol' Ferris Bueller 'fake body under the sheet' trick. ",
    cast: "Directed by DON SIEGEL starring CLINT EASTWOOD PATRICK McGOOHAN ROBERTS BLOSSOM and JACK THIBEAU",
  },
  {
    id: 250,
    name: "The Warriors",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTU2MWRiMTMtYzAzZi00NGYzLTlkMDEtNWQ3MzZlNTJlNzZkL2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyNjc1NTYyMjg@._V1_SX300.jpg",
    rating: 5,
    year: 1979,
    synopsis:
      "The little weasel that Arnie throws off a cliff in Commando murders New York's main gang leader, and the blame falls on The Warriors. They must fight their way home while being hunted by literally every gang in the city. ",
    genre: "Action",
    subgenre: "Cult Classic",
    runtime: "92 min",
    pairs: "",
    noPairs:
      "three bottles of Budweiser. Drink them and ask your friends to come out and play.",
    rated: "R",
    picture1: warriors1,
    picture2: warriors2,
    picture3: warriors3,
    tagline: "WE CAN DIG IT!",
    taglineWriter: "The Mock Turtles",
    bulletOne: "Showing up with no permits or parley.",
    bulletTwo: "Trying to figure out which gang you and your boys would join.",
    cast: "Directed by WALTER HILL starring MICHAEL BECK JAMES REMAR DORSEY WRIGHT and TOM McKITTERICK",
  },
  {
    id: 251,
    name: "Wayne's World",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDAyNDY3MjUtYmJjYS00Zjc5LTlhM2MtNzgzYjNlOWVkZjkzL2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 5,
    year: 1992,
    synopsis:
      "Two buddies with an excellent public access cable program battle executives for creative control of their show. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "92 min",
    pairs: "a tropical fishbowl. ",
    drinkRecipe: "Whatever you do, don't get partied out or honk.",
    noPairs: "",
    rated: "PG-13",
    picture1: waynesWorld1,
    picture2: waynesWorld2,
    picture3: waynesWorld3,
    tagline: "Mille-wahque: Algonguin for 'the good movie'!",
    taglineWriter: "Alice Cooper",
    bulletOne: "Tips for how to prevent spewing and not bowing to sponsors.",
    bulletTwo:
      "Chris Farley having an awful lot of information for a security guard.",
    cast: "Directed by PENELOPE SPHEERIS starring MIKE MEYERS DANA CARVEY TIA CARRERE and ROB LOWE",
  },
  {
    id: 252,
    name: "Wayne's World 2",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTdhODcyMGMtMzg3NC00MTViLWJiZjktZWIyY2NhNWZhYzUxXkEyXkFqcGdeQXVyNjE5MjUyOTM@._V1_SX300.jpg",
    rating: 5,
    year: 1993,
    synopsis:
      "A half-naked indian inspires Wayne and Garth to throw their own version of Woodstock.",
    genre: "Comedy",
    subgenre: "",
    runtime: "95 min",
    pairs: "Red Rope Licorice.",
    drinkRecipe: "Fireball, fruit punch.",
    rated: "PG-13",
    picture1: waynesWorld21,
    picture2: waynesWorld22,
    picture3: waynesWorld23,
    tagline: "See it on a school night, sans parents.",
    taglineWriter: "Garth",
    bulletOne: "Fight scene with legendary James Hong.",
    bulletTwo:
      "Finding a better actor mid scene even though it's a small part.",
    cast: "Directed by STEVEN SURJIK starring MIKE MEYERS DANA CARVEY TIA CARRERE and CHRISTOPHER WALKEN",
  },
  {
    id: 253,
    name: "Dazed and Confused",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTM5MDY5MDQyOV5BMl5BanBnXkFtZTgwMzM3NzMxMDE@._V1_SX300.jpg",
    rating: 5,
    year: 1993,
    synopsis:
      "Alright alright alright. Whether freshman, senior, or years out of high school, everyone is trying to get drunk, stoned, or laid. Sit back with some buds and enjoy the slow ride. ",
    genre: "Comedy",
    subgenre: "Cult Classic",
    runtime: "102 min",
    pairs: "",
    noPairs: "a keg of beer and an eigth of weed.",
    rated: "R",
    picture1: dazedAndConfused1,
    picture2: dazedAndConfused2,
    picture3: dazedAndConfused3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by RICHARD LINKLATER starring JASON LONDON WILEY WIGGINS RORY COCHRANE and BEN AFFLECK",
  },
  {
    id: 254,
    name: "Donnie Darko",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjZlZDlkYTktMmU1My00ZDBiLWFlNjEtYTBhNjVhOTM4ZjJjXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    rating: 4,
    year: 2001,
    synopsis:
      "Remember that awkward loner from high school who wore Hot Topic and scratched emo band names into their binder? This is their favorite movie.  ",
    genre: "Scifi",
    subgenre: "",
    runtime: "113 min",
    pairs: "a White Rabbit.",
    drinkRecipe: "Pepsi and vanilla vodka.",
    rated: "R",
    picture1: donnieDarko1,
    picture2: donnieDarko2,
    picture3: donnieDarko3,
    tagline: "DONNIE DARKO DROPPED A DOUBLE DEUCE",
    taglineWriter: "Dalton",
    bulletOne: "Sweet soundtrack.",
    bulletTwo:
      "Good cameos. Somehow Patrick Swayze and Seth Rogen ended up in this movie.",
    cast: "Directed by RICHARD KELLY starring JAKE GYLLENHAAL JENA MALONE MAGGIE GYLLENHAAL and PATRICK SWAYZE",
  },
  {
    id: 255,
    name: "Misery",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzY0ODQ3MTMxN15BMl5BanBnXkFtZTgwMDkwNTg4NjE@._V1_SX300.jpg",
    rating: 4,
    year: 1990,
    synopsis: "A stage five clinger holds her favorite author hostage.",
    genre: "Horror",
    subgenre: "Thriller",
    runtime: "113 min",
    pairs: "",
    noPairs: "Writers Tears whiskey.",
    rated: "R",
    picture1: misery1,
    picture2: misery2,
    picture3: misery3,
    tagline: "Stay forever and ever and ever!",
    taglineWriter: "Grady Twins",
    bulletOne: "Taking the phrase 'break a leg!' literally.",
    bulletTwo:
      "Wishing D&D would crash outside your cabin so you could force them to write a REAL ending to Game of Thrones. ",
    cast: "Directed by ROB REINER starring JAMES CAAN KATHY BATES RICHARD FARNSWORTH and FRANCES STERNHAGEN",
  },
  {
    id: 256,
    name: "The Dead Zone",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDk2ZGVkY2UtZGE0MS00NTY1LThiZjYtYjQxMTAwMjM5M2VlXkEyXkFqcGdeQXVyNTI4MjkwNjA@._V1_SX300.jpg",
    rating: 4,
    year: 1983,
    synopsis: "",
    genre: "Horror",
    subgenre: "Thriller",
    runtime: "103 min",
    pairs: "",
    rated: "R",
    picture1: deadZone1,
    picture2: deadZone2,
    picture3: deadZone3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by DAVID CRONENBERG starring CHRISTOPHER WALKEN BROOKE ADAMS TOM SKERRITT and HERBERT LOM",
  },
  {
    id: 257,
    name: "The Incredibles",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTY5OTU0OTc2NV5BMl5BanBnXkFtZTcwMzU4MDcyMQ@@._V1_SX300.jpg",
    rating: 5,
    year: 2004,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "115 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 258,
    name: "The Incredibles 2",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTEzNzY0OTg0NTdeQTJeQWpwZ15BbWU4MDU3OTg3MjUz._V1_SX300.jpg",
    rating: 4,
    year: 2018,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "118 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 259,
    name: "Zootopia",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTMyMjEyNzIzMV5BMl5BanBnXkFtZTgwNzIyNjU0NzE@._V1_SX300.jpg",
    rating: 4,
    year: 2016,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "108 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 260,
    name: "Up",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjBkM2RjMzItM2M3Ni00N2NjLWE3NzMtMGY4MzE4MDAzMTRiXkEyXkFqcGdeQXVyNDUzOTQ5MjY@._V1_SX300.jpg",
    rating: 5,
    year: 2009,
    synopsis:
      "A young boy ruins an old man's last chance at a great adventure.",
    genre: "Animation",
    subgenre: "",
    runtime: "96 min",
    pairs: "a Boy Stout.",
    drinkRecipe: "Your favorite stout mixed with your favorite golden ale.",
    rated: "PG",
    picture1: up1,
    picture2: up2,
    picture3: up3,
    tagline: "Wouldn't it be funny if we made everyone cry?",
    taglineWriter: "Pixar",
    bulletOne:
      "Expectation: 'Looks like a fun, lighthearted movie - put it on, babe!'.  ",
    bulletTwo: "Reality: ugly sobbing into a pillow within 5 minutes.",
    cast: "Directed by PETE DOCTER starring ED ASNER JORDAN NAGAI CHRISTOPHER PLUMMER and BOB PETERSON",
  },
  {
    id: 261,
    name: "Lawrence of Arabia",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYWY5ZjhjNGYtZmI2Ny00ODM0LWFkNzgtZmI1YzA2N2MxMzA0XkEyXkFqcGdeQXVyNjUwNzk3NDc@._V1_SX300.jpg",
    rating: 2,
    year: 1962,
    synopsis:
      "Another classic that sucks. Despite being incredibly boring, it won't put you to sleep. Right when you're about to drift off, screams from desert people or ear shattering musical scores will explode through your speakers.",
    genre: "War",
    subgenre: "Adventure",
    runtime: "228 min",
    pairs: "an Oasis. ",
    drinkRecipe: "Blue Curaçao, lime juice, tequila, orange slice.",
    rated: "Approved",
    picture1: arabia1,
    picture2: arabia2,
    picture3: arabia3,
    tagline: "Holy shit that was long",
    taglineWriter: "Nelson Mandela",
    bulletOne:
      "It's about 372 hours long, and most of it is wide shots of the desert. Cool.",
    bulletTwo:
      "Who better to unite desert-faring Arabs than a city-dwelling British man.",
    cast: "Directed by DAVD LEAN starring PETER O'TOOLE ALEC GUINNESS ANTHONY QUINN and JACK HAWKINS",
  },
  {
    id: 262,
    name: "Skyfall",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWZiNjE2OWItMTkwNy00ZWQzLWI0NTgtMWE0NjNiYTljN2Q1XkEyXkFqcGdeQXVyNzAwMjYxMzA@._V1_SX300.jpg",
    rating: 4,
    year: 2012,
    synopsis: "During retirement, James Bond remodels his childhood home.",
    genre: "Action",
    subgenre: "Adventure",
    runtime: "143 min",
    pairs: "an MI7.",
    drinkRecipe: "Kidding. Shake a dry gin Martini, of course.",
    rated: "PG-13",
    picture1: skyfall1,
    picture2: skyfall2,
    picture3: skyfall3,
    tagline: "NOT WHAT I EXPECTED!",
    taglineWriter: "Little Chicken",
    bulletOne: "Ripped training montage.",
    bulletTwo: "Classic Bond gadgets and weapons.",
    cast: "Directed by SAM MENDES starring DANIEL CRAIG  JAVIER BARDEM  NAOMIE HARRIS and DAME JUDI DENCH",
  },
  {
    id: 263,
    name: "The Jungle Book",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTc3NTUzNTI4MV5BMl5BanBnXkFtZTgwNjU0NjU5NzE@._V1_SX300.jpg",
    rating: 4,
    year: 2016,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "106 min",
    pairs: "jungle juice.",
    rated: "PG",
    picture1: jungleBook1,
    picture2: jungleBook2,
    picture3: jungleBook3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by JON FAVREAU starring NEEL SETHI BILL MURRAY BEN KINGSLEY and IDRIS ELBA",
  },
  {
    id: 264,
    name: "The LEGO Movie",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTg4MDk1ODExN15BMl5BanBnXkFtZTgwNzIyNjg3MDE@._V1_SX300.jpg",
    rating: 4,
    year: 2014,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "100 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 265,
    name: "Crocodile Dundee",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjNlMTQ1MzQtMjNjNS00MWY4LTgxZTgtM2M5NThkMDg4N2ViXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1986,
    synopsis:
      "A man as leathery as his character's namesake travels to New York to teach Americans about knives.",
    genre: "Comedy",
    subgenre: "Action",
    runtime: "97 min",
    pairs: "a Manhattan.",
    drinkRecipe: "Bourbon, Sweet Vermouth, Angostura bitters, orange bitters.",
    rated: "PG-13",
    picture1: dundee1,
    picture2: dundee2,
    picture3: dundee3,
    tagline: "A COMPLETE RIPOFF OF MY STORY!",
    taglineWriter: "Alligator Andy",
    bulletOne: "Getting freebies from hookers.",
    bulletTwo: "Using a limo antenna as a boomerang.",
    cast: "Directed by PETER FAIMAN starring PAUL HOGAN LINDA KOZLOWKSKI JOHN MEILLON and STEVE RACKMAN",
  },
  {
    id: 266,
    name: "Crocodile Dundee II",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzkxYTkxZDItYTI2YS00MDdkLWE3ODctY2YzYWQxYjJjYTU0XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1988,
    synopsis:
      "Mick Dundee battles a Pablo Escobar wannabe in New York and the Outback. When it comes to getting out alive, his chances are better than average. ",
    genre: "Comedy",
    subgenre: "Action",
    runtime: "108 min",
    pairs: "",
    noPairs: "unhealthy amounts of Fosters.",
    rated: "PG-13",
    picture1: dundee21,
    picture2: dundee22,
    picture3: dundee23,
    tagline: "A REAL WILD ONE!",
    taglineWriter: "Iggy Pop",
    bulletOne: "Confusing Mick for Clint Eastwood.",
    bulletTwo:
      "Seeing what a Donk was before the meaning changed to 'a huge ass'.",
    cast: "Directed by JOHN CORNELL starring PAUL HOGAN LINDA KOZLOWKSKI JOHN MEILLON and HECHTER UBARRY",
  },
  {
    id: 267,
    name: "Moana",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjI4MzU5NTExNF5BMl5BanBnXkFtZTgwNzY1MTEwMDI@._V1_SX300.jpg",
    rating: 5,
    year: 2016,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "107 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 268,
    name: "Groundhog Day",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZWIxNzM5YzQtY2FmMS00Yjc3LWI1ZjUtNGVjMjMzZTIxZTIxXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 5,
    year: 1993,
    synopsis:
      "Could you imagine repeating the same day over and over again? Experiencing identical events day after day, hour after hour? Then put your little hand in mine and let's do it. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "101 min",
    pairs: "",
    noPairs: "sweet vermouth on the rocks with a twist.",
    rated: "PG",
    picture1: groundhogDay1,
    picture2: groundhogDay2,
    picture3: groundhogDay3,
    tagline: "YOU'LL WATCH AGAIN AND AGAIN!",
    taglineWriter: "Loop Willis",
    bulletOne:
      "Could you imagine repeating the same day over and over again? Experiencing identical events day after day, hour after hour?",
    bulletTwo: "Punching Ned Ryerson.",
    cast: "Directed by HAROLD RAMIS starring BILL MURRAY ANDIE MACDOWELL CHRIS ELLIOTT and BRIAN DOYLE-MURRAY",
  },
  {
    id: 269,
    name: "Star Trek",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjE5NDQ5OTE4Ml5BMl5BanBnXkFtZTcwOTE3NDIzMw@@._V1_SX300.jpg",
    rating: 5,
    year: 2009,
    synopsis:
      "You really don't need to know anything about Star Trek to enjoy this movie. That probably drives the hardcore dorks crazy, which only makes it better. ",
    genre: "Scifi",
    subgenre: "Action",
    runtime: "127 min",
    pairs: "",
    noPairs: "a shot of Jack straight up - make it two.",
    rated: "PG-13",
    picture1: starTrek1,
    picture2: starTrek2,
    picture3: starTrek3,
    tagline: "MY BRAAAAKKES!",
    taglineWriter: "Anton Yell-chin",
    bulletOne: "Being Spocked at how good it was.",
    bulletTwo:
      "After watching the movie, it's another classic you can Checkov your list.",
    cast: "Directed by JJ ABRAMS starring CHRIS PINE ZACHARY QUINTO SIMON PEGG and LEONARD NIMOY",
  },
  {
    id: 270,
    name: "Grandma's Boy",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDFiYzQ0NzktMGNkNy00YTQ0LTgxODgtNDViNGIxMTc1NGFjL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    year: 2006,
    synopsis:
      "A stoner has to balance taking care of his grandma with winning back his video game from Bono's brother.",
    genre: "Comedy",
    subgenre: "",
    runtime: "94 min",
    pairs: "a Demonik.",
    drinkRecipe: "THC soda mixed with Vitamin Water.",
    rated: "R",
    picture1: grandmasBoy1,
    picture2: grandmasBoy2,
    picture3: grandmasBoy3,
    tagline: "You can't stop laughing - it feels so good!",
    taglineWriter: "Lara Croft",
    bulletOne: "Making fun of vegans.",
    bulletTwo:
      "Going to the loony bin together with your bros after getting too high.",
    cast: "Directed by NICHOLAUS GOOSSEN starring ALLEN COVERT LINDA CARDELLINI NICK SWARDSON and JOEL DAVID MOORE",
  },
  {
    id: 271,
    name: "Iron Man",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTczNTI2ODUwOF5BMl5BanBnXkFtZTcwMTU0NTIzMw@@._V1_SX300.jpg",
    rating: 5,
    year: 2008,
    synopsis: "",
    genre: "Superhero",
    subgenre: "Action",
    runtime: "126 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 272,
    name: "Blood Diamond",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDMxOGZhNWYtMzRlYy00Mzk5LWJjMjEtNmQ4NDU4M2QxM2UzXkEyXkFqcGdeQXVyNTA4NzY1MzY@._V1_SX300.jpg",
    rating: 5,
    year: 2006,
    synopsis:
      "In yet another movie where Leo can't hang on to a priceless stone, he finally learns he should've just gone to Zales.",
    genre: "Action",
    subgenre: "",
    runtime: "143 min",
    pairs: "",
    noPairs: "Jack Daniel's.",
    rated: "R",
    picture1: bloodDiamond1,
    picture2: bloodDiamond2,
    picture3: bloodDiamond3,
    tagline: "I HATE THIS MOVIE!!!",
    taglineWriter: "Steven Singer",
    bulletOne: "Child soldiers.",
    bulletTwo:
      "Speaking intentionally broken English is a powerful negotiation tool.",
    cast: "Directed by EDWARD ZWICK starring LEONARDO DiCAPRIO DJIMON HOUNSOU JENNIFER CONNELLY and ARNOLD VOSLOO",
  },
  {
    id: 273,
    name: "Music and Lyrics",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDY5MTQwODI3OV5BMl5BanBnXkFtZTcwODM2MjEzMw@@._V1_SX300.jpg",
    rating: 4,
    year: 2007,
    synopsis:
      "An 80's hasbeen pop-icon must write a hit song with some help from the girl who waters his plants.",
    genre: "Romcom",
    subgenre: "Musical",
    runtime: "104 min",
    pairs: "Soda PoP.",
    drinkRecipe: "Sprite and vodka.",
    rated: "PG-13",
    picture1: musicAndLyrics1,
    picture2: musicAndLyrics2,
    picture3: musicAndLyrics3,
    tagline: "Turn the volume up, cause this movie's intended for humans!",
    taglineWriter: "Colin Thompson",
    bulletOne: "Hugh Grant in tight pants showing off his 'pop hip'",
    bulletTwo: "Fantastic originial soundtrack",
    cast: "Directed by MARC LAWRENCE starring HUGH GRANT DREW BARRYMORE SCOTT PORTER and ANDREW WYATT",
  },
  {
    id: 274,
    name: "Going the Distance",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjQ1ODUyMjk5N15BMl5BanBnXkFtZTcwMjAxOTE2Mw@@._V1_SX300.jpg",
    rating: 5,
    year: 2010,
    synopsis:
      "Distance makes the heart grow fonder for Drew Barrymore and Justin Long as lovers living on opposite coasts in this late 2010 rom-com. ",
    genre: "Romcom",
    subgenre: "",
    runtime: "102 min",
    pairs: "a Shirtless Neighbor.",
    drinkRecipe: "Beer mixed with franzia",
    rated: "R",
    picture1: goingTheDistance1,
    picture2: goingTheDistance2,
    picture3: goingTheDistance3,
    tagline: "TALK ABOUT A LAYOVER!",
    taglineWriter: "Sully Sullenberger",
    bulletOne: "Growing a mustache to be sexier for older demographics.",
    bulletTwo: "Popping open your newest bottle of wine to impress a date.",
    cast: "Directed by NANETTE BURSTEIN starring DREW BARRYMORE JUSTIN LONG CHARLIE DAY and JASON SUDEIKIS",
  },
  {
    id: 275,
    name: "Zack and Miri Make a Porno",
    poster:
      "https://m.media-amazon.com/images/M/MV5BY2I1N2Y0ODEtNzZmZS00NzBjLWFkZjEtMTVkOTY1Y2UzZGE1XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 2008,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "101 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 276,
    name: "Tommy Boy",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTMwZGU3MGUtZWE0Ni00YzExLWIyY2MtMmNmMDlmYTdmNzFkXkEyXkFqcGdeQXVyNjExODE1MDc@._V1_SX300.jpg",
    rating: 6,
    year: 1995,
    synopsis:
      "A salesman with a bit of a weight problem saves his town by threatening to blow up a building. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "97 min",
    pairs: "",
    noPairs: "six pack of b.... soda.",
    rated: "PG-13",
    picture1: tommyboy1,
    picture2: tommyboy2,
    picture3: tommyboy3,
    tagline: "HILARIOUS! THIS MOVIE RIPS!",
    taglineWriter: "Fat guy in a little coat",
    bulletOne: "Having an emotional meltdown turn into your biggest strength.",
    bulletTwo:
      "Pro tip for impressing babes: lie about hitting the weight room.",
    cast: "Directed by PETER SEGAL starring CHRIS FARLEY DAVID SPADE ROB LOWE and BO DEREK",
  },
  {
    id: 277,
    name: "True Grit",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODhkZDIzNjgtOTA5ZS00MmMzLWFkNjYtM2Y2MzFjN2FkNjAzL2ltYWdlXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 4,
    year: 2010,
    synopsis: "",
    genre: "Western",
    subgenre: "Action",
    runtime: "110 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 278,
    name: "Hot Tub Time Machine 2",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTU3NzQzMzE0NV5BMl5BanBnXkFtZTgwMDM4MTI0NDE@._V1_SX300.jpg",
    rating: 3,
    year: 2015,
    synopsis:
      "The guys attempt to go back in time but end up in the future - causing all sorts of alternate timelines.",
    genre: "Comedy",
    subgenre: "",
    runtime: "93 min",
    pairs: "Shotgun to the Dick.",
    drinkRecipe:
      "Rip a shot of whiskey, shotgun a beer, rip another shot of whiskey.",
    rated: "R",
    picture1: httm21,
    picture2: httm23,
    picture3: httm22,
    tagline: "Sux cox N' dix!",
    taglineWriter: "Jacuzzi Timelord",
    bulletOne: "Shooting Clark Duke into the corner pocket.",
    bulletTwo:
      "Tough to make a successful sequel when you lose the main character.",
    cast: "Directed by STEVE PINK starring ROB CORDDRY CRAIG ROBINSON CLARK DUKE and ADAM SCOTT",
  },
  {
    id: 297,
    name: "Escape From New York",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTUzMTY0Nzg0MV5BMl5BanBnXkFtZTgwMDU3MzQxMDE@._V1_SX300.jpg",
    rating: 5,
    year: 1981,
    synopsis:
      "A musclebound badass has to rescue the (totally American) president from brigands and murderers inhabiting Manahattan. ",
    genre: "Scifi",
    subgenre: "Cult Classic",
    runtime: "99 min",
    pairs: "a Manhattan.",
    drinkRecipe: "Bourbon, Sweet Vermouth, Angostura bitters, orange bitters.",
    noPairs: "or whiskey straight up.",
    rated: "R",
    picture1: escapeFromNy1,
    picture2: escapeFromNy3,
    picture3: escapeFromNy2,
    tagline: "A complex film. You'll have to mullet over.",
    taglineWriter: "Patrick Swayze",
    bulletOne: "Utopian 1997.",
    bulletTwo:
      "You can beat a guy with an eyepatch OR a mullet OR named 'Snake', but you'll never beat a man with all three.",
    cast: "Directed by JOHN CARPENTER starring KURT RUSSELL  DONALD PLEASANCE  ERNEST BORGNINE and ISAAC HAYES",
  },
  {
    id: 280,
    name: "The Hangover Part II",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTM2MTM4MzY2OV5BMl5BanBnXkFtZTcwNjQ3NzI4NA@@._V1_SX300.jpg",
    rating: 4,
    year: 2011,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "102 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 281,
    name: "The Hangover Part III",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTA0NjE1MzMzODheQTJeQWpwZ15BbWU3MDY4MTQ3Mzk@._V1_SX300.jpg",
    rating: 4,
    year: 2013,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "100 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 282,
    name: "Doctor Strange",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjgwNzAzNjk1Nl5BMl5BanBnXkFtZTgwMzQ2NjI1OTE@._V1_SX300.jpg",
    rating: 4,
    year: 2016,
    synopsis:
      "Benedict Cumberbatch was cast from the title alone. The director thought 'Strange looking? Get me Babadook Crumplesnatch or whatever the fuck his name is'. ",
    genre: "Superhero",
    subgenre: "Action",
    runtime: "115 min",
    pairs: "a Bandersnatch Cummerbund.",
    drinkRecipe: "Absinthe, Midori.",
    rated: "PG-13",
    picture1: doctorStrange1,
    picture2: doctorStrange2,
    picture3: doctorStrange3,
    tagline: "ASTROLOGY MEETS INCEPTION ON ACID.",
    taglineWriter: "Bangladesh Calabash",
    bulletOne: "Good visual effects.",
    bulletTwo: "Tilda 'The Testicle' Swinton.",
    cast: "Directed by SCOTT DERRICKSON starring BENEDICT CUMBERBATCH CHIWETEL EJIOFOR RACHEL MCADAMS and BENEDICT WONG ",
  },
  {
    id: 283,
    name: "Casino Royale",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDI5ZWJhOWItYTlhOC00YWNhLTlkNzctNDU5YTI1M2E1MWZhXkEyXkFqcGdeQXVyNTIzOTk5ODM@._V1_SX300.jpg",
    rating: 4,
    year: 2006,
    synopsis:
      "Sorry, but Bond isn't a supposed to be a shredded bodybuilder that does krav maga and parkour. He's supposed to be a vodka-swilling womanizer who throws telegraphed punches which cause opponents to fall out of respect. ",
    genre: "Action",
    subgenre: "",
    runtime: "144 min",
    pairs: "a Butternut Squash Old Fashioned.",
    drinkRecipe:
      "Squash-infused bourbon, Giffard Banane Du Brasil liquer, maple syrup, walnut bitters.",
    rated: "PG-13",
    picture1: casinoRoyale1,
    picture2: casinoRoyale2,
    picture3: casinoRoyale3,
    tagline: "Whozh that pretty boy panzhy?",
    taglineWriter: "Sean Connery",
    bulletOne: "What if that fat dude won the poker game?",
    bulletTwo:
      "Craig is a little crybaby. Any REAL bond would consider ball torture 'foreplay', then sleep with the torturers wife.",
    cast: "Directed by MARTIN CAMPBELL starring DANIEL CRAIG EVA GREEN JUDI DENCH and MADS MIKKELSEN",
  },
  {
    id: 284,
    name: "No Country for Old Men",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjA5Njk3MjM4OV5BMl5BanBnXkFtZTcwMTc5MTE1MQ@@._V1_SX300.jpg",
    rating: 4,
    year: 2007,
    synopsis: "",
    genre: "Crime",
    subgenre: "Thriller",
    runtime: "122 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "Sweet hair, bro.",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 285,
    name: "Training Day",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDZkMTUxYWEtMDY5NS00ZTA5LTg3MTItNTlkZWE1YWRjYjMwL2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 2001,
    synopsis:
      "King Kong aint got shit on this movie. Watch as Denzel 'Heh' and 'HA's' poor Ethan Hawke to death during the worlds most corrupt training program.",
    genre: "Crime",
    subgenre: "",
    runtime: "122 min",
    pairs: "a Snitch Dogg.",
    drinkRecipe: "gin and juice.",
    rated: "R",
    picture1: trainingDay1,
    picture2: trainingDay3,
    picture3: trainingDay2,
    tagline: "IT'LL PUSH YOUR SHIT IN!",
    taglineWriter: "Tuco Salamanca",
    bulletOne: "Getting high and drunk on the job.",
    bulletTwo: "Full-frontal Eva Mendes.",
    cast: "Directed by ANTOINE FUQUA starring DENZEL WASHINGTON ETHAN HAWKE EVA MENDES and SCOTT GLENN",
  },
  {
    id: 286,
    name: "The Last Boy Scout",
    poster:
      "https://m.media-amazon.com/images/M/MV5BN2QxYjAwNDUtYWIzMC00ZTg3LTg5YjMtOGM4YzAyZmUxMzI2XkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 6,
    year: 1991,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "105 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "Bruce Willis' response to the 'solid build' comment.",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 287,
    name: "Last Action Hero",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjdhOGY1OTktYWJkZC00OGY5LWJhY2QtZmQzZDA2MzY5MmNmXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 6,
    year: 1993,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "130 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 288,
    name: "Men of Honor",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTc1Njk1NTE3NF5BMl5BanBnXkFtZTgwNjAyMzcxMTE@._V1_SX300.jpg",
    rating: 4,
    year: 2000,
    synopsis:
      "Dishonorable men seek to prevent Radio from becoming a Naval diver.",
    genre: "War",
    subgenre: "Drama",
    runtime: "129 min",
    pairs: "Sunken Treasure.",
    drinkRecipe: "Rum, pineapple juice, coconut milk, cherry.",
    rated: "R",
    picture1: menOfHonor1,
    picture2: menOfHonor2,
    picture3: menOfHonor3,
    tagline: "THEY WANT YOU AS A NEW RECRUIT!",
    taglineWriter: "The Village People",
    bulletOne: "Pissing contests so intense that you're willing to die. ",
    bulletTwo: "Learning your lesson for stealing a pot.",
    cast: "Directed by GEORGE TILLMAN JR starring CUBA GOODING JR ROBERT DE NIRO CHARLIZE THERON and MICHAEL RAPAPORT",
  },
  {
    id: 289,
    name: "Cape Fear",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjdhNzZlYzYtYTBlNS00NTJiLWI2ZWUtMDI2YzI2MzM5NjY4XkEyXkFqcGdeQXVyNjc1NTYyMjg@._V1_SX300.jpg",
    rating: 5,
    year: 1991,
    synopsis: "",
    genre: "Crime",
    subgenre: "Thriller",
    runtime: "128 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 290,
    name: "Jurassic Park",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjM2MDgxMDg0Nl5BMl5BanBnXkFtZTgwNTM2OTM5NDE@._V1_SX300.jpg",
    rating: 6,
    year: 1993,
    synopsis: "",
    genre: "Scifi",
    subgenre: "Adventure",
    runtime: "127 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 291,
    name: "The Lost World: Jurassic Park",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDFlMmM4Y2QtNDg1ZS00MWVlLTlmODgtZDdhYjY5YjdhN2M0XkEyXkFqcGdeQXVyNTI4MjkwNjA@._V1_SX300.jpg",
    rating: 5,
    year: 1997,
    synopsis: "",
    genre: "Scifi",
    subgenre: "Adventure",
    runtime: "129 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 292,
    name: "Jurassic Park III",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDMyZGJjOGItYjJkZC00MDVlLWE0Y2YtZGIwMDExYWE3MGQ3XkEyXkFqcGdeQXVyNDYyMDk5MTU@._V1_SX300.jpg",
    rating: 4,
    year: 2001,
    synopsis: "",
    genre: "Scifi",
    subgenre: "Adventure",
    runtime: "92 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 293,
    name: "Who Framed Roger Rabbit",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDhiOTM2OTctODk3Ny00NWI4LThhZDgtNGQ4NjRiYjFkZGQzXkEyXkFqcGdeQXVyMTA0MjU0Ng@@._V1_SX300.jpg",
    rating: 6,
    year: 1988,
    synopsis: "",
    genre: "Animation",
    subgenre: "Comedy",
    runtime: "104 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 294,
    name: "Independence Day",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGQwNDNkMmItYWY1Yy00YTZmLWE5OTAtODU0MGZmMzQ1NDdkXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1996,
    synopsis: "Invading aliens are given a harsh welcome to Erf.",
    genre: "Action",
    subgenre: "Scifi",
    runtime: "145 min",
    pairs: "The Probe.",
    drinkRecipe: "coffee liquer, Kahlúa, coconut milk, cigarettes.",
    rated: "PG-13",
    picture1: indDay1,
    picture2: indDay2,
    picture3: indDay3,
    tagline: "THAT'S WHAT I LIKE!",
    taglineWriter: "Bruno Mars",
    bulletOne: "Nutbag Randy Quaid.",
    bulletTwo:
      "Human computer viruses easily infect alien programming, somehow. ",
    cast: "Directed by ROLAND EMMERICH starring WILL SMITH JEFF GOLDBLUM BILL PULLMAN and RANDY QUAID",
  },

  {
    id: 295,
    name: "The Bourne Identity",
    poster:
      "https://m.media-amazon.com/images/M/MV5BM2JkNGU0ZGMtZjVjNS00NjgyLWEyOWYtZmRmZGQyN2IxZjA2XkEyXkFqcGdeQXVyNTIzOTk5ODM@._V1_SX300.jpg",
    rating: 5,
    year: 2002,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "119 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 296,
    name: "The Bourne Supremacy",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTIyMDFmMmItMWQzYy00MjBiLTg2M2UtM2JiNDRhOWE4NjBhXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 5,
    year: 2004,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "108 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 297,
    name: "The Bourne Ultimatum",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNGNiNmU2YTMtZmU4OS00MjM0LTlmYWUtMjVlYjAzYjE2N2RjXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 5,
    year: 2007,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "115 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 298,
    name: "Eurotrip",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTIxNjcxMDUxN15BMl5BanBnXkFtZTYwNjAxNTM3._V1_SX300.jpg",
    rating: 5,
    year: 2004,
    synopsis:
      "We follow Scotty's misadventures through Europe as he tries to find and bang his German penpal.",
    genre: "Comedy",
    subgenre: "",
    runtime: "92 min",
    pairs: "",
    noPairs: "Absinthe.",
    rated: "R",
    picture1: eurotrip1,
    picture2: eurotrip2,
    picture3: eurotrip3,
    tagline: "EUROPEANS DO EVERYTHING DIFFERENT!",
    taglineWriter: "Kilometres Davis",
    bulletOne: "Matt Damon?",
    bulletTwo:
      "Be careful not to eat plain brownies or make out with your sister while watching this one.",
    cast: "Directed by JEFF SCHAFFER starring SCOTT MECHLOWICZ JACOB PITTS MICHELLE TRACHTENBERG and TRAVIS WESTER",
  },
  {
    id: 299,
    name: "How High",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZWZlNWE3MjctMDJmYS00ZTAyLWFkYmEtMzllNjU0ZDY5ODA2XkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 6,
    year: 2001,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "93 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 300,
    name: "Escape from LA",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOWUzNTRmNTQtNWMwZS00MTg4LThhMDUtYmQ5OGQ5MmNmYzNmXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    year: 1996,
    synopsis:
      "The worlds most recognizable HOT-SHOT is forced by a dictatorial President (actually American this time) to recover a dangerous weapon. Along the way Snake surfs the carpool lane, hang glides past Nakatomi Plaza, and proves that white men Can jump. ",
    genre: "Scifi",
    subgenre: "Action",
    runtime: "101 min",
    pairs: "a Plutoxin.",
    drinkRecipe: "absinthe and red bull.",
    rated: "R",
    picture1: escapeLA1,
    picture2: escapeLA2,
    picture3: escapeLA3,
    tagline: "Snake hang glides better than I hang dong",
    taglineWriter: "Thundergun",
    bulletOne: "LA really IS that bad.",
    bulletTwo:
      "The special effects are worse than the first, but that somehow makes it better.",
    cast: "Directed by JOHN CARPENTER starring KURT RUSSELL STACEY KEACH STEVE BUSCEMI and PAM GRIER",
  },
  {
    id: 301,
    name: "The Meg",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTAxMGRmODYtM2NkYS00ZGRlLWE1MWItYjI1MzIwNjQwN2RiXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    rating: 4,
    year: 2018,
    synopsis: "",
    genre: "Scifi",
    subgenre: "Action",
    runtime: "113 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 302,
    name: "The Simpsons Movie",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTgxMDczMTA5N15BMl5BanBnXkFtZTcwMzk1MzMzMw@@._V1_SX300.jpg",
    rating: 5,
    year: 2007,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Animation",
    runtime: "87 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 303,
    name: "The Transporter",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTk2NDc2MDAxN15BMl5BanBnXkFtZTYwNDc1NDY2._V1_SX300.jpg",
    rating: 6,
    year: 2002,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "92 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 304,
    name: "The Transporter 2",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQ5MDM0MTI2N15BMl5BanBnXkFtZTcwNTM5NDczMw@@._V1_SX300.jpg",
    rating: 5,
    year: 2005,
    synopsis:
      "Make sure you watch 'Transporter One' or you'll be completely lost.",
    genre: "Action",
    subgenre: "",
    runtime: "87 min",
    pairs: "a Golden God.",
    drinkRecipe: "White rum, orange juice, pineapple juice, sprite.",
    rated: "PG-13",
    picture1: transporter21,
    picture2: transporter22,
    picture3: transporter23,
    tagline: "THIS MOVIE'S AWESOME!",
    taglineWriter: "Video store clerk guy",
    bulletOne: "Statham's physique is nothing like the lineup in Predator",
    bulletTwo:
      "It's important to pack on mass. It's all about carbo loading and cultivation. ",
    cast: "Directed by LOUIS LETTERIER starring JASON STATHAM AMBER VALLETTA MATTHEW MODINE and ALESSANDRO GASSMANN",
  },
  {
    id: 305,
    name: "The Transporter 3",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTkxNzg1OTU4M15BMl5BanBnXkFtZTcwMjcyMjI5MQ@@._V1_SX300.jpg",
    rating: 5,
    year: 2008,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "104 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 306,
    name: "Jerry Maguire",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTM0ZWNmZTUtOTVkZS00MTZiLTg3M2QtZjA0Y2RmOWM1NWEyXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 5,
    year: 1996,
    synopsis:
      "A sports agent shows clients the money in this rom-com with Tom who dates a mom.",
    genre: "Sports",
    subgenre: "Romcom",
    runtime: "138 min",
    pairs: "a Cardinal.",
    drinkRecipe: "Vodka, Fruit Punch Gatorade.",
    rated: "R",
    picture1: jerry1,
    picture2: jerry2,
    picture3: jerry3,
    tagline: "The human head actually weighs 10 pounds",
    taglineWriter: "Rain Man",
    bulletOne: "Lispy, fat-headed freak.",
    bulletTwo: "Low key one of the best sports movies out there. ",
    cast: "Directed by CAMERON CROWE starring TOM CRUISE CUBA GOODING JR RENEE ZELLWEGER and JAY MOHR ",
  },
  {
    id: 307,
    name: "Mean Streets",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWNmNGY3ZGMtYWQ3OC00Zjg4LWFiN2EtZjM2MDI1YzRiNjg3XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 3,
    year: 1973,
    synopsis: "Low-level street hustlers try to be big shot mafiosos.",
    genre: "Crime",
    subgenre: "",
    runtime: "112 min",
    pairs: "",
    noPairs: "bottom-shelf whiskey and beer.",
    rated: "R",
    picture1: meanStreets1,
    picture2: meanStreets2,
    picture3: meanStreets3,
    tagline: "If you like this movie, you're a MOOK!",
    taglineWriter: "Minnesota Fats",
    bulletOne:
      "The only thing more obnoxious than the 70's shirt collars are the New York accents.",
    bulletTwo: "Prepare yourself for two hours of 'Eyyy' and 'YO'.",
    cast: "Directed by MARTIN SCORSESE starring ROBERT DE NIRO HARVEY KEITEL DAVID PROVAL and AMY ROBINSON",
  },
  {
    id: 308,
    name: "Warrior",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTk4ODk5MTMyNV5BMl5BanBnXkFtZTcwMDMyNTg0Ng@@._V1_SX300.jpg",
    rating: 4,
    year: 2011,
    synopsis: "",
    genre: "Sports",
    subgenre: "Drama",
    runtime: "140 min",
    pairs: "",
    noPairs: "Yards Brawler.",
    rated: "PG-13",
    picture1: warrior1,
    picture2: warrior3,
    picture3: warrior2,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by GAVIN O'CONNOR starring TOM HARDY JOEL EDGERTON NICK NOLTE and JENNIFER MORRISON",
  },
  {
    id: 309,
    name: "Project Kill",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODY3MjAzMWUtNDI3Zi00ZWViLWI5YTktN2FiZGNlNzE5YzFkXkEyXkFqcGdeQXVyMTY5Nzc4MDY@._V1_SX300.jpg",
    rating: 1,
    year: 1976,
    synopsis:
      "This movie is unwatchable. Even fans of cult classic movies have nothing to tune in for. The action is slow, the story hard to follow, and the paycheck shows in the acting.",
    genre: "Action",
    subgenre: "Bad",
    runtime: "90 min",
    pairs: "blacking out immediately afterwards.",
    rated: "N/A",
    picture1: projectKill1,
    picture2: projectKill2,
    picture3: projectKill3,
    tagline: "ALIVE AND HATING IT!",
    taglineWriter: "Dracula",
    bulletOne: "Thanking God for the parody genre.",
    bulletTwo: "Project kill yourself before watching.",
    cast: "Directed by WILLIAM GIRDLER starring LESLIE NIELSEN GARY LOCKWOOD NANCY KWAN and VIC DIAZ",
  },
  {
    id: 310,
    name: "Primal Fear",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTM2NWI2OGYtYWNhMi00ZTlmLTg2ZTAtMmI5NWRjODA5YTE1XkEyXkFqcGdeQXVyODE2OTYwNTg@._V1_SX300.jpg",
    rating: 5,
    year: 1996,
    synopsis: "A schizophrenic porn star stands up to his demanding director.",
    genre: "Crime",
    subgenre: "Thriller",
    runtime: "129 min",
    pairs: "a Dissociative Identity.",
    drinkRecipe: "Jim Beam, Jack Daniels, & Mr. PiBB.",
    rated: "R",
    picture1: primalFear1,
    picture2: primalFear2,
    picture3: primalFear3,
    tagline: "A LOT OF COMPELLING CHARACTERS!",
    taglineWriter: "Sybil Dorsett",
    bulletOne: "Court room shenanigans.",
    bulletTwo: "Somehow this is Norton's feature film debut.",
    cast: "Directed by GREGORY HOBLIT starring RICHARD GERE  LAURA LINNEY  ANDRE BRAUGHER and EDWARD NORTON",
  },
  {
    id: 311,
    name: "Batman Begins",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTY4YjI2N2MtYmFlMC00ZjcyLTg3YjEtMDQyM2ZjYzQ5YWFkXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    year: 2005,
    synopsis:
      "The tale of a fraidy cat who can't stand bats. Without a doubt the best origin story in the superhero canon. ",
    genre: "Superhero",
    subgenre: "Action",
    runtime: "140 min",
    pairs: "Ra's al Fool.",
    drinkRecipe: "equal parts vodka, Blue Curaçao, and cream.",
    rated: "PG-13",
    picture1: batmanBegins1,
    picture2: batmanBegins2,
    picture3: batmanBegins3,
    tagline: "WHERE'RE THE BAT NIPPLES?",
    taglineWriter: "Scare-Ho",
    bulletOne: "New bicep curl techniques.",
    bulletTwo: "Alfred never giving up on Bruce.",
    cast: "Directed by CHRISTOPHER NOLAN starring CHRISTIAN BALE LIAM NEESON MICHAEL CAINE and KATIE HOLMES",
  },
  {
    id: 312,
    name: "The Dark Knight",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTMxNTMwODM0NF5BMl5BanBnXkFtZTcwODAyMTk2Mw@@._V1_SX300.jpg",
    rating: 2,
    year: 2008,
    synopsis:
      "If I wanted to see this clown smack lips for 3 hours I would have watched Brokeback Mountain.",
    genre: "Superhero",
    subgenre: "Action",
    runtime: "152 min",
    pairs: "The Jokester.",
    drinkRecipe: "purple drank, vodka.",
    rated: "PG-13",
    picture1: darkKnight1,
    picture2: darkKnight2,
    picture3: darkKnight3,
    tagline: "Mleh mleh mleh Oscar, please!",
    taglineWriter: "Heath Ledger",
    bulletOne: "Doesn't live up to the first of this series.",
    bulletTwo: "Took the name too seriously. Impossible to see anything.",
    cast: "Directed by CHRISTOPHER NOLAN starring CHRISTIAN BALE HEATH LEDGER AARON ECKHART and MICHAEL CAINE",
  },
  {
    id: 313,
    name: "The Dark Knight Rises",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTk4ODQzNDY3Ml5BMl5BanBnXkFtZTcwODA0NTM4Nw@@._V1_SX300.jpg",
    rating: 2,
    year: 2012,
    synopsis:
      "There are many reasons to say 'fuck this movie', but mainly: fuck this movie for encouraging every douchebag in the universe to do shitty Bane impressions.  Oh, you like the darkness? Good, because I'm going to plant you in the fucking dirt. ",
    genre: "Superhero",
    subgenre: "Action",
    runtime: "164 min",
    pairs: "a Ventilator.",
    drinkRecipe: "Diet Dr. Pepper and Irish whisky.",
    rated: "PG-13",
    picture1: darkKnightRises1,
    picture2: darkKnightRises2,
    picture3: darkKnightRises3,
    tagline: "He's still going for the tackle!",
    taglineWriter: "Perry",
    bulletOne: "Zero National Guard assistance.",
    bulletTwo:
      "Alfred giving up on Bruce when two movies ago he said he never would.",
    cast: "Directed by CHRISTOPHER NOLAN starring CHRISTIAN BALE TOM HARDY ANNE HATHAWAY and GARY OLDMAN",
  },
  {
    id: 314,
    name: "Iron Man 2",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTM0MDgwNjMyMl5BMl5BanBnXkFtZTcwNTg3NzAzMw@@._V1_SX300.jpg",
    rating: 4,
    year: 2010,
    synopsis: "Anthony Stark faces off against his daddy's old comrade.",
    genre: "Superhero",
    subgenre: "Action",
    runtime: "124 min",
    pairs: "FeMx.",
    drinkRecipe: "equal parts kale and prune juice with vodka",
    rated: "PG-13",
    picture1: ironman21,
    picture2: ironman22,
    picture3: ironman23,
    tagline: "I'M IRON MAN TOO!",
    taglineWriter: "Ozzy",
    bulletOne: "Easily creating new elements.",
    bulletTwo: "Terrance Howard definitely regret$ leaving the MCU.",
    cast: "Directed by JON FAVREAU starring ROBERT DOWNEY JR.  MICKEY ROUKE  GWYNETH PALTROW  and DON CHEADLE",
  },
  {
    id: 315,
    name: "Iron Man 3",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjE5MzcyNjk1M15BMl5BanBnXkFtZTcwMjQ4MjcxOQ@@._V1_SX300.jpg",
    rating: 3,
    year: 2013,
    synopsis: "",
    genre: "Superhero",
    subgenre: "Action",
    runtime: "130 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 316,
    name: "Monsters, Inc.",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTY1NTI0ODUyOF5BMl5BanBnXkFtZTgwNTEyNjQ0MDE@._V1_SX300.jpg",
    rating: 5,
    year: 2001,
    synopsis:
      "To generate electricity, monsters scare the shit out of children and harness the power of their screams.",
    genre: "Animation",
    subgenre: "",
    runtime: "92 min",
    pairs: "Nightmare Juice.",
    drinkRecipe: "Monster and Midori.",
    rated: "G",
    picture1: monstersInc1,
    picture2: monstersInc2,
    picture3: monstersInc3,
    tagline: "Banishment worked great for me!",
    taglineWriter: "King Itchy",
    bulletOne: "Relatable coworker rivalries.",
    bulletTwo:
      "Fun Fact: They modeled Waternoose's legs after James Coburn's hands!",
    cast: "Directed by PETE DOCTER starring BILLY CRYSTAL JOHN GOODMAN STEVE BUSCEMI and JENNIFER TILLY",
  },
  {
    id: 317,
    name: "Step Brothers",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODViZDg3ZjYtMzhiYS00YTVkLTk4MzktYWUxMTlkYjc1NjdlXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    rating: 6,
    year: 2008,
    synopsis:
      "Huff and Dobak start out as enemies but after a courageous act against the one called Derek, they become friends who ride majestic, translucent steeds, shooting flaming arrows across the bridge of Hemdale.",
    genre: "Comedy",
    subgenre: "",
    runtime: "98 min",
    pairs: "",
    noPairs: "mixed wines.",
    rated: "R",
    picture1: stepBrothers1,
    picture2: stepBrothers2,
    picture3: stepBrothers3,
    tagline: "Holy Guacamole it's funny!",
    taglineWriter: "Good Housekeeping",
    bulletOne: "Traveling 500 miles to give your seed.",
    bulletTwo: "Interviewing as a team to solve the ol' Pan/Pamm dilemma.",
    cast: "Directed by ADAM McKAY starring WILL FERRELL JOHN C. REILLY MARY STEENBURGEN and RICHARD JENKINS",
  },
  {
    id: 318,
    name: "Anchorman",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQ2MzYwMzk5Ml5BMl5BanBnXkFtZTcwOTI4NzUyMw@@._V1_SX300.jpg",
    rating: 5,
    year: 2004,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "94 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by ADAM McKAY starring WILL FERRELL STEVE CARRELL PAUL RUDD and CHRISTINA APPLEGATE",
  },
  {
    id: 319,
    name: "Dumb and Dumber",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDQwMjNiMTQtY2UwYy00NjhiLTk0ZWEtZWM5ZWMzNGFjNTVkXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    year: 1994,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "107 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by THE FARRELLY BROTHERS starring JIM CARREY JEFF DANIELS LAUREN HOLLY and MIKE STARR",
  },
  {
    id: 320,
    name: "Dumb and Dumber To",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTYxMzA0MzAyMF5BMl5BanBnXkFtZTgwMjMyNjcwMjE@._V1_SX300.jpg",
    rating: 4,
    year: 2014,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "109 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 321,
    name: "Ace Ventura: Pet Detective",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYmVhNmFmOGYtZjgwNi00ZGQ0LThiMmQtOGZjMDUzNzJhMGIzXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    rating: 5,
    year: 1994,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "86 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 322,
    name: "Ace Ventura: When Nature Calls",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNGFiYTgxZDctNGI4OS00MWU1LWIwOGUtZmMyNGQxYjVkZjQ3XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1995,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "90 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 323,
    name: "Yes Man",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzBmZTMzYmItNzhhMC00M2FkLWIxMGEtMjIxMjAwNmQ2ZmM4XkEyXkFqcGdeQXVyNTIzOTk5ODM@._V1_SX300.jpg",
    rating: 4,
    year: 2008,
    synopsis:
      "This No Dude completely turns his life upside down with the power of Yep.",
    genre: "Comedy",
    subgenre: "Romcom",
    runtime: "104 min",
    pairs: "a Morning Run.",
    drinkRecipe: "Redbull, vodka, blue gatorade.",
    rated: "PG-13",
    picture1: yesman1,
    picture2: yesman2,
    picture3: yesman3,
    tagline: "JUST SAY YES TO THIS MOVIE!",
    taglineWriter: "Nan C. Reegan",
    bulletOne: "Stretching a favor to the absolute limit and beyond.",
    bulletTwo: "Letting your new friend borrow your bike.",
    cast: "Directed by PEYTON REED starring JIM CARREY  ZOOEY DESCHANEL  BRADLEY COOPER and SASHA ALEXANDERN",
  },
  {
    id: 324,
    name: "Scarface",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjdjNGQ4NDEtNTEwYS00MTgxLTliYzQtYzE2ZDRiZjFhZmNlXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 5,
    year: 1983,
    synopsis: "",
    genre: "Crime",
    subgenre: "",
    runtime: "170 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 325,
    name: "All the Right Moves",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzk5ZThhMzEtNzI2NS00OGRmLWI4Y2UtMWQyZWMxOGFhYzc5XkEyXkFqcGdeQXVyMTUzMDUzNTI3._V1_SX300.jpg",
    rating: 5,
    year: 1983,
    synopsis:
      "When a disrespectful high-school twerp assists the town dirtbags with property damage, he is rightfully removed from participating in extracurricular activities.",
    genre: "Sports",
    subgenre: "Drama",
    runtime: "91 min",
    pairs: "an Interception.",
    drinkRecipe: "Yuengling Lager, Whiskey Bomb, Gatorade Chaser.",
    noPairs: "",
    rated: "R",
    picture1: alltheright1,
    picture2: alltheright2,
    picture3: alltheright3,
    tagline: "I'D GO TO PROM WITH MY MOM!",
    taglineWriter: "Leo, Tom's Son",
    bulletOne: "Blowing up the W with a bad play call...",
    bulletTwo: "and still landing your dream job for 9 seasons.",
    cast: "Directed by MICHAEL CHAPMAN starring TOM CRUISE LEA THOMSON CRAIG T. NELSON and CHRIS PENN",
  },
  {
    id: 326,
    name: "Sherlock Holmes: A Game of Shadows",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQwMzQ5Njk1MF5BMl5BanBnXkFtZTcwNjIxNzIxNw@@._V1_SX300.jpg",
    year: 2011,
    rating: 4,
    synopsis: "",
    genre: "Mystery",
    subgenre: "",
    runtime: "129 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 327,
    name: "The Curious Case of Benjamin Button",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjQ0NTY2ODY2M15BMl5BanBnXkFtZTgwMjE4MzkxMDE@._V1_SX300.jpg",
    year: 2008,
    rating: 4,
    synopsis:
      "Achilles' birth defect causes him to age in reverse. Hope you’re not planning on doing anything the rest of the week cause you’re in for a long haul.",
    genre: "Drama",
    subgenre: "Adventure",
    runtime: "166 min",
    pairs: "a Sazerac.",
    drinkRecipe: "Absinthe, sugar cube, bitters, rye, cognac, cold water.",
    rated: "PG-13",
    picture1: bb1,
    picture2: bb2,
    picture3: bb3,
    tagline: "YOU'LL FEEL 17 AGAIN!",
    taglineWriter: "Zack F. Ron",
    bulletOne:
      "Despite trying to age you to death it is an interesting and well told story.",
    bulletTwo: "Old man/young woman? Cool. Old woman/young man? Yuck.",
    cast: "Directed by DAVID FINCHER starring BRAD PITT CATE BLANCHETT TARAJI P. HENSON and JARED HARRIS",
  },
  {
    id: 328,
    name: "Friday",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYmEwNjNlZTUtNzkwMS00ZTlhLTkyY2MtMjM2MzlmODQyZGVhXkEyXkFqcGdeQXVyNTI4MjkwNjA@._V1_SX300.jpg",
    year: 1995,
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "91 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 329,
    name: "Next Friday",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTg4NTkzMDEtM2MzOS00MDE1LWJmYWItYzdiMGRiNTdmZmFkXkEyXkFqcGdeQXVyNjUwNzk3NDc@._V1_SX300.jpg",
    year: 2000,
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "98 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 330,
    name: "Friday After Next",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTE5NTI3OTYyN15BMl5BanBnXkFtZTYwNjUxNTg2._V1_SX300.jpg",
    year: 2002,
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "85 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 331,
    name: "Don't Be a Menace to South Central While Drinking Your Juice in the Hood",
    poster:
      "https://m.media-amazon.com/images/M/MV5BY2NmM2M2MWItNjdlMC00ZWI3LTkwODUtZDNkYWZjYjgzZjY3XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    year: 1996,
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "89 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "Tips to guarantee scoring a phone number.",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 332,
    name: "Natural Born Killers",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTI2NTU2Nzc0MV5BMl5BanBnXkFtZTcwMzY1OTM2MQ@@._V1_SX300.jpg",
    year: 1994,
    rating: 4,
    synopsis: "",
    genre: "Crime",
    subgenre: "Thriller",
    runtime: "118 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 333,
    name: "Zombieland",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTU5MDg0NTQ1N15BMl5BanBnXkFtZTcwMjA4Mjg3Mg@@._V1_SX300.jpg",
    year: 2009,
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Horror",
    runtime: "88 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 334,
    name: "Zombieland: Double Tap",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjcwNjZhNGYtOGNlNy00NGI3LTlmODMtMGZlMjA3YjA0Njg0XkEyXkFqcGdeQXVyMDM2NDM2MQ@@._V1_SX300.jpg",
    year: 2019,
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Horror",
    runtime: "99 min",
    pairs: "",
    rated: "R",
    picture1: zombieland21,
    picture2: zombieland22,
    picture3: zombieland23,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne:
      "Despite being a decade later, this film follows up the first and doesn’t disappoint.",
    bulletTwo: "",
    cast: "Directed by RUBEN FLEISCHER starring WOODY HARRELSON JESSE EISENBERG EMMA STONE and ABIGAIL BRESLIN",
  },
  {
    id: 335,
    name: "Trainspotting",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzA5Zjc3ZTMtMmU5YS00YTMwLWI4MWUtYTU0YTVmNjVmODZhXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    year: 1996,
    rating: 5,
    synopsis: "",
    genre: "Crime",
    subgenre: "Drama",
    runtime: "93 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 336,
    name: "Argo",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzljNjY3MDYtYzc0Ni00YjU0LWIyNDUtNTE0ZDRiMGExMjZlXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    year: 2012,
    rating: 5,
    synopsis: "",
    genre: "Thriller",
    subgenre: "",
    runtime: "120 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 337,
    name: "Fargo",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDJiZDgyZjctYmRjMS00ZjdkLTkwMTEtNGU1NDg3NDQ0Yzk1XkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    year: 1996,
    rating: 4,
    synopsis: "",
    genre: "Crime",
    subgenre: "",
    runtime: "98 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 338,
    name: "300",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNWMxYTZlOTUtZDExMi00YzZmLTkwYTMtZmM2MmRjZmQ3OGY4XkEyXkFqcGdeQXVyMTAwMzUyMzUy._V1_SX300.jpg",
    year: 2006,
    rating: 5,
    synopsis:
      "A war breaks out after 300 stubborn beefcakes refuse to share Earth and Water with a jewelry-obsessed lesbian.",
    genre: "Action",
    subgenre: "War",
    runtime: "117 min",
    pairs: "",
    noPairs: "a skin of wine.",
    rated: "R",
    picture1: threeHundred1,
    picture2: threeHundred3,
    picture3: threeHundred2,
    tagline: "Why dine in hell? The food is better at Dorsia",
    taglineWriter: "Patrick Bateman",
    bulletOne: "Never eating a carb, ever.",
    bulletTwo: "Freak creatures apparently existing among humans.",
    cast: "Directed by ZACK SNYDER starring GERARD BUTLER LENA HEADEY DAVID WENHAM and VINCENT REGAN",
  },
  {
    id: 339,
    name: "Due Date",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTU5MTgxODM3Nl5BMl5BanBnXkFtZTcwMjMxNDEwNA@@._V1_SX300.jpg",
    year: 2010,
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "95 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 340,
    name: "Fear and Loathing in Las Vegas",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjA2ZDY3ZjYtZmNiMC00MDU5LTgxMWEtNzk1YmI3NzdkMTU0XkEyXkFqcGdeQXVyNjQyMjcwNDM@._V1_SX300.jpg",
    year: 1998,
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "118 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 341,
    name: "I Am Legend",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTE1ZTBlYzgtNmMyNS00ZTQ2LWE4NjEtZjUxNDJkNTg2MzlhXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    year: 2007,
    rating: 3,
    synopsis:
      "Will Smith and his pup navigate a world ruled by nocturnal virus-zombies.",
    genre: "Scifi",
    subgenre: "Horror",
    runtime: "101 min",
    pairs: "Blood Serum.",
    drinkRecipe: "Mad Dog Dragonfruit and vodka.",
    rated: "PG-13",
    picture1: legend1,
    picture2: legend2,
    picture3: legend3,
    tagline: "Holy SHIT the special effects were bad",
    taglineWriter: "C.G.I, Robot",
    bulletOne: "Talking to yourself and creating friends.",
    bulletTwo:
      "If you know when and where the zombies come out, how do you continually find yourself in trouble?",
    cast: "Directed by FRANCIS LAWRENCE starring WILL SMITH ALICE BRAGA CHARLIE TAHAN and DASH MIHOK",
  },
  {
    id: 342,
    name: "The Machinist",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjk1NzBlY2YtNjJmNi00YTVmLWI2OTgtNDUxNDE5NjUzZmE0XkEyXkFqcGdeQXVyNTc1NTQxODI@._V1_SX300.jpg",
    year: 2004,
    rating: 4,
    synopsis:
      "The skinniest mothafucka on Earth can't remember the last time he had lunch.",
    genre: "Thriller",
    subgenre: "Mystery",
    runtime: "101 min",
    pairs: "",
    noPairs: "Ensure.",
    rated: "R",
    picture1: machinist1,
    picture2: machinist2,
    picture3: machinist3,
    tagline: "Who's the fat guy?",
    taglineWriter: "Nicole Richie",
    bulletOne: "An apple a day keeps the weight away.",
    bulletTwo: "Batman begins 1 year and 100lbs later.",
    cast: "Directed by BRAD ANDERSON starring CHRISTIAN BALE  JENNIFER JASON LEIGH JOHN SHARIAN  and AITANA SÁNCHEZ-GIJÓN",
  },
  {
    id: 343,
    name: "Thor",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOGE4NzU1YTAtNzA3Mi00ZTA2LTg2YmYtMDJmMThiMjlkYjg2XkEyXkFqcGdeQXVyNTgzMDMzMTg@._V1_SX300.jpg",
    year: 2011,
    rating: 4,
    synopsis: "",
    genre: "Superhero",
    subgenre: "Action",
    runtime: "115 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 344,
    name: "Thor: The Dark World",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQyNzAwOTUxOF5BMl5BanBnXkFtZTcwMTE0OTc5OQ@@._V1_SX300.jpg",
    year: 2013,
    rating: 4,
    synopsis: "",
    genre: "Superhero",
    subgenre: "Action",
    runtime: "112 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 345,
    name: "Thor: Ragnarok",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjMyNDkzMzI1OF5BMl5BanBnXkFtZTgwODcxODg5MjI@._V1_SX300.jpg",
    year: 2017,
    rating: 4,
    synopsis: "",
    genre: "Superhero",
    subgenre: "Action",
    runtime: "130 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 346,
    name: "Hancock",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTgyMzc4ODU3NV5BMl5BanBnXkFtZTcwNjk5Mzc1MQ@@._V1_SX300.jpg",
    year: 2008,
    rating: 3,
    synopsis: "",
    genre: "Superhero",
    subgenre: "Comedy",
    runtime: "92 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 347,
    name: "Eastern Promises",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTcwMzU0OTY3NF5BMl5BanBnXkFtZTYwNzkwNjg2._V1_SX300.jpg",
    year: 2007,
    rating: 4,
    synopsis:
      "Vijjo Morgenstein hangs dong as a tattooed enforcer for the Russian mob.",
    genre: "Crime",
    subgenre: "",
    runtime: "100 min",
    pairs: "",
    noPairs: "straight Stoli.",
    rated: "R",
    picture1: easternPromises1,
    picture2: easternPromises2,
    picture3: easternPromises3,
    tagline: "CRIMINALLY UNDERRATED",
    taglineWriter: "Cagey Bee",
    bulletOne:
      "After watching, you'll forever be weary of getting a straight razor shave.",
    bulletTwo:
      "Fighting naked is effective, as no one wants to touch your balls.",
    cast: "Directed by DAVID CRONENBERG starring VIGGO MORTENSON NAOMI WATTS ARMIN MUELLER-STAHL and VINCENT CASSEL",
  },
  {
    id: 348,
    name: "A History of Violence",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTczMzIzMTQ1OV5BMl5BanBnXkFtZTYwMDQwNjk2._V1_SX300.jpg",
    year: 2005,
    rating: 4,
    synopsis: "",
    genre: "Crime",
    subgenre: "",
    runtime: "96 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 349,
    name: "Brothers",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTkyMTY5MTcxOV5BMl5BanBnXkFtZTcwMTI3MDE3Mg@@._V1_SX300.jpg",
    year: 2009,
    rating: 3,
    synopsis: "",
    genre: "Drama",
    subgenre: "War",
    runtime: "105 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 350,
    name: "The Wrestler",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTc5MjYyOTg4MF5BMl5BanBnXkFtZTcwNDc2MzQwMg@@._V1_SX300.jpg",
    year: 2008,
    rating: 6,
    synopsis: "",
    genre: "Sports",
    subgenre: "Drama",
    runtime: "109 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 351,
    name: "Raging Bull",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjRmODkzNDItMTNhNi00YjJlLTg0ZjAtODlhZTM0YzgzYThlXkEyXkFqcGdeQXVyNzQ1ODk3MTQ@._V1_SX300.jpg",
    year: 1980,
    rating: 4,
    synopsis: "",
    genre: "Sports",
    subgenre: "Drama",
    runtime: "129 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 352,
    name: "A Beautiful Mind",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzcwYWFkYzktZjAzNC00OGY1LWI4YTgtNzc5MzVjMDVmNjY0XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    year: 2001,
    rating: 4,
    synopsis: "",
    genre: "Drama",
    subgenre: "",
    runtime: "135 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 353,
    name: "Cinderella Man",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODEyYmQxZjUtZGQ0NS00ZTAwLTkwOGQtNGY2NzEwMWE0MDc3XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    year: 2005,
    rating: 5,
    synopsis: "",
    genre: "Sports",
    subgenre: "Drama",
    runtime: "144 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 354,
    name: "The Avengers",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDYxNjQyMjAtNTdiOS00NGYwLWFmNTAtNThmYjU5ZGI2YTI1XkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    year: 2012,
    rating: 4,
    synopsis: "",
    genre: "Superhero",
    subgenre: "Action",
    runtime: "143 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 355,
    name: "American Beauty",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTBmZWJkNjctNDhiNC00MGE2LWEwOTctZTk5OGVhMWMyNmVhXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    year: 1999,
    rating: 4,
    synopsis: "",
    genre: "Drama",
    subgenre: "",
    runtime: "122 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 356,
    name: "American Psycho",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTM2ZGJmNjQtN2UyOS00NjcxLWFjMDktMDE2NzMyNTZlZTBiXkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    year: 2000,
    rating: 4,
    synopsis: "",
    genre: "Horror",
    subgenre: "",
    runtime: "101 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 357,
    name: "The Wolf of Wall Street",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjIxMjgxNTk0MF5BMl5BanBnXkFtZTgwNjIyOTg2MDE@._V1_SX300.jpg",
    year: 2013,
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Crime",
    runtime: "180 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 358,
    name: "Dallas Buyers Club",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTYwMTA4MzgyNF5BMl5BanBnXkFtZTgwMjEyMjE0MDE@._V1_SX300.jpg",
    year: 2013,
    rating: 4,
    synopsis: "",
    genre: "Drama",
    subgenre: "",
    runtime: "117 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 359,
    name: "Limitless",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYmViZGM0MGItZTdiYi00ZDU4LWIxNDYtNTc1NWQ5Njc2N2YwXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    year: 2011,
    rating: 4,
    synopsis: "",
    genre: "Scifi",
    subgenre: "Thriller",
    runtime: "105 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 360,
    name: "L.A. Confidential",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDQ2YzEyZGItYWRhOS00MjBmLTkzMDUtMTdjYzkyMmQxZTJlXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    year: 1997,
    rating: 4,
    synopsis: "",
    genre: "Crime",
    subgenre: "",
    runtime: "138 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 361,
    name: "127 Hours",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTc2NjMzOTE3Ml5BMl5BanBnXkFtZTcwMDE0OTc5Mw@@._V1_SX300.jpg",
    year: 2010,
    rating: 4,
    synopsis: "",
    genre: "Sports",
    subgenre: "Drama",
    runtime: "94 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 362,
    name: "End of Watch",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjMxNjU0ODU5Ml5BMl5BanBnXkFtZTcwNjI4MzAyOA@@._V1_SX300.jpg",
    year: 2012,
    rating: 4,
    synopsis: "",
    genre: "Crime",
    subgenre: "Action",
    runtime: "109 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 363,
    name: "Shutter Island",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzhiNDkyNzktNTZmYS00ZTBkLTk2MDAtM2U0YjU1MzgxZjgzXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    year: 2010,
    rating: 4,
    synopsis: "",
    genre: "Thriller",
    subgenre: "Mystery",
    runtime: "138 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 364,
    name: "Pineapple Express",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTY1MTE4NzAwM15BMl5BanBnXkFtZTcwNzg3Mjg2MQ@@._V1_SX300.jpg",
    year: 2008,
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "111 min",
    pairs: "",
    rated: "R",
    picture1: pineapple1,
    picture2: pineapple2,
    picture3: pineapple3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "Not being the nerd at the sleepover who fell asleep at 9.",
    bulletTwo: "",
    cast: "Directed by DAVID GORDON GREEN starring SETH ROGEN JAMES FRANCO GARY COLE and DANNY McBRIDE",
  },
  {
    id: 365,
    name: "Mystic River",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTIzNDUyMjA4MV5BMl5BanBnXkFtZTYwNDc4ODM3._V1_SX300.jpg",
    year: 2003,
    rating: 5,
    synopsis: "",
    genre: "Crime",
    subgenre: "Drama",
    runtime: "138 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 366,
    name: "Mud",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTU2MzcyODgyNV5BMl5BanBnXkFtZTcwNTc4MDYwOQ@@._V1_SX300.jpg",
    year: 2012,
    rating: 3,
    synopsis: "",
    genre: "Crime",
    subgenre: "Drama",
    runtime: "130 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 367,
    name: "Being John Malkovich",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYmUxY2MyOTQtYjRlMi00ZWEwLTkzODctZDMxNDcyNTFhYjNjXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    year: 1999,
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "113 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 368,
    name: "Catch Me If You Can",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTY5MzYzNjc5NV5BMl5BanBnXkFtZTYwNTUyNTc2._V1_SX300.jpg",
    year: 2002,
    rating: 6,
    synopsis:
      "Outstanding cat-and-mouse caper with Hanks and DiCaprio. If this movie isn't quite your speed, we only have this to say: Knock. Knock.",
    genre: "Comedy",
    subgenre: "",
    runtime: "141 min",
    pairs: "a Blank Check.",
    drinkRecipe:
      "Pomegranate juice, cranberry juice, raspberry vodka, ice. Shaken.",
    rated: "PG-13",
    picture1: catch1,
    picture2: catch2,
    picture3: catch3,
    tagline: "Yeah, right - Leonardo flying a plane?!",
    taglineWriter: "The Aviator",
    bulletOne: "Faking it til you make it.",
    bulletTwo:
      "Pretending to be a substitute teacher even if you've never studied the subject.",
    cast: "Directed by STEVEN SPIELBERG starring LEONARDO DiCAPRIO TOM HANKS CHRISTOPHER WALKEN and AMY ADAMS",
  },
  {
    id: 369,
    name: "Jarhead",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYmMyNGM4NWItYjgwYS00N2Q2LWJmY2YtY2ViNWYwMzRhODlmXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    year: 2005,
    rating: 4,
    synopsis: "",
    genre: "War",
    subgenre: "Action",
    runtime: "125 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 370,
    name: "Gone Baby Gone",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYmM2NDNiNGItMTRhMi00ZDA2LTgzOWMtZTE2ZjFhMDQ2M2U5XkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    year: 2007,
    rating: 5,
    synopsis: "",
    genre: "Crime",
    subgenre: "Mystery",
    runtime: "114 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 371,
    name: "Chronicle",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYmRkY2RhM2QtMjQwNC00NDVjLTk4MTQtZGNiMjYxMmJmODVhXkEyXkFqcGdeQXVyNTIzOTk5ODM@._V1_SX300.jpg",
    year: 2012,
    rating: 4,
    synopsis: "",
    genre: "Scifi",
    subgenre: "",
    runtime: "84 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "",
    taglineWriter: "",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by JOHN TRANK starring DANE DeHAAN ALEX RUSSEL ASHLEY HINSHAW and MICHAEL B. JORDAN",
  },
  {
    id: 372,
    name: "Collateral",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjE3NjM5OTMxMV5BMl5BanBnXkFtZTcwOTIzMTQyMw@@._V1_SX300.jpg",
    year: 2004,
    rating: 5,
    synopsis:
      "Over the last 12 years Max Doucher was your typical temporary cab driver, over the next 12 hours he's in for the ride of his life (or death).",
    genre: "Crime",
    subgenre: "Action",
    runtime: "120 min",
    noPairs: "tequila silver, triple shot.",
    rated: "R",
    picture1: collateral1,
    picture2: collateral2,
    picture3: collateral3,
    tagline: "MUSIC SCHOOL IS FOR FOOLS!",
    taglineWriter: "Ray Charles",
    bulletOne: "Ida knowing her son more than she realizes.",
    bulletTwo: "Riding around the LA metro with a dead guy.",
    cast: "Directed by MICHAEL MANN starring TOM CRUISE JAMIE FOXX JADA PINKETT SMITH and MARK RUFFALO",
  },
  {
    id: 373,
    name: "Lock, Stock and Two Smoking Barrels",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTAyN2JmZmEtNjAyMy00NzYwLThmY2MtYWQ3OGNhNjExMmM4XkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    year: 1998,
    rating: 5,
    synopsis: "",
    genre: "Crime",
    subgenre: "Action",
    runtime: "107 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 374,
    name: "Road to Perdition",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjcxMmQ0MmItYTkzYy00MmUyLTlhOTQtMmJmNjE3MDMwYjdlXkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 4,
    year: 2002,
    synopsis:
      "After his wife and youngest son are murdered, a mob hitman brings his eldest son on a journey of revenge.",
    genre: "Crime",
    subgenre: "Drama",
    runtime: "117 min",
    pairs: "a Bootleg Bourbon.",
    drinkRecipe: "Birch beer, bulleit.",
    rated: "R",
    picture1: perdition1,
    picture2: perdition2,
    picture3: perdition3,
    tagline: "A KILLER FLICK!",
    taglineWriter: "Jude Lawbreaker",
    bulletOne: "Paul Newman is always the man. ",
    bulletTwo:
      "Daniel Craigs terrible American accent is fun. Also the scene where he gets dressed down by Stanley Tucci.",
    cast: "Directed by SAM MENDES starring TOM HANKS TYLER HOECHLIN PAUL NEWMAN and JUDE LAW",
  },
  {
    id: 375,
    name: "Equilibrium",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTkzMzA1OTI3N15BMl5BanBnXkFtZTYwMzUyMDg5._V1_SX300.jpg",
    year: 2002,
    rating: 6,
    synopsis: "",
    genre: "Scifi",
    subgenre: "Action",
    runtime: "107 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 376,
    name: "In Bruges",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTUwOGFiM2QtOWMxYS00MjU2LThmZDMtZDM2MWMzNzllNjdhXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    year: 2008,
    rating: 5,
    synopsis:
      "An aging hitman and his new partner take a much needed vacation in Bruges.",
    genre: "Crime",
    subgenre: "",
    runtime: "107 min",
    pairs: "a Chimay Blue.",
    drinkRecipe: "Pour in a fancy glass for better effect.",
    rated: "R",
    picture1: inBruges1,
    picture2: inBruges2,
    picture3: inBruges3,
    tagline: "I'D RATHER TOUR PERSIA!",
    taglineWriter: "Alexander the Alright",
    bulletOne: "How all Europeans view Americans.",
    bulletTwo: "Choosing your side for the upcoming Race War.",
    cast: "Directed by MARTIN MCDONAGH starring COLIN FARRELL BRENDAN GLEESON RALPH FIENNES and JORDAN PRENTICE",
  },
  {
    id: 377,
    name: "Law Abiding Citizen",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTQ1NmZlNDktZTg0NS00ZjNhLTk5M2QtNzExNjUzY2MwMjliXkEyXkFqcGdeQXVyODE5NzE3OTE@._V1_SX300.jpg",
    year: 2009,
    rating: 4,
    synopsis:
      "An upstanding member of society, imprisoned due to a failed legal system, must fight for his freedom and bring the true criminals to justice.",
    genre: "Crime",
    subgenre: "Thriller",
    runtime: "109 min",
    pairs: "a Conjugal Visit.",
    drinkRecipe: "Pinnicle Whipped vodka, cranberry juice, shot of loco-nut.",
    rated: "R",
    picture1: lawCitizen1,
    picture2: lawCitizen2,
    picture3: lawCitizen3,
    tagline: "Outstanding. Fantastic. Incredible. A good movie!",
    taglineWriter: "Jackie Chiles",
    bulletOne: "Paralegal ending.",
    bulletTwo: "Picking the wrong guy to rob.",
    cast: "Directed by F. GARY GRAY starring GERARD BUTLER  JAMIE FOXX  LESLIE BIBB and COLM MEANEY",
  },
  {
    id: 378,
    name: "Prisoners",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTg0NTIzMjQ1NV5BMl5BanBnXkFtZTcwNDc3MzM5OQ@@._V1_SX300.jpg",
    year: 2013,
    rating: 4,
    synopsis: "",
    genre: "Crime",
    subgenre: "Thriller, Mystery",
    runtime: "153 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 379,
    name: "Pacific Rim",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTY3MTI5NjQ4Nl5BMl5BanBnXkFtZTcwOTU1OTU0OQ@@._V1_SX300.jpg",
    year: 2013,
    rating: 4,
    synopsis:
      "A fissure in the earth’s crust creates an interdimensional portal where Kaiju, massive alien monsters, emerge from the ocean. Naturally the only way to defend humanity is to build Power Ranger style Decipticon suits and fight the Kaiju in hand to hand combat.",
    genre: "Scifi",
    subgenre: "Action",
    runtime: "131 min",
    pairs: "",
    noPairs: "a Raglan Brewing Co. Pacific Ale.",
    rated: "PG-13",
    picture1: pacificRim1,
    picture2: pacificRim2,
    picture3: pacificRim3,
    tagline: "NOT ACCURATE!",
    taglineWriter: "Godzirra",
    bulletOne: "Sweet special effects and action.",
    bulletTwo: "Investing 100% of the military budget on comic-con gear.",
    cast: "Directed by GUILLERMO DEL TORO starring CHARLIE HUNNAM IDRIS ELBA RINKO KIKUCHI and CHARLIE DAY",
  },
  {
    id: 380,
    name: "Gangster Squad",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTcwMjAyMTUzMl5BMl5BanBnXkFtZTcwODgxNzk1OA@@._V1_SX300.jpg",
    year: 2013,
    rating: 4,
    synopsis: "",
    genre: "Crime",
    subgenre: "",
    runtime: "113 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 381,
    name: "Guardians of the Galaxy",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTAwMjU5OTgxNjZeQTJeQWpwZ15BbWU4MDUxNDYxODEx._V1_SX300.jpg",
    year: 2014,
    rating: 4,
    synopsis: "",
    genre: "Superhero",
    subgenre: "Action",
    runtime: "121 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 382,
    name: "Harsh Times",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTk0Mzc1NTY1N15BMl5BanBnXkFtZTYwMTMxOTY3._V1_SX300.jpg",
    year: 2005,
    rating: 4,
    synopsis: "",
    genre: "Crime",
    subgenre: "Action",
    runtime: "116 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 383,
    name: "The Condemned",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzkyOTczMjU0M15BMl5BanBnXkFtZTcwMDExMzM0MQ@@._V1_SX300.jpg",
    year: 2007,
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "113 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 384,
    name: "Nightcrawler",
    poster:
      "https://m.media-amazon.com/images/M/MV5BN2U1YzdhYWMtZWUzMi00OWI1LWFkM2ItNWVjM2YxMGQ2MmNhXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    year: 2014,
    rating: 5,
    synopsis:
      "An independent reporter will do anything to get the edge on a story. Fake news be damned, when it comes to getting the scoop there’s no moral high ground.",
    genre: "Thriller",
    subgenre: "Crime",
    runtime: "117 min",
    pairs: "a Crawly Critter.",
    drinkRecipe: "Vodka, Monster Ultra Blue, gummie worm(s).",
    rated: "R",
    picture1: nightcrawler1,
    picture2: nightcrawler2,
    picture3: nightcrawler3,
    tagline: "That guy's a CREEP!",
    taglineWriter: "Radiohead",
    bulletOne: "How to con a pawnshop.",
    bulletTwo: "Blackmail and blue-balls.",
    cast: "Directed by DAN GILROY starring JAKE GYLLENHAAL RENE RUSSO BILL PAXTON and RIZ AHMED",
  },
  {
    id: 385,
    name: "Her",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjA1Nzk0OTM2OF5BMl5BanBnXkFtZTgwNjU2NjEwMDE@._V1_SX300.jpg",
    year: 2013,
    rating: 4,
    synopsis: "",
    genre: "Scifi",
    subgenre: "Romcom",
    runtime: "126 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "It's as if when love and death embrace!",
    taglineWriter: "HIM",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 386,
    name: "John Wick",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTU2NjA1ODgzMF5BMl5BanBnXkFtZTgwMTM2MTI4MjE@._V1_SX300.jpg",
    year: 2014,
    rating: 6,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "101 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 387,
    name: "John Wick: Chapter 2",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjE2NDkxNTY2M15BMl5BanBnXkFtZTgwMDc2NzE0MTI@._V1_SX300.jpg",
    year: 2017,
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "122 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 388,
    name: "John Wick: Chapter 3 - Parabellum",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDg2YzI0ODctYjliMy00NTU0LTkxODYtYTNkNjQwMzVmOTcxXkEyXkFqcGdeQXVyNjg2NjQwMDQ@._V1_SX300.jpg",
    year: 2019,
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "130 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 389,
    name: "Lawless",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjAxNjUyNjUwN15BMl5BanBnXkFtZTcwMDgwOTIyOA@@._V1_SX300.jpg",
    year: 2012,
    rating: 4,
    synopsis: "",
    genre: "Crime",
    subgenre: "",
    runtime: "116 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 390,
    name: "Cast Away",
    poster:
      "https://m.media-amazon.com/images/M/MV5BN2Y5ZTU4YjctMDRmMC00MTg4LWE1M2MtMjk4MzVmOTE4YjkzXkEyXkFqcGdeQXVyNTc1NTQxODI@._V1_SX300.jpg",
    year: 2000,
    rating: 4,
    synopsis:
      "A scrawny loser gets stuck on a tropical island and cries when he loses his volleyball",
    genre: "Adventure",
    subgenre: "Drama",
    runtime: "143 min",
    pairs: "a Shipwreck.",
    drinkRecipe: "coconut rum and pineapple juice.",
    rated: "PG-13",
    picture1: castaway1,
    picture2: castaway2,
    picture3: castaway3,
    tagline: "SWEET LIFE-RAFT!",
    taglineWriter: "Elian Gonzalez",
    bulletOne: "Going way too far to pee.",
    bulletTwo: "Being extremely proud of your meager accomplishments.",
    cast: "Directed by ROBERT ZEMECKIS starring TOM HANKS HELEN HUNT PAUL SANCHEZ and LARI WHITE",
  },
  {
    id: 391,
    name: "American Gangster",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjFmZGI2YTEtYmJhMS00YTE5LWJjNjAtNDI5OGY5ZDhmNTRlXkEyXkFqcGdeQXVyODAwMTU1MTE@._V1_SX300.jpg",
    year: 2007,
    rating: 4,
    synopsis: "",
    genre: "Crime",
    subgenre: "",
    runtime: "157 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 392,
    name: "The Negotiator",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOGE1YTI2NjItNDAyYy00YzI3LTlmNDUtYWU0ZmFlNWE3N2ViL2ltYWdlXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    year: 1998,
    rating: 4,
    synopsis: "",
    genre: "Crime",
    subgenre: "Action",
    runtime: "140 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 393,
    name: "Heat",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNGMwNzUwNjYtZWM5NS00YzMyLWI4NjAtNjM0ZDBiMzE1YWExXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    year: 1995,
    rating: 5,
    synopsis: "",
    genre: "Crime",
    subgenre: "Thriller",
    runtime: "170 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 394,
    name: "Revolver",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQ1OTA3MjM4MF5BMl5BanBnXkFtZTYwMTMxODc4._V1_SX300.jpg",
    year: 2005,
    rating: 4,
    synopsis:
      "Crime-thriller in which Jake Green (Jason Statham) goes to jail for a crime he didn't commit and plots revenge upon release. ",
    genre: "Crime",
    subgenre: "Thriller",
    runtime: "111 min",
    pairs: "a Moscow Mule.",
    drinkRecipe: "Vodka, ginger beer, lime juice.",
    rated: "R",
    picture1: revolver1,
    picture2: revolver2,
    picture3: revolver3,
    tagline: "From Guy Ritchie, I expect more jazz flute",
    taglineWriter: "Ron Burgundy",
    bulletOne:
      "Worth watching for Statham's WILD hairpiece and handlebar stache alone.",
    bulletTwo: "Vintage performance from Ray Liotta.",
    cast: "Directed by GUY RITCHIE starring JASON STATHAM RAY LIOTTA ANDRE 3000 and VINCENT PASTORE",
  },
  {
    id: 395,
    name: "The Bank Job",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmE2NzEyOWItYjI1ZC00YWZhLWI2MjEtZmU1NjIyNjliOTkyXkEyXkFqcGdeQXVyMTYzMDM0NTU@._V1_SX300.jpg",
    year: 2008,
    rating: 3,
    synopsis: "",
    genre: "Crime",
    subgenre: "Thriller",
    runtime: "111 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 396,
    name: "Cemetery Junction",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQ1MjA2NzIwMF5BMl5BanBnXkFtZTcwMzUyMjcyMw@@._V1_SX300.jpg",
    year: 2010,
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Drama",
    runtime: "95 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 397,
    name: "I Give It a Year",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTg5NDk4MTUwNl5BMl5BanBnXkFtZTcwMDU5MTc4OQ@@._V1_SX300.jpg",
    year: 2013,
    rating: 5,
    synopsis: "",
    genre: "Romcom",
    subgenre: "Comedy",
    runtime: "97 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 398,
    name: "Basic Instinct",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjk0YmJkNjItNDY3Mi00ZWFiLWEwY2EtMDJlZWJlOWZkNzJlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    year: 1992,
    rating: 5,
    synopsis: "",
    genre: "Thriller",
    subgenre: "",
    runtime: "127 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 399,
    name: "A Perfect Murder",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTcwODQxNTEyN15BMl5BanBnXkFtZTYwNTE3NzI3._V1_SX300.jpg",
    year: 1998,
    rating: 4,
    synopsis: "",
    genre: "Thriller",
    subgenre: "",
    runtime: "107 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 400,
    name: "French Kiss",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTkzMjg5MDQ3M15BMl5BanBnXkFtZTgwOTM5NTE0MDE@._V1_SX300.jpg",
    year: 1995,
    rating: 5,
    synopsis: "",
    genre: "Romcom",
    subgenre: "",
    runtime: "111 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 401,
    name: "Perfect Stranger",
    poster:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.explicit.bing.net%2Fth%3Fid%3DOIP.w9CtV9mGRP320lLTCLc4YQHaOG%26pid%3DApi&f=1&ipt=d8da7e5c89b36f086ef6c4cab350e8c4e5f7cf5e7860fa31354eeb88b43eac9b&ipo=images",
    year: 2007,
    rating: 3,
    synopsis:
      "A strange thriller about corporate intrigue and death. With the help of her friend, Halle Berry (gorgeous as ever) infiltrates the world of a wealthy businessman in an attempt to solve the murder of her friend.",
    genre: "Thriller",
    subgenre: "Crime, Mystery",
    runtime: "109 min",
    pairs: "a Flawed Acquaintance.",
    drinkRecipe: "Kentucky straight rye, sweet red vermouth, bitters.",
    rated: "R",
    picture1: perfectStranger1,
    picture2: perfectStranger2,
    picture3: perfectStranger3,
    tagline: "A real game of cat and mouse!",
    taglineWriter: "Tom & Jerry",
    bulletOne: "FUCK YOU! You’re my best friend. ",
    bulletTwo: "Keep your friends close and your enemies closer. ",
    cast: "Directed by JAMES FOLEY starring HALLE BERRY BRUCE WILLIS GIOVANNI RIBISI and RICHARD PORTNOW",
  },
  {
    id: 402,
    name: "The Italian Job",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDYyNzYxNjYtNmYzMC00MTE0LWIwMmYtNTAyZDBjYTIxMTRhXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    year: 2003,
    rating: 5,
    synopsis: "",
    genre: "Crime",
    subgenre: "Action",
    runtime: "111 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 403,
    name: "Shooter",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGRjMzY0OGItNDc4YS00OGNlLWI3MGYtZjRkNjdiNWUyNDY4XkEyXkFqcGdeQXVyNTA4NzY1MzY@._V1_SX300.jpg",
    year: 2007,
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "War",
    runtime: "124 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 404,
    name: "Up in the Air",
    year: 2009,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTI3MzYxMTA4NF5BMl5BanBnXkFtZTcwMDE4ODg3Mg@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Drama",
    subgenre: "Romcom",
    runtime: "109 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 405,
    name: "Finding Nemo",
    year: 2004,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTc5NjExNTA5OV5BMl5BanBnXkFtZTYwMTQ0ODY2._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "100 min",
    pairs: "",
    rated: "G",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 406,
    name: "The Incredible Hulk",
    year: 2008,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTUyNzk3MjA1OF5BMl5BanBnXkFtZTcwMTE1Njg2MQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Superhero",
    subgenre: "Action",
    runtime: "112 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 407,
    name: "A Walk to Remember",
    year: 2002,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzU3NTYxM2MtNjViMS00YmNlLWEwM2MtYWI2MzgzNTkxODFjXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 3,
    synopsis:
      "A sappy, melodramatic snot-fest that beats you over the head with how sad it's supposed to be.",
    genre: "Romcom",
    subgenre: "Drama",
    runtime: "101 min",
    pairs: "an Untimely Death.",
    drinkRecipe: "Jager and vodka.",
    rated: "PG",
    picture1: walk1,
    picture2: walk2,
    picture3: walk3,
    tagline: "A FILM TO FORGET!",
    taglineWriter: "Fredrick King Sr.",
    bulletOne: "'Oh no, she's dying and that's sad!'...",
    bulletTwo: "...we get it.",
    cast: "Directed by ADAM SHANKMAN starring MANDY MOORE SHANE WEST PETER COYOTE and DARYL HANNAH",
  },
  {
    id: 408,
    name: "The Notebook",
    year: 2004,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTk3OTM5Njg5M15BMl5BanBnXkFtZTYwMzA0ODI3._V1_SX300.jpg",
    rating: 3,
    synopsis:
      "This movie proves that something is only romantic if the person who says it is attractive. Switch Gosling with Paul Giamatti and you've got a horror movie. ",
    genre: "Romcom",
    subgenre: "Drama",
    runtime: "123 min",
    pairs: "a Stalker.",
    drinkRecipe: "Bacardi 151 and Fanta.",
    rated: "PG-13",
    picture1: notebook1,
    picture2: notebook2,
    picture3: notebook3,
    tagline: "You'll laugh, you'll cry, they'll die!",
    taglineWriter: "Ted Bundy",
    bulletOne: "Threatening suicide unless you get a date.",
    bulletTwo:
      "Writing letters to an engaged person isn't sleazy, it's romantic ... apparently.",
    cast: "Directed by NICK CASSAVETES starring RYAN GOSLING RACHEL MCADAMS JAMES GARNER and GENA ROWLANDS",
  },
  {
    id: 409,
    name: "Swordfish",
    year: 2001,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzk5ZmQxMWYtM2QyNi00MTY3LTlmNjItYjUwODY3Y2YwOTIwXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "Crime, Thriller",
    runtime: "99 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 410,
    name: "10 Things I Hate About You",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmVhZjhlZDYtMDAwZi00MDcyLTgzOTItOWNiZjY0YmE0MGE0XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Two students are unable to find love so they pretend to be terrible people before realizing they’re hot and live happily ever after.",
    genre: "Romcom",
    subgenre: "Comedy",
    runtime: "97 min",
    pairs: "",
    noPairs: "five Dos Equis.",
    rated: "PG-13",
    picture1: hateYou1,
    picture2: hateYou2,
    picture3: hateYou3,
    tagline: "This guy sure is a JOKESTER!",
    taglineWriter: "Chris Nolan",
    bulletOne: "Having the same 'up-chuck' effect on women",
    bulletTwo: "Dudes at this highschool are unjustifiably picky",
    cast: "Directed by GIL JUNGER starring HEATH LEDGER JULIA STILES JOSEPH GORDON-LEVITT and LARRY MILLER",
  },
  {
    id: 411,
    name: "How to Lose a Guy in 10 Days",
    year: 2003,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjE4NTA1NzExN15BMl5BanBnXkFtZTYwNjc3MjM3._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Romcom",
    subgenre: "Comedy",
    runtime: "116 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 412,
    name: "The Truman Show",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDIzODcyY2EtMmY2MC00ZWVlLTgwMzAtMjQwOWUyNmJjNTYyXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Drama",
    subgenre: "Comedy",
    runtime: "103 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 413,
    name: "Million Dollar Baby",
    year: 2004,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTkxNzA1NDQxOV5BMl5BanBnXkFtZTcwNTkyMTIzMw@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Sports",
    subgenre: "Drama",
    runtime: "132 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 414,
    name: "Remember The Titans",
    year: 2000,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYThkMzgxNjEtMzFiOC00MTI0LWI5MDItNDVmYjA4NzY5MDQ2L2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "In early 1970’s America, racial tensions have never been higher for one desegregated highschool football team coached by Malcolm X.",
    genre: "Sports",
    subgenre: "Drama",
    runtime: "113 min",
    pairs: "a Car Wreck.",
    drinkRecipe: "Equal parts vodka, gin, ginger ale, crushed ice.",
    rated: "PG",
    picture1: titans1,
    picture2: titans2,
    picture3: titans3,
    tagline: "That's a goo' one!",
    taglineWriter: "Radio",
    bulletOne: "Phenomenal soundtrack",
    bulletTwo:
      "Cultures clash as coaches and classmates commiserate collectively",
    cast: "Directed by BOAZ YAKIN starring DENZEL WASHINGTON WILL PATTON RYAN HURST and WOOD HARRIS",
  },
  {
    id: 415,
    name: "Rudy",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGUzMDU1YmQtMzBkOS00MTNmLTg5ZDQtZjY5Njk4Njk2MmRlXkEyXkFqcGdeQXVyNjc1NTYyMjg@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A plucky little pipsqueak proves that if you take enough beatings, you can join a team you don't deserve to be on.",
    genre: "Sports",
    subgenre: "Drama",
    runtime: "114 min",
    pairs: "an Irish Car Bomb.",
    drinkRecipe:
      "Half glass of guinness, half shot baileys, half shot jameson.",
    rated: "PG",
    picture1: rudy1,
    picture2: rudy2,
    picture3: rudy3,
    tagline: "There were smarter ways to play",
    taglineWriter: "Shrewdy",
    bulletOne: "Pete getting properly fired from the mill.",
    bulletTwo:
      "Busting your ass to impress your family, and them not really giving a shit.",
    cast: "Directed by DAVID ANSPAUGH starring SEAN ASTIN JON FAVREAU NED BEATTY and LILI TAYLOR",
  },
  {
    id: 416,
    name: "Coach Carter",
    year: 2005,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNWYxZWFiNTItN2FkNS00ZDJmLWE1MWItYjMyMTgyOTI4MmQ4XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A basketball coach has had it with these mothafuckin' chumps on his mothafuckin' team.",
    genre: "Sports",
    subgenre: "Drama",
    runtime: "136 min",
    pairs: "a Coach's Quarter.",
    drinkRecipe: "Canadian Club, Cannon Blast, Coke.",
    rated: "PG",
    picture1: coachCarter1,
    picture2: coachCarter2,
    picture3: coachCarter3,
    tagline: "A SLAM DUNK!",
    taglineWriter: "Shackheel Oh'Kneel",
    bulletOne: "Beating up students.",
    bulletTwo: "Channing Tatum as the token ghetto white dude.",
    cast: "Directed by THOMAS CARTER starring SAMUEL L. JACKSON RICK GONZALEZ ROBERT RICHARD and ROB BROWN",
  },
  {
    id: 417,
    name: "1408",
    year: 2007,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTk1MDg0NTU0OV5BMl5BanBnXkFtZTcwMTM1NDk0MQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Horror",
    subgenre: "",
    runtime: "104 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 418,
    name: "The Illusionist",
    year: 2006,
    poster:
      "https://m.media-amazon.com/images/M/MV5BY2VkMzZlZDAtNTkzNS00MDIzLWFmOTctMWQwZjQ1OWJiYzQ1XkEyXkFqcGdeQXVyNTIzOTk5ODM@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Scifi",
    subgenre: "Mystery",
    runtime: "110 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 419,
    name: "The Score",
    year: 2001,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDNjYmY1ZDEtM2I3YS00MDhmLTk5NDYtYzU5MTA4ZjIyYzJiL2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Crime",
    subgenre: "Action",
    runtime: "124 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 420,
    name: "Titanic",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDdmZGU3NDQtY2E5My00ZTliLWIzOTUtMTY4ZGI1YjdiNjk3XkEyXkFqcGdeQXVyNTA4NzY1MzY@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A lascivious little strumpet cheats on her fiancé and then drowns her paramour.",
    genre: "Romcom",
    subgenre: "Drama",
    runtime: "194 min",
    pairs: "a Steamy Handprint.",
    drinkRecipe: "Whiskey and Earl Grey Tea.",
    rated: "PG-13",
    picture1: titanic1,
    picture2: titanic2,
    picture3: titanic3,
    tagline: "I'D HIT THAT!",
    taglineWriter: "I.C. Berg",
    bulletOne: "Being the king of the world.",
    bulletTwo: "Remembering which VHS had the nude scene (it was a two-taper).",
    cast: "Directed by JAMES CAMERON starring LEONARDO DiCAPRIO KATE WINSLETT BILLY ZANE and KATHY BATES",
  },
  {
    id: 421,
    name: "Half Baked",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZWMxZWE1ZjQtYzAyNS00NjEwLTgwM2ItMWI2NWVhZGJiYjk3XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "82 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 422,
    name: "Secret Window",
    year: 2004,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNmI5MWNlMjYtZTAxNy00N2Q3LTkwZDgtMWRjOWQ1ZjJiN2Y4XkEyXkFqcGdeQXVyNDQ2MTMzODA@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Horror",
    subgenre: "Thriller",
    runtime: "96 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 423,
    name: "The Hurt Locker",
    year: 2008,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYWYxZjU2MmQtMmMzYi00ZWUwLTg2ZWQtMDExZTVlYjM3ZWM1XkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "War",
    subgenre: "",
    runtime: "131 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 424,
    name: "Scent of a Woman",
    year: 1992,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTM3ZjA3NTctZThkYy00ODYyLTk2ZjItZmE0MmZlMTk3YjQwXkEyXkFqcGdeQXVyNTA4NzY1MzY@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Drama",
    subgenre: "",
    runtime: "156 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 425,
    name: "The Devil's Advocate",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BM2M2MDJhMDgtMmJkYy00MTgzLTkyZTktODM5NzE1MWUyNDA4XkEyXkFqcGdeQXVyNTA4NzY1MzY@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "This movie invokes a moral quandary: Is it wrong to masturbate to a full-frontal yet mutilated Charlize Theron? We could argue either case, but that's between you and your god (or devil).",
    genre: "Thriller",
    subgenre: "Mystery",
    runtime: "144 min",
    pairs: "a Hot Devil.",
    drinkRecipe: "Brandy, white creme de menthe, pinch of cayenne pepper.",
    rated: "R",
    picture1: devilsAdvocate1,
    picture2: devilsAdvocate2,
    picture3: devilsAdvocate3,
    tagline: "A deal with the devil - what could go wrong?!",
    taglineWriter: "Branden Frazher",
    bulletOne: "The biggest sin is Keanu's 'southern' accent.",
    bulletTwo:
      "Fair warning: you should mentally prepare yourself for some of the most jarring jump scares in cinema.",
    cast: "Directed by TAYLOR HACKFORD starring KEANU REEVES AL PACINO CHARLIZE THERON and CONNIE NIELSEN",
  },
  {
    id: 426,
    name: "50 First Dates",
    year: 2004,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjAwMzc4MDgxNF5BMl5BanBnXkFtZTYwNjUwMzE3._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Romcom",
    runtime: "99 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 427,
    name: "Deuce Bigalow: Male Gigolo",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZWIzMzQxZTUtYTJmYi00ZGNmLWEzMDUtZGM2ZjJiNTU2MTk1XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "88 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 428,
    name: "Deuce Bigalow: European Gigolo",
    year: 2005,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTAwYTFhNzgtN2E0ZC00NGZkLWIxY2UtZWRlYzJiMmZlODRjXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "83 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 429,
    name: "The Town",
    year: 2010,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTcyNzcxODg3Nl5BMl5BanBnXkFtZTcwMTUyNjQ3Mw@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Crime",
    subgenre: "Action",
    runtime: "125 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 430,
    name: "Hitch",
    year: 2005,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzYyNzM2NzM2NF5BMl5BanBnXkFtZTcwNjg5NTQzMw@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Romcom",
    subgenre: "Comedy",
    runtime: "118 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "This guy's got 𝘼𝙡𝙡 𝙩𝙝𝙚 𝙒𝙧𝙤𝙣𝙜 𝙈𝙤𝙫𝙚𝙨!",
    taglineWriter: "Tom Cruize",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 431,
    name: "I, Robot",
    year: 2004,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNmE1OWI2ZGItMDUyOS00MmU5LWE0MzUtYTQ0YzA1YTE5MGYxXkEyXkFqcGdeQXVyMDM5ODIyNw@@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "The totally 100% accurate portrayal of what WILL happen if we allow robots to live among us.",
    genre: "Scifi",
    subgenre: "Action",
    runtime: "115 min",
    pairs: "",
    noPairs: "a $23 Tecate.",
    rated: "PG-13",
    picture1: iRobot1,
    picture2: iRobot2,
    picture3: iRobot3,
    tagline: "'I love this movie', just doesn’t quite say it!",
    taglineWriter: "Nedd Ludd",
    bulletOne: "LameBoof before befriending the machines.",
    bulletTwo:
      "Will Smith living on the edge between, “Enemy of the State” and “I am Legend”.",
    cast: "Directed by ALEX PROYAS starring WILL SMITH BRIDGET MOYNAHAN BRUCE GREENWOOD and JAMES CROMWELL",
  },
  {
    id: 432,
    name: "The Pursuit of Happyness",
    year: 2006,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQ5NjQ0NDI3NF5BMl5BanBnXkFtZTcwNDI0MjEzMw@@._V1_SX300.jpg",
    rating: 3,
    synopsis:
      "A loser busynessman makes a bad investment and cries about it the whole time. ",
    genre: "Drama",
    subgenre: "",
    runtime: "117 min",
    pairs: "a Bone Density Scanner.",
    drinkRecipe: "Baileys, vodka, whipped cream, cherry on top",
    rated: "PG-13",
    picture1: happyness1,
    picture2: happyness2,
    picture3: happyness3,
    tagline: "THE STALE PRINCE OF BAD HAIR",
    taglineWriter: "Jaydyn Smyth",
    bulletOne: "He probably bet against the internet.",
    bulletTwo:
      "Still trying to sell all the HD DVD players we bought in 2006. ",
    cast: "Directed by GABRIELLE MUCCINO starring WILL SMITH THANDIE NEWTON JADEN SMITH and BRIAN HOWE",
  },
  {
    id: 433,
    name: "Fracture",
    year: 2007,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzIzNjQyMzkwM15BMl5BanBnXkFtZTcwOTg5ODQ0MQ@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Thriller",
    subgenre: "Crime",
    runtime: "113 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 434,
    name: "Top Gun",
    year: 1986,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjQxYTA3ODItNzgxMy00N2Y2LWJlZGMtMTRlM2JkZjI1ZDhhXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "War",
    runtime: "110 min",
    pairs: "",
    drinkRecipe: ".",
    rated: "PG",
    picture1: topgun1,
    picture2: topgun2,
    picture3: topgun3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: ".",
    bulletTwo: ".",
    cast: "Directed by TONY SCOTT starring TOM CRUISE TIM ROBBINS KELLY McGILLIS and VAL KILMER",
  },
  {
    id: 435,
    name: "A Few Good Men",
    year: 1992,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmRlZDQ1MmUtMzE2Yi00YTkxLTk1MGMtYmIyYWQwODcxYzRlXkEyXkFqcGdeQXVyNTI4MjkwNjA@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "A cocky Army lawyer, who has never seen the inside of a courtroom, attempts to impress his superior officer with the hope of getting (a)head.",
    genre: "Drama",
    subgenre: "War",
    runtime: "138 min",
    pairs: "Código rojo.",
    drinkRecipe: "Havana Club rum & mtn. dew code red.",
    rated: "R",
    picture1: fewgood1,
    picture2: fewgood2,
    picture3: fewgood3,
    tagline: "IDK IF YOU CAN HANDLE THIS MOVIE!",
    taglineWriter: "JJ Gittes, P.I.",
    bulletOne:
      "Using deep cuts like insulting your beer league softball ability.",
    bulletTwo: "Waiting for your set of steak knives.",
    cast: "Directed by ROB REINER starring TOM CRUISE JACK NICKOLSON DEMI MOORE and KEVIN BACON",
  },
  {
    id: 436,
    name: "The Butterfly Effect",
    year: 2004,
    poster:
      "https://m.media-amazon.com/images/M/MV5BODNiZmY2MWUtMjFhMy00ZmM2LTg2MjYtNWY1OTY5NGU2MjdjL2ltYWdlXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Kelso uses time traveling powers, passed on by his father, to change his life for the better; or so he hopes. Amy Smart co-stars in this trippy “what if” drama.",
    genre: "Scifi",
    subgenre: "Thriller",
    runtime: "113 min",
    pairs: "a Dog in the Sack.",
    drinkRecipe: "Sweet tea, whiskey.",
    rated: "R",
    picture1: butterfly1,
    picture2: butterfly2,
    picture3: butterfly3,
    tagline: "IT CAN SUCK MY NUTS!",
    taglineWriter: "Mötley Löu",
    bulletOne: "Getting stabbed by jagged sheet metal.",
    bulletTwo: "Prostitution in alternate timelines.",
    cast: "Directed by ERIC BRESS starring ASHTON KUTCHER AMY SMART ELDEN HENSON and KEVIN DURAND",
  },
  {
    id: 437,
    name: "Man on Fire",
    year: 2004,
    poster:
      "https://m.media-amazon.com/images/M/MV5BODFlMmEwMDgtYjhmZi00ZTE5LTk2NWQtMWE1Y2M0NjkzOGYxXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "146 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 438,
    name: "Philadelphia",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDE0MWE1ZTMtOWFkMS00YjdiLTkwZTItMDljYjY3MjM0NTk5XkEyXkFqcGdeQXVyNDYyMDk5MTU@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Alonzo AIDs Woody with his battle against homophobia in the City of Brotherly Love.",
    genre: "Drama",
    subgenre: "",
    runtime: "125 min",
    pairs: "a City Wide.",
    drinkRecipe: "Can of PBR, shot of whiskey.",
    rated: "PG-13",
    picture1: philadelphia1,
    picture2: philadelphia2,
    picture3: philadelphia3,
    tagline: "Everything Tom Hanks says is a stitch!",
    taglineWriter: "P. Tear Gryphon",
    bulletOne: "Original music from the Boss.",
    bulletTwo: "The beginning of Hanks' weight loss addiction.",
    cast: "Directed by JONATHAN DEMME starring TOM HANKS DENZEL WASHINGTON ROBERTA MAXWELL and DANIEL CHAPMAN",
  },
  {
    id: 439,
    name: "The Rock",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDJjOTE0N2EtMmRlZS00NzU0LWE0ZWQtM2Q3MWMxNjcwZjBhXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "136 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 440,
    name: "School of Rock",
    year: 2003,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjEwOTMzNjYzMl5BMl5BanBnXkFtZTcwNjczMTQyMQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "109 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 441,
    name: "Gone in 60 Seconds",
    year: 2000,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTIwMzExNDEwN15BMl5BanBnXkFtZTYwODMxMzg2._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "This is Golden Era Nic Cage. He has one night to steal 50 cars or his brother is toast. Awesome action, characters, and car chases.",
    genre: "Action",
    subgenre: "Crime",
    runtime: "118 min",
    pairs: "an Eleanor.",
    drinkRecipe: "Moonshine, lemon juice. You'll be gone in 59 seconds.",
    rated: "PG-13",
    picture1: seconds1,
    picture2: seconds2,
    picture3: seconds3,
    tagline: "A shitting family of fucking thieves!",
    taglineWriter: "Dominic Touretteso",
    bulletOne: "Using Elvis' fingerprints to steal shit.",
    bulletTwo:
      "Committing 100% to an awful pick up line. 'How about having sex WHILE boosting cars?!'. Nah.",
    cast: "Directed by DOMINIC SENA starring NICOLAS CAGE ANGELINA JOLIE GIOVANNI RIBISI and VINNIE JONES",
  },
  {
    id: 442,
    name: "Ghosts of Girlfriends Past",
    year: 2009,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTA0Njk2NTIyMTVeQTJeQWpwZ15BbWU3MDU0MzUyMzI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Romcom",
    subgenre: "",
    runtime: "100 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 443,
    name: "The Ugly Truth",
    year: 2009,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTM2MTM2OTUwNl5BMl5BanBnXkFtZTcwNTgwNTE0Mg@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Romcom",
    subgenre: "",
    runtime: "96 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 444,
    name: "Troy",
    year: 2004,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTk5MzU1MDMwMF5BMl5BanBnXkFtZTcwNjczODMzMw@@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Bana puts Pitt's IRL swordsmanship to shame in this version of Homer's epic poem.",
    genre: "Action",
    subgenre: "War",
    runtime: "163 min",
    pairs: "",
    noPairs: "a SACK OF WINE!",
    rated: "R",
    picture1: troy1,
    picture2: troy2,
    picture3: troy3,
    tagline: "The jump kill is impractical.",
    taglineWriter: "Deadliest Warrior",
    bulletOne:
      "Letting the good brother die so you can continue being a coward",
    bulletTwo: "Launching a thousand ships for Diane Kruger.",
    cast: "Directed by WOLFGANG PETERSEN starring BRAD PITT ERIC BANA DIANE KRUGER and ORLANDO BLOOM",
  },
  {
    id: 445,
    name: "Pirates of the Caribbean: The Curse of the Black Pearl",
    year: 2003,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNGYyZGM5MGMtYTY2Ni00M2Y1LWIzNjQtYWUzM2VlNGVhMDNhXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "With no ship, no crew, and no rum, Captain Jack Sparrow is without doubt the worst pirate in the Caribbean... but at least you've heard of him.",
    genre: "Adventure",
    subgenre: "Comedy",
    runtime: "143 min",
    noPairs: "an island of rum.",
    rated: "PG-13",
    picture1: piratespearl1,
    picture2: piratespearl2,
    picture3: piratespearl3,
    tagline: "MISREPRESENTATION OF PIRATE CULTURE!",
    taglineWriter: "Black(pearl)beard",
    bulletOne: "The best revenge is well, living.",
    bulletTwo: "Every American's British impression post 2003: 'Ello poppet!'",
    cast: "Directed by GORE VERBINSKI starring JOHNNY DEPP ORLANDO BLOOM KEIRA KNIGHTLEY and JACK DAVENPORT",
  },
  {
    id: 446,
    name: "Pirates of the Caribbean: Dead Man's Chest",
    year: 2006,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTcwODc1MTMxM15BMl5BanBnXkFtZTYwMDg1NzY3._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Adventure",
    subgenre: "Comedy",
    runtime: "151 min",
    pairs: "a Surly Seabeast.",
    drinkRecipe: "Kraken and Coke.",
    rated: "PG-13",
    picture1: deadMansChest1,
    picture2: deadMansChest2,
    picture3: deadMansChest3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne:
      "Captain Jack is back and ready for another light hearted pirate adventure! With some cannibals and a self beating heart… yeah it’s getting a little weird now.",
    bulletTwo: "",
    cast: "Directed by GORE VERBINSKI starring JOHNNY DEPP ORLANDO BLOOM KEIRA KNIGHTLEY and JACK DAVENPORT",
  },
  {
    id: 447,
    name: "Pirates of the Caribbean: At World's End",
    year: 2007,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjIyNjkxNzEyMl5BMl5BanBnXkFtZTYwMjc3MDE3._V1_SX300.jpg",
    rating: 3,
    synopsis: "",
    genre: "Adventure",
    subgenre: "",
    runtime: "169 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by GORE VERBINSKI starring JOHNNY DEPP ORLANDO BLOOM KEIRA KNIGHTLEY and GEOFFREY RUSH",
  },
  {
    id: 448,
    name: "The Corruptor",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BN2RmNmM4ZmYtZWQ5OS00OTkyLWI3NWMtNDJmYjA5YWFkNWMxXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 3,
    synopsis: "",
    genre: "Action",
    subgenre: "Crime",
    runtime: "110 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 449,
    name: "The Other Guys",
    year: 2010,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTc0NDQzNTA2Ml5BMl5BanBnXkFtZTcwNzI2OTQzMw@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Action",
    runtime: "107 min",
    pairs: "Arnie Palmies.",
    drinkRecipe: "Iced tea, lemonade, Vodka.",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 450,
    name: "Daddy's Home",
    year: 2015,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQ0OTE1MTk4N15BMl5BanBnXkFtZTgwMDM5OTk5NjE@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "96 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 451,
    name: "Daddy's Home 2",
    year: 2017,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDA4Nzc1OTg2OV5BMl5BanBnXkFtZTgwODE3ODgwNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "100 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 452,
    name: "Syriana",
    year: 2005,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjA2MTQwMTg0N15BMl5BanBnXkFtZTcwOTE5NjEzMQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Thriller",
    subgenre: "War",
    runtime: "128 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 453,
    name: "Paycheck",
    year: 2003,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTczMzU5NDk4MV5BMl5BanBnXkFtZTYwNDQxNzc3._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Scifi",
    subgenre: "Thriller",
    runtime: "119 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 454,
    name: "Murder By Numbers",
    year: 2002,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTI4MTcyMTU1OF5BMl5BanBnXkFtZTYwMTE5MDE3._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Crime",
    subgenre: "Thriller",
    runtime: "115 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 455,
    name: "The Exorcist",
    year: 1973,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjhmMGMxZDYtMTkyNy00YWVmLTgyYmUtYTU3ZjcwNTBjN2I1XkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Horror",
    subgenre: "",
    runtime: "122 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 456,
    name: "Lucky Number Slevin",
    year: 2006,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjczMWI1YWMtYTZjOS00ZDc5LWE2MWItMTY3ZGUxNzFkNjJmL2ltYWdlXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Crime",
    subgenre: "",
    runtime: "110 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 457,
    name: "21",
    year: 2008,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjAyNTU5OTcxOV5BMl5BanBnXkFtZTcwMDEyNjM2MQ@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Crime",
    subgenre: "Drama",
    runtime: "123 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 458,
    name: "17 Again",
    year: 2009,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjA2NTI1Mzg3N15BMl5BanBnXkFtZTcwMjYwNjAzMg@@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Through the powers of a mystical janitor, Mike O’Donnell is transformed back to his 17 year old self in order to win back his wife during a pending divorce.",
    genre: "Comedy",
    subgenre: "",
    runtime: "102 min",
    pairs: "",
    noPairs: "being old enough to drink.",
    rated: "PG-13",
    picture1: seventeenAgain1,
    picture2: seventeenAgain2,
    picture3: seventeenAgain3,
    tagline: "At least he's not 13 going on 30!",
    taglineWriter: "Jennifer Garner",
    bulletOne: "Letting your rich friend pay for everything.",
    bulletTwo:
      "If you thought the McFly’s incestuous relationship was odd imagine a father fighting off the advances of his daughter.",
    cast: "Directed by BURR STEERS starring ZAC EFRON MATTHEW PERRY LESLIE MANN and THOMAS LENNON",
  },
  {
    id: 459,
    name: "Love Actually",
    year: 2003,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTY4NjQ5NDc0Nl5BMl5BanBnXkFtZTYwNjk5NDM3._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Romcom",
    subgenre: "",
    runtime: "135 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 460,
    name: "Two Weeks Notice",
    year: 2002,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTg4N2M2ODItZjVjMy00YTljLTllNjUtYzY4ZTE2MTI2M2RhXkEyXkFqcGdeQXVyNjExODE1MDc@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Romcom",
    subgenre: "",
    runtime: "101 min",
    pairs: "",
    rated: "PG-13",
    picture1: twoWeeks1,
    picture2: twoWeeks2,
    picture3: twoWeeks3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 461,
    name: "Notting Hill",
    year: 2002,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTE5OTkwYzYtNDhlNC00MzljLTk1YTktY2IxZjliZmNjMjUzL2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Romcom",
    subgenre: "",
    runtime: "124 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 462,
    name: "Mickey Blue Eyes",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjIzNDQ4MDc5OV5BMl5BanBnXkFtZTYwNzI3MDM3._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "A toothy bloke becomes entagled with the mob and needs to act like a mafioso - despite being a refined English auctioneer. ",
    genre: "Comedy",
    subgenre: "Romcom, Crime",
    runtime: "102 min",
    pairs: "Fish out of Water.",
    drinkRecipe: "Blue Curaçao, Glacier Freeze Gatorade, vodka.",
    rated: "PG-13",
    picture1: mickeyBlue1,
    picture2: mickeyBlue2,
    picture3: mickeyBlue3,
    tagline: "DOGGONE FUNNY!",
    taglineWriter: "James Caanine",
    bulletOne:
      "This is a quality romantic-comedy-crime-movie you definitely won't fuggedaboud.",
    bulletTwo: "Hugh Grant trying to do an Italian American gangster accent.",
    cast: "Directed by KELLY MAKIN starring HUGH GRANT JAMES CAAN JEANNE TRIPPLEHORN and JOE VITERELLI",
  },
  {
    id: 463,
    name: "Mr. Brooks",
    year: 2007,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQyNTk5MTMxN15BMl5BanBnXkFtZTcwNTc0ODI1NA@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Crime",
    subgenre: "Thriller",
    runtime: "120 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 464,
    name: "Taken",
    year: 2008,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTM4NzQ0OTYyOF5BMl5BanBnXkFtZTcwMDkyNjQyMg@@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "90 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 465,
    name: "Taken 2",
    year: 2012,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTkwNTQ0ODExOV5BMl5BanBnXkFtZTcwNjU3NDQwOA@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "92 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 466,
    name: "Taken 3",
    year: 2014,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjM5MDU3NTY0M15BMl5BanBnXkFtZTgwOTk2ODU2MzE@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "108 min",
    pairs: "",
    rated: "R",
    picture1: taken31,
    picture2: taken32,
    picture3: taken33,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by OLIVIER MEGATON starring LIAM NEESON FOREST WHITAKER MAGGIE GRACE and FAMKE JANSSEN",
  },
  {
    id: 467,
    name: "Shrek",
    year: 2001,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOGZhM2FhNTItODAzNi00YjA0LWEyN2UtNjJlYWQzYzU1MDg5L2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "90 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 468,
    name: "Shrek 2",
    year: 2004,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDJhMGRjN2QtNDUxYy00NGM3LThjNGQtMmZiZTRhNjM4YzUxL2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "93 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 469,
    name: "Shrek the Third",
    year: 2007,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTgyMjc3ODk2MV5BMl5BanBnXkFtZTcwMjY0MjEzMw@@._V1_SX300.jpg",
    rating: 3,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "93 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 470,
    name: "Rat Race",
    year: 2001,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNWEzM2NjMjctM2U3Yi00MGZhLWJlYTYtMWEyYjVjZDEzZjM4XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "112 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 471,
    name: "The Inbetweeners Movie",
    year: 2011,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTAyNmQyNjItYmQ0OS00ZGVkLTk3NTItYTE2YjkxNTkxZWU3XkEyXkFqcGdeQXVyODE5NzE3OTE@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "The lads are back on pussayyy patrol and head to Greece for summer holiday. Crank up the “We No Speak Americano”, double check your bumps, and remember if you shit on the floor that’s 50 Euro.",
    genre: "Comedy",
    subgenre: "British",
    runtime: "97 min",
    pairs: "a Fishbowl4Friends.",
    drinkRecipe:
      "Berry Blue Typhoon Hawaiian Punch, Smirnoff Ice, Brexitovka-- 4 straws.",
    rated: "R",
    picture1: inbetweeners1,
    picture2: inbetweeners2,
    picture3: inbetweeners3,
    tagline: "What the hell is a 'fit bird'?!",
    taglineWriter: "Mamadou Bah",
    bulletOne: "Cheering when you didn't book the shit hotel.",
    bulletTwo:
      "Accidentally stalking your ex but actually being super excited about it.",
    cast: "Directed by BEN PALMER starring SIMON BIRD JOE THOMAS JAMES BUCKLEY and BLAKE HARRISON",
  },
  {
    id: 472,
    name: "The Inbetweeners Movie 2",
    year: 2014,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTI3OTc1YTEtNGQ5My00ZGRiLWE4YTYtNjNhMDRiOWI4Y2RhXkEyXkFqcGdeQXVyODE5NzE3OTE@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "96 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 473,
    name: "Narc",
    year: 2002,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjVhMWI2ZjgtNzNiNy00Mjc2LTljZDQtNGYwMzYwMTlhZGFhXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Crime",
    subgenre: "",
    runtime: "105 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 474,
    name: "A Man Apart",
    year: 2003,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTk4NzEwNjk1N15BMl5BanBnXkFtZTYwNTEzNTk5._V1_SX300.jpg",
    rating: 3,
    synopsis: "",
    genre: "Crime",
    subgenre: "Action",
    runtime: "109 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 475,
    name: "Juno",
    year: 2007,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTIwMDgwODc5Nl5BMl5BanBnXkFtZTYwMjQzMDM4._V1_SX300.jpg",
    rating: 3,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "96 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 476,
    name: "Munich",
    year: 2005,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTcwNzYzMzMwMF5BMl5BanBnXkFtZTcwMzMzODczMQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Every movie with Jews, they’re the ones getting killed. ‘Munich’ flips it on its ear. They're not only killing, but taking names.",
    genre: "Action",
    subgenre: "Drama",
    runtime: "164 min",
    pairs: "",
    noPairs: "a stein of Hofbrauhaus Dunkel.",
    rated: "R",
    picture1: munich1,
    picture2: munich2,
    picture3: munich3,
    tagline: "ARE JEW TALKING TO ME?",
    taglineWriter: "Scarface",
    bulletOne:
      "The ONLY reason Seth Rogen ever got laid is because of Eric Bana.",
    bulletTwo:
      "Strange assassinations with weapons like a tap-powered air pump.",
    cast: "Directed by STEVEN SPIELBERG starring EIRC BANA DANIEL CRAIG CIARAN HINDS and MATHIEU KASSOVITZ",
  },
  {
    id: 477,
    name: "The Ring",
    year: 2002,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDA2NTg2NjE4Ml5BMl5BanBnXkFtZTYwMjYxMDg5._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Horror",
    subgenre: "",
    runtime: "115 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 478,
    name: "The Grudge",
    year: 2004,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjIxODg1Nzc3NF5BMl5BanBnXkFtZTcwMjM0MjEzMw@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Horror",
    subgenre: "",
    runtime: "91 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 479,
    name: "Daredevil",
    year: 2003,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjYwZDNhMTgtNjEwNS00Y2Y0LTkxYjMtY2MyYTM0NDE1N2ZlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 3,
    synopsis: "",
    genre: "Superhero",
    subgenre: "Action",
    runtime: "133 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 480,
    name: "The Blind Side",
    year: 2009,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjEzOTE3ODM3OF5BMl5BanBnXkFtZTcwMzYyODI4Mg@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Sports",
    subgenre: "Drama",
    runtime: "129 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 481,
    name: "Mr. Majestyk",
    year: 1974,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTU5NDY1ODE5MV5BMl5BanBnXkFtZTcwMzkwMDM1NA@@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Charles Bronson is a farmer that everyone is clamoring to work for. Local crooks and the mafia alike want to styck their hand in his pie, but we all know Bronsons hands can crush anything - especially crymynal nytwyts.",
    genre: "Action",
    subgenre: "Crime",
    runtime: "103 min",
    pairs: "a Crushing Gryp.",
    drinkRecipe: "Watermelon Vodka, Midori.",
    rated: "PG",
    picture1: majestyk1,
    picture2: majestyk2,
    picture3: majestyk3,
    tagline: "THOSE ARE SOME FINE MELONS!",
    taglineWriter: "Gallagher",
    bulletOne: "Sudden and violent mood swings.",
    bulletTwo:
      "Usually 'melon farmer' is a censored version of 'motherfucker'. In this movie, Bronson is both.",
    cast: "Directed by RICHARD FLEISCHER starring CHARLES BRONSON LINDA CRISTAL AL LETTIERI and LEE PURCELL",
  },
  {
    id: 482,
    name: "Joe",
    year: 2013,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjExMzk5MTM1Ml5BMl5BanBnXkFtZTgwNzAzODgxMTE@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Drama",
    subgenre: "Crime",
    runtime: "117 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 484,
    name: "The Thomas Crown Affair",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmU0N2RiNTAtN2U4MS00MWExLTkzNGYtOTVhNmVmZmNlOTUzXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Crime",
    subgenre: "",
    runtime: "113 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 485,
    name: "Shanghai Noon",
    year: 2000,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTI0MjE2MzUwOV5BMl5BanBnXkFtZTYwMTk5NjU3._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "Comedy, Western",
    runtime: "110 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 486,
    name: "Shanghai Knights",
    year: 2003,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTMxMTgwOTI3Nl5BMl5BanBnXkFtZTYwMTI2NDQ3._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "Comedy, Western",
    runtime: "114 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 487,
    name: "Disturbia",
    year: 2007,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTMyNTIxOTQ3M15BMl5BanBnXkFtZTcwMjU2NzAzMw@@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A modern take on “Rear Window”, Louis Stevens is under house arrest and starts noticing his neighborhood’s nefarious activities. This film will surely have you rethink heading to the block party.",
    genre: "Thriller",
    subgenre: "Mystery",
    runtime: "105 min",
    pairs: "a Peeping Tom.",
    drinkRecipe: "Gin, lemon juice, club soda.",
    rated: "PG-13",
    picture1: disturbia1,
    picture2: disturbia2,
    picture3: disturbia3,
    tagline: "It's like the darkness is the light",
    taglineWriter: "Rihanna",
    bulletOne: "Beating up your teacher.",
    bulletTwo:
      "Taking time away from the real world to experience motherhood in the Matrix.",
    cast: "Directed by D.J. CARUSO starring SHIA LaBEOUF DAVID MORSE CARRIE-ANNE MOSS and SARAH ROEMER ",
  },
  {
    id: 488,
    name: "Flightplan",
    year: 2005,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGQyZmQ1OGItYWNkNi00YzU4LWJmZjktNTQyZjNlOGNmNWJlXkEyXkFqcGc@._V1_SX300.jpg",
    rating: 3,
    synopsis: "",
    genre: "Thriller",
    subgenre: "Mystery",
    runtime: "98 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 489,
    name: "The Social Network",
    year: 2010,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOGUyZDUxZjEtMmIzMC00MzlmLTg4MGItZWJmMzBhZjE0Mjc1XkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Drama",
    subgenre: "",
    runtime: "120 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 490,
    name: "Public Enemies",
    year: 2009,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGI4YTIxMDQtNDlmNC00ZDJhLWIyZGItM2QyODRiMTEzN2ViXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Crime",
    subgenre: "",
    runtime: "120 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 491,
    name: "Weird Science",
    year: 1985,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTEwMTU1YjMtMjc0OS00NjZiLTk1MGMtNzhkODI0ZDFjNGI2XkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Scifi",
    runtime: "94 min",
    pairs: "a Double D.",
    drinkRecipe: "two shots of Dewar's",
    rated: "PG-13",
    picture1: weirdScience1,
    picture2: weirdScience2,
    picture3: weirdScience3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by JOHN HUGHES starring ANTHONY MICHAEAL HALL KELLY LEBROCK IIAN MITCHELL-SMITH and BILL PAXTON",
  },
  {
    id: 492,
    name: "BASEketball",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmZjZDg2ZjItYTVkNC00YWRmLThmZTMtNTYxNTI5MWM4NjU4XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "103 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 493,
    name: "Team America: World Police",
    year: 2004,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTM2Nzc4NjYxMV5BMl5BanBnXkFtZTcwNTM1MTcyMQ@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "98 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 494,
    name: "South Park: Bigger, Longer, & Uncut",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOGE0ZWI0YzAtY2NkZi00YjkyLWIzYWEtNTJmMzJjODllNjdjXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "81 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 495,
    name: "Something's Gotta Give",
    year: 2003,
    poster:
      "https://m.media-amazon.com/images/M/MV5BODYyNmRlNDMtYzg1Zi00NzlhLWI4ZDMtZWUwOTQzMDZiYTQyXkEyXkFqcGdeQXVyNjE5MjUyOTM@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "An aged man who still sleeps with young women is grossed out by old tits. ",
    genre: "Romcom",
    subgenre: "",
    runtime: "128 min",
    pairs: "a Wizards Sleeve.",
    drinkRecipe: "vermouth, pineapple juice, club soda",
    rated: "PG-13",
    picture1: gottaGive1,
    picture2: gottaGive2,
    picture3: gottaGive3,
    tagline: "AS GOOD AS IT GETS!",
    taglineWriter: "Greg Kinnear",
    bulletOne: "Preferring to die instead of admitting you use boner pills.",
    bulletTwo:
      "Universal truth: if you can climb a flight of stairs, you can have sex.",
    cast: "Directed by NANCY MEYERS starring JACK NICHOLSON DIANE KEATON KEANU REEVES and AMANDA PEET",
  },
  {
    id: 496,
    name: "It's Complicated",
    year: 2009,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjQ0NjM2NTI4N15BMl5BanBnXkFtZTcwNjQ2MDc5Mg@@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Complications arise in this modern love story that shows it’s never too late to rekindle an old flame. One big problem, they’re both with other people.",
    genre: "Romcom",
    subgenre: "",
    runtime: "120 min",
    pairs: "",
    noPairs: "a bottle Hobnob for Peggy.",
    rated: "R",
    picture1: complications1,
    picture2: complications2,
    picture3: complications3,
    tagline: "I love that mom and dad are banging again!",
    taglineWriter: "No one",
    bulletOne: "No matter your age, love makes you do strange things.",
    bulletTwo: "Alec Baldwin is fat and everyone calls him 'big guy'.",
    cast: "Directed by NANCY MEYERS starring ALEC BALDWIN MERYL STREEP STEVE MARTIN and JOHN KRASINSKI",
  },
  {
    id: 497,
    name: "As Good as It Gets",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNWMxZTgzMWEtMTU0Zi00NDc5LWFkZjctMzUxNDIyNzZiMmNjXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Romcom",
    subgenre: "Comedy",
    runtime: "139 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 498,
    name: "Anger Management",
    year: 2003,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDg1YTNiODItZGYyOC00NGIwLWI3MjYtMTY3NDQ0ZDIwYjBiXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "106 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 499,
    name: "Billy Madison",
    year: 1995,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzcyMjZmNjctNGNhMS00ZmQxLWFkNzQtYTIxYjVkYmU1NmNmXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "To inherit a hotel empire, an idiot must attend grades 1-12 all over again. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "89 min",
    pairs: "a borophyll.",
    drinkRecipe: "Midori, apple schnapps, lime gatorade",
    rated: "PG-13",
    picture1: billyMadison1,
    picture2: billyMadison2,
    picture3: billyMadison3,
    tagline: "THIS MOVIE RULES!",
    taglineWriter: "O' Doyle",
    bulletOne: "Being glad you called that guy.",
    bulletTwo: "Getting special motivation to study.",
    cast: "Directed by TAMRA DAVIS starring ADAM SANDLER BRADLEY WHITFORD BRIDGETTE WILSON-SAMPRAS and LARRY HANKIN",
  },
  {
    id: 500,
    name: "Happy Gilmore",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZWI2NjliOTYtZjE1OS00YzAyLWJjYTQtYWNmZTQzMTQzNzVjXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Billy Madison trades his hockey stick for a golf club and uncovers heaps of natural talent. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "92 min",
    pairs: "a John Daly.",
    drinkRecipe: "lemonade, iced tea, vodka",
    rated: "PG-13",
    picture1: happyGilmore1,
    picture2: happyGilmore2,
    picture3: happyGilmore3,
    tagline: "TIGE HER WOODS? I BARELY KNOW 'ER!",
    taglineWriter: "Chip Eagle",
    bulletOne: "Eating pieces of shit for breakfast.",
    bulletTwo: "The hot mom from Modern Family.",
    cast: "Directed by DENNIS DUGAN starring ADAM SANDLER CHRISTOPHER MCDONALD JULIE BOWEN and CARL WEATHERS",
  },
  {
    id: 501,
    name: "A Night at the Roxbury",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTFjYmQ2ZTAtNGE4YS00NzMxLWIwZjItMjcyYzdjODRmZWQ1XkEyXkFqcGdeQXVyMTY5Nzc4MDY@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "82 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 502,
    name: "Dirty Work",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjE0NzkzNDI3Ml5BMl5BanBnXkFtZTcwNjA0NzQzNA@@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "82 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 503,
    name: "Almost Heroes",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BY2IyNTI2YzktNzUwMy00ZTMyLTkyYjItMzk1OTE3NjkxMTk1XkEyXkFqcGdeQXVyNzc5MjA3OA@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "90 min",
    pairs: "",
    rated: "PG-13",
    picture1: almostHeroes1,
    picture2: almostHeroes2,
    picture3: almostHeroes3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by CHRISTOPHER GUEST starring CHRIS FARLEY MATTHEW PERRY LISA BARBUSCIA and BOKEEM WOODBINE",
  },
  {
    id: 504,
    name: "Ice Age",
    year: 2002,
    poster:
      "https://m.media-amazon.com/images/M/MV5BM2JmZGJkNzAtOWI0MC00ZmE5LWIwMjYtODU0OGUxNDg4ZDkwXkEyXkFqcGdeQXVyMTA5ODEyNTc5._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "81 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 505,
    name: "Face/Off",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzFjNzIxMmEtMzY5NS00YTgzLTkwYWEtN2FjMmY0NmNkZWY3XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "Thriller",
    runtime: "138 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 506,
    name: "War",
    year: 2007,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTIwMjE2Mjc1MF5BMl5BanBnXkFtZTYwNzI0OTI3._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "103 min",
    pairs: "",
    rated: "R",
    picture1: war1,
    picture2: war2,
    picture3: war3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "Statham's flawless American Accent",
    bulletTwo: "",
    cast: "Directed by PHILIP G. ATWELL starring JET LI JASON STATHAM NADINE VELAZQUEZ and JOHN LONE",
  },
  {
    id: 507,
    name: "Fearless",
    year: 2006,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTI5MjA2Mzk2M15BMl5BanBnXkFtZTcwODY1MDUzMQ@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Sports",
    subgenre: "Action",
    runtime: "104 min",
    pairs: "",
    rated: "PG-13",
    picture1: fearless1,
    picture2: fearless2,
    picture3: fearless3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by RONNY YU starring JET LI LI SUN COLLIN CHOU and SHIDO NAKAMURA",
  },
  {
    id: 508,
    name: "Ice Age: The Meltdown",
    year: 2006,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjAwODg3OTAxMl5BMl5BanBnXkFtZTcwMjg2NjYyMw@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "91 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 509,
    name: "Ice Age: Dawn of the Dinosaurs",
    year: 2009,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTMzNDkzMTcyOV5BMl5BanBnXkFtZTcwNDIzMjM2MQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "94 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 510,
    name: "Ratatouille",
    year: 2007,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTMzODU0NTkxMF5BMl5BanBnXkFtZTcwMjQ4MzMzMw@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "111 min",
    pairs: "",
    rated: "G",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 511,
    name: "Unleashed",
    year: 2005,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTgwNjIyNTczMF5BMl5BanBnXkFtZTcwODI5MDkyMQ@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Crime",
    subgenre: "Action",
    runtime: "103 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 512,
    name: "Kate & Leopold",
    year: 2001,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNmNlN2VlOTctYTdhMS00NzUxLTg0ZGMtYWE2ZTJmMThlMTk2XkEyXkFqcGdeQXVyMzI0NDc4ODY@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Romcom",
    subgenre: "",
    runtime: "118 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 513,
    name: "High Fidelity",
    year: 2000,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTgxMTI1ODYyOV5BMl5BanBnXkFtZTYwNjQyOTI3._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Romcom",
    subgenre: "Comedy",
    runtime: "118 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 514,
    name: "Grosse Pointe Blank",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmRhYTg0N2QtMWZhMS00OWQwLTk1ZmEtMmUyMTY0NTE4YWUwXkEyXkFqcGdeQXVyNzQ1ODk3MTQ@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Martin Q. Blank (John Cusak) is a simple guy, he just so happens to be an assassin that receives a hit on someone in his hometown on the weekend of his high school reunion. ",
    genre: "Action",
    subgenre: "",
    runtime: "107 min",
    pairs: "",
    noPairs: "a Steam Tunnel Stout.",
    rated: "R",
    picture1: gpb1,
    picture2: gpb2,
    picture3: gpb3,
    tagline: "PREPARE TO BE WHACKED!",
    taglineWriter: "Pee-wee Herman",
    bulletOne: "Benny Urquidez looking strange as ever.",
    bulletTwo: "Dan Aykroyd being a wacky and cartoonish psycho.",
    cast: "Directed by GEORGE ARMITAGE starring JOHN CUSACK MINNIE DRIVER DAN AYKROYD and JOAN CUSACK",
  },
  {
    id: 515,
    name: "Perfume: The Story of a Murderer",
    year: 2006,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTg2Mzk2NjkzNl5BMl5BanBnXkFtZTYwMzIzOTc2._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Thriller",
    subgenre: "Crime",
    runtime: "147 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 516,
    name: "The Fugitive",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYmFmOGZjYTItYjY1ZS00OWRiLTk0NDgtMjQ5MzBkYWE2YWE0XkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Thriller",
    subgenre: "Action",
    runtime: "130 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 517,
    name: "Wrongfully Accused",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOGI5NWUwNmItZDBmZS00Yjg5LWE0ZWMtMjAzYmVlNWIwMmEzL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "One of Nielsen's funniest movies, Wrongfully Accused is a tremendous parody of 'The Fugitive'. If you haven't seen this movie, you're a pee pee head.",
    genre: "Comedy",
    subgenre: "",
    runtime: "87 min",
    pairs: "a Wooden Leg.",
    drinkRecipe: "Just make a humongous Irish coffee.",
    rated: "PG-13",
    picture1: wrongfullyAccused1,
    picture2: wrongfullyAccused2,
    picture3: wrongfullyAccused3,
    tagline: "Cancel the mensroom meeting and watch this!",
    taglineWriter: "Buzzin' Frog",
    bulletOne: "Paying for parking while the hydraulics are kickin'.",
    bulletTwo:
      "Knowing the length of a persons stride and the time of their last meal.",
    cast: "Directed by PAT PROFT starring LESLIE NIELSEN RICHARD CRENNA KELLY LeBROCK and MICHAEL YORK",
  },
  {
    id: 518,
    name: "The Interpreter",
    year: 2005,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTg3MjMxNDUzNV5BMl5BanBnXkFtZTcwNTM2NzgyMQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Thriller",
    subgenre: "Mystery",
    runtime: "128 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 519,
    name: "Rounders",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzViMmMxMzItYmYyYi00NGU3LWI2MDMtNjcwOWFmZTZkOTcwXkEyXkFqcGdeQXVyNDkzNTM2ODg@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "Will Hunting learns how to count cards and hustle people at poker.",
    genre: "Sports",
    subgenre: "Drama",
    runtime: "121 min",
    pairs: "Cookies & Cream",
    drinkRecipe: "oreo milkshake, add bourbon.",
    rated: "R",
    picture1: rounders1,
    picture2: rounders2,
    picture3: rounders3,
    tagline: "GOOD AS A FULL HOUSE!",
    taglineWriter: "Bob Saget",
    bulletOne: "John Malkovich's Russian accent",
    bulletTwo: "Ed Norton's ability to play another creep",
    cast: "Directed by JOHN DAHL starring MATT DAMON EDWARD NORTON JOHN MALKOVICH and GRETCHEN MOL",
  },
  {
    id: 520,
    name: "Unknown",
    year: 2011,
    poster:
      "https://m.media-amazon.com/images/M/MV5BODA4NTk3MTQwN15BMl5BanBnXkFtZTcwNjUwMTMxNA@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Thriller",
    subgenre: "",
    runtime: "113 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 521,
    name: "Say Anything",
    year: 1989,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTg5OTk1NjczNl5BMl5BanBnXkFtZTgwNTE3NjkzMTE@._V1_SX300.jpg",
    rating: 4,
    synopsis: "A duster-clad creep stalks a girl against her parents wishes.",
    genre: "Romcom",
    subgenre: "Drama",
    runtime: "100 min",
    pairs: "Boombox.",
    drinkRecipe: "Coke, whiskey, and wine.",
    rated: "PG-13",
    picture1: sayAnything1,
    picture2: sayAnything2,
    picture3: sayAnything3,
    tagline: "I'll never have rough sex to this movie again!",
    taglineWriter: "Molly Connelly",
    bulletOne: "Coining the stereotypical move for getting an ex back",
    bulletTwo: "It's only sweet because he's attractive.",
    cast: "Directed by CAMERON CROWE starring JOHN CUSACK IONE SKYE JOHN MAHONEY and LILI TAYLOR",
  },
  {
    id: 522,
    name: "Horrible Bosses",
    year: 2011,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzYxNDI5Njc5NF5BMl5BanBnXkFtZTcwMDUxODE1NQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Three dimwits hatch an ill-conceived “Strangers on a Train” plot to kill each other's bosses.",
    genre: "Comedy",
    subgenre: "",
    runtime: "98 min",
    pairs: "laughing gas.",
    drinkRecipe: "THC Soda, 1800 Silver, Monster Ultra Blue",
    rated: "R",
    picture1: horribleBosses1,
    picture2: horribleBosses2,
    picture3: horribleBosses3,
    tagline: "A ROLE I RELATE WITH!",
    taglineWriter: "Kevin Spacey",
    bulletOne: "Starring two Jason’s and a Day.",
    bulletTwo: "Unconvincingly not being attracted to Jennifer Aniston at all.",
    cast: "Directed by SETH GORDON starring JASON BATEMAN CHARLIE DAY JASON SUDEIKIS and JENNIFER ANISTON",
  },
  {
    id: 523,
    name: "Definitely, Maybe",
    year: 2008,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTc1Mzc1ODMxMF5BMl5BanBnXkFtZTcwNDY1MzU1MQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A single father tells tales from his love life to his curious young daughter. If you’re feeling blue, this film will make you right as rain for sure, possibly.",
    genre: "Romcom",
    subgenre: "Comedy",
    runtime: "112 min",
    pairs: "a Wishy-Wash.",
    drinkRecipe: "Blue gatorade, blueberry vodka.",
    rated: "PG-13",
    picture1: definitelyMaybe1,
    picture2: definitelyMaybe2,
    picture3: definitelyMaybe3,
    tagline: "I COULD BE YOUR MAYBE, INDEFINITELY!",
    taglineWriter: "Samantha James",
    bulletOne: "The NDC is basically the Olympic Village.",
    bulletTwo: "It's hard to look right at you baby, so watch it maybe?",
    cast: "Directed by ADAM BROOKS starring RYAN REYNOLDS RACHEL WEISZ ABIGAIL BRESLIN and BOB WILTFONG",
  },
  {
    id: 524,
    name: "The Forbidden Kingom",
    year: 2008,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTUwNTExMTg3NF5BMl5BanBnXkFtZTcwNDYyMTM2MQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Adventure",
    subgenre: "Action",
    runtime: "104 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 525,
    name: "The Way Way Back",
    year: 2013,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTU5ODk5NDg0Nl5BMl5BanBnXkFtZTcwNzQwMjI1OQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "103 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 526,
    name: "American Made",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTUxNzUwMjk1Nl5BMl5BanBnXkFtZTgwNDkwODI1MjI@._V1_SX300.jp",
    rating: 5,
    year: 2017,
    synopsis:
      "T.C. is back to the sky zipping north, south, east and west for the CIA, DEA, FBI, and Colombian Cartels.",
    genre: "Action",
    subgenre: "Thriller",
    runtime: "115 min",
    pairs: "a Photo Bomb.",
    drinkRecipe: "CUMBÉ Aguardiente, Lemon Lime Gatorade, O.J.",
    noPairs: "",
    rated: "R",
    picture1: americanmade1,
    picture2: americanmade2,
    picture3: americanmade3,
    tagline: "Cute plane, fly-boy.",
    taglineWriter: "Bret Maverick",
    bulletOne: "Tiresome Texan Twang.",
    bulletTwo: "I wouldn't believe any of it if it weren't on VHS.",
    cast: "Directed by DOUG LIMAN starring TOM CRUISE DOMHNALL GLEESON JESSE PLEMONS and SARAH WRIGHT",
  },
  {
    id: 527,
    name: "Dirty Harry",
    year: 1971,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzdhMTM2YTItOWU2YS00MTM0LTgyNDYtMDM1OWM3NzkzNTM2XkEyXkFqcGdeQXVyNjc1NTYyMjg@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Hard-nosed cop Harry Callahan pursues a Zodiac wannabe in San Francisco.",
    genre: "Crime",
    subgenre: "Action",
    runtime: "102 min",
    pairs: "a Hammerhead.",
    drinkRecipe: "Rum, Dr. Pepper, crushed ice",
    rated: "R",
    picture1: dirtyHarry1,
    picture2: dirtyHarry2,
    picture3: dirtyHarry3,
    tagline: "IT'LL BLOW YOUR HEAD CLEAN OFF",
    taglineWriter: ".44 Magnum P.I.",
    bulletOne: "Smoking criminals with a mouth full of hotdog.",
    bulletTwo:
      "Spitting a sweet line and exiting the room before anyone can respond.",
    cast: "Directed by DON SIEGEL starring CLINT EASTWOOD ANDY ROBINSON RENI SANTONI and JOHN VERNON",
  },
  {
    id: 528,
    name: "Deadpool",
    year: 2016,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzE5MjY1ZDgtMTkyNC00MTMyLThhMjAtZGI5OTE1NzFlZGJjXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Superhero",
    subgenre: "Comedy",
    runtime: "108 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 529,
    name: "Deadpool 2",
    year: 2018,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjk1Njk3YjctMmMyYS00Y2I4LThhMzktN2U0MTMyZTFlYWQ5XkEyXkFqcGdeQXVyODM2ODEzMDA@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Superhero",
    subgenre: "Comedy",
    runtime: "119 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 530,
    name: "Chupacabra Terror",
    year: 2005,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTA1ODYwNDI1MzheQTJeQWpwZ15BbWU3MDEyNjA5MjE@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Horror",
    subgenre: "",
    runtime: "89 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 531,
    name: "Ballistic: Ecks vs Sever",
    year: 2002,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTkwMDk0OTcyMF5BMl5BanBnXkFtZTYwNzcxMDg5._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "89 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 532,
    name: "Drive",
    year: 2011,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjY5ZjQyMjMtMmEwOC00Nzc2LTllYTItMmU2MzJjNTg1NjY0XkEyXkFqcGdeQXVyNjQ1MTMzMDQ@._V1_SX300.jpg",
    rating: 5,
    synopsis: "A quiet chauffeur beats the ever loving shit out of people.",
    genre: "Action",
    subgenre: "Crime",
    runtime: "100 min",
    pairs: "a Car Chase.",
    drinkRecipe: "Whiskey with Lager chaser.",
    rated: "R",
    picture1: drive1,
    picture2: drive2,
    picture3: drive3,
    tagline: "THIS FLICK TAKES YOU FOR A RIDE!",
    taglineWriter: "The Cars",
    bulletOne: "Sweet soundtrack.",
    bulletTwo: "Old school shaving techniques.",
    cast: "Directed by NICOLAS WINDING REFN starring RYAN GOSLING  CAREY MULLIGAN  ALBERT BROOKS and BRYAN CRANSTON",
  },
  {
    id: 533,
    name: "First Blood",
    year: 1982,
    poster:
      "https://m.media-amazon.com/images/M/MV5BODBmOWU2YWMtZGUzZi00YzRhLWJjNDAtYTUwNWVkNDcyZmU5XkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "War",
    runtime: "93 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 534,
    name: "Rambo: First Blood Part II",
    year: 1985,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZWFkY2I1ZDAtNmZhNS00NjVlLWJiMGQtMGQ1ZmM0ZDA5ODg5XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "War",
    runtime: "96 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 535,
    name: "Rambo III",
    year: 1988,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNGM5ZWZiODEtMTIyYy00ZDYyLWE0M2EtMTgzZDViY2EzNjU4XkEyXkFqcGdeQXVyMjMwNDgzNjc@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "War",
    runtime: "102 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 536,
    name: "Halloween",
    year: 1978,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzk1OGU2NmMtNTdhZC00NjdlLWE5YTMtZTQ0MGExZTQzOGQyXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Horror",
    subgenre: "",
    runtime: "91 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 537,
    name: "The Passion of the Christ",
    year: 2004,
    poster: "https://m.media-amazon.com/images/I/81n7R3poUzL._AC_UY218_.jpg",
    rating: 5,
    synopsis:
      "A fictional tale set around the beginning of Earth’s largest religion.",
    genre: "Drama",
    subgenre: "",
    runtime: "127 min",
    noPairs: "watered down wine.",
    rated: "R",
    picture1: passion1,
    picture2: passion2,
    picture3: passion3,
    tagline: "My next one will be bloodier!",
    taglineWriter: "Lem Gibbons",
    bulletOne: "Jesus introducing the world to cross-fit.",
    bulletTwo: "Realizing your dad hates you.",
    cast: "Directed by MEL GIBSON starring JIM CAVIEZEL MONICA BELLUCCI FRANCESCO DEVITO and LUCA LIONELLO",
  },
  {
    id: 538,
    name: "The Big Sick",
    year: 2017,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZWM4YzZjOTEtZmU5ZS00ZTRkLWFiNjAtZTEwNzIzMDM5MjdmXkEyXkFqcGdeQXVyNDg2MjUxNjM@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Drama",
    runtime: "120 min",
    pairs: "",
    rated: "R",
    picture1: bigSick1,
    picture2: bigSick2,
    picture3: bigSick3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by MICHAEL SHOWALTER starring KUMAIL NANJIANI ZOE KAZAN HOLLY HUNTER and RAY ROMANO",
  },
  {
    id: 539,
    name: "Scott Pilgrim vs. the World",
    year: 2010,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTkwNTczNTMyOF5BMl5BanBnXkFtZTcwNzUxOTUyMw@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "120 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 540,
    name: "I Love You, Man",
    year: 2009,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTU4MjI5NTEyNV5BMl5BanBnXkFtZTcwNjQ1NTMzMg@@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "A man has got to get some fucking male friends or his fiancee won't give him any BJ's.",
    genre: "Comedy",
    subgenre: "",
    runtime: "105 min",
    pairs: "an Andre the Giant.",
    drinkRecipe: "drink a barrel of beer or two.",
    rated: "R",
    picture1: loveyouman1,
    picture2: loveyouman2,
    picture3: loveyouman3,
    tagline: "ME RIKEY THE MOVIE!",
    taglineWriter: "The Hulk",
    bulletOne: "Projectile Vomiting is a real thing. ",
    bulletTwo: "How to tell if someone is about to fart.",
    cast: "Directed by JOHN HAMBURG starring PAUL RUDD JASON SEGEL RASHIDA JONES and ANDY SAMBERG",
  },
  {
    id: 541,
    name: "Bad Times at the El Royale",
    year: 2018,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTk1Nzk1MDc1MF5BMl5BanBnXkFtZTgwNjU2NDExNjM@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A bunch of strangers with dodgy secrets converge at a rundown hotel. ",
    genre: "Thriller",
    subgenre: "Mystery",
    runtime: "141 min",
    pairs: "a Big Kahuna.",
    drinkRecipe: "Rum, coffee liquer, vanilla, cream, cinnamon.",
    rated: "R",
    picture1: elRoyale1,
    picture2: elRoyale2,
    picture3: elRoyale3,
    tagline: "Like Tarzan swinging from vine to vine",
    taglineWriter: "Pete Campbell",
    bulletOne: "Still don't understand it, but it was cool.",
    bulletTwo:
      "This movie tried so hard to look and feel like a Tarantino, we're surprised it wasn't called Bad Times at the El Royale With Cheese.",
    cast: "Directed by DREW GODDARD starring JOHN HAMM CYNTHIA ERIVO CHRIS HEMSWORTH and DAKOTA JOHNSON",
  },
  {
    id: 542,
    name: "Us",
    year: 2019,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTliNWJhM2YtNDc1MC00YTk1LWE2MGYtZmE4M2Y5ODdlNzQzXkEyXkFqcGdeQXVyMzY0MTE3NzU@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Horror",
    subgenre: "Thriller",
    runtime: "116 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 543,
    name: "Get Out",
    year: 2017,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjUxMDQwNjcyNl5BMl5BanBnXkFtZTgwNzcwMzc0MTI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Horror",
    subgenre: "Thriller",
    runtime: "104 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 544,
    name: "BlacKkKlansman",
    year: 2018,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjUyOTE1NjI0OF5BMl5BanBnXkFtZTgwMTM4ODQ5NTM@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "In 1970’s Colorado, a black police officer dupes a local chapter of the KkK.",
    genre: "Crime",
    subgenre: "Drama",
    runtime: "135 min",
    pairs: "",
    noPairs: "Coors with blue moutains.",
    rated: "R",
    picture1: klan1,
    picture2: klan2,
    picture3: klan3,
    tagline: "THIS MOVIE IS RACIST!",
    taglineWriter: "Archie Bunker",
    bulletOne: "JDW goes white-voice",
    bulletTwo: "Finding out where Eric Foreman went",
    cast: "Directed by SPIKE LEE starring JOHN DAVID WASHINGTON ADAM DRIVER LAURA HARRIER and TOPHER GRACE",
  },
  {
    id: 545,
    name: "Knives Out",
    year: 2019,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGUwZjliMTAtNzAxZi00MWNiLWE2NzgtZGUxMGQxZjhhNDRiXkEyXkFqcGdeQXVyNjU1NzU3MzE@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Crime",
    subgenre: "Mystery",
    runtime: "130 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 546,
    name: "Once Upon a Time ... In Hollywood",
    year: 2019,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTg4ZTNkZmUtMzNlZi00YmFjLTk1MmUtNWQwNTM0YjcyNTNkXkEyXkFqcGdeQXVyNjg2NjQwMDQ@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "A behind the scenes look at Tinsletown during an alternate time in the summer of '69.",
    genre: "Drama",
    subgenre: "Comedy",
    runtime: "181 min",
    pairs: "two Whiskey Sours.",
    drinkRecipe: "in a beer stein",
    rated: "R",
    picture1: onceHollywood1,
    picture2: onceHollywood2,
    picture3: onceHollywood3,
    tagline: "I don't bow to China!",
    taglineWriter: "Quentin Fuckin' Tarantino",
    bulletOne: "Brandy's a fine girl.",
    bulletTwo: "Worth the runtime 'til the end.",
    cast: "Directed by QUENTIN TARANTINO starring LEONARDO DiCAPRIO  BRAD PITT  MARGOT ROBBIE  and  MARGARET QUALLEY",
  },
  {
    id: 547,
    name: "The Hateful Eight",
    year: 2015,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjA1MTc1NTg5NV5BMl5BanBnXkFtZTgwOTM2MDEzNzE@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "An octet of angry boarders hole up in a haberdashery during a blistering blizzard.",
    genre: "Drama",
    subgenre: "Mystery",
    runtime: "188 min",
    pairs: "",
    noPairs: "hot coffee and whiskey.",
    drinkRecipe: "",
    rated: "R",
    picture1: hateful81,
    picture2: hateful82,
    picture3: hateful83,
    tagline: "THAT'S FORGERY!",
    taglineWriter: "Abraham Lincoln",
    bulletOne: "Sam Jackson's Johnson.",
    bulletTwo: "R.I.P. that guitar for real.",
    cast: "Directed by QUENTIN TARANTINO starring SAMUEL L. JACKSON  KURT RUSSELL  WALTON GOGGINS  and  JENNIFER JASON LEIGH",
  },
  {
    id: 548,
    name: "The Heartbreak Kid",
    year: 2007,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjExNTM2NTE2NF5BMl5BanBnXkFtZTcwOTI5MDUzMw@@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Ben Stiller continually falls in love, moves too fast, and regrets his decisions in this hilarious comedy. ",
    genre: "Romcom",
    subgenre: "Comedy",
    runtime: "116 min",
    pairs: "a Sunburn.",
    drinkRecipe: "Fireball, Fruit Punch.",
    rated: "R",
    picture1: heartbreak1,
    picture2: heartbreak2,
    picture3: heartbreak3,
    tagline: "This movie cocks you good, damnit!",
    taglineWriter: "Arthur Spooner",
    bulletOne: "Growing out bangs for the wife.",
    bulletTwo: "Having your girlfriend pee on you after a jellyfish sting.",
    cast: "Directed by THE FARRELLY BROTHERS starring BEN STILLER MICHELLE MONAGHAN MALIN AKERMAN and JERRY STILLER",
  },
  {
    id: 549,
    name: "Stuck On You",
    year: 2003,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTYwODE1MTc3NF5BMl5BanBnXkFtZTcwMjQxMzQyMQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Conjoined freaks aspire to do more than they ever actually could.",
    genre: "Comedy",
    subgenre: "",
    runtime: "118 min",
    pairs: "a shared fishbowl.",
    drinkRecipe:
      "vodka, hawaiian punch polar blast, frozen pineapple slices, two straws.",
    rated: "PG-13",
    picture1: stuck1,
    picture2: stuck2,
    picture3: stuck3,
    tagline: "INSEPARABLE! THE LAUGHS ARE NON-STOP!",
    taglineWriter: "Dr. Ben Carson",
    bulletOne: "Always having a wingman.",
    bulletTwo: "Having a seven year age gap with your twin.",
    cast: "Directed by THE FARRELLY BROTHERS starring MATT DAMON GREG KINNEAR EVA MENDES and CHER",
  },
  {
    id: 550,
    name: "Shallow Hal",
    year: 2001,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTcwMzY2NDE0NF5BMl5BanBnXkFtZTYwNjg2Njc2._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "A superficial man is viciously tricked into thinking uggo's are hot.",
    genre: "Comedy",
    subgenre: "",
    runtime: "114 min",
    pairs: "a chocolate daiquiri.",
    drinkRecipe: "frozen coffee, kahlua, baileys.",
    rated: "PG-13",
    picture1: shallow1,
    picture2: shallow3,
    picture3: shallow2,
    tagline: "I LIKE 'EM REAL THICK AND JUICY!",
    taglineWriter: "Sir Mix-A-Lot",
    bulletOne: "Turning down a Beatles/Clapton concert because of a bad toe.",
    bulletTwo: "Calling your friends out if they take too long on a comeback.",
    cast: "Directed by THE FARRELLY BROTHERS starring JACK BLACK GWYNETH PALROW JASON ALEXANDER and JOE VITERELLI",
  },
  {
    id: 551,
    name: "The Gentlemen",
    year: 2019,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTlkMmVmYjktYTc2NC00ZGZjLWEyOWUtMjc2MDMwMjQwOTA5XkEyXkFqcGdeQXVyNTI4MzE4MDU@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "When the criminals of London's underground hear McConaughey is trying to sell his weed business, they do everything in their power to steal it. But McConaughey is no fool(s gold); he will get the best (lincoln) lawyer and start a reign of fire, leaving everyone dazed and confused. ",
    genre: "Crime",
    subgenre: "",
    runtime: "113 min",
    pairs: "a Pleasant Chap.",
    drinkRecipe: "Gentleman Jack + ginger.",
    rated: "R",
    picture1: gentlemen1,
    picture2: gentlemen2,
    picture3: gentlemen3,
    tagline: "So good it gave me wood",
    taglineWriter: "Matthew Mahogany",
    bulletOne: "Sweet track suits.",
    bulletTwo:
      "Learning that calling someone a '[race] + cunt' isn't racist, as long as they are in fact of that race, and a cunt.",
    cast: "Directed by GUY RITCHIE starring MATTHEW MCCONAUGHEY CHARLIE HUNNAM COLIN FARRELL and HENRY GOLDING",
  },
  {
    id: 552,
    name: "Rogue One: A Star Wars Story",
    year: 2016,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjEwMzMxODIzOV5BMl5BanBnXkFtZTgwNzg3OTAzMDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "A roundabout way to cover up plot holes in A New Hope.",
    genre: "Adventure",
    subgenre: "Scifi",
    runtime: "133 min",
    pairs: "a Vader's Corridor.",
    drinkRecipe: "Red Sangria and Coke.",
    rated: "PG-13",
    picture1: rogue1,
    picture2: rogue2,
    picture3: rogue3,
    tagline: "I know how it ends, so it CAN'T be good!",
    taglineWriter: "Titanic Fans",
    bulletOne: "Somehow being hated by both fan camps.",
    bulletTwo: "The best ending scene in any movie, ever.",
    cast: "Directed by GARETH EDWARDS starring FELICITY JONES DIEGO LUNA DONNIE YEN and MADS MIKKELSEN",
  },
  {
    id: 553,
    name: "The Kings of Summer",
    year: 2013,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTc3ODA1NTI0MV5BMl5BanBnXkFtZTcwOTE4OTUzOQ@@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Two best friends and their sidekick spend a summer living the ultimate adolescent boys dream: running away from home to make a place for themselves in the wild.",
    genre: "Comedy",
    subgenre: "Drama",
    runtime: "95 min",
    pairs: "",
    noPairs: "Rolling Rock.",
    rated: "R",
    picture1: summer1,
    picture2: summer2,
    picture3: summer3,
    tagline: "Teenage boys love playing with their wood",
    taglineWriter: "Carpenters",
    bulletOne: "Little-known comedy hit with a great cast.",
    bulletTwo:
      "Pretending you're in highschool again and sneaking booze from your parents liquor cabinet.",
    cast: "Directed by JORDAN VOGT-ROBERTS starring NICK ROBINSON GABRIEL BASSO MOISES ARIAS and NICK OFFERMAN",
  },
  {
    id: 554,
    name: "Miracle",
    year: 2004,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjEyOTk1OTcyNV5BMl5BanBnXkFtZTYwNjMzNDU3._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Sports",
    subgenre: "Drama",
    runtime: "135 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 555,
    name: "Seabiscuit",
    year: 2003,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWVmMmE1YzItNGUyZS00MzgwLWFhNzYtYjM4ZTJmZDVjNmNmL2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Spiderman trades in his spandex for jockey breeches in this period piece about an underdog horse who no one thought would stand up to the competition. ",
    genre: "Sports",
    subgenre: "Drama",
    runtime: "140 min",
    pairs: "a Horseshoe.",
    drinkRecipe: "Kahlúa and Coke.",
    rated: "PG-13",
    picture1: seabiscuit1,
    picture2: seabiscuit2,
    picture3: seabiscuit3,
    tagline: "I want a horse joke, but that would be lame",
    taglineWriter: "Thomas Equineas",
    bulletOne: "Elizabeth Banks arguably in brownface.",
    bulletTwo:
      "The title sounding like a great crossover appetizer from Long John Silver's and Popeye's.",
    cast: "Directed by GARY ROSS starring TOBEY MAGUIRE JEFF BRIDGES ELIZABETH BANKS and CHRIS COOPER",
  },
  {
    id: 556,
    name: "The Natural",
    year: 1984,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGU5NDU4ZTctYjE2YS00NTUxLWIzMTAtMTMxNTgxODAzNDI5XkEyXkFqcGdeQXVyMTAwMzUyOTc@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Sports",
    subgenre: "",
    runtime: "138 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 557,
    name: "Goon",
    year: 2011,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTcxOTQwMTQ3MF5BMl5BanBnXkFtZTcwMDcyOTQwNw@@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Stiffler is a tough but lovable enforcer in a minor league hockey team. ",
    genre: "Sports",
    subgenre: "Comedy",
    runtime: "92 min",
    pairs: "a Black Eye.",
    drinkRecipe: "Kraken, Coke.",
    rated: "R",
    picture1: goon1,
    picture2: goon2,
    picture3: goon3,
    tagline: "You got blood on your face, you big disgrace",
    taglineWriter: "Bleddie Mercury",
    bulletOne: "The diner scene.",
    bulletTwo:
      "Surprisingly gruesome fights. Didn't expect it to be so graphic.",
    cast: "Directed by MICHAEL DOWSE starring SEAN WILLIAM SCOTT JAY BARUCHEL ALISON PILL and LIEV SCHREIBER",
  },
  {
    id: 558,
    name: "Goon: Last of the Enforcers",
    year: 2017,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTYxNzk5NzM3OV5BMl5BanBnXkFtZTgwMjEzOTc4MjI@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "After trying to work a regular job and have a normal life, the call of the ice is too strong for the Stiffmeister. ",
    genre: "Sports",
    subgenre: "Comedy",
    runtime: "101 min",
    pairs: "a Heavy Hitter.",
    drinkRecipe: "Bacardi 151, Coke.",
    rated: "R",
    picture1: goon21,
    picture2: goon22,
    picture3: goon23,
    tagline: "IT TRULY IS KNUCKLE PUCK TIME",
    taglineWriter: "Keenan",
    bulletOne: "Wyatt Russell being nowhere near as cool as his dad.",
    bulletTwo: "Getting trained by an old rival, Rocky 3 style.",
    cast: "Directed by JAY BARUCHEL starring SEAN WILLIAM SCOTT WYATT RUSSELL ALISON PILL and LIEV SCHREIBER",
  },
  {
    id: 559,
    name: "I, Tonya",
    year: 2017,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjI5MDY1NjYzMl5BMl5BanBnXkFtZTgwNjIzNDAxNDM@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "The story of Tonya Harding's conspiracy to put Nancy Kerrigan out of commission. Yet another biopic where the actor is WAY more attractive than the real person. Margot Robbie playing Tonya Harding is like Idris Elba playing Anthony Davis.",
    genre: "Sports",
    subgenre: "Drama",
    runtime: "119 min",
    pairs: "a Crybaby.",
    drinkRecipe: "Vodka, Blue Gatorade.",
    rated: "R",
    picture1: tonya1,
    picture2: tonya2,
    picture3: tonya3,
    tagline: "COLD AS ICE!",
    taglineWriter: "Foreigner",
    bulletOne: "Having a parent who cares about your success.",
    bulletTwo: "Proving people wrong and rubbing your triumphs in their face.",
    cast: "Directed by CRAIG GILLESPIE starring MARGOT ROBBIE SEBASTIAN STAN ALLISON JANNEY and PAUL WALTER HAUSER",
  },
  {
    id: 560,
    name: "The Longest Yard",
    year: 2005,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTc1NTQyNDk2NV5BMl5BanBnXkFtZTcwOTE2OTQzMw@@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Update on an old classic wherein Happy Gilmore leads a team of dangerous convicts in a warmup football game with the guards. ",
    genre: "Sports",
    subgenre: "Comedy",
    runtime: "113 min",
    pairs: "a Point Shave.",
    drinkRecipe: "Gin, Sprite.",
    rated: "PG-13",
    picture1: longestYard1,
    picture2: longestYard2,
    picture3: longestYard3,
    tagline: "Not a yard, but still pretty long",
    taglineWriter: "Courtney's Cock",
    bulletOne: "Adam Sandler once again tries to prove he’s tough.",
    bulletTwo:
      "Paying homage to the original by throwing Burt Reynolds in the mix.",
    cast: "Directed by PETER SEGAL starring ADAM SANDLER CHRIS ROCK BURT REYNOLDS and BOB SAPP",
  },
  {
    id: 561,
    name: "White Men Can't Jump",
    year: 1992,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTc5ZjE2MmEtYWIxYi00OGY0LTk0ZTUtMzJiYjI4OWZmNTVmXkEyXkFqcGdeQXVyNzc5MjA3OA@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Sports",
    subgenre: "Comedy",
    runtime: "115 min",
    pairs: "",
    rated: "R",
    picture1: whiteMen1,
    picture2: whiteMen2,
    picture3: whiteMen3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by RON SHELTON starring WESLEY SNIPES WOODY HARRELSON ROSIE PEREZ and TYRA FERRELL",
  },
  {
    id: 562,
    name: "Celtic Pride",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjg4NThhNjItZmQzZi00ZDVlLWI5YzgtODY0NDk5YTlmNmNlXkEyXkFqcGdeQXVyMTMxMTY0OTQ@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Sports",
    subgenre: "Comedy",
    runtime: "91 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 563,
    name: "Major League",
    year: 1990,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzM5MWViNTMtMDQ5Ni00M2M0LTg1OTktY2I3YTJkODVkNTBkXkEyXkFqcGdeQXVyNTI4MjkwNjA@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Sports",
    subgenre: "Comedy",
    runtime: "107 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 564,
    name: "Talladega Nights: The Ballad of Ricky Bobby",
    year: 2006,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzAzOTk1OTIyM15BMl5BanBnXkFtZTcwNDIzNTQzMQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Sports",
    subgenre: "Comedy",
    runtime: "108 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 565,
    name: "Bend It Like Beckham",
    year: 2002,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTg1Njk5NjczM15BMl5BanBnXkFtZTYwNjc2NTQ3._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Sports",
    subgenre: "",
    runtime: "112 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 566,
    name: "Kicking & Screaming",
    year: 2005,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTA5Njk1MjEzMzZeQTJeQWpwZ15BbWU3MDY3NjY4MjE@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Sports",
    subgenre: "Comedy",
    runtime: "95 min",
    pairs: "",
    rated: "PG",
    picture1: kicking1,
    picture2: kicking2,
    picture3: kicking3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by JESSE DYLAN starring WILL FERRELL ROBERT DUVALL JOSH HUTCHERSON and KATE WALSH",
  },
  {
    id: 567,
    name: "The Fighter",
    year: 2010,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTM0ODk3MjM1MV5BMl5BanBnXkFtZTcwNzc1MDIwNA@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Sports",
    subgenre: "Drama",
    runtime: "116 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "Should've seen the barber",
    cast: "",
  },
  {
    id: 568,
    name: "Creed",
    year: 2015,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNmZkYjQzY2QtNjdkNC00YjkzLTk5NjUtY2MyNDNiYTBhN2M2XkEyXkFqcGdeQXVyMjMwNDgzNjc@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Sports",
    subgenre: "",
    runtime: "133 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 569,
    name: "Creed II",
    year: 2018,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYmEyNWZhM2YtZDAyNi00ZjYzLWI2ZWMtOWM4ZmI1MDE0OWNmXkEyXkFqcGdeQXVyMjMwNDgzNjc@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Sports",
    subgenre: "",
    runtime: "130 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 570,
    name: "Rush",
    year: 2013,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOWEwODJmZDItYTNmZC00OGM4LThlNDktOTQzZjIzMGQxODA4XkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Sports",
    subgenre: "",
    runtime: "123 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 571,
    name: "Ford v Ferrari",
    year: 2019,
    poster:
      "https://m.media-amazon.com/images/M/MV5BM2UwMDVmMDItM2I2Yi00NGZmLTk4ZTUtY2JjNTQ3OGQ5ZjM2XkEyXkFqcGdeQXVyMTA1OTYzOTUx._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Sports",
    subgenre: "",
    runtime: "152 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 572,
    name: "Hoosiers",
    year: 1986,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTYyNTc0OTUwNV5BMl5BanBnXkFtZTcwODA3NzE0NA@@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "The blueprint for every sports movie trope. If you take a drink every time you see a well-worn archetype, you'll be as drunk as the drunk dad in every sports movie.",
    genre: "Sports",
    subgenre: "",
    runtime: "114 min",
    pairs: "an Alley Oop.",
    drinkRecipe: "svedka clementine, shandy.",
    rated: "PG",
    picture1: hoosiers1,
    picture2: hoosiers2,
    picture3: hoosiers3,
    tagline: "NOTHING BUT NET!",
    taglineWriter: "Coach Carter",
    bulletOne:
      "Being under constant threat of lynching if the team doesn't win.",
    bulletTwo:
      "Hackman being a fish out of water as a navy guy in landlocked Indiana. ",
    cast: "Directed by DAVID ANSPAUGH starring GENE HACKMAN BARBARA HERSHEY DENNIS HOOPER and CHELCIE ROSS",
  },
  {
    id: 573,
    name: "Moneyball",
    year: 2011,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjAxOTU3Mzc1M15BMl5BanBnXkFtZTcwMzk1ODUzNg@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Sports",
    subgenre: "",
    runtime: "133 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 574,
    name: "",
    year: 1989,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzk5OWY0YjAtYWU3ZS00Y2Q4LWFlNjItMzgwMTQ2MjIyMDFmL2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Sports",
    subgenre: "",
    runtime: "107 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 575,
    name: "Slap Shot",
    year: 1977,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTk0MzcwNzc3NV5BMl5BanBnXkFtZTgwMTYzODg4MDE@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Sports",
    subgenre: "Comedy",
    runtime: "123 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 576,
    name: "A League of Their Own",
    year: 1992,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjQ1NTQyYjktZDc5My00NDA1LWI1NmItY2ViNjQ0NDk4NmRjXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Sports",
    subgenre: "Comedy",
    runtime: "128 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 577,
    name: "Ali",
    year: 2001,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjA3OTUxNTktN2FlNC00NGUyLWI1NDktY2FlZTc5MDlmOGFhXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Sports",
    subgenre: "",
    runtime: "157 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 578,
    name: "Foxcatcher",
    year: 2014,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQ2MjQxNjYxOV5BMl5BanBnXkFtZTgwMzIwODUxMzE@._V1_SX300.jpg",
    rating: 4,
    synopsis: "A crazy-rich weirdo wants to start wrestling at age 50. ",
    genre: "Sports",
    subgenre: "",
    runtime: "134 min",
    pairs: "a Full Nelson.",
    drinkRecipe: "entire cup of admiral nelson rum.",
    rated: "R",
    picture1: foxcatcher1,
    picture2: foxcatcher2,
    picture3: foxcatcher3,
    tagline: "STUNNING!",
    taglineWriter: "Stone Cold Steve Austin",
    bulletOne:
      "Against all odds, the makeup team made Steve Carrells nose bigger",
    bulletTwo:
      "Tatum and Ruffalo look like they're packing a lip the whole time.",
    cast: "Directed by BENNETT MILLER starring STEVE CARRELL CHANNING TATUM MARK RUFFALO and VANESSA REDGRAVE",
  },
  {
    id: 579,
    name: "Beauty and the Beast",
    year: 1991,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzE5MDM1NDktY2I0OC00YWI5LTk2NzUtYjczNDczOWQxYjM0XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "84 min",
    pairs: "",
    rated: "G",
    picture1: beautyAndBeast1,
    picture2: beautyAndBeast2,
    picture3: beautyAndBeast3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by GARY TROUSDALE starring PAIGE O'HARA ROBBY BENSON JESSE CORTI and REX EVERHART",
  },
  {
    id: 580,
    name: "Paddington",
    year: 2014,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTAxOTMwOTkwNDZeQTJeQWpwZ15BbWU4MDEyMTI1NjMx._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "95 min",
    pairs: "",
    rated: "PG",
    picture1: paddington1,
    picture2: paddington2,
    picture3: paddington3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by PAUL KING starring HUGH BONNEVILLE SALLY HAWKINS BEN WHISHAW and JIM BROADBENT",
  },
  {
    id: 581,
    name: "Paddington 2",
    year: 2017,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmYwNWZlNzEtNjE4Zi00NzQ4LWI2YmUtOWZhNzZhZDYyNmVmXkEyXkFqcGdeQXVyNzYzODM3Mzg@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "103 min",
    pairs: "",
    rated: "PG",
    picture1: paddington21,
    picture2: paddington22,
    picture3: paddington23,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by PAUL KING starring BEN WHISHAW HUGH GRANT HUGH BONNEVILLE and SALLY HAWKINS",
  },
  {
    id: 582,
    name: "Finding Dory",
    year: 2016,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzg4MjM2NDQ4MV5BMl5BanBnXkFtZTgwMzk3MTgyODE@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "97 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 583,
    name: "James and the Giant Peach",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTNkNWIwNGUtNTJlOC00NDU3LTk0NWEtNjNjNDM4NzRiNThkXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "79 min",
    pairs: "",
    rated: "PG",
    picture1: giantPeach1,
    picture2: giantPeach2,
    picture3: giantPeach3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by HENRY SELICK starring PAUL TERRY JOANNA LUMLEY SIMON CALLOW and RICHARD DREYFUSS",
  },
  {
    id: 584,
    name: "Kubo and the Two Strings",
    year: 2016,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjA2Mzg2NDMzNl5BMl5BanBnXkFtZTgwMjcwODUzOTE@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "101 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  // {
  //   id: 585,
  //   name: "Placeholder",
  //   year: 2010,
  //   poster:
  //     "https://m.media-amazon.com/images/M/MV5BMjA5NDQyMjc2NF5BMl5BanBnXkFtZTcwMjg5ODcyMw@@._V1_SX300.jpg",
  //   rating: 4,
  //   synopsis: "",
  //   genre: "Animation",
  //   subgenre: "",
  //   runtime: "98 min",
  //   pairs: "",
  //   rated: "PG",
  //   picture1: trainDragon1,
  //   picture2: trainDragon2,
  //   picture3: trainDragon3,
  //   tagline: "Headline to come!",
  //   taglineWriter: "Some cool writer",
  //   bulletOne: "",
  //   bulletTwo: "",
  //   cast: "Directed by DEAN DEBLOIS starring JAY BARUCHEL GERARD BUTLER AMERICA FERRARA and CRAIG FERGUSON",
  // },
  {
    id: 586,
    name: "Dumbo",
    year: 1941,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNWVmNWQ2OTQtNzJlNC00ZmQwLTg4ZTktZTNmM2IxZTlkOGM3L2ltYWdlXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "64 min",
    pairs: "",
    rated: "G",
    picture1: dumbo1,
    picture2: dumbo2,
    picture3: dumbo3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by SAMUEL ARMSTRONG starring STERLING HOLLOWAY EDWARD BROPHY MEL BLANC and BILLY BLETCHER",
  },
  {
    id: 587,
    name: "The Nightmare Before Christmas",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNWE4OTNiM2ItMjY4Ni00ZTViLWFiZmEtZGEyNGY2ZmNlMzIyXkEyXkFqcGdeQXVyMDU5NDcxNw@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "76 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 588,
    name: "Monsters University",
    year: 2013,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTUyODgwMDU3M15BMl5BanBnXkFtZTcwOTM4MjcxOQ@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Animation",
    subgenre: "Family",
    runtime: "104 min",
    pairs: "",
    noPairs: "a kegger.",
    rated: "G",
    picture1: monstersUniversity1,
    picture2: monstersUniversity3,
    picture3: monstersUniversity2,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "Getting to class on time.",
    bulletTwo: "",
    cast: "Directed by DAN SCANLON starring BILLY CRYSTAL JOHN GOODMAN STEVE BUSCEMI and HELEN MIRREN",
  },
  {
    id: 589,
    name: "Aladdin",
    year: 1992,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTg5ZTVmM2EtZjdhZC00MzBjLWEwZTYtNWIwZDczYzZkMzA4XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "With the help of his pet monkey, a dirty little guttersnipe tricks a princess into falling in love and stealing a magic lamp from a pious mystic.",
    genre: "Animation",
    subgenre: "",
    runtime: "90 min",
    pairs: "a Street Urchin.",
    drinkRecipe: "Iced tea, dark rum",
    rated: "G",
    picture1: aladdin1,
    picture2: aladdin2,
    picture3: aladdin3,
    tagline: "RUBS ME THE RIGHT WAY",
    taglineWriter: "XTina Aguilera",
    bulletOne: "Does the magic carpet match the drapes?",
    bulletTwo:
      "Everyone should have a snappy animal sidekick like Abu or Iago.",
    cast: "Directed by RON CLEMENTS starring SCOTT WEINGER ROBIN WILLIAMS LINDA LARKIN and JONATHAN FREEMAN",
  },
  {
    id: 590,
    name: "Wreck It Ralph",
    year: 2012,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzMxNTExOTkyMF5BMl5BanBnXkFtZTcwMzEyNDc0OA@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "101 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 591,
    name: "Ralph Breaks the Internet",
    year: 2018,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTYyNzEyNDAzOV5BMl5BanBnXkFtZTgwNTk3NDczNjM@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "112 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 592,
    name: "Lady and the Tramp",
    year: 1955,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTI1ZDc1OTItM2EzNS00ODMxLWIxYzctOTc5YWNiYzI3NGZiXkEyXkFqcGdeQXVyNDE5MTU2MDE@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "76 min",
    pairs: "",
    rated: "G",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 593,
    name: "Homeward Bound: The Incredible Journey",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmRlODBjOWEtODViZS00NGNiLWEwNzYtYjIyZWQwZThhODBkXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Animation",
    subgenre: "Adventure",
    runtime: "84 min",
    pairs: "",
    rated: "G",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 594,
    name: "The Adventures of Milo and Otis",
    year: 1986,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGRjZGEwZTctMmRmYy00YmI1LTgwOGItZmE5ZmNjYmRhOWI4XkEyXkFqcGdeQXVyNjMwMjk0MTQ@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "A cat and dog are thrust into extremely dangerous situations after straying from their farm.",
    genre: "Animation",
    subgenre: "Adventure",
    runtime: "76 min",
    pairs: "a Salty Dog.",
    drinkRecipe: "Vodka, grapefruit juice, salted rim.",
    rated: "G",
    picture1: miloOtis1,
    picture2: miloOtis2,
    picture3: miloOtis3,
    tagline: "Want an omelette? Gotta break a couple legs",
    taglineWriter: "Rocky Balboa-constrictor",
    bulletOne:
      "There's a semi-coherent plot, and one guy voices all the characters. ",
    bulletTwo:
      "The disclaimer should read 'all animals were harmed in the making of this movie'.",
    cast: "Directed by MASANORI HATA starring DUDLEY MOORE",
  },
  {
    id: 595,
    name: "Big Hero 6",
    year: 2014,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDliOTIzNmUtOTllOC00NDU3LWFiNjYtMGM0NDc1YTMxNjYxXkEyXkFqcGdeQXVyNTM3NzExMDQ@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "102 min",
    pairs: "",
    rated: "PG",
    picture1: bigHero1,
    picture2: bigHero2,
    picture3: bigHero3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne:
      "A teenage nerd befriends Bymax, his late brother’s Stay Puff Marshmallow robot.",
    bulletTwo: "Forming an Avenger squad we'd actually want to see.",
    cast: "Directed by DON HALL starring RYAN POTTER SCOTT ADSIT JAMIE CHUNG and TJ MILLER",
  },
  {
    id: 596,
    name: "Bambi",
    year: 1942,
    poster:
      "https://m.media-amazon.com/images/M/MV5BY2JhMTJmYjYtNTVkYi00OWE3LWJlMjEtOWI4MTFkMjJhMmQ3XkEyXkFqcGdeQXVyNzY1NDgwNjQ@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "70 min",
    pairs: "",
    rated: "G",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 597,
    name: "Tangled",
    year: 2010,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTAxNDYxMjg0MjNeQTJeQWpwZ15BbWU3MDcyNTk2OTM@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "100 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 598,
    name: "Rango",
    year: 2011,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTc4NjEyODE1OV5BMl5BanBnXkFtZTcwMjYzNTkxNA@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "107 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 599,
    name: "Kung Fu Panda",
    year: 2008,
    poster:
      "https://m.media-amazon.com/images/M/MV5BODJkZTZhMWItMDI3Yy00ZWZlLTk4NjQtOTI1ZjU5NjBjZTVjXkEyXkFqcGdeQXVyODE5NzE3OTE@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "A fat and clumsy panda learns his destiny is to become the greatest kung fu fighter ever. Ths movie is awesome and has several winks to old kung fu movies.",
    genre: "Animation",
    subgenre: "",
    runtime: "92 min",
    pairs: "",
    rated: "PG",
    picture1: kfp1,
    picture2: kfp2,
    picture3: kfp3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by MARK OSBORNE starring JACK BLACK IAN MCSHANE ANGELINA JOLIE and DUSTIN HOFFMAN",
  },
  {
    id: 600,
    name: "Kung Fu Panda 2",
    year: 2011,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzQ0ZWIxZjAtYWI3Yy00MGM0LWFjOGYtNzcyYThiOTA3ODI1XkEyXkFqcGdeQXVyODE5NzE3OTE@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "90 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by JENNIFER YUH NELSON starring JACK BLACK JACKIE CHAN ANGELINA JOLIE and DUSTIN HOFFMAN",
  },
  {
    id: 601,
    name: "Kung Fu Panda 3",
    year: 2016,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTUyNzgxNjg2M15BMl5BanBnXkFtZTgwMTY1NDI1NjE@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "95 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by ALESSANDRA CARLONI starring JACK BLACK BRYAN CRANSTON ANGELINA JOLIE and DUSTIN HOFFMAN",
  },
  {
    id: 602,
    name: "Rock of Ages",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTg2NDQyODAzNF5BMl5BanBnXkFtZTcwMTg5MDA3Nw@@._V1_SX300.jpg",
    rating: 4,
    year: 2012,
    synopsis:
      "She's only a tiny village gal, surviving in a isolated universe and he's a metropolis man, born and raised to disappoint.",
    genre: "Comedy",
    subgenre: "Musical",
    runtime: "109 min",
    pairs: "an Aged Rocker.",
    drinkRecipe: "Macallan Matured 15 Years, ice. coffee chaser.",
    noPairs: "",
    rated: "PG-13",
    picture1: rockages1,
    picture2: rockages2,
    picture3: rockages3,
    tagline: "GREAT PLOT FOR A BALLAD!",
    taglineWriter: "Adventure",
    bulletOne: "Solid soundtrack.",
    bulletTwo: "Jack Donaghy as a liberal in showbiz.",
    cast: "Directed by ADAM SHANKMAN starring TOM CRUISE JULIANNE HOUGH DIEGO BONETA and ALEC BALDWIN",
  },
  {
    id: 603,
    name: "The Toxic Avenger",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzViNmQ5MTYtMmI4Yy00N2Y2LTg4NWUtYWU3MThkMTVjNjk3XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    year: 1983,
    synopsis:
      "A pipsqueak janitor falls into a vat of toxic waste that grants him superpowers. With new size and strength, he hits the streets to mop up crime.",
    genre: "Cult Classic",
    subgenre: "Horror",
    runtime: "82 min",
    pairs: "Toxic Waste.",
    drinkRecipe: "Green apple vodka, candy melon liqueur, lime juice, lemon.",
    rated: "R",
    picture1: toxicAvenger1,
    picture2: toxicAvenger2,
    picture3: toxicAvenger3,
    tagline: "ALL HAIL THE MONSTER HERO!",
    taglineWriter: "Sgt. Kabukiman",
    bulletOne: "Ample nudity.",
    bulletTwo: "Not giving a wet fart about being PC.",
    cast: "Directed by MICHAEL HERZ & LLOYD KAUFMAN starring MITCH COHEN  CINDY MANION  ROBERT PRICHARD and GARY SCHNEIDER",
  },
  {
    id: 604,
    name: "Terminator: Dark Fate",
    year: 2019,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOWExYzVlZDgtY2E1ZS00NTFjLWFmZWItZjI2NWY5ZWJiNTE4XkEyXkFqcGdeQXVyMTA3MTA4Mzgw._V1_SX300.jpg",
    rating: 2,
    synopsis:
      "Another missed mark at revamping the Terminator. At least Arnie still rules.",
    genre: "Scifi",
    subgenre: "Action",
    runtime: "128 min",
    pairs: "",
    noPairs: "Dos Equis",
    rated: "R",
    picture1: darkFate1,
    picture2: darkFate2,
    picture3: darkFate3,
    tagline: "Time to say 'hasta la vista' to this franchise.",
    taglineWriter: "Edweird Forelong",
    bulletOne: "Hard nips in tank tops.",
    bulletTwo:
      "Despite being made in 2019, the special effects look like they were made in 1092.",
    cast: "Directed by TIM MILLER starring LINDA HAMILTON ARNOLD SCHWARZENEGGER MACKENZIE DAVIS and NATALIA REYES",
  },
  {
    id: 605,
    name: "Baby Mama",
    year: 2008,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTYwNTc1Nzk1N15BMl5BanBnXkFtZTcwNjE4OTI2MQ@@._V1_SX300.jpg",
    rating: 3,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "99 min",
    pairs: "",
    noPairs: "Adult Chocolate Milk.",
    rated: "PG-13",
    picture1: babyMama1,
    picture2: babyMama2,
    picture3: babyMama3,
    tagline: "ABORTION SHOULD BE LEGAL",
    taglineWriter: "Amy Poehlerizer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by MICHAEL MCCULLERS starring TINA FEY AMY POEHLER SIGOURNEY WEAVER and GREG KINNEAR",
  },
  {
    id: 606,
    name: "Pumping Iron",
    year: 1977,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDA1ZmZmNTctMGVkOS00ZjZiLWFkODQtNjg3NTEwMWM0OGJhXkEyXkFqcGdeQXVyMjI4MjA5MzA@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "The first and still greatest bodybuilding doc out there. We get a behind the scenes look at what it’s like to get massive gains, the original Hulk shows off his biceps, and Arnold Schwarzenegger smokes weed. If you ever need motivation to hit the gym, throw this on and start bench pressing your sofa. For best results, chug a Muscle Milk within an hour after viewing.",
    genre: "Sports",
    subgenre: "",
    runtime: "86 min",
    pairs: "",
    rated: "PG",
    picture1: pumpingIron1,
    picture2: pumpingIron2,
    picture3: pumpingIron3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by GEORGE BUTLER starring ARNOLD SCHWARZENEGGER FRANCO COLUMBU LOU FERRIGNO and MIKE KATZ",
  },
  {
    id: 607,
    name: "Hannibal Rising",
    year: 2007,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTIzMTA0NTM4NF5BMl5BanBnXkFtZTcwNjY3ODM0MQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "After the death of their parents during WWII, Hannibal and his sister fight to survive the harsh Lithuanian winter. We meet the boy behind the creepy mask in this prequel to, “Red Dragon”.",
    genre: "Thriller",
    subgenre: "Crime",
    runtime: "121 min",
    pairs: "Svyturys Ekstra.",
    rated: "R",
    picture1: hannibalRising1,
    picture2: hannibalRising2,
    picture3: hannibalRising3,
    tagline: "THIS PREQUEL UPS THE CHI-ANTE!",
    taglineWriter: "Jeffrey Dahmer",
    bulletOne: "Shockingly makes for a decent date night movie.",
    bulletTwo: "Gaining your acquired taste.",
    cast: "Directed by PETER WEBBER starring GASPARD ULLIEL RHYS IFANS GONG LI and RICHARD LEAF",
  },
  {
    id: 608,
    name: "The Legend of Bagger Vance",
    year: 2000,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWYzOGE4NGItYTAyNy00NDBkLTljYjMtYzE4MjFmYTY4NGVlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "During the depression and without any money a woman decides to host the most expensive golf game ever played and beau Junuh steps up to the tee.",
    genre: "Sports",
    subgenre: "Drama",
    runtime: "126 min",
    pairs: "a Tee Shot.",
    drinkRecipe: "Gin, campari, rosé",
    rated: "PG-13",
    picture1: baggerVance1,
    picture2: baggerVance2,
    picture3: baggerVance3,
    tagline: "A birdie in hand is worth two in her bush",
    taglineWriter: "Tiger Woods",
    bulletOne: "Sage advice from a bad boy caddy",
    bulletTwo: "Good sportsmanship",
    cast: "Directed by ROBERT REDFORD starring WILL SMITH MATT DAMON CHARIZE THERON and BRUCE MCGILL",
  },
  {
    id: 609,
    name: "Tin Cup",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDQxNzY1MzItZmU1Mi00MWI3LTk4ZjYtOTBlM2RmYTk1MTJjXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 3,
    synopsis:
      "After meeting and instantly falling in love with his nemesis’ girlfriend, driving range hustler, Roy McAvoy decides to win her affection.",
    genre: "Sports",
    subgenre: "Romcom",
    runtime: "135 min",
    pairs: "a John Daly.",
    drinkRecipe: "Lemonade, iced tea, vodka.",
    rated: "R",
    picture1: tinCup1,
    picture2: tinCup2,
    picture3: tinCup3,
    tagline: "Hey man, don't forget your seven iron",
    taglineWriter: "Chong",
    bulletOne: "Handsy golf lessons from a drunk.",
    bulletTwo:
      "Love confessions so creepy they should come with a restraining order.",
    cast: "Directed by RON SHELTON starring KEVIN COSTNER RENE RUSSO DON JOHNSON and CHEECH MARIN",
  },
  {
    id: 610,
    name: "Draft Day",
    year: 2014,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjAyOTMxMjA3Nl5BMl5BanBnXkFtZTgwMTMwNjQ4MDE@._V1_SX300.jpg",
    rating: 3,
    synopsis: "",
    genre: "Sports",
    subgenre: "",
    runtime: "110 min",
    pairs: "",
    rated: "PG-13",
    picture1: draftDay1,
    picture2: draftDay2,
    picture3: draftDay3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by IVAN REITMAN starring KEVIN COSTNER CHADWICK BOSEMAN JENNIFER GARNER and FRANK LANGELLA",
  },
  {
    id: 611,
    name: "Pinocchio",
    year: 1940,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTU4Mzk3ODIyOF5BMl5BanBnXkFtZTgwODgyNzk2NjE@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "88 min",
    pairs: "",
    rated: "G",
    picture1: pinocchio1,
    picture2: pinocchio2,
    picture3: pinocchio3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by NORMAN FERGUSON starring DICKIE JONES CHRISTIAN RUB MEL BLANC and BILLY BLETCHER",
  },
  {
    id: 612,
    name: "Saving Silverman",
    year: 2001,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzhlY2MwZTUtOGMxNS00ZDA2LTliN2UtZDdmNmUyNWM2YzE0XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "A horrid woman tries to separate a man from his best friends, but they're not giving up that easily. For maximum enjoyment grab some nacho's and give your recliner the ol' SWEEP-A-ROO before sitting down.",
    genre: "Comedy",
    subgenre: "",
    runtime: "90 min",
    pairs: "",
    noPairs: "a beer bong for the lady.",
    rated: "PG-13",
    picture1: silverman1,
    picture2: silverman2,
    picture3: silverman3,
    tagline: "Oh, come on. She's not trying to steal him!",
    taglineWriter: "Yoko Ono",
    bulletOne: "Diagrams for whacking off.",
    bulletTwo:
      "Jack Black and Steve Zahn as two of the schlubbiest characters in cinema. R. Lee Ermey is also hilarious.",
    cast: "Directed by DENNIS DUGAN starring JACK BLACK STEVE ZAHN JASON BIGGS and AMANDA PEET",
  },
  {
    id: 613,
    name: "The Hustler",
    year: 1961,
    poster:
      "https://m.media-amazon.com/images/M/MV5BY2U1OGIwNjItMDg5Ni00MTQ0LWFkYjAtYjFmYjRlYTI1OTg3XkEyXkFqcGdeQXVyNjc5NjEzNA@@._V1_SX300.jpg",
    rating: 2,
    synopsis:
      "This beloved movie STINKS. Aside from when pool is actually played, it's painfully dull. The only posible way to enjoy this movie would be to drink as much as Fast Eddie and then make like his girlfriend, Sarah.",
    genre: "Sports",
    subgenre: "",
    runtime: "134 min",
    pairs: "",
    noPairs: "cheap whiskey and beer.",
    rated: "Not Rated",
    picture1: hustler1,
    picture2: hustler2,
    picture3: hustler3,
    tagline: "I feel hustled for having watched it",
    taglineWriter: "Vic Tim",
    bulletOne: "The pool scenes are actually awesome.",
    bulletTwo: "Just watch The Color of Money instead, it's much better.",
    cast: "Directed by ROBERT ROSSEN starring PAUL NEWMAN JACKIE GLEASON PIPER LAURIE and GEORGE C. SCOTT",
  },
  {
    id: 614,
    name: "Diary of a Wimpy Kid",
    year: 2010,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTg3NzQ2NDgyNF5BMl5BanBnXkFtZTcwMDc1NzIyMw@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "120 min",
    pairs: "",
    rated: "PG",
    picture1: wimpyKid1,
    picture2: wimpyKid2,
    picture3: wimpyKid3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by THOR FREUDENTHAL starring ZACHARY GORDON ROBERT CAPRON STEVE ZAHN and DEVON BOSTICK",
  },
  {
    id: 615,
    name: "The Man Who Knew Too Little",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTA2OTNiZWQtNTBlNi00OGNjLTkzMzgtOTZiZDY2ZWIyNTY1XkEyXkFqcGdeQXVyNjU0NTI0Nw@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "94 min",
    pairs: "",
    rated: "PG",
    picture1: tooLittle1,
    picture2: tooLittle2,
    picture3: tooLittle3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "Making Mr. Magoo proud",
    bulletTwo: "",
    cast: "Directed by JON AMIEL starring BILL MURRAY JOANNE WHALLEY PETER GALLAGHER and ALFRED MOLINA",
  },
  {
    id: 616,
    name: "American Pie",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTg3ODY5ODI1NF5BMl5BanBnXkFtZTgwMTkxNTYxMTE@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "High school virgins make a pact to get laid on or before prom night. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "95 min",
    pairs: "",
    noPairs: "Beer from a Solo cup ... just be sure to check the contents.",
    rated: "R",
    picture1: americanPie1,
    picture2: americanPie2,
    picture3: americanPie3,
    tagline: "WAIT FOR THE CREAM!",
    taglineWriter: "Hans Landa",
    bulletOne: "This flick coined the term “MILF”.",
    bulletTwo:
      "Not only getting caught banging a dessert, but being humiliated in front of everyone who owned a computer.",
    cast: "Directed by PAUL WEITZ starring JASON BIGGS CHRIS KLEIN SEAN WILLIAM SCOTT and SHANNON ELIZABETH",
  },
  {
    id: 617,
    name: "How Do You Know",
    year: 2010,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTQyMzU4OTk1N15BMl5BanBnXkFtZTcwNTE5MTUxNA@@._V1_SX300.jpg",
    rating: 3,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "121 min",
    pairs: "",
    rated: "PG-13",
    picture1: hdyk1,
    picture2: hdyk2,
    picture3: hdyk3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by JAMES L. BROOKS starring REESE WITHERSPOON PAUL RUDD OWEN WILSON and JACK NICHOLSON",
  },
  {
    id: 618,
    name: "The Ballad of Buster Scruggs",
    year: 2018,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjRkYTI3M2EtZWQ4Ny00OTA2LWFmMTMtY2E4MTEyZmNjOTMxXkEyXkFqcGdeQXVyNDg4NjY5OTQ@._V1_SX300.jpg",
    rating: 3,
    synopsis:
      "The Cohen Brothers present six stories of life in the wild west. The titular vignette, being the first and the best, features a serenade from Tim Blake Nelson’s pleasing baritone, a surly poker player, and of course gunslingin’ shoot-outs.",
    genre: "Comedy",
    subgenre: "Western",
    runtime: "133 min",
    pairs: "",
    noPairs: "a whiskey, where it's legal.",
    rated: "R",
    picture1: busterScruggs1,
    picture2: busterScruggs2,
    picture3: busterScruggs3,
    tagline: "A ROOTIN' TOOTIN' RIDE!",
    taglineWriter: "Hal D. Yall",
    bulletOne: "Society should bring back freak shows.",
    bulletTwo:
      "Vingette Ratings: 1) 6 Beers, 2) 2 Beers, 3) 4 Beers, 4) 3 Beers, 5) 4 Beers, 6) 3 Beers",
    cast: "Directed by THE COEN BROTHERS starring TIM BLAKE NELSON JAMES FRANCO LIAM NEESON and WILLIE WATSON",
  },
  {
    id: 619,
    name: "Hacksaw Ridge",
    year: 2016,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjQ1NjM3MTUxNV5BMl5BanBnXkFtZTgwMDc5MTY5OTE@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "An anti-gun weasel reluctantly joins the US armed forces to serve during WWII in this movie based on a true story.",
    genre: "War",
    subgenre: "",
    runtime: "139 min",
    pairs: "a Soiled Tourniquet",
    drinkRecipe: "Kraken, Baileys, and Chocolate Milk.",
    rated: "R",
    picture1: hacksawRidge1,
    picture2: hacksawRidge2,
    picture3: hacksawRidge3,
    tagline: "GROW A PAIR!",
    taglineWriter: "Florence Nightingale",
    bulletOne:
      "Instead of shooting at them, maybe the Nazi's will stop fighting if asked nicely.",
    bulletTwo:
      "Running out of heros to make movies about so I guess we're stuck with the wimps now.",
    cast: "Directed by MEL GIBSON starring ANDREW GARFIELD SAM WORTHINGTON LUKE BRACEY and VINCE VAUGHN",
  },
  {
    id: 620,
    name: "The Abyss",
    year: 1989,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYWMzMDNlMDQtMTYyYi00OTM3LTllZDYtYThhMTk5MzNhZTRhXkEyXkFqcGdeQXVyMDUyOTUyNQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Scifi",
    subgenre: "",
    runtime: "140 min",
    pairs: "",
    rated: "PG-13",
    picture1: abyss1,
    picture2: abyss2,
    picture3: abyss3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by JAMES CAMERON starring ED HARRIS MICHAEL BIEHN LEO BURMESTER and MARY ELIZABETH MASTRANTONIO",
  },
  {
    id: 621,
    name: "The Rift",
    year: 1990,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGQ1Y2E5NmYtNDMxNC00MDQ5LWI0NjYtNzFkMzNlOGM2MWYyXkEyXkFqcGdeQXVyNjQzNDI3NzY@._V1_SX300.jpg",
    rating: 3,
    synopsis: "",
    genre: "Horror",
    subgenre: "",
    runtime: "83 min",
    pairs: "",
    rated: "R",
    picture1: rift1,
    picture2: rift2,
    picture3: rift3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by JUAN PIQUER SIMON starring JACK SCALIA R. LEE ERMEY RAY WISE and DEBORAH ADAIR",
  },
  {
    id: 622,
    name: "Dead Before Dawn",
    year: 2012,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQ2ODIyNTU1OF5BMl5BanBnXkFtZTcwOTM1MTI2OQ@@._V1_SX300.jpg",
    rating: 3,
    synopsis: "",
    genre: "Horror",
    subgenre: "Comedy",
    runtime: "88 min",
    pairs: "",
    noPairs: "Molson Ice American-Style Lager",
    rated: "R",
    picture1: deadBeforeDawn1,
    picture2: deadBeforeDawn2,
    picture3: deadBeforeDawn3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by APRIL MULLEN starring DEVON BOSTICK MARTHA MACISSAC CHRISTOPHER LLOYD and BRITTANY ALLEN",
  },
  {
    id: 623,
    name: "Harry Potter and the Sorcerer's Stone",
    year: 2001,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjQ3NWNlNmQtMTE5ZS00MDdmLTlkZjUtZTBlM2UxMGFiMTU3XkEyXkFqcGdeQXVyNjUwNzk3NDc@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A four eyed nerd finds out he's half special in this kids tale about magic, wizards, and gross jelly beans.",
    genre: "Adventure",
    subgenre: "",
    runtime: "152 min",
    pairs: "an Expecto Patron.",
    drinkRecipe: "Lemonade, cranberry juice, tequila",
    rated: "PG",
    picture1: sorceror1,
    picture2: sorceror2,
    picture3: sorceror3,
    tagline: "WITCH, PLEASE!",
    taglineWriter: "Merlin",
    bulletOne: "Quidditch is good hexercise.",
    bulletTwo:
      "Adults being way too chill about kids hanging with Hagrid. 'Go play in the shed with the halfwit janitor!' ",
    cast: "Directed by CHRIS COLUMBUS starring DANIEL RADCLIFFE RUPERT GRINT EMMA WATSON and RICHARD HARRIS",
  },
  {
    id: 624,
    name: "Harry Potter and the Chamber of Secrets",
    year: 2002,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTcxODgwMDkxNV5BMl5BanBnXkFtZTYwMDk2MDg3._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Adventure",
    subgenre: "",
    runtime: "161 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 625,
    name: "Harry Potter and the Prisoner of Azkaban",
    year: 2004,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTY4NTIwODg0N15BMl5BanBnXkFtZTcwOTc0MjEzMw@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Adventure",
    subgenre: "",
    runtime: "142 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 626,
    name: "Harry Potter and the Goblet of Fire",
    year: 2005,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTI1NDMyMjExOF5BMl5BanBnXkFtZTcwOTc4MjQzMQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Adventure",
    subgenre: "",
    runtime: "157 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 627,
    name: "Harry Potter and the Order of the Phoenix",
    year: 2007,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTM0NTczMTUzOV5BMl5BanBnXkFtZTYwMzIxNTg3._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Adventure",
    subgenre: "",
    runtime: "138 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 628,
    name: "Harry Potter and the Half-Blood Prince",
    year: 2009,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzU3NDg4NTAyNV5BMl5BanBnXkFtZTcwOTg2ODg1Mg@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Adventure",
    subgenre: "",
    runtime: "153 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 629,
    name: "Harry Potter and the Deathly Hollows: Part 1",
    year: 2010,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQ2OTE1Mjk0N15BMl5BanBnXkFtZTcwODE3MDAwNA@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Adventure",
    subgenre: "",
    runtime: "146 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 630,
    name: "Harry Potter and the Deathly Hollows: Part 2",
    year: 2011,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGVmMWNiMDktYjQ0Mi00MWIxLTk0N2UtN2ZlYTdkN2IzNDNlXkEyXkFqcGdeQXVyODE5NzE3OTE@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Adventure",
    subgenre: "",
    runtime: "130 min",
    pairs: "",
    rated: "PG-13",
    picture1: hollows1,
    picture2: hollows3,
    picture3: hollows2,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by DAVID YATES starring DANIEL RADCLIFFE EMMA WATSON RUPERT GRINT and MICHAEL GAMBON",
  },
  {
    id: 631,
    name: "American Pie 2",
    year: 2001,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTEyYjhiMjYtNjU3YS00NmQ4LTlhNTEtYTczNWY3MGJmNzE2XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "108 min",
    pairs: "",
    rated: "R",
    picture1: ap21,
    picture2: ap22,
    picture3: ap23,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by J.B. ROGERS starring JASON BIGGS SEAN WILLIAM SCOTT CHRIS KLEIN and SHANNON ELIZABETH",
  },
  {
    id: 632,
    name: "American Wedding",
    year: 2003,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTAwNTIzNDk1MDVeQTJeQWpwZ15BbWU3MDMwNzAwMDE@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "96 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 633,
    name: "American Reunion",
    year: 2012,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTY4MTEyMzU1N15BMl5BanBnXkFtZTcwNDQ0NTc1Nw@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "113 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "OH MY GOD! THIS MOVIE'S MORE THAN OK!",
    taglineWriter: "Harold Lee",
    bulletOne: "Saved the best for last of the 'real' American Pie Sequals.",
    bulletTwo: "",
    cast: "Directed by JON HURWITZ & JAYDEN SCHLOSSBERG starring JASON BIGGS  ALYSON HANNIGAN and SEANN WILLIAM SCOTT",
  },
  {
    id: 634,
    name: "The Wrong Missy",
    year: 2020,
    poster:
      "https://m.media-amazon.com/images/M/MV5BY2QwZWJlZjMtNzU5NC00NTA0LWI1MjQtYWQ1ZTg4NWZmNjdkXkEyXkFqcGdeQXVyMTEyMjM2NDc2._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "An overworked Sober Sally extends a bad date while on his company retreat.",
    genre: "Comedy",
    subgenre: "",
    runtime: "90 min",
    pairs: "",
    noPairs: "an O'Doul's.",
    rated: "TV-MA",
    picture1: wrongMissy1,
    picture2: wrongMissy2,
    picture3: wrongMissy3,
    tagline: "Wrong Missy? Right Movie!",
    taglineWriter: "Bo Clean",
    bulletOne: "Sending mixed messages.",
    bulletTwo: "Filming a movie as an excuse to vacation in Hawaii.",
    cast: "Directed by TYLER SPINDEL starring DAVID SPADE  LAREN LAPKUS  NICK SWARDSON and GEOFF PIERSON",
  },
  {
    id: 635,
    name: "I Now Pronounce You Chuck & Larry",
    year: 2007,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTM4NDQ3NDQ3Nl5BMl5BanBnXkFtZTcwMjE4NjY0MQ@@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Two straight firemen pretend to be gay lovers in order to reap insurance benefits.",
    genre: "Comedy",
    subgenre: "",
    runtime: "115 min",
    pairs: "an Appletini.",
    drinkRecipe: "Apple Juice, Lemon Juice, Green Apple Schnapps, Vodka, Ice.",
    rated: "PG-13",
    picture1: chuckLarry1,
    picture2: chuckLarry2,
    picture3: chuckLarry3,
    tagline: "Faking homosexuality is a pain in the ass",
    taglineWriter: "Clay Ache-in",
    bulletOne: "PRIME Jessica Biel.",
    bulletTwo: "Learning that love is like a circle.",
    cast: "Directed by DENNIS DUGAN starring ADAM SANDLER KEVIN JAMES JESSICA BIEL and VING RHAMES",
  },
  {
    id: 636,
    name: "Paul Blart: Mall Cop",
    year: 2009,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTM2NzA1MzE1NF5BMl5BanBnXkFtZTcwOTYyNDIzMg@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "A security officer's day dream: high flying action at the mall.",
    genre: "Comedy",
    subgenre: "",
    runtime: "91 min",
    pairs: "fruity cocktails.",
    rated: "PG",
    picture1: paulBlart1,
    picture2: paulBlart2,
    picture3: paulBlart3,
    tagline: "Also available at Blockbuster!",
    taglineWriter: "Doug Benson",
    bulletOne: "Todd's art career didn't take off.",
    bulletTwo: "Playing a pretty convincing happy drunk.",
    cast: "Directed by STEVE CARR starring KEVIN JAMES  KEIR O'DONNELL  JAYMA MAYS and RAINI RODRIGUEZ",
  },
  {
    id: 637,
    name: "Paul Blart: Mall Cop 2",
    year: 2015,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTkwMjkxNDQ1MV5BMl5BanBnXkFtZTgwNDQwODIxNTE@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "94 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "HARD TO SAY THREE TIMES FAST!",
    taglineWriter: "Jack Black",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 638,
    name: "Observe and Report",
    year: 2009,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjA2MzMzODExOF5BMl5BanBnXkFtZTcwMjkzNzA0Mg@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "86 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "Basically 'Paul Blart: Mall Cop' on weed brownies.",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 639,
    name: "Zookeeper",
    year: 2011,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTMyNjYyODk0Ml5BMl5BanBnXkFtZTcwNDUzOTczNQ@@._V1_SX300.jpg",
    rating: 3,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "102 min",
    pairs: "",
    rated: "PG",
    picture1: zookeeper1,
    picture2: zookeeper2,
    picture3: zookeeper3,
    tagline: "GREAT DECOR!",
    taglineWriter: "Rainforest Cafe",
    bulletOne: "Not going far enough to pee.",
    bulletTwo: "",
    cast: "Directed by FRANK CORACI starring KEVIN JAMES ROSARIO DAWSON KEN JEONG and NAT FAXON",
  },
  {
    id: 640,
    name: "Here Comes the Boom",
    year: 2012,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTUxMzEzNDQ0Nl5BMl5BanBnXkFtZTcwMDI2NTY1OA@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Sports",
    runtime: "105 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Not my kind of flick, Douglas.",
    taglineWriter: "Arthur Spooner",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 641,
    name: "The Wedding Singer",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjM5YTQ0ZGYtMWExZi00MTFmLTg0YjUtZDcyMGNiYzE5MmNmL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Romcom",
    runtime: "97 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 642,
    name: "The Nutty Professor",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTcwODlmZDktNWRkOC00NTFlLTkyMjQtYWUxMzZkNDE4MGVmXkEyXkFqcGdeQXVyNTIzOTk5ODM@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "95 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 643,
    name: "Never Been Kissed",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjZmNTUzN2QtYzUxZi00M2YwLTgzNTgtZDkzYjg0YWVmNmYzXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Romcom",
    subgenre: "",
    runtime: "107 min",
    pairs: "",
    rated: "PG-13",
    picture1: neverKissed1,
    picture2: neverKissed2,
    picture3: neverKissed3,
    tagline: "STORY OF MY LIFE!",
    taglineWriter: "Social Distorion",
    bulletOne: "A perfect spoof of 'Not Another Teen Movie'.",
    bulletTwo: "",
    cast: "Directed by RAJA GOSNELL starring DREW BARRYMORE DAVID ARQUETTE MICHAEL VARTAN and MOLLY SHANNON",
  },
  {
    id: 644,
    name: "Norbit",
    year: 2007,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTI4NDE4MjgyNV5BMl5BanBnXkFtZTcwMTQwODc0MQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "After meeting a new woman, Eddie must find a way to break up with his fatter (woman) self. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "102 min",
    pairs: "",
    noPairs: "a lamb kebab and a wine cooler.",
    rated: "PG-13",
    picture1: norbit1,
    picture2: norbit2,
    picture3: norbit3,
    tagline: "Reminds me of The Fatties: Fart One",
    taglineWriter: "ALPA CHINO",
    bulletOne:
      "Eddie Murphy plays Eddie Murphy as Eddie Murphy and Eddie Murphy. ",
    bulletTwo: "Showin' fools how a bitch go down a slide. ",
    cast: "Directed by BRIAN ROBBINS starring EDDIE MURPHY THANDIE NEWTON TERRY CREWS and KATT WILLIAMS",
  },
  {
    id: 645,
    name: "Soul Plane",
    year: 2004,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTc5NDcxNjg5N15BMl5BanBnXkFtZTcwMDIyNzczMw@@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "After getting his li'l ass stuck in a toilet seat, Kevin Hart uses the settlement money to create his own airline.",
    genre: "Comedy",
    subgenre: "",
    runtime: "86 min",
    pairs: "Spin & Juice.",
    drinkRecipe: "Gin and Juice. Stirred, not shaken.",
    rated: "R",
    picture1: soulplane1,
    picture2: soulplane2,
    picture3: soulplane3,
    tagline: "HIGHER THAN A MU'FUCKA",
    taglineWriter: "Method Man",
    bulletOne:
      "From low-class seating to arab-looking passengers, this movie is hilarious.",
    bulletTwo: "Getting a stinky pinky from a baked potato.",
    cast: "Directed by JESSY TERRERO starring KEVIN HART  METHOD MAN TOM ARNOLD and SNOOP DOGG",
  },
  {
    id: 646,
    name: "White Chicks",
    year: 2004,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTY3OTg2OTM3OV5BMl5BanBnXkFtZTYwNzY5OTA3._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "109 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "MOVE BITCH!",
    taglineWriter: "Ludacris",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 647,
    name: "Idiocracy",
    year: 2006,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWQ4MzI2ZDQtYjk3MS00ODdjLTkwN2QtOTBjYzIwM2RmNzgyXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "84 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne:
      "Surprising yourself and others with your prestigious law degree.",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 648,
    name: "Office Space",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTA5MzQ3MzI1NV5BMl5BanBnXkFtZTgwNTcxNTYxMTE@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "89 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 649,
    name: "Oblivion",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQwMDY0MTA4MF5BMl5BanBnXkFtZTcwNzI3MDgxOQ@@._V1_SX300.jpg",
    rating: 4,
    year: 2013,
    synopsis:
      "In the not so distant future, a simple drone repair tech gets caught up in a conspiracy where he must decide to either come back down to Earth or keep his head in the clouds.",
    genre: "Action",
    subgenre: "Scifi",
    runtime: "124 min",
    pairs: "a Scav Hole.",
    drinkRecipe: "Sweet Tea, Moonshine, Sparkling Lemon Water.",
    noPairs: "",
    rated: "PG-13",
    picture1: oblivion1,
    picture2: oblivion3,
    picture3: oblivion2,
    tagline: "I NEVER SAID THAT!",
    taglineWriter: "Pubilius Horatius Cocles",
    bulletOne: "Having a sweet off the grid summer cabin.",
    bulletTwo: "AI's electric hopes and dreams for 2077.",
    cast: "Directed by JOSEPH KOSINSKI starring TOM CRUISE MORGAN FREEMAN ANDREA RISEBOROUGH and OLGA KURYLENKO",
  },
  {
    id: 650,
    name: "Balls of Fury",
    year: 2007,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjA3MDQ1Mzc4N15BMl5BanBnXkFtZTcwODI0NTc0MQ@@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "A child ping pong prodigy grows up to be not so prodigious - but when a mysterious invite appears for an international tournament, he dusts off the paddle and plays with his balls.",
    genre: "Comedy",
    subgenre: "Sports",
    runtime: "90 min",
    pairs: "a Lucky Cricket.",
    drinkRecipe: "Midori, Sprite.",
    rated: "PG-13",
    picture1: bof1,
    picture2: bof3,
    picture3: bof2,
    tagline: "Makes Forest Gump look like Forest Chump",
    taglineWriter: "The Hammer",
    bulletOne: "Learning how to entertain guests and handle volunteers.",
    bulletTwo:
      "The whole cast is hilarious, but James Hong is outstanding. Such a good performance.",
    cast: "Directed by ROBERT BEN GARANT starring DAN FOGLER CHRISTOPHER WALKEN MAGGIE Q and GEORGE LOPEZ",
  },
  {
    id: 651,
    name: "Glory",
    year: 1989,
    poster:
      "https://m.media-amazon.com/images/M/MV5BODhlNjA5MDEtZDVhNS00ZmM3LTg1YzAtZGRjNjhjNTAzNzVkXkEyXkFqcGdeQXVyNjUwMzI2NzU@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "This American Civil War story follows the 54th Massachusetts Volunteer Infantry, the first African American unit in the US Army. Bullets move pretty fast, if you stop and look around once in a while you could be dead.",
    genre: "War",
    subgenre: "History",
    runtime: "122 min",
    pairs: "a shrapnel shot.",
    drinkRecipe: "Equal parts Cannon Blast and Jagermeister.",
    rated: "R",
    picture1: glory1,
    picture2: glory2,
    picture3: glory3,
    tagline: "Needs more baseball.",
    taglineWriter: "Can Byrnes",
    bulletOne: "No Guts, No G(l)ory.",
    bulletTwo: "Finding out that war isn’t always black and white.",
    cast: "Directed by EDWARD ZWICK starring MATTHEW BRODERICK DENZEL WASHINGTON CARY ELWES and MORGAN FREEMAN",
  },
  {
    id: 652,
    name: "Napolean Dynamite",
    year: 2004,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjYwNTA3MDIyMl5BMl5BanBnXkFtZTYwMjIxNjA3._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "96 min",
    pairs: "",
    rated: "PG",
    picture1: napoleon1,
    picture2: napoleon2,
    picture3: napoleon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by JARED HESS starring JOHN HEDER EFREN RAMIREZ JOHN GRIES and TINA MAJORINO",
  },
  {
    id: 653,
    name: "The Counselor",
    year: 2013,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTc3ODk0MTY0N15BMl5BanBnXkFtZTgwOTU2MTEzMDE@._V1_SX300.jpg",
    rating: 3,
    synopsis:
      "Despite everyone in Hollywood showing up this movie's a painfully slow dud.",
    genre: "Crime",
    subgenre: "Thriller",
    runtime: "117 min",
    pairs: "an Objection.",
    drinkRecipe: "whiskey on ice, splash of lemon.",
    rated: "R",
    picture1: counselor1,
    picture2: counselor2,
    picture3: counselor3,
    tagline: "COUNSELOR!",
    taglineWriter: "Max Cady",
    bulletOne: "Pitt's cool bolo tie.",
    bulletTwo: "The thing about Mary is that she's into autoeratisicm.",
    cast: "Directed by RIDLEY SCOTT starring MICHAEL FASSBENDER BRAD PITT CAMERON DIAZ and PENELOPE CRUZ",
  },
  {
    id: 654,
    name: "Bone Tomahawk",
    year: 2015,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzQ0MzE4OTUzMF5BMl5BanBnXkFtZTgwODAyNzI3NjE@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "A group of grizzled gunslingers sets off to help a gimp save his wife from a clan of cannibalistic inbreds.",
    genre: "Horror",
    subgenre: "Western",
    runtime: "132 min",
    pairs: "Sacrificial Blood.",
    drinkRecipe: "Bloody Mary mix with whiskey.",
    rated: "Not Rated",
    picture1: tomahawk1,
    picture2: tomahawk2,
    picture3: tomahawk3,
    tagline: "I didn't finish it, not enough gore.",
    taglineWriter: "E. Lyroth",
    bulletOne: "Having almost a black comedy feel.",
    bulletTwo: "The cave scene.",
    cast: "Directed by S. CRAIG ZAHLER starring KURT RUSSELL PATRICK WILSON MATTHEW FOX and RICHARD JENKINS",
  },
  {
    id: 655,
    name: "Mean Girls",
    year: 2004,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjE1MDQ4MjI1OV5BMl5BanBnXkFtZTcwNzcwODAzMw@@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "It's tough being the new kid in school, don't fall into the wrong crowd, especially if you're hot and everyone wants to be friends with you.",
    genre: "Comedy",
    subgenre: "",
    runtime: "97 min",
    pairs: "drinks in the house from 4 to 6!",
    drinkRecipe: "fruit punch and vodka.",
    rated: "PG-13",
    picture1: meangirls1,
    picture2: meangirls2,
    picture3: meangirls3,
    tagline: "Hey Regina, how's your mom's pill addiction?",
    taglineWriter: "Liz Lemon",
    bulletOne: "Coming out to your crush.",
    bulletTwo: "Better sex ed. class than IRL.",
    cast: "Directed by MARK WATERS starring LINDSAY LOHAN RACHAEL McADAMS JONATHAN BENNETT and LIZZY CAPLAN",
  },
  {
    id: 656,
    name: "Grown Ups",
    year: 2010,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjA0ODYwNzU5Nl5BMl5BanBnXkFtZTcwNTI1MTgxMw@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "102 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 657,
    name: "We Were Soldiers",
    year: 2002,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTk3NTc4MTktNjFmZC00ZjdjLWE0ZjgtNTM4NzdmMDBjOTcxXkEyXkFqcGdeQXVyNDkzNTM2ODg@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "War",
    subgenre: "",
    runtime: "138 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 658,
    name: "Inside Man",
    year: 2006,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjc4MjA2ZDgtOGY3YS00NDYzLTlmNTEtYWMxMzcwZjgzYWNjXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Crime",
    subgenre: "Action",
    runtime: "129 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 659,
    name: "Children of Men",
    year: 2006,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQ5NTI2NTI4NF5BMl5BanBnXkFtZTcwNjk2NDA2OQ@@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "In a utopian future where there are no kids, a terrorist attack sets off a series of events for an unlikely hero and his pregnant companion.",
    genre: "Scifi",
    subgenre: "Drama",
    runtime: "109 min",
    pairs: "Instant Formula.",
    drinkRecipe: "Loconut Rum, vodka, water and powdered milk.",
    rated: "R",
    picture1: com1,
    picture2: com2,
    picture3: com3,
    tagline: "Disobey the law? What a Dirty Rotten Scoundrel!",
    taglineWriter: "Steve Martin",
    bulletOne: "Sweet uncut single take scene.",
    bulletTwo: "Using the death of a celebrity to call out of work.",
    cast: "Directed by ALFONSO CUARON starring JULIANNE MOORE CLIVE OWEN CHIWETEL EJIOFOR and MICHAEL CAINE",
  },
  {
    id: 660,
    name: "Repo Men",
    year: 2010,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjA4MTUxODM0MV5BMl5BanBnXkFtZTcwMDg3MTgwMw@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Scifi",
    subgenre: "Action",
    runtime: "111 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 661,
    name: "Daybreakers",
    year: 2009,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzg3NDYyMDU4Nl5BMl5BanBnXkFtZTcwODA5NzA5Mg@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Horror",
    subgenre: "Scifi",
    runtime: "98 min",
    pairs: "",
    rated: "R",
    picture1: daybreakers1,
    picture2: daybreakers2,
    picture3: daybreakers3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by MICHAEL SPIERIG starring ETHAN HAWKE WILLEM DAFOE SAM NEILL and HARRIET MINTO-DAY",
  },
  {
    id: 662,
    name: "Lions for Lambs",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTcyMzc0MzYyM15BMl5BanBnXkFtZTcwMTM2NDk0NA@@._V1_SX300.jpg",
    rating: 3,
    year: 2007,
    synopsis:
      "A senator who won't apologize for his intelligence, a reporter who doesn't report, and a profoessor who can't change the hearts and minds of his students discuss the war on terror.",
    genre: "Drama",
    subgenre: "Political",
    runtime: "92 min",
    pairs: "drinks from a red mug.",
    drinkRecipe: "Kentucky Rye, Coca-Cola, Sweet Tea.",
    noPairs: "",
    rated: "R",
    picture1: lionslambs1,
    picture2: lionslambs2,
    picture3: lionslambs3,
    tagline: "NOT ENOUGH FRATRICIDE!",
    taglineWriter: "The Lamb King",
    bulletOne: "Encouraging soldiers to question orders.",
    bulletTwo: "Not leaving your pal, even with a ladder.",
    cast: "Directed by ROBERT REDFORD starring TOM CRUISE MERYL STREEP MICHAEL PEÑA and ROBERT REDFORD",
  },
  {
    id: 663,
    name: "Ghost Team",
    year: 2016,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDgzMzQzNDE1OF5BMl5BanBnXkFtZTgwODk1NzIxOTE@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A paranormal nerd assembles a team of night bumpers who stumble into more than they can handle while roaming a haunted homestead.",
    genre: "Comedy",
    subgenre: "Horror",
    runtime: "83 min",
    pairs: "a Phantom Group.",
    drinkRecipe: "Monster ultra white, vodka, and splash of lime juice",
    rated: "PG-13",
    picture1: ghostTeam1,
    picture2: ghostTeam2,
    picture3: ghostTeam3,
    tagline: "OMG! WHERE'D HE GET THOSE PEEPERS?",
    taglineWriter: "Jeeper's Creeper",
    bulletOne: "Living out your strange childhood dreams.",
    bulletTwo: "It’s ok to believe in ghosts, but aliens are ridiculous.",
    cast: "Directed by OLIVER IRVING starring JON HEDER DAVID KRUMHOLTZ JUSTIN LONG and MELONIE DIAZ",
  },
  {
    id: 664,
    name: "The Big Hit",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjMyMmI3ZWMtZTUyMC00OTVhLTg5YTctYjY2YjcxY2RlYzcwXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 3,
    synopsis:
      "Gratuitous kicks, flips, and firing guns through the air plus enough tumbles and somersaults to make the Power Rangers blush... and that’s just the first 12 minutes!",
    genre: "Action",
    subgenre: "",
    runtime: "91 min",
    pairs: "a small blow.",
    drinkRecipe: "champagne, lemon seltzer water, orange slice.",
    rated: "R",
    picture1: bigHit1,
    picture2: bigHit2,
    picture3: bigHit3,
    tagline: "We'll always take a big hit!",
    taglineWriter: "Cheap & Chang",
    bulletOne: "Super boxy 90’s suits.",
    bulletTwo:
      "Video Store nostalgia: late fees, rewind fees, and power hungry teens.",
    cast: "Directed by KIRK WONG starring MARK WAHLBERG LOU DIAMOND PHILLIPS CHRISTINA APPLEGATE and AVERY BROOKS",
  },
  {
    id: 665,
    name: "American Hero",
    year: 2015,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTUxNDE2NDU5Ml5BMl5BanBnXkFtZTgwMTE4MDczNzE@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "Comedy",
    runtime: "86 min",
    pairs: "",
    rated: "Not Rated",
    picture1: americanHero1,
    picture2: americanHero2,
    picture3: americanHero3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by NICK LOVE starring STEPHEN DORFF EDDIE GRIFFIN LUIS DA SILVA JR. and CHRISTOPER BERRY",
  },
  {
    id: 666,
    name: "From Hell",
    year: 2001,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTM1MjkxNTQxMV5BMl5BanBnXkFtZTYwMDMxNDg2._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Horror",
    subgenre: "Thriller",
    runtime: "122 min",
    pairs: "",
    rated: "R",
    picture1: fromHell1,
    picture2: fromHell2,
    picture3: fromHell3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by ALBERT HUGHES starring JOHNNY DEPP HEATHER GRAHAM IAN HOLM and ROBBIE COLTRANE",
  },
  {
    id: 667,
    name: "Blank Check",
    year: 1994,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNWM4M2QwOWUtNGMwMS00Nzg2LTg0YjktYTU4M2ZhYTMwYTU5XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "93 min",
    pairs: "",
    rated: "PG",
    picture1: blankCheck1,
    picture2: blankCheck2,
    picture3: blankCheck3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by RUPER WAINWRIGHT starring BRIAN BONSALL KAREN DUFFY JAMES REBHORN and JAYNE ATKINSON",
  },
  {
    id: 668,
    name: "Boogie Nights",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BY2E2YWYxY2QtZmJmZi00MjJlLWFiYWItZTk5Y2IyMWQ1ZThhXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Drama",
    subgenre: "",
    runtime: "155 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 669,
    name: "American Sniper",
    year: 2014,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTkxNzI3ODI4Nl5BMl5BanBnXkFtZTgwMjkwMjY4MjE@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "War",
    subgenre: "",
    runtime: "133 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 670,
    name: "Night at the Museum",
    year: 2006,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQyOTM4MDMxN15BMl5BanBnXkFtZTcwODg5NTQzMw@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "108 min",
    pairs: "",
    rated: "PG",
    picture1: natm1,
    picture2: natm2,
    picture3: natm3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by SHAWN LEVY starring BEN STILLER DICK VAN DYKE MICKEY ROONEY and CARLA GUGINO",
  },
  {
    id: 671,
    name: "He's Just Not That Into You",
    year: 2009,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTY5MTUyNDgwMF5BMl5BanBnXkFtZTcwNzYzMDQyMg@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Romcom",
    runtime: "129 min",
    pairs: "",
    rated: "PG-13",
    picture1: hjntiy1,
    picture2: hjntiy2,
    picture3: hjntiy3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "stuff",
    bulletTwo: "",
    cast: "Directed by KEN KWAPIS starring JENNIFER ANISTON JUSTIN LONG BRADLEY COOPER and JENNIFER CONNELLY",
  },
  {
    id: 672,
    name: "All About Steve",
    year: 2009,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTA5MTQxOTE2OTReQTJeQWpwZ15BbWU3MDY5NzI4NzI@._V1_SX300.jpg",
    rating: 3,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Romcom",
    runtime: "99 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 673,
    name: "The Proposal",
    year: 2009,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOGM5YTNiYzktNmEwZC00ZjE5LWIyNzEtOTUwNDE0NmVkYzE3XkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Romcom",
    runtime: "108 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 674,
    name: "Watchmen",
    year: 2009,
    poster:
      "https://m.media-amazon.com/images/M/MV5BY2IzNGNiODgtOWYzOS00OTI0LTgxZTUtOTA5OTQ5YmI3NGUzXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Superhero",
    subgenre: "Action",
    runtime: "162 min",
    pairs: "",
    rated: "R",
    picture1: watchmen1,
    picture2: watchmen2,
    picture3: watchmen3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "Blue dick.",
    bulletTwo: "",
    cast: "Directed by ZACK SNYDER starring JACKIE EARLE HALEY BILL CRUDUP JEFFREY DEAD MORGAN and MALIN AKERMAN",
  },
  {
    id: 675,
    name: "Message from the King",
    year: 2016,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTJlNGVhZjUtYjJhZC00ZTRiLWI0YTgtNzdlNWJhZWEwNTAzXkEyXkFqcGdeQXVyMjgzMTA2NzY@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "102 min",
    pairs: "",
    rated: "R",
    picture1: messageFromKing1,
    picture2: messageFromKing2,
    picture3: messageFromKing3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by FABRICE DE WELZ starring CHADWICK BOSEMAN LUKE EVANS ALFRED MOLINA and TERESA PALMER",
  },
  {
    id: 676,
    name: "Year One",
    year: 2009,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTcyMjI2OTgxN15BMl5BanBnXkFtZTcwODU3ODkzMg@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "97 min",
    pairs: "",
    rated: "PG-13",
    picture1: year11,
    picture2: year12,
    picture3: year13,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by HAROLD RAMIS starring JACK BLACK MICHAEL CERA OLIVIA WILDE and OLIVER PLATT",
  },
  {
    id: 677,
    name: "Avengers: Endgame",
    year: 2019,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTc5MDE2ODcwNV5BMl5BanBnXkFtZTgwMzI2NzQ2NzM@._V1_SX300.jpg",
    rating: 2,
    synopsis:
      "Not satisfied with only a 50% death rate on the first go around, the Avengers team up to cause even more destruction.",
    genre: "Superhero",
    subgenre: "Action",
    runtime: "181 min",
    pairs: "a Money Grab.",
    drinkRecipe: "Absinthe, Bitters, Tuaca.",
    rated: "PG-13",
    picture1: endgame1,
    picture2: endgame2,
    picture3: endgame3,
    tagline: "Wow! These morons watch anything we make!",
    taglineWriter: "Disney, probably.",
    bulletOne: "Lame 1000% CGI fight scenes.",
    bulletTwo: "Tony Stark pretending he isn't going to help for some reason.",
    cast: "Directed by ANTHONY RUSSO starring ROBERT DOWNEY JR. CHRIS EVANS SCARLETT JOHANSSON and CHRIS HEMSWORTH",
  },
  {
    id: 678,
    name: "Only God Forgives",
    year: 2013,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzE5NzcxMTk5NF5BMl5BanBnXkFtZTcwNjE2MDg2OQ@@._V1_SX300.jpg",
    rating: 1,
    synopsis:
      "Ryan Gosling silently roams Bangkok while looking menacing under shining multicolored lights. Cool artistic camera work. Not. Huge pass.",
    genre: "Crime",
    subgenre: "Drama",
    runtime: "90 min",
    pairs: "",
    noPairs: "Singha beer.",
    rated: "R",
    picture1: godForgives1,
    picture2: godForgives2,
    picture3: godForgives3,
    tagline: "I can forgive A LOT of things, but not this",
    taglineWriter: "God",
    bulletOne: "Atsy fartsy piece of crap.",
    bulletTwo:
      "Watching an actual mass or even being an altar boy in Boston in the 80's would be more fun than watching this. ",
    cast: "Directed by NICOLAS WINDING REFN starring RYAN GOSLING KRISTIN SCOTT THOMAS VITHAYA PANSRINGARM and TOM BURKE",
  },
  {
    id: 679,
    name: "Match Point",
    year: 2005,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQ1NzQ4OTM4MV5BMl5BanBnXkFtZTcwNzY2OTQzMw@@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A retired tennis pro risks losing everything he's barely worked for in this story of dumb luck… or lack thereof.",
    genre: "Romcom",
    subgenre: "Thriller",
    runtime: "124 min",
    pairs: "a Game Set.",
    drinkRecipe: "Pimm's, pink lemonade, and gin.",
    rated: "R",
    picture1: matchPoint1,
    picture2: matchPoint2,
    picture3: matchPoint3,
    tagline: "This film is an American Tragedy!",
    taglineWriter: "Theodore Dreiser",
    bulletOne: "Mixing work with play with family.",
    bulletTwo:
      "Choosing between making love and being part of some fertility project.",
    cast: "Directed by WOODY ALLEN starring SCARLETT JOHANSSON JONATHAN RHYS MEYERS EMILY MORTIMER and BRIAN COX",
  },
  {
    id: 680,
    name: "Unhinged",
    year: 2020,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjc1Mjg5NjItY2I2MS00NDk3LWI5NGYtNzZjNTNiZmMwZTA3XkEyXkFqcGdeQXVyMjM4NTM5NDY@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A psycho stalks a woman in a fit of road rage. Or in this case an unfit of road rage.",
    genre: "Thriller",
    subgenre: "",
    runtime: "90 min",
    pairs: "a bourbon milkshake.",
    rated: "R",
    picture1: unhinged1,
    picture2: unhinged2,
    picture3: unhinged3,
    tagline: "Even I never gained that much",
    taglineWriter: "Christian Whale",
    bulletOne: "Crowe certainly unhinged his jaw to eat enough for this role.",
    bulletTwo:
      "In Max Cady fashion, Russell teaches a family about loss - though not weight loss.",
    cast: "Directed by DERRICK BORTE starring RUSSELL CROWE CAREN PISTORIUS GABRIEL BATEMAN and AUSTIN P. MACKENZIE",
  },
  {
    id: 681,
    name: "The Game",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGVmMDNmYmEtNGQ2Mi00Y2ZhLThhZTYtYjE5YmQzMjZiZGMxXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Thriller",
    subgenre: "Crime",
    runtime: "129 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 682,
    name: "My Girl",
    year: 1991,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYWM2ZDliNjItZTcxOC00NTY2LWE1ODctNzRhNGM3YWIyYjBiXkEyXkFqcGdeQXVyNTIzOTk5ODM@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Dan and Jamie trade places again. Meanwhile two strange kids hang around a funeral parlor.",
    genre: "Drama",
    subgenre: "",
    runtime: "102 min",
    pairs: "Embalming Fluid.",
    drinkRecipe: "Rum, pink lemonade, and gin.",
    rated: "PG",
    picture1: myGirl1,
    picture2: myGirl2,
    picture3: myGirl3,
    tagline: "NO! NOT THE BEES!",
    taglineWriter: "Sargeant Edward Malus",
    bulletOne: "Resist the Temptations to cry.",
    bulletTwo:
      "Don't forget your glasses, you can't watch without your glasses.",
    cast: "Directed by HOWARD ZIEFF starring ANNA CHLUMSKY MACAULAY CULKIN DAN AYKROYD and JAMIE LEE CURTIS",
  },
  {
    id: 683,
    name: "Now and Then",
    year: 1995,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTM2MDQ1YjUtMGM0NC00NmFlLTljMDktZjJiNWRhMWYxOWYyXkEyXkFqcGdeQXVyNjgzMjI4ODE@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Drama",
    subgenre: "",
    runtime: "100 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 684,
    name: "Simon Birch",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BN2ZlNDkzY2ItNTkxMi00NzY3LTgyZDItZTI5MjczMzVmNGViXkEyXkFqcGdeQXVyMzM4MjM0Nzg@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "David Spade said it best: 'It's about a goofy little kid with problems'.",
    genre: "Drama",
    subgenre: "",
    runtime: "114 min",
    pairs: "a hidden baseball.",
    drinkRecipe: "Cranapple juice, lemon wedge, and gin.",
    rated: "PG",
    picture1: simonBirch1,
    picture2: simonBirch2,
    picture3: simonBirch3,
    tagline: "Dont forget, I'm in this!",
    taglineWriter: "Jim Carrey",
    bulletOne: "Being as cute as a baby turtle.",
    bulletTwo: "Typecasting in the school play.",
    cast: "Directed by MARK STEVEN JOHNSON starring IAN MICHAEL SMITH JOSEPH MAZZELLO ASHLEY JUDD and OLIVER PLATT",
  },
  {
    id: 685,
    name: "Cruel Intentions",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjMxNjM5OTQ2M15BMl5BanBnXkFtZTgwNjUxNzYxMTE@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Drama",
    subgenre: "Thriller",
    runtime: "97 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 686,
    name: "Edward Scissorhands",
    year: 1990,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDUxN2I5NDUtZjdlMC00NjlmLTg0OTQtNjk0NjAxZjFmZTUzXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Drama",
    subgenre: "Scifi, Cult Classic",
    runtime: "105 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 687,
    name: "Mousehunt",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzE0NTRhZWQtZmE5OS00NTI5LWJhMzMtMGU4MzE4MmRlZDE0L2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "98 min",
    pairs: "",
    rated: "PG",
    picture1: mousehunt1,
    picture2: mousehunt2,
    picture3: mousehunt3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by GORE VERBINSKI starring NATHAN LANE LEE EVANS MAURY CHAYKIN and CHRISTOPHER WALKEN",
  },
  {
    id: 688,
    name: "There's Something About Mary",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZWFlZjE5OTYtNWY0ZC00MzgzLTg5MjUtYTFkZjk2NjJkYjM0XkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "119 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 689,
    name: "Patch Adams",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzM4Y2FlNzYtZmY5Yy00NzU4LTk1ODItY2NjYWYzYzUyZGM3L2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Drama",
    runtime: "115 min",
    pairs: "",
    rated: "PG-13",
    picture1: patchAdams1,
    picture2: patchAdams2,
    picture3: patchAdams3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by TOM SHADYAC starring ROBIN WILLAMS MONICA POTTER PHILIP SEYMOUR HOFFMAN and BOB GUNTON",
  },
  {
    id: 690,
    name: "What's Eating Gilbert Grape",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BY2EyZDlhNjItODYzNi00Mzc3LWJjOWUtMTViODU5MTExZWMyL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Drama",
    subgenre: "",
    runtime: "118 min",
    pairs: "",
    rated: "PG-13",
    picture1: gilbertGrape1,
    picture2: gilbertGrape2,
    picture3: gilbertGrape3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by LASSE HALLSTROM starring JOHNNY DEPP LEONARDO DiCAPRIO JULIETTE LEWIS and DARLENE CATES",
  },
  {
    id: 691,
    name: "Not Another Teen Movie",
    year: 2001,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTRhNWJmNTUtM2VhYi00NzdkLTgyYTUtMWMzMWI3MzFmNzk5XkEyXkFqcGdeQXVyNjgzMjI4ODE@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "89 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 692,
    name: "Matilda",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGVlYTA5NzQtMjBkNy00MmE1LWI3YzYtMTVkMTNmOGE5YmEwXkEyXkFqcGdeQXVyMTAyOTE2ODg0._V1_SX300.jpg",
    rating: 5,
    synopsis:
      " An evil little witch divides her time between ruining her fathers business, hating her family, and tormenting the school principal.",
    genre: "Comedy",
    subgenre: "",
    runtime: "102 min",
    pairs: "",
    noPairs: "a glass of sherry.",
    rated: "PG",
    picture1: matilda1,
    picture2: matilda2,
    picture3: matilda3,
    tagline: "A WHALE OF A MOVIE!",
    taglineWriter: "Captain Ahab",
    bulletOne:
      "Back in the good ol' days when throwing children by their pigtails was acceptable.",
    bulletTwo:
      "Hot tip for good parents: demand your kids watch TV instead of reading.",
    cast: "Directed by DANNY DEVITO starring MARA WILSON EMBETH DAVIDTZ PAM FERRISS and RHEA PERLMAN",
  },
  {
    id: 693,
    name: "Jingle All The Way",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmJlYzViNzctMjQ1Ni00ZWQ4LThkN2YtMzI2ZGU5Nzk0NTAyXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Much like the T-800 in pursuit of John Connor, nothing can stop Arnie from getting a Turbo Man doll - not even fake elves, comedians with pirate names, or an army of Santa's.",
    genre: "Comedy",
    subgenre: "",
    runtime: "89 min",
    pairs: "",
    noPairs: "a gingerbread stout.",
    rated: "PG",
    picture1: jingle1,
    picture2: jingle2,
    picture3: jingle3,
    tagline: "PREPARE TO EMPTY YOUR SACK!",
    taglineWriter: "Ted Maltin",
    bulletOne: "The Big Show as huge Santa.",
    bulletTwo:
      "Missing all of the overt sexual references as a child and being shocked as an adult to see all of Hartman's lines were innuendos. ",
    cast: "Directed by BRIAN LEVANT starring ARNOLD SCHWARZENEGGER JAKE LLOYD PHIL HARTMAN and SINBAD",
  },
  {
    id: 694,
    name: "The Blair Witch Project",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzQ1NDBlNDItMDAyYS00YTI2LTgwMmYtMzAwMzg4NDFlM2ZmXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Horror",
    subgenre: "",
    runtime: "81 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "Marketing the tape as real.",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 695,
    name: "Cry-Baby",
    year: 1990,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOGY3MzAyZDQtYTYyMS00ODI1LWEwYTEtMjU5MGFjZTYxZmQ4XkEyXkFqcGdeQXVyMjA0MzYwMDY@._V1_SX300.jpg",
    rating: 3,
    synopsis: "",
    genre: "Drama",
    subgenre: "",
    runtime: "85 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 696,
    name: "Romeo + Juliet",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGU4YmI1ZGQtZjExYi00M2E0LTgyYTAtNzQ5ZmVlMTk4NzUzXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    rating: 3,
    synopsis:
      "This movie proves that even guns, action, and a futuristic backdrop can't make Shakespeare cool.",
    genre: "Drama",
    subgenre: "",
    runtime: "120 min",
    pairs: "a forever sleeping potion.",
    drinkRecipe: "Cannon Blast, Jagermeister, coke on ice.",
    rated: "PG-13",
    picture1: romeoJuliet1,
    picture2: romeoJuliet2,
    picture3: romeoJuliet3,
    tagline: "To see or not to see. No question. Don't.",
    taglineWriter: "Bill Shakespier",
    bulletOne:
      "Using the original script word-for-word in a modern setting. Wow, so artsy and cool.",
    bulletTwo: "Calling your gun a sword. Lame.",
    cast: "Directed by BAZ LUHRMANN starring LEONARDO DiCAPRIO CLAIRE DANES JOHN LEGUIZAMO and HAROLD PERRINEAU",
  },
  {
    id: 697,
    name: "Gremlins",
    year: 1984,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDVjN2FkYTQtNTBlOC00MjM5LTgzMWEtZWRlNGUyYmNiOTFiXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "After buying a mystery creature from a mystical Asian, the worlds worst pet owner fails to follow the three simple rules for its care - and causes all hell to break loose. ",
    genre: "Scifi",
    subgenre: "Horror",
    runtime: "106 min",
    pairs: "",
    noPairs: "scotch and water.",
    rated: "PG",
    picture1: gremlins1,
    picture2: gremlins2,
    picture3: gremlins3,
    tagline: "Kingston Falls sure looks like Hill Valley",
    taglineWriter: "Marty McFly",
    bulletOne: "This counts as a Christmas Movie",
    bulletTwo:
      "Phoebe Cates is Bartender of the Century for finishing her shift. ",
    cast: "Directed by JOE DANTE starring ZACH GALLIGAN PHOEBE CATES JOHN LOUIE and KEYE LUKE",
  },
  {
    id: 698,
    name: "Gremlins 2: The New Batch",
    year: 1990,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGE3MTA3OWQtYzVjYi00MjkwLThlZTQtNWUzOGVlYjEwMzBhXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Billy truly is a bad pet owner. He had Gizmo for about five minutes before shit hit the fan again. Even the most unethical scientists in New York took better care of Mogwai.",
    genre: "Scifi",
    subgenre: "Horror",
    runtime: "106 min",
    pairs: "Red Lipstick.",
    drinkRecipe: "Fireball, fruit punch.",
    rated: "PG-13",
    picture1: gremlins21,
    picture2: gremlins22,
    picture3: gremlins23,
    tagline: "THAT GREEN BABE WAS KISSABLE!",
    taglineWriter: "Thunderlips",
    bulletOne:
      "This wacky sequel is just plain fun. It's like an insane fever dream of the first movie.",
    bulletTwo: "Tuco Salamanca was a postman before becoming a meth dealer.",
    cast: "Directed by JOE DANTE starring ZACH GALLIGAN PHOEBE CATES JOHN GLOVER and ROBERT PROSKY",
  },
  {
    id: 699,
    name: "Kindergarten Cop",
    year: 1990,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjMyMTIyOTc0N15BMl5BanBnXkFtZTgwODY1NTk4NjE@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "111 min",
    pairs: "",
    rated: "PG-13",
    picture1: kindergarten1,
    picture2: kindergarten2,
    picture3: kindergarten3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by IVAN REITMAN starring ARNOLD SCHWARZENEGGER PAMELA REED RICHARD TYSON and PENELOPE ANN MILLER",
  },
  {
    id: 700,
    name: "Tremors",
    year: 1990,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTEzNjkwMzIyMjZeQTJeQWpwZ15BbWU4MDI2NTU5ODYx._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Horror",
    subgenre: "Comedy",
    runtime: "96 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 701,
    name: "The Addams Family",
    year: 1991,
    poster:
      "https://m.media-amazon.com/images/M/MV5BODc1NmY0MDUtNjUzNS00ODdhLWJlN2ItMTgwZjczZjI0MDkyXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "99 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 702,
    name: "Barton Fink",
    year: 1991,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTgxMDMxMTctNDY0Zi00ZmNlLWFlYmQtODA2YjY4MDk4MjU1XkEyXkFqcGdeQXVyNTc1NTQxODI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "116 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 703,
    name: "Bill & Ted's Excellent Adventure",
    year: 1989,
    poster:
      "https://image.tmdb.org/t/p/w185_and_h278_bestv2//qcLGk8CkZW4rHK1kqD9Jdst5vd8.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "90 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 704,
    name: "Bill & Ted's Bogus Journey",
    year: 1991,
    poster:
      "https://image.tmdb.org/t/p/w185_and_h278_bestv2//6UkNsSAxDLpyXwu6MboRP7fXcDI.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "94 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 705,
    name: "Bill & Ted Face the Music",
    year: 2020,
    poster:
      "https://image.tmdb.org/t/p/w185_and_h278_bestv2//4V2nTPfeB59TcqJcUfQ9ziTi7VN.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "93 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 706,
    name: "City Slickers",
    year: 1991,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTkwNjM2MTc5NF5BMl5BanBnXkFtZTcwMzQ2NjEzNA@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "113 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 707,
    name: "Curly Sue",
    year: 1991,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTc1OWM0YWQtN2NjNS00NTlkLWE2YzktMmQwOTMxMGFhNmEzXkEyXkFqcGdeQXVyNjgzMjI4ODE@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "101 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 708,
    name: "Dutch",
    year: 1991,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYmMzY2FhOTgtNjA1OC00ODIyLTgzNWYtMjMwNzUxZDA3NmY1XkEyXkFqcGdeQXVyNjMwMjk0MTQ@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "107 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 709,
    name: "Ernest Scared Stupid",
    year: 1991,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDA4MzIxY2EtYzQxMy00MmJkLWI1ODktNzFjMDM0Yzg4YjhlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "91 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 710,
    name: "Father of the Bride",
    year: 1991,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzM0YzBmMTUtN2RmZS00YWY5LWFkZTMtMjM1OWU3Nzk4ZjI1L2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "105 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 711,
    name: "The Jerk",
    year: 1979,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDNmNThjMTMtNzVlZC00MzgyLWE3M2UtNGQ3ZTZmNjM3YWI2XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "94 min",
    pairs: "",
    rated: "R",
    picture1: jerk1,
    picture2: jerk2,
    picture3: jerk3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by CARL REINER starring STEVE MARTIN BERNADETTE PETERS RICHARD WARD and MABEL KING",
  },
  {
    id: 712,
    name: "",
    year: "",
    poster: "",
    rating: 4,
    synopsis: "",
    genre: "",
    subgenre: "",
    runtime: " min",
    pairs: "",
    drinkRecipe: "",
    rated: "",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 713,
    name: "Fried Green Tomatoes",
    year: 1991,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOWQ1ZWE0MTQtMmEwOS00YjA3LTgyZTAtNjY5ODEyZTJjNDI2XkEyXkFqcGdeQXVyNjE5MjUyOTM@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Drama",
    subgenre: "",
    runtime: "130 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 714,
    name: "Hudson Hawk",
    year: 1991,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzc3OGExYzYtMGE0NS00YmVlLWEzOWQtNTZiMjkxZWMzYzQ1XkEyXkFqcGdeQXVyNTIzOTk5ODM@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "100 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 715,
    name: "The Naked Gun: From the Files of Police Squad!",
    year: 1988,
    poster:
      "https://m.media-amazon.com/images/M/MV5BODk1ZWM4ZjItMjFhZi00MDMxLTgxNmYtODFhNWZlZTkwM2UwXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "Bumbling detective Frank Drebin takes slapstick crime-solving to the next level in this laugh-out-loud spoof of police procedurals.",
    genre: "Comedy",
    subgenre: "",
    runtime: "85 min",
    pairs: "a Screwdriver",
    drinkRecipe: "Vodka, OJ",
    rated: "PG-13",
    picture1: nakedGun1,
    picture2: nakedGun2,
    picture3: nakedGun3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "Endlessly quotable and hilarious lines.",
    bulletTwo:
      "You have a 50/50 chance of loving it, though there's only 10 percent chance of that",
    cast: "Directed by DAVID ZUCKER starring LESLIE NIELSEN PRISCILLA PRESLEY OJ SIMPSON and GEORGE KENNEDY",
  },
  {
    id: 716,
    name: "The Naked Gun 2 1/2: The Smell of Fear",
    year: 1991,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYmU2NGU5MTUtYWRlNy00NzJiLWIxMTMtMDIxNDYxNjRlODg5XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "85 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 717,
    name: "The Naked Gun 33 1/3: The Final Insult",
    year: 1991,
    poster:
      "https://image.tmdb.org/t/p/w185_and_h278_bestv2//hnDzdvCamSKY2yCYGnjD3TkQPrB.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "83 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 718,
    name: "Only the Lonely",
    year: 1991,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTE1YTQ3YTQtNTUxNi00YzAwLWFkY2UtZjBlZDU4ZTI1YzM2XkEyXkFqcGdeQXVyNzc5MjA3OA@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "104 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  // {
  //   id: 719,
  //   name: "Uncle Buck",
  //   year: 1989,
  //   poster:
  //     "https://m.media-amazon.com/images/M/MV5BMjIwOWVkNzMtZTIyZC00ZWM0LTg5MWYtMWYwYjVhZTM3MDMzXkEyXkFqcGdeQXVyNTI4MjkwNjA@._V1_SX300.jpg",
  //   rating: 6,
  //   synopsis: "",
  //   genre: "Comedy",
  //   subgenre: "",
  //   runtime: "100 min",
  //   pairs: "",
  //   rated: "PG",
  //   picture1: uncleBuck1,
  //   picture2: uncleBuck2,
  //   picture3: uncleBuck3,
  //   tagline: "Headline to come!",
  //   taglineWriter: "Some cool writer",
  //   bulletOne: "",
  //   bulletTwo: "",
  //   cast: "Directed by JOHN HUGHES starring JOHN CANDY MACAULAY CULKIN JEAN LOUISA KELLY and GABY HOFFMAN",
  // },
  {
    id: 720,
    name: "The People Under the Stairs",
    year: 1989,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNGQ4OTI4NmUtZDI1Mi00NTA2LWFlY2YtYWVmNjgzYmFmZjIyXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Horror",
    subgenre: "",
    runtime: "102 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 721,
    name: "Problem Child",
    year: 1990,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzk3NGI4NjgtYTJkNS00NzBmLTllYTAtYzE1ZGJlOWQ0MDQxXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "81 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 722,
    name: "Problem Child 2",
    year: 1991,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTQzNjRhMTctZjJmNi00NjFkLTgzMzgtNzUyOTUyODQ1NDA3XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "90 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 723,
    name: "Sgt. Kabukiman N.Y.P.D",
    year: 1991,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYWZjYzkzYTAtYjM3MC00MjY0LWI5NWYtZjgxYzg5N2U3MTg5XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Cult Classic",
    subgenre: "Action",
    runtime: "105 min",
    pairs: "",
    rated: "PG-13",
    picture1: kabukiman1,
    picture2: kabukiman2,
    picture3: kabukiman3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by MICHAEL HERZ & LLOYD KAUFMAN starring RICK GIANASI SUSAN BYUN BILL WEEDEN and NOBLE LEE LESTER",
  },
  {
    id: 724,
    name: "Soapdish",
    year: 1991,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjc1Y2ZlNDktNmQzOC00ZTA0LThlNTAtMjNlODVmMzA1MzQ4XkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Romcom",
    subgenre: "Comedy",
    runtime: "97 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 725,
    name: "3 Ninjas",
    year: 1992,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTU1OGI2OTktZmQ0Yy00ZmQ0LWI3ZTgtNWNlMzFjYWY1YzAwXkEyXkFqcGdeQXVyNzc5MjA3OA@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "84 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 726,
    name: "Beethoven",
    year: 1992,
    poster:
      "https://m.media-amazon.com/images/M/MV5BODgwY2MyMTItMzY1OC00YmU3LTlhOWMtYWFkNGJjNzIwOWZiXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "87 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 727,
    name: "Braindead",
    year: 1992,
    poster:
      "https://image.tmdb.org/t/p/w185_and_h278_bestv2//8ppAsvY7HPoNzNvAmkgjx7FTRpw.jpg",
    rating: 4,
    synopsis:
      "Bonkers movie from Peter Jackson where a Sumatran rat-monkey bites a woman and causes a zombie apocalypse. ",
    genre: "Cult Classic",
    subgenre: "Horror",
    runtime: "104 min",
    pairs: "a Festering Infection.",
    drinkRecipe: "Sprite, grenadine, maraschino cherries, vodka",
    rated: "R",
    picture1: braindead1,
    picture2: braindead2,
    picture3: braindead3,
    tagline: "SHOULD HAVE SEEN THIS COMING",
    taglineWriter: "Sauron",
    bulletOne: "Hilariously cheesy special effects.",
    bulletTwo: "Lawnmowers are criminally underrated weapons.",
    cast: "Directed by PETER JACKSON starring TIMOTHY BALME DIANA PENALVER ELIZABETH MOODY and STUART DEVENIE",
  },
  {
    id: 728,
    name: "Buffy the Vampire Slayer",
    year: 1992,
    poster:
      "https://image.tmdb.org/t/p/w185_and_h278_bestv2//so4tTMItoq90wphs18lWbV0ASVV.jpg",
    rating: 4,
    synopsis: "",
    genre: "Horror",
    subgenre: "",
    runtime: "86 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 729,
    name: "Overboard",
    year: 1987,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjIzMjMxOTgzM15BMl5BanBnXkFtZTcwMTAwODM2NA@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Romcom",
    subgenre: "Comedy",
    runtime: "112 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 730,
    name: "Captain Ron",
    year: 1992,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOWZkYTk4YjAtZmViZS00ZTY5LWE2YmItOGQ3Y2Y0N2MzODkyXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A salty old seadog helps a bunch of yuppies sail a boat back to the States. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "90 min",
    pairs: "a Rum Runner.",
    drinkRecipe:
      "Rum, banana liquer, blackberry liquer, pineapple juice, lime juice, grenadine.",
    rated: "PG-13",
    picture1: captainRon1,
    picture2: captainRon2,
    picture3: captainRon3,
    tagline: "SNAKE PLISSKEN GOES OVERBOARD",
    taglineWriter: "Wyatt Earp",
    bulletOne: "Ruining your buddies car.",
    bulletTwo:
      "Russell gets in Big Trouble and has to Escape after banging whores and stiffing a pimp.",
    cast: "Directed by THOM EBERHARDT starring KURT RUSSELL MARTIN SHORT MARY KAY PLACE and BENJAMIN SALISBURY",
  },
  {
    id: 731,
    name: "Coneheads",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTNjNGM5OTktM2Q1Zi00YTFmLWJlYzUtMTMyMzgzNjNlNmQzL2ltYWdlXkEyXkFqcGdeQXVyNjE5MjUyOTM@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "88 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 732,
    name: "Death Becomes Her",
    year: 1992,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzM2YjIwMzgtZTg4Yy00NmJlLWIzOTYtYTQ0Zjc3NjIyZDcyXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "104 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 733,
    name: "Home Alone 2: Lost in New York",
    year: 1992,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDI1MzM0Y2YtYmIyMS00ODE3LTlhZjEtZTUyNmEzMTNhZWU5XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Two extremely determined criminals are hell-bent on revenge-murdering a child.",
    genre: "Comedy",
    subgenre: "",
    runtime: "120 min",
    pairs: "a Brooklyn Bandit.",
    drinkRecipe: "Bourbon, coke zero, lime",
    rated: "PG",
    picture1: homeAlone21,
    picture2: homeAlone22,
    picture3: homeAlone23,
    tagline: "WHAT ABOUT ME?!",
    taglineWriter: "Kierkegarrd Colkyn",
    bulletOne: "Pre-Presidential cameo.",
    bulletTwo:
      "After realizing Kevin is a psycho, his parents are clearly trying to lose the fucker.",
    cast: "Directed by CHRIS COLUMBUS starring MACAULAY CULKIN JOE PESCI DANIEL STERN and CATHERINE O' HARA",
  },
  {
    id: 734,
    name: "Honey, I Shrunk the Kids",
    year: 1989,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTQ1NTg4MDAtOGU0OS00ZGQwLTliZjQtNDEzZjAzZGI5MjFjXkEyXkFqcGdeQXVyNTI4MjkwNjA@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "93 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 735,
    name: "Honey, I Blew Up the Kid",
    year: 1992,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOGU3N2MzOGYtODhmMy00Y2IzLWI3YmEtYzNiMTU1ZjY0ZDNkXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "89 min",
    pairs: "",
    rated: "PG",
    picture1: blewUp1,
    picture2: blewUp2,
    picture3: blewUp3,
    tagline: "Babe, YOU shouldn't let me experiment!",
    taglineWriter: "Rich Lessanus",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by RANDALL KLEISER starring RICK MORANIS MARCIA STRASSMAN ROBERT OLIVERI and LLOYD BRIDGES",
  },
  {
    id: 736,
    name: "Honeymoon in Vegas",
    year: 1992,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzA3ODllYTgtMWM4NC00MmNiLThhYTctNzdkNTQzZTA4YmM1XkEyXkFqcGdeQXVyNjMwMjk0MTQ@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Romcom",
    subgenre: "Comedy",
    runtime: "96 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 737,
    name: "Housesitter",
    year: 1992,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWM1NjA4ZjktZTAwNy00NzBiLWEzZjYtZWI1YmJiMDUwYmI5XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Romcom",
    subgenre: "Comedy",
    runtime: "102 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 738,
    name: "Ladybugs",
    year: 1992,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjMwZWYyYWItYTY3ZS00NmJiLTg4ZGQtYjFkN2ZmMTc4MDYwXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Sports",
    subgenre: "Comedy",
    runtime: "90 min",
    pairs: "",
    rated: "PG-13",
    picture1: ladybugs1,
    picture2: ladybugs2,
    picture3: ladybugs3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by SIDNEY J. FURIE starring RODNEY DANGERFIELD JACKEE KERRY JONATHAN BRANDIS and ILENE GRAFF",
  },
  {
    id: 739,
    name: "Command Performance",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZmIxZTQwNDktMDg5My00ZjcyLTg1NDMtNjRkNTNiNjAzZDRkXkEyXkFqcGdeQXVyMDI4NjM1MQ@@._V1_SX300.jpg",
    rating: 3,
    year: 2009,
    synopsis: "Dolph Lundgren is a SMOKING HOT drummer.",
    genre: "Action",
    subgenre: "",
    runtime: "93 min",
    pairs: "a Moscow Mule.",
    drinkRecipe: "Vodka, ginger beer, lime juice.",
    rated: "R",
    picture1: commandPerformance1,
    picture2: commandPerformance2,
    picture3: commandPerformance3,
    tagline: "ROCK N' LOAD!",
    taglineWriter: "Headbangers Weekly",
    bulletOne: "Dolph NEVER ONCE wearing sleeves.",
    bulletTwo: "An accent that sounds like Sean Connery from Amshterdam.",
    cast: "Directed by DOLPH LUNDGREN starring DOLPH LUNDGREN  MELISSA MOLINARO  HRISTO SHOPOV and DAVE LEGENO",
  },
  {
    id: 740,
    name: "Creepshow",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTU3NGIyZTctOWEyMS00MGIyLWFkZWMtMTg0ODE2MjExNGZlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1982,
    synopsis:
      "Some people fall into graves and laugh, others transform into grass, and the rest murder their wives or their lovers in this horror anthology.",
    genre: "Horror",
    subgenre: "Cult Classic",
    runtime: "120 min",
    pairs: "Sex on the Beach.",
    drinkRecipe: "Vodka, Peach Schnapps, Orange Juice, Cranberry Juice",
    rated: "R",
    picture1: creepshow1,
    picture2: creepshow2,
    picture3: creepshow3,
    tagline: "The only creep at this show is YOU!",
    taglineWriter: "Howard Udowin",
    bulletOne: "Getting revenge on your slutty significant other.",
    bulletTwo:
      "Stephen King and George A. Romero teaming up to make a movie together.",
    cast: "Directed by GEORGE A. ROMERO  starring ED HARRIS  STEPHEN KING  HAL HOLBROOK and LESLIE NIELSEN",
  },
  {
    id: 741,
    name: "D3: The Mighty Ducks",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNWMyZmNkNTMtYTZlMi00MmQ5LWE4NWQtMzVjZWZhNmU1Yjc1L2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Sports",
    subgenre: "",
    runtime: "104 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 742,
    name: "Mr. Baseball",
    year: 1992,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTY0NDc2NzUtMmRkZi00NzIyLTg5YTctNmIyZjc0NzBkZmYzXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Sports",
    subgenre: "",
    runtime: "108 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 743,
    name: "My Cousin Vinny",
    year: 1992,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQxNDYzMTg1M15BMl5BanBnXkFtZTgwNzk4MDgxMTE@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "120 min",
    pairs: "a Wakeup Call.",
    drinkRecipe: "Vodka, coconut rum, coconut redbull.",
    rated: "R",
    picture1: cousinVinny1,
    picture2: cousinVinny2,
    picture3: cousinVinny3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by JONATHAN LYNN starring JOE PESCI MARISA TOMEI RALPH MACCHIO and MITCHELL WHITFIELD",
  },
  {
    id: 744,
    name: "Spies Like Us",
    year: 1985,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYWE0NmZiNDktODFjMi00NzA4LWI2ZTEtN2M2OWU5ZDQ5YzBhXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Two guys who are definitely doctors go behind enemy lines during a literal cold war.",
    genre: "Action",
    subgenre: "Comedy",
    runtime: "101 min",
    pairs: "",
    noPairs: "Budweiser.",
    rated: "PG",
    picture1: spiesLikeUs1,
    picture2: spiesLikeUs2,
    picture3: spiesLikeUs3,
    tagline: "I'VE SEEN LONGER ELEVATORS!",
    taglineWriter: "Shaft",
    bulletOne: "Snow Bunnies",
    bulletTwo: "American music trivia!",
    cast: "Directed by JOHN LANDIS starring CHEVY CHASE DAN AYKROYD TOM HATTEN and RONALD REAGAN",
  },
  {
    id: 745,
    name: "Revenge of the Nerds",
    year: 1984,
    poster:
      "https://m.media-amazon.com/images/M/MV5BODU1NzM4NTA4Nl5BMl5BanBnXkFtZTgwMTkxMzcxMTE@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "90 min",
    pairs: "",
    rated: "R",
    picture1: revengeNerds1,
    picture2: revengeNerds2,
    picture3: revengeNerds3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by JEFF KANEW starring ROBERT CARRADINE ANTHONY EDWARDS CURTIS ARMSTRONG and DONALD GIBB",
  },
  {
    id: 746,
    name: "Revenge of the Nerds II: Nerds in Paradise",
    year: 1987,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYThmOGViMjQtYjYwMS00ZmI5LWEwNjMtNmQ5YzhmYWY5NTAyXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "88 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 747,
    name: "Revenge of the Nerds III: The Next Generation",
    year: 1987,
    poster:
      "https://m.media-amazon.com/images/M/MV5BODEyZTE3NTctYzJmMi00Y2UyLTlmNWEtMzAxYzFmMDk2ZjQyXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "93 min",
    pairs: "",
    rated: "Not Rated",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 748,
    name: "Sister Act",
    year: 1992,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTFmOTYxOWMtMDE2MC00ODhmLTlhM2QtZjZkZmVhZGYyNGFlXkEyXkFqcGdeQXVyMjA0MzYwMDY@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "100 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 749,
    name: "Stop! Or My Mom Will Shoot",
    year: 1992,
    poster: "https://m.media-amazon.com/images/I/518ijvUemhL.jpg",
    rating: 4,
    synopsis:
      "Stallone's real-life mom should have shot him for taking this role. In all honesty this movie gets a lot of shit but it's a fun action-buddy-cop comedy. ",
    genre: "Action",
    subgenre: "Comedy",
    runtime: "87 min",
    pairs: "",
    noPairs: "shots of your favorite booze.",
    rated: "PG-13",
    picture1: stopMomShoot1,
    picture2: stopMomShoot2,
    picture3: stopMomShoot3,
    tagline: "IM GONNA SHOOT TOO!",
    taglineWriter: "MacGruber",
    bulletOne: "Fingers crossed we'll get 'Stop! Or My Grandma Will Shoot'.",
    bulletTwo: "Arnie tricking Sly into doing this movie.",
    cast: "Directed by ROGER SPOTTISWOODE starring SYLVESTER STALLONE ESTELLE GETTY MARTIN FERRERO and JOHN WESLEY",
  },
  {
    id: 750,
    name: "Addams Family Values",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZWFhNjY0YjItNjM5NC00NzAwLWI3ZWUtMTlkNTA0ZWVkNjBkXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "94 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 751,
    name: "Airborne",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZmM4YzU2OWUtM2VkYy00Y2I5LTk3NzgtNmI3M2MyODM5YzY1XkEyXkFqcGdeQXVyMjA0MDQ0Mjc@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Sports",
    subgenre: "Comedy",
    runtime: "91 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 752,
    name: "Cool Runnings",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjMxMTQ3MzMwMV5BMl5BanBnXkFtZTgwNTYxNzYxMTE@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Sports",
    subgenre: "Comedy",
    runtime: "98 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 753,
    name: "Demolition Man",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDBmNDhjOTYtZWVlMC00YzUwLWIyZjEtYzFjMWM5OTdiZDJkXkEyXkFqcGdeQXVyNjUwNzk3NDc@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "115 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 754,
    name: "Dennis the Menace",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOWY1NGQ3YjktMmU0MS00Y2YyLThlOGEtNTNjNDJkNjVjZTM4XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "94 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 755,
    name: "Grumpy Old Men",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzNiYzQyNGEtYjFiOS00OTcyLTg5YzItMDQ2ZGRmZjE1N2Y4XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "103 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 756,
    name: "Grumpier Old Men",
    year: 1995,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDkwYTZmMDQtOWNhOC00Y2I5LWI0NjQtOTljODBmZjU0NWU2XkEyXkFqcGdeQXVyMTcwOTQzOTYy._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "The ultimate frenemies reluctantly team up to get their bait shop back from an old seductress trying to turn it into a fancy Italian restaurant. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "101 min",
    pairs: "",
    noPairs: "wine in a box, so you know it's good.",
    rated: "PG-13",
    picture1: grumpierOldMen1,
    picture2: grumpierOldMen2,
    picture3: grumpierOldMen3,
    tagline: "I'd take this skinboat to Tuna Town!",
    taglineWriter: "Mick",
    bulletOne: "Tip for living a long life: eat bacon.",
    bulletTwo:
      "Actually catching the fish you've lied your whole life about catching.",
    cast: "Directed by HOWARD DEUTCH starring WALTHER MATTHAU JACK LEMMON BURGESS MEREDITH and DARYL HANNAH",
  },
  {
    id: 757,
    name: "Hocus Pocus",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWUxNWI0YTYtY2RiZS00ZjNmLTg4ZGUtNDI0Mzk4NmQ5NWE5XkEyXkFqcGdeQXVyNjk1Njg5NTA@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "A virgin disrespects his elders and battles three youthful witches.",
    genre: "Comedy",
    subgenre: "",
    runtime: "96 min",
    pairs: "Witches Brew.",
    drinkRecipe: "Apple Cider, Whiskey, cinnamon",
    rated: "PG",
    picture1: hocusPocus1,
    picture2: hocusPocus2,
    picture3: hocusPocus3,
    tagline: "BROOM GOES THE DYNAMITE!",
    taglineWriter: "Hermione Granger",
    bulletOne: "Salem's cousin Thackery Binx",
    bulletTwo: "Keeping your fingers clear of the storm drain",
    cast: "Directed by KENNY ORTEGA starring BETTE MIDLER SARAH JESSICA PARKER KATHY NAJIMY and OMRI KATZ",
  },
  {
    id: 758,
    name: "Mad Dog and Glory",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYWViMGIzMzktNDhjYS00ZjVhLWFjOWQtMjVlMmRhZjI5ZDlkXkEyXkFqcGdeQXVyNjUwNzk3NDc@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Crime",
    runtime: "97 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 759,
    name: "Mrs. Doubtfire",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjExMDUzODE1N15BMl5BanBnXkFtZTgwNTU5NTYxMTE@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "125 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 760,
    name: "Robin Hood: Men in Tights",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGYyNmU2NmEtNGU1ZS00YjFkLWI0MWQtZjU2MmUxZDAyN2UxXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "104 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 761,
    name: "Rookie of the Year",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGJhODAwNDQtNGY0My00ZDA4LTgwOTMtMTA5NzdhZTQ0YmIwXkEyXkFqcGdeQXVyNTI4MjkwNjA@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Sports",
    subgenre: "",
    runtime: "103 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 762,
    name: "The Sandlot",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BODllYjM1ODItYjBmOC00MzkwLWJmM2YtMjMyZDU3MGJhNjc4L2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "A cautionary coming-of-age tale that deters children from mixing carnival rides and chewing tobacco.",
    genre: "Sports",
    subgenre: "Comedy",
    runtime: "101 min",
    pairs: "a rootbeer float.",
    drinkRecipe: "bourbon, root beer, vanilla bean ice cream.",
    rated: "PG",
    picture1: sandlot1,
    picture2: sandlot2,
    picture3: sandlot3,
    tagline: "What is this? An ad for Converse?",
    taglineWriter: "Fil Night",
    bulletOne: "Playing ball like a girl",
    bulletTwo: "Tips and tricks for nailing your crush",
    cast: "Directed by DAVID MICKEY EVANS starring TOM GUIRY MIKE VITAR PATRICK RENNA and CHAUNCEY LEOPARDI",
  },
  {
    id: 763,
    name: "Stand By Me",
    year: 1986,
    poster:
      "https://m.media-amazon.com/images/M/MV5BODJmY2Y2OGQtMDg2My00N2Q3LWJmZTUtYTc2ODBjZDVlNDlhXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "Four innocent young friends set out on a wholesome journey to to gaze upon a corpse.",
    genre: "Drama",
    subgenre: "Adventure",
    runtime: "89 min",
    pairs: "a Blueberry Pie.",
    drinkRecipe: "Vodka, Blueberry Syrup, seltzer.",
    rated: "R",
    picture1: standByMe1,
    picture2: standByMe2,
    picture3: standByMe3,
    tagline: "THIS MOVIE IS THE GOOD BROTHER",
    taglineWriter: "Alec Baldwin",
    bulletOne: "Missing your train by that much.",
    bulletTwo:
      "Listen carefully for what happens to Chris, it's just barely a casual mention.",
    cast: "Directed by ROB REINER starring WIL WHEATON RIVER PHOENIX COREY FELDMAN and JERRY O'CONNELL",
  },
  {
    id: 764,
    name: "Sister Act 2: Back in the Habit",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmM0YjY5YjctYzljMy00ODcxLWI3ZDItMmE1MGU5YTI5MzI3XkEyXkFqcGdeQXVyMjA0MzYwMDY@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "107 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 765,
    name: "Six Degrees of Separation",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQ5ODk1NTI4NV5BMl5BanBnXkFtZTcwNDYzMjI3NA@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Drama",
    subgenre: "",
    runtime: "112 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 766,
    name: "Sleepless in Seattle",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNWY1MDJkZGUtZTE2OS00ODZiLTlmNzQtMDZjNzM2ZjkwM2QxXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Romcom",
    subgenre: "",
    runtime: "105 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 767,
    name: "So I Married An Axe Murderer",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BODIxNzJhYjUtZWYwOC00YjI2LWI1ZDgtMDVjMjY3ODUyOGZjXkEyXkFqcGdeQXVyNjE5MjUyOTM@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Romcom",
    runtime: "93 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 768,
    name: "Surf Ninjas",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTA2NjUyOTYtODEyMy00OTE1LWFhOWYtZDhhNWU1MWYwMjA3XkEyXkFqcGdeQXVyNzc5MjA3OA@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Cult Classic",
    runtime: "87 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 769,
    name: "Weekend At Bernie's",
    year: 1989,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWRhM2Q4MDgtYzAzNi00YTdkLWFkN2MtMzAyMzUxNDVjMWRjXkEyXkFqcGdeQXVyMjUzOTY1NTc@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "A pair of friends do what anyone would do upon finding their boss's corpse -- drag it around and continue to party.",
    genre: "Comedy",
    subgenre: "",
    runtime: "97 min",
    pairs: "",
    noPairs: "a stiff drink.",
    rated: "PG-13",
    picture1: bernies1,
    picture2: bernies2,
    picture3: bernies3,
    tagline: "We're testing a 'Morbid Muppets' idea",
    taglineWriter: "Jim Henson",
    bulletOne:
      "Not letting something minor like being dead prevent you from getting laid.",
    bulletTwo:
      "Bonus points if you got this DVD for free with an order from Papa John's. ",
    cast: "Directed by TED KOTCHEFF starring JONATHAN SILVERMAN ANDREW MCCARTHY CATHERINE MARY STEWART and TERRY KISER",
  },
  {
    id: 770,
    name: "Weekend At Bernie's 2",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNWIxNWEwYjUtZjA4ZS00ZDFlLTg1M2QtMDUxMDNmM2JmMWQ1XkEyXkFqcGdeQXVyMjUzOTY1NTc@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "97 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 771,
    name: "3 Ninja's Kick Back",
    year: 1994,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzFiNWI1MDQtMjg3Mi00YjIxLWI2NDktNjI0MmNiNjRjMDI5XkEyXkFqcGdeQXVyNjMwMjk0MTQ@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "93 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 772,
    name: "Airheads",
    year: 1994,
    poster:
      "https://m.media-amazon.com/images/M/MV5BY2M3OWY0OTYtZWE5Yy00NDRkLTllYmUtOWVjOTMyZjJjNjhkXkEyXkFqcGdeQXVyMjUzOTY1NTc@._V1_SX300.jpg",
    rating: 3,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "92 min",
    pairs: "",
    rated: "PG-13",
    picture1: airheads1,
    picture2: airheads2,
    picture3: airheads3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by MICHAEL LEHMANN starring BENHAM FRAISIN STEVE BUSCEMI ADAM SANDLER and CHRIS FARLEY",
  },
  {
    id: 773,
    name: "Angels in the Outfield",
    year: 1994,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDE1MWU1ZDQtOTg3YS00MTEwLTkxNjItMDQzY2UxMThjYmZkXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Sports",
    subgenre: "",
    runtime: "102 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 774,
    name: "Beverly Hills Cop",
    year: 1984,
    poster:
      "https://m.media-amazon.com/images/M/MV5BN2MyZDE0YjAtNWFjYy00MWRlLTk3MTktMzY3ZDVhNTJkZTlmXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "Comedy",
    runtime: "105 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 775,
    name: "Beverly Hills Cop 2",
    year: 1987,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNThlYWFhYmYtMzQzZS00ZmZjLWJjZjAtYWVhMmM4YTc1ZjhjXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "Comedy",
    runtime: "100 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 776,
    name: "Beverly Hills Cop 3",
    year: 1994,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTdhMTU4MzMtYTVjMi00MTYzLTkxZDYtOWUwYjI0NDFjZTY1XkEyXkFqcGdeQXVyMDEwMjgxNg@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "Comedy",
    runtime: "104 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 777,
    name: "City Slickers 2: The Legend of Curly's Gold",
    year: 1994,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjQ3NzM2NjctN2JjMy00M2NmLWJmMGItY2UxYjAzYWFhYmI1XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "116 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 778,
    name: "Clifford",
    year: 1994,
    poster:
      "https://m.media-amazon.com/images/M/MV5BODAwYzRlODEtMmE4Yy00NDQ2LWFlYTAtODk3OTIzZDJjNjU2XkEyXkFqcGdeQXVyNzc5MjA3OA@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "90 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 779,
    name: "Drunken Master",
    year: 1978,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzdlOWRlZGUtOGViNS00ODVkLWFiNzEtN2JmZDViYzUxMDI1XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "111 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 780,
    name: "Drunken Master II",
    year: 1994,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjhhZDI4ZjItZjJkOS00NTJiLWJlNjMtOGI3Y2E3YzQ3MjNhXkEyXkFqcGdeQXVyMjUyNDk2ODc@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "102 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 781,
    name: "The Flintstones",
    year: 1994,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzY0ZTk4YzctNzYzNy00ZDQ0LTg2Y2ItZTQyYzQxMjQwMDc2XkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "91 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 782,
    name: "Four Weddings and a Funeral",
    year: 1994,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTMyNzg2NzgxNV5BMl5BanBnXkFtZTcwMTcxNzczNA@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Romcom",
    subgenre: "",
    runtime: "117 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 783,
    name: "In The Army Now",
    year: 1994,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOWU5MDMzODQtMmM0ZS00MjU5LWExMzMtZjBlOTczY2NjNTMzXkEyXkFqcGdeQXVyMjY3MjUzNDk@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "91 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 784,
    name: "It's Pat",
    year: 1994,
    poster:
      "https://m.media-amazon.com/images/M/MV5BM2VhMjI0NDQtZWRjMS00ZWY4LTk2MmItZTY4MzE4M2ZmOTQ3L2ltYWdlXkEyXkFqcGdeQXVyNjE5MjUyOTM@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "77 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 785,
    name: "Junior",
    year: 1994,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGJjZGUzZWItMmY4Yi00NWQ2LThmZmMtMzQxOTExZjA5ZmZlXkEyXkFqcGdeQXVyMjMwNDgzNjc@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "109 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 786,
    name: "Twins",
    year: 1988,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWUzN2VkY2ItYmQ4YS00MjFmLWJhZDQtYWY1NWQ2NTA5NDNlXkEyXkFqcGdeQXVyNDc2NjEyMw@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "107 min",
    pairs: "",
    rated: "PG-13",
    picture1: twins1,
    picture2: twins2,
    picture3: twins3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by IVAN REITMAN starring ARNOLD SCHWARZENEGGER DANNY DEVITO KELLY PRESTON and CHLOE WEBB",
  },
  {
    id: 787,
    name: "Little Giants",
    year: 1994,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTBlNGFmNTktZGJlZi00ZWNkLWI0MGItNjhhNjQyNWM1MmVlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Sports",
    subgenre: "Comedy",
    runtime: "107 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 788,
    name: "The Little Rascals",
    year: 1994,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYWE1MzdmNmMtNWQwZS00MTVkLTk3MDMtYzVkOWZlOTczMDIwXkEyXkFqcGdeQXVyNjg4NzYzMzA@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Tiny scoundrels create a misogynist club and learn that their idol isn't at all who they seem.",
    genre: "Comedy",
    subgenre: "Family",
    runtime: "82 min",
    pairs: "Pickle Juice.",
    rated: "PG",
    picture1: rascals1,
    picture2: rascals2,
    picture3: rascals3,
    tagline: "STREET RACING SAFE FOR ALL AGES!",
    taglineWriter: "Van Gasoline",
    bulletOne: "Fun fix-em-up montage(s).",
    bulletTwo: "Not being able to read but having the memory of an elephant.",
    cast: "Directed by PENELOPE SPHEERIS starring BUG HALL  TRAVIS TEDFORD  BRITTANY ASHTON HOLMES and DARYL HANNAH",
  },
  {
    id: 789,
    name: "Maverick",
    year: 1994,
    poster:
      "https://m.media-amazon.com/images/M/MV5BY2I1OTY2NmUtMGVlZi00NjNmLThkNTgtMjExMzRhOTM2MDJiXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 5,
    synopsis: "Mel cons his way through the wild west.",
    genre: "Western",
    subgenre: "Action",
    runtime: "127 min",
    pairs: "",
    noPairs: "whiskey - neat.",
    rated: "PG",
    picture1: maverick1,
    picture2: maverick2,
    picture3: maverick3,
    tagline: "The poker scenes weren't realistic",
    taglineWriter: "Jonnie Chan",
    bulletOne: "Being a Maverick doesn't make you a loner",
    bulletTwo:
      "Since their escape, Jameses Garner and Coburn reunite for the third time",
    cast: "Directed by RICHARD DONNER starring MEL GIBSON JAMES GARNER JODIE FOSTER and JAMES COBURN",
  },
  {
    id: 790,
    name: "National Lampoon's Last Resort",
    year: 1994,
    poster:
      "https://image.tmdb.org/t/p/w185_and_h278_bestv2//h3ycFo3qKhF9SDERN1PQq4bJeIv.jpg",
    rating: 3,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "91 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 791,
    name: "The Ref",
    year: 1994,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDA2Yzg3ZGUtMmJlOC00NzY2LWIxMDYtODcwOTM0ZWZhNWQ0XkEyXkFqcGdeQXVyNjExODE1MDc@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "96 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 792,
    name: "Revenge of the Nerds IV: Nerds in Love",
    year: 1994,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTcxODFjN2YtMDllZC00ZDg2LTk0MWQtOTMxMTc5NWY5MmRmXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "92 min",
    pairs: "",
    rated: "Not Rated",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 793,
    name: "Richie Rich",
    year: 1994,
    poster:
      "https://image.tmdb.org/t/p/w185_and_h278_bestv2//qgGh5d0IHAZRlHIdFS3XWVygumR.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "96 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 794,
    name: "The Santa Clause",
    year: 1994,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTZlNzk1MjItYjJlYy00MTAxLWJkNjEtZmNiNmVlNjQ4NDE5XkEyXkFqcGdeQXVyMzI0NDc4ODY@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "97 min",
    pairs: "",
    rated: "PG",
    picture1: santaClause1,
    picture2: santaClause2,
    picture3: santaClause3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by JOHN PASQUIN starring TIM ALLEN JUDGE REINHOLD ERIC LLOYD and DAVID KRUMHOLTZ",
  },
  {
    id: 795,
    name: "True Lies",
    year: 1994,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzg5YmUyNGMtMThiNS00MjA2LTgwZDctNDlhM2RkZDNmZmRkXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "141 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 796,
    name: "3 Ninja's Knuckle Up",
    year: 1995,
    poster:
      "https://m.media-amazon.com/images/M/MV5BM2ZhNGEwNGYtZDIwOC00MjcwLTgyNGEtMjlhZDFjY2JmNWIyXkEyXkFqcGdeQXVyNjMwMjk0MTQ@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "88 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 797,
    name: "Bad Boys",
    year: 1995,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGE1ZTQ0ZTEtZTEwZS00NWE0LTlmMDUtMTE1ZWJiZTYzZTQ2XkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Action",
    subgenre: "Comedy",
    runtime: "119 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 798,
    name: "Bad Boys 2",
    year: 2003,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZmU4NzVkZjEtZmQxMi00ZDY5LWI3ZDYtMWRmZjE5YmYwZjQzXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "Comedy",
    runtime: "147 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 799,
    name: "Bad Boys for Life",
    year: 2020,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWU0MGYwZWQtMzcwYS00NWVhLTlkZTAtYWVjOTYwZTBhZTBiXkEyXkFqcGdeQXVyMTkxNjUyNQ@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "Comedy",
    runtime: "124 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 800,
    name: "Clueless",
    year: 1995,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzBmOGQ0NWItOTZjZC00ZDAxLTgyOTEtODJiYWQ2YWNiYWVjXkEyXkFqcGdeQXVyNTE1NjY5Mg@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Romcom",
    subgenre: "Comedy",
    runtime: "97 min",
    pairs: "",
    rated: "PG-13",
    picture1: clueless1,
    picture2: clueless2,
    picture3: clueless3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by AMY HECKERLING starring ALICIA SILVERSTONE BRITTANY MURPHY PAUL RUDD and STACEY DASH",
  },
  {
    id: 801,
    name: "Midnight Special",
    year: 2016,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTUwNDcyNzkyOV5BMl5BanBnXkFtZTgwNzI1NzkyNzE@._V1_SX300.jpg",
    rating: 3,
    synopsis: "",
    genre: "Scifi",
    subgenre: "Thriller",
    runtime: "112 min",
    pairs: "",
    rated: "PG-13",
    picture1: midnightSpecial1,
    picture2: midnightSpecial2,
    picture3: midnightSpecial3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "Aliens, cults, and Kylo Ren.",
    bulletTwo: "",
    cast: "Directed by JEFF NICHOLS starring MICHAEL SHANNON JOEL EDGERTON KIRSTEN DUNST and ADAM DRIVER",
  },
  {
    id: 802,
    name: "Kill The Irishman",
    year: 2011,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQyNzIxMzMwMV5BMl5BanBnXkFtZTcwOTU4OTcxNA@@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A street tough turned union leader in 1970’s Cleveland is shot, beaten, and bombed, but nothing will stop Danny Greene and his Irish pride.",
    genre: "Crime",
    subgenre: "Drama",
    runtime: "106 min",
    pairs: "a Great Lakes IPA.",
    rated: "R",
    picture1: killIrishman1,
    picture2: killIrishman2,
    picture3: killIrishman3,
    tagline: "INSPIRING TITLE!",
    taglineWriter: "Bill 'The Butcher' Cutting",
    bulletOne: "Ray Stevenson, the poor man’s Liev Schreiber.",
    bulletTwo: "Gangland: The Shores of Erie.",
    cast: "Directed by JONATHAN HENSLEIGH starring RAY STEVENSON CHRISTOPHER WALKEN VINCENT D'ONOFRIO and VAL KILMER",
  },
  {
    id: 803,
    name: "Dracula: Dead and Loving It",
    year: 1995,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZWQ0ZDFmYzMtZGMyMi00NmYxLWE0MGYtYzM2ZGNhMTE1NTczL2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMjM5ODMxODc@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "88 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 804,
    name: "Father of the Bride Part II",
    year: 1995,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTEyNzg5NjYtNDU4OS00MWYxLWJhMTItYWU4NTkyNDBmM2Y0XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "106 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 805,
    name: "Four Rooms",
    year: 1995,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDc3Y2YwMjUtYzlkMi00MTljLTg1ZGMtYzUwODljZTI1OTZjXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "98 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 806,
    name: "Get Shorty",
    year: 1995,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjAwODYzNDY4Ml5BMl5BanBnXkFtZTcwODkwNTgzNA@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "105 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 807,
    name: "Heavyweights",
    year: 1995,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmUzMmVjZjMtMThkMS00NDRkLWJhYmQtMjcwMmJkY2ExYmQ5XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "100 min",
    pairs: "",
    rated: "PG",
    picture1: heavyweights1,
    picture2: heavyweights2,
    picture3: heavyweights3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by STEVEN BRILL starring BENN STILLER SHAUN WEISS TOM HODGES and TIM BLAKE NELSON",
  },
  {
    id: 808,
    name: "Home for the Holidays",
    year: 1995,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjVlYTQ4NjgtZWQxMS00ZmQ0LTg4M2QtOGE0ZmJiNDhkMzI4XkEyXkFqcGdeQXVyNTIzOTk5ODM@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "103 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 809,
    name: "Houseguest",
    year: 1995,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNGVkZWMwZWItOWQzZS00YTU5LWJjMGUtZTM5MjdmZmQ2YTU3XkEyXkFqcGdeQXVyNzc5MjA3OA@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "113 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 810,
    name: "Jury Duty",
    year: 1995,
    poster:
      "https://m.media-amazon.com/images/M/MV5BN2RlNGM4NjEtNjUxZC00YjA2LThjYjQtMDdkNDA1YzNlNzJjXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "88 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 811,
    name: "A Kid in King Arthur's Court",
    year: 1995,
    poster:
      "https://m.media-amazon.com/images/M/MV5BODc0ZGUyNDYtNTE3MS00YzVhLWFmY2QtZGVlNjFmMjkyNjhlXkEyXkFqcGdeQXVyMjgyOTI1ODY@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A pine-riding-pip-squeak goes back in time, to a different reality, and uses his futuristic gadgets in order to swindle his way into King Arthur's court and prevent a noble Lord from his rightful inheritance.",
    genre: "Comedy",
    subgenre: "Fantasy",
    runtime: "89 min",
    pairs: "a Wooden Bike.",
    drinkRecipe: "bourbon, root beer, vanilla bean ice cream, cherry.",
    rated: "PG",
    picture1: kingArthur1,
    picture2: kingArthur2,
    picture3: kingArthur3,
    tagline: "YOU SHALL NOT PASS-- class if you don't show up.",
    taglineWriter: "Prof. Dan Golf",
    bulletOne: "Early career star studded cast.",
    bulletTwo: "Roller blading before 𝘽𝙧𝙞𝙣𝙠! made it cool.",
    cast: "Directed by MICHAEL GOTTLIEB starring THOMAS IAN NICHOLAS JOSS ACKLAND PALOMA BAEZA and KATE WINSLETT",
  },
  {
    id: 812,
    name: "Dunston Checks In",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BN2RhOTA4YzAtNTg3NC00NmYxLWI1YjQtYzM4NTJlYzVhZGYwXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Family",
    runtime: "89 min",
    pairs: "a Secret Inspector.",
    drinkRecipe: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "YOU'RE BALD!",
    taglineWriter: "Elaine Benes",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by KEN KWAPIS starring JASON ALEXANDER FAYE DUNAWAY RUBERT EVERETT and SAM",
  },
  {
    id: 813,
    name: "Mallrats",
    year: 1995,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGNhYzE4YzktNDIwNC00YmRiLTgyOTgtMzk1ZjNhNmI4ODI1XkEyXkFqcGdeQXVyMTk3NDAwMzI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "94 min",
    pairs: "",
    rated: "R",
    picture1: mallrats1,
    picture2: mallrats2,
    picture3: mallrats3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by KEVIN SMITH starring JEREMY LONDON JASON LEE SHANNEN DOUGHERTY and MICHAEL ROOKER",
  },
  {
    id: 814,
    name: "Man of the House",
    year: 1995,
    poster:
      "https://image.tmdb.org/t/p/w185_and_h278_bestv2//6bnOhhrrrq6RZhMgz4aGyqbJ9Wf.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "97 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 815,
    name: "Operation Dumbo Drop",
    year: 1995,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjlhY2JiNTEtYzcyZi00YmVjLThiZGEtODg2Nzk5NTc5OGM0XkEyXkFqcGdeQXVyNDYzNTI2ODc@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "107 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 816,
    name: "Ghost",
    year: 1990,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmE4ZjI3YzctNjM0YS00MjBlLWFmYjItNDRiNTk0ZmRkYjVjXkEyXkFqcGdeQXVyNjc3MjQzNTI@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "A pushy spirit forces a fortune teller to convince his girlfriend he was murdered.",
    genre: "Drama",
    subgenre: "",
    runtime: "127 min",
    pairs: "Ectoplasm.",
    drinkRecipe: "Vodka, Ecto-Cooler Hi-C.",
    rated: "PG-13",
    picture1: ghost1,
    picture2: ghost2,
    picture3: ghost3,
    tagline: "Demi's bush is longer than that mushroom cut",
    taglineWriter: "Bruce Willis",
    bulletOne: "Pottery just got HOT.",
    bulletTwo: "Helping out the crazies by normalizing self talk.",
    cast: "Directed by JERRY ZUCKER starring PATRICK SWAYZE DEMI MOORE WHOOPI GOLDBERG and TONY GOLDWYN",
  },
  {
    id: 817,
    name: "While You Were Sleeping",
    year: 1995,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTU5NjU4NDUzMl5BMl5BanBnXkFtZTgwMjc4NDgzMTE@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Romcom",
    subgenre: "",
    runtime: "103 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 818,
    name: "101 Dalmatians",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGI2NTQ2N2EtNDExMS00OWM2LTkzZWItZGUzY2M2MDFjODk0XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "103 min",
    pairs: "",
    rated: "G",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 819,
    name: "Beavis and Butt-Head Do America",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNWY3NmNlYzYtNzY5Yy00Y2Q1LWE1NWEtYzQ2NDM4YTMyYjVmXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Animation",
    runtime: "81 min",
    pairs: "",
    rated: "PG-13",
    picture1: butthead1,
    picture2: butthead2,
    picture3: butthead3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by MIKE JUDGE starring MIKE JUDGE BRUCE WILLIS DEMI MOORE and ROBERT STACK",
  },
  {
    id: 820,
    name: "Bio-Dome",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzY0ZjI0NTUtNDE4Yi00OGU2LWExZDMtNTY1MTM3ZWE3MzEyXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "88 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 821,
    name: "The Birdcage",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTM2NjExODYyOF5BMl5BanBnXkFtZTcwNTc0NjgyNA@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "117 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 822,
    name: "Black Sheep",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjc2NzU0YjUtYzkyNS00NjcwLWJiM2QtM2Y3YjFhMTQ0M2I3XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "87 min",
    pairs: "",
    rated: "PG-13",
    picture1: blackSheep1,
    picture2: blackSheep2,
    picture3: blackSheep3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by PENELOPE SPHEERIS starring CHRIS FARLEY DAVID SPADE TIM MATHESON and CHRISTINE EBERSOLE",
  },
  {
    id: 823,
    name: "The Cable Guy",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzE3ZTc5ZjctN2RhZS00NTljLWFkNzgtYWQ1ZjA2NTJmOWE4XkEyXkFqcGdeQXVyNjE5MjUyOTM@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "A jerk bribes an innocent cable technician into providing free channels and then won't even be friends with the guy.",
    genre: "Comedy",
    subgenre: "",
    runtime: "96 min",
    pairs: "Shattered Backboard.",
    drinkRecipe: "Vodka, chipped ice.",
    rated: "PG-13",
    picture1: cableGuy1,
    picture2: cableGuy2,
    picture3: cableGuy3,
    tagline: "Treat people right - no ifs, ands, or butts!",
    taglineWriter: "Sam Sweet",
    bulletOne: "Exposing yourself during a prison visit.",
    bulletTwo: "Playing porno password with your friends parents. ",
    cast: "Directed by BEN STILLER starring JIM CARREY MATTHEW BRODERICK LESLIE MANN and JACK BLACK",
  },
  {
    id: 824,
    name: "From Dusk till Dawn",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjk3YmZhMDAtOWUzMS00YjE5LTkxNzAtY2I1NGZjMDA2ZTk0XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Cult Classic",
    subgenre: "Horror",
    runtime: "108 min",
    pairs: "",
    rated: "R",
    picture1: dusktildawn1,
    picture2: dusktildawn2,
    picture3: dusktildawn3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by ROBERT RODRIGUEZ starring GEORGE CLOONEY QUENTIN TARANTINO HARVEY KEITEL and SALMA HAYEK",
  },
  {
    id: 825,
    name: "High School High",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmQ4NzE5ZDItMzFjYi00MTU5LTliYjAtYzRjZDI4ZjQ4NzY5XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A spoof of all the 'uptight teacher goes to the inner city and shows tough youths that education is cool' movies that seemed to be made every year from 1985-1995..",
    genre: "Comedy",
    subgenre: "",
    runtime: "86 min",
    pairs: "A Gummy Desk.",
    drinkRecipe: "Bubblegum vodka, Sprite.",
    rated: "PG-13",
    picture1: hshigh1,
    picture2: hshigh2,
    picture3: hshigh3,
    tagline: "IT'S TOUGH BEING A SUBSTITUTE",
    taglineWriter: "Tom Berenger",
    bulletOne: "The funniest cat gag of all time.",
    bulletTwo: "Gangsta's Paradise playing on every single radio station.",
    cast: "Directed by HART BOCHNER starring JON LOVITZ TIA CARRERE MEKHI PHIFER and GUILLERMO DIAZ",
  },
  {
    id: 826,
    name: "Jack",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDYyYzAyNjctODM1Mi00ZjE4LWFmNTItZjRmOWUxODA1ODY4XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "A genetic freak pulls a reverse Benjamin Button and struggles to make friends.",
    genre: "Comedy",
    subgenre: "",
    runtime: "113 min",
    pairs: "an Ageism.",
    drinkRecipe: "Capri-Sun and vodka.",
    rated: "PG-13",
    picture1: jack1,
    picture2: jack2,
    picture3: jack3,
    tagline: "Don't drink in the treehouse with Mr. Woodruff",
    taglineWriter: "Karen",
    bulletOne: "I'd ask JLo to the dance too",
    bulletTwo: "Robin Williams is: Centennial Man",
    cast: "Directed by FRANCIS FORD COPPOLA starring ROBIN WILLIAMS  DIANE LANE  ADAM ZOLOTIN and JENNIFER LOPEZ",
  },
  {
    id: 827,
    name: "Joe's Apartment",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzczODMyNGUtNjRmOS00ZTA3LWIxOTgtOGE2YmYwZDA2YWU1XkEyXkFqcGdeQXVyNzc5MjA3OA@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "80 min",
    pairs: "",
    rated: "PG-13",
    picture1: joesApt1,
    picture2: joesApt2,
    picture3: joesApt3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "I've had worse roommates.",
    bulletTwo: "",
    cast: "Directed by JOHN PAYSON starring JERRY O'CONNELL MEGAN WARD BILLY WEST and REGINALD HUDLIN",
  },
  {
    id: 828,
    name: "Larger Than Life",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDg3NTYyYjktZWZjOC00ZTIwLTgwMzQtMDdjYjY1NmQxN2RlXkEyXkFqcGdeQXVyNTM5NzI0NDY@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "93 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 829,
    name: "Mars Attacks!",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNThlMjBmOWItNjljZi00ZGU4LWJkZjgtNWU1ZjI1MTg4MzczXkEyXkFqcGdeQXVyNTIzOTk5ODM@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Scifi",
    subgenre: "Comedy",
    runtime: "106 min",
    pairs: "",
    rated: "PG-13",
    picture1: marsAttacks1,
    picture2: marsAttacks2,
    picture3: marsAttacks3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by TIM BURTON starring PIERCE BROSNAN SARAH JESSICA PARKER JACK NICHOLSON and JIM BROWN",
  },
  {
    id: 830,
    name: "Mystery Science Theater 3000: The Movie",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzNmN2UzNTAtNmZkMS00MmI3LThhNzEtNjI1YjJlN2JiZjU0XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Cult Classic",
    subgenre: "Comedy",
    runtime: "73 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 831,
    name: "Puddle Cruiser",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTc5ODIxMTg5M15BMl5BanBnXkFtZTcwNzEyNzQzMQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "102 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 832,
    name: "Jack Reacher",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTM1NjUxMDI3OV5BMl5BanBnXkFtZTcwNjg1ODM3OA@@._V1_SX300.jpg",
    rating: 5,
    year: 2012,
    synopsis:
      "Against the advice of her father, a Pittsburgh lawyer hires a new lead investigator with zero references, one shirt, and two moods.",
    genre: "Action",
    subgenre: "Drama",
    runtime: "130 min",
    pairs: "a Yinzer.",
    drinkRecipe: "Kraken rum & lemon lime gatorade,.",
    noPairs: "",
    rated: "PG-13",
    picture1: jackreacher1,
    picture2: jackreacher2,
    picture3: jackreacher3,
    tagline: "WHAT ABOUT JUSTICE?!",
    taglineWriter: "Gone Girl",
    bulletOne: "Doing what it takes to survive, hands down.",
    bulletTwo:
      "Being the coolest guy in a bar with people less than half your age.",
    cast: "Directed by CHRISTOPHER McQUARRIE starring TOM CRUISE ROSAMUND PIKE RICHARD JENKINS and WERNER HERZOG",
  },
  {
    id: 833,
    name: "Space Jam",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjA3ZmMwNTUtNGM2Ni00YjUyLWE5ZDYtY2Y4YzhmYzlhYWI2XkEyXkFqcGdeQXVyMjUzOTY1NTc@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Animation",
    subgenre: "Sports",
    runtime: "88 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 834,
    name: "Spy Hard",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOGFmYWFiZDAtZTc1YS00ZTIxLTkwODctODc0MzUzNGIyNzgwL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "81 min",
    pairs: "",
    rated: "PG-13",
    picture1: spyhard1,
    picture2: spyhard2,
    picture3: spyhard3,
    tagline: "Laughs EXPLODE straight from the title sequence!",
    taglineWriter: "S. Silver Cox",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by RICK FRIEDBERG starring LESLIE NIELSEN NICOLLETTE SHERIDAN CHARLES DURNING and MARCIA GAY HARDEN",
  },
  {
    id: 835,
    name: "UHF",
    year: 1989,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTAzMjE2NDg5NjZeQTJeQWpwZ15BbWU3MDAzNzI4NzQ@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "A guy gets his own public access channel and his wacky shows become huge hits. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "97 min",
    pairs: "",
    noPairs: "beer from a firehose.",
    rated: "PG-13",
    picture1: uhf1,
    picture2: uhf2,
    picture3: uhf3,
    tagline: "If you don't watch, YOU'RE SO STUPID!!!",
    taglineWriter: "Long Duk Dong",
    bulletOne:
      "Respect the Dewey Decimal System and learn some new things about your pets.",
    bulletTwo:
      "Michael Richards turns in one of the best physical comedy performances of all time.",
    cast: "Directed by JAY LEVEY starring WEIRD AL YANKOVIC DAVID BOWE MICHAEL RICHARDS and GEDDE WATANABE",
  },
  {
    id: 836,
    name: "8 Heads in a Duffel Bag",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWQyOTZhYmUtZTE3NC00MTg5LWE3YzAtNjI0YmFlOWYwZGM1XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "95 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 837,
    name: "Air Bud",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzQxODE4MzEyNF5BMl5BanBnXkFtZTgwNjk2OTY4ODE@._V1_SX300.jpg",
    rating: 6,
    synopsis: "A selfish kid pet-naps a pup from a simple entertainer.",
    genre: "Sports",
    subgenre: "",
    runtime: "98 min",
    pairs: "a Poison Pudding.",
    drinkRecipe: "Baileys, Milk, Gin. Blended.",
    rated: "PG",
    picture1: airbud1,
    picture2: airbud2,
    picture3: airbud3,
    tagline: "You can tell when Buddy is acting",
    taglineWriter: "P.E. Tah",
    bulletOne: "Don't feed your dog pudding for tricks apparently",
    bulletTwo: "Hypocrites force Buddy into a new kind of performance",
    cast: "Directed by CHARLES MARTIN SMITH starring KEVIN ZEGERS MICHAEL JETER BILL COBBS and WENDY MAKKENA",
  },
  {
    id: 838,
    name: "Austin Powers: International Man of Mystery",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWUwMmEwY2QtMGZmZC00ZDVjLTg1NDgtMmI0MmZmYmM4NGIxXkEyXkFqcGdeQXVyMTY5Nzc4MDY@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "89 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 839,
    name: "Austin Powers: The Spy Who Shagged Me",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmFkZGQxN2YtODNlYS00MzM5LTk3NjQtNTUxYmQ1YzkwMDhmXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "95 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 840,
    name: "Austin Powers in Goldmember",
    year: 2002,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTI3NTkzODI0MF5BMl5BanBnXkFtZTYwNjc5MTA3._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "94 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 841,
    name: "Bean",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjU2YzY5NDEtM2M3NC00NGM0LTg3MmItNWQ1OWY2ZmNkZTU0XkEyXkFqcGdeQXVyNTIzOTk5ODM@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "89 min",
    pairs: "",
    rated: "PG-13",
    picture1: bean1,
    picture2: bean2,
    picture3: bean3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by MEL SMITH starring ROWAN ATKINSON PETER MACNICOL PAMELA REED and JOHN MILLS",
  },
  {
    id: 842,
    name: "Ernest Goes to Africa",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDVlM2MwNDUtYzFmNy00MzRjLTk2ZmEtZmQ3ZTlhYzFlYWNlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "90 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 843,
    name: "Gone Fishin'",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZmFlMWMwYmUtMGEwNS00ZWVlLTlkNjctYTQ4NWUxMTIxOTg5XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "Where else can you see Joe Pesci kick an alligator in the nuts? 10/10 isn't high enough for this movie; it's a borderline lebbin.",
    genre: "Comedy",
    subgenre: "",
    runtime: "94 min",
    pairs: "",
    noPairs: "red wine to the brim.",
    rated: "PG",
    picture1: goneFishin1,
    picture2: goneFishin2,
    picture3: goneFishin3,
    tagline: "Not a sofishticated film, but cod damn it's fun",
    taglineWriter: "Big Mouth Billy Bass",
    bulletOne: "Willie Nelson cameo.",
    bulletTwo: "One of the few movies where Pesci doesn't play a psycho.",
    cast: "Directed by CHRISTOPHER CAIN starring JOE PESCI DANNY GLOVER NICK BRIMBLE and ROSANNA ARQUETTE",
  },
  {
    id: 844,
    name: "Flubber",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDlkOTA0OTUtOThmMi00NTMyLTg0NDgtZWZiYmRjZWI4MDY4XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "93 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 845,
    name: "For Richer or Poorer",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGU0ODhmODEtZTJlOC00YjA3LThhYWItZjQ0Mzk3NDNhNzIwXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "115 min",
    pairs: "",
    rated: "PG-13",
    picture1: richerPoorer1,
    picture2: richerPoorer2,
    picture3: richerPoorer3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by BRYAN SPICER starring TIM ALLEN KIRSTIE ALLEY JAY O. SANDERS and LARRY MILLER",
  },
  {
    id: 846,
    name: "George of the Jungle",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTdiM2VjYjYtZjEwNS00ZWU5LWFkZGYtZGYxMDcwMzY1OTEzL2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTczNjQwOTY@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "92 min",
    pairs: "",
    rated: "PG",
    picture1: georgeJungle1,
    picture2: georgeJungle2,
    picture3: georgeJungle3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by SAM WEISMAN starring BRESNICK FRATER LESLIE MANN RICHARD ROUNDTREE and THOMAS HADEN CHURCH",
  },
  {
    id: 847,
    name: "Good Burger",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjJmOTYwZmMtMWQ3Ni00YzJiLThhNWEtZjU1MGU3ZjEwMDI2XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Two chuckleheads must fight corporate sellouts and keep the Good name of their Burger.",
    genre: "Comedy",
    subgenre: "",
    runtime: "95 min",
    pairs: "a Pickleback",
    drinkRecipe: "shot of whiskey, shot of pickle juice",
    rated: "PG",
    picture1: goodBurger1,
    picture2: goodBurger2,
    picture3: goodBurger3,
    tagline: "MORE LIKE HOOD BURGER!",
    taglineWriter: "Karen",
    bulletOne: "This movie is All That and a bottle of orange soda.",
    bulletTwo:
      "Having a sweet work vehicle that rivals the Oscar Meyer Weiner.",
    cast: "Directed by BRIAN ROBBINS starring KEL MITCHELL KENAN THOMPSON ABE VIGODA and SINBAD ",
  },
  {
    id: 848,
    name: "Hercules",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDcwYjEyNWItMGI1Mi00MjI1LWIyMDEtMTQ4YjhkNjM4NDU3XkEyXkFqcGdeQXVyNDUyOTg3Njg@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "93 min",
    pairs: "",
    drinkRecipe: "",
    rated: "G",
    picture1: hercules971,
    picture2: hercules972,
    picture3: hercules973,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "Perfect cartoon representation of Danny DeVito.",
    bulletTwo: "",
    cast: "Directed by RON CLEMENTS starring TATE DONOVAN SUSAN EGAN JAMES WOODS and JOSH KEATON",
  },
  {
    id: 849,
    name: "Hercules in New York",
    year: 1970,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDE2MDRmMjQtNGM0NC00N2U4LWI2ZGYtM2I2MzIyNzY5NjlmXkEyXkFqcGdeQXVyNDIwODAwNzg@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Transported from the heavens and chiseled out of marble, antiquity's most famous mythic hero struggles to find love (and an acting coach) in the Big Apple.",
    genre: "Comedy",
    subgenre: "Cult Classic",
    runtime: "92 min",
    pairs: "Zeus' Blood.",
    drinkRecipe: "Franzia Dark Red, 2oz Tuaca, Coca-Cola",
    rated: "G",
    picture1: herculesNY1,
    picture2: herculesNY2,
    picture3: herculesNY3,
    tagline: "WHAT ARE YOU WAITING FOR? WATCH IT NOW!",
    taglineWriter: "Major Alan Schaefer",
    bulletOne: "Using two horsepower to out pace traffic in NYC.",
    bulletTwo: "Arnie's big screen debut. Worth it for that alone.",
    cast: "Directed by ARTHUR ALLAN SEIDELMAN starring ARNOLD SCHWARZENEGGER ARNOLD STANG JAMES KAREN and MERWIN GOLDSMITH",
  },
  {
    id: 850,
    name: "Jungle 2 Jungle",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BM2Q4MjNjNmYtOGYyZS00OGUzLWI4OTQtZjI0NmFiZTUyZDAwXkEyXkFqcGdeQXVyNTUyMzE4Mzg@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "A city slicker discovers he has a Tarzan-like son who lives in the Amazon.",
    genre: "Comedy",
    subgenre: "",
    runtime: "105 min",
    pairs: "Blow-Fly.",
    drinkRecipe: "rum, mango juice, boba, ice.",
    rated: "PG",
    picture1: mimisiku1,
    picture2: mimisiku2,
    picture3: mimisiku3,
    tagline: "WATCH OUT FOR THAT TREE!",
    taglineWriter: "George of the Jungle Too",
    bulletOne: "Having hairy new pets.",
    bulletTwo:
      "Getting your girlfriends parents to vaca in the same spot as you.",
    cast: "Directed by JOHN PASQUIN starring TIM ALLEN MARTIN SHORT SAM HUNTINGTON and LOLITA DAVIDOVICH",
  },
  {
    id: 851,
    name: "In & Out",
    year: 1997,
    poster:
      "https://image.tmdb.org/t/p/w185_and_h278_bestv2//xf06EVsKA2wI5TUpozGmHw4Phrs.jpg",
    rating: 5,
    synopsis: "",
    genre: "Romcom",
    subgenre: "Comedy",
    runtime: "92 min",
    pairs: "",
    rated: "PG-13",
    picture1: inout1,
    picture2: inout2,
    picture3: inout3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by FRANK OZ starring KEVIN KLINE JOAN CUSACK TOM SELLECK and MATT DILLON",
  },
  {
    id: 852,
    name: "Liar Liar",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjZlYmJjYWYtZDM0NS00YmZlLWIyMTAtMDY5ZTNjZTgwMDhjXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "A whiny little brat tanks his dads career after unleashing sorcery at a birthday party.",
    genre: "Comedy",
    subgenre: "",
    runtime: "86 min",
    pairs: "Pinocchio Punch",
    drinkRecipe: "pineapple juice, oj, vodka, sparkling water.",
    rated: "PG-13",
    picture1: liarliar1,
    picture2: liarliar2,
    picture3: liarliar3,
    tagline: "A MAN I CAN TRUST",
    taglineWriter: "Honest Abe",
    bulletOne:
      "Jim Carrey's physical comedy is next-level. Dude has a rubber face.",
    bulletTwo:
      "Proving once and for all that lying is better than telling the truth.",
    cast: "Directed by TOM SHADYAC starring JIM CARREY MAURA TIERNEY CARY ELWES and JUSTIN COOPER",
  },
  {
    id: 853,
    name: "Men in Black",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTlhYTVkMDktYzIyNC00NzlkLTlmN2ItOGEyMWQ4OTA2NDdmXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Scifi",
    subgenre: "Comedy",
    runtime: "98 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 854,
    name: "Lucy in the Sky",
    year: 2019,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNmEzMDkxYzItMjdiNC00YzA5LWJmYWMtMzc2Mjc1MTdjNDZiXkEyXkFqcGdeQXVyMTkxNjUyNQ@@._V1_SX300.jpg",
    rating: 2,
    synopsis: "",
    genre: "Drama",
    subgenre: "",
    runtime: "124 min",
    pairs: "",
    rated: "R",
    picture1: lucySky1,
    picture2: lucySky2,
    picture3: lucySky3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne:
      "Started out interesting, quickly turned into a dull artsy flick.",
    bulletTwo: "",
    cast: "Directed by NOAH HAWLEY starring NATALIE PORTMAN JON HAMM ZAZIE BEETZ and DAN STEVENS",
  },
  {
    id: 855,
    name: "Mr. Magoo",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BODFiOWY2OTctMzBlNi00MjhhLTg4MWQtMTI5YTUzZTI2N2E1XkEyXkFqcGdeQXVyNTUyMzE4Mzg@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "87 min",
    pairs: "",
    rated: "PG",
    picture1: mrMagoo1,
    picture2: mrMagoo2,
    picture3: mrMagoo3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by STANLEY TONG starring LESLIE NIELSEN KELLY LYNCH MIGUEL FERRER and MALCOLM MCDOWELL",
  },
  {
    id: 856,
    name: "Orgazmo",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTYyYjczNGMtMDM0My00NWFlLTk0ZjYtOWY0Y2Q4NmU4NmE4XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "94 min",
    pairs: "",
    rated: "NC-17",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 857,
    name: "Private Parts",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDhjZjAxNzgtNzU3NS00OWQxLWI2ODItMjk0NmRjMTU5MDhjXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "109 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 858,
    name: "Romy and Michele's High School Reunion",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYWI2NTYyNTAtMjI4ZC00NzY4LThhZjctMDA4NWMyYWE4ODc5XkEyXkFqcGdeQXVyNTE1NjY5Mg@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Romcom",
    subgenre: "",
    runtime: "92 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 859,
    name: "Vegas Vacation",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTIwMWEwZmQtMjI1ZS00NDdmLTgwMGItNmFiOTk1ZWQ5NGZiXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "The Griswalds go dancin' across the USA for their most sinful family vaca yet!",
    genre: "Comedy",
    subgenre: "",
    runtime: "93 min",
    pairs: "",
    noPairs: "necking a blue yard long drink.",
    rated: "PG",
    picture1: vegasVaca1,
    picture2: vegasVaca2,
    picture3: vegasVaca3,
    tagline: "THIS MOVIE IS A HARD SIX!",
    taglineWriter: "Pierce Hawthorne",
    bulletOne: "Lindsay Buckingham Original Soundtrack.",
    bulletTwo: "Clark being every dad ever at the casino.",
    cast: "Directed by STEPHEN KESSLER starring CHEVY CHASE  BEVERLY D'ANGELO  ETHAN EMBRY and MARISOL NICHOLS",
  },
  {
    id: 860,
    name: "Antz",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzMzMDdlMDktODg4OC00Y2E5LTk1ZjMtNzM2MzIxZGQ0ZGI3XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "If you want to watch insects act like people just check out 𝘈 𝘉𝘶𝘨'𝘴 𝘓𝘪𝘧𝘦. If you want to watch Woody Allen act like a bug then check out 𝘈𝘯𝘯𝘪𝘦 𝘏𝘢𝘭𝘭.",
    genre: "Animation",
    subgenre: "",
    runtime: "83 min",
    pairs: "two aphid beerz.",
    drinkRecipe:
      "Milk Stout, 2 scoops Mint Chocolate Chip, 1 shot of Baileys & 1 of Jack Daniels",
    rated: "PG",
    picture1: antz1,
    picture2: antz2,
    picture3: antz3,
    tagline: "YEEWH! SOMEONE CALL THE EX-TERMINATOR!",
    taglineWriter: "Anthill Schwarzenbugger",
    bulletOne: "Super chizeled worker antz.",
    bulletTwo: "Learning about the termite rivalry.",
    cast: "Directed by ERIC DARNELL starring WOODY ALLEN SHARON STONE GENE HACKMAN and SYLVESTER STALLONE",
  },
  {
    id: 861,
    name: "A Bug's Life",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNThmZGY4NzgtMTM4OC00NzNkLWEwNmEtMjdhMGY5YTc1NDE4XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "95 min",
    pairs: "",
    rated: "G",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 862,
    name: "Dead Man on Campus",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BY2EwMWZlMDYtMTdlZC00ZDIzLTkzOTAtNjZkNThhNmM5ZDU3XkEyXkFqcGdeQXVyNTM0NTU5Mg@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "96 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 863,
    name: "Ernest in the Army",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BM2Y3YjFlYzItMDMzZC00MGZmLTk4MzMtM2Y4MjUwMGMyNjQ3XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "85 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 864,
    name: "Holy Man",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYmNlZjM0ZDktOTJlZS00MmFiLWI2OWMtYTM0M2NkOTEwMTM0L2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "114 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 865,
    name: "I'll Be Home for Christmas",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTA2ZTcwMDMtMTRhNC00N2I5LWJlNDMtYjIyZjBkYzQ2NDEwXkEyXkFqcGdeQXVyNzc5MjA3OA@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "86 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  // {
  //   id: 866,
  //   name: "Jack Frost",
  //   year: 1998,
  //   poster:
  //     "https://m.media-amazon.com/images/M/MV5BOTE4YWIzNjItZWNhNi00Y2E1LTgyODktMTUzM2ZkMmJlNDFlL2ltYWdlXkEyXkFqcGdeQXVyNjg4NzYzMzA@._V1_SX300.jpg",
  //   rating: 4,
  //   synopsis:
  //     "After getting iced in a car accident, Michael Keaton becomes a snowman. You'd think that’d leave him frosty, but you'll laugh your sled off. Everyone teased Jack about his pointy nose, but he didn't carrot-all. Then he fuckin' melted.",
  //   genre: "Comedy",
  //   subgenre: "",
  //   runtime: "101 min",
  //   pairs: "",
  //   rated: "PG",
  //   picture1: jackfrostKeaton1,
  //   picture2: jackfrostKeaton2,
  //   picture3: jackfrostKeaton3,
  //   tagline: "IT'LL MELT YOUR HEART!",
  //   taglineWriter: "Some cool writer",
  //   bulletOne: "",
  //   bulletTwo: "",
  //   cast: "Directed by TROY MILLER starring MICHAEL KEATON KELLY PRESTON JOSEPH CROSS and MARK ADDY",
  // },
  {
    id: 867,
    name: "Jack Frost",
    year: 1997,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjE4ZDAxNmItODBjMy00YjVlLTkwMDMtZjYxZWY0YmI4ZDczXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Ah the memories of sifting through rows of holiday movies at Blockbuster as a kid and this hologram cover pops up out of nowhere. Then having to go home, change undies, and get under the covers forever.",
    genre: "Horror",
    subgenre: "Christmas",
    runtime: "90 min",
    pairs: "Frozen Tears.",
    drinkRecipe:
      "peppermint schnapps, 7-UP, blue curaçao, Loconut Rum, and Tito's. ",
    rated: "R",
    picture1: jackfrostHorror1,
    picture2: jackfrostHorror2,
    picture3: jackfrostHorror3,
    tagline: "THIS DUDE IS COLD!",
    taglineWriter: "Noreaster Bunny",
    bulletOne:
      "If you're looking for the family friendly Michael Keaton flick, pick this one.",
    bulletTwo:
      "Mix 𝙏𝙝𝙚 𝙂𝙞𝙣𝙜𝙚𝙧𝙙𝙚𝙖𝙙 𝙈𝙖𝙣 with 𝘾𝙝𝙪𝙘𝙠𝙮 and a little 𝙁𝙧𝙤𝙨𝙩𝙮 𝙩𝙝𝙚 𝙎𝙣𝙤𝙬𝙢𝙖𝙣.",
    cast: "Directed by MICHAEL COONEY starring SHANNON ELIZABETH SCOTT MACDONALD CHRISTOPHER ALLPORT and STEPHEN MENDEL",
  },
  {
    id: 868,
    name: "Lethal Weapon",
    year: 1987,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTllNWNlZjctMWQwMS00ZDc3LTg5ZjMtNzhmNzhjMmVhYTFlXkEyXkFqcGdeQXVyNTc1NTQxODI@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "109 min",
    pairs: "",
    rated: "R",
    picture1: lethalWeapon1,
    picture2: lethalWeapon2,
    picture3: lethalWeapon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by RICHARD DONNER starring MEL GIBSON DANNY GLOVER GARY BUSEY and DARLENE LOVE",
  },
  {
    id: 869,
    name: "Lethal Weapon 2",
    year: 1989,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZWE1OWYwZTAtMGUwMC00MGE3LWE1ODAtMTI0NGQ1NzU5YmYzXkEyXkFqcGc@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "114 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 870,
    name: "Lethal Weapon 3",
    year: 1992,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDA3Zjc5NjYtODZiNy00Yjg3LTk0MWEtMDk3NDA4ZjU3YTY0XkEyXkFqcGc@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "118 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 871,
    name: "Lethal Weapon 4",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDU4MDIyMzEtZjEwZi00NmFjLTljMzQtNDgzNDE5ZDhlMTZmXkEyXkFqcGc@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "127 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 872,
    name: "Mafia!",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjJlZjYwMjQtMWUwYi00MDYwLWFjOTgtZjk0M2ZkMmVkZGQ1XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "87 min",
    pairs: "",
    rated: "PG-13",
    picture1: mafia1,
    picture2: mafia2,
    picture3: mafia3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by JIM ABRAHAMS starring JAY MOHR CHRISTINA APPLEGATE LLOYD BRIDGES and BILLY BURKE",
  },
  {
    id: 873,
    name: "The Parent Trap",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNWYyMzJhNzEtMzFkNC00M2U0LTkwMWYtYWVkN2Y2MGI5ODZkL2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "128 min",
    pairs: "",
    rated: "PG",
    picture1: parentTrap1,
    picture2: parentTrap2,
    picture3: parentTrap3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by NANCY MEYERS starring LINDSAY LOHAN DENNIS QUAID NATASHA RICHARDSON and ELAINE HENDRIX",
  },
  {
    id: 874,
    name: "Paulie",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BY2MwZjM2NTYtNmU5Zi00NWM3LTg1ZWUtMjJkZTkxMTcwNWY4XkEyXkFqcGdeQXVyNjg4NzYzMzA@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "91 min",
    pairs: "",
    rated: "PG",
    picture1: paulie1,
    picture2: paulie2,
    picture3: paulie3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by JOHN ROBERTS starring GENA ROWLANDS TONY SHALHOUB CHEECH MARIN and JAY MOHR",
  },
  {
    id: 875,
    name: "Pleasantville",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTRlYzk4NDktODE3Ni00YjFkLWFjYmUtNjg1MzdmYmFmOTJkXkEyXkFqcGdeQXVyMTAwMzUyOTc@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "124 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 876,
    name: "Rushmore",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjE2OTc3OTk2M15BMl5BanBnXkFtZTgwMjg2NjIyMDE@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "93 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 877,
    name: "Strangers With Candy",
    year: 2005,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTgwMTM0MzgyNF5BMl5BanBnXkFtZTcwMjg4MzMzMQ@@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "A former boozer, user, and loser gets a second chance at a first try.",
    genre: "Comedy",
    subgenre: "",
    runtime: "97 min",
    pairs: "a Blackman.",
    drinkRecipe: "Kahlúa, Coffee.",
    rated: "R",
    picture1: strangersCandy1,
    picture2: strangersCandy2,
    picture3: strangersCandy3,
    tagline: "This movie will pull you closer to yourself",
    taglineWriter: "Crotch Niblet",
    bulletOne: "Principal Blackman.",
    bulletTwo: "Having some cruel fun at other peoples expense.",
    cast: "Directed by PAUL DINELLO starring AMY SEDARIS STEPHEN COLBERT PAUL DINELLO and GREG HOLLIMON",
  },
  // {
  //   id: 878,
  //   name: "Shakespeare in Love",
  //   year: 1998,
  //   poster:
  //     "https://m.media-amazon.com/images/M/MV5BM2ZkNjM5MjEtNTBlMC00OTI5LTgyYmEtZDljMzNmNzhiNzY0XkEyXkFqcGdeQXVyNDYyMDk5MTU@._V1_SX300.jpg",
  //   rating: 4,
  //   synopsis: "",
  //   genre: "Romcom",
  //   subgenre: "Drama",
  //   runtime: "123 min",
  //   pairs: "",
  //   rated: "R",
  //   picture1: shakespeareInLove1,
  //   picture2: shakespeareInLove2,
  //   picture3: shakespeareInLove3,
  //   tagline: "Headline to come!",
  //   taglineWriter: "Some cool writer",
  //   bulletOne: "",
  //   bulletTwo: "",
  //   cast: "Directed by JOHN MADDEN starring GWYNETH PALTROW JOSEPH FIENNES GEOFFREY RUSH and TOM WILKINSON",
  // },
  {
    id: 879,
    name: "Six Days Seven Nights",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDgwYzNlMjYtNjQxMy00NjQxLWJiMTItNjkzZTQ0ZTZiMTcyXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "102 min",
    pairs: "",
    rated: "PG-13",
    picture1: sixdays1,
    picture2: sixdays2,
    picture3: sixdays3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by IVAN REITMAN starring HARRISON FORD ANNE HECHE DAVID SCHWIMMER and JACQUELINE OBRADORS",
  },
  {
    id: 880,
    name: "Small Soldiers",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjBlN2I0YjYtM2EzNC00NGE0LTg5ZWEtNGFhNzBmNmMxNmQzXkEyXkFqcGdeQXVyMTUzMDUzNTI3._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Animation",
    subgenre: "",
    runtime: "110 min",
    pairs: "",
    rated: "PG-13",
    picture1: smallSoldiers1,
    picture2: smallSoldiers2,
    picture3: smallSoldiers3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by JOE DANTE starring KIRSTEN DUNST DAVID CROSS JAY MOHR and DENIS LEARY",
  },
  {
    id: 881,
    name: "The Waterboy",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzJmZDZlMGItZGJhOC00Y2NjLTljNzctMDg5YmQ1NzU3NzYzL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "A mumbling moron uses his empty, thick skull to obliterate players on the football field.",
    genre: "Comedy",
    subgenre: "",
    runtime: "90 min",
    pairs: "",
    noPairs: "scotch and water - hold the water.",
    rated: "PG-13",
    picture1: waterboy1,
    picture2: waterboy2,
    picture3: waterboy3,
    tagline: "Simple Jack joins a football team",
    taglineWriter: "Tugg Speedman",
    bulletOne:
      "Henry Winklers nervous breakdown scene. 'I hate him I hate him I hate him!'.",
    bulletTwo:
      "Allen Covert needing heavy makeup to be as ugly as un-madeup Clint Howard.",
    cast: "Directed by FRANK CORACI starring ADAM SANDLER KATHY BATES FAIRUZA BALK and HENRY WINKLER",
  },
  {
    id: 882,
    name: "You've Got Mail",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTcxNzgzZjMtYzZiZC00MmE1LTg3MzQtZDAxMTYyZWE4MDNhL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Back in the infancy of the internet American OnLine had a stranglehold on the email market. Tom Hanks and Meg Ryan start the first online relationship; proving that even in the beginning you couldn’t trust an online profile.",
    genre: "Romcom",
    subgenre: "",
    runtime: "119 min",
    pairs: "a Manhattan.",
    drinkRecipe: "Bourbon, Sweet Vermouth, Angostura bitters, orange bitters.",
    rated: "PG",
    picture1: youveGotMail1,
    picture2: youveGotMail2,
    picture3: youveGotMail3,
    tagline: "The original bait and switch!",
    taglineWriter: "Catfish Stevens",
    bulletOne: "One of the few movies where Tom Hanks is a jerk.",
    bulletTwo:
      "Being substantially more eloquent and charismatic virtually than in real life",
    cast: "Directed by NORA EPHRON starring TOM HANKS MEG RYAN GREG KINNEAR and PARKER POSEY",
  },
  {
    id: 883,
    name: "Analyze This",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTIzNjQ5OTMtM2U2NS00YTNkLWIzMmYtOTcyOTA5MDU0YTdjXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "103 min",
    pairs: "",
    rated: "R",
    picture1: analyzeThis1,
    picture2: analyzeThis2,
    picture3: analyzeThis3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by HAROLD RAMIS starring ROBERT DE NIRO BILLY CRYSTAL LISA KUDROW and CHAZZ PALMINTERI",
  },
  {
    id: 884,
    name: "Analyze That",
    year: 2002,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTc0OTYwMzQ5OF5BMl5BanBnXkFtZTYwMzQyMDg5._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "96 min",
    pairs: "",
    rated: "R",
    picture1: analyzeThat1,
    picture2: analyzeThat2,
    picture3: analyzeThat3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by HAROLD RAMIS starring ROBERT DE NIRO BILLY CRYSTAL LISA KUDROW and JOE VITERELLI",
  },
  {
    id: 885,
    name: "Big Daddy",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjAzNzQ4YzEtZWFlOS00YWVkLWE2NDctZDBiZTUxYjgwOTYzXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "93 min",
    pairs: "",
    noPairs: "beer",
    rated: "PG-13",
    picture1: bigDaddy1,
    picture2: bigDaddy2,
    picture3: bigDaddy3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by DENNIS DUGAN starring ADAM SANDLER JON STEWART COLE SPROUSE and DYLAN SPROUSE",
  },
  {
    id: 886,
    name: "Bowfinger",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTJlNzcyMjctOGE5Mi00MzU4LWI1MzUtOGZiYzZlMzYxZmFlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "97 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 887,
    name: "Detroit Rock City",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmU5ZTJmOWQtNTMwMi00NmIzLTk0ZTAtMjNkMTE4YTA0Yzg4XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "95 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 888,
    name: "Dick",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNmUwZGUyOWYtMjJlMC00ODhhLWIxMDMtYjAwOGFmMGVjZTA1XkEyXkFqcGdeQXVyMjA5MTIzMjQ@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "94 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 889,
    name: "Dogma",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzAyOWUyZjQtNDBiMy00ZDExLTgwNmMtZDdmY2ViNzkyN2Y0XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "130 min",
    pairs: "",
    rated: "R",
    picture1: dogma1,
    picture2: dogma2,
    picture3: dogma3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by KEVIN SMITH starring BEN AFFLECK MATT DAMON CHRIS ROCK and JASON MEWES",
  },
  {
    id: 890,
    name: "Drop Dead Gorgeous",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTBhYjRlYmYtNzQxZS00NmEwLTk3OTAtNjU4MDEwMmUyZDczXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "97 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 891,
    name: "EDtv",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjBhNjc1NjktZTg4OS00YmUyLWI4YjAtNGIxZGM4ZTdkNmZkXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "122 min",
    pairs: "",
    rated: "PG-13",
    picture1: edtv1,
    picture2: edtv2,
    picture3: edtv3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by RON HOWARD starring MATTHEW MCCONAUGHEY WOODY HARRELSON ELLEN DEGENERES and ADAM GOLDBERG",
  },
  {
    id: 892,
    name: "Election",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjViYWQ4NjAtZmUzNy00Nzg5LWJkMzctMTgwOTRhNjdjYWI3XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "103 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 893,
    name: "Forces of Nature",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNWI5OWE0MWQtNjliMi00MjE4LTg5ODAtOTM0YjA2OWVlYzgwXkEyXkFqcGdeQXVyNjU0NTI0Nw@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Romcom",
    subgenre: "",
    runtime: "105 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 894,
    name: "Galaxy Quest",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNmZlNTY5YjQtZTU5ZC00MzcyLWI1NDMtNjA0ZjQxMmQwYjJmL2ltYWdlXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Scifi",
    runtime: "102 min",
    pairs: "",
    rated: "PG",
    picture1: galaxyQuest1,
    picture2: galaxyQuest2,
    picture3: galaxyQuest3,
    tagline: "NAME YOUR OWN INTERGALACTIC PRICE!",
    taglineWriter: "Will Shitner",
    bulletOne: "Segourney Weaver clearly saying, “Fuck!”.",
    bulletTwo: "",
    cast: "Directed by DEAN PARISOT starring TIM ALLEN SIGOURNEY WEAVER ALAN RICKMAN and TONY SHALHOUB",
  },
  {
    id: 895,
    name: "Inspector Gadget",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BN2RhYTQ5YmYtZTNkMS00M2YwLTliMjctNzg0OGYwMDQ5NDg0XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "78 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 896,
    name: "Man on the Moon",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDI1Mjc3MzAtZDk0OS00OTZlLTlhZjktNzA3ODgwZGY2NWIwXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "118 min",
    pairs: "",
    rated: "R",
    picture1: manMoon1,
    picture2: manMoon2,
    picture3: manMoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by MILOS FORMAN starring JIM CARREY DANNY DEVITO GERRY BECKER and GEORGE SHAPIRO",
  },
  {
    id: 897,
    name: "Mystery Men",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjdkZDRhYTgtNDliYS00ZmE1LThjYWMtOWNjNzNiODFmY2VmXkEyXkFqcGdeQXVyNjc4ODQwNzg@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "121 min",
    pairs: "a mystery.",
    drinkRecipe: "Idk, that's the mystery...man.",
    rated: "PG-13",
    picture1: mysteryMen1,
    picture2: mysteryMen2,
    picture3: mysteryMen3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "Inspirational quotes and team building.",
    bulletTwo: "Offbeat superhero movie that came out BEFORE it was cool.",
    cast: "Directed by KINKA USHER starring BEN STILLER WILLIAM H. MACY HANK AZARIA and KEL MITCHELL",
  },
  {
    id: 898,
    name: "Mystery, Alaska",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjE2MDM2NzcyMl5BMl5BanBnXkFtZTYwMzA2NjQ3._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Sports",
    subgenre: "",
    runtime: "119 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 899,
    name: "The Nutty Professor II: The Klumps",
    year: 2000,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjcyOWM2NTUtYzRjYS00ZTQ4LWFlZGItNTE2MDg4MGIxNjAyXkEyXkFqcGdeQXVyNTUyMzE4Mzg@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "106 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "TOTAL RIP OFF THE FATTIES: FART 2!",
    taglineWriter: "Jeff Portnoy",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 900,
    name: "Stuart Little",
    year: 1999,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDJlZWZiODItMGE3NC00Yzg3LWFhYTYtZTI2YWNlNjExMDE4XkEyXkFqcGdeQXVyMTA0MjU0Ng@@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A wealthy family decides it's time to adopt because their current son is strange and a new pet doesn't count as a dependent for tax purposes.",
    genre: "Animation",
    subgenre: "Family",
    runtime: "84 min",
    pairs: "a Manhattan.",
    drinkRecipe:
      "Rye Whiskey, Sweet Vermouth, Angostura bitters, and ice with cherry garnish.",
    rated: "PG",
    picture1: stuartLittle1,
    picture2: stuartLittle2,
    picture3: stuartLittle3,
    tagline: "ADOPTION TURNS TO INFESTATION!",
    taglineWriter: "Stewie Big",
    bulletOne:
      "It’s gotta suck for the other orphans; a freakin’ rat got picked before them.",
    bulletTwo: "Making a mouse grab the remote control that's twice his size.",
    cast: "Directed by ROB MINKOFF starring MICHAEL J. FOX GEENA DAVIS HUGH LAURIE and JONATHAN LIPNICKI",
  },
  {
    id: 901,
    name: "Vanilla Sky",
    year: 2001,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzFlMTJjYzUtMWFjYy00NjkyLTg1Y2EtYmZkMjdlOGQ1ZGYwL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "The lines of reality and facial recognition are blurred in this trippy drama as Tom Cruise sprints through an empty Manhattan for, at the time of filming, the most expensive shot in cinema history.",
    genre: "Drama",
    subgenre: "Mystery",
    runtime: "136 min",
    pairs: "a Chocolate Ground.",
    drinkRecipe: "Coffee liqueur, kalhua, rum, and chocolate milk.",
    rated: "R",
    picture1: vanillaSky1,
    picture2: vanillaSky2,
    picture3: vanillaSky3,
    tagline: "GREAT USE OF MASKING!",
    taglineWriter: "OperaPhanatics.com",
    bulletOne: "Cruz or Diaz? Tough zelection.",
    bulletTwo:
      "The highest-costing shot by 2001 wasn't 𝙅𝙪𝙧𝙖𝙨𝙨𝙞𝙘 𝙋𝙖𝙧𝙠'𝙨 T-Rex, 𝙏𝙝𝙚 𝙈𝙖𝙩𝙧𝙞𝙭'𝙨 bullet dodge, or 𝙏𝙞𝙩𝙖𝙣𝙞𝙘'𝙨 Titanic. It was Tom Cruise running.",
    cast: "Directed by CAMERON CROWE starring TOM CRUISE PENÉLOPE CRUZ CAMERON DIAZ and JASON LEE",
  },
  {
    id: 902,
    name: "Split",
    year: 2016,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTJiNGM2NjItNDRiYy00ZjY0LTgwNTItZDBmZGRlODQ4YThkL2ltYWdlXkEyXkFqcGdeQXVyMjY5ODI4NDk@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "A schizophrenic man with superhuman strength lives at the zoo and holds underage girls hostage.",
    genre: "Thriller",
    subgenre: "Horror",
    runtime: "117 min",
    pairs: "a Multiple Personality.",
    drinkRecipe: "Arnold Palmer, Jim Beam, Jack Daniels.",
    rated: "PG-13",
    picture1: split1,
    picture2: split2,
    picture3: split3,
    tagline: "Now offering basement exhibit tours!",
    taglineWriter: "Philadelphia Zoo",
    bulletOne: "J-Mac as 15 very different people.",
    bulletTwo: "Worth it for the end credits scene alone.",
    cast: "Directed by M. NIGHT SHYAMALAN starring JAMES MACAVOY ANYA TAYLOR-JOY HALEY LU RICHARDSON and JESSICA SULA",
  },
  {
    id: 903,
    name: "The Cannonball Run",
    year: 1981,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDJmZDRkMDAtM2JiNS00ZjdlLThiOTYtMWVhODJmYmY1MGEwXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "95 min",
    pairs: ".",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 904,
    name: "Indiana Jones and the Raiders of the Lost Ark",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjA0ODEzMTc1Nl5BMl5BanBnXkFtZTcwODM2MjAxNA@@._V1_SX300.jpg",
    rating: 6,
    year: 1981,
    synopsis:
      "A professor takes a sabbatical to fight Nazi's and find a destructive artifact. ",
    genre: "Adventure",
    subgenre: "Action",
    runtime: "115 min",
    pairs: "",
    noPairs: "shots til you drop.",
    drinkRecipe: "",
    rated: "PG",
    picture1: raiders1,
    picture2: raiders2,
    picture3: raiders3,
    tagline: "BLINDED BY THE LIGHT!",
    taglineWriter: "Riley Downs",
    bulletOne: "Killing at least one person in every country you visit.",
    bulletTwo:
      "Overcoming your fears. For a guy terrified of snakes, he's not very panicky around them.",
    cast: "Directed by STEVEN SPIELBERG starring HARRISON FORD  KAREN ALLEN  PAUL FREEMAN and JOHN RHYS-DAVIES",
  },

  {
    id: 905,
    name: "Outland",
    year: 1981,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGI2YWEyMGYtYWUwNy00MzkxLWE2MjMtMzUxMzhkZDFkMTI4XkEyXkFqcGdeQXVyNjUwNzk3NDc@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "Scifi",
    runtime: "109 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 906,
    name: "Indiana Jones and the Temple of Doom",
    year: 1984,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGI1NTk2ZWMtMmI0YS00Yzg0LTljMzgtZTg4YjkyY2E5Zjc0XkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "This time accompanied by a tiny chinese boy and a shrieking woman, Indy must find five rocks to rule the world.",
    genre: "Adventure",
    subgenre: "Action",
    runtime: "118 min",
    pairs: "Monkey Brains.",
    drinkRecipe: "Fruit punch, fireball.",
    rated: "PG",
    picture1: templeDoom1,
    picture2: templeDoom2,
    picture3: templeDoom3,
    tagline: "INDY IN INDIA IS INDESTRUCTABLE!",
    taglineWriter: "Slumdog Millionaire",
    bulletOne: "The darkest of the trilogy. That's right - TRILOGY.",
    bulletTwo: "Gratuitous grossout scenes with bugs, snakes, and guts.",
    cast: "Directed by STEVEN SPIELBERG starring HARRISON FORD KATE CAPSHAW KE HUY QUAN and AMRISH PURI",
  },
  {
    id: 907,
    name: "Indiana Jones and the Last Crusade",
    year: 1989,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjNkMzc2N2QtNjVlNS00ZTk5LTg0MTgtODY2MDAwNTMwZjBjXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Dr. Jones and the Nazi's go at it again in this movie that proves Indy will do anything to not actually teach. ",
    genre: "Adventure",
    subgenre: "Action",
    runtime: "127 min",
    pairs: "a Junior Mint.",
    drinkRecipe: "Ice 101, chocolate syrup.",
    rated: "PG-13",
    picture1: lastCrusade1,
    picture2: lastCrusade2,
    picture3: lastCrusade3,
    tagline: "INTER-NATIONAL TREASURE!",
    taglineWriter: "Cagey Nic",
    bulletOne:
      "Seriously ... he even climbed out a window during office hours.",
    bulletTwo: "Sean Connery is the man, as usual.",
    cast: "Directed by STEVEN SPIELBERG starring HARRISON FORD SEAN CONNERY ALISON DOODY and JULIAN GLOVER",
  },
  {
    id: 908,
    name: "Indiana Jones and the Kingdom of the Crystal Skull",
    year: 2008,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDIzNzM5MDUtZmI5MC00NGQ5LWFlNzEtYzE3ODIxNDI3ZmNhXkEyXkFqcGdeQXVyNjQ4ODE4MzQ@._V1_SX300.jpg",
    rating: 2,
    synopsis:
      "It's good to know if I'm ever in the immediate blast radius of a nuclear explosion my grandparents fridge can and will save my life.",
    genre: "Adventure",
    subgenre: "Action",
    runtime: "122 min",
    pairs: "cyanide.",
    rated: "PG-13",
    picture1: ijskull1,
    picture2: ijskull2,
    picture3: ijskull3,
    tagline: "Let's kill an icon for tons of money!",
    taglineWriter: "Spielberg & Lucas",
    bulletOne: "Shame LeDoof isn't acting",
    bulletTwo: "Not being considered canon by any fans",
    cast: "Directed by STEVEN SPIELBERG starring HARRISON FORD SHIA LABEOUF CATE BLANCHETT and RAY WINSTONE",
  },
  {
    id: 909,
    name: "48 Hrs.",
    year: 1982,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYWFkZjNkNmEtMTE5OC00MzA4LThkNWUtOWJlNjUxZGFiYzhmXkEyXkFqcGdeQXVyNTI4MjkwNjA@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "A crook-hating cop gets partnered up with a CONVICT to hunt down a murderer.",
    genre: "Action",
    subgenre: "Comedy",
    runtime: "96 min",
    pairs: "Ebony and Ivory.",
    drinkRecipe: "Kahlúa, Irish Cream.",
    rated: "R",
    picture1: fortyEightHours1,
    picture2: fortyEightHours2,
    picture3: fortyEightHours3,
    tagline: "Wish I had two days left.",
    taglineWriter: "Lorenzo Odone",
    bulletOne: "Super 80's suits in one of the first awesome buddy cop flicks.",
    bulletTwo:
      "Another movie where Sonny Landham goes shirtless while holding a huge knife.",
    cast: "Directed by WALTER HILL starring EDDIE MURPHY NICK NOLTE FRANK MCRAE and SONNY LANDHAM",
  },
  {
    id: 910,
    name: "Another 48 Hrs.",
    year: 1990,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTAzOTE4MzgwNV5BMl5BanBnXkFtZTgwNDgwNTg4NjE@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "93 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 911,
    name: "Conan the Barbarian",
    year: 1982,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWIxMzQxZjAtMGFkNC00NzYwLWFiMGEtNzZhZjE5MmFiMmMyL2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "Adventure",
    runtime: "129 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 912,
    name: "Conan the Destroyer",
    year: 1984,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDExOWE2N2YtN2QyMi00ZWI2LWE4MDAtZWJiNjg4MTI3NzI0L2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "Adventure",
    runtime: "103 min",
    pairs: "",
    rated: "PG",
    picture1: conanDestroyer1,
    picture2: conanDestroyer2,
    picture3: conanDestroyer3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by RICHARD FLEISCHER starring ARNOLD SCHWARZENEGGER GRACE JONES SARAH DOUGLAS and WILT CHAMBERLAIN",
  },
  {
    id: 913,
    name: "Death Wish",
    year: 1974,
    poster:
      "https://image.tmdb.org/t/p/w185_and_h278_bestv2//n0MJPBLSCtt7DBYZGFsOfQkZCWt.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "Crime",
    runtime: "94 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 914,
    name: "Death Wish II",
    year: 1982,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTU1MzU3ZTktMTEwZC00MGU3LWE1YTktY2RlMTcxYzFiYjFkL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "Crime",
    runtime: "89 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 915,
    name: "Death Wish 3",
    year: 1985,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNGE5MmNlMDktMmVmNC00M2VlLWJkNjItYzJjMzM4MDY5ODY4XkEyXkFqcGdeQXVyNjc1NTYyMjg@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "Crime",
    runtime: "92 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 916,
    name: "Death Wish 4: The Crackdown",
    year: 1987,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTNiZDhiMmQtZGRiOC00Yzg0LTkyMzUtZDBhOGQzMTIyYzQ3L2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "Crime",
    runtime: "99 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 917,
    name: "Death Wish V: The Face of Death",
    year: 1994,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTViYzMxMGItYzJlNC00MTc3LWI0MTAtNGM5NTJlMjUzODQ2L2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "Crime",
    runtime: "95 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 918,
    name: "10 to Midnight",
    year: 1983,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzc0OWVmZjQtMGUwZS00YjBiLTg5NmEtZWUyMTBjNWE2MThhXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A store-brand Ted Bundy murders women all over town with impunity ... until Bronson squeezes his way into the case.",
    genre: "Action",
    subgenre: "Crime",
    runtime: "101 min",
    pairs: "a Summary Execution.",
    drinkRecipe: "Whiskey, lemon juice, fresh egg",
    rated: "R",
    picture1: tenmidnight1,
    picture2: tenmidnight2,
    picture3: tenmidnight3,
    tagline: "I COULD FART OUT A BETTER HEADLINE!",
    taglineWriter: "Jerry",
    bulletOne:
      "A very crude, mechanized, and gross precursor to the fleshlight.",
    bulletTwo:
      "Eating a terrible lunch of coleslaw and quiche in an ulcer factory.",
    cast: "Directed by J. LEE THOMPSON starring CHARLES BRONSON LISA EILBACHER ANDREW STEVENS and GENE DAVIS",
  },
  {
    id: 919,
    name: "Blue Thunder",
    year: 1983,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmVmNWIzNWQtYmMwOC00MTc1LTkxN2UtNzhlYjg5YjZjMzA4XkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "Crime",
    runtime: "109 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 920,
    name: "Fearless Hyena Part II",
    year: 1983,
    poster:
      "https://image.tmdb.org/t/p/w185_and_h278_bestv2//A2NZsfAFuaYhDn93SuXM68Xe2g5.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "94 min",
    pairs: "",
    rated: "Not rated",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 921,
    name: "Lone Wolf McQuade",
    year: 1983,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTljMjVhYmYtZDc0Yi00OTAzLTk1NjctMDllZDEzNTc4MDQ1XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "War",
    runtime: "107 min",
    pairs: "",
    rated: "PG",
    picture1: loneWolf1,
    picture2: loneWolf2,
    picture3: loneWolf3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "Marie Schrader’s idea of a tough guy.",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 922,
    name: "Never Say Never Again",
    year: 1983,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTM1NjgzMDkwOF5BMl5BanBnXkFtZTcwMzM4NzI0NA@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "134 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 923,
    name: "Octopussy",
    year: 1983,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGRiMDAwNWYtYmQzNS00NjEyLWE1NjQtNDg5ZTJlMmRmMjFhXkEyXkFqcGdeQXVyMjUzOTY1NTc@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Thought I was settling in for an eight woman orgy, instead got stuck with Roger Moore's wrinkled brow.",
    genre: "Action",
    subgenre: "",
    runtime: "131 min",
    pairs: "Sex on the Beach.",
    drinkRecipe: "Vodka, Peach Schnapps, Orange Juice, Cranberry Juice",
    rated: "PG",
    picture1: octopussy1,
    picture2: octopussy2,
    picture3: octopussy3,
    tagline: "HITS ALL THE RIGHT SPOTS",
    taglineWriter: "Pussy Galore",
    bulletOne: "SPOILER ALERT: NOT PORN!",
    bulletTwo:
      "Originally titled Eightvagina but didn't quite roll off the tongue (more of a swirl).",
    cast: "Directed by JOHN GLEN starring ROGER MOORE MAUD ADAMS DESMOND LLEWELYN and KABIR BEDI",
  },
  {
    id: 924,
    name: "Project A",
    year: 1983,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNGYyZTkzYzctOWNlZC00NDc0LWI5ZDAtOGM1NDRhYjc4ZTRmXkEyXkFqcGdeQXVyNTcxNTIzNTU@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "105 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 925,
    name: "Sudden Impact",
    year: 1983,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTA2NzY5ZTgtNTY3Ni00YTc3LWFhN2ItY2Q4NzljYTRkZTIwXkEyXkFqcGdeQXVyMjUzOTY1NTc@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "117 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 926,
    name: "The Boxer's Omen",
    year: 1983,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWY3MjUzZDgtNTM3NC00ZWU4LTkzMjgtYWJkY2E1MWMyMjhkXkEyXkFqcGdeQXVyMjUyNDk2ODc@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Horror",
    subgenre: "",
    runtime: "99 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 927,
    name: "Uncommon Valor",
    year: 1983,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzlkNTM2NDItOGY1Yy00ODE1LWE5MzEtMTRjYmJlMzg3OTMxL2ltYWdlXkEyXkFqcGdeQXVyNjQzNDI3NzY@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "A team of ex-soldiers return to the jungles of Vietnam 10 years after the war to rescue POW's. In many ways this is the land version of Iron Eagle.",
    genre: "War",
    subgenre: "Action",
    runtime: "105 min",
    pairs: "a Golden Triangle.",
    drinkRecipe: "Tequila, orange juice, sprite, gold sanding sugar",
    rated: "R",
    picture1: uncommonValor1,
    picture2: uncommonValor2,
    picture3: uncommonValor3,
    tagline: "THESE FELLA'S AINT YELLA",
    taglineWriter: "Agent Orange",
    bulletOne:
      "When buying from an arms dealer, make sure he's a maniac with a bird on his shoulder.",
    bulletTwo:
      "Using Oriental Martial Bullshit on Tex Cobb gets real expensive.",
    cast: "Directed by TED KOTCHEFF starring GENE HACKMAN TEX COBB REB BROWN and FRED WARD",
  },
  {
    id: 928,
    name: "Heartbreak Ridge",
    year: 1986,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZWNiOTY4ZGUtYzM2Ny00ZTE2LThjZmEtNjI2MzA5NDRmNTczXkEyXkFqcGdeQXVyMjUzOTY1NTc@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Clint Eastwood is a mean, nasty, and tired drill instructor who eats concertina wire and pisses napalm.",
    genre: "War",
    subgenre: "Action",
    runtime: "130 min",
    pairs: "",
    noPairs: "PBR and a shot of Jack.",
    rated: "R",
    picture1: heartbreakRidge1,
    picture2: heartbreakRidge2,
    picture3: heartbreakRidge3,
    tagline: "Badass is as badass does",
    taglineWriter: "Forrest Gump",
    bulletOne: "The prequel to Gran Torino?",
    bulletTwo:
      "Gunney Highway has been pumpin' pussy since Christ was a corporal.",
    cast: "Directed by CLINT EASTWOOD starring CLINT EASTWOOD MARSHA MASON EVERETT MCGILL and MOSES GUNN",
  },
  {
    id: 929,
    name: "Cannonball Run II",
    year: 1986,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDUwMjQ4YmUtNjZhMi00YzQ2LWI1NjYtMmNiMTYyNzY5ODIzXkEyXkFqcGdeQXVyNzc5MjA3OA@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "108 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 930,
    name: "Firestarter",
    year: 1984,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzFiZTQ4ZjQtNjgyZi00Yjc0LWI2Y2YtNjk0YzM3NWEwYjYzXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "114 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 931,
    name: "Top Dog",
    year: 1995,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGNiODEzYTYtZDlkZi00Y2NmLWFhZmMtNzQ1NzY1YmJhOGIzXkEyXkFqcGdeQXVyNjMwMjk0MTQ@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "Turner & Hooch with fight scenes that are (K-)9 times more exciting. When it comes to this man/dog crime-fighting duo, the action is unleashed! (The movie is better than that joke).",
    genre: "Action",
    subgenre: "Comedy",
    runtime: "86 min",
    pairs: "a Salty Dog.",
    drinkRecipe: "Gin, grapefruit juice, salted rim.",
    rated: "PG-13",
    picture1: topDog1,
    picture2: topDog2,
    picture3: topDog3,
    tagline: "DOG WALKER TEXAS RANGER",
    taglineWriter: "PoochPerfect",
    bulletOne:
      "For the stoners out there (who else would watch?), the title backwards is 'GOD POT'",
    bulletTwo:
      "Norris somehow not getting dizzy after 5 successive roundhouses.",
    cast: "Directed by AARON NORRIS starring CHUCK NORRIS CLYDE KUSATSU KAI WULFF and MICHELE LAMAR RICHARDS",
  },
  {
    id: 932,
    name: "Turner & Hooch",
    year: 1989,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGYxNjdjMTItMmUwYy00NzNhLTk1NjctZWVkZTMzZTYzNjBmXkEyXkFqcGdeQXVyNzc5MjA3OA@@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Top Dog but with less exciting fight scenes and that one guy from Big.",
    genre: "Action",
    subgenre: "Comedy",
    runtime: "99 min",
    pairs: "",
    noPairs: "Miller High Life.",
    rated: "PG",
    picture1: turnerAndHooch1,
    picture2: turnerAndHooch2,
    picture3: turnerAndHooch3,
    tagline: "Plenty of bark AND bite!",
    taglineWriter: "Craig T. Nelson",
    bulletOne: "Seeing dogs drink beer is always funny.",
    bulletTwo:
      "Reginald VelJohnson brightens up absolutely everything he's in.",
    cast: "Directed by ROGER SPOTTISWOODE starring TOM HANKS MARE WINNINGHAM CRAIG T. NELSON and REGINALD VELJOHNSON",
  },
  {
    id: 933,
    name: "Wheels on Meals",
    year: 1984,
    poster:
      "https://image.tmdb.org/t/p/w185_and_h278_bestv2//i5iZVBfOqlRs4g50dm2G6YGfhYD.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "100 min",
    pairs: "",
    rated: "TV-14",
    picture1: wheelsMeals1,
    picture2: wheelsMeals2,
    picture3: wheelsMeals3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne:
      "Any time Jackie Chan and Benny Urquidez throw down, the plot doesn't even matter.",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 934,
    name: "Dragons Forever",
    year: 1988,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzI0NzQ4YWYtOTQ4Ni00ZGRhLWJlN2QtYjU2NDUxY2E2MTNkXkEyXkFqcGdeQXVyMjUyNDk2ODc@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "102 min",
    pairs: "",
    rated: "TV-14",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "Chan vs Urquidez fight scene might is absolutely incredible.",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 935,
    name: "A View to Kill",
    year: 1985,
    poster:
      "https://image.tmdb.org/t/p/w185_and_h278_bestv2//ieMTbSdYJJ7RZIW0X02NbwIAxMl.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "131 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 936,
    name: "American Ninja",
    year: 1985,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjcxMzc5ZWQtN2Y2OS00NGNiLWE4MDYtMzAyYWJkN2YyODliXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "95 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 937,
    name: "Invasion U.S.A",
    year: 1985,
    poster:
      "https://image.tmdb.org/t/p/w185_and_h278_bestv2//mE5dSRTKW1dzV7D0pgP8sMxyIa9.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "War",
    runtime: "110 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 938,
    name: "Ninja Terminator",
    year: 1986,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGZiNTczNWItOTdmYy00OTFjLWIwOWUtMmE3Y2QyNzBmZDJkXkEyXkFqcGdeQXVyNzg3NjQyOQ@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "88 min",
    pairs: "",
    rated: "Not Rated",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 939,
    name: "Police Story",
    year: 1985,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWJhZmI5ZWUtYjgyNy00NTk1LWIzODktYTE3NzQzNzJlOTgzXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "A supercop (calm down, we know not yet) must prove his innocence after being framed for murder.",
    genre: "Action",
    subgenre: "",
    runtime: "100 min",
    pairs: "shattered glass.",
    drinkRecipe: "vodka, sprite, crushed ice",
    rated: "PG-13",
    picture1: policeStory1,
    picture2: policeStory2,
    picture3: policeStory3,
    tagline: "THIS GUY IS 𝘼𝘽𝙊𝙑𝙀 𝙏𝙃𝙀 𝙇𝘼𝙒!",
    taglineWriter: "Steven Seagal",
    bulletOne:
      "Some of the best action scenes ever - and all practical effects.",
    bulletTwo: "Escaping a shantytown by smashing through it in your car.",
    cast: "Directed by JACKIE CHAN starring JACKIE CHAN MAGGIE CHEUNG BRIGITTE LIN and BILL TUNG",
  },
  {
    id: 940,
    name: "Police Story 2",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDZjOTYwMjAtOWQ5YS00MjVhLTlkMmEtMjUyYTczYTk1YjkyXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 5,
    year: 1988,
    synopsis:
      "Hotshot Ka Kui is back at it again, this time trying to prevent public bombings (even though it's probably one big hoax).",
    genre: "Action",
    subgenre: "",
    runtime: "101 min",
    pairs: "a Warehouse Explosion.",
    drinkRecipe: "Fireball, Jalapeno vodka, coke. Serve over ice.",
    rated: "R",
    picture1: policeStory21,
    picture2: policeStory22,
    picture3: policeStory23,
    tagline: "NAB THE CROOK BY THE BOOK",
    taglineWriter: "Officer Bookman",
    bulletOne: "Lite-Brite™ computer GPS tracking system.",
    bulletTwo: "Was hoping for more traffic cop.",
    cast: "Directed by JACKIE CHAN starring JACKIE CHAN MAGGIE CHEUNG KWOK-HUNG LAM and BILL TUNG",
  },
  {
    id: 941,
    name: "Supercop",
    year: 1992,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNWJiMzMzMjAtNmIxZi00Yjk3LWFlYmItNzNiMGZmYjE0NWJlXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "It's true - he's the cop that can't be stopped. From Hong Kong to China to Malaysia, Ka Kui will do anything to bust a drug smuggling operation.",
    genre: "Action",
    subgenre: "",
    runtime: "91 min",
    pairs: "a Rope Ladder.",
    drinkRecipe: "Fireball and orange juice served over ice.",
    rated: "R",
    picture1: supercop1,
    picture2: supercop2,
    picture3: supercop3,
    tagline: "THE COP THAT CAN'T BE STOPPED!",
    taglineWriter: "Nick Frost",
    bulletOne:
      "A noticeably different vibe (and 30 minutes shorter) than the first two.",
    bulletTwo:
      "Tarantino said this movie has 'the greatest stunts ever filmed in any movie ever'.",
    cast: "Directed by STANLEY TONG starring JACKIE CHAN MICHELLE YEOH WAH YUEN and KENNETH TSANG",
  },
  {
    id: 942,
    name: "Big Trouble in Little China",
    year: 1985,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzlhYjEzOGItN2MwNS00ODRiLWE5OTItYThiNmJlMTdmMzgxXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "Cult Classic",
    runtime: "99 min",
    pairs: "",
    rated: "PG-13",
    picture1: bigTrouble1,
    picture2: bigTrouble2,
    picture3: bigTrouble3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by JOHN CARPENTER starring KURT RUSSELL KIM CATTRALL DENNIS DUN and JAMES HONG",
  },
  {
    id: 943,
    name: "Universal Solider",
    year: 1992,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTdmYzQ1YzYtMjFlYi00NGU2LWE2YTEtZDljMWNmYzBhMzFhXkEyXkFqcGc@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "102 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 944,
    name: "Soldier",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDI1NmM5ZjItNGI4NC00OWI5LWI3ZTgtNGViMWNmNjI5OTY4XkEyXkFqcGdeQXVyMTM2MzAwOA@@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "After his escape, Snake takes on a team of futuristic super-soldiers with some help from garbage people.",
    genre: "Action",
    subgenre: "",
    runtime: "99 min",
    pairs: "Trash Planet.",
    drinkRecipe: "(green Gatorade, vodka, and Redbull.",
    rated: "R",
    picture1: soldier1,
    picture2: soldier2,
    picture3: soldier3,
    tagline: "To say this movie is GREAT' isn't going Overboard!",
    taglineWriter: "Bronzie Dawn",
    bulletOne: "Beefcake Kurt Russell",
    bulletTwo: "Sleeper hit that just won't quit",
    cast: "Directed by PAUL W.S. ANDERSON starring KURT RUSSELL JASON SCOTT LEE CONNIE NIELSEN and JASON ISAACS",
  },
  {
    id: 945,
    name: "Cobra",
    year: 1986,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOWZhOWVmMzctY2RjMi00YzkxLTkwOGQtODA2MDk1MDg1ZWVlXkEyXkFqcGdeQXVyMDEwMjgxNg@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "87 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 946,
    name: "The Last American Virgin",
    year: 1982,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNGZlZGYzMGEtYzdjOS00ZjljLWI5NDAtNmMxODYyOWM2MjdmXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "80's sex comedy. Expect funny/lame jokes, tits, and losers trying to get laid. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "92 min",
    pairs: "a Shirley Temple (but add booze).",
    rated: "R",
    picture1: lastVirgin1,
    picture2: lastVirgin2,
    picture3: lastVirgin3,
    tagline: "Don't worry about the going, but the coming",
    taglineWriter: "McLovin",
    bulletOne: "Surprisingly well made for a Cannon film.",
    bulletTwo:
      "One of the best taglines of any movie ever: 'See it or be it'. After that, are you really gonna risk not watching it? ",
    cast: "Directed by BOAZ DAVIDSON starring LAWRENCE MONOSON DIANE FRANKLIN STEVE ANTIN and JOE RUBBO",
  },
  {
    id: 947,
    name: "Firewalker",
    year: 1986,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOGNjYjBhYmItMDI3Ni00MWM2LWE2ZDgtY2U5MGNjNDVmYTc1XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "105 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 948,
    name: "Iron Eagle",
    year: 1986,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGY3MWIzZGYtOTliMy00YzQzLTgwNjgtNjlkYTA2Y2Y5YTVkL2ltYWdlXkEyXkFqcGdeQXVyNjQzNDI3NzY@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "If two time All Valley Karate Tournament Champion Johnny Lawrence loves it, it's gotta be good.",
    genre: "Action",
    subgenre: "War",
    runtime: "117 min",
    pairs: "Unlicensed Pilot.",
    drinkRecipe: "Whiskey and RC Cola.",
    rated: "PG-13",
    picture1: ironEagle1,
    picture2: ironEagle2,
    picture3: ironEagle3,
    tagline: "It's awesome - plane and simple.",
    taglineWriter: "Howard Hughes",
    bulletOne:
      "Flying a jet to the Middle East despite being a highschooler not in the Air Force.",
    bulletTwo:
      "80's jams giving you the ability to pull off precision bomb strikes. ",
    cast: "Directed by SIDNEY J. FURIE starring LOUIS GOSSETT JR. JASON GEDRICK LARRY B. SCOTT and TIM THOMERSON",
  },
  {
    id: 949,
    name: "Collision Course",
    year: 1989,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmE3ZDlkMWQtODE2MC00NDJjLTgwYmEtMTFjZThlMDIxZDNhXkEyXkFqcGdeQXVyMjAxMjEzNzU@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Instead of noses, Mr. Myagi is honking car horns in this high-octane caper about a Japanese motor prototype gone missing.",
    genre: "Action",
    subgenre: "Comedy",
    runtime: "100 min",
    pairs: "a Turbocharger.",
    drinkRecipe: "Kahlua, coffee, redbull, vodka.",
    rated: "PG",
    picture1: collisionCourse1,
    picture2: collisionCourse2,
    picture3: collisionCourse3,
    tagline: "The jokes definitely collided with a wall!",
    taglineWriter: "Crash Test Dummies",
    bulletOne:
      "Jay Leno absolutely CANNOT break down a door on his first try. ",
    bulletTwo: "Dry humping Pat Morita to safety.",
    cast: "Directed by LEWIS TEAGUE starring JAY LENO PAT MORITA ERNIE HUDSON and JOHN HANCOCK",
  },
  {
    id: 950,
    name: "No Retreat, No Surrender",
    year: 1986,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjQxOWM0YjgtNGRlMC00ZGQzLWJkZTUtNjU4NjZhNDE0Njk4XkEyXkFqcGdeQXVyMjY3MjUzNDk@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "85 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 951,
    name: "The Delta Force",
    year: 1986,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTM3NzY5MTg3MV5BMl5BanBnXkFtZTcwMjAwODM2NA@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "War",
    runtime: "125 min",
    pairs: "",
    rated: "R",
    picture1: deltaForce1,
    picture2: deltaForce2,
    picture3: deltaForce3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by MENAHEM GOLAN starring CHUCK NORRIS LEE MARVIN ROBERT FORSTER and GEORGE KENNEDY",
  },
  {
    id: 952,
    name: "Raw Deal",
    year: 1986,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTVkNWIwZmYtMzM5MS00ZTcwLWI0ZjEtNTVlMTNjZWViNzliXkEyXkFqcGdeQXVyNTIzOTk5ODM@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "106 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 953,
    name: "The Golden Child",
    year: 1986,
    poster:
      "https://m.media-amazon.com/images/M/MV5BN2E2MWJhZjEtMDdmOS00MmFkLWJlNWEtNWQ0NjA0NzIwNjRkXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Action",
    runtime: "94 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 954,
    name: "Commando",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZWE0ZjFhYjItMzI5MC00MDllLWE4OGMtMzlhNGQzN2RjN2MwXkEyXkFqcGdeQXVyNDc2NjEyMw@@._V1_SX300.jpg",
    rating: 5,
    year: 1985,
    synopsis:
      "The bodies pile high as Arnie blasts his way to his kidnapped daughter. The perfect movie to watch when you want to let out some steam.",
    genre: "Action",
    subgenre: "",
    runtime: "90 min",
    pairs: "",
    noPairs: "a Vodka Red Bull -- you don't want to be dead tired.",
    rated: "R",
    picture1: commando1,
    picture2: commando2,
    picture3: commando3,
    tagline: "Schwarzenegger VS Fat Freddie Mercury",
    taglineWriter: "Bovine Joni",
    bulletOne:
      "Selecting a chainmail-wearing doughboy to match Arnold's physicality.",
    bulletTwo: "Fun Fact: Arnie filmed this entire movie with no underwear.",
    cast: "Directed by MARK L. LESTER starring ARNOLD SCHWARZENEGGER  RAE DAWN CHONG  VERNON WELLS and ALYSSA MILANO",
  },
  {
    id: 955,
    name: "Highlander",
    year: 1986,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjc3YmU3MzQtNTA4OC00ZjljLWFmODAtZDU1YzY5ZTNkZDU3XkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "Adventure",
    runtime: "116 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 956,
    name: "American Ninja 2: The Confrontation",
    year: 1987,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGE5ZDU2MjctZTQ0NS00MDIwLThlZmItZGUwMjMwYzcwNTJjXkEyXkFqcGdeQXVyMjMwNDgzNjc@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "90 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 957,
    name: "Masters of the Universe",
    year: 1987,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzRlMzQzNDEtYTg5My00NTFjLWFiYzYtMjJkMzUyYzJjMzgyXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Scifi",
    subgenre: "Action",
    runtime: "106 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 958,
    name: "RoboCop",
    year: 1987,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZWVlYzU2ZjQtZmNkMi00OTc3LTkwZmYtZDVjNmY4OWFmZGJlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "Scifi",
    runtime: "102 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 959,
    name: "RoboCop 2",
    year: 1990,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNmFiNjVlMTctODE3Zi00NWIxLWFlOTctMjQ1NWFhNzI0MTkwXkEyXkFqcGdeQXVyMjY3MjUzNDk@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "Scifi",
    runtime: "117 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 960,
    name: "RoboCop 3",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzdiMTkwYWUtM2RlYi00NThlLWI5OWYtY2Y3ZjY1MDg5OTMyXkEyXkFqcGdeQXVyNTIzOTk5ODM@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "Scifi",
    runtime: "104 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 961,
    name: "The Running Man",
    year: 1987,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWU4NzA2OWYtNGQ0MS00YWNkLTg4M2YtZjlkZmY1YmJjMDE4XkEyXkFqcGdeQXVyNDc2NjEyMw@@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Arnie got to the choppa but is thrown in jail for not using it to kill civilians. Not wanting to stick around, he escapes from prison and is thrust into a kill or be killed TV show. ",
    genre: "Scifi",
    subgenre: "Action",
    runtime: "101 min",
    pairs: "a Usain Bolt.",
    drinkRecipe: "Gold Tequila, Monster.",
    rated: "R",
    picture1: runningMan1,
    picture2: runningMan2,
    picture3: runningMan3,
    tagline: "He doesn't even run that much",
    taglineWriter: "Micraze Lightning",
    bulletOne:
      "Featuring the most badass dude in cinema history - catch it at 1:17:58.",
    bulletTwo:
      "Warhol's quote is true: in the future, everyone will have a Court Appointed Theatrical Agent.",
    cast: "Directed by PAUL MICHAEL GLASER starring ARNOLD SCHWARZENEGGER JESSE VENTURA JIM BROWN and PROFESSOR TORU TANAKA",
  },
  {
    id: 962,
    name: "Short Circuit",
    year: 1987,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjU0YzBhMDQtYmZkZC00NWFiLTkyMDUtZmFhNjk0NGQ5MjFlXkEyXkFqcGdeQXVyMTY4MjE1MDA@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Scifi",
    subgenre: "",
    runtime: "98 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 963,
    name: "Short Circuit 2",
    year: 1988,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjI3OTg5YmYtMWJkOC00OTI0LWI1MTAtMzU3OGYzMWJhYTBmXkEyXkFqcGdeQXVyNzEyNjE5NzM@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Scifi",
    subgenre: "",
    runtime: "110 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 964,
    name: "Black Eagle",
    year: 1988,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNWJiNGNhYTItMzI5NC00MDIzLWFkYTQtZDA1NDdiNjVhY2NjXkEyXkFqcGdeQXVyNDc2NjEyMw@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "93 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 965,
    name: "Missing in Action",
    year: 1984,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGRkY2M1YWMtYmZiMy00NDMzLTg0OTctYWNmNzllMGNlYmMyXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "War",
    runtime: "101 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 966,
    name: "Missing in Action 2: The Beginning",
    year: 1985,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTUzODkyODg4Ml5BMl5BanBnXkFtZTcwOTQ2NjE2NA@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "War",
    runtime: "100 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 967,
    name: "Braddock: Missing in Action 3",
    year: 1988,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTRjODU0MTUtMjBmMi00ZTBmLTk2MDYtOWQzYjg1M2FiZDJiXkEyXkFqcGdeQXVyMjY3MjUzNDk@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "War",
    runtime: "101 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 968,
    name: "American Ninja 3: Blood Hunt",
    year: 1989,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWZiODgwMTYtZDMzZC00MzBmLTg3OTEtOGE4NGQxOWM0NGE4XkEyXkFqcGdeQXVyMTQ2MjQyNDc@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "89 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 969,
    name: "Black Rain",
    year: 1989,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTkwMDA2MmQtODI1MS00MGI5LWE5YTktMTUzYzY4ZDZjMmQ3XkEyXkFqcGdeQXVyMjY3MjUzNDk@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "125 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 970,
    name: "Ghostbusters",
    year: 1984,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTkxMjYyNzgwMl5BMl5BanBnXkFtZTgwMTE3MjYyMTE@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "105 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 971,
    name: "Ghostbusters II",
    year: 1989,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQ2NTk4MjE5Ml5BMl5BanBnXkFtZTgwODIwNjYxMTE@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Comedy",
    subgenre: "",
    runtime: "108 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 972,
    name: "Kickboxer",
    year: 1989,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGYzZDQ2ZWItNjE4ZS00ZTgzLTk3ZjctZjAwZDUyM2I4Nzg5L2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTYxNjkxOQ@@._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "97 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 973,
    name: "Kinjite: Forbidden Subjects",
    year: 1989,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGVjNjk0NTktYzViNS00M2ZmLTgxMWMtM2YxNDUwZmZjY2Q5XkEyXkFqcGdeQXVyMjUzOTY1NTc@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A detective will do anything to bust street-hustlin' pimps ... especially if he has to work outside the system.",
    genre: "Action",
    subgenre: "Crime",
    runtime: "97 min",
    pairs: "good balance.",
    drinkRecipe: "a drink in one hand, a breast in the other.",
    rated: "R",
    picture1: forbiddensubjects1,
    picture2: forbiddensubjects2,
    picture3: forbiddensubjects3,
    tagline: "COPPING A FEEL IS NO BIG DEAL",
    taglineWriter: "Every police officer",
    bulletOne: "Shooting gasoline to ignite a flame.",
    bulletTwo: "Forcing lowdown criminal scum to eat their own flashy jewelry.",
    cast: "Directed by J. LEE THOMPSON starring CHARLES BRONSON PERRY LOPEZ JUAN FERNANDEZ and JAMES PAX",
  },
  {
    id: 974,
    name: "Next of Kin",
    year: 1989,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZmQ0NTQwYWMtMTA1MC00YWRkLTgwMGMtODJkODFhZGU0NmY0XkEyXkFqcGdeQXVyMTUzMDUzNTI3._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "Crime",
    runtime: "108 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 975,
    name: "Renegades",
    year: 1989,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWYxZjI2YzEtM2ExOC00YjhhLWE1MjctYjkyMmQyNDY3MzA1XkEyXkFqcGdeQXVyNzc5MjA3OA@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "Crime",
    runtime: "106 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 976,
    name: "Tango & Cash",
    year: 1989,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTBhYjZjNDktZDE5NC00M2I1LWEzYmEtOGU2ZGRlYjdjMmNlXkEyXkFqcGdeQXVyMTUzMDUzNTI3._V1_SX300.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "Crime",
    runtime: "104 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 977,
    name: "The Punisher",
    year: 1989,
    poster:
      "https://image.tmdb.org/t/p/w185_and_h278_bestv2//tA0O0hYyHX7Hl2Fl8VEtGtfBtjI.jpg",
    rating: 4,
    synopsis: "",
    genre: "Superhero",
    subgenre: "Action",
    runtime: "92 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 978,
    name: "Air America",
    year: 1990,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDRiNzdlYzUtNzIxMy00OGI4LThlNjQtNTI3Njg5NjVlOWJkL2ltYWdlXkEyXkFqcGdeQXVyNjQzNDI3NzY@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "113 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 979,
    name: "American Ninja 4: The Annihilation",
    year: 1990,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjA4OTY1ZDgtMDlhOS00YmYwLWJjZDYtODk1MjJkNDI4MmNmXkEyXkFqcGdeQXVyODkxMTU1Njc@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "99 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 980,
    name: "Bird on a Wire",
    year: 1990,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzU5MTQyNzEwNV5BMl5BanBnXkFtZTgwMTgwNTg4NjE@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "110 min",
    pairs: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 981,
    name: "Darkman",
    year: 1990,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTc5MzUxMjk4NF5BMl5BanBnXkFtZTgwNTEzNDk4NjE@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Scifi",
    subgenre: "Thriller",
    runtime: "96 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "vosloo shumadu",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 982,
    name: "Darkman 2: The Return of Durant",
    year: 1995,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzczZDdhYWEtZDIxNi00NWMzLThiYWQtNTA4YmFkOTk3OTJhXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "Imhotep dons a new set of rags to fight an old enemy.",
    genre: "Scifi",
    subgenre: "Thriller",
    runtime: "93 min",
    pairs: "a Vigilante.",
    drinkRecipe: "Mtn. Dew code red, Blue Curaçao, vodka.",
    rated: "R",
    picture1: darkman21,
    picture2: darkman22,
    picture3: darkman23,
    tagline: "JUST CUT HIS HEART OUT!",
    taglineWriter: "Dr. Giggles",
    bulletOne: "Not the worst special effects for '95.",
    bulletTwo: "The hero we didn't want or need... twice. ",
    cast: "Directed by BRADFORD MAY starring LARRY DRAKE ARNOLD VOSLOO KIM DELANEY and RENEE O'CONNOR",
  },
  {
    id: 983,
    name: "Delta Force 2: The Colombian Connection",
    year: 1990,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNmIwZmM0MzItMjc4YS00Yjk2LTg4YzUtMDI2ODFiMDIxMzYyXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "War",
    runtime: "111 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 984,
    name: "Lionheart",
    year: 1990,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDA4ZDVlOWMtNzAzMi00YmYwLTljYjctYzQ1MzcyOWZmYzdmXkEyXkFqcGdeQXVyMjUzOTY1NTc@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Van Damme's a soldier & martial artist. Sounds like Street Fighter-- it's not. OK his name's almost the same (Guile vs Gaultier). But in this go-around JCVD fights opponents of diverse backgrounds to save people he loves. ",
    genre: "Action",
    subgenre: "",
    runtime: "108 min",
    pairs: "a Tigerbrain.",
    drinkRecipe: "OJ, Cointreau.",
    rated: "R",
    picture1: lionheart1,
    picture2: lionheart2,
    picture3: lionheart3,
    tagline: "I watched twice. It made a Double Impact",
    taglineWriter: "Bolo Yeung",
    bulletOne: " Ok ... this is Street Fighter. ",
    bulletTwo: "Van Damme almost killed by a Damn Van.",
    cast: "Directed by SHELDON LETTICH starring JEAN CLAUDE VAN DAMME HARRISON PAGE DEBORAH RENNARD and BRIAN THOMPSON",
  },
  {
    id: 985,
    name: "Marked for Death",
    year: 1990,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTEzOTNhODMtOTA5NC00YWM2LWJmYTEtOTAyNDVjZWQ3OTUzXkEyXkFqcGdeQXVyNjQ2MjQ5NzM@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Instead of enjoying retirement, DEA agent John Hatcher becomes the target of evil voodoo from the most stereotypical Jamaicans this side of the islands. ",
    genre: "Action",
    subgenre: "Crime",
    runtime: "93 min",
    pairs: "",
    noPairs: "Red Stripe",
    rated: "R",
    picture1: markedForDeath1,
    picture2: markedForDeath2,
    picture3: markedForDeath3,
    tagline: "I guess you could say he's a 𝙃𝙖𝙧𝙙 𝙏𝙖𝙧𝙜𝙚𝙩.",
    taglineWriter: "Jean Claude Van Damme",
    bulletOne: "These bombaclat's should've stayed on the bobsled team.",
    bulletTwo:
      "Hearing the 'shing' of unsheathing as Seagal picks up a samurai sword from the carpet.",
    cast: "Directed by DWIGHT H. LITTLE starring STEVEN SEAGAL JOANNA PACULA BASIL WALLACE and KEITH DAVID",
  },
  {
    id: 986,
    name: "Navy SEALS",
    year: 1990,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDRlZjgwYTAtMDM2Zi00YzU3LTkzZWQtMjdkZjU3ODEzNTliXkEyXkFqcGdeQXVyMjY3MjUzNDk@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "War",
    runtime: "113 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 987,
    name: "Predator 2",
    year: 1990,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZmVlNGZjNTktMTQ0Yi00MjkxLWFkYjQtMmJiOTZhNmNiNWJlXkEyXkFqcGdeQXVyMTcwOTQzOTYy._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Danny Glover may be too old for catching criminals, but super-predators from outer space? He's just the right age for that shit. ",
    genre: "Action",
    subgenre: "Scifi",
    runtime: "108 min",
    pairs: "a Prey.",
    drinkRecipe: "SoCo and lemonade.",
    rated: "R",
    picture1: predator21,
    picture2: predator22,
    picture3: predator23,
    tagline: "THIS IS A PREDATOR YOU HAVE TO CATCH!",
    taglineWriter: "Chris Hansen",
    bulletOne: "Calling the predator a pussyface.",
    bulletTwo:
      "Even though there's no jungle or anyone from the original cast, this is an awesome sequel. Really enjoyable for it's wackiness.",
    cast: "Directed by STEPHEN HOPKINS starring DANNY GLOVER GARY BUSEY KEVIN PETER HALL and BILL PAXTON",
  },
  {
    id: 988,
    name: "The Hunt for Red October",
    year: 1990,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDdkODg3YzctMGVmZS00ZDM5LWIzNTctZDRlMjA5Mjc4MGViXkEyXkFqcGdeQXVyODE5NzE3OTE@._V1_SX300.jpg",
    rating: 6,
    synopsis: "",
    genre: "Action",
    subgenre: "War",
    runtime: "135 min",
    pairs: "",
    rated: "PG",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 989,
    name: "The Rookie",
    year: 1990,
    poster:
      "https://image.tmdb.org/t/p/w185_and_h278_bestv2//pr37i12Qe1gGxMBf6GTIDZvfFIT.jpg",
    rating: 4,
    synopsis: "",
    genre: "Sports",
    subgenre: "",
    runtime: "120 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 990,
    name: "Total Recall",
    year: 1990,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzU1YmJjMGEtMjY4Yy00MTFlLWE3NTUtNzI3YjkwZTMxZjZmXkEyXkFqcGdeQXVyNDc2NjEyMw@@._V1_SX300.jpg",
    rating: 6,
    synopsis: "I'm having trouble remembering this movie.",
    genre: "Scifi",
    subgenre: "Action",
    runtime: "113 min",
    pairs: "a Coke on Earth or a Pepsi on Mars.",
    drinkRecipe: "either way, throw in some whiskey.",
    rated: "R",
    picture1: totalRecall1,
    picture2: totalRecall2,
    picture3: totalRecall3,
    tagline: "ACTION NEVER HITS THE BRAKES!",
    taglineWriter: "Toyota Recall",
    bulletOne: "The source of spousal trust issues.",
    bulletTwo: "Hoping for the directors cut X-rated violence.",
    cast: "Directed by PAUL VERHOEVEN starring ARNOLD SCHWARZENEGGER SHARON STONE MICHAEL IRONSIDE and MEL JOHNSON JR",
  },
  {
    id: 991,
    name: "Backdraft",
    year: 1991,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZTFjOWVkNjUtYTdkOC00MGQ5LWI5OTQtMTIyYzQwN2UzMDgzXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "Drama",
    runtime: "137 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 992,
    name: "Once Upon a Time in China",
    year: 1991,
    poster:
      "https://image.tmdb.org/t/p/w185_and_h278_bestv2//6oBrdOS9E1WNPaRKcRDxx7F2df1.jpg",
    rating: 5,
    synopsis: "",
    genre: "Action",
    subgenre: "",
    runtime: "134 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 993,
    name: "Out for Justice",
    year: 1991,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzAzMTU0YWEtYzE2ZC00OGQyLWJhNjEtZDY1Y2YzYzBiZDIxXkEyXkFqcGdeQXVyMDEwMjgxNg@@._V1_SX300.jpg",
    rating: 3,
    synopsis:
      "Armed with his flawless accent and speed-ramped fists a born and bred Brooklynite, Gino Fileno, hunts the madman who killed his best friend. ",
    genre: "Action",
    subgenre: "",
    runtime: "91 min",
    pairs: "",
    noPairs: "a Brooklyn Lager.",
    rated: "R",
    picture1: out4just1,
    picture2: out4just2,
    picture3: out4just3,
    tagline: "THIS COP'S IN FOR VENGEANCE!",
    taglineWriter: "Johnclaude Von Dayum",
    bulletOne: "Being kinda, sorta, friends with the mob.",
    bulletTwo: "Wearing bigger shoulder pads than Mike Singletary.",
    cast: "Directed by JOHN FLYNN starring STEVEN SEAGAL  WILLIAM FORSYTHE  JERRY ORBACH and GINA GERSHON",
  },
  {
    id: 994,
    name: "Point Break",
    year: 1991,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNWVjZWFmYjItZGJlOC00YTllLWE4YjctMWY2ZTg5ZjE0MDIyXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "A tenured psycho and FBI newbie track down former heads of state hard up on cash.",
    genre: "Action",
    subgenre: "Crime",
    runtime: "122 min",
    pairs: "a beach bum.",
    drinkRecipe: "Malibu rum, pink lemonade, sugar rim.",
    rated: "R",
    picture1: pointbreak1,
    picture2: pointbreak2,
    picture3: pointbreak3,
    tagline: "GO TO THE BREAKING POINT!",
    taglineWriter: "James Dalton",
    bulletOne: "Exploiting a family tragedy to get laid.",
    bulletTwo: "Catchin' gnarly swells on company time, dude.",
    cast: "Directed by KATHERYN BIGELOW starring PATRICK SWAYZE  KEANU REEVES  LORI PETTY and GARY BUSEY",
  },
  {
    id: 995,
    name: "Riki-Oh: The Story of Ricky",
    year: 1991,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZWY4YTE2YWEtYzg0ZS00MDk2LWFkNzMtY2M1ODcxMDkyNDkyXkEyXkFqcGdeQXVyMjQwMjk0NjI@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "A man with superhuman strength and fighting ability finds new and inventive ways to murder criminal lowlives in this extremely graphic Hong Kong Category III movie.",
    genre: "Action",
    subgenre: "Cult Classic",
    runtime: "91 min",
    pairs: "a Kill Count.",
    drinkRecipe: "Sangria, Rum, Sprite.",
    rated: "R",
    picture1: riki1,
    picture2: riki2,
    picture3: riki3,
    tagline: "I'VE SEEN WORSE PRISONS",
    taglineWriter: "San Quentin Tarantino",
    bulletOne: "Unquenchable homicidal mania.",
    bulletTwo:
      "Hysterically over-the-top violence. Truly visionary filmmaking.",
    cast: "Directed by NGAI CHOI LAM starring SIU-WONG FAN MEI SHENG FAN KA-KUI HO and YUKARI OSHIMA",
  },
  {
    id: 996,
    name: "The Hard Way",
    year: 1991,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzkyZWRiMTEtNmZhNy00NjI3LWE2ZDktM2UzYjkyNDBiMmE4XkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Comedy",
    subgenre: "Crime",
    runtime: "111 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by JOHN BADHAM starring MICHAEL J FOX JAMES WOODS STEPHEN LANG and ANNABELLA SCIORRA",
  },
  {
    id: 997,
    name: "Under Siege",
    year: 1992,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZWYxMDZkNmMtZjljNS00MDE5LTlmYmItMmVjZWU5NjQwYmI0L2ltYWdlXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "'Just a cook' Steven Seagal throws everything and the kitchen sink at hijacking mercenaries. ",
    genre: "Action",
    subgenre: "",
    runtime: "103 min",
    pairs: "a Sinking Ship.",
    drinkRecipe: "Kraken, Coke.",
    rated: "R",
    picture1: underSiege1,
    picture2: underSiege2,
    picture3: underSiege3,
    tagline: "Steven put the craft service table under siege",
    taglineWriter: "Jean Claude Canned Ham",
    bulletOne:
      "Seagal must've became a chef in real life. Clearly he hasn't stopped eating since.",
    bulletTwo:
      "Steven's punch must've scrambled Busey's brain. He hasn't been sane since. ",
    cast: "Directed by ANDREW DAVIS starring STEVEN SEAGAL TOMMY LEE JONES GARY BUSEY and ERIKA ELENIAK",
  },
  {
    id: 998,
    name: "Under Siege 2: Dark Territory",
    year: 1995,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZWFjNzhjZTYtMmYxOS00YTBhLTkxM2YtYTVjMGQwYzMzYWU1XkEyXkFqcGdeQXVyMTUzMDUzNTI3._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "The simple chef's outta the boat and onto a train. He's preparing a banquet of pain, and Everett McGill's razor-sharp cheekbones will easily slice the cold cuts.",
    genre: "Action",
    subgenre: "",
    runtime: "100 min",
    pairs: "a Stealth Bomber.",
    drinkRecipe: "Bacardi 151, Coke.",
    rated: "R",
    picture1: underSiege21,
    picture2: underSiege22,
    picture3: underSiege23,
    tagline: "Hijack a train for ransom? That's a loco motive",
    taglineWriter: "Thomas the Choo-Choo",
    bulletOne: "Connecting an ethernet to your Palm Pilot.",
    bulletTwo:
      "Seagals 'no one beats me in the kitchen' one-liner. These days, it's 'no one beats me TO the kitchen'.",
    cast: "Directed by GEOFF MURPHY starring STEVEN SEAGAL EVERETT MCGILL ERIC BOGOSIAN and KATHERINE HEIGL",
  },
  {
    id: 999,
    name: "American Yakuza",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTcyODM4NjI2Ml5BMl5BanBnXkFtZTcwNTQyODQyMQ@@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Action",
    subgenre: "Crime",
    runtime: "96 min",
    pairs: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "",
  },
  {
    id: 1000,
    name: "Cliffhanger",
    year: 1993,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYWE4MTYxMDYtMTgzNi00NWNjLTk5NjItMmRkNDU3N2FiMTczXkEyXkFqcGdeQXVyMjMwNDgzNjc@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A US Treasury Agent with a horrible plan gets himself, his partner, and their team stranded on a mountain. A local rescue team must fight high winds, thick snows, and bad grips to save them.",
    genre: "Action",
    subgenre: "Adventure",
    runtime: "113 min",
    pairs: "a Harmonious Ending.",
    drinkRecipe: "Hot coco, Kahlúa, marshmallows, cinnamon stick.",
    rated: "R",
    picture1: cliffhanger1,
    picture2: cliffhanger2,
    picture3: cliffhanger3,
    tagline: "A HOOK WOULD'VE COME IN HANDY!",
    taglineWriter: "Merle Dixon",
    bulletOne: "Stallone's Lip-hanger.",
    bulletTwo: "Bunny = 1 : Travers = -100. Stab-actite while spelunking",
    cast: "Directed by RENNY HARLIN starring SYVESTER STALLONE  JOHN LITHGOW  JANINE TURNER and MICHAEL ROOKER",
  },
  {
    id: 1001,
    name: "Hunt for the Wilderpeople",
    year: 2016,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjI1MDQ2MDg5Ml5BMl5BanBnXkFtZTgwMjc2NjM5ODE@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "A fat boy and a molesterer wander the magestical New Zealand brush while being pursued by real police with guns.",
    genre: "Comedy",
    subgenre: "Adventure",
    runtime: "101 min",
    pairs: "",
    noPairs: "an Epic Pale Ale",
    rated: "PG-13",
    picture1: wilderPeople1,
    picture2: wilderPeople2,
    picture3: wilderPeople3,
    tagline:
      "After careful consideration, I've decided to definitely endorse this movie!",
    taglineWriter: "Dr. Alan Grant",
    bulletOne: "Fantastic soundtrack",
    bulletTwo: "No child getting left behind.",
    cast: "Directed by TAIKA WAITITI starring SAM NEILL JULIAN DENNISON RACHEL HOUSE and TIOREORE NGATAI-MELBOURNE",
  },
  {
    id: 1002,
    name: "Pirates of the Caribbean: On Stranger Tides",
    year: 2011,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjE5MjkwODI3Nl5BMl5BanBnXkFtZTcwNjcwMDk4NA@@._V1_SX300.jpg",
    rating: 3,
    synopsis:
      "Oh.. Orlando Bloom isn’t in this one? Is Keira Knightley at least-- No, not her either. I guess they're off the deep end as Cap'n Jack 'n crew attempt to find the Fountain of Youth.",
    genre: "Adventure",
    subgenre: "Action",
    runtime: "136 min",
    pairs: "",
    noPairs: "rum, if it's not all gone.",
    rated: "PG-13",
    picture1: piratesCarb1,
    picture2: piratesCarb2,
    picture3: piratesCarb3,
    tagline: "At this point, I get paid in eyeliner",
    taglineWriter: "Donnie Jepp",
    bulletOne:
      "If they need the cup for the ritual, wouldn't that mean the Fountain was already found?",
    bulletTwo: "Never mind, they've given up trying so should we.",
    cast: "Directed by ROB MARSHALL starring JOHNNY DEPP PENELOPE CRUZ IAN MCSHANE and GEOFFREY RUSH",
  },
  {
    id: 1003,
    name: "Black Panther",
    year: 2018,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTg1MTY2MjYzNV5BMl5BanBnXkFtZTgwMTc4NTMwNDI@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "I mean, it's a Marvel movie... a supervillain tries to overthrow a superhero.",
    genre: "Superhero",
    subgenre: "Action",
    runtime: "136 min",
    pairs: "a Heart-Shaped Herb.",
    drinkRecipe: "Vodka, coconut rum, lemonade, Blue Curaçao, lavender syrup.",
    rated: "PG-13",
    picture1: blackPanther1,
    picture2: blackPanther2,
    picture3: blackPanther3,
    tagline: "Too much plot development!",
    taglineWriter: "Wit E. Hyena",
    bulletOne: "Kinda agree with the badguy in this one",
    bulletTwo: "A stingy King needs to learn it's 'puff, puff, PASS'.",
    cast: "Directed by RYAN COOGLER starring CHADWICK BOSEMAN MICHAEL B. JORDAN LUPITA NYONG'O and DANAI GURIRA",
  },
  {
    id: 1004,
    name: "I Could Never Be Your Woman",
    year: 2007,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOGRhODJjMzgtZjNkZS00ODU3LWEyZDktYTIyNDgxZmM3NzUyXkEyXkFqcGdeQXVyMTY2MzYyNzA@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "TV producer and single mother, Rosie, attempts to dissuade a younger man’s affection. A down to Earth comedy featuring Michelle Pfeiffer and Paul Rudd.",
    genre: "Romcom",
    subgenre: "",
    runtime: "97 min",
    pairs: "a Younger Man.",
    drinkRecipe: "Calico Jack and mango slushie.",
    rated: "PG-13",
    picture1: neverWoman1,
    picture2: neverWoman2,
    picture3: neverWoman3,
    tagline: "Of course she could never be 𝙢𝙮 woman.",
    taglineWriter: "Mark Corrigan",
    bulletOne: "Solid Brittany Spears parody.",
    bulletTwo: "Jeannie un-coupling the entire show.",
    cast: "Directed by AMY HECKERLING starring MICHELLE PFEIFFER PAUL RUDD SAOIRSE RONAN and TRACEY ULLMAN",
  },
  {
    id: 1005,
    name: "Blockers",
    year: 2018,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjE0ODIzNjkzMl5BMl5BanBnXkFtZTgwODQ3MzU4NDM@._V1_SX300.jpg",
    rating: 5,
    synopsis: "Helicopter parents stalk their daughters on prom night.",
    genre: "Romcom",
    subgenre: "Comedy",
    runtime: "102 min",
    pairs: "a Microdose.",
    drinkRecipe: "Caan Lavendar Lemonade soda and vodka.",
    rated: "R",
    picture1: blockers1,
    picture2: blockers2,
    picture3: blockers3,
    tagline: "INSPIRING FOREPLAY!",
    taglineWriter: "Masochism Monthly",
    bulletOne: "Leslie Mann auditions for Fast Fifteen",
    bulletTwo: "Cena's colon gets an Altitude Adjustment.",
    cast: "Directed by KAY CANNON starring LESLIE MANN JOHN CENA IKE BARINHOLTZ and KATHRYN NEWTON",
  },
  {
    id: 1006,
    name: "The Professor and the Madman",
    year: 2019,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjRjNWIxMWMtNzcxYi00NDYyLWFmMzAtZTRiZWZhZDMwZmVkXkEyXkFqcGdeQXVyMjExMDE1MzQ@._V1_SX300.jpg",
    rating: 5,
    synopsis: "A bookworm and a eunuch write down a bunch of words.",
    genre: "Drama",
    subgenre: "",
    runtime: "124 min",
    pairs: "a Thesaurus.",
    drinkRecipe: "Gin, Sprite, and 7-Up with lime.",
    rated: "Not Rated",
    picture1: professorMadman1,
    picture2: professorMadman2,
    picture3: professorMadman3,
    tagline: "This flick put the Dick in dicktionary!",
    taglineWriter: "Webster",
    bulletOne: "Diesel beards.",
    bulletTwo: "Still not really caring about etymology.",
    cast: "Directed by FARHAD SAFINIA starring MEL GIBSON SEAN PENN NATALIE DORMER and STEVE COOGAN",
  },
  {
    id: 1007,
    name: "Holidate",
    year: 2020,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYWVmYTFjODItOTY2Ni00NDhhLTk1ZDYtMzBmOGFhZTMyY2Q0XkEyXkFqcGdeQXVyMTEyMjM2NDc2._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Predictable rom-com that lampoons the genre so much you’ll shit your skirt.",
    genre: "Romcom",
    subgenre: "Comnedy",
    runtime: "104 min",
    pairs: "an RSVP for Two.",
    drinkRecipe: "Eggnog and Fireball Whiskey.",
    rated: "TV-MA",
    picture1: holidate1,
    picture2: holidate2,
    picture3: holidate3,
    tagline: "HoliGREAT!",
    taglineWriter: "Brasey Robberts",
    bulletOne: "Pretty accurate for being single at 30.",
    bulletTwo: "Definitely not being snydicated on Lifetime.",
    cast: "Directed by JOHN WHITESELL starring EMMA ROBERTS LUKE BRACEY KRISTIN CHENOWETH and FRANCES FISHER",
  },
  {
    id: 1008,
    name: "Holiday in the Wild",
    year: 2019,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzc1MWI3MWMtYmNlYi00NjllLWIyNjctNWU2NWRjNjE2ZjhkXkEyXkFqcGdeQXVyNTU5MzI1OTM@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Sex in the city is fine and all but abstinence in the desert is better.",
    genre: "Romcom",
    subgenre: "Holiday",
    runtime: "85 min",
    pairs: "a Springbokkie.",
    drinkRecipe: "1/2 Amarula then 1/2 crème de menthe.",
    rated: "TV-PG",
    picture1: holidayWild1,
    picture2: holidayWild2,
    picture3: holidayWild3,
    tagline: "BIBIDI-BA-BIDI-BA-DI-BA-BAH!",
    taglineWriter: "Donnie Thornberry",
    bulletOne: "Rob Lowe keeping it tight.",
    bulletTwo: "Family friendly feel fine flick.",
    cast: "Directed by ERNIE BARBARASH starring ROB LOWE KRISTIN DAVIS FEZILE MPELA and JOHN OWEN LOWE",
  },
  {
    id: 1009,
    name: "Ava",
    year: 2020,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTMzMTg1MjgtOWNhYy00NmZmLWExOTctMjA2OTZhZDFkNDhhXkEyXkFqcGdeQXVyNzAwMjU2MTY@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "The titular palindrome, Ron Howard’s other daughter, seems to love getting involved with degenerate gamblers.",
    genre: "Thriller",
    subgenre: "Crime",
    runtime: "96 min",
    noPairs: "drinks from the mini-bar",
    rated: "R",
    picture1: ava1,
    picture2: ava2,
    picture3: ava3,
    tagline: "Family relationships are always funny!",
    taglineWriter: "Woodie Ellan",
    bulletOne: "John Malkovich does his own stunts.",
    bulletTwo:
      "Colin Farrell moves from Bruges to New York to continue his killing spree.",
    cast: "Directed by TATE TAYLOR starring JESSICA CHASTAIN JOHN MALKOVICH COMMON and GEENA DAVIS",
  },
  {
    id: 1010,
    name: "Billionaire Boy's Club",
    year: 2018,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDAxZTE2MzgtZjNjYi00YjE4LWIzNzYtNTQyZWRhMWVhMTc2XkEyXkFqcGdeQXVyNTUzOTUwMTk@._V1_SX300.jpg",
    rating: 3,
    synopsis:
      "Madoff wannabees rip off rich kids in the 80's. The real con in this flick is getting you to watch it.",
    genre: "Thriller",
    subgenre: "Crime",
    runtime: "108 min",
    pairs: "a Ponzi Scheme.",
    drinkRecipe: "Gin, Diet Sprite, Club soda, pickle.",
    rated: "R",
    picture1: billionaire1,
    picture2: billionaire2,
    picture3: billionaire3,
    tagline: "Can they start remaking GOOD movies?",
    taglineWriter: "Michelle Bae",
    bulletOne: "Cary Elwes killing his cameo",
    bulletTwo: "Spacey grooming Anvile Igort for the second time",
    cast: "Directed by JAMES COX starring ANSEL ELGORT KEVIN SPACEY TARON EGERTON and EMMA ROBERTS",
  },
  {
    id: 1011,
    name: "Bombshell",
    year: 2019,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjlhOWE3YjktY2MzOC00ZmQ1LWIwNjgtZmVhZmFjZGExMzgyXkEyXkFqcGdeQXVyMDA4NzMyOA@@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Two Australians and a South African take on a powerful creep in this film based on true events.",
    genre: "Thriller",
    subgenre: "Drama",
    runtime: "109 min",
    pairs: "a blondie.",
    drinkRecipe: "Mimosa, vodka, lemon peel.",
    rated: "R",
    picture1: bombshell1,
    picture2: bombshell2,
    picture3: bombshell3,
    tagline: "NOT ALL BLONDES ARE DUMB!",
    taglineWriter: "Mygyn Kylly",
    bulletOne: "Are there any American actors left?",
    bulletTwo: "Just stop watching the news and maybe they'll all go away.",
    cast: "Directed by JAY ROACH starring CHARLIZE THERON NICOLE KIDMAN MARGOT ROBBIE and JOHN LITHGOW",
  },
  {
    id: 1012,
    name: "Under the Silver Lake",
    year: 2018,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjY0NTA5MGItNDU1Yy00ZmE3LWI5NGItZDY5OGUxZjYzZGQ5XkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Night owls, dog killers, and missing neighbors roam the sleepy streets of LA. Also, a stinky weirdo is confused for two hours straight. ",
    genre: "Thriller",
    subgenre: "Drama",
    runtime: "139 min",
    pairs: "",
    noPairs: "anything, just make it strong.",
    rated: "R",
    picture1: silverLake1,
    picture2: silverLake2,
    picture3: silverLake3,
    tagline: "I fucking hate this movie so much that I love it",
    taglineWriter: "Screamo Flathead",
    bulletOne: "Music and Pop Culture icon(s)",
    bulletTwo: "Vandals getting what they deserve",
    cast: "Directed by DAVID ROBERT MITCHELL starring ANDREW GARFIELD RILEY KEOUGH and TOPHER GRACE",
  },
  {
    id: 1013,
    name: "The Fisher King",
    year: 1991,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOGZmMDM1NTQtMzc0Yi00ODRiLWJjYjQtMGRmYWRlOTY2ODMxXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 3,
    synopsis:
      "A dirtball widower stalks the biggest klutz on earth and falls in love. ",
    genre: "Drama",
    subgenre: "Comedy",
    runtime: "137 min",
    pairs: "an entire bottle of Jack.",
    rated: "R",
    picture1: fisherking1,
    picture2: fisherking2,
    picture3: fisherking3,
    tagline: "Get me more screen time!",
    taglineWriter: "Robin Williams' Penis",
    bulletOne: "Jeff Bridges' ponytail",
    bulletTwo: "Hanging dong in Central Park",
    cast: "Directed by TERRY GILLIAM starring JEFF BRIDGES ROBIN WILLIAMS and MERCEDES RUEHL",
  },
  {
    id: 1014,
    name: "Just Add Water",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjQ4ZDU5NjgtNTgyNS00NzViLThhYzctMTg3NGZmNzMwODA3XkEyXkFqcGdeQXVyMTQ3Njg3MQ@@._V1_SX300.jpg",
    rating: 3,
    year: 2008,
    synopsis:
      "Dried up citizens deal with changing taxes imposed by new policy makers and refuse to move away from their dirtball town.",
    genre: "Comedy",
    subgenre: "Romcom",
    runtime: "95 min",
    pairs: "Lemon Meringue Martini.",
    drinkRecipe:
      "Limoncello, UV Whipped Vodka, lemonade, half & half, graham cracker crumbs.",
    rated: "R",
    picture1: addwater1,
    picture2: addwater2,
    picture3: addwater3,
    tagline: "JUST SUBTRACT ME!",
    taglineWriter: "Jon Ahill",
    bulletOne: "Dessert recipes to die for.",
    bulletTwo: "Frank's money laudering in gas stations.",
    cast: "Directed by HART BOCHNER starring DYLAN WALSH JUSTIN LONG JONAH HILL and DANNY DeVITO",
  },
  {
    id: 1015,
    name: "The Perfect Score",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjg5NzU4NTQzNV5BMl5BanBnXkFtZTYwMDMzNjU3._V1_SX300.jpg",
    rating: 4,
    year: 2004,
    synopsis:
      "Pre-franchise money ScarJo and ChrEv show off their Marvelous B&E skills.",
    genre: "Comedy",
    subgenre: "Crime",
    runtime: "93 min",
    pairs: "",
    noPairs: "anything stolen from your rents liquor cabinet",
    rated: "R",
    picture1: perfectScore1,
    picture2: perfectScore2,
    picture3: perfectScore3,
    tagline: "The Perfect BORE!",
    taglineWriter: "E.C. Ryder",
    bulletOne: "SAT scores don’t matter as much as this movie thinks",
    bulletTwo: "Non-stereotypical representation of Black and Asian Americans",
    cast: "Directed by BRIAN ROBBINS starring SCARLETT JOHANSSON CHRIS EVANS LEONARDO NAM and ERIKA CHRISTENSEN",
  },
  {
    id: 1016,
    name: "The Conjuring: The Devil Made Me Do It",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOWRkOTYzZTQtMzQwNi00NDYwLTk4NjUtN2FjYTI4Y2UzM2RjXkEyXkFqcGdeQXVyODE5NzE3OTE@._V1_SX300.jpg",
    rating: 2,
    year: 2021,
    synopsis:
      "Two expert demon hunters are fooled by a demon… for the third time.",
    genre: "Horror",
    subgenre: "Mystery",
    runtime: "112 min",
    pairs: "",
    noPairs: "red wine.",
    rated: "R",
    picture1: conjuringThree1,
    picture2: conjuringThree2,
    picture3: conjuringThree3,
    tagline: "I’d rather be in Hell than watch that again.",
    taglineWriter: "Lou Siffer",
    bulletOne: "An unholy amount of water.",
    bulletTwo:
      "Using trinkets and tchotchkes are the best way to prove innocence.",
    cast: "Directed by MICHAEL CHAVES starring VERA FARMIGA PATRICK WILSON and RUAIRI O’CONNER",
  },
  {
    id: 1017,
    name: "A Quiet Place Part II",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTE2ODU4NDEtNmRjNS00OTk1LTg4NmMtNTAzYzVlNzJmYjgzXkEyXkFqcGdeQXVyODk4OTc3MTY@._V1_SX300.jpg",
    rating: 3,
    year: 2020,
    synopsis:
      "Man, Cillian Murphy just can’t escape post-apocalyptic societies.",
    genre: "Drama",
    subgenre: "Scifi",
    runtime: "112 min",
    pairs: "Quiet Riot II.",
    drinkRecipe: "Seagrams Ginger ale, two shots of Quiet Man Whiskey.",
    rated: "R",
    picture1: quietplaceTwo1,
    picture2: quietplaceTwo2,
    picture3: quietplaceTwo3,
    tagline: "Do NOT watch on full volume!",
    taglineWriter: "Sen Btiller",
    bulletOne: "Deaf-us ex machina.",
    bulletTwo: "The beginning was good.",
    cast: "Directed by JIM HALPERT starring EMILY BLUNT MILLICENT SIMMONDS and CILLIAN MURPHY",
  },
  {
    id: 1018,
    name: "Wander",
    poster:
      "https://m.media-amazon.com/images/I/81z743hoxsL._AC_UY436_FMwebp_QL65_.jpg",
    rating: 4,
    year: 2020,
    synopsis:
      "G-Men are after you but you can’t see them through all the lens flares and blurs.",
    genre: "Thriller",
    subgenre: "Mystery",
    runtime: "94 min",
    pairs: "Wanderland Ale.",
    rated: "R",
    picture1: wander1,
    picture2: wander2,
    picture3: wander3,
    tagline: "I WANDER who the bad guy is?!",
    taglineWriter: "Boston E. Klaire",
    bulletOne: "Alex Jones wet dream coming true",
    bulletTwo: "Heather Graham",
    cast: "Directed by APRIL MULLEN starring AARON ECKHART TOMMY LEE JONES and KATHERYN WINNICK",
  },
  {
    id: 1019,
    name: "Spider-Man: Far From Home",
    poster:
      "https://m.media-amazon.com/images/I/91A+eXyGmvL._AC_UY436_FMwebp_QL65_.jpg",
    rating: 5,
    year: 2019,
    synopsis:
      "The Night Monkey destroys historic cities while keeping his European rip-off identity a secret.",
    genre: "Superhero",
    subgenre: "Action",
    runtime: "129 min",
    pairs: "a Cobweb.",
    drinkRecipe: "Malibu Coconut rum with Gatorade Frost Glacier Cherry.",
    rated: "PG-13",
    picture1: farFromHome1,
    picture2: farFromHome2,
    picture3: farFromHome3,
    tagline: "WATCH IT THE NIGHT BEFORE YESTERDAY!",
    taglineWriter: "Juke Gyllynhual",
    bulletOne: "It definitely not being him.",
    bulletTwo:
      "Even though it’s not a competition, Ned did know first and the longest.",
    cast: "Directed by JON WATTS starring TOM HOLLAND JAKE GYLLENHAAL JACOB BATALON and ZENDAYA",
  },
  {
    id: 1020,
    name: "Mission: Impossible - Fallout",
    poster:
      "https://m.media-amazon.com/images/I/71gttgI4KqL._AC_UY436_FMwebp_QL65_.jpg",
    rating: 4,
    year: 2018,
    synopsis:
      "There’s so much two-timing in this one you’ll have to watch it thrice.",
    genre: "Action",
    subgenre: "Thriller",
    runtime: "147 min",
    pairs: "an Outfall.",
    drinkRecipe: "Mezcal, sparkling lime water.",
    rated: "PG-13",
    picture1: miFallout1,
    picture2: miFallout2,
    picture3: miFallout3,
    tagline: "We need another 7 of these movies!",
    taglineWriter: "Paramount",
    bulletOne: "The most expensive mustache in film history",
    bulletTwo: "Seriously, who is who and what side are they on?",
    cast: "Directed by CHRISTOPHER McQUARRIE starring TOM CRUISE HENRY CAVILL REBECCA FERGUSON and VING RHAMES",
  },
  {
    id: 1021,
    name: "There’s Someone Inside Your House",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYmQ4OTAwNjAtMzBjZS00ZTI3LTk0YTUtMzE1N2I5ZDA5ZGRiXkEyXkFqcGdeQXVyODk4OTc3MTY@._V1_SX300.jpg",
    rating: 3,
    year: 2018,
    synopsis:
      "A group of highschool ‘friends’ become the target of a small town serial killer that no one seems to care about",
    genre: "Horror",
    subgenre: "Thriller",
    runtime: "96 min",
    pairs: "Teen Killer.",
    drinkRecipe: " straight Bankers Club.",
    rated: "TV-MA",
    picture1: inYourHouse1,
    picture2: inYourHouse2,
    picture3: inYourHouse3,
    tagline: "For a movie so ‘woke’, it put me to sleep",
    taglineWriter: "Booth Radiator Guinnessburg",
    bulletOne: "It’s ok that you’re a murderer, we won’t judge you.",
    bulletTwo: "Every actor being ten years older than their character.",
    cast: "Directed by PATRICK BRICE starring SYDNEY PARK BURKELY DUFFIELD THEODORE PELLERIN and ASHJA COOPER",
  },
  {
    id: 1022,
    name: "The Guilty",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTJjYjVkMGQtNTM1Yi00Mjc3LWI5YzEtN2NiYTQ4NDU4NjdmXkEyXkFqcGdeQXVyMTkxNjUyNQ@@._V1_SX300.jpg",
    rating: 4,
    year: 2021,
    synopsis:
      "An unstable 911 operator randomly freaks out over the phone for an hour and a half.",
    genre: "Crime",
    subgenre: "Thriller",
    runtime: "90 min",
    pairs: "an Automated Message.",
    drinkRecipe: "Scotch and Scotch Ale.",
    rated: "R",
    picture1: guilty1,
    picture2: guilty2,
    picture3: guilty3,
    tagline: "I LOVE SCREAMING AT PEOPLE!",
    taglineWriter: "Jyky Gellenhelle",
    bulletOne: "UN-believable crying scene.",
    bulletTwo: "Definitely filmed during quarantine.",
    cast: "Directed by ANTOINE FUQUA starring JAKE GYLLENHAAL",
  },
  {
    id: 1023,
    name: "The Living Daylights",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjI4MjBmYzItYTY5OC00OWYzLWE0NWYtZDQxNDQxM2QzYjA4XkEyXkFqcGdeQXVyNjc1NTYyMjg@._V1_SX300.jpg",
    rating: 4,
    year: 1987,
    synopsis:
      "A hot Russian assassin defects while gratuitous explosions and car wrecks destroy cheap movie sets. Grab your ghetto blaster and prepare for some prime 80's Bond.",
    genre: "Action",
    subgenre: "Adventure",
    runtime: "130 min",
    noPairs: "a Martini, shaken of course.",
    rated: "R",
    picture1: livingDaylights1,
    picture2: livingDaylights2,
    picture3: livingDaylights3,
    tagline: "Two hours long?! Better make it one.",
    taglineWriter: "Diego Vínculo",
    bulletOne: "New uses for headphones!",
    bulletTwo: "A serious lack of model homes thru the jaw.",
    cast: "Directed by JOHN GLEN starring TIMOTHY DALTON  MARYAM d'ABO  JEROEN KRABBÊ and JOE DON BAKER",
  },
  {
    id: 1024,
    name: "The Babysitter",
    poster:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.TTDHjTnndz90FZ-dG40o8gHaK-%26pid%3DApi&f=1",
    rating: 4,
    year: 2017,
    synopsis:
      "A know-it-all little jerk ruins the hopes and dreams of five high school students.",
    genre: "Comedy",
    subgenre: "Thiller",
    runtime: "85 min",
    pairs: "a Blood Sacrifice.",
    drinkRecipe: "Sangria, Vodka, and Sprite.",
    rated: "TV-MA",
    picture1: babysitter1,
    picture2: babysitter2,
    picture3: babysitter3,
    tagline: "We do not condone cult behavior.",
    taglineWriter: "The Babysitter's Club",
    bulletOne: "Never trust an invite to a hot people party",
    bulletTwo: "A real bully will stop mid-murder to teach a dork a lesson",
    cast: "Directed by McG starring SAMARA WEAVING  JUDAH LEWIS  BELLA THORNE and ROBBIE AMELL",
  },
  {
    id: 1025,
    name: "The Babysitter: Killer Queen",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMWEwMDU3MWUtZTdiMy00Yjg5LWFiNWYtYTRmZGExNzk5YjQ2XkEyXkFqcGdeQXVyNTUwOTkzMzY@._V1_SX300.jpg",
    rating: 4,
    year: 2020,
    synopsis:
      "After successfully preventing an orgy two years prior, Cole decides to be the wet blanket at a boat party.",
    genre: "Comedy",
    subgenre: "Thiller",
    runtime: "101 min",
    pairs: "Tainted Blood.",
    drinkRecipe: "Sangria and Gin.",
    rated: "TV-MA",
    picture1: babysitter21,
    picture2: babysitter22,
    picture3: babysitter23,
    tagline: "What's a little piss between brand new friends?",
    taglineWriter: "Pheobe",
    bulletOne: "The perfect s'mores recipe!",
    bulletTwo: "Seemingly being held back 10 years between movies.",
    cast: "Directed by McG starring EMILY ALYN LIND  JUDAH LEWIS  JENNA ORTEGA and ANDREW BACHELOR",
  },
  {
    id: 1026,
    name: "The Knights of Badassdom",
    poster:
      "https://m.media-amazon.com/images/I/81mM3DRpVML._AC_UY436_FMwebp_QL65_.jpg",
    rating: 4,
    year: 2013,
    synopsis:
      "Fantasy camp turns bloody when LARP enthusiasts bring an actual demon to the Parking Lot of Evermore.",
    genre: "Comedy",
    subgenre: "Fantasy",
    runtime: "86 min",
    pairs: "Old Turkey and bong hits.",
    rated: "R",
    picture1: knightsBadass1,
    picture2: knightsBadass2,
    picture3: knightsBadass3,
    tagline: "That's how to you toss keys like a fuckin' badass.",
    taglineWriter: "Jimmi Simpson",
    bulletOne: "Featuring hairstyles of the mid-2000's.",
    bulletTwo: "Doom Metal headlined by the poor man's Chris Pratt.",
    cast: "Directed by JOE LYNCH starring RYAN KWANTEN  STEVE ZAHN  PETER DINKLAGE  and SUMMER GLAU",
  },
  {
    id: 1027,
    name: "Palmer",
    poster:
      "https://media-cache.cinematerial.com/p/500x/rpvyrx4r/palmer-movie-poster.jpg?v=1608293054",
    rating: 5,
    year: 2021,
    synopsis:
      "JT knocks it out of the park as a speechless ex-con in rural Louisiana.",
    genre: "Drama",
    subgenre: "Comedy",
    runtime: "110 min",
    pairs: "",
    noPairs: "Coors Light pounders.",
    rated: "R",
    picture1: palmer1,
    picture2: palmer2,
    picture3: palmer3,
    tagline: "What'd you expect dating mayhem like me?",
    taglineWriter: "Jerry",
    bulletOne: "Stereotypical bullying.",
    bulletTwo: "Introducing Ryder Allen herself.",
    cast: "Directed by FISHER STEVENS starring JUSTIN TIMBERLAKE  JUNO TEMPLE ALISHA WAINWRIGHT and JUNE SQUIBB",
  },
  {
    id: 1028,
    name: "Mystery Team",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjA0MzI5ODYyMl5BMl5BanBnXkFtZTcwNTEwOTQzMg@@._V1_SX300.jpg",
    rating: 5,
    year: 2009,
    synopsis:
      "Three young detectives set out to solve their small town's biggest mystery... as a team.",
    genre: "Comedy",
    subgenre: "Mystery",
    runtime: "97 min",
    pairs: "an Adult Chocolate Milk.",
    drinkRecipe: "Or two bourbons.",
    rated: "R",
    picture1: mysteryTeam1,
    picture2: mysteryTeam2,
    picture3: mysteryTeam3,
    tagline: "Have fun at the wake!",
    taglineWriter: "Jason",
    bulletOne: "I wanna meet Charlie's dad",
    bulletTwo:
      "If you don't like this movie that's fine because you don't know that you're wrong",
    cast: "Directed by DAN ECKMAN starring DONALD GLOVER  DOMINIC DIERKES  and D.C. PIERSON",
  },
  {
    id: 1029,
    name: "Almost Famous",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzY1ZjMwMGEtYTY1ZS00ZDllLTk0ZmUtYzA3ZTA4NmYwNGNkXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 5,
    year: 2000,
    synopsis:
      "In 1973 a 15 year old dweeb goes behind enemy lines to narc on cool cats and hip chicks.",
    genre: "Comedy",
    subgenre: "Drama",
    runtime: "122 min",
    pairs: "",
    noPairs: "beer from the red cups.",
    rated: "R",
    picture1: almostFamous1,
    picture2: almostFamous2,
    picture3: almostFamous3,
    tagline: "DON'T DO DRUGS!",
    taglineWriter: "Franklin MacDoormat",
    bulletOne: "Never wanting to fly again.",
    bulletTwo: "Well worth the music budget.",
    cast: "Directed by CAMERON CROWE starring KATE HUDSON  BILLY CRUDUP  and PATRICK FUGIT",
  },
  {
    id: 1030,
    name: "Parasite",
    poster:
      "https://images-na.ssl-images-amazon.com/images/I/A1XVjZAdS5L._SL1500_.jpg",
    rating: 4,
    year: 2019,
    synopsis:
      "A dirtball, basement dwelling family slowly rises up in society.",
    genre: "Drama",
    subgenre: "Comedy",
    runtime: "132 min",
    pairs: "an OB Golden Lager.",
    rated: "R",
    picture1: parasite1,
    picture2: parasite2,
    picture3: parasite3,
    tagline: "These house workers are well connected!",
    taglineWriter: "Mrs. Park",
    bulletOne: "Need better fumigation",
    bulletTwo: "Reminder to check your B.O. before work",
    cast: "Directed by BONG HOON HO starring SUN-KYUN LEE  YEO-JEONG CHO  SO-DAM PARK  and  KANG-HO SONG",
  },
  {
    id: 1031,
    name: "Carrie",
    poster:
      "https://m.media-amazon.com/images/I/91baKvLDHXL._AC_UY436_FMwebp_QL65_.jpg",
    rating: 6,
    year: 1976,
    synopsis:
      "Regina George's grandma torments a religous nut with the help of Danny Zuko.",
    genre: "Horror",
    subgenre: "Drama",
    runtime: "98 min",
    pairs: "a Bloody Carrie.",
    drinkRecipe: "Sangria, vodka, and cranberry juice cocktail.",
    rated: "R",
    picture1: carrie1,
    picture2: carrie2,
    picture3: carrie3,
    tagline: "THE SCRIPT WAS HILARIOUS!",
    taglineWriter: "Mrs. White",
    bulletOne: "Getting double vertigo at the school dance.",
    bulletTwo: "It's a tie. The Book and the Movie are equally good.",
    cast: "Directed by BRIAN DE PALMA starring SISSY SPACEK  AMY IRVING  PIPER LAURIE  and  NANCY ALLEN",
  },
  {
    id: 1032,
    name: "Being the Ricardos",
    poster:
      "https://m.media-amazon.com/images/I/917jK2lJJWL._AC_UY436_FMwebp_QL65_.jpg",
    rating: 4,
    year: 2021,
    synopsis:
      "Lusi & Decy stay together for the fans during a red hot week from hell.",
    genre: "Drama",
    subgenre: "Comedy",
    runtime: "121 min",
    pairs: "a Red Head.",
    drinkRecipe: "Absolut Raspberry and Sprite on ice with a cherry.",
    rated: "R",
    picture1: beingRicardos1,
    picture2: beingRicardos2,
    picture3: beingRicardos3,
    tagline: "It's a long way of saying, 'She hated him.'",
    taglineWriter: "Eerin Snorklin",
    bulletOne: "Don't fuck with the Cuban. ",
    bulletTwo: "Cast was fine but timing needed... work.",
    cast: "Directed by AARON SORKIN starring NICOLE KIDMAN  JAVIER BARDEM  TONY HALE  and  J.K. SIMMONS",
  },
  {
    id: 1033,
    name: "Dune",
    poster:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.etsystatic.com%2F13367669%2Fr%2Fil%2F81b8fb%2F3303143889%2Fil_fullxfull.3303143889_smws.jpg&f=1&nofb=1",
    rating: 3,
    year: 2021,
    synopsis:
      "Way more focused on the sand and desert than on the battles and galactic trade law.",
    genre: "Drama",
    subgenre: "Scifi",
    runtime: "155 min",
    pairs: "",
    noPairs: "a glass of WATER!",
    rated: "PG-13",
    picture1: dune1,
    picture2: dune2,
    picture3: dune3,
    tagline: "At least I'll get paid thru the sequel.",
    taglineWriter: "Zendaya",
    bulletOne: "Not only is the book better but so is the original movie.",
    bulletTwo: "Swords versus spaceships...",
    cast: "Directed by DENNIS VILLENEUVE starring TIMOTHEE CHALAMET  JOSH BROLIN  and  ZENDAYA",
  },
  {
    id: 1034,
    name: "Baby Driver",
    poster:
      "https://www.vintagemovieposters.co.uk/wp-content/uploads/2020/04/IMG_5274-689x1024.jpeg",
    rating: 5,
    year: 2017,
    synopsis:
      "Angle Isntshort does his best Gosling impression and listens to hip music.",
    genre: "Drama",
    subgenre: "Comedy",
    runtime: "113 min",
    pairs: "an Adult Walker.",
    drinkRecipe: "Tequila, Johnny Walker.",
    rated: "R",
    picture1: babyDriver1,
    picture2: babyDriver2,
    picture3: babyDriver3,
    tagline: "What's the driver's name again?",
    taglineWriter: "Doc",
    bulletOne: "Austin Powers makes a comeback.",
    bulletTwo: "Driving with headphones is safe after all.",
    cast: "Directed by EDGAR WRIGHT starring ANSEL ELGORT  KEVIN SPACEY  JAMIE FOXX  and  LILY JAMES",
  },
  {
    id: 1035,
    name: "The Number 23",
    poster:
      "https://m.media-amazon.com/images/I/91EJrZ6ELoL._AC_UY436_FMwebp_QL65_.jpg",
    rating: 2.3,
    year: 2007,
    synopsis:
      "During the mid 2000's a documentary crew films Jim Carrey, in his daily life, as he slowly slips deeper and deeper into insanity.",
    genre: "Drama",
    subgenre: "Mystery",
    runtime: "98 min",
    pairs: "an Abacus.",
    drinkRecipe: "Element 29, 7 Up, & 5 Hour Energy.",
    rated: "R",
    picture1: number231,
    picture2: number232,
    picture3: number233,
    tagline: "This film just doesn't add up!",
    taglineWriter: "Katherine Coleman Johnson",
    bulletOne: "New mathematic techniques.",
    bulletTwo: "It should've ran for 149 mins.",
    cast: "Directed by JOEL SCHUMACHER starring JIM CARREY  VIRGINIA MADSEN  DANNY HUSTON  and  LOGAN LERMAN",
  },
  {
    id: 1036,
    name: "The English Patient",
    poster:
      "https://m.media-amazon.com/images/I/81iZ7tdXPML._AC_UY436_FMwebp_QL65_.jpg",
    rating: 3,
    year: 1996,
    synopsis:
      "After WWII, a French nurse bangs a bomb diffuser and provides hospice care for Voldemort.",
    genre: "Drama",
    subgenre: "",
    runtime: "162 min",
    pairs: "",
    noPairs: "a whole bottle of champagne.",
    rated: "R",
    picture1: englishPatient1,
    picture2: englishPatient2,
    picture3: englishPatient3,
    tagline: "JUST DIE ALREADY!",
    taglineWriter: "Elaine Benes",
    bulletOne: "Private Singh sure did get LOST.",
    bulletTwo: "Buckle up and strap in for a long, smooth, ride to no where.",
    cast: "Directed by ANTHONY MINGHELLA starring RALPH FIENNES  JULIETTE BINOCHE  WILLEM DAFOE  and  NAVEEN ANDREWS",
  },
  {
    id: 1037,
    name: "Midnight in Paris",
    poster:
      "https://m.media-amazon.com/images/I/91Dw3HHfuiL._AC_UY436_FMwebp_QL65_.jpg",
    rating: 4,
    year: 2011,
    synopsis:
      "A screenwriting hack really hates his job, and wife, while on an all-expenses-paid for vaca in Paris.",
    genre: "Comedy",
    subgenre: "Fantasy",
    runtime: "94 min",
    pairs: "a Rose Cocktail.",
    drinkRecipe: "French vermouth, Kirsch, Monin, and a maraschino cherry.",
    rated: "R",
    picture1: midnightInParis1,
    picture2: midnightInParis2,
    picture3: midnightInParis3,
    tagline: "Waddya say, kid? Ain't I from the 20's, see?",
    taglineWriter: "Loki",
    bulletOne: "I guess Hemmingway was pretty intense.",
    bulletTwo:
      "If I remember correctly, Michael Sheen plays a great pretentious douche.",
    cast: "Directed by WOODY ALLEN starring OWEN WILSON  RACHEL McADAMS  ALLISON PILL  and  COREY STOLL",
  },
  {
    id: 1038,
    name: "The God Commitee",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDU1N2QyMjktYzM2NC00ZDMzLWEzNTYtZDdiN2VmYWU5YWFjXkEyXkFqcGdeQXVyNTM0NTU5Mg@@._V1_SX300.jpg",
    rating: 4,
    year: 2021,
    synopsis:
      "Doctors at a financially destitute hospital must decide which two out of three patients to kill.",
    genre: "Drama",
    subgenre: "",
    runtime: "98 min",
    pairs: "a Transplant.",
    drinkRecipe: "Cranberry juice cocktail and gin.",
    rated: "NR",
    picture1: godCommittee1,
    picture2: godCommittee2,
    picture3: godCommittee3,
    tagline: "This movie's got no heart!",
    taglineWriter: "Dr. Bartley P. Griffith",
    bulletOne: "You could say she, 'Trip'd out of the car'.",
    bulletTwo:
      "Surprised Phillip Morris isn't funding the lung transplant division.",
    cast: "Directed by AUSTIN STARK starring KELSEY GRAMMER  JULIA STILES  COLEMAN DOMINGO  and  JANEANE GAROFALO",
  },
  {
    id: 1039,
    name: "MacGruber",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjE4MDY1ODY5Nl5BMl5BanBnXkFtZTcwNjgzMTEzMw@@._V1_SX300.jpg",
    rating: 6,
    year: 2010,
    synopsis:
      "To prevent a nuclear attack, a former spec-ops soldier comes out of retirement to pound some Cunth.",
    genre: "Comedy",
    subgenre: "",
    runtime: "90 min",
    pairs: "a Celery Stalk.",
    drinkRecipe: "KFBR392𝐤𝐟𝐛𝐫𝟑𝟗𝟐KFBR392KFBR392KFBЯ392",
    rated: "R",
    picture1: macgruber1,
    picture2: macgruber2,
    picture3: macgruber3,
    tagline: "You oughta save the world? No, Miata do it",
    taglineWriter: "Bob Marley",
    bulletOne: "Soothing sounds of a Blaupunkt.",
    bulletTwo: "kfbr392KFBR392🅺🅵🅱🆁392kfbr392Kᖴᗷᖇ392",
    cast: "Directed by JORMA TACCONE starring WILL FORTE KRISTEN WIIG RYAN PHILLIPPE and VAL KILMER",
  },
  {
    id: 1040,
    name: "Zoolander",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODI4NDY2NDM5M15BMl5BanBnXkFtZTgwNzEwOTMxMDE@._V1_SX300.jpg",
    rating: 6,
    year: 2001,
    synopsis:
      "Following a freak gasoline fight accident a philanthropist sets aside his dream of starting a center for kids who can't read good to save the world... of fashion.",
    genre: "Comedy",
    subgenre: "",
    runtime: "90 min",
    pairs: "a Merman.",
    drinkRecipe: "Coconut rum, Blue Curaçao, pineapple juice.",
    rated: "R",
    picture1: zoolander1,
    picture2: zoolander2,
    picture3: zoolander3,
    tagline: "Male models are dumb?! This movie is defensive!",
    taglineWriter: "Fabio",
    bulletOne: "Finding your soulmate at an orgy.",
    bulletTwo: "Learning how to download files from a computer.",
    cast: "Directed by BEN STILLER starring BEN STILLER  OWEN WILSON  CHRISTINE TAYLOR and WILL FERRELL",
  },
  {
    id: 1041,
    name: "Nighthawks",
    year: 1981,
    poster:
      "https://m.media-amazon.com/images/I/81QQyKXkyvL._AC_UY436_FMwebp_QL65_.jpg",
    rating: 5,
    synopsis:
      "Rocky teams up with Lando Calrissian to catch a Eurotrash terrorist guilty of home-i-cide.",
    genre: "Action",
    subgenre: "",
    runtime: "99 min",
    pairs: "",
    noPairs: "a shot (that you should've took).",
    rated: "R",
    picture1: nighthawks1,
    picture2: nighthawks2,
    picture3: nighthawks3,
    tagline: "YOU'RE FUCKING DEAD!!!",
    taglineWriter: "Ivan Drago",
    bulletOne: "Stallone's awful beard.",
    bulletTwo:
      "Has the gritty feel of a French Connection - because it was supposed to be The French Connection 3.",
    cast: "Directed by BRUCE MALMUTH starring SYLVESTER STALLONE BILLY DEE WILLIAMS RUTGER HAUER and LINDSAY WAGNER",
  },

  {
    id: 1042,
    name: "The Fly",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODcxMGMwOGEtMDUxMi00MzE5LTg4YTYtYjk1YjA4MzQxNTNlXkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 6,
    year: 1986,
    synopsis:
      "Ian Malcom demonstrates chaos theory by accidentally splicing himself with a fly.",
    genre: "Scifi",
    subgenre: "Cult Classic",
    runtime: "96 min",
    pairs: "a Regurgitation.",
    drinkRecipe: "lemonade and vodka, shaken.",
    rated: "R",
    picture1: fly1,
    picture2: fly2,
    picture3: fly3,
    tagline: "WHAT A METAMORPHOSIS!",
    taglineWriter: "Franz Kafka",
    bulletOne: "Lesson learned here: don't do science experiments while drunk.",
    bulletTwo: "Where did Goldblum keep getting those baboons?",
    cast: "Directed by DAVID CRONENBERG starring JEFF GOLDBLUM GEENA DAVIS JOHN GETZ and TYPHOON",
  },
  {
    id: 1043,
    name: "Double Team",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTEzZTczYjctOGY4Zi00MjFhLWJlMDctZjBiMWU2MDYwZTQ5XkEyXkFqcGdeQXVyNDc2NjEyMw@@._V1_SX300.jpg",
    rating: 3,
    year: 1997,
    synopsis:
      "Jack Quinn gets off his sunset watching ass to box-out Stavros 'The Tiger' Robinson and slam dunk his wife & unborn child to safety.",
    genre: "Action",
    subgenre: "Comedy",
    runtime: "93 min",
    pairs: "a colorful hairstyle.",
    drinkRecipe: "Lemon lime Gatorade and Kraken.",
    rated: "R",
    picture1: doubleTeam1,
    picture2: doubleTeam2,
    picture3: doubleTeam3,
    tagline: "Action. Comedy. Romance. A TRIPLE THREAT!",
    taglineWriter: "Mikell Gordon",
    bulletOne: "A deadly hangnail.",
    bulletTwo: "Most Italian wedding ever: shoot-out in the Piazza.",
    cast: "Directed by HARK TSUI starring JEAN-CLAUDE VAN DAMME  DENNIS RODMAN  MICKEY ROURKE and NARACHA LINDINER",
  },
  {
    id: 1044,
    name: "Operation Mincemeat",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOGZlNjZhNjgtMjQzZC00MDI0LWFmNGQtNmNiYTIyYTE3ODhkXkEyXkFqcGdeQXVyMDA4NzMyOA@@._V1_SX300.jpg",
    rating: 4,
    year: 2021,
    synopsis:
      "During WW Dos, British Spooks use every trick not yet in the book to convince Hitler of an impending Greek invasion.",
    genre: "Action",
    subgenre: "Thriller",
    runtime: "128 min",
    pairs: "ground beef.",
    drinkRecipe: "Vodka blended with red Jello.",
    rated: "PG-13",
    picture1: mincemeat1,
    picture2: mincemeat2,
    picture3: mincemeat3,
    tagline: "WHAT A NOVEL IDEA!",
    taglineWriter: "Lt Cdr Ian Flemming",
    bulletOne: "Answering correctly the first time.",
    bulletTwo: "Giving drug addicts a chance to change who they are.",
    cast: "Directed by JOHN MADDEN starring COLIN FIRTH  MATTHEW MACFADYEN  KELLY MACDONALD and PENELOPE WILTON",
  },
  {
    id: 1045,
    name: "Cop Land",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNmNhMzI0NmQtMzU1OS00NzQzLTg0NzctZDJkODZlMjU3OTc5XkEyXkFqcGdeQXVyNDc2NjEyMw@@._V1_SX300.jpg",
    rating: 5,
    year: 1997,
    synopsis:
      "Stallone. De Niro. Liotta. Kietel. You aren't ready for the lack of action in this flick.",
    genre: "Crime",
    subgenre: "Drama",
    runtime: "104 min",
    pairs: "Four Aces.",
    drinkRecipe: "",
    rated: "R",
    picture1: copland1,
    picture2: copland2,
    picture3: copland3,
    tagline: "Set in Jersey? I'd better do the soundtrack!",
    taglineWriter: "Broose Sprungstein",
    bulletOne: "Kinda cute cupcake cops.",
    bulletTwo: "It's hard to play darts and do coke.",
    cast: "Directed by JAMES MANGOLD starring SYLVESTER STALLONE  HARVEY KEITEL  RAY LIOTTA and ROBERT DE NIRO",
  },
  {
    id: 1046,
    name: "Mulholland Drive",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTRiMzg4NDItNTc3Zi00NjBjLTgwOWYtOGZjMTFmNGU4ODY4XkEyXkFqcGdeQXVyMjUzOTY1NTc@._V1_SX300.jpg",
    rating: 1,
    year: 2001,
    synopsis:
      "Against all odds, this turd is worse than the directors haircut. It's a long, meandering mindfuck that's weird for weirds sake. Hard pass. ",
    genre: "Thriller",
    subgenre: "Mystery",
    runtime: "147 min",
    pairs: "a Mind Eraserhead.",
    drinkRecipe: "Enough moonshine to make you forget about watching this.",
    rated: "R",
    picture1: mulholland1,
    picture2: mulholland2,
    picture3: mulholland3,
    tagline: "This movie was a goddamn nightmare",
    taglineWriter: "Freddy Krueger",
    bulletOne: "Steamy lesbian scenes.",
    bulletTwo:
      "Highly praised only because no one wants to seem like they don't 'get it'. There's nothing to get. It's indulgent drivel.",
    cast: "Directed by DAVID LYNCH starring NAOMI WATTS LAURA HARRING JUSTIN THEROUX and ANN MILLER",
  },
  {
    id: 1047,
    name: "Battle Royale",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDc2MGYwYzAtNzE2Yi00YmU3LTkxMDUtODk2YjhiNDM5NDIyXkEyXkFqcGdeQXVyMTEwNDU1MzEy._V1_SX300.jpg",
    rating: 5,
    year: 2000,
    synopsis:
      "A bunch of students on a remote island are forced to kill or be killed. Last one standing wins.",
    genre: "Action",
    subgenre: "Action",
    runtime: "114 min",
    pairs: "Royale Crowne.",
    drinkRecipe: "Crown Royal, Cranberry Canada Dry, maraschino cherries.",
    rated: "R",
    picture1: battleRoyale1,
    picture2: battleRoyale2,
    picture3: battleRoyale3,
    tagline: "School's out for summer. No more Mr. Nice Guy",
    taglineWriter: "Alice Cooper",
    bulletOne:
      "Getting a definitive answer on which of your friends actually wants to kill you.",
    bulletTwo: "Picture The Condemned but with Japanese middle schoolers.",
    cast: "Directed by KINJI FUKASAKU starring TATSUYA FUJIWARA AKI MAEDA TARO YAMAMOTO and TAKESHI KITANO",
  },
  {
    id: 1048,
    name: "Innerspace",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDk1NzNiMGQtNGQ4Yy00NmMzLTkyNWQtZjM4MDU2Y2Q2NDVlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    year: 1987,
    synopsis: "A renegade pilot takes flight in(ner)side his Eskimo Bro.",
    genre: "Adventure",
    subgenre: "Scifi",
    runtime: "120 min",
    pairs: "a Mini Guinness.",
    drinkRecipe: "Shot of Kahlua topped with Baileys.",
    rated: "PG",
    picture1: innerspace1,
    picture2: innerspace2,
    picture3: innerspace3,
    tagline: "THAT'S A BAD AMIGO!",
    taglineWriter: "Martin Steve",
    bulletOne:
      "Martin Short and Meg Ryan having the same haircut with a different color.",
    bulletTwo:
      "Pulling a man card for a bit of privacy to get off with your friends girl.",
    cast: "Directed by JOE DANTE starring MARTIN SHORT DENNIS QUAID MEG RYAN and KEVIN McCARTHY",
  },
  {
    id: 1049,
    name: "The Gray Man",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOWY4MmFiY2QtMzE1YS00NTg1LWIwOTQtYTI4ZGUzNWIxNTVmXkEyXkFqcGdeQXVyODk4OTc3MTY@._V1_SX300.jpg",
    rating: 4,
    year: 2022,
    synopsis:
      "Starting a movie in Bangkok with Ryan Gosling can lead to disaster (see: Only God Forgives), but in this case forgiveness is unneccessary - we have a solid action movie on our hands. ",
    genre: "Action",
    subgenre: "Crime",
    runtime: "122 min",
    pairs: "Just Another Thursday.",
    drinkRecipe:
      "Grey goose, Creme de violette, lemon juice, maraschino cherry liquer.",
    rated: "PG-13",
    picture1: grayman1,
    picture2: grayman2,
    picture3: grayman3,
    tagline: "Good action? Czech (5-0)",
    taglineWriter: "xXx",
    bulletOne:
      "Wondering if Captain America's mustache was as heavily insured as Superman's.",
    bulletTwo: "Throwing one ethical and honorable hitman in the mix.",
    cast: "Directed by ANTHONY RUSSO and JOE RUSSO starring RYAN GOSLING CHRIS EVANS ANA DE ARMAS and JESSICA HENWICK",
  },
  {
    id: 1050,
    name: "Nope",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGIyNTI3NWItNTJkOS00MGYyLWE4NjgtZDhjMWQ4Y2JkZTU5XkEyXkFqcGdeQXVyNjY1MTg4Mzc@._V1_SX300.jpg",
    rating: 1,
    year: 2022,
    synopsis:
      "Should you watch this movie? Nope. Monkey's Paw really slipped on the banana Peele in this long, boring, and unfunny/unscary shitheap.",
    genre: "Horror",
    subgenre: "Mystery",
    runtime: "135 min",
    pairs: "a Rusty Nickel.",
    drinkRecipe: "Drambuie and Scotch.",
    rated: "R",
    picture1: nope1,
    picture2: nope2,
    picture3: nope3,
    tagline: "Nah.",
    taglineWriter: "Dr. No",
    bulletOne: "No explanation or resolution for anything.",
    bulletTwo:
      "'Full House', 'Saved by the Bell'... maybe 'Gordy's Home!', will get a revamp?",
    cast: "Directed by JORDAN PEELE starring DANIEL KALUUYA KEKE PALMER BRANDON PEREA and STEVEN YUEN",
  },
  {
    id: 1051,
    name: "Cat's Eye",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTA0MDliYzMtYTNhMS00MjkyLWExMGItYzY5MjFhOTNmZjRhXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    year: 1985,
    synopsis:
      "Three for one special on 80's King. A clever Cat blunders into anti-smoking extremists, an adulterer's balancing act, and one Borrower's home invasion.",
    genre: "Horror",
    subgenre: "Cult Classic",
    runtime: "94 min",
    pairs: "a fuzzball.",
    drinkRecipe: "cigs and champagne.",
    rated: "PG-13",
    picture1: catseye1,
    picture2: catseye2,
    picture3: catseye3,
    tagline: "KILLER SOUNDTRACK!",
    taglineWriter: "Cats Evens",
    bulletOne: "Sweet insults from Donatti.",
    bulletTwo: "Kounting all of the references.",
    cast: "Directed by LEWIS TEAGUE starring JAMES WOODS ROBERT HAYS DREW BARRYMORE and CAT",
  },
  {
    id: 1052,
    name: "Honor Society",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODk0MjFlY2YtOGFmMi00OTVjLTkwMzctOTdlYzYzNTZkMmRmXkEyXkFqcGdeQXVyMDA4NzMyOA@@._V1_.jpg",
    rating: 4,
    year: 2022,
    synopsis:
      "Three students unwittingly spar for a valedictorian victory with a determined little bitch.",
    genre: "Comedy",
    subgenre: "Romcom",
    runtime: "97 min",
    pairs: "a rape prevention roofie.",
    drinkRecipe: "Lemon-Lime Airborne in a Corona.",
    rated: "TV-MA",
    picture1: honorsociety1,
    picture2: honorsociety2,
    picture3: honorsociety3,
    tagline: "SHE'D MAKE A GREAT CLASS PRESIDENT!",
    taglineWriter: "Matty Broderick",
    bulletOne: "30 year old high schoolers.",
    bulletTwo: "Super harsh rating, it's barely TV-14.",
    cast: "Directed by ORAN ZEGMAN starring GATEN MATARAZZO ANGOURIE RICE CHRISTOPHER MINTZ-PLASSE and AVERY KONRAD",
  },
  {
    id: 1053,
    name: "Empire of the Sun",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmQwNzczZDItNmI0OS00MjRmLTliYWItZWIyMjk1MTU4ZTQ4L2ltYWdlXkEyXkFqcGdeQXVyNTAyODkwOQ@@._V1_SX300.jpg",
    rating: 4,
    year: 1987,
    synopsis:
      "Separated from his parents during the Japanese invasion of Shanghai, a boy does whatever it takes to survive.",
    genre: "War",
    subgenre: "Action",
    runtime: "153 min",
    pairs: "a Kamikaze.",
    drinkRecipe: "Vodka, orange liquer, lime juice.",
    rated: "PG",
    picture1: empireSun1,
    picture2: empireSun2,
    picture3: empireSun3,
    tagline: "POW right in the kisser!",
    taglineWriter: "Jackie Gleason",
    bulletOne:
      "Whenever Christian Bale plays a rich child, you know he's fucked.",
    bulletTwo: "John Malkovich playing a duplicitous, untrustworthy snake.",
    cast: "Directed by STEVEN SPIELBERG starring CHRISTIAN BALE JOHN MALKOVICH JOE PANTOLIANO and TAKATARO KATAOKA",
  },
  {
    id: 1054,
    name: "Adventures in Babysitting",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZmI3MjM2YTYtZTAxMS00NDA5LWIxODctZjhjMmNkMmRmMWI5XkEyXkFqcGdeQXVyNTI4MjkwNjA@._V1_SX300.jpg",
    rating: 4,
    year: 1987,
    synopsis:
      "Soft suburbanites battle sinful city slickers during a slow sojourn to the subway station. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "102 min",
    pairs: "a Bohunk.",
    drinkRecipe: "Vodka and orangina",
    rated: "PG-13",
    picture1: babysitting1,
    picture2: babysitting2,
    picture3: babysitting3,
    tagline: "SHUE'S GOT THE BLUES",
    taglineWriter: "Muddy Waters",
    bulletOne:
      "Completely lazy and asynchronous censorship if you watch on Disney+.",
    bulletTwo:
      "The final in-shape movie for Vince D'onofrio before he became Blintz D'onutfrio.",
    cast: "Directed by CHRIS COLUMBUS starring ELISABETH SHUE MAIA BREWTON KEITH COOGAN and ANTHONY REPP ",
  },
  {
    id: 1055,
    name: "Mississippi Grind",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzU1YzdjNDktNDI5Ny00OGM3LTk5ZDUtNWVmMDQwNzNlZjliXkEyXkFqcGdeQXVyNTA3MTU2MjE@._V1_SX300.jpg",
    rating: 4,
    year: 2015,
    synopsis:
      "Two degenerate gamblers take a road trip to New Orleans for a poker tournamnet. ",
    genre: "Drama",
    subgenre: "",
    runtime: "108 min",
    pairs: "",
    noPairs: "a glass of Woodford Reserve",
    drinkRecipe: "",
    rated: "R",
    picture1: grind1,
    picture2: grind2,
    picture3: grind3,
    tagline: "THESE GUYS ARE BAD LUCK!",
    taglineWriter: "The Cooler",
    bulletOne: "Learning when to hold 'em vs. fold 'em.",
    bulletTwo:
      "Ben Mendelsohn plays a really convincing piece of shit who doesn't know when  to quit.",
    cast: "Directed by ANNA BODEN and RYAN FLECK starring RYAN REYNOLDS BEN MENDELSOHN YVONNE LANDRY and STEPHANIE HONORE",
  },
  {
    id: 1056,
    name: "Fourth of July",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzU4NDI4ZTYtNTFiMy00M2NlLWJkYzktOTlhMmZkYzE5NjY1XkEyXkFqcGdeQXVyMzAxNTY5MzY@._V1_SX300.jpg",
    rating: 4,
    year: 2022,
    synopsis:
      "A recently sober pianist grudgingly attends his drunk family's 4th of July party to confess his true feelings for them.",
    genre: "Comedy",
    subgenre: "Drama",
    runtime: "90 min",
    pairs: "a Sponsor/Sponsee.",
    drinkRecipe: "Beer/shot of whiskey.",
    rated: "N/A",
    picture1: fourthJuly1,
    picture2: fourthJuly2,
    picture3: fourthJuly3,
    tagline: "A TRULY LOVING FAMILY!",
    taglineWriter: "Charles Manson",
    bulletOne: "Accurate portayal of East Coast assholes.",
    bulletTwo: "Busting balls as the main way to show love.",
    cast: "Directed by LOUIS CK starring JOE LIST LYNNE KOPLITZ NICK DEPAULO and TARA PACHECO",
  },
  {
    id: 1057,
    name: "Thelma & Louise",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjIxNDgzMDE2MF5BMl5BanBnXkFtZTcwNzY5NTk1NA@@._V1_SX300.jpg",
    rating: 4,
    year: 1991,
    synopsis:
      "Two gal-pals go on a weekend getaway that quickly takes turn and goes for a plunge.",
    genre: "Crime",
    subgenre: "Drama",
    runtime: "130 min",
    pairs: "a Blind Turn.",
    drinkRecipe:
      "Wild turkey and coke followed by a margarita and shot of cuervo",
    rated: "R",
    picture1: thelma1,
    picture2: thelma2,
    picture3: thelma3,
    tagline: "WOMAN DRIVER, NO SURVIVOR",
    taglineWriter: "Confucius",
    bulletOne: "Brad Pitt is a little shitt.",
    bulletTwo: "This movie really makes women look like terrible drivers.",
    cast: "Directed by RIDLEY SCOTT starring SUSAN SARANDON GEENA DAVIS HARVEY KEITEL and BRAD PITT",
  },
  {
    id: 1058,
    name: "Serious Men",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDQ3MDEwNTEtZTlmZi00MjA2LWExZTYtNmMwNmZkNmI0ZTczXkEyXkFqcGdeQXVyMTE5MDgzMjMx._V1_SX300.jpg",
    rating: 4,
    year: 2020,
    synopsis:
      "A Slumdog Hundredaire cultivates a boy-genius reputation for his son in order to give him a better life.",
    genre: "Drama",
    subgenre: "Comedy",
    runtime: "114 min",
    pairs: "Newton's Fifth Law.",
    drinkRecipe: "Sour apple schnapps, green apple vodka, midori.",
    rated: "N/A",
    picture1: seriousMen1,
    picture2: seriousMen2,
    picture3: seriousMen3,
    tagline: "Adi can't add-i at all!",
    taglineWriter: "Addis Ababa Arithmetic Association",
    bulletOne: "Pretending to love science.",
    bulletTwo:
      "Shouting 'I can't deal with primitive minds like you!' to win any argument.",
    cast: "Directed by SUDHIR MISHRA and SACHIN KRISHN starring NAWAZUDDIN SIDDIQUI NASSAR AAKSHATH DAS and INDIRA TIWARI",
  },
  {
    id: 1059,
    name: "Top Gun: Maverick",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOWQwOTA1ZDQtNzk3Yi00ZmVmLWFiZGYtNjdjNThiYjJhNzRjXkEyXkFqcGdeQXVyODE5NzE3OTE@._V1_SX300.jpg",
    rating: 6,
    year: 2022,
    synopsis:
      "Maverick teaches the latest crop of Top Gunners how to fly like a boss. An outstanding throwback to the summer blockbuster.",
    genre: "Action",
    subgenre: "War",
    runtime: "130 min",
    pairs: "an Iceman.",
    drinkRecipe: "ice 101, orange juice, sprite.",
    rated: "PG-13",
    picture1: topgunMaverick1,
    picture2: topgunMaverick2,
    picture3: topgunMaverick3,
    tagline: "The game has changed but the players are the same",
    taglineWriter: "Macgruber",
    bulletOne: "No preachy woke bullshit, just good ol' fashioned action.",
    bulletTwo:
      "Artfully winking at things from the first movie without overdoing it.",
    cast: "Directed by JOSEPH KOSINSKI starring TOM CRUISE JENNIFER CONNELLY MILES TELLER and VAL KILMER",
  },
  {
    id: 1060,
    name: "Elvis",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzMzNTJjYmMtZTkxNS00MjI4LWI3YmQtOTQ4MDZjZDJlZjQyXkEyXkFqcGdeQXVyNjc0NzQzNTM@._V1_SX300.jpg",
    rating: 6,
    year: 2022,
    synopsis:
      "This flick explores Elvis' life from boyhood all the way through superstardom. ",
    genre: "Drama",
    subgenre: "",
    runtime: "159 min",
    pairs: "a Blue Suede Shoe.",
    drinkRecipe: "Vodka, blue curacao, lime juice, cranberry juice.",
    rated: "PG-13",
    picture1: elvis1,
    picture2: elvis3,
    picture3: elvis2,
    tagline: "I'M IN LOVE!",
    taglineWriter: "Amal Shookup",
    bulletOne:
      "Austin Butler does a tremendous job looking, sounding, and moving like Elvis.",
    bulletTwo:
      "They did a really good job making this period piece look authentic.",
    cast: "Directed by BAZ LUHRMAN starring AUSTIN BUTLER TOM HANKS HELEN THOMSON and RICHARD ROXBURGH",
  },
  {
    id: 1061,
    name: "Splash",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDRmZTQ0MDQtNWRmYS00NDhhLTgzNzUtZGIyYzkwZDMyNTc0L2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    year: 1983,
    synopsis:
      "Tom Hanks dates a woman who seems mentally deranged until it turns out she's just a mermaid (who's mentally deranged) in this bizarre romcom from the 80's. ",
    genre: "Romcom",
    subgenre: "Comedy",
    runtime: "111 min",
    pairs: "a Treasured Chest.",
    drinkRecipe: "Pussers rum, cream of coconut, pineapple juice.",
    rated: "PG",
    picture1: splash1,
    picture2: splash2,
    picture3: splash3,
    tagline: "YOU'VE GOT TO SEA THIS!",
    taglineWriter: "Long John Silver",
    bulletOne:
      "How did they fuck? I guess he gave her bass to mouth. Hope he didn't pull a mussel.",
    bulletTwo:
      "Not sure how she got money. Must've sold some stuff at the prawn shop.",
    cast: "Directed by RON HOWARD starring TOM HANKS DARYL HANNAH EUGENE LEVY and JOHN CANDY",
  },
  {
    id: 1062,
    name: "Last Looks",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTRjMjAzOGMtM2JmNi00YjkxLTk0ZGQtZDE5YjlmNWZhZjNiXkEyXkFqcGdeQXVyMzQwMTY2Nzk@._V1_SX300.jpg",
    rating: 3,
    year: 2021,
    synopsis:
      "An ex-detective is dragged out of retirement to investigate the murder of a high-profile actor's wife.",
    genre: "Crime",
    subgenre: "Mystery",
    runtime: "110 min",
    pairs: "",
    noPairs: "Makers Mark.",
    drinkRecipe: "",
    rated: "R",
    picture1: lastLooks1,
    picture2: lastLooks2,
    picture3: lastLooks3,
    tagline: "WHODUNNIT?",
    taglineWriter: "Whodini",
    bulletOne:
      "Mel Gibson's facial hair is as good as Charlie Hunnam's is bad.",
    bulletTwo:
      "Kudos to Mel for doing an Oliver Reed impression the whole movie.",
    cast: "Directed by TIM KIRBY starring MEL GIBSON CHARLIE HUNNAM LUCY FRY and RUPERT FRIEND",
  },
  {
    id: 1063,
    name: "Event Horizon",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGI0NDMwNjAtNGEzNC00MzA1LTlkMjQtYjBkYTZlZjAyZWEyXkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 6,
    year: 1997,
    synopsis:
      "A rescue crew heads into deep space to investigate a ship that mysteriously returned from a black hole. ",
    genre: "Horror",
    subgenre: "Scifi",
    runtime: "96 min",
    pairs: "a Corpsicle.",
    drinkRecipe: "Vodka, simple syrup, ice, frozen raspberries. ",
    rated: "R",
    picture1: eventHorizon1,
    picture2: eventHorizon2,
    picture3: eventHorizon3,
    tagline: "IT REALLY SUCKS YOU IN",
    taglineWriter: "Sarlacc Blacc Hole",
    bulletOne:
      "Top notch atmosphere and visual effects. Truly creepy and unsettling.",
    bulletTwo: "Still using CD's in the year 2047. A bold prediction.",
    cast: "Directed by PAUL W.S. ANDERSON starring LAURENCE FISHBURNE SAM NEILL JOELY RICHARDSON and JASON ISAACS",
  },
  {
    id: 1064,
    name: "1922",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTFmYmJkM2YtYzFjNS00NGI2LThiOWMtNGVhZDZiOGI0NDBhXkEyXkFqcGdeQXVyNjU1OTMzNDU@._V1_SX300.jpg",
    rating: 4,
    year: 2017,
    synopsis: "A father/son duo feel bad for murdering their wife/mother.",
    genre: "Horror",
    subgenre: "Thriller",
    runtime: "102 min",
    pairs: "a Dement Hystericus.",
    drinkRecipe: "cinnamon schnapps, sarsaparilla, lime, bourbon",
    rated: "TV-MA",
    picture1: nineteen1,
    picture2: nineteen2,
    picture3: nineteen3,
    tagline: "This fella needs to uh-nunciate, mmhmm",
    taglineWriter: "Sling Blade",
    bulletOne: "Being extremely amenable to matricide.",
    bulletTwo:
      "The extent of a murder investigation being 'Did you kill her?'. 'Nope'. 'Ok, case closed.'",
    cast: "Directed by ZAK HILDITCH starring THOMAS JANE MOLLY PARKER DYLAN SCHMID and NEAL MCDONOUGH",
  },
  {
    id: 1065,
    name: "The Brothers Grimsby",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjE0NTE3MjMwNV5BMl5BanBnXkFtZTgwMDc5NjQxODE@._V1_SX300.jpg",
    rating: 6,
    year: 2016,
    synopsis:
      "Brothers separated during youth reunite after 28 years to find they are VERY different. This movie is goddamn hilarious, definitely watch it. ",
    genre: "Comedy",
    subgenre: "Action",
    runtime: "83 min",
    pairs: "Lonomia Caterpillar Toxin.",
    drinkRecipe: "Kahlua, mint chocolate chip liquer, crushed ice.",
    rated: "R",
    picture1: grimsby1,
    picture2: grimsby2,
    picture3: grimsby3,
    tagline: "A MOVIE YOU'LL NEVER FORGET",
    taglineWriter: "Elephant",
    bulletOne: "Reminding the world that not all Brits are posh.",
    bulletTwo:
      "Taking jokes to the absolute limit, and then blowing past that.",
    cast: "Directed by LOUIS LETERRIER starring SACHA BARON COHEN MARK STRONG REBEL WILSON and ISLA FISHER",
  },
  {
    id: 1066,
    name: "The Dictator",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTlkOWYzZDYtNzQ1MS00YTNkLTkyYTItMjBmNjgyMDBlMjI4XkEyXkFqcGdeQXVyNTIzOTk5ODM@._V1_SX300.jpg",
    rating: 4,
    year: 2012,
    synopsis:
      "A humble despot has but one simple wish for his country's people: to keep them oppressed and free of democracy forever.",
    genre: "Comedy",
    subgenre: "",
    runtime: "83 min",
    pairs: "a Textbook Drowning.",
    drinkRecipe: "vodka, sprite, cherry, spherical ice cube.",
    rated: "R",
    picture1: dictator1,
    picture2: dictator2,
    picture3: dictator3,
    tagline: "Wadiya think of the movie? I think it's Aladeen!",
    taglineWriter: "Nuclear Nadal",
    bulletOne:
      "Causing extreme discomfort when speaking foreign languages in an aircraft.",
    bulletTwo:
      "Learning that anyone from outside America is technically an A-rab.",
    cast: "Directed by LARRY CHARLES starring SACHA BARON COHEN ANNA FARIS JOHN C. REILLY and BEN KINGSLEY",
  },
  {
    id: 1067,
    name: "Career Opportunities",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTJmMjI5NTktMTE5NS00MWFhLTk5N2UtYjJhMTcxMmRiYWFlXkEyXkFqcGdeQXVyNjE5MjUyOTM@._V1_SX300.jpg",
    rating: 4,
    year: 1991,
    synopsis:
      "A loser who always gets fired for goofing off gets a new job and commits about 113 fireable offenses on day one.",
    genre: "Comedy",
    subgenre: "Romcom",
    runtime: "83 min",
    pairs: "a Red Hot.",
    drinkRecipe: "absolut peppar, orange juice, tabasco",
    rated: "PG-13",
    picture1: careerOpportunities1,
    picture2: careerOpportunities2,
    picture3: careerOpportunities3,
    tagline: "THIS GUY'S A SLACKER!",
    taglineWriter: "Principal Strickland",
    bulletOne: "Eternal babe Jennifer Connelly.",
    bulletTwo:
      "After moving to LA, Jim changed his name to 'Brett' and made the fatal mistake of crossing Marsellus Wallace. ",
    cast: "Directed by BRYAN GORDON starring FRANK WHALEY JENNIFER CONNELLY DERMOT MULRONEY and KIERAN MULRONEY",
  },
  {
    id: 1068,
    name: "Scarface",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjdjNGQ4NDEtNTEwYS00MTgxLTliYzQtYzE2ZDRiZjFhZmNlXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 6,
    year: 1983,
    synopsis:
      "A modern-day Icarus busts his butt and achieves the American Dream, but he's too legit to quit and the dream becomes a nightmare.",
    genre: "Crime",
    subgenre: "",
    runtime: "170 min",
    pairs: "Cuba Libre.",
    drinkRecipe: "rum, coke, lime",
    rated: "R",
    picture1: scarface1,
    picture2: scarface2,
    picture3: scarface3,
    tagline: "Addressing people by deformities is funny",
    taglineWriter: "Captain Hook",
    bulletOne: "Don Eladio has been in the coke game forever.",
    bulletTwo:
      "Getting high on your own supply and telling the truth even when you lie.",
    cast: "Directed by BRIAN DE PALMA starring AL PACINO STEVEN BAUER ROBERT LOGGIA and MICHELLE PFEIFFER",
  },
  {
    id: 1069,
    name: "Donnie Brasco",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzMzMDZkYWEtODIzNS00YjI3LTkxNTktOWEyZGM3ZWI2MWM4XkEyXkFqcGdeQXVyNzkwMjQ5NzM@._V1_SX300.jpg",
    rating: 5,
    year: 1997,
    synopsis: "Need to review",
    genre: "Crime",
    subgenre: "",
    runtime: "127 min",
    pairs: "Brass Balls.",
    drinkRecipe: "double whiskey, two ice spheres",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Fugedaboudit",
    taglineWriter: "I forgot",
    bulletOne: "k",
    bulletTwo:
      "Beating the shit out of people who ask you to remove your shoes at their place.",
    cast: "Directed by MIKE NEWELL starring AL PACINO JOHNNY DEPP MICHAEL MADSEN and ANNE HECHE",
  },
  {
    id: 1070,
    name: "See How They Run",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzU5OTNmZDYtZjA4OC00YmQyLTk4ZjctMzVlZTYyYTE3ODUwXkEyXkFqcGdeQXVyMjM4NTM5NDY@._V1_SX300.jpg",
    rating: 4,
    year: 2022,
    synopsis:
      "A fun whodunit wherein a female Bobbie attempts to talk the killer into confessing; as long as her partner (accent unknown) doesn't lock her up first.",
    genre: "Crime",
    subgenre: "Mystery",
    runtime: "98 min",
    pairs: "Rat Poison Tea.",
    drinkRecipe: "iced tea with gin",
    rated: "PG-13",
    picture1: seerun1,
    picture2: seerun2,
    picture3: seerun3,
    tagline: "Dreadfully unlikeable characters.",
    taglineWriter: "Aggy Christine",
    bulletOne: "Cinema is better than theater.",
    bulletTwo: "Maybe get a Jump to Conclusions mat.",
    cast: "Directed by TOM GEORGE starring SAM ROCKWELL  SAOIRSE RONAN  RUTH WILSON and ADRIEN BRODY",
  },
  {
    id: 1071,
    name: "Father Stu",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNGYwOTI1MTUtYmY0Mi00OTAxLTkwNDEtOTI5YTkyZTVhN2JiXkEyXkFqcGdeQXVyMTAxNDE3MTE5._V1_SX300.jpg",
    rating: 5,
    year: 2022,
    synopsis:
      "Could do without all the church love but the guy from Fear beefs up big time and pulls out a great performance.",
    genre: "Drama",
    subgenre: "Comedy",
    runtime: "124 min",
    pairs: "Catholic's Brew.",
    drinkRecipe: "Guinness over Ambar Especial",
    rated: "R",
    picture1: fatherstu1,
    picture2: fatherstu2,
    picture3: fatherstu3,
    tagline: "A GODDAMN INSPIRATION!",
    taglineWriter: "J.H. Christ",
    bulletOne: "Proving kids who don't play sports become weirdo adults.",
    bulletTwo:
      "Pulling a total dirtbag move knowing that it's cool cause God will forgive you.",
    cast: "Directed by ROSALIND ROSS starring MARK WAHLBURG  JACKI WEAVER  MEL GIBSON and TERESA RUIZ",
  },
  {
    id: 1072,
    name: "Doc Hollywood",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTcwMjZlZTgtM2VlMC00MDZkLWIzODQtMDRhZjE0N2QzMTgwXkEyXkFqcGdeQXVyMjY3MjUzNDk@._V1_SX300.jpg",
    rating: 4,
    year: 1991,
    synopsis: "",
    genre: "Drama",
    subgenre: "Comedy",
    runtime: "104 min",
    pairs: "",
    noPairs: "dr pepper",
    drinkRecipe: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by MICHAEL CATON-JONES starring MICHAEL J. FOX JULIE WARNER WOODY HARRELSON and BARNARD HUGHES",
  },
  {
    id: 1073,
    name: "Vice",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTY1NjM0MzgxMV5BMl5BanBnXkFtZTgwNDc4NTY0NjM@._V1_SX300.jpg",
    rating: 4,
    year: 2018,
    synopsis: "",
    genre: "Drama",
    subgenre: "Comedy",
    runtime: "104 min",
    pairs: "",
    drinkRecipe: "",
    rated: "R",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "havent finished",
    bulletTwo: "",
    cast: "Directed by ADAM MCKAY starring CHRISTIAN BALE AMY ADAMS STEVE CARELL and SAM ROCKWELL",
  },
  {
    id: 1074,
    name: "Angel Heart",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZmQ3Mzg3YjgtNTA1Zi00ODgyLTkyZGUtYTE5NDA5ZmI4NjI1L2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTA0MjU0Ng@@._V1_SX300.jpg",
    rating: 4,
    year: 1987,
    synopsis:
      "Johnny's least favorite benefactor hires a NYC gumshoe to head south of the gumbo line and track down the MIA crooning ladies man.",
    genre: "Drama",
    subgenre: "Mystery",
    runtime: "116 min",
    pairs: "Nails on a Chalk Board.",
    drinkRecipe: "Captain morgan loconut and fireball",
    rated: "X",
    picture1: angelheart1,
    picture2: angelheart2,
    picture3: angelheart3,
    tagline: "DYNAMIC RELATIONSHIPS!",
    taglineWriter: "G. Richard Raymond M.",
    bulletOne: "Hoping for a Toots Sweet prequel.",
    bulletTwo:
      "For an X-rating, we were expecting Bobby DeNiro to have a full penetration scene.",
    cast: "Directed by ALAN PARKER starring MICKEY ROURKE  ROBERT DE NIRO  LISA BONET and CHAROLETTE RAMPLING",
  },
  {
    id: 1075,
    name: "eXistenZ",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmU1MTJkYWItMzM5Ny00NDgxLTgxOGEtNTkzNDdkZjkwNGI0XkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: 4,
    year: 1999,
    synopsis:
      "Two video game employees get sucked into a life or death fight against NPC's with tooth guns, horrible accents, and attention deficit disorders.",
    genre: "SciFi",
    subgenre: "Action",
    runtime: "97 min",
    pairs: "a Pikul-Back.",
    drinkRecipe: "pickle juice, lemon lime gatorade, whiskey",
    rated: "R",
    picture1: existenz1,
    picture2: existenz2,
    picture3: existenz3,
    tagline: "LEIGH WAS TOERRIFIC!",
    taglineWriter: "tArantinO",
    bulletOne: "People definitely bang eachothers bio-ports.",
    bulletTwo: "Law deserves an Oscar nod for eating that gross ass meal.",
    cast: "Directed by DAVID CRONENBERG starring JENNIFER JASON LEIGH  JUDE LAW  IAN HOLM and WILLEM DAFOE",
  },
  {
    id: 1076,
    name: "Werewolves Within",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDlkZmY3ZGUtMjY3ZS00MGE4LWFjMjYtZjNmYTE0YWYxOTJmXkEyXkFqcGdeQXVyODk4OTc3MTY@._V1_SX300.jpg",
    rating: 4,
    year: 2021,
    synopsis:
      "Weary woodspeople wonder whether winter will whither or waiver. Oh and also there's a werewolf.",
    genre: "Horror",
    subgenre: "Comedy",
    runtime: "97 min",
    pairs: "a White Wash.",
    drinkRecipe: "Monster zero white and Hennessy Pure White",
    rated: "R",
    picture1: werewolvesin1,
    picture2: werewolvesin2,
    picture3: werewolvesin3,
    tagline: "I'VE SEEN STRANGER THINGS!",
    taglineWriter: "Finn Werewolfhard",
    bulletOne: "Odd there wasn't any cell reception.",
    bulletTwo: "This town should just pass gas already.",
    cast: "Directed by JOSH RUBEN starring SAM RICHARDSON  MILANA VAYNTRUB  GEORGE BASIL and HARVEY GUILLÉN",
  },
  {
    id: 1077,
    name: "The Wolf of Snow Hollow",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOWZlMDBiMmItMWU0Ny00MGIzLWI1NTQtZDEwMjM0MzBiYjhkXkEyXkFqcGdeQXVyMzIxMjMyODY@._V1_SX300.jpg",
    rating: 3,
    year: 2021,
    synopsis:
      "The Sheriff-to-be of a small town gets more than he bargained for while hunting a big, bad, local predator.",
    genre: "Horror",
    subgenre: "Comedy",
    runtime: "85 min",
    pairs: "a Half Sober.",
    drinkRecipe: "1/2 vodka, 1/2 water",
    rated: "R",
    picture1: wolfhollow1,
    picture2: wolfhollow2,
    picture3: wolfhollow3,
    tagline: "HADLEY'S STILL A HUNK!",
    taglineWriter: "Jackie Brown",
    bulletOne: "The bigot has a shockingly level-headed buddy.",
    bulletTwo: "It's cool to leave your kid at the table with a total freak.",
    cast: "Directed by JIM CUMMINGS starring JIM CUMMINGS RIKI LINDHOME CHLOE EAST and ROBERT FORSTER",
  },
  {
    id: 1078,
    name: "Ghost World",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNWYwODRlYjgtODUxNy00YmMyLWE3NWYtNTYzZmUwNDJiZGVlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    year: 2001,
    synopsis:
      "Two recent highschool grads judge and hate everyone on the face of the Earth.",
    genre: "Comedy",
    subgenre: "Drama",
    runtime: "111 min",
    noPairs: "two month old champagne.",
    rated: "R",
    picture1: ghostworld1,
    picture2: ghostworld2,
    picture3: ghostworld3,
    tagline: "THIS AIN'T GOT NO GHOST!",
    taglineWriter: "Ray Parker II",
    bulletOne: "Don't bang your heroes.",
    bulletTwo:
      "Using a Word doc to Google 3D images of slightly below the uterus on a female.",
    cast: "Directed by TERRY ZWIGOFF starring THORA BIRCH  SCARLETT JOHANSSON  STEVE BUSCEMI and BRAD RENFRO",
  },
  {
    id: 1079,
    name: "Raising Cain",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjMzZjY3OTQtZjRjNi00YTIyLWFkMGQtZTFkYTg4ODk5YjFhXkEyXkFqcGdeQXVyMjUzOTY1NTc@._V1_SX300.jpg",
    rating: 4,
    year: 1992,
    synopsis:
      "A family of child psychologists attempt to recruit new patients and the long awaited arrival of Harriet and the Hendersons.",
    genre: "Drama",
    subgenre: "Horror",
    runtime: "92 min",
    pairs: "a Lab Rat.",
    drinkRecipe: "coffee liqueur, starbucks nitro cold brew.",
    rated: "R",
    picture1: raisingcain1,
    picture2: raisingcain2,
    picture3: raisingcain3,
    tagline: "YOUR MIND WILL BE SPLIT!",
    taglineWriter: "Mennonite Shaman",
    bulletOne: "Cheaters should never win.",
    bulletTwo: "Lithgow pulls a Murphy in this family reunion.",
    cast: "Directed by BRIAN De PALMA starring JOHN LITHGOW  LOLITA DAVIDOVICH  STEVEN BAUER and FRANCES STERNHAGEN",
  },
  {
    id: 1080,
    name: "You Were Never Really Here",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDkwOTE0ZjMtZmRiYS00M2M3LWE3MzUtNzNmNmExNTNmNjg5XkEyXkFqcGdeQXVyODE1MjMyNzI@._V1_SX300.jpg",
    rating: 3,
    year: 2017,
    synopsis:
      "Joe and his PTSD attempt to save a young girl from a lifetime of abuse.",
    genre: "Drama",
    subgenre: "Crime",
    runtime: "89 min",
    pairs: "a hammer to the head.",
    drinkRecipe: "equal parts jägermeister and barcardi 151 with coke and ice.",
    rated: "R",
    picture1: neverhere1,
    picture2: neverhere2,
    picture3: neverhere3,
    tagline: "HOW I WISH!",
    taglineWriter: "Blue Llyod",
    bulletOne: "Wishing Joe had met Epstein.",
    bulletTwo: "More about drama and cool shots than gore and fight scenes.",
    cast: "Directed by LYNNE RAMSAY starring JOAQUIN PHOENIX  JUDITH ROBERTS  EKATERINA SAMSONOV  and JOHN DOMAN",
  },
  {
    id: 1081,
    name: "Urban Legend",
    poster:
      "https://m.media-amazon.com/images/I/81zY4te7hkL._AC_UY436_FMwebp_QL65_.jpg",
    rating: 4,
    year: 1998,
    synopsis:
      "Liberal arts students spend their super-duper senior year taking blow-off classes.",
    genre: "Horror",
    subgenre: "Mystery",
    runtime: "99 min",
    pairs: "a Blue Razz Funnel.",
    drinkRecipe:
      "Vodka, Kool aid blue raspberry lemonade. Rim salted with pop rocks",
    rated: "R",
    picture1: urbanlegend1,
    picture2: urbanlegend2,
    picture3: urbanlegend3,
    tagline: "I feel like destroying Paul's face!",
    taglineWriter: "The Narrator",
    bulletOne: "The pooch got screwed. Twice.",
    bulletTwo:
      "Intro to Myths & Allegories and Archetype Generalization II (lab).",
    cast: "Directed by JAMIE BLANKS starring ALICIA WITT  JARED LETO  REBECCA GAYHEART and TARA REID",
  },
  {
    id: 1082,
    name: "Vibes",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjgwM2FlYWYtMGM1ZC00NDY4LTgzMDAtOTcxMDEzODkyOWFjL2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTYxNjkxOQ@@._V1_SX300.jpg",
    rating: 4,
    year: 1988,
    synopsis:
      "An ice cream thief convinces a pair of psychics to help him find a lost Incan city filled with blinding wealth.",
    genre: "Comedy",
    subgenre: "Adventure",
    runtime: "99 min",
    pairs: "a Flaming Dr. Nobel.",
    drinkRecipe: "Svedka, Fireball, & mtn dew code red.",
    rated: "PG",
    picture1: vibes1,
    picture2: vibes2,
    picture3: vibes3,
    tagline: "Who is this chick? 𝙎𝙝𝙚'𝙨 𝙎𝙤 𝙐𝙣𝙪𝙨𝙪𝙖𝙡 !",
    taglineWriter: "Sandy Looper",
    bulletOne:
      "With each outfit dumber than the next, Columbo resembles a retarded Scarface.",
    bulletTwo: "Goldblum will find any reason to expose his chest.",
    cast: "Directed by KEN KWAPIS starring CYNDI LAUPER  JEFF GOLDBLUM  PETER FALK and GOOGY GRESS",
  },
  {
    id: 1083,
    name: "Last Night in Soho",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjgwZDIwY2MtNGZlNy00NGRlLWFmNTgtOTBkZThjMDUwMGJhXkEyXkFqcGdeQXVyMTEyMjM2NDc2._V1_SX300.jpg",
    rating: 4,
    year: 2021,
    synopsis:
      "A country girl arrives fashionably late to the big city in search of her big break.",
    genre: "Mystery",
    subgenre: "Drama",
    runtime: "116 min",
    pairs: "a Vespah.",
    drinkRecipe: "100 proof vodka, Lillet Blanc, and Tanqueray.",
    rated: "R",
    picture1: lastsoho1,
    picture2: lastsoho2,
    picture3: lastsoho3,
    tagline: "WHERE'S THE CORNETTO?!",
    taglineWriter: "Shaun o' da Ded",
    bulletOne:
      "Successfully pulling off, 'the Hero and the Pig' with your buddy.",
    bulletTwo: "Being able to adapt and change your dreams of stardom.",
    cast: "Directed by EDGAR WRIGHT starring THOMASIN McKENZIE  ANYA TAYLOR-JOY  MATT SMITH and DIANA RIGG",
  },
  {
    id: 1084,
    name: "Day Shift",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYWI2ZDRjYjEtOWJiOS00NWVjLWI1MjAtNWNiMzAwNGFmNjljXkEyXkFqcGdeQXVyODEwMTc2ODQ@._V1_SX300.jpg",
    rating: 4,
    year: 2022,
    synopsis:
      "A pool guy stuck on the wrong end of 9 to 5 is forced to partner up with a pencil-pushing weasel in order to pay his union dues.",
    genre: "Horror",
    subgenre: "Comedy",
    runtime: "113 min",
    pairs: "some dark blood.",
    drinkRecipe: "Gin, cherry coke, 2 oz. merlot",
    rated: "R",
    picture1: dayshift1,
    picture2: dayshift2,
    picture3: dayshift3,
    tagline: "HE SEES UNDEAD PEOPLE!",
    taglineWriter: "M. Night Shaft",
    bulletOne: "D Oh Double G.",
    bulletTwo: "Seeing your daughter grow old.",
    cast: "Directed by J.J. PERRY starring JAMIE FOXX  DAVE FRANCO  KARLA SOUZA and MEAGAN GOOD",
  },
  {
    id: 1085,
    name: "City Slickers",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTkwNjM2MTc5NF5BMl5BanBnXkFtZTcwMzQ2NjEzNA@@._V1_SX300.jpg",
    rating: 6,
    year: 1991,
    synopsis:
      "After an urbanite recieves a horrible birthday gift he and his buddies traverse the American West with the last real cowboy, pre-90's behavior, and two dentists (but let's not make an issue out of it).",
    genre: "Comedy",
    subgenre: "Adventure",
    runtime: "113 min",
    pairs: "fresh ground coffee.",
    drinkRecipe: "premier protein café latte, kalhua, coffee liqueur.",
    rated: "PG-13",
    picture1: cityslickers1,
    picture2: cityslickers2,
    picture3: cityslickers3,
    tagline: "Heiffer? I hardly know her!!",
    taglineWriter: "Norman",
    bulletOne: "Mr. Morelli's awesome career day presentation.",
    bulletTwo: "Jerk Gellynhall as the most annoying kid ever.",
    cast: "Directed by RON UNDERWOOD starring BILLY CRYSTAL  JACK PALANCE  DANIEL STERN and HELEN SLATER",
  },
  {
    id: 1086,
    name: "A Quiet Place",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjI0MDMzNTQ0M15BMl5BanBnXkFtZTgwMTM5NzM3NDM@._V1_SX300.jpg",
    rating: 2,
    year: 2018,
    synopsis:
      "Somehow humanity has forgotten it's ability to use superior weapons, intelligence, and technology to remain the apex of planet Earth.",
    genre: "Horror",
    subgenre: "Scifi",
    runtime: "90 min",
    pairs: "LOUD NOISES!",
    drinkRecipe: "smirnoff, lacroix black razzberry, pop rocks.",
    rated: "PG-13",
    picture1: quietplace1,
    picture2: quietplace2,
    picture3: quietplace3,
    tagline: "SHHHHH!!",
    taglineWriter: "Shush Illustrated",
    bulletOne:
      "John Blunt and Emily Krasinski need an unannounced visit by child protective services.",
    bulletTwo: "A librarian's wet dream, probably.",
    cast: "Directed by JOHN KRASINSKI starring EMILY BLUNT  JOHN KRASINSKI  MILLICENT SIMMONDS and NOAH JUPE",
  },
  {
    id: 1087,
    name: "The Witches of Eastwick",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmY3MDZkODktYjk4My00YWZmLThmOWItN2M1MjU5MDczZTQ1XkEyXkFqcGdeQXVyNjc5NjEzNA@@._V1_SX300.jpg",
    rating: 4,
    year: 1987,
    synopsis:
      "Banging three hot witches turns out to be fours company in this sleepy little town.",
    genre: "Horror",
    subgenre: "Fantasy",
    runtime: "118 min",
    pairs: "a Bitches Brew.",
    drinkRecipe: "Brandy, Lillet Blanc, and Suze.",
    rated: "R",
    picture1: witcheastwick1,
    picture2: witcheastwick2,
    picture3: witcheastwick3,
    tagline: "Damn! She's got A LOT of kids!",
    taglineWriter: "Octo-Mom",
    bulletOne: "Being mega confident in the face of disgust.",
    bulletTwo: "Losing your lunch from two weeks ago.",
    cast: "Directed by GEORGE MILLER starring SUSAN SARANDON MICHELLE PFEIFFER CHER and JACK NICHOLSON",
  },
  {
    id: 1088,
    name: "House",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGZkMDc1M2QtOWYyYy00ODZjLTk5OWQtOTI5NDVlYTRkY2FiXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 3,
    year: 1985,
    synopsis:
      "Carrie's ex-boyfriend moves into a mansion with a bunch of his Aunt's bad paintings and old junk...",
    genre: "Horror",
    subgenre: "Fantasy",
    runtime: "93 min",
    pairs: ".",
    drinkRecipe: ".",
    rated: "R",
    picture1: house851,
    picture2: house852,
    picture3: house853,
    tagline: "!",
    taglineWriter: "",
    bulletOne: "Really holding onto a grudge.",
    bulletTwo: "The deepest v-neck of all time.",
    cast: "Directed by STEVE MINER starring WILLIAM KATT  KAY LENZ  GEORGE WENDT and RICHARD MOLL",
  },
  {
    id: 1089,
    name: "Hellraiser",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOGRlZTdhOGYtODc5NS00YmJkLTkzN2UtZDMyYmRhZWM1NTQwXkEyXkFqcGdeQXVyMzU4Nzk4MDI@._V1_SX300.jpg",
    rating: 4,
    year: 1987,
    synopsis:
      "A sexual deviant is taken hostage by the worst acupuncturists ever. Luckily, his brother's wife will do anything to save him.",
    genre: "Horror",
    subgenre: "Thriller",
    runtime: "94 min",
    pairs: "a Heavenlower.",
    drinkRecipe: "Smirnoff Vanilla, Cherry Coke, & Kraken on ice.",
    rated: "R",
    picture1: hellraiser1,
    picture2: hellraiser2,
    picture3: hellraiser3,
    tagline: "HELLRAISER? I BARELY KNOW HER!",
    taglineWriter: "Little Nicky",
    bulletOne: "Outstanding practical effects and prosthetics.",
    bulletTwo: "Using the same rigging as 'House (1985)' to save SFX money.",
    cast: "Directed by CLIVE BARKER starring ANDREW ROBINSON  CLAIRE HIGGINS  SEAN CHAPMAN and ASHLEY LAURENCE",
  },
  {
    id: 1090,
    name: "The Stepford Wives",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjEzNTM4ODM1OF5BMl5BanBnXkFtZTcwNjg4NzAzMw@@._V1_SX300.jpg",
    rating: 3,
    year: 2004,
    synopsis:
      "An idyllic community is ruined by an uptight career woman who refuses to pay her dues to the neighborhood HOA.",
    genre: "Drama",
    subgenre: "Thriller",
    runtime: "93 min",
    pairs: "Mother's Little Helper.",
    drinkRecipe: "SoCo, Arnold Palmer mix, maraschino cherry garnish.",
    rated: "PG-13",
    picture1: stepfordwives1,
    picture2: stepfordwives2,
    picture3: stepfordwives3,
    tagline: "A NICE PLACE TO LIVE!",
    taglineWriter: "The Pleasantville Review",
    bulletOne: "ATM has a whole different meaning.",
    bulletTwo:
      "Religion, race, and orientation don't matter, it's what's inside that counts.",
    cast: "Directed by FRANK OZ starring NICOLE KIDMAN  BETTE MIDLER  MATTHEW BRODERICK and CHRISTOPHER WALKEN",
  },
  {
    id: 1091,
    name: "The Pope's Exorcist",
    year: "2023",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjA0MGU4MzYtYTYxMy00MjRhLTlmMDYtZTVhZDc1Y2QwNWY2XkEyXkFqcGdeQXVyMjY5ODI4NDk@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "An American family inherits a sweet Castilian castle then receives a demonic visit that no one expected; just like during the Spanish Inquisition.",
    genre: "Horror",
    subgenre: "Thriller",
    runtime: "103 min",
    pairs: "Unholy Water.",
    drinkRecipe: "Gin, Perrier, SoCo, Lime.",
    rated: "R",
    picture1: popeexorcist1,
    picture2: popeexorcist2,
    picture3: popeexorcist3,
    tagline: "THAT'S A TOUGH ONE TO SWALLOW!",
    taglineWriter: "The Keymaster",
    bulletOne: "Casually riding your Vespa from Rome to Madrid.",
    bulletTwo: "Crowe's phenomenal accento.",
    cast: "Directed by JULIUS AVERY starring RUSSEL CROWE DANUEK ZOVATTO ALEX ESSOE and PETER DeSOUZA-FEIGHONEY",
  },
  {
    id: 1092,
    name: "The Hobbit: An Unexpected Journey",
    year: "2012",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTcwNTE4MTUxMl5BMl5BanBnXkFtZTcwMDIyODM4OA@@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Before ring owning became a fellowship, Bilbo writes down his adventures with Gandolf and a bakers dozen of unwelcome house guests.",
    genre: "Adventure",
    subgenre: "Fantasy",
    runtime: "169 min",
    pairs: "a Burgler's Contract.",
    drinkRecipe: "Powers Whiskey, ",
    rated: "PG-13",
    picture1: hobbitone1,
    picture2: hobbitone2,
    picture3: hobbitone3,
    tagline: "This Movie is Not Very Good.",
    taglineWriter: "Random Film Talk",
    bulletOne: "Martin Freeman is a delight.",
    bulletTwo: "Getting three movies for a one book 'series'.",
    cast: "Directed by PETER JACKSON starring MARTIN FREEMAN IAN McKELLAN RICHARD ARMITAGE and KEN STOTT",
  },
  {
    id: 1093,
    name: "Violent Night",
    year: "2022",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzg2NWNhOWItYjA3Yi00MzhhLTg4ZmItYzM3ZTIwN2U0ZGQ5XkEyXkFqcGdeQXVyMzEyMDQzNzY@._V1_SX300.jp",
    rating: 4,
    synopsis:
      "Just your typical Christmas, Santa's out delivering gifts while getting fat, happy, and drunk when suddenly he has to kill a bunch of highly trained mercenaries in order to save a little girl on the Good List.",
    genre: "Action",
    subgenre: "Christmas",
    runtime: "112 min",
    pairs: "a Screwball Comedy.",
    drinkRecipe: "Skrewball whiskey, Kahlúa, Baileys Irish Cream, coffee.",
    rated: "4",
    picture1: violentnight1,
    picture2: violentnight2,
    picture3: violentnight3,
    tagline: "AN ULTRAVIOLENT DELIGHT!",
    taglineWriter: "Killer Kringle",
    bulletOne: "Interesting lore for Jolly Old Saint Nick.",
    bulletTwo:
      "Sleeping during the fights and only watching the sweet heart-felt moments.",
    cast: "Directed by TOMMY WIRKOLA starring DAVID HARBOUR JOHN LEGUIZAMO ALEX HASSLE and BEVERLY D'ANGELO",
  },
  {
    id: 1094,
    name: "The Sound of Music",
    year: "1965",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNWFhNjg3YjctMjg2Ny00YjBkLTg5M2EtMTk2MjA1NDY3NzQ2XkEyXkFqcGdeQXVyMTA0MTM5NjI2._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "A treasonous family avoids their duty to the Fatherland and attempts a traitors flee to Switzerland while singing about every single thing they do.",
    genre: "Family",
    subgenre: "Musical",
    runtime: "172 min",
    pairs: "an Alto-schluss",
    drinkRecipe: "Pink Lemonade, Sweet Tea, and Black Magic Vodka.",
    rated: "G",
    picture1: soundmusic1,
    picture2: soundmusic2,
    picture3: soundmusic3,
    tagline: "TIME FOR ANOTHER REMAKE!",
    taglineWriter: "Disney, probably.",
    bulletOne:
      "Featuring a timeless soundtrack (because the movie never ends).",
    bulletTwo: "Giving a flattering accusation.",
    cast: "Directed by ROBERT WISE starring JULIE ANDREWS CHRISTOPHER PLUMMER PEGGY WOOD and CHARMIAN CARR",
  },
  {
    id: 1095,
    name: "The Equalizer",
    year: "2014",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQ2MzE2NTk0NF5BMl5BanBnXkFtZTgwOTM3NTk1MjE@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Happily living his slow-paced civilian life, working part-time as a dietitian at a local home improvement store, Bob is forced back into being a badass when his favorite book club partner is mistreated by her employer.",
    genre: "Action",
    subgenre: "Crime",
    runtime: "132 min",
    pairs: "a Nail Gun.",
    drinkRecipe: "Splash Putnam Rye Whiskey in a Boston Lager.",
    rated: "R",
    picture1: equalizer1,
    picture2: equalizer2,
    picture3: equalizer3,
    tagline: "RALPHIE IS AN EQUALOSER!",
    taglineWriter: "Paul Blart, Mall Cop",
    bulletOne: "Caring way too much about other people's caloric intake.",
    bulletTwo: "Was this set in Boston? 𝙉𝙃𝘼𝙒𝙏 𝘾𝙃𝙇𝙀𝘼𝙍𝙃.",
    cast: "Directed by ANTOINE FUQUA starring DENZEL WASHINGTON MARTON CSOKAS CHLOË GRACE MORETZ and DAVID HARBOUR",
  },
  {
    id: 1096,
    name: "The Equalizer 2",
    year: "2018",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTU2OTYzODQyMF5BMl5BanBnXkFtZTgwNjU3Njk5NTM@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Boston's nicest guy, Bob McCall, is back on neighborhood watch, cleaning up and kicking ass, after a GANG tags his block.",
    genre: "Action",
    subgenre: "Crime",
    runtime: "121 min",
    pairs: "Hurricane Cover.",
    drinkRecipe: "Decaf, Baileys, Potato Vodka; served hot or cold",
    rated: "R",
    picture1: equalizer21,
    picture2: equalizer22,
    picture3: equalizer23,
    tagline: "I AM THE EQUALIZER TOO!",
    taglineWriter: "Queen Latifah",
    bulletOne: "40 minutes of 'Save-the-Cat'.",
    bulletTwo: "Correcting his watch the second time around.",
    cast: "Directed by ANTOINE FUQUA starring DENZEL WASHINGTON ORSON BEAN ASHTON SANDERS and PEDRO PASCAL",
  },
  {
    id: 1097,
    name: "The Equalizer 3",
    year: "2023",
    poster: equalizer3Poster,
    rating: 4,
    synopsis:
      "After touring a Sicilian vineyard, Roberto ends up in Altomonte, Italy where he finally feels he belongs and becomes the live-in courrier for a slip & fall doctor. Unfortunately for the local mafia, that means their time's up.",
    genre: "Action",
    subgenre: "Crime",
    runtime: "109 min",
    pairs: "tea for Ladies/Englishmen.",
    drinkRecipe: "Earl Grey with Skinny Girl Vodka add Lemon.",
    rated: "R",
    picture1: equalizer31,
    picture2: equalizer33,
    picture3: equalizer32,
    tagline: "THREEQUALIZER THREEPEATS THE THRILLOGY!!!",
    taglineWriter: "Tertile Magazine",
    bulletOne: "Finally being able to sell that hat.",
    bulletTwo:
      "Best of the three. One of the few trilogies to get better each movie.",
    cast: "Directed by ANTOINE FUQUA starring DENZEL WASHINGTON DAKOTA FANNING EUGENIO MASTRANDREA and ANDREA SCARDUZIO",
  },
  {
    id: 1098,
    name: "Bad Words",
    year: "2013",
    poster:
      "https://m.media-amazon.com/images/M/MV5BN2M1MDljMmItMDE0ZS00OTQxLTkxNmQtMTgwZjk3NmM3MDc4XkEyXkFqcGdeQXVyMTY5Nzc4MDY@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "A 40 year old man enters the 111th Golden Quill spelling bee to achieve his lifelong dream of making small children cry on live TV.",
    genre: "Comedy",
    subgenre: "Drama",
    runtime: " min",
    pairs: "a S-C-R-E-W.",
    drinkRecipe: "Two Old fashioneds with OJ.",
    rated: "R",
    picture1: badwords1,
    picture2: badwords2,
    picture3: badwords3,
    tagline: "Technically correct, is the best kind of correct.",
    taglineWriter: "Hermes Conrad, Grade 36 Bureaucrat",
    bulletOne: "Chaitanya is a lying machine.",
    bulletTwo:
      "Learning what challenges a true orthographic enthusiast would and would not flinch at.",
    cast: "Directed by JASON BATEMAN starring JASON BATEMAN KATHRYN HAHN PHILIP BAKER HALL and ROHAN CHAND",
  },
  {
    id: 1099,
    name: "The Monuments Men",
    year: "2014",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjMxMjk4NTM1M15BMl5BanBnXkFtZTgwNjg0MjQ3MDE@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A who's who of old white monuments, these men set out to find histories greatest (f)art works during the Second World War.",
    genre: "War",
    subgenre: "Comedy",
    runtime: "118 min",
    pairs: "Painting's Worth 1000 Men.",
    drinkRecipe: "Bourbon, Champagne, Echte Kroatzbeere Blackberry liqueur.",
    rated: "PG-13",
    picture1: monumentsmen1,
    picture2: monumentsmen2,
    picture3: monumentsmen3,
    tagline: "ZHIS FILM IST DER WURST!",
    taglineWriter: "Academy of Fine Arts Vienna",
    bulletOne: "Husband of the Year (1944): James Granger.",
    bulletTwo: "𝙈𝙞𝙣𝙞𝙣𝙜 your own business.",
    cast: "Directed by GEORGE CLOONEY starring GEORGE CLOONEY MATT DAMON BILL MURRAY and CATE BLANCHETT",
  },
  {
    id: 1100,
    name: "Fright Night",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTBlOTEzZjYtZTlmMC00OTYyLWIyMTAtNzMzZTRhNzU1YzAyL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    year: 1985,
    synopsis:
      "Charley's an odd, sorta rapey teen who watches his new neighbor move in and then decides to ruin the guy's life.",
    genre: "Horror",
    subgenre: "Thriller",
    runtime: "106 min",
    pairs: "a Might Bite.",
    drinkRecipe:
      "vodka, peach schnapps, sloe gin, orange juice, cranberry juice, ice cubes.",
    rated: "R",
    picture1: frightnight1,
    picture2: frightnight2,
    picture3: frightnight3,
    tagline: "OH WHAT A NIGHT!",
    taglineWriter: "Frankie Valli",
    bulletOne:
      "The 'Good Guy' is the villain and the 'Bad Guy' is the hero in this one.",
    bulletTwo: "Cool 80's effects...Dracula steals Charley's girl",
    cast: "Directed by TOM HOLLAND starring CHRIS SARANDON  WILLIAM RAGSDALE  AMANDA BEARSE and RODDY McDOWALL",
  },
  {
    id: 1101,
    name: "Searching",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjIwOTA3NDI3MF5BMl5BanBnXkFtZTgwNzIzMzA5NTM@._V1_SX300.jpg",
    rating: 3,
    year: 2018,
    synopsis:
      "If you don't get enough of your computer screen from 9-5 watch this guy look for his missing daugher seemlessly on all his Apple devices.",
    genre: "Drama",
    subgenre: "Mystery",
    runtime: "106 min",
    pairs: "a New Phone.",
    drinkRecipe:
      "apple juice, vodka, apple liqueur, monster ultra pradise on ice.",
    rated: "PG-13",
    picture1: searching1,
    picture2: searching2,
    picture3: searching3,
    tagline: "SHOT ON AN IPHONE!",
    taglineWriter: "Stephen Unemployment",
    bulletOne:
      "Creepily close to the line between protection and personal privacy.",
    bulletTwo: "A 90 minute commercial for Mac.",
    cast: "Directed by ANEESH CHAGANTY starring JOHN CHO DEBRA MESSING JOSEPH LEE and MICHELLE LA",
  },
  {
    id: 1102,
    name: "Lifeforce",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTU4MTMxOTQyOV5BMl5BanBnXkFtZTcwMzU1NDk0NA@@._V1_SX300.jpg",
    rating: 4,
    year: 1985,
    synopsis:
      "In a not-so-distant future, humanity's hubris humiliates, horrifies, and humbles the people of London.",
    genre: "Horror",
    subgenre: "Scifi",
    runtime: "101 min",
    pairs: "a Force of Life.",
    drinkRecipe: "Bacardi 151, Monster Ultra Blue, la croix lemon, ice.",
    rated: "R",
    picture1: lifeforce1,
    picture2: lifeforce2,
    picture3: lifeforce3,
    tagline: "LUST IS A DRUG!",
    taglineWriter: "Rocksie Music",
    bulletOne: "Ample Alien Areola.",
    bulletTwo: "A Vampire-Zombie crossover flick only the 80's could provide.",
    cast: "Directed by TROBE HOOPER starring STEVE RAILSBACK  MATHILDA MAY PATRICK STEWART and PETER FIRTH",
  },
  {
    id: 1103,
    name: "My Best Friend's Exorcism",
    poster:
      "https://m.media-amazon.com/images/M/MV5BN2VmY2M4Y2QtODg4OS00MjY0LThjODUtZTM0MGYwOGUzNzY3XkEyXkFqcGdeQXVyMjkwOTAyMDU@._V1_SX300.jpg",
    rating: 3,
    year: 2022,
    synopsis:
      "Catholic school girls exercise their right to party one more time before summer comes but unfortunately one gains a Stage Five clinger...",
    genre: "Horror",
    subgenre: "Comedy",
    runtime: "97 min",
    pairs: "Post-Workout Cocktails.",
    drinkRecipe: "gin, amino energy powder, water, gatorade.",
    rated: "R",
    picture1: friendsexorcism1,
    picture2: friendsexorcism2,
    picture3: friendsexorcism3,
    tagline: "COVER YO UGLY ASS UP, GIRL!",
    taglineWriter: "OLAY",
    bulletOne: "Getting the least jacked bodybuilding brother.",
    bulletTwo: "Promoting Jesus and anti-drug use for teens.",
    cast: "Directed by DAMON THOMAS starring ELSIE FISHER  AMIAH MILLER  CATHY ANG and CHRISTOPHER LOWELL",
  },
  {
    id: 1104,
    name: "Apocalypto",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzhmNGMzMDMtZDM0Yi00MmVmLWExYjAtZDhjZjcxZDM0MzJhXkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 5,
    year: 2006,
    synopsis:
      "If you can imagine 'The Running Man' in the Amazon, you're off to the races.",
    genre: "Action",
    subgenre: "Horror",
    runtime: "139 min",
    pairs: "a Beating Heart.",
    drinkRecipe: "Blood Orange Juice, bourbon, sour mix.",
    rated: "R",
    picture1: apocalypto1,
    picture2: apocalypto2,
    picture3: apocalypto3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by MEL GIBSON starring RUDY YOUNGBLOOD RAOUL MAX TRUJILLO GERARDO TARACENA and JONATHAN BREWER",
  },
  {
    id: 1105,
    name: "Bee Movie",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjE1MDYxOTA4MF5BMl5BanBnXkFtZTcwMDE0MDUzMw@@._V1_SX300.jpg",
    rating: 4,
    year: 2007,
    synopsis:
      "Everything is running smoothly at Honex, almost too smoothly, until one lazy bee works harder to remain unemployed than if he'd just picked a job in the first place.",
    genre: "Comedy",
    subgenre: "Animation",
    runtime: "91 min",
    pairs: "a Hive.",
    drinkRecipe: "hard apple cider, whiskey, honey.",
    rated: "PG",
    picture1: beemovie1,
    picture2: beemovie2,
    picture3: beemovie3,
    tagline: "ACHOO! THAT'S A LOT OF POLLEN!",
    taglineWriter: "Kosmo Cramer",
    bulletOne:
      "Helping someone achieve their goals and immedately chastising them after succeeding.",
    bulletTwo:
      "Not caring if other creatures are just as intelligent and deserve court representation.",
    cast: "Directed by SIMON J. SMITH & STEVE HICKNER starring JERRY SEINFELD RENEÉ ZELLWEGER MATTHEW BRODERICK and JOHN GOODMAN",
  },
  {
    id: 1106,
    name: "Now Is Good",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTM2MTA3MDczNF5BMl5BanBnXkFtZTcwNjU0MTA1Nw@@._V1_SX300.jpg",
    rating: 3,
    year: 2012,
    synopsis:
      "A teen with cancer attempts to finish her bucket list with help from the boy next door. She's dying and that's tough but damn Tessa is super mean to her dad.",
    genre: "Drama",
    subgenre: "Romcom",
    runtime: "103 min",
    pairs: "an American Breakfast Tea.",
    drinkRecipe: "hot sweet tea & kentucky bourbon.",
    rated: "PG-13",
    picture1: nowsgood1,
    picture2: nowsgood2,
    picture3: nowsgood3,
    tagline: "WHAT A BITCH!",
    taglineWriter: "Squeaker Fromm",
    bulletOne: "Paddy is considine-ly attempting to keep his family together.",
    bulletTwo:
      "Being cruel to your friend when your own medical choices are questionable.",
    cast: "Directed by OL PARKER starring DAKOTA FANNING JEREMY IRVINE  PADDY CONSIDINE and KAYA SCODELARIO",
  },
  {
    id: 1107,
    name: "Rolling Thunder",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDhjY2Y5YjItNzhjZi00M2E0LWFlMzAtOGVkYzdhN2NiZWE0XkEyXkFqcGdeQXVyMTUzMDUzNTI3._V1_SX300.jpg",
    rating: 5,
    year: 1977,
    synopsis:
      "After surviving a Vietnamese prison camp, Major Charles Rane thought life would be easy. But after his family is murdered (like the day he gets home), he gets a thirst for blood that cannot be quenched.",
    genre: "Action",
    subgenre: "Thriller",
    runtime: "95 min",
    pairs: "a Shooter.",
    drinkRecipe: "Tequila, lime juice, tabasco",
    rated: "R",
    picture1: rollingThunder1,
    picture2: rollingThunder2,
    picture3: rollingThunder3,
    tagline: "IT'S COMING ON LIKE A HURRICANE",
    taglineWriter: "ACϟDC",
    bulletOne: "Being one MACHO MOTHERFUCKER.",
    bulletTwo:
      "Sharpening the metal on your fake hand to craft a lethal weapon.",
    cast: "Directed by JOHN FLYNN starring WILLIAM DEVANE LINDA HAYNES TOMMY LEE JONES and JAMES BEST",
  },
  {
    id: 1108,
    name: "Paradise Alley",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjJiMjRmNmYtN2JkZS00NWZiLTkwNjAtNmU3NzUxZWVlZTAxL2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyNDQzMDg4Nzk@._V1_SX300.jpg",
    rating: 5,
    year: 1977,
    synopsis:
      "This movie is awesome. If for no other reason, watch it to hear Sly sing the opening theme song 'Too Close to Paradise'. It sounds like a brain-damaged Elvis on novocaine, and it should've been called 'Too Close to Microphone'.",
    genre: "Sports",
    subgenre: "Comedy",
    runtime: "107 min",
    pairs: "an Ice Clamp.",
    drinkRecipe: "Vodka, zima, crushed ice",
    rated: "PG",
    picture1: paradiseAlley1,
    picture2: paradiseAlley2,
    picture3: paradiseAlley3,
    tagline: "Sounds like a meetup location for Grindr",
    taglineWriter: "Kid Salami",
    bulletOne:
      "Longest and sweatiest armwrestling match outside of Over the Top.",
    bulletTwo: "Not walkin' behind nobody cause you aint no fart catcher.",
    cast: "Directed by SYLVESTER STALLONE starring SYLVESTER STALLONE LEE CANALITO ARMAND ASSANTE and TERRY FUNK",
  },
  {
    id: 1109,
    name: "Hardcore",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTdhYWNhMzMtYzkzOC00ZGNiLWE5MDMtMzRlMTc3MzM2N2ZkXkEyXkFqcGdeQXVyNTc1NTQxODI@._V1_SX300.jpg",
    rating: 5,
    year: 1979,
    synopsis:
      "To find his runaway daughter, a strait-laced Midwesterner goes balls-deep in the seedy world of pornography and prostitution in late 70's LA.",
    genre: "Crime",
    subgenre: "Drama",
    runtime: "108 min",
    pairs: "a Money Shot.",
    drinkRecipe: "White Rum, heavy cream ",
    rated: "R",
    picture1: hardcore1,
    picture2: hardcore2,
    picture3: hardcore3,
    tagline: "IT'S A LOT TO TAKE IN",
    taglineWriter: "Deep Throat",
    bulletOne:
      "Peter Boyle plays a good sleazeball, and his hair is delightfully insane.",
    bulletTwo:
      "The scene with Patton pretending to be a casting agent is priceless.",
    cast: "Directed by PAUL SCHRADER starring GEORGE C SCOTT PETER BOYLE SEASON HUBLEY and DICK SARGENT",
  },
  {
    id: 1110,
    name: "Carny",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODhhMGFmN2QtODVjYy00ODFkLWI2MTAtZGQxYWY2MjZhYWVmXkEyXkFqcGdeQXVyNTcwNjUwNzk@._V1_SX300.jpg",
    rating: 5,
    year: 1980,
    synopsis:
      "Donkey-faced lunatic Gary Busey dons his best juggalo makeup and plays a stunningly believable d(r)unk-tank bozo.",
    genre: "Drama",
    subgenre: "",
    runtime: "107 min",
    pairs: "a Bearded Lady.",
    drinkRecipe: "Tequila and lime with a salted rim",
    rated: "R",
    picture1: carny1,
    picture2: carny2,
    picture3: carny3,
    tagline: "Slick movie - as if greased with PAM",
    taglineWriter: "Joe Dirt",
    bulletOne:
      "Authentic environment. You can almost smell the funnel cake on the fairground.",
    bulletTwo:
      "Casting real-life freaks and taking a peek behind the curtain of a unique world.",
    cast: "Directed by ROBERT KAYLOR starring GARY BUSEY JODIE FOSTER ROBBIE PATERSON and KENNETH MCMILLAN",
  },
  {
    id: 1111,
    name: "Sitting Target",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTkxMDU4NzgxNl5BMl5BanBnXkFtZTgwOTI2OTE4NTE@._V1_SX300.jpg",
    rating: 4,
    year: 1972,
    synopsis:
      "While imprisoned on a 12 year stretch, Harry Lomart's wife reveals she's met someone else. So Harry does the only thing a rational man can do in that position: break out to kill her.",
    genre: "Crime",
    subgenre: "Drama",
    runtime: "93 min",
    pairs: "Shattered Glass.",
    drinkRecipe: "Vodka, Sprite, crushed ice",
    rated: "R",
    picture1: sittingTarget1,
    picture2: sittingTarget2,
    picture3: sittingTarget3,
    tagline: "THIS MOVIE RULES!",
    taglineWriter: "O.J. Simpson",
    bulletOne: "Awesome fully-automatic pistol/sniper-rifle.",
    bulletTwo:
      "Olly Reed running through the sheets while being three sheets to the wind.",
    cast: "Directed by DOUGLAS HICKOX starring OLIVER REED JILL ST JANE IAN MCSHANE and EDWARD WOODWARD",
  },
  {
    id: 1112,
    name: "Captain Phillips",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODhiZWRhMjctNDUyMS00NmUwLTgwYmItMjJhOWNkZWQ3ZTQxXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    rating: 4,
    year: 2013,
    synopsis:
      "A skeleton crew ships cargo and a beggars worth of cash towards 𝙈𝙊𝙈𝘽𝘼𝙎𝘼 when they are overtaken by pirates. If it's based on a true story where someone fails at piloting something-- get Hanks.",
    genre: "Biography",
    subgenre: "Drama",
    runtime: "134 min",
    pairs: "a Boat Hose.",
    drinkRecipe: "vodka, water, cucumber straw.",
    rated: "PG-13",
    picture1: capphillips1,
    picture2: capphillips2,
    picture3: capphillips3,
    tagline: "WORST PIRATES I'VE NEVER HEARD OF!",
    taglineWriter: "Cpt. J. Sparrow",
    bulletOne: "Waiting for the village Elders.",
    bulletTwo:
      "Being a wheezy, whiny, cry-baby for way too long in the med bay.",
    cast: "Directed by PAUL GREENGRASS starring TOM HANKS  BARKHAD ABDI  BARKHAD ABDIRAHMAN and MICHAEL CHERNUS",
  },
  {
    id: 1113,
    name: "Facing Nolan",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzQzZTRiNjYtNjY1MC00ZTk5LWFkZTEtNTc2M2MyZjg4ZDViXkEyXkFqcGdeQXVyMTUzOTY0MzUy._V1_SX300.jpg",
    rating: 5,
    year: 2022,
    synopsis:
      "The Ryan Express' 27 year MLB pitiching career before, during, and after the game in an up close and personal look without having to face him in the batters box.",
    genre: "Documentary",
    subgenre: "Sports",
    runtime: "102 min",
    pairs: "",
    noPairs: "a Lone Star beer.",
    rated: "TV-14",
    picture1: facingnolan1,
    picture2: facingnolan2,
    picture3: facingnolan3,
    tagline: "Didn't think y'all'd see me in this, did y'all?",
    taglineWriter: "GW Bush",
    bulletOne:
      "If you play long enough you'll have both kinds of records: Good and Bad.",
    bulletTwo: "That one dude really loves statistics.",
    cast: "Directed by BRADLEY JACKSON starring RUTH RYAN  NOLAN RYAN  PETE ROSE and MIKE MACrAE",
  },
  {
    id: 1114,
    name: "All Quiet on the Western Front",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTE1MmZiMWYtYTFmZi00YjA3LWI2ODgtMWFlNWYxZjdmNGE3XkEyXkFqcGdeQXVyNjk1Njg5NTA@._V1_SX300.jpg",
    rating: 5,
    year: 2022,
    synopsis:
      "The story of a soldier's uniform during the Greatest Generation's Great War.",
    genre: "War",
    subgenre: "",
    runtime: "148 min",
    pairs: "",
    noPairs: "a Köstritzer Schwarzbier.",
    rated: "TV-14",
    picture1: aqotwf1,
    picture2: aqotwf2,
    picture3: aqotwf3,
    tagline: "NOT THAT QUIET!",
    taglineWriter: "Jon Krazinscki",
    bulletOne:
      "Brutally honest representation of warfare and different ways to kill someone.",
    bulletTwo: "Gaining a perspective from the side of the baddies.",
    cast: "Directed by EDWARD BERGER starring FELIX KAMMERER  ALBRECHT SCHUCH  MORITZ KLAUS and DANIEL BRÜHL",
  },
  {
    id: 1115,
    name: "Alligator",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTUyMWQ0N2ItMDAzOS00OGNlLTgxNDctZTczMzU1YzljNDlmXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    year: 1980,
    synopsis:
      "Remember the lie your parents told you about your flushed pets growing enormous in the sewers? Turns out it's true.",
    genre: "Horror",
    subgenre: "Scifi",
    runtime: "91 min",
    pairs: "a Bald Spot.",
    noPairs: "",
    drinkRecipe: "Fill shot glass with kahlua. Add splash of bailey's",
    rated: "R",
    picture1: alligator1,
    picture2: alligator2,
    picture3: alligator3,
    tagline: "THAT LAKE WAS NOT PLACID",
    taglineWriter: "Oliver Platt",
    bulletOne: "Booking criminals for 'being creeps'.",
    bulletTwo:
      "Joey driving his mom crazy!! But not half as crazy as Chief Clark's eyebrows.",
    cast: "Directed by LEWIS TEAGUE starring ROBERT FORSTER ROBIN RIKER MICHAEL V GAZZO and DEAN JAGGER",
  },
  {
    id: 1116,
    name: "Weird: The Al Yankovic Story",
    poster:
      "https://m.media-amazon.com/images/M/MV5BOWRiNmI1OTItYjc0Zi00YTYwLWI4OTEtMmE0YTNlODJkOTQwXkEyXkFqcGdeQXVyMDM2NDM2MQ@@._V1_SX300.jpg",
    rating: 5,
    year: 2022,
    synopsis:
      "The factual tale of the world's, perhaps not technically the best but arguably, most famous accordion player in an extremely specific genre of music.",
    genre: "Comedy",
    subgenre: "Biography",
    runtime: "91 min",
    pairs: "",
    noPairs: "a Champagne Waterfall.",
    drinkRecipe: "",
    rated: "R",
    picture1: weirdal1,
    picture2: weirdal2,
    picture3: weirdal3,
    tagline: "This movie makes my 𝙃𝙚𝙖𝙧𝙩 𝙎𝙤 𝙋𝙧𝙤𝙪𝙙!",
    taglineWriter: "Jenna Maroney",
    bulletOne: "Has the feel of a UHF Production.",
    bulletTwo: "An awesome pool party attended by pop icons.",
    cast: "Directed by ERIC APPEL starring DANIEL RADCLIFFE  RAINN WILSON  DIEDRICH BADER and EVAN RACHEL WOOD",
  },
  {
    id: 1117,
    name: "Croupier",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmMwNDE5YmYtNzdmYy00ZTlmLWJkOWYtMDY5NDBiMGQyNTFmXkEyXkFqcGdeQXVyMjUzOTY1NTc@._V1_SX300.jpg",
    rating: 4,
    year: 1998,
    synopsis:
      "To make ends meet, a writer works as a card dealer and becomes engrossed in a world that takes him over.",
    genre: "Crime",
    subgenre: "Drama",
    runtime: "91 min",
    pairs: "",
    noPairs: "straight vodka.",
    drinkRecipe: "",
    rated: "Not Rated",
    picture1: croupier1,
    picture2: croupier2,
    picture3: croupier3,
    tagline: "AN ACE WAS DETECTED!",
    taglineWriter: "Jim Carrey",
    bulletOne:
      "Claiming 'all men are scumbags' then kissing a guy in front of his girlfriend.",
    bulletTwo: "Flop-sweat gambler had the hairiest ears in the goddamn world.",
    cast: "Directed by MIKE HODGES starring CLIVE OWEN NICHOLAS BALL GINA MCKEE KATE HARDIE",
  },
  {
    id: 1118,
    name: "Any Given Sunday",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDk2YTQxZjUtYzJkYi00MTk4LTk4NDAtZmQ3ZGQwMDQxY2ZjXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    year: 1999,
    synopsis:
      "Scarface has to decide between putting in the young new quarterback or the ol' reliable who got them there. ",
    genre: "Sports",
    subgenre: "Drama",
    runtime: "162 min",
    pairs: "a Red Zone.",
    noPairs: "",
    drinkRecipe: "cranberry juice, vodka, raspberry syrup, lemon juice",
    rated: "R",
    picture1: anySunday1,
    picture2: anySunday2,
    picture3: anySunday3,
    tagline: "NOW THAT'S SOME HIGH QUALITY FOOSBALL",
    taglineWriter: "The Waterboy",
    bulletOne: "Prime Cameron Diaz.",
    bulletTwo:
      "Awesome hits from when you were actually allowed to tackle and be aggressive in football.",
    cast: "Directed by OLIVER STONE starring AL PACINO JAIME FOXX CAMERON DIAZ and DENNIS QUAID",
  },
  {
    id: 1119,
    name: "The Outrage",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjE0NDA2ODU3Ml5BMl5BanBnXkFtZTcwODUzNDc2Ng@@._V1_SX300.jpg",
    rating: 4,
    year: 2010,
    synopsis:
      "A pair of Yakuza families duke it out for control of territory. ",
    genre: "Crime",
    subgenre: "Action",
    runtime: "109 min",
    pairs: "a Pinkie Knuckle.",
    noPairs: "",
    drinkRecipe: "Bailey's, Campari",
    rated: "R",
    picture1: outrage1,
    picture2: outrage2,
    picture3: outrage3,
    tagline: "TALK ABOUT A BULLET TRAIN!",
    taglineWriter: "Shin Kansen",
    bulletOne: "The Yakuza way of speaking has a nice growling quality.",
    bulletTwo: "Breaking a guys neck with automotive assistance.",
    cast: "Directed by TAKESHI KITANO starring TAKESHI KITANO FUMIYO KOHINATA KIPPEI SHINA and RYO KASE",
  },
  {
    id: 1120,
    name: "The Bad News Bears",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTU1ZjNmNjktNjk4Yy00MzdjLTk0YzktMDRlYTllMjE0N2I0XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 1976,
    synopsis:
      "A washed up minor league ballplayer coaches a ragteam team of rugrats.",
    genre: "Sports",
    subgenre: "Comedy",
    runtime: "102 min",
    pairs: "a Boilermaker.",
    noPairs: "",
    drinkRecipe: "Whiskey, beer",
    rated: "PG",
    picture1: badnewsbears1,
    picture2: badnewsbears3,
    picture3: badnewsbears2,
    tagline: "The bases weren't the only thing loaded",
    taglineWriter: "Coach Buttermaker",
    bulletOne:
      "Seeing the good old times when it was acceptable to crush beers in public all day.",
    bulletTwo: "Riding around on your own Harley and rippin' cigs at age 11.",
    cast: "Directed by MICHAEL RITCHIE starring WALTER MATTHAU TATUM O'NEAL CHRIS BARNES and JACKIE EARLE HALEY",
  },
  {
    id: 1121,
    name: "Spider-Man: No Way Home",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZWMyYzFjYTYtNTRjYi00OGExLWE2YzgtOGRmYjAxZTU3NzBiXkEyXkFqcGdeQXVyMzQ0MzA0NTM@._V1_SX300.jpg",
    rating: 4,
    year: 2021,
    synopsis:
      "Peter Parker #1 creates universal issues because, he's so smart and good at addition, he forgot about his two friends and one family member. With almost no help from Dr. Strange-looking, Spider-Men find themselves facing ferocious foes from past, present, and not quite yet future.",
    genre: "Action",
    subgenre: "Superhero",
    runtime: "148 min",
    pairs: "a Biological Web Shooter.",
    noPairs: "",
    drinkRecipe: "Loconut & Tequila Silver with Lime Margarita Mix chaser.",
    rated: "PG-13",
    picture1: spidermannwh1,
    picture2: spidermannwh3,
    picture3: spidermannwh2,
    tagline: "Ya know what's lamer than magic? MATH!",
    taglineWriter: "Nikky Tesla",
    bulletOne: "Unfortunately the worst of the trilogy.",
    bulletTwo: "Gen Z smelling themselves a bit.",
    cast: "Directed by JON WATTS starring TOM HOLLAND  ZENDAYA  JACOB BATALON and WILLEM DAFOE",
  },
  {
    id: 1122,
    name: "Where the Crawdads Sing",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTJmNGJmYTgtYjAxNy00YmMzLTk2YTYtMGIzMmUwNDMyMTY1XkEyXkFqcGdeQXVyMTEyMjM2NDc2._V1_SX300.jpg",
    rating: 4,
    year: 2022,
    synopsis:
      "A small Southern town puts a dirty outcast on trial for murder. She should know that being alone or independent doesn't make you creepy but being super into bugs and never ever wearing shoes does.",
    genre: "Drama",
    subgenre: "Mystery",
    runtime: "125 min",
    pairs: "a Marsh Rat.",
    noPairs: "",
    drinkRecipe: "Carolina sweet tea and Jack Daniels with a lime on ice.",
    rated: "PG-13",
    picture1: crawdadssing1,
    picture2: crawdadssing2,
    picture3: crawdadssing3,
    tagline: "'AIN'T AIN'T A WORD', AIN'T TRUE!",
    taglineWriter: "Willie Neldaughter",
    bulletOne: "Finally seeing your best friend's entire face.",
    bulletTwo:
      "A little boy effortlessly throws a full grown adult man to the dirt.",
    cast: "Directed by OLIVIA NEWMAN starring DAISY EDGAR-JONES  TAYLOR JOHN SMITH STERLING MACER JR and DAVID STRATHAIRN",
  },
  {
    id: 1123,
    name: "Big Momma's House",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTcyMjdlYmUtNjQ1Zi00YTg3LTliNTgtZmNkOTRmYzEzYTMzXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 5,
    year: 2000,
    synopsis:
      "Detective Malone goes undercover as a granny to catch a criminal.",
    genre: "Comedy",
    subgenre: "Action",
    runtime: "99 min",
    pairs: "Captain Crisco.",
    noPairs: "",
    drinkRecipe: "Lemonade, Captain morgan",
    rated: "PG-13",
    picture1: bigmomma1,
    picture2: bigmomma2,
    picture3: bigmomma3,
    tagline: "You better ask Big Momma, Sherry baby",
    taglineWriter: "Frankie Valli",
    bulletOne: "Martin Lawrence in Asianface making a killer Seoul joke.",
    bulletTwo:
      "Great physical gags such as slam dunking and beating up a karate instructor.",
    cast: "Directed by RAJA GOSNELL starring MARTIN LAWRENCE  NIA LONG  PAUL GIAMATTI and TERRENCE HOWARD",
  },
  {
    id: 1124,
    name: "The Lego Movie 2: The Second Part",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTkyOTkwNDc1N15BMl5BanBnXkFtZTgwNzkyMzk3NjM@._V1_SX300.jpg",
    rating: 4,
    year: 2019,
    synopsis:
      "Everything was awesome until Finn had to share his toys in this solid follow-up with fun family jokes and original music where saying you aren't evil pretty much proves you're a villain.",
    genre: "Animation",
    subgenre: "Family",
    runtime: "107 min",
    pairs: "a White Velvet Cake.",
    noPairs: "",
    drinkRecipe:
      "Pinnacle whipped, Mozart White Chocolate Vanilla Cream Liqueur, 1% Milk, Ice. ",
    rated: "PG",
    picture1: legotwo1,
    picture2: legotwo2,
    picture3: legotwo3,
    tagline: "BATMAN DOESN'T EVEN BUILD ANYTHING!",
    taglineWriter: "Lego Masters",
    bulletOne: "R.I.P. Maya Rudolph's foot.",
    bulletTwo:
      "President Business delivering the worst news ever. Such a shame.",
    cast: "Directed by MIKE MITCHELL starring CHRIS PRATT  ELIZABETH BANKS  WILL ARNETT and TIFFANY HADDISH",
  },
  {
    id: 1125,
    name: "The Beach Bum",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTY3MDUzMTAzOV5BMl5BanBnXkFtZTgwNDk3ODQyNzM@._V1_SX300.jpg",
    rating: 2,
    year: 2019,
    synopsis:
      "A filthy hobo is forced to grow up after he kills his wife and then destroys her mansion. Instead he continues his selfish behavior, and borderline incestuous father-daughter relationship, with zero consequences.",
    genre: "Comedy",
    subgenre: "Drug",
    runtime: "95 min",
    pairs: "a Shore Vagrant.",
    noPairs: "",
    drinkRecipe: "Malibu Rum, sand, and red wine.",
    rated: "R",
    picture1: beachbum1,
    picture2: beachbum2,
    picture3: beachbum3,
    tagline: "Sorry Wall St. didn't work out guys!",
    taglineWriter: "Lee O. DeCaffeino",
    bulletOne:
      "Constantly saying someone's a genius doesn't make it believable.",
    bulletTwo: "Annoying continued conversation cut scenes.",
    cast: "Directed by HARMONY KORINE starring MATTHEW McCONAUGHEY  ISLA FISHER  STEFANIA LaVIE and SNOOP DOGG",
  },
  {
    id: 1126,
    name: "Amsterdam",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDQwNzE0ZTItYmZjMC00NjI3LWFlNzctNTExZDY2NWE0Zjc0XkEyXkFqcGdeQXVyMTA3MDk2NDg2._V1_SX300.jpg",
    rating: 4,
    year: 2022,
    synopsis:
      "Based on actual events this period piece takes place between World Wars and set in New and old Amsterdam. A trio of allies are pulled into a whirlwind conspiracy that intertwines family, duty, and prosthetic eyes.",
    genre: "Mystery",
    subgenre: "Comedy",
    runtime: "132 min",
    pairs: "drinks from a Safe Deposit Box.",
    noPairs: "",
    drinkRecipe: "whisky, neat.",
    rated: "R",
    picture1: amsterdam1,
    picture2: amsterdam2,
    picture3: amsterdam3,
    tagline: "Nothin' good starts in a getaway car!",
    taglineWriter: "Anya-Taylor Swift",
    bulletOne: "Tips and tricks from expert orinthologists.",
    bulletTwo: "Tossing in a love interest for your third-wheelin' pal.",
    cast: "Directed by DAVID O. RUSSELL starring CHRISTIAN BALE  JOHN DAVID WASHINGTON  MARGOT ROBBIE and RAMI MALEK",
  },
  {
    id: 1127,
    name: "Hearts of Darkness: A Filmmaker's Apocalypse",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNWQxZTQ4OTItNTdmNS00NGY0LTg5NDAtZjViMjQ2ODg3ZGFhXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 4,
    year: 1991,
    synopsis:
      "In this documentary, with clearly no agenda at all, we follow a film crew behind the scenes of an overrated movie haphazardly organized by an extremely pretentious director.",
    genre: "Documentary",
    subgenre: "Film",
    runtime: "96 min",
    pairs: "a Hot Sweaty Jungle.",
    noPairs: "",
    drinkRecipe: "room temp. beer and a cup of water.",
    rated: "R",
    picture1: heartsdarkness1,
    picture2: heartsdarkness2,
    picture3: heartsdarkness3,
    tagline: "WAY BETTER THAN 𝘼𝙥𝙤𝙘𝙖𝙡𝙮𝙥𝙨𝙚 𝙉𝙤𝙬!",
    taglineWriter: "Abed Nadir",
    bulletOne: "Another reason to hate Brando.",
    bulletTwo: "Making a movie on location that no one wants to be at.",
    cast: "Directed by FAX BAHR GEORGE HICKENLOOPER AND ELANOR COPPOLA starring FRANCIS FORD COPPOLA  MARTIN SHEEN and MARLON BRANDO",
  },
  {
    id: 1128,
    name: "Pinocchio",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZDIyYjM3N2EtOTU1MC00YTA4LTgzNjctMTNjODcxZTZhODU2XkEyXkFqcGdeQXVyMTM1MTE1NDMx._V1_SX300.jpg",
    rating: 1,
    year: 2022,
    synopsis:
      "Another attempt by Disney to force interest in fucking up a classic movie.",
    genre: "Disney",
    subgenre: "Remake",
    runtime: "105 min",
    pairs: "",
    noPairs: "lighter fluid and a lit match.",
    drinkRecipe: "",
    rated: "PG",
    picture1: pinocchio221,
    picture2: pinocchio223,
    picture3: pinocchio222,
    tagline: "LET'S REMAKE 𝘽𝘼𝘾𝙆 𝙏𝙊 𝙏𝙃𝙀 𝙁𝙐𝙏𝙐𝙍𝙀 NEXT!",
    taglineWriter: "ALAN BERGMAN",
    bulletOne: "Thanks but no, T-Hanks.",
    bulletTwo: "TBF did't watch so the rating is invaild.",
    cast: "Directed by ROBERT ZEMECKIS starring JOSEPH GORDON-LEVITT  TOM HANKS  CYNTHIA ERIVO and ANGUS WRIGHT",
  },
  {
    id: 1129,
    name: "Hot Summer Nights",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYWEzZDI3NTQtYmFlZi00N2QxLTk0MmYtMThjYjlmZmRlMmVjXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg",
    rating: 4,
    year: 2017,
    synopsis:
      "During the hottest summer of recent memory in New England, a tansplanted pipsqueak dates his boss' sister and lies to both of them about it.",
    genre: "Comedy",
    subgenre: "Drama",
    runtime: "107 min",
    pairs: "a Cold Winters Day.",
    noPairs: "",
    drinkRecipe: "2 oz. Putnam Rye Whiskey in Sam Adams Winter Lager.",
    rated: "R",
    picture1: summernights1,
    picture2: summernights2,
    picture3: summernights3,
    tagline: "THIS MOVIE'S A PERFECT STORM!",
    taglineWriter: "Sully Sullivan",
    bulletOne: "Learning what cops can and can't do.",
    bulletTwo: "Being weak and stupid is no way to go through life, son.",
    cast: "Directed by ELIJAH BYNUM starring TIMOTHÉE CHALAMET  MIAKA MONROE  ALEX ROE and MAIA MITCHELL",
  },
  {
    id: 1130,
    name: "Devotion",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjI5NGJjMmEtODk4Yy00ZDRjLWE5ZGItMjJiNGM4NTI0NmZkXkEyXkFqcGdeQXVyNjk1Njg5NTA@._V1_SX300.jpg",
    rating: 4,
    year: 2022,
    synopsis: "",
    genre: "War",
    subgenre: "Action",
    runtime: "139 min",
    pairs: "",
    noPairs: "",
    drinkRecipe: "",
    rated: "PG-13",
    picture1: comingSoon1,
    picture2: comingSoon2,
    picture3: comingSoon3,
    tagline: "Headline to come!",
    taglineWriter: "Some cool writer",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by J.D. DILLARD starring JONATHAN MAJORS GLEN POWELL CHRISTINA JACKSON and THOMAS SADOSKI",
  },
  {
    id: 1131,
    name: "Honk for Jesus. Save Your Soul.",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYWUwZjNiMzQtNzZiOC00YjdjLWFiMTctOGNlMWI3ZDZkN2MxXkEyXkFqcGdeQXVyMDA4NzMyOA@@._V1_SX300.jpg",
    rating: 4,
    year: 2022,
    synopsis:
      "A documentary crew, with an unknown agenda, follows a shamed power couple attempting to rebound their Southern Baptist Church into the hearts of the religious community.",
    genre: "Comedy",
    subgenre: "Mockumentary",
    runtime: "102 min",
    pairs: "the Blood of Christ, Ah-Men!",
    noPairs: "",
    drinkRecipe: "Sangria, Coca-Cola, Mt. Gay Rum.",
    rated: "R",
    picture1: honk41,
    picture2: honk42,
    picture3: honk43,
    tagline: "STAND BY YOUR MAN!",
    taglineWriter: "Tammi Fey",
    bulletOne: "The rap-a-long car ride is cute and fun.",
    bulletTwo: "A black comedy featuring a Black cast.",
    cast: "Directed by ADAMMA EBO starring REGINA HALL STERLING K. BROWN NICOLE BEHARIE and CONPHIDANCE",
  },
  {
    id: 1132,
    name: "St. Elmo's Fire",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjdlMTJkNDQtMmI5ZS00OTA1LWI2YTYtN2I5MGQwODhjOTQwXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 3,
    year: 1985,
    synopsis:
      "Thick saxy soundtrack, a causal pile of burning leafs, and taking a swirly from your buddy at the bar then laughing about it-- Breakfast Club Pt 2: Brunch Association.",
    genre: "Drama",
    subgenre: "Comedy",
    runtime: "110 min",
    pairs: "Apostle Big Bird's Water.",
    noPairs: "",
    drinkRecipe: "Vodka, Water, Lime Seltzer with lemon.",
    rated: "R",
    picture1: stelmos1,
    picture2: stelmos2,
    picture3: stelmos3,
    tagline: "College was yesterday, grow up already!",
    taglineWriter: "Van Wilder",
    bulletOne:
      "Not wanting to be in some kind of confusing 𝙬𝙚𝙚𝙠𝙚𝙣𝙙 𝙙𝙖𝙙 scenario.",
    bulletTwo: "Dudes in the 80's would NOT take a hint.",
    cast: "Directed by JOEL SCHUMACHER starring JUDD NELSON EMILIO ESTEVEZ ALLY SHEEDY and ROB LOWE",
  },
  {
    id: 1133,
    name: "Fletch",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzQ4MWI3YTYtNzlkNS00Zjc0LWI5MWItMTQwZDYzMzc1MzQ2XkEyXkFqcGdeQXVyNjE5MjUyOTM@._V1_SX300.jpg",
    rating: 4,
    year: 1985,
    synopsis:
      "The bravest investigative reporter in the world goes undercover for an all time scoop involving high class crime, Utah, and beach bums. Prime Chevy physical comedy, silly one-liners, and extremely smooth off the cuff fake names.",
    genre: "Comedy",
    subgenre: "Mockumentary",
    runtime: "98 min",
    pairs: "the Most Unusual Lemonade.",
    noPairs: "",
    drinkRecipe: "Pink Lemonade, Gin, strawberry garnish with a straw.",
    rated: "PG",
    picture1: fletch1,
    picture2: fletch2,
    picture3: fletch3,
    tagline: "Fletcher? I barely know her!",
    taglineWriter: "Clark G. Hawthorne",
    bulletOne: "Seeing why cops guide in the perp's head.",
    bulletTwo: "Waiting on Mr. Stanwyk's credit card information.",
    cast: "Directed by MICHAEL RITCHIE starring CHEVY CHASE JOE DON BAKER DANA WHEELER-NICOLSON and GEORGE WENDT",
  },
  {
    id: 1134,
    name: "A Christmas Story Christmas",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjliZjM5MWMtMzcyYi00Y2ZiLTliOGMtNGNjMjE1YWIwZTkyXkEyXkFqcGdeQXVyMTkxNjUyNQ@@._V1_SX300.jpg",
    rating: 5,
    year: 2022,
    synopsis:
      "Ralphie is grown up and in desperate need of a Christmas miracle. Luckily he still has all four eyes.",
    genre: "Comedy",
    subgenre: "Christmas",
    runtime: "98 min",
    pairs: "",
    noPairs: "Free Beer with the Budz.",
    drinkRecipe: "",
    rated: "PG",
    picture1: xstoryxmas1,
    picture2: xstoryxmas2,
    picture3: xstoryxmas3,
    tagline: "WORTH A 24HR MARATHON!",
    taglineWriter: "TBS",
    bulletOne:
      "Solid follow-up that bridges the gap between nostalgia and modern day 1970s.",
    bulletTwo: "Getting the gang back together again.",
    cast: "Directed by CLAY KAYTIS starring PETER BILLINGSLEY ERINN HAYES JULIE HAGERTY and R.D. ROBB",
  },
  {
    id: 1135,
    name: "D.C. Cab",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODNmYjc2ZjYtODc1Ny00Mjk4LWIxMjItOGQwMzkyMDU3ODNiL2ltYWdlXkEyXkFqcGdeQXVyNzc5MjA3OA@@._V1_SX300.jpg",
    rating: 4,
    year: 1983,
    synopsis:
      "A ragtag group of drivers hustle, scheme, and do whatever is necessary to keep their kooky taxi business afloat. ",
    genre: "Comedy",
    subgenre: "Action",
    runtime: "100 min",
    pairs: "Intestinal Yoga.",
    noPairs: "",
    drinkRecipe: "whiskey and pepto bismol",
    rated: "R",
    picture1: dccab1,
    picture2: dccab2,
    picture3: dccab3,
    tagline: "YOU'LL HAVE TO CLEAN THE BACK SEATS",
    taglineWriter: "Travis Bickle",
    bulletOne: "Keeping a flamethrower handy in case you want to light a fire.",
    bulletTwo:
      "Maniac Busey's unique take on race relations and collecting fares.",
    cast: "Directed by JOEL SCHUMACHER starring MR. T MAX GAIL ADAM BALDWIN and GARY BUSEY",
  },
  {
    id: 1136,
    name: "The Firm",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDMzNDEwM2EtN2I4YS00NjRjLTk5ZTAtZTg5NjI1OWZhN2FkXkEyXkFqcGdeQXVyMjUzOTY1NTc@._V1_SX300.jpg",
    rating: 5,
    year: 1993,
    synopsis:
      "A hot shot punk thinks he's too smart for every law firm in the country, until one offer is too good to be true. This esquire extraordinaire is about to learn the differences in tax law, felony sentence negotiation, and mail fraud!",
    genre: "Crime",
    subgenre: "Drama",
    runtime: "154 min",
    pairs: "a few minor rebellions.",
    noPairs: "",
    drinkRecipe: "Red Stripe and Bacardi 151",
    rated: "R",
    picture1: thefirm1,
    picture2: thefirm2,
    picture3: thefirm3,
    tagline: "NOT THE ONLY THING THAT'S FIRM!",
    taglineWriter: "Viagra",
    bulletOne: "Gary Busey delivers another great cameo character.",
    bulletTwo: "Diabeetus is a suspiciously slick dick.",
    cast: "Directed by SYDNEY POLLACK starring TOM CRUISE GENE HACKMAN JEANNE TRIPPLEHORN and HOLLY HUNTER",
  },
  {
    id: 1137,
    name: "Whiskey Tango Foxtrot",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjIxOTIzMTM5OF5BMl5BanBnXkFtZTgwNDIxNTA1NzE@._V1_SX300.jpg",
    rating: 3,
    year: 2016,
    synopsis:
      "Based on a true story an unmarried and lonely reporter is offered the chance of a short lifetime to report on the war in Afghanistan. I hate to burst the Kabubble but this movie suffers from being made during the comedy drought of the mid twenty-teens.",
    genre: "War",
    subgenre: "Comedy",
    runtime: "112 min",
    pairs: "a Short Brian.",
    noPairs: "",
    drinkRecipe: "Extra Stout Beer and Bols Apricot Brandy",
    rated: "R",
    picture1: whistanfox1,
    picture2: whistanfox2,
    picture3: whistanfox3,
    tagline: "WOMAN DRIVER, NO SURVIVOR!",
    taglineWriter: "Louise & Thelma",
    bulletOne: "Margot Robbie is a 15 everywhere.",
    bulletTwo: "Accurate description of overweight tough guys.",
    cast: "Directed by GLENN FICARRA and JOHN REQUA starring TINA FEY MARGOT ROBBIE MARTIN FREEMAN and BILLY BOB THORNTON",
  },
  {
    id: 1138,
    name: "Birdman or The Unexpected Virtue of Ignorance",
    poster:
      "https://m.media-amazon.com/images/M/MV5BODAzNDMxMzAxOV5BMl5BanBnXkFtZTgwMDMxMjA4MjE@._V1_SX300.jpg",
    rating: 4,
    year: 2014,
    synopsis:
      "In an attempt to gain back his mega-stardom, a famous actor from the 90's directs, stars, and produces a broadway play. We're given complete backstage access to the crummy St. James Theatre and secret lives of talent and crew.",
    genre: "Drama",
    subgenre: "Comedy",
    runtime: "112 min",
    pairs: "gin.",
    noPairs: "",
    drinkRecipe: "Do not replace with water!",
    rated: "R",
    picture1: birdman1,
    picture2: birdman2,
    picture3: birdman3,
    tagline: "GENDER ROLES ARE OVERRATED!",
    taglineWriter: "Mr. Mom",
    bulletOne: "Many clever cuts, interesting style, a bit overplayed.",
    bulletTwo: "Listening to your dad rehearse while getting laid.",
    cast: "Directed by ALEJANDRO G. IÑÁRRITU starring MICHAEL KEATON EMMA STONE EDWARD NORTON and NAOMI WATTS",
  },
  {
    id: 1139,
    name: "Struggle: The Life and Lost Art of Szukalski",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTU0NjUxMDU2Ml5BMl5BanBnXkFtZTgwMDE2NTIwNzM@._V1_SX300.jpg",
    rating: 5,
    year: 2018,
    synopsis:
      "Stanley Szukalski, a once forgotten foreign sculptor, is given a chance to tell his longwinded story with a wonderful history lesson on human origins, art, and Europe during the early 20th century.",
    genre: "Documentary",
    subgenre: "Historical",
    runtime: "115 min",
    pairs: "booze.",
    noPairs: "",
    drinkRecipe: "chilled Żubrówka Vodka, neat.",
    rated: "TV-MA",
    picture1: struggle1,
    picture2: struggle2,
    picture3: struggle3,
    tagline: "I got Polish Citizenship for the jokes!",
    taglineWriter: "Tim Whatley, D.D.S.",
    bulletOne: "Having the best hype-man of the 1930's.",
    bulletTwo: "Leo and his dad could be twins.",
    cast: "Directed by IRENEUSZ DOBROWOLSKI starring STANISLAV SZUKALSKI GLENN BRAY ROBERT WILLIAMS and GEORGE DiCAPRIO",
  },
  {
    id: 1140,
    name: "I Love You, Beth Cooper",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTgyNTc2NDM3NF5BMl5BanBnXkFtZTcwNjU3Mzg0Mg@@._V1_SX300.jpg",
    rating: 5,
    year: 2009,
    synopsis:
      "On the very last day of high school, a nerdy teen confesses his love, humilates the town bullies, and forces his best friend out of the closet setting off a one-night-only adventure with the girl of his dreams that he probably doesn't regret.",
    genre: "Comedy",
    subgenre: "Romcom",
    runtime: "102 min",
    pairs: "drinks at the cabin.",
    noPairs: "",
    drinkRecipe: "3 Olives Vodka, Triple Sec, 3oz. OJ.",
    rated: "PG-13",
    picture1: bethcooper1,
    picture2: bethcooper2,
    picture3: bethcooper3,
    tagline: "Puts the dick in valedictorian!",
    taglineWriter: "Gus Cruikshank",
    bulletOne: "Being in your 50's and sneaking out to bang in a car.",
    bulletTwo: "ZERO regard for property damage.",
    cast: "Directed by CHRIS COLUMBUS starring PAUL RUST HAYDEN PANETTIERE JACK CARPENTER and NAOMI WATTS",
  },
  {
    id: 1141,
    name: "The Wonder",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDBmNzIwY2QtMjZhOC00YzlhLWIwNjMtZDdkZjY3OWUzZDI4XkEyXkFqcGdeQXVyMTAyMjQ3NzQ1._V1_SX300.jpg",
    rating: 3,
    year: 2022,
    synopsis:
      "During the 1860's a young girl's miraculous refusal to eat, yet still live, after four months puzzles the local community until two women are hired to observe and report their findings. An otherwise enjoyable period peice with a strange opening scene.",
    genre: "Drama",
    subgenre: "Historical",
    runtime: "108 min",
    pairs: "magic creek water.",
    noPairs: "",
    drinkRecipe: "powers irish whisky, large ice sphere, splash of bailey's.",
    rated: "R",
    picture1: thewonder1,
    picture2: thewonder2,
    picture3: thewonder3,
    tagline: "MADE ME HUNGRY!",
    taglineWriter: "Anorexics Anomalous",
    bulletOne:
      "Ditching your broke ass family to come back years later as a lousey journo.",
    bulletTwo: "A Brother's Kiss is Sacred.",
    cast: "Directed by SEBASTIÁN LELIO starring FLORENCE PUGH TOM BURKE KÍLA LORD CASSIDY and TOBY JONES",
  },
  {
    id: 1142,
    name: "Losin' It",
    poster:
      "https://m.media-amazon.com/images/M/MV5BZjUzMjkwMTItYzE3OC00YTE5LWI5YWEtZmRlZThiZmIyYTYzXkEyXkFqcGdeQXVyMTUzMDUzNTI3._V1_SX300.jpg",
    rating: 5,
    year: 1982,
    synopsis:
      "Horny highschoolers high-tale it down to Mexico one night lookin' for some action, cool cars, and fireworks.",
    genre: "Comedy",
    subgenre: "",
    runtime: "100 min",
    pairs: "Ta-kill-ya.",
    noPairs: "",
    drinkRecipe: "Tequila, Whiskey, So-Co, and Mexican Coke.",
    rated: "R",
    picture1: losinit1,
    picture2: losinit2,
    picture3: losinit3,
    tagline: "If you ain't usin' it, you're losin' it!",
    taglineWriter: "40 Year Old Virgins",
    bulletOne: "Tijuana, Mexico: A Capitalist's Dream.",
    bulletTwo: "Getting cucked by an 18-year-old.",
    cast: "Directed by CURTIS HANSON starring JACKIE EARLE HALEY TOM CRUISE JOHN STOCKWELL and SHELLEY LONG",
  },
  {
    id: 1143,
    name: "There's Something Wrong with Aunt Diane",
    poster:
      "https://m.media-amazon.com/images/M/MV5BN2Q3YTk0YTUtOTM4OC00NDdiLTg2ZTUtMzdiMDA2Njc5MmVlXkEyXkFqcGdeQXVyNzM0MDQ1Mw@@._V1_SX300.jpg",
    rating: 2,
    year: 2011,
    synopsis:
      "Not getting the full story, a quickly made doc rushes to judgement in order to cash in on a tragedy.",
    genre: "Documentary",
    subgenre: "Crime",
    runtime: "101 min",
    pairs: "",
    noPairs: "not drinking and driving.",
    drinkRecipe: "",
    rated: "TV-MA",
    picture1: auntdiane1,
    picture2: auntdiane2,
    picture3: auntdiane3,
    tagline: "Ya know, I knew Diane, ya know.",
    taglineWriter: "Diane's BFF, ya know",
    bulletOne: "What happened between 9:30-11:00AM?",
    bulletTwo: "The mother-in-law relationship is pretty suspicious.",
    cast: "Directed by LIZ GARBUS starring JESSE TEMPLE BRADE KATINAS and DIANE SCHULER",
  },
  {
    id: 1144,
    name: "Mortal Kombat",
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjY5NTEzZGItMGY3My00NzE4LThkYTUtYjJkNzk3MDBiMWE3XkEyXkFqcGdeQXVyNzg5MDE1MDk@._V1_SX300.jpg",
    rating: 5,
    year: 1995,
    synopsis:
      "Earth's best martial artists battle a sorceror and his goons for control of the realm. ",
    genre: "Action",
    subgenre: "Cult Classic",
    runtime: "101 min",
    pairs: "a Finisher.",
    drinkRecipe: "Everclear, red Kool-Aid.",
    rated: "PG-13",
    picture1: mortalKombat1,
    picture2: mortalKombat2,
    picture3: mortalKombat3,
    tagline: "It sounds ice cold, but humans are bums",
    taglineWriter: "Sub-Zero",
    bulletOne: "Spectacular visual effects.",
    bulletTwo: "Must-watch for fans of the video game and 90's action/kung-fu.",
    cast: "Directed by PAUL W.S. ANDERSON starring CHRISTOPHER LAMBERT ROBIN SHOU LINDEN ASHBY and CARY-HIROYUKI TAGAWA",
  },
  {
    id: 1145,
    name: "Marathon Man",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYTcwNGYwNzgtNDBlZC00NTI4LTlmNDEtZmU1NTIxMGZmMDIwXkEyXkFqcGdeQXVyMTY5Nzc4MDY@._V1_SX300.jpg",
    rating: 5,
    year: 1976,
    synopsis:
      "A Nazi doctor teaches Dustin Hoffman the importance of practicing good oral hygiene.",
    genre: "Thriller",
    subgenre: "Mystery",
    runtime: "125 min",
    pairs: "",
    noPairs: "Michelob Ultra.",
    rated: "R",
    picture1: marathonMan1,
    picture2: marathonMan2,
    picture3: marathonMan3,
    tagline: "IT REALLY STRIKES A NERVE",
    taglineWriter: "American Dental Association",
    bulletOne: "Paying your bully to rob your house.",
    bulletTwo:
      "Awesome sleeve blade that looks like it's straight out of Travis Bickle's closet.",
    cast: "Directed by JOHN SCHLESINGER starring DUSTIN HOFFMAN ROY SCHNEIDER LAURENCE OLIVIER and WILLIAM DEVANE",
  },
  {
    id: 1146,
    name: "Predestination",
    year: 2014,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTAzODc3NjU1NzNeQTJeQWpwZ15BbWU4MDk5NTQ4NTMx._V1_SX300.jpg",
    rating: 2,
    synopsis:
      "Ethan Hawke is Ethan Hawke, he is also someone else, and that person too.",
    genre: "Scifi",
    subgenre: "Drama",
    runtime: "97 min",
    pairs: "a lethal injection.",
    drinkRecipe: "3 shots - vodka, gin, and tequila",
    rated: "R",
    picture1: predestination1,
    picture2: predestination2,
    picture3: predestination3,
    tagline: "A REAL CIRCLE JERK!",
    taglineWriter: "Nathan Falcon",
    bulletOne: "Wishing it was predetermined you didn't watch this movie.",
    bulletTwo: "Taking the phrase 'go fuck yourself' literally.",
    cast: "Directed by MICHAEL SPIERIG starring ETHAN HAWKE ETHAN HAWKE ETHAN HAWKE and ETHAN HAWKE",
  },
  {
    id: 1147,
    name: "Drillbit Taylor",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTkxNzkwOTc3OF5BMl5BanBnXkFtZTcwMTc1MzU1MQ@@._V1_SX300.jpg",
    rating: 4,
    year: 2008,
    synopsis:
      "Owen Wilson stars as a homeless hero who takes advantage of three twerps looking for protection from bullies. A con so good, there’s no way it can fail, just make sure to keep track of your finances.",
    genre: "Comedy",
    subgenre: "",
    runtime: "110 min",
    pairs: "",
    noPairs: "a marijuana party.",
    rated: "PG-13",
    picture1: drillbit1,
    picture2: drillbit2,
    picture3: drillbit3,
    tagline: "Fake substitute? That's my jam!",
    taglineWriter: "Jack Black",
    bulletOne: "Wearing the same outfit as your bestie.",
    bulletTwo: "Getting discharged from the military for unauthorized heroism.",
    cast: "Directed by STEVEN BRILL starring OWEN WILSON NATE HARTLEY TROY GENTILE and JOSH PECK",
  },
  {
    id: 1148,
    name: "Adventureland",
    year: 2009,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTI1NTYyMjA2N15BMl5BanBnXkFtZTcwNjU1OTA0Mg@@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "A pothead uses his drug connection to influence innocent theme park employees in Pittsburgh during the summer of '87. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "107 min",
    pairs: "a Carny.",
    drinkRecipe: "Vodka, rock candy.",
    rated: "R",
    picture1: adventure1,
    picture2: adventure2,
    picture3: adventure3,
    tagline: "Eisenberg only does movies with 'land' in it",
    taglineWriter: "Hollywoodland Reporter",
    bulletOne: "Don’t drink your drugs",
    bulletTwo: "Scene Stealers: Bill Hader & Kristen Wiig",
    cast: "Directed by GREG MOTTOLA starring JESSE EISENBERG KRISTEN STEWART RYAN REYNOLDS and MARTIN STARR",
  },
  {
    id: 1149,
    name: "Licorice Pizza",
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjkwMzIxYzMtOTVkMS00NDQxLThkMjItNzgxN2RiNjdlNTliXkEyXkFqcGdeQXVyODE5NzE3OTE@._V1_SX300.jpg",
    rating: 4,
    year: 2021,
    synopsis:
      "In 1970's California, a pedophile grooms the local highschool boys that she works with.",
    genre: "Comedy",
    subgenre: "Drama",
    runtime: "133 min",
    pairs: "a Twizzler Hoagie.",
    drinkRecipe: "Hawaiian punch, gin, miller lite.",
    noPairs: "",
    rated: "R",
    picture1: licoricepizza1,
    picture2: licoricepizza2,
    picture3: licoricepizza3,
    tagline: "NO ONE EVEN EATS PIZZA!",
    taglineWriter: "Mama Joan",
    bulletOne: "Driving a truck on E further than on F.",
    bulletTwo: "This would be courtroom footage had the ages been reversed.",
    cast: "Directed by PAUL THOMAS ANDERSON starring ALANA HAIM COOPER HOFFMAN SEAN PENN and BRADLEY COOPER",
  },
  {
    id: 1150,
    name: "Once Upon a Time in America",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGFkNWI4MTMtNGQ0OC00MWVmLTk3MTktOGYxN2Y2YWVkZWE2XkEyXkFqcGdeQXVyNjU0OTQ0OTY@._V1_SX300.jpg",
    rating: 3,
    year: 1984,
    synopsis:
      "This historical crime drama follows the lives of a coupl'a trouble boys, minute by minute, for over 35 years.",
    genre: "Crime",
    subgenre: "Drama",
    runtime: "229 min",
    pairs: "a bottle or two of Prohibition Gin.",
    rated: "R",
    picture1: onceamerica1,
    picture2: onceamerica2,
    picture3: onceamerica3,
    tagline: "CHICKENSHIT COCKSUCKER CAN'T DO IT HIMSELF!",
    taglineWriter: "Lester Diamond",
    bulletOne: "Wishing they'd made it in two parts.",
    bulletTwo: "Getting a boner from stinky street trash.",
    cast: "Directed by SERGIO LEONE starring ROBERT DE NIRO JAMES WOODS ELIZABETH MCGOVERN and BURT YOUNG",
  },
  {
    id: 1151,
    name: "Scream",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjA2NjU5MTg5OF5BMl5BanBnXkFtZTgwOTkyMzQxMDE@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Drew Berrymore stars in this teen slasher that revitilized the genre.",
    genre: "Horror",
    subgenre: "Thriller",
    runtime: "111 min",
    pairs: "",
    noPairs: "anything from a red solo cup.",
    rated: "R",
    picture1: scream1,
    picture2: scream2,
    picture3: scream3,
    tagline: "Don't the police have redial?",
    taglineWriter: "A.G. Bell",
    bulletOne: "No plot armor is strong enough.",
    bulletTwo: "A near-perfect antecedent tribute to 'Scary Movie'.",
    cast: "Directed by WES CRAVEN starring DREW BERRYMORE  NEVE CAMPBELL  COURTENEY COX  and DAVID COX-ARQUETTE",
  },
  {
    id: 1152,
    name: "The Benchwarmers",
    year: 2006,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTcxMzg3NDQwNl5BMl5BanBnXkFtZTcwMjk1ODQzMQ@@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "Three losers who have been bullied their entire lives get a chance at redemption by playing little league as fully grown adult men.",
    genre: "Comedy",
    subgenre: "Sports",
    runtime: "85 min",
    pairs: "Sunscreen.",
    drinkRecipe: "Milk, cream, vodka.",
    rated: "PG-13",
    picture1: benchwarmers1,
    picture2: benchwarmers2,
    picture3: benchwarmers3,
    tagline: "KNOCKED IT OUT OF THE PARK!",
    taglineWriter: "Gus Bus",
    bulletOne: "Going to Pizza Hut after winning a game.",
    bulletTwo:
      "Making billions as an adult and getting revenge on your childhood bullies",
    cast: "Directed by DENNIS DUGAN starring ROB SCHNEIDER JON HEDER DAVID SPADE and NICK SWARDSON",
  },
  {
    id: 1153,
    name: "A Million Ways to Die in the West",
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQ0NDcyNjg0MV5BMl5BanBnXkFtZTgwMzk4NTA4MTE@._V1_SX300.jpg",
    rating: 4,
    year: 2014,
    synopsis:
      "A cowardly weasel falls in love with a notorious gunslingers wife who's already Taken. With so many ways to die, how will he survive?",
    genre: "Comedy",
    subgenre: "Western",
    runtime: "116 min",
    pairs: "Moonshine and Magical Elixir.",
    drinkRecipe: "Sasparilla and whiskey.",
    rated: "R",
    picture1: million1,
    picture2: million2,
    picture3: million3,
    tagline: "Liam Neeson is a hopeless American Cowboy",
    taglineWriter: "Peter Griffin",
    bulletOne: "Cameo's galore",
    bulletTwo: "SPOILER ALERT: No where near a million deaths",
    cast: "Directed by SETH MACFARLANE starring CHARLIZE THERON SETH MACFARLANE LIAM NEESON and AMANDA SEYFRIED",
  },
  {
    id: 1154,
    name: "Cirque du Freak: The Vampires Assistant",
    year: 2009,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTI1NDA1MTMwNl5BMl5BanBnXkFtZTcwNTg0MDE4Mg@@._V1_SX300.jpg",
    rating: 3,
    synopsis:
      "The Last of the Mo-Freakins battle for the fate of the world comes down to highschool best frienemies.",
    genre: "Adventure",
    subgenre: "Scifi",
    runtime: "109 min",
    pairs: "a Spider's Kiss.",
    drinkRecipe: "Coke, Cranberry Smirnoff, Lime.",
    rated: "PG-13",
    picture1: cirqueFreak1,
    picture2: cirqueFreak2,
    picture3: cirqueFreak3,
    tagline: "I DIET, NATURALLY!",
    taglineWriter: "Jenna Maroney",
    bulletOne: "Failing to cash in on the late 2000's vampire craze",
    bulletTwo: "Salma Hayek's facial hair may awaken something in you",
    cast: "Directed by PAUL WEITZ starring CHRIS MASSOGLIA JOHN C. REILLY SALMA HAYEK JOSH HUTCHERSON",
  },
  {
    id: 1155,
    name: "Edge of Tomorrow",
    year: 2014,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTc5OTk4MTM3M15BMl5BanBnXkFtZTgwODcxNjg3MDE@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "When aliens take over most of mainland Europe, a Major military brat gets a series of wake up calls after he's demoted to private and forced to the front lines of a doomed beach assault.",
    genre: "Action",
    subgenre: "Scifi",
    runtime: "113 min",
    pairs: "Future Blood.",
    drinkRecipe: "Sangria, Dr. Pepper, and Lemonade.",
    rated: "PG-13",
    picture1: edgetomorrow1,
    picture2: edgetomorrow2,
    picture3: edgetomorrow3,
    tagline: "GETS BETTER EVERY REWATCH",
    taglineWriter: "Tomorrowland Review",
    bulletOne:
      "Willing to try all the options with the Full Metal Angel of Verdun.",
    bulletTwo: "Using your cool Witcher eye thing.",
    cast: "Directed by DOUG LIMAN starring TOM CRUISE EMILY CLUNT BILL PAXTON and TERENCE MAYNARD",
  },
  {
    id: 1156,
    name: "Oppenheimer",
    year: 2023,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDBmYTZjNjUtN2M1MS00MTQ2LTk2ODgtNzc2M2QyZGE5NTVjXkEyXkFqcGdeQXVyNzAwMjU2MTY@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "The story of a gaunt American theoretical physicist as told by a bunch of Brits.",
    genre: "Drama",
    subgenre: "History",
    runtime: "180 min",
    pairs: "dropping bombs.",
    drinkRecipe: "JÄGERBOMB & Irish Car Bomb.",
    rated: "PG-13",
    picture1: oppenheimer1,
    picture2: oppenheimer2,
    picture3: oppenheimer3,
    tagline: "MATH CHECKS OUT UND GREAT VIEW OF PUGH!",
    taglineWriter: "Alfred Einsteen",
    bulletOne: "Being super duper long as fuck.",
    bulletTwo: "WAY overestimating your importance to others.",
    cast: "Directed by CHRISTOPHER NOLAN starring CILLIAN MURPHY EMILY BLUNT MATT DAMON and KENNETH BRANAGH",
  },
  {
    id: 1157,
    name: "The Out-Laws",
    year: 2023,
    poster:
      "https://m.media-amazon.com/images/M/MV5BM2JmMmE0NjYtODUzNS00ZDg2LTk2ZjMtNDBiNjhlMGU2NjJlXkEyXkFqcGdeQXVyMTQ4NTgxNzYx._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A pasty bank manager needs to commit security fraud in order to keep his fiancee and have a Father-Out-Law who everyone on Earth wants to bang.",
    genre: "Comedy",
    subgenre: "Action",
    runtime: "180 min",
    noPairs: "drinking while the sun is out.",
    drinkRecipe: ".",
    rated: "PG-13",
    picture1: outlaws1,
    picture2: outlaws2,
    picture3: outlaws3,
    tagline: "MORE LIKE OWEN'S OUT-BALLS!",
    taglineWriter: "Phoebe King",
    bulletOne: "Knowing your CPR training will kick in during a crisis.",
    bulletTwo: "Learning about Dan Marino's second cannon.",
    cast: "Directed by TYLER SPINDEL starring ADAM DEVINE  PIERCE BROSNAN  ELLEN BARKIN  and NONA DOBREV",
  },
  {
    id: 1158,
    name: "Happy Valley",
    year: 2014,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTk0OTkxMjkxOF5BMl5BanBnXkFtZTgwMzg1NjUxMzE@._V1_SX300.jpg",
    rating: 4,
    synopsis: "",
    genre: "Documentary",
    subgenre: "Drama",
    runtime: "98 min",
    noPairs: "",
    drinkRecipe: "",
    rated: "NR",
    picture1: outlaws1,
    picture2: outlaws2,
    picture3: outlaws3,
    tagline: "SANDUSKHIM? I HARDLY KNOW HIM!",
    taglineWriter: "Scott Tillmann",
    bulletOne: "",
    bulletTwo: "",
    cast: "Directed by AMIR BAR-LEV starring JOE PATERNO JAY PATERNO ANDREW SHUBIN and NONA DOBREV",
  },
  {
    id: 1159,
    name: "Happiness for Beginners",
    year: 2023,
    poster:
      "https://m.media-amazon.com/images/M/MV5BM2E5MTExODktMjEzYy00OTQ1LThiZGYtM2RiYTFjOWE0ODNlXkEyXkFqcGdeQXVyMjkwMjY1MjI@._V1_SX300.jpg",
    rating: 3,
    synopsis:
      "Helen with an 'H' is an unprepared, and unaware, adult camper who doesn't need any help from anyone; especially not some lousy handsome doctor who is very clearly into her. Don't be Helen with an 'H'.",
    genre: "Romcom",
    subgenre: "Comedy",
    runtime: "103 min",
    pairs: "Two Drinks for a Lightweight.",
    drinkRecipe: "1 MARG & 1 PIÑA",
    rated: "TV-14",
    picture1: happinessbeginners1,
    picture2: happinessbeginners2,
    picture3: happinessbeginners3,
    tagline: "OKAY PEOPLE!",
    taglineWriter: "Doogie Howser",
    bulletOne: "Be more like Windy with an 'i'.",
    bulletTwo: "Serious BSC voter fraud needs to be addressed.",
    cast: "Directed by VICKY WIGHT starring JELLIE KEMPER LUKE GRIMES NICO SANTOS and BEN COOK",
  },
  {
    id: 1160,
    name: "Dumb Money",
    year: 2023,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzNlODQ3ZjUtYzVmYi00YmMwLTk5YTgtYzZkOGEzZmIwZGE3XkEyXkFqcGdeQXVyMTAxNzQ1NzI@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A feline-loving dork convinces the world to hodl Gamestop stock til it hits the MOON.",
    genre: "Comedy",
    subgenre: "",
    runtime: "104 min",
    pairs: "a Golden Tycoon.",
    drinkRecipe:
      "goldschlager, grand marnier, cointreau, orange juice. Serve over ice.",
    rated: "R",
    picture1: dumbMoney1,
    picture2: dumbMoney2,
    picture3: dumbMoney3,
    tagline: "THE PUSS OF WALL STREET",
    taglineWriter: "Leonardo DiCatrio",
    bulletOne: "Seeing the little guy stick it to the fatcats.",
    bulletTwo: "Wishing you'd ever made a good investment.",
    cast: "Directed by CRAIG GILLESPIE starring PAUL DANO PETE DAVIDSON SHAILENE WOODLEY and SETH ROGEN",
  },
  {
    id: 1161,
    name: "Field of Dreams",
    year: 1989,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzk5OWY0YjAtYWU3ZS00Y2Q4LWFlNjItMzgwMTQ2MjIyMDFmL2ltYWdlL2ltYWdlXkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 2,
    synopsis:
      "A psychotic man and his family are willing to bet the farm on a mass hallucination while watching grass grow where their crops should be in this abosulte snooze-fest.",
    genre: "Drama",
    subgenre: "Scifi",
    runtime: "107 min",
    pairs: "Maize Ethanol.",
    drinkRecipe: "Yellow Gatorade, Corn whiskey, Lime.",
    rated: "PG",
    picture1: fielddreams1,
    picture2: fielddreams2,
    picture3: fielddreams3,
    tagline: "PEACE, LOVE, DOPE!",
    taglineWriter: "Darth Vader",
    bulletOne:
      "Hating your son for life 'cause he dissed your favorite ball player.",
    bulletTwo: "Somehow this movie is loved by millions.",
    cast: "Directed by PHIL ALDEN ROBINSON starring KEVIN COSTNER RAY LIOTTA AMY MADIGAN and JAMES EARL JONES",
  },
  {
    id: 1162,
    name: "Stoker",
    year: 2013,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjI3MTM5ODI5MV5BMl5BanBnXkFtZTcwMjE1Mzc4OA@@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "After her father's sudden death a quiet weirdo must attend her long-lost uncle's intimate piano lessons.",
    genre: "Drama",
    subgenre: "Horror",
    runtime: "99 min",
    pairs: "a Key Stroker.",
    drinkRecipe: "Baileys, Milk, iced Black Coffee.",
    rated: "R",
    picture1: stoker1,
    picture2: stoker2,
    picture3: stoker3,
    tagline: "I hate her outfit.",
    taglineWriter: "Wednesday Adams",
    bulletOne: "Learning where to stand on the stairs.",
    bulletTwo: "Getting super turned on by a bloody lip.",
    cast: "Directed by PARK CHAN-WOOK starring MIA WASIKOWSKA NICOLE KIDMAN PHYLLIS SOMERVILLE and MATTHEW GOODE",
  },
  {
    id: 1163,
    name: "Spy Game",
    year: 2001,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjNhOGZkNzktMGU3NC00ODk2LWE4NjctZTliN2JjZTQxZmIxXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_SX300.jpg",
    rating: "3",
    synopsis:
      "On his very last day before retirement, a happy-go-lucky spymaster gives an exit-interview and reminisces about a former collegue who is locked up abroad.",
    genre: "Drama",
    subgenre: "Thriller",
    runtime: "126 min",
    pairs: "an Operation Breakfast In.",
    drinkRecipe: "OJ, Champagne, Baiju.",
    rated: "R",
    picture1: spygame1,
    picture2: spygame2,
    picture3: spygame3,
    tagline: "Bobby's still got his 𝙎𝙩𝙞𝙣𝙜!",
    taglineWriter: "Paul Oldman",
    bulletOne: "CIA Operative must haves: Sandy blonde hair and sweet shades.",
    bulletTwo: "By the fourth wife... you learn to speak in code.",
    cast: "Directed by TONY SCOTT starring ROBERT REDFORD BRAD PITT MARIANNE JEAN-BAPTISTE and CATHERINE McCORMACK",
  },
  {
    id: 1164,
    name: "Wallace & Gromit: The Curse of the Were-Rabbit",
    year: 2001,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOGNlNWI5Y2QtZDkyZS00N2IxLWFkN2ItZDA4Mjk1N2FlYmZiXkEyXkFqcGdeQXVyNTM3MDMyMDQ@._V1_SX300.jpg",
    rating: "4",
    synopsis:
      "How would one catch a Were-Rabbit with teeth the size of axe blades and ears like terrible tombstones? With a big trap of course!",
    genre: "Animation",
    subgenre: "Family",
    runtime: "85 min",
    pairs: "a 24k Bullet.",
    drinkRecipe: "Carrot Juice, Vodka, OJ.",
    rated: "G",
    picture1: wererabbit1,
    picture2: wererabbit2,
    picture3: wererabbit3,
    tagline: "A RUBE WITH IMPRACTICAL INVENTIONS!",
    taglineWriter: "Rude Goldberg",
    bulletOne: "Quartermaine's hare-raising proposal.",
    bulletTwo: "Being allowed to enter your crush's secret garden.",
    cast: "Directed by NICK PARK & STEVE BOX starring PETER SALLIS HELENA BONHAM CARTER RALPH FIENNES and NICHOLAS SMITH",
  },
  {
    id: 1165,
    name: "Killers of the Flower Moon",
    year: 2023,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNjQwOGM2YTItMGYwNC00YTM4LWI0Y2QtZjQ2ZDcyMmRjMTFhXkEyXkFqcGdeQXVyMDM2NDM2MQ@@._V1_SX300.jpg",
    rating: 2,
    synopsis:
      "Marty Scorsese is usually a home run hitter, but this was a swing and a miss. It's not that he's past his prime; it's just that this film ran past our bedtime. ",
    genre: "Crime",
    subgenre: "",
    runtime: "206 min",
    pairs: "an Hourglass.",
    drinkRecipe: "gin, lemon juice, simple syrup, dash of bitters",
    rated: "R",
    picture1: flowerMoon1,
    picture2: flowerMoon2,
    picture3: flowerMoon3,
    tagline: "HOLY SHIT THAT WAS LONG",
    taglineWriter: "Lawrence of Arabia",
    bulletOne: "This SLOW BURN had almost zero twists and turns.",
    bulletTwo: "Did we mention that its runtime is FOREVER?!",
    cast: "Directed by MARTIN SCORSESE starring ROBERT DENIRO LEONARDO DICAPRIO LILY GLADSTONE and JESSE PLEMONS",
  },
  {
    id: 1166,
    name: "Old Dads",
    year: 2023,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGYxYTVmMGItODQ4YS00NDZmLTg3ZjUtNjZiMzhlM2NmZjhhXkEyXkFqcGdeQXVyOTQ5Nzg2MTU@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "New (old) fathers and perenially annoyed Bill Burr get mad at all the infuriating newfangled things in the modern world.",
    genre: "Comedy",
    subgenre: "",
    runtime: "104 min",
    pairs: "Vintage Spice.",
    drinkRecipe:
      "aged bourbon, amaretto, orange liquer, fresh lemon juice, dash of bitters",
    rated: "R",
    picture1: oldDads1,
    picture2: oldDads2,
    picture3: oldDads3,
    tagline: "I LOVE THOSE OLD DOGS!",
    taglineWriter: "John Travolta",
    bulletOne:
      "Rightfully skewers overprotective parents and other annoying people.",
    bulletTwo: "Bill Burr transforming everyday irritations to funny rants.",
    cast: "Directed by BILL BURR starring BILL BURR BOKEEM WOODBINE BOBBY CANNAVALE and KATIE ASELTON",
  },
  {
    id: 1167,
    name: "The Killer",
    year: 2023,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZGJkMDUwZWQtYTMzMS00NTg5LWE1ZTYtOTVhMDI4NGI1YjMyXkEyXkFqcGdeQXVyODk4OTc3MTY@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Completely detached from any emotion, a Killer (who trusts absolutely no one and has zero empathy what-so-ever) hunts down everyone responsible for hurting his innocent girlfiend; who he loves and cares for dearly.",
    genre: "Thriller",
    subgenre: "Drama",
    runtime: "118 min",
    pairs: "a flight of whiskeys.",
    drinkRecipe:
      "Bulleit, Leadslingers Bourbon, Makers Mark, and Heaven's Door Double Barrel",
    rated: "R",
    picture1: thekiller1,
    picture2: thekiller2,
    picture3: thekiller3,
    tagline: "NEVER MISS YOUR MARK!",
    taglineWriter: "Billy Summers",
    bulletOne: "Almost making a bucket hat look cool.",
    bulletTwo:
      "Learning contract killing is mostly about waiting around, sleeping in uncomfortable places, and product placement.",
    cast: "Directed by DAVID FINCHER starring MICHAEL FASSBENDER TILDA SWINTON CHARELS PARNELL and ARLISS HOWARD",
  },
  {
    id: 1168,
    name: "The Adventures of Tintin",
    year: 2011,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDE5MDExNTQ1OF5BMl5BanBnXkFtZTcwMDIxMTM5Ng@@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A nosey little reporter and his super smart pup purchase a priceless model ship for next to nothing-- only to find it's almost more trouble than it's worth(less).",
    genre: "Animation",
    subgenre: "",
    runtime: "107 min",
    pairs: "Medicinal Spirits.",
    drinkRecipe: "The Real McCoy White Rum and Dr. Pepper",
    rated: "PG",
    picture1: tintin1,
    picture2: tintin2,
    picture3: tintin3,
    tagline: "SPIELBERG TURNS TINTIN INTO GOLDGOLD!",
    taglineWriter: "Chrys O'poeia",
    bulletOne: "Upgrading from a 2 Star hotel without ever changing rooms.",
    bulletTwo: "Hallucinating in the desert becomes a sobering reality.",
    cast: "Directed by STEVEN SPIELBERG starring JAMIE BELL ANDY SERKIS TOBY JONES and DANIEL CRAIG",
  },
  {
    id: 1169,
    name: "The Road to El Dorado",
    year: 2000,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTEzNWIwMzctOTE1YS00YjIyLTgwZGEtMTMxZDAzNzlmODMxXkEyXkFqcGdeQXVyMjgyMDk1MzY@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Two bumbling buffoons use a stolen map to locate the lost Aztec City of Gold and are dumbfounded by it's inhabitants.",
    genre: "Animation",
    subgenre: "",
    runtime: "89 min",
    pairs: "Calles del Oro.",
    drinkRecipe: "Mezcal, Sprite, lemon wedge, ice.",
    rated: "PG",
    picture1: eldorado1,
    picture2: eldorado2,
    picture3: eldorado3,
    tagline: "FORTUNE FAVORS THE GOLD!",
    taglineWriter: "Xerxes",
    bulletOne:
      "The old fake argument to get out of a fight trick works everytime.",
    bulletTwo: "Using your pet armadillo's God-like skills in hand-ring-rugby.",
    cast: "Directed by BIBO BERGERON DON PAUL and JEFFERY KATZENBERG starring KEVIN KLINE KENNETH BRANAGH EDWARD JAMES OLMOS and ROSIE PEREZ",
  },
  {
    id: 1170,
    name: "Totally Killer",
    year: 2023,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYzQwYzY4YWUtNDMxMS00Y2UxLTlhODQtY2ExZDY5ZDhhZGMzXkEyXkFqcGdeQXVyMTUzMTg2ODkz._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Thanks to her BBFs high school science project, 17 year old travels in time to meet the local Mean Girls of 1987 in an attempt to prevent the future murder of her mother.",
    genre: "Horror",
    subgenre: "Comedy",
    runtime: "106 min",
    pairs: "Podcast Pulitzer.",
    drinkRecipe: "Whiskey, 7-Up, Miller Lite in a Red Solo Cup.",
    rated: "R",
    picture1: totallykiller1,
    picture2: totallykiller2,
    picture3: totallykiller3,
    tagline: "OMG! IT'S LIKE TOTALLY AWESOME!!",
    taglineWriter: "Valley Girl Vogue",
    bulletOne: "Just think maybe giving blow jobs could save your life.",
    bulletTwo: "In both timelines, Randy is the hero that no one deserved.",
    cast: "Directed by NAHNATCHKA KHAN starring KIERNAN SHIPKA OLIVIA HOLT CHARLIE GILLESPIE and TROY LEIGH-ANNE JOHNSON",
  },
  {
    id: 1171,
    name: "Hell Camp: Teen Nightmare",
    year: 2023,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDliNmYzNDMtMGMyMS00NjZhLWJiOTYtNGZhM2YxMTc1MmU5XkEyXkFqcGdeQXVyMTQxNzMzNDI@._V1_SX300.jpg",
    rating: 2,
    synopsis:
      "Adult children complain about roughin' it one summer in the 1980's.",
    genre: "Documentary",
    subgenre: "",
    runtime: "90 min",
    pairs: "Peanut Butter S'mores.",
    drinkRecipe: "Screwball, Dragon's Milk Chocolate Stout.",
    rated: "NR",
    picture1: hellcamp1,
    picture2: hellcamp3,
    picture3: hellcamp2,
    tagline: "THEY SHOULD MEET MR. WILSON!",
    taglineWriter: "Dennis da Menace",
    bulletOne:
      "Having wealthy parents so you can avoid juvie and not take responsibility for your own behavior.",
    bulletTwo: "Just watch that one episode of 𝙎𝙞𝙭 𝙁𝙚𝙚𝙩 𝙐𝙣𝙙𝙚𝙧 instead (S1E8).",
    cast: "Directed by LIZA WILLIAMS starring NADINE LOUISE GUERRARA STEVE CARTISANO CHRIS SMITH and LANCE 'HORSEHAIR' JAGGAR",
  },
  {
    id: 1172,
    name: "The Five Year Engagement",
    year: 2012,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTk3MDE1NTA3M15BMl5BanBnXkFtZTcwNDcwNTMxNw@@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "After one year of dating, a Bay area couple move to Minnesota and begin the first half of their 10 year plan.",
    genre: "Comedy",
    subgenre: "romcom",
    runtime: "124 min",
    pairs: "Just Honey.",
    drinkRecipe: "Topped off with some mead [huge mug required].",
    rated: "R",
    picture1: fiveyearengage1,
    picture2: fiveyearengage2,
    picture3: fiveyearengage3,
    tagline: "Ayayayaaaaaaayyyyy!!",
    taglineWriter: "Andy & Annie",
    bulletOne: "Lizzie Gray for the hat trick!",
    bulletTwo: "Lamb Chop Tom < Chef Tom",
    cast: "Directed by NICHOLAS STOLLER starring JASON SEGEL EMILY BLUNT CHRIS PRATT and ALLISON BRIE",
  },
  {
    id: 1173,
    name: "X",
    year: 2022,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTJiMmE5YWItOWZjYS00YTg0LWE0MTYtMzg2ZTY4YjNkNDEzXkEyXkFqcGdeQXVyMTAzMDg4NzU0._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A group of hopeful adult filmmakers rent a cabin on a remote Texan farm from two aging lovers- who refuse to be kept out of the movie.",
    genre: "Horror",
    subgenre: "",
    runtime: "124 min",
    pairs: "Sex in the Barn.",
    drinkRecipe: "Margarita mix, Tequila, Whiskey, Sprite.",
    rated: "R",
    picture1: xmovie1,
    picture2: xmovie2,
    picture3: xmovie3,
    tagline: "Y'all know y'all like titties too, y'all.",
    taglineWriter: "Hannah Hits Houston",
    bulletOne: "That hot old woman is a real pain in the neck.",
    bulletTwo:
      "Immediately changing tunes when it's your own girlfriend on the line.",
    cast: "Directed by TI WEST starring MIA GOTH JENNA ORTEGA BRITTANY SNOW and KID CUDI",
  },
  {
    id: 1174,
    name: "Velvet Buzzsaw",
    year: 2019,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjExMDQ1NjMzOV5BMl5BanBnXkFtZTgwMjkxMzMyNzM@._V1_SX300.jpg",
    rating: 3,
    synopsis:
      "Pretentious gallery freaks get their comeuppance while profiting from a dead janitor's crazy works of art.",
    genre: "Horror",
    subgenre: "",
    runtime: "112 min",
    pairs: "Corduroy Jackhammer.",
    drinkRecipe: "Pineapple juice, Plum schnapps, Blue Curacao, lots of ice.",
    rated: "R",
    picture1: velvetbuzz1,
    picture2: velvetbuzz2,
    picture3: velvetbuzz3,
    tagline: "Uh. What.",
    taglineWriter: "Sylvester's Delí",
    bulletOne: "The LA version of Will Hunting.",
    bulletTwo: "How many hints do you need to move back to Minnesota already?!",
    cast: "Directed by DAN GILROY starring JAKE GYLLENHAAL RENE RUSSO NATALIA DYER and ZAWE ASHTON",
  },
  {
    id: 1175,
    name: "Terror at Bloodfart Lake",
    year: 2009,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTk5MzMxMTgwNV5BMl5BanBnXkFtZTcwOTkzODA3Mg@@._V1_SX300.jpg",
    rating: 2,
    synopsis:
      "A group of retards go to a secluded lake and become more retarded, then some die.",
    genre: "Horror",
    subgenre: "Comedy",
    runtime: "76 min",
    pairs: "a Tits McGee.",
    drinkRecipe: "Vodka, peach schnapps, cranberry juice, orange juice.",
    rated: "Not rated",
    picture1: bloodfart1,
    picture2: bloodfart2,
    picture3: bloodfart3,
    tagline: "THIS MOVIE SUCKS",
    taglineWriter: "Everyone",
    bulletOne: "There is a hot chick who has a great body and shows her tits.",
    bulletTwo: "There is a hot chick who has a great body and shows her tits.",
    cast: "Directed by CHRIS SEAVER starring JESSE AMES ANDREW BALTES NICOLA FIORE and BILLY GARBERINA",
  },
  {
    id: 1176,
    name: "Teenage Mutant Ninja Turtles",
    year: 1990,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNzg3NTQ4NDk5NV5BMl5BanBnXkFtZTgwNzMzNDg4NjE@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "A ruthless, Japanese ninja master warrior and large-scale crime boss can't control his rage when he comes across a rat-human and four turtle-humans in New York where no one is really bothered by it.",
    genre: "Comedy",
    subgenre: "Action",
    runtime: "93 min",
    pairs: "Tipsy Turtle.",
    drinkRecipe: "Coconut rum, dark rum, blue curaçao, orange juice.",
    rated: "PG",
    picture1: tmnt1,
    picture2: tmnt2,
    picture3: tmnt3,
    tagline: "Never pay full price for late pizza",
    taglineWriter: "Michelangelo (the painter)",
    bulletOne: "Jose Canseco bats are worthless.",
    bulletTwo: "Wayne Gretzky on steroids.",
    cast: "Directed by STEVE BARRON starring JUDITH HOAG ELIAS KOTAS JOSH PAIS and COREY FELDMAN",
  },
  {
    id: 1177,
    name: "Belfast",
    year: 2021,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOGU1MmMyNTMtMGE3Ni00N2E0LWFlYjEtMDIwNDk5NzUyODAzXkEyXkFqcGc@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "A young whippersnapper doesn't see the point of the Catholic vs. Protestant Troubles in the 1960's. Kids, eh?",
    genre: "Drama",
    subgenre: "",
    runtime: "98 min",
    pairs: "a Flaming Sidecar.",
    drinkRecipe:
      "Cognac and Cointreau topped with high proof rum and set on fire.",
    rated: "PG-13",
    picture1: belfast1,
    picture2: belfast2,
    picture3: belfast3,
    tagline: "A SUMMER BLOCKBUSTER!",
    taglineWriter: "Ira O'Carbomb",
    bulletOne: "Brick beats gun.",
    bulletTwo:
      "The Troubles with Van Morrison's music since most of it is not written in the 1960's so it just sounds weird and out of place.",
    cast: "Directed by KENNETH BRANAGH starring JUDE HILL LEWIS McASKIE CAITRIONA BALFE and JAMIE DORNAN",
  },
  {
    id: 1178,
    name: "Fubar",
    year: 2002,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTU3MzY0NzgwN15BMl5BanBnXkFtZTcwMDgxOTUyMQ@@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "Fuckin' eh this is a good comedy. A couple of Canadian dirtbags (which is meant as an absolute compliment) crush beers and frickin' give 'er during the countdown to nut removal.",
    genre: "Comedy",
    subgenre: "",
    runtime: "76 min",
    pairs: "",
    noPairs: "Old Style Pilsner.",
    drinkRecipe: "",
    rated: "R",
    picture1: fubar1,
    picture2: fubar2,
    picture3: fubar3,
    tagline: "NOW I'VE SEEN IT ALL",
    taglineWriter: "Bindare Dundat",
    bulletOne: "Livin' each day blind and going the distance.",
    bulletTwo: "Still partying even if you're tired, you fuckin' baldy.",
    cast: "Directed by MICHAEL DOWSE starring PAUL SPENCE DAVID LAWRENCE GORDON SKILLING and ANDREW SPARACINO",
  },
  {
    id: 1179,
    name: "Howard the Duck",
    year: 1986,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMGE5YTg3ZWMtYTdiOS00YTdlLWIzYTQtMDM0OWM0MmRlZDA4XkEyXkFqcGdeQXVyNjc5NjEzNA@@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "In this delightfully insane flick, Howard is a sarcastic wise-quacker who's thrust into a parallel universe. Still wondering how the duck this got made, and who paid the bill. ",
    genre: "Comedy",
    subgenre: "",
    runtime: "110 min",
    pairs: "",
    noPairs: "big brewskis (glass bottle) and cigars.",
    drinkRecipe: "",
    rated: "PG",
    picture1: howardduck1,
    picture2: howardduck2,
    picture3: howardduck3,
    tagline: "THE ONE GOOD MARVEL MOVIE",
    taglineWriter: "Tony Snark",
    bulletOne:
      "The very fact that this movie exists is reason enough to watch it.",
    bulletTwo: "Lea Thompson is a MILF - just ask Michael J. Fox.",
    cast: "Directed by WILLARD HUYCK starring LEA THOMPSON CHIP ZIEN JEFFREY JONES and TIM ROBBINS",
  },
  {
    id: 1180,
    name: "Falling for Christmas",
    year: 2022,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMDNhMWUxMjctZWFhNy00MjUzLWE3NjYtNTE4ZjVhYjRkZThmXkEyXkFqcGdeQXVyMTkxNjUyNQ@@._V1_SX300.jpg",
    rating: 2,
    synopsis:
      "Lindsay Lohan gets amnesia and can't remember who she is, but nobody in town knows either. It's a Christmas miracle!",
    genre: "Romcom",
    subgenre: "",
    runtime: "93 min",
    pairs: "a Snowy Martini.",
    noPairs: "",
    drinkRecipe: "Martini with Prozac-dusted rim.",
    rated: "TV-PG",
    picture1: fallingChristmas1,
    picture2: fallingChristmas2,
    picture3: fallingChristmas3,
    tagline: "My only regret is not working with Lindsay Lohan",
    taglineWriter: "Fartin Snorcese",
    bulletOne: "The Santa Claus in this movie is a confirmed pedophile.",
    bulletTwo:
      "Grandma and granddaughter take Prozac, it's the only explanation for why they're so happy.",
    cast: "Directed by JANEEN DAMIAN starring LINDSAY LOHAN CHORD OVERSTREET GEORGE YOUNG and BUS RILEY",
  },
  {
    id: 1181,
    name: "A Castle for Christmas",
    year: 2021,
    poster:
      "https://m.media-amazon.com/images/M/MV5BN2MwOTJhMTMtZGI3My00ZjgzLTk1MjQtNDYyYmQ2NTg5ZDYyXkEyXkFqcGdeQXVyMTEzMTI1Mjk3._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "An American author is charmed by a Scottish duke, then she hates him, then she loves him, then not, then ...",
    genre: "Romcom",
    subgenre: "",
    runtime: "98 min",
    pairs: "a Rusty Nail.",
    noPairs: "",
    drinkRecipe: "Scotch and Drambuie.",
    rated: "TV-G",
    picture1: castleChristmas1,
    picture2: castleChristmas3,
    picture3: castleChristmas2,
    tagline: "I LOVE CARY ELWES!",
    taglineWriter: "Cary Elwes",
    bulletOne: "Free Scottish lessons with Cary Elwes.",
    bulletTwo:
      "'I wanted to call it 'The Christmas Bride' but Brooke said it's not about you. But it is' - Cary Elwes.",
    cast: "Directed by MARY LAMBERT starring BROOKE SHIELDS CARY ELWES LEE ROSS and ANDI OSHO",
  },
  {
    id: 1182,
    name: "Planes, Trains & Automobiles",
    year: 1987,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNWRjZmFlMDAtZjljYy00YjUwLTgyMDQtMTI5ZjhiZTNkNDNhXkEyXkFqcGdeQXVyMTUzMDUzNTI3._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "Uptight marketing exec gets stuck on misadventure after misadventure with obnoxious shower curtain salesman as he tries to get home for Thanksgiving.",
    genre: "Comedy",
    subgenre: "",
    runtime: "93 min",
    pairs: "",
    noPairs: "mini bottles of Jamaican rum.",
    drinkRecipe: "",
    rated: "R",
    picture1: planesTrains1,
    picture2: planesTrains2,
    picture3: planesTrains3,
    tagline: "AGH! AGH! AGH! AGH!",
    taglineWriter: "Chatty Cathy",
    bulletOne: "Masterclass in swearing with Steve Martin.",
    bulletTwo: "John Candy's surprised expression.",
    cast: "Directed by JOHN HUGHES starring STEVE MARTIN JOHN CANDY LAILA ROBBINS and DYLAN BAKER",
  },
  {
    id: 1183,
    name: "Joker: Folie à Deux",
    year: 2024,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTRlNmU1NzEtODNkNC00ZGM3LWFmNzQtMjBlMWRiYTcyMGRhXkEyXkFqcGc@._V1_SX300.jpg",
    rating: 1,
    synopsis:
      "The little loser is back. And this time he gives you a glimpse into his mind by torturing you through singing with Lady Gaga.",
    genre: "Crime",
    subgenre: "",
    runtime: "138 min",
    pairs: "an Isopropyl.",
    noPairs: "",
    drinkRecipe: "Vodka, gin, dry vermouth, dash of absinthe, lemon twist.",
    rated: "R",
    picture1: joker21,
    picture2: joker22,
    picture3: joker23,
    tagline: "PLEASE STOP SINGING",
    taglineWriter: "Arthur Fleck and everyone in the audience",
    bulletOne:
      "Being able to feel like Arthur Fleck: like you want to kill yourself.",
    bulletTwo: "Watching Todd Phillips' career go downhill.",
    cast: "Directed by TODD PHILLIPS starring JOAQUIN PHOENIX LADY GAGA BRENDAN GLEESON and ZAZIE BEETZ",
  },
  {
    id: 1184,
    name: "Christmas in Rome",
    year: 2019,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMmE0ZjcyNzAtZTFmMy00YjVkLWFmYmUtOWU4ODQ2NGI2ZjJiXkEyXkFqcGc@._V1_SX300.jpg",
    rating: 1,
    synopsis:
      "If someone knows what the plot of this movie is, please email us at contact@filmslobs.com.",
    genre: "Romcom",
    subgenre: "",
    runtime: "86 min",
    pairs: "a Latte X.",
    noPairs: "",
    drinkRecipe: "Warm milk and xanax.",
    rated: "TV-G",
    picture1: christmasRome1,
    picture2: christmasRome2,
    picture3: christmasRome3,
    tagline: "OH MY GOSH, DOES THAT SUCK",
    taglineWriter: "Frank Cross",
    bulletOne: "Seeing how a movie can be made without a story.",
    bulletTwo:
      "In Italian, Angela DeLuca translates to 'I was hotter when I was a mean girl'.",
    cast: "Directed by ERNIE BARBARASH starring LACEY CHABERT SAM PAGE FRANCO NERO and HOLLY HAYES",
  },
  {
    id: 1185,
    name: "Anchorman: The Legend of Ron Burgundy",
    year: 2004,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTQ2MzYwMzk5Ml5BMl5BanBnXkFtZTcwOTI4NzUyMw@@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "Anchorman Ron Burgundy loses to the teleprompter and gets upstaged by a smelly pirate hooker.",
    genre: "Comedy",
    subgenre: "",
    runtime: "94 min",
    pairs: "",
    noPairs: "Three fingers of Glenlivet.",
    drinkRecipe: "",
    rated: "PG-13",
    picture1: anchorman1,
    picture2: anchorman2,
    picture3: anchorman3,
    tagline: "JAZZ FLUTE IS FOR LITTLE FAIRY BOYS",
    taglineWriter: "Tits McGee",
    bulletOne: "San Diego means 'Whales Vagina' in German.",
    bulletTwo: "60% of the time Sex Panther works every time.",
    cast: "Directed by ADAM MCKAY starring WILL FERRELL STEVE CARRELL PAUL RUDD and CHRISTINA APPLEGATE",
  },
  {
    id: 1186,
    name: "Hot Frosty",
    year: 2024,
    poster:
      "https://m.media-amazon.com/images/M/MV5BYjYzOTA0NWYtNzkxNi00YmY4LTljOTYtODdhYTc0ODI3MDViXkEyXkFqcGc@._V1_SX300.jpg",
    rating: 4,
    synopsis: "Hot girl gets what she always dreamed of - a hot and cool guy!",
    genre: "Romcom",
    subgenre: "",
    runtime: "92 min",
    pairs: "a Boozy Eggnog.",
    noPairs: "",
    drinkRecipe: "Eggnogg and rum.",
    rated: "TV-PG",
    picture1: hotFrosty1,
    picture2: hotFrosty2,
    picture3: hotFrosty3,
    tagline: "SOME PEOPLE ARE WORTH MELTING FOR",
    taglineWriter: "Mr. Freeze",
    bulletOne: "Discovering why Frosty is snow joke when things heat up.",
    bulletTwo:
      "Instead of a fat jolly actor portaying frosty, they cast a hunk to ensure women tune in.",
    cast: "Directed by JERRY CICCORITTI starring LACEY CHABERT DUSTIN MILLIGAN CHRISHELL STAUSE and SOPHIA WEBSTER",
  },
  {
    id: 1187,
    name: "Our Souls at Night",
    year: 2017,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMzU4ODQ5MDc5OV5BMl5BanBnXkFtZTgwMjk4MDM1MzI@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Two widowed neighbors take a chance on late-night companionship, proving that love - and Jane Fonda - never go out of style.",
    genre: "Romcom",
    subgenre: "",
    runtime: "92 min",
    pairs: "Jane Fonda's Tomatoes.",
    noPairs: "",
    drinkRecipe:
      "tomato juice, worcester sauce, lemon juice, black pepper, celery seed, vodka optional.",
    rated: "TV-14",
    picture1: soulsNight1,
    picture2: soulsNight2,
    picture3: soulsNight3,
    tagline: "Who's the hottie? Jane Fonda. At 80.",
    taglineWriter: "Spence Diamond",
    bulletOne:
      "Jane Fonda still outpacing everyone, trading leg warmers for charm.",
    bulletTwo:
      "Realizing love in your 80's can be just as transformative as an aerobics tape.",
    cast: "Directed by JERRY CICCORITTI starring LACEY CHABERT DUSTIN MILLIGAN CHRISHELL STAUSE and SOPHIA WEBSTER",
  },
  {
    id: 1188,
    name: "How to Train Your Dragon",
    year: 2010,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjA5NDQyMjc2NF5BMl5BanBnXkFtZTcwMjg5ODcyMw@@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Thought this was an instructional film, was way off. My dragon is more out of control than ever.",
    genre: "Animation",
    subgenre: "",
    runtime: "98 min",
    pairs: "a Firebreathers Delight.",
    noPairs: "",
    drinkRecipe:
      "Spicy jalapeño-infused tequila, blood orange juice, a dash of chili powder on the rim.",
    rated: "PG",
    picture1: trainDragon1,
    picture2: trainDragon2,
    picture3: trainDragon3,
    tagline: "FIRED ME UP ON A LARGE SCALE",
    taglineWriter: "Daenerys Targaryen",
    bulletOne:
      "I want to make a long, bad lizard joke but I don't want to let it dragon. ",
    bulletTwo: "Maybe I'll just wing it, since I'm so talonted.",
    cast: "Directed by DEAN DEBLOIS starring JAY BARUCHEL GERARD BUTLER AMERICA FERRARA and CRAIG FERGUSON",
  },
  {
    id: 1189,
    name: "Jack Frost",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BOTE4YWIzNjItZWNhNi00Y2E1LTgyODktMTUzM2ZkMmJlNDFlL2ltYWdlXkEyXkFqcGdeQXVyNjg4NzYzMzA@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "After getting iced in a car accident, Michael Keaton becomes a snowman. You'd think that’d leave him frosty, but he's jolly as can be. Everyone teased Jack about his pointy nose, but he didn't carrot all. Then he fuckin' melted.",
    genre: "Comedy",
    subgenre: "",
    runtime: "101 min",
    pairs: "a Frostbite Fizz.",
    noPairs: "",
    drinkRecipe:
      "vodka, blue curaçao, coconut cream, pineapple juice, crushed ice.",
    rated: "PG",
    picture1: jackfrostKeaton1,
    picture2: jackfrostKeaton2,
    picture3: jackfrostKeaton3,
    tagline: "YOU'LL LAUGH YOUR SLED OFF!",
    taglineWriter: "Citizen Kane",
    bulletOne:
      "Confusing this family friendly Jack Frost for the bowel-liquefying horror version.",
    bulletTwo: "Pelting kids with snowballs, and getting snowball tits.",
    cast: "Directed by TROY MILLER starring MICHAEL KEATON KELLY PRESTON JOSEPH CROSS and MARK ADDY",
  },
  {
    id: 1190,
    name: "Shine",
    year: 1996,
    poster:
      "https://m.media-amazon.com/images/M/MV5BODZlMjRmN2UtZmY1Yi00YjhjLTg4OTAtZmQxNWMwZTY5YjI4XkEyXkFqcGc@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "An overbearing father pushes his prodigious, piano playing son to retardation, only later to reclaim his talent and life by playing Rachmaninoff really, really loudly.",
    genre: "Drama",
    subgenre: "",
    runtime: "105 min",
    pairs: "a Flight of the Bumblebees.",
    noPairs: "",
    drinkRecipe:
      "three shot glasses of each rum, bourbon, and scotch with honey.",
    rated: "PG-13",
    picture1: shine1,
    picture2: shine2,
    picture3: shine3,
    tagline: "It helps to play Rachmaninoff while mad",
    taglineWriter: "Probably not Rachmaninoff",
    bulletOne: "Proof that playing piano can be a full-contact sport.",
    bulletTwo:
      "Questioning if your dad was really helping you or just obsessed with Beethoven.",
    cast: "Directed by SCOTT HICKS starring GEOFFREY RUSH ARMIN MUELLER-STAHL JUSTIN BRAINE and SONIA TODD",
  },
  {
    id: 1191,
    name: "Uncle Buck",
    year: 1989,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMjIwOWVkNzMtZTIyZC00ZWM0LTg5MWYtMWYwYjVhZTM3MDMzXkEyXkFqcGdeQXVyNTI4MjkwNjA@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "A generous uncle not only funds rodent-based facial surgeries for homely women, but also performs hatchet-based body modification for shitty boyfriends. Overall, a super-cool guy!",
    genre: "Comedy",
    subgenre: "",
    runtime: "100 min",
    pairs: "a Backfire.",
    noPairs: "",
    drinkRecipe:
      "bourbon, amaretto, cranberry juice, lemon juice, ginger beer.",
    rated: "PG",
    picture1: uncleBuck1,
    picture2: uncleBuck2,
    picture3: uncleBuck3,
    tagline: "IT GROWS ON YOU LIKE A MELANOMA",
    taglineWriter: "Moley Russell's Wart",
    bulletOne:
      "Having a hat so awesome that it angers people, just the sight of it.",
    bulletTwo: "Beating up a clown at a kids party.",
    cast: "Directed by JOHN HUGHES starring JOHN CANDY MACAULAY CULKIN JEAN LOUISA KELLY and GABY HOFFMAN",
  },
  {
    id: 1192,
    name: "Argylle",
    year: 2024,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNDkzMTU2OWUtZjA2ZS00ZmYxLWE2MzgtZDlhZDc1YjM4Yjk5XkEyXkFqcGc@._V1_SX300.jpg",
    rating: 2,
    synopsis:
      "This super long and drawn out spy movie proves that too many twists can strangle a plot. It’s like a puzzle missing half the pieces and you won't care enough to find them.",
    genre: "Action",
    subgenre: "",
    runtime: "139 min",
    pairs: "a Not Worthwhylle.",
    noPairs: "",
    drinkRecipe:
      "vodka, peach schnapps, cranberry juice, lemon juice, splash of club soda.",
    rated: "PG-13",
    picture1: argylle1,
    picture2: argylle2,
    picture3: argylle3,
    tagline: "ARGYLLE TAKES A WHYLLE",
    taglineWriter: "Jake Gyllenhyllle",
    bulletOne: "Dear Hollywood: STOP USING SUCH SHITTY CGI FOR EVERYTHING.",
    bulletTwo:
      "Would've much rather seen a standalone origin movie about Die Hard's Argyle.",
    cast: "Directed by MATTHEW VAUGHN starring BRYCE DALLAS HOWARD SAM ROCKWELL HENRY CAVILL and BRYAN CRANSTON",
  },
  {
    id: 1193,
    name: "Scarecrow",
    year: 1973,
    poster:
      "https://m.media-amazon.com/images/M/MV5BY2Q4ODA2MGUtMDVmNi00YzQzLWIzY2ItOTQ1NzEyMDZkNTQ4XkEyXkFqcGc@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "Two drifters pal around while using their wits and fists to scrape together enough dough for a car wash - because at a certain point you just can't make it alone anymore.",
    genre: "Drama",
    subgenre: "",
    runtime: "112 min",
    pairs: "a Bellyachin' Rhinoceros.",
    noPairs: "",
    drinkRecipe:
      "bourbon, tamarind syrup, fresh lime juice, angostura bitters, splash of soda water.",
    rated: "R",
    picture1: scarecrow1,
    picture2: scarecrow2,
    picture3: scarecrow3,
    tagline: "THAT SCARECROW WASN'T GOOFY",
    taglineWriter: "Sillyan Murphy",
    bulletOne: "Wearing every article of clothing you own at once.",
    bulletTwo: "Learning that Brick Tamland isn't the only one who loves lamp.",
    cast: "Directed by JERRY SCHATZBERG starring AL PACINO GENE HACKMAN PENELOPE ALLEN and ANN WEDGEWORTH",
  },
  {
    id: 1194,
    name: "Shakespeare in Love",
    year: 1998,
    poster:
      "https://m.media-amazon.com/images/M/MV5BM2ZkNjM5MjEtNTBlMC00OTI5LTgyYmEtZDljMzNmNzhiNzY0XkEyXkFqcGdeQXVyNDYyMDk5MTU@._V1_SX300.jpg",
    rating: 3,
    synopsis:
      "Doth thou wanteth to watch this film? Nay. However, ye receiveth a glimpse of Gyneth Paltrow's magnificent bosoms.",
    genre: "Romcom",
    subgenre: "Drama",
    runtime: "123 min",
    pairs: "a Midsummer Nights Cream.",
    noPairs: "",
    drinkRecipe: "irish cream liquer, dark rum, kahlua, grated chocolate.",
    rated: "R",
    picture1: shakespeareInLove1,
    picture2: shakespeareInLove2,
    picture3: shakespeareInLove3,
    tagline: "SHAKESPEARE’S INTO LOVE AND OTHER DRUGS",
    taglineWriter: "Anne Hathaway",
    bulletOne:
      "Ay me! She speaks! O do not speak again for thou art not a man at all!",
    bulletTwo:
      "Honestly, movies with frilly dandyism just isn't our cup of beer.",
    cast: "Directed by JOHN MADDEN starring GWYNETH PALTROW JOSEPH FIENNES GEOFFREY RUSH and TOM WILKINSON",
  },
  {
    id: 1195,
    name: "The Invention of Lying",
    year: 2009,
    poster:
      "https://m.media-amazon.com/images/M/MV5BMTU2OTQzOTc1Nl5BMl5BanBnXkFtZTcwNDM5MDE4Mg@@._V1_SX300.jpg",
    rating: 3,
    synopsis:
      "The little fat man with a pug nose face discovers he can lie. As I must be honest in this review, it's a funny concept but gets pretty stale midway through.",
    genre: "Comedy",
    subgenre: "",
    runtime: "100 min",
    pairs: "a Truth Serum Sour.",
    noPairs: "",
    drinkRecipe: "Whiskey, bitters, lemon juice, simple syrup, egg white.",
    rated: "PG-13",
    picture1: inventLying1,
    picture2: inventLying2,
    picture3: inventLying3,
    tagline: "That ginger looked pudgy without a goatee",
    taglineWriter: "Louis Seize-Cake",
    bulletOne: "Tina Fey turns in a great cameo.",
    bulletTwo:
      "Gervais is a clever chap for naming his protagonist M. Bellison or embellishing.",
    cast: "Directed by RICKY GERVAIS starring RICKY GERVAIS JENNIFER GARNER LOUIS CK and ROB LOWE",
  },
  {
    id: 1196,
    name: "Love and Death",
    year: 1975,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNGY4ODgwYjctNGQ1NS00MzgxLWE2ZmQtMjdjN2IzNGUwY2I3XkEyXkFqcGc@._V1_SX300.jpg",
    rating: 6,
    synopsis:
      "A neurotic peasant accidentally becomes a war hero, plots a botched assassination, and debates life, love, and death - mostly while running away.",
    genre: "Comedy",
    subgenre: "",
    runtime: "85 min",
    pairs: "a Russian Paradox.",
    noPairs: "",
    drinkRecipe:
      "2oz vodka, 1oz coffee liquer, 1oz heavy cream, a dash of cinnamon.",
    rated: "PG",
    picture1: loveDeath1,
    picture2: loveDeath2,
    picture3: loveDeath3,
    tagline: "TO DIE IS EASY, COMEDY IS HARD",
    taglineWriter: "Someone funnier than Boris",
    bulletOne: "A step-by-step guide to surviving a duel: just don't show up.",
    bulletTwo: "Finally understanding why Tolstoy didn't write comedies.",
    cast: "Directed by WOODY ALLEN starring WOODY ALLEN DIANE KEATON FEODOR ATKINE and YVES BARSACQ",
  },
  {
    id: 1197,
    name: "Elvira: Mistress of the Dark",
    year: 1988,
    poster:
      "https://m.media-amazon.com/images/M/MV5BZmEzNjc4OTItYTU5YS00MzlkLTg2MDctNmQ0MDQ1Y2Y4ZTQzXkEyXkFqcGc@._V1_SX300.jpg",
    rating: 5,
    synopsis:
      "A horror hostess inherits a spooky mansion in a small town, only to scandalize the locals with her black lace, brilliant puns, and intelligent boobs.",
    genre: "Comedy",
    subgenre: "Horror",
    runtime: "96 min",
    pairs: "Eye Liner. ",
    noPairs: "",
    drinkRecipe:
      "2oz black vodka, 1oz coffee liquer, 1/2oz creme de cassis, splash of sparkling water, lemon peel garnish.",
    rated: "PG-13",
    picture1: elvira1,
    picture2: elvira2,
    picture3: elvira3,
    tagline: "HOME IS WHERE THE CLEAVAGE IS",
    taglineWriter: "Elvira, Mistress of the Boner",
    bulletOne: "Recipes for casserole distasters - and revenge.",
    bulletTwo: "The winner for best supporting actor goes to: Cleavage.",
    cast: "Directed by JAMES SIGNORELLI starring CASSANDRA PETERSON PHIL RUBENSTEIN LARRY FLASH JENKINS and TRESS MACNEILLE",
  },
  {
    id: 1198,
    name: "Bridget Jones's Diary",
    year: 2001,
    poster:
      "https://m.media-amazon.com/images/M/MV5BNTM1MTFiNDEtMGYzZC00MjM5LWEwMWMtN2VhNjg1MTEzOTg3XkEyXkFqcGc@._V1_SX300.jpg",
    rating: 4,
    synopsis:
      "A woman with a loving family, friends, and two handsome boyfriends whines in her diary and has more Chardonnay than the French wine industry. Textbook woman.",
    genre: "Romcom",
    subgenre: "",
    runtime: "97 min",
    pairs: "Sauvignon Bleu.",
    noPairs: "",
    drinkRecipe: "white wine and a shot of blue curaçao.",
    rated: "R",
    picture1: bridgetJones1,
    picture2: bridgetJones2,
    picture3: bridgetJones3,
    tagline: "Vodka is not a substitute for emotional stability",
    taglineWriter: "Hugh Grant's Diary",
    bulletOne: "The iconic battle of fists vs. floppy hair.",
    bulletTwo: "Absolutely enormous panties.",
    cast: "Directed by SHARON MAGUIRE starring RENEE ZELLWEGGER HUGH GRANT COLIN FIRTH and GEMMA JONES",
  },
];

export default MovieList;

// var result = Object.values(
//   MovieList.reduce((c, v) => {
//     let k = v.name;
//     c[k] = c[k] || [];
//     c[k].push(v);
//     return c;
//   }, {})
// ).reduce((c, v) => (v.length > 1 ? c.concat(v) : c), []);

// console.log("The result is " + result);

// Call the findIncomplete() function

// for (let i = 0; i < MovieList.length; i++) {
//   if (MovieList[i].bulletOne === "" || MovieList[i].bulletTwo === "") {
//     console.log(MovieList[i]);
//   }
// }

// let reviewedMovies = MovieList.filter((movie) => movie.tagline === "");

// console.log(reviewedMovies);
