import React, { useState } from "react";
import { Link } from "react-router-dom";
import ShareCircle from "../ShareCircle.js";
import ShareSquare from "../ShareSquare";
import Frost1 from "../grabbag-pictures/jack-frost-1.png";
import Frost2 from "../grabbag-pictures/jack-frost-2.png";
import DvdShelf from "../grabbag-pictures/dvdshelf-1.jpeg";
import ActionStars from "../grabbag-pictures/action-stars.jpeg";
import VideoStore from "../grabbag-pictures/video-store.webp";
import Pirates from "../grabbag-pictures/pirates.png";
import Mashup from "../grabbag-pictures/collage.jpg";
import Riverdance from "../grabbag-pictures/riverdance.jpeg";
import Boozecruize from "../grabbag-pictures/BC_TN2.jpg";
import Zingers from "../pictures/topfive/zingers.jpeg";
import koogler from "../topfive-pictures/koogler.jpeg";
import NotificationBell from "../NotificationBell.js";

function OdeVideoStore() {
  const [isHovered, setIsHovered] = useState(false);
  function handleMouseEnter() {
    setIsHovered(true);
  }

  function handleMouseLeave() {
    setIsHovered(false);
  }
  return (
    <div>
      <div className="currentRoute">
        <Link to="/articles">
          <span className="underline">Articles</span>
        </Link>{" "}
        &gt;{" "}
        <Link to="writingGrabbag">
          <span className="underline">Grab Bag</span>
        </Link>{" "}
        &gt; Ode to the Video Store
      </div>
      <div className="articleContentContainer">
        <div class="article-container">
          <div class="article-container-header">
            <p>Ode to the Video Store</p>
          </div>
          <div className="published">PUBLISHED Jul 3, 2023</div>
          <div className="authorName">
            BY{" "}
            <p>
              <Link to="/dean">
                <span className="green" id="author-name">
                  Dean
                </span>
              </Link>
            </p>
            <ShareCircle
              description={
                "Check out this article from FilmSlobs! Ode to the Video Store"
              }
            />
          </div>
          <div class="article-container-body">
            <div className="image-and-caption">
              <img
                src={DvdShelf}
                className="article-image"
                alt={"Player Avatar"}
              />
            </div>
            <p>
              This should surprise no one, but we{" "}
              <span className="green">loved the video store. </span>
              From the tantalizing scent of popcorn to the anticipation of
              securing your desired DVD, and the awkward interactions with other
              eccentric weirdos, the video store truly had something for
              everyone.
            </p>

            <p>
              We don't have a <span className="green">personal vendetta</span>{" "}
              against streaming services, but they just don't compare. Sure,
              virtual platforms offer convenience and a vast selection, but
              there's a certain joy-sucking feeling that comes with endless
              scrolling. It can never replicate the thrill of physically
              browsing through aisles, uncovering hidden gems, and having stupid
              debates with fellow geeks about which '80s action hero reigns
              supreme.
            </p>
            <div className="image-and-caption">
              <img
                src={ActionStars}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                'Personal Vendetta' was somehow not a single one of their movie
                titles.
              </p>
            </div>
            <p>
              Stepping into the video store turbocharged the movie-watching
              adventure. It was like unleashing your inner{" "}
              <span className="green">
                hunter-gatherer, armed and dangerous, on the celluloid
                battlefield
              </span>{" "}
              (also somehow not 80's action movie titles). You stalked your
              genre prey, nerves electrified with anticipation. The
              heart-pounding question lingered: Would your cinematic treasure be
              on the shelf, or cruelly swiped by a fellow cinephile seconds
              before your grand entrance? It was a high-stakes game of chance
              where the winner took all.
            </p>

            <div className="image-and-caption">
              <img
                src={VideoStore}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">
                Khaki Chan battles Tuck Norris for the last copy of 'Phat
                Beach'.
              </p>
            </div>
            <p>
              In the midst of the chaos, camaraderie flourished – a haven where
              engaging conversations with fellow film buffs flowed as smoothly
              as buttering popcorn. It was a realm where the only thing stickier
              than the floor was the sense of kinship in the air.
            </p>
            <p>
              And speaking of sticky floors, who could forget the dire
              predicament of <span className="green">'mistaken rentals'</span>.
              How many times could you see a fat slob in sweatpants awkwardly
              shuffle back to the store, palms rubbed raw and forehead
              glistening, desperately confessing to the clerk, "Oops! I
              mistakenly sailed into the wrong territory with Pirates instead of
              Pirates of the Caribbean!".{" "}
              <span className="green">Yeah, sure.</span>
            </p>
            <div className="image-and-caption">
              <img
                src={Pirates}
                className="article-image"
                alt={"Player Avatar"}
                style={{ objectFit: "contain" }}
              />
              <p id="image-caption">
                <strong>Disclaimer:</strong> Chest, Booty, and Peg may not mean
                what you think.
              </p>
              <p>
                'Pirates' and 'Pirates of the Caribbean' are pretty close, but
                how about the diabolical movie companies releasing &nbsp;
                <span className="green">
                  identically-titled films with wildly different genres?
                </span>{" "}
                My innocent childhood took an unexpected detour when I aimed for
                the comedic embrace of "Jack Frost" only to shit my pants in
                terror as I mistakenly snatched the horror version.
              </p>
            </div>
            <div className="image-and-caption">
              <div className="article-image-vertical-div">
                <img
                  id="jackFrost"
                  src={isHovered ? Frost2 : Frost1}
                  alt="Image"
                  class="article-image-vertical"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                />
              </div>
              <p id="image-caption">R.I.P. Childhood</p>
            </div>
            <p>
              The deceitful title wasn't enough; they doubled down on the
              psychological havoc with a bowel-liquefying hologram on the VHS
              cover. It's a jolt of terror that children today will
              unfortunately never experience, unless Disney implements a hover
              effect on Frozen where Olaf chugs spinal fluid from Elsa's severed
              head.
            </p>

            <p>
              Trolling notwithstanding, the artwork on movie covers back then
              was vastly superior. Aside from the occasional cover which was
              genuinely repulsive - like the dreaded 'Bleeders' - there was a
              certain charm and allure that made browsing the aisles a{" "}
              <span className="green">visual delight</span>. Unlike today, where
              the scrolling experience only offers a doofus face and a bland
              movie title in giant white print.
            </p>
            <div className="image-and-caption">
              <img
                src={Mashup}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">Creativity 101</p>
            </div>
            <p>
              One major drawback of the video store was admittedly late fees --
              but once the writing was on the wall with an impending digital
              revolution, it was like the last days of Rome. No more late fees,
              free food and drink combos, and unlimited rentals. Hollywood
              Video's MVP plan was a bigger enabler of our addiction than a
              mother blending Twinkie smoothies for her 600-pound 8-year-old.
            </p>

            <p>
              Speaking of <span className="green">fat and useless</span>, Redbox
              strutted into the market like a film salesman at a digital camera
              convention. It had no concept of unlimited rentals and it didn't
              eliminate late fees, but it DID provide a terrible ambiance. Video
              stores provided space, privacy, and a roof over your head, whereas
              Redbox left you huddled in the rain outside a decrepit mini-mart.
              It's hard to say what was worse - the scrutiny of every passerby
              judging your rental or the rhapsody of impatient foot tapping from
              others waiting in line.
            </p>
            <div className="image-and-caption">
              <img
                src={Riverdance}
                className="article-image"
                alt={"Player Avatar"}
              />
              <p id="image-caption">"Take your time"</p>
            </div>
            <p>
              A few video rental joints (and somehow, Redbox) are still hanging
              on, but the golden days are certainly past. If we could end our
              disjointed and incoherent love-letter to the video store with a
              final recommendation, let it be this ...
            </p>
            <p>
              The next time you feel your life force draining away while
              drowning in the endless sea of streaming choices, take a moment to
              reminisce on the{" "}
              <span className="green">majesty of the video store.</span> Close
              your eyes and imagine the symphony of buttery popcorn, sweet
              licorice, and the intoxicating scent of freshly printed movie
              posters. Indulge in that sensory bliss, for it's a red carpet
              unfurled exclusively for your nose, beckoning you to step into a
              realm of nostalgia and cinematic enchantment.
            </p>
            <p>
              Now let us raise our dust-covered rental cards in a toast to the
              bygone era of VHS tapes and DVD's. To the time when when movie
              nights were filled with anticipation, surprises, and delightful
              human interactions. When getting lost among the aisles meant
              finding a little piece of film heaven, and maybe even a friend .
            </p>
            <p>Cheers!</p>
          </div>{" "}
          <Link to="/hackiswack">
            <div className="readMoreContainer">
              <p className="readMoreHeader">Read next:</p>
              <p className="readMoreContent">Hack is Wack</p>
              <p className="underscore"></p>
            </div>
          </Link>
          <ShareSquare
            description={
              "Check out this article from FilmSlobs! Ode to the Video Store"
            }
          />
          <div>
            <Link to="/email">
              <NotificationBell />
            </Link>
          </div>
        </div>
        <div className="relatedSidebar">
          <p className="seeAlso">You may also like ...</p>
          <Link to="/boozecruize">
            <div className="image-and-caption">
              <img
                src={Boozecruize}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Ride the booze cruize</p>
            </div>
          </Link>
          <Link to="/bestzingers">
            <div className="image-and-caption">
              <img
                src={Zingers}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">
                best zingers from minor characters
              </p>
            </div>
          </Link>
          <Link to="/fakemovies">
            <div className="image-and-caption">
              <img
                src={koogler}
                className="article-image-sidebar"
                alt={"Player Avatar"}
              />
              <p id="sidebar-image-caption">Fake movies we want to see</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default OdeVideoStore;
